import React from "react";
import styled from "styled-components";

const AlertIcon = () => {
  return (
    <Svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <circle opacity="0.1" cx="37.5" cy="37.5" r="37.5" fill="#0079FF"/> */}
      <path
        d="M50.2777 23.1692C49.9412 22.9416 49.5136 22.8957 49.1365 23.0467L37.1022 27.8565H27.5512C24.1698 27.8565 21.4287 30.5977 21.4287 33.979C21.4287 37.3603 24.1698 40.1014 27.5512 40.1014H37.1022L49.1267 44.9112C49.505 45.0667 49.9359 45.0229 50.2753 44.7947C50.6147 44.5665 50.8177 44.1839 50.8165 43.7749V24.1831C50.8163 23.7766 50.6144 23.3968 50.2777 23.1692Z"
        fill="#0079FF"
      />
      <path
        d="M36.4043 42.5508H28.4941L31.4868 50.0373C32.2402 51.9214 34.3782 52.838 36.2623 52.0847C38.1464 51.3313 39.063 49.1932 38.3097 47.3091L36.4043 42.5508Z"
        fill="#0079FF"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  path,
  circle {
    fill: ${(props) => props.theme.colors.blue};
  }
`;

export default AlertIcon;
