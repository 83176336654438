import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getAccessLevelsFromApi } from "./utils/api";
import { getMembersFromApi } from "../../utils/api";

import ProfileImage from "../../components/ProfileImage";
import SimpleLink from "../../components/links/SimpleLink";
import CreateLink from "../../components/links/CreateLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../components/Table";

const AccessLevels = () => {
  const [sortBy, setSortBy] = useState("name-asc");
  const [accessLevels, setAccessLevels] = useState([]);
  const [members, setMembers] = useState([]);

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    const results = await getMembersFromApi();

    if (results) {
      setMembers(results);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all access levels
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members) {
      getAccessLevels();
    }
  }, [members]); //eslint-disable-line

  const getAccessLevels = async () => {
    let results = await getAccessLevelsFromApi();

    if (results) {
      // Sort them alphabetically from the start
      results.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      results = results.map((item) => {
        const users = [];
        members.forEach((member) => {
          if (member.accessLvl === item.name) {
            users.push(member);
          }
        });

        item.users = users;
        return item;
      });

      setAccessLevels(results);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the AccessLevels
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedAccessLevels = [...accessLevels];

    if (sortBy === "name-asc") {
      sortedAccessLevels.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedAccessLevels.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "users-asc") {
      sortedAccessLevels.sort(function (a, b) {
        var textA = a.users.length;
        var textB = b.users.length;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "users-desc") {
      sortedAccessLevels.sort(function (a, b) {
        var textA = a.users.length;
        var textB = b.users.length;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setAccessLevels(sortedAccessLevels);
  }, [sortBy]); // eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  return (
    <AccessLevelsContainer>
      <AccessLevelsHeader>
        <h1>Access levels</h1>
        <CreateLink as={Link} to={`/manage/accessLevels/new`}>
          Create Access level
        </CreateLink>
      </AccessLevelsHeader>
      {accessLevels.length ? (
        <AccessLevelsList id="accessLevelsTable">
          <thead>
            <tr>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
                style={{ paddingLeft: "0px" }}
              >
                Name
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("users")}
                isArrowUp={sortBy === "users-desc"}
                align="left"
              >
                Assigned To
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {accessLevels.map((accessLevel, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  <TableCellTitle>
                    <SimpleLink
                      as={Link}
                      to={`/manage/AccessLevels/${accessLevel._id}`}
                    >
                      {accessLevel.name}
                    </SimpleLink>
                  </TableCellTitle>
                </TableCell>
                <TableCell align="left">
                  <Users>
                    {accessLevel.users.map((user) => {
                      return (
                        <ProfileImage
                          handle={user.handle}
                          name={user.name}
                          small
                          style={{ marginRight: "15px" }}
                          showStatus
                        />
                      );
                    })}
                  </Users>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </AccessLevelsList>
      ) : null}
    </AccessLevelsContainer>
  );
};

const AccessLevelsContainer = styled.div`
  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const AccessLevelsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const AccessLevelsList = styled.table`
  padding: 50px;
`;

const Users = styled.div`
  display: flex;
`;

export default AccessLevels;
