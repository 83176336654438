import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { getMembersFromApi } from "../../utils/api";
import { getKpiFromApi, updateKpiOnApi } from "./utils/api";

import { useNotifications } from "../../context/notificationsContext";
import { Field, Label, Input } from "../../components/Form";
import Button from "../../components/buttons/Button";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";
import Checkbox from "../tasks/components/Checkbox";

const KpiCreate = () => {
  const history = useHistory();
  const { id } = useParams();
  const { openAlertPopup } = useNotifications();

  const [custom, setCustom] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [target, setTarget] = useState("");
  const [frequency, setFrequency] = useState("");
  const [owner, setOwner] = useState("");
  const [modalTitle, setModalTitle] = useState(null);
  const [modalBody, setModalBody] = useState(null);
  const [members, setMembers] = useState([]);

  const frequencies = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
  ];

  const types = [
    {
      label: "Overdue Tasks",
      value: "Overdue Tasks",
    },
    {
      label: "Billable Hours",
      value: "Billable Hours",
    },
    {
      label: "Projects Overdue",
      value: "Projects Overdue",
    },
    // {
    //   label: "Proposals Sent",
    //   value: "Proposals Sent",
    // },
  ];

  /*
  |--------------------------------------------------------------------------
  | Get the current Kpi
  |--------------------------------------------------------------------------
  */
  const getKpi = async () => {
    try {
      const results = await getKpiFromApi(id);

      if (results.type) {
        setType(results.type);
        setCustom(false);
      } else if (!results.type && results.name) {
        setName(results.name);
        setCustom(true);
      }
      setFrequency(results.frequency);
      setTarget(results.target);
      setOwner(results.member);
      setModalTitle(results.modalTitle);
      setModalBody(results.modalBody);
    } catch (error) {
      console.error(error);
    }
  };

  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();
      setMembers(results);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id,
      name,
      modalTitle,
      modalBody,
      type,
      target,
      frequency,
      member: owner,
    };

    try {
      await updateKpiOnApi(data);
      openAlertPopup(
        "Success",
        `${name ? name : type} has been updated.`,
        true,
      );

      history.push(`/manage/kpi`);
    } catch (error) {
      console.error("error", error);

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't update the KPI.`,
      );
    }
  };

  useEffect(() => {
    getMembers();
    getKpi();
  }, []); //eslint-disable-line

  return (
    <Container>
      <HeaderSection>
        <Heading>Edit KPI</Heading>
        <SimpleLink as={Link} to={`/manage/kpi/`}>
          Back to KPIs
        </SimpleLink>
      </HeaderSection>

      <EditSection onSubmit={handleSubmit}>
        <Field>
          <Label>Custom KPI?</Label>
          <Checkbox
            checked={custom}
            onChange={() => {
              setCustom(!custom);
              if (custom) {
                setName(null);
                setModalTitle(null);
                setModalBody(null);
              } else {
                setType(null);
              }
            }}
          />
        </Field>
        {custom ? (
          <>
            <Field>
              <Label>KPI Name</Label>
              <Input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="KPI Name"
                required
              />
            </Field>
            <Field>
              <Label>Modal Title</Label>
              <Input
                type="text"
                value={modalTitle}
                onChange={(e) => {
                  setModalTitle(e.target.value);
                }}
                placeholder="Modal Title"
                required
              />
            </Field>
            <Field>
              <Label>Modal Body</Label>
              <Input
                type="text"
                value={modalBody}
                onChange={(e) => {
                  setModalBody(e.target.value);
                }}
                placeholder="Modal Body"
                required
              />
            </Field>
          </>
        ) : (
          <Field>
            <Label>KPI Type</Label>
            <SelectDropdown
              placeholder="Select KPI Type"
              onChange={(e) => {
                setType(e.value);
                if (
                  e.value === "Overdue Tasks" ||
                  e.value === "Projects Overdue"
                ) {
                  setFrequency("Daily");
                }
              }}
              value={
                type
                  ? {
                      label: type,
                      value: type,
                    }
                  : null
              }
              options={types}
            />
          </Field>
        )}

        <Field>
          <Label>Target</Label>
          <Input
            value={target}
            type="number"
            onChange={(e) => setTarget(e.target.value)}
            placeholder="Target"
            required
          />
        </Field>
        <Field>
          <Label>Member</Label>
          {members && members.length && owner ? (
            <SelectDropdown
              placeholder="Select Member"
              onChange={(e) => {
                setOwner(e.value);
              }}
              value={{
                label: members.find((member) => member._id === owner).name,
                value: owner,
              }}
              options={members.map((member) => {
                return {
                  label: member.name,
                  value: member._id,
                };
              })}
            />
          ) : null}
        </Field>
        <Field>
          <Label>Frequency</Label>
          <SelectDropdown
            placeholder="Select Frequency"
            isDisabled={type === "Overdue Tasks" || type === "Projects Overdue"}
            value={{
              label: frequency,
              value: frequency,
            }}
            onChange={(e) => {
              setFrequency(e.value);
            }}
            options={frequencies}
          />
        </Field>
        <Button type="submit">Edit</Button>
      </EditSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const HeaderSection = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 40px;
`;

const EditSection = styled.form`
  width: 300px;
`;

export default KpiCreate;
