import React, { useState } from "react";
import styled from "styled-components";
import AccordionIcon from "../../sales/components/AccordionIcon";

const Accordion = ({ header, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <AccordionHeader onClick={() => setOpen(!open)}>
        <AccordionIconContainer open={open}>
          <AccordionIcon />
        </AccordionIconContainer>
        {header}
      </AccordionHeader>
      <AccordionPanel open={open}>
        <AccordionBody>{children}</AccordionBody>
      </AccordionPanel>
    </Container>
  );
};

const Container = styled.div`
  margin: 0px 10px 15px 10px;
  width: calc(50% - 20px);
`;

const AccordionHeader = styled.div`
  background-color: #f9fafb;
  color: #374151;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  padding: 8px 32px 8px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const AccordionIconContainer = styled.div`
  margin-right: 4px;
  /* transition: 0.2s ease-in-out; */
  transform: ${(props) => (props.open ? "rotate(90deg)" : "rotate(0deg)")};
`;

const AccordionPanel = styled.div`
  overflow: hidden;
  transition: 0.5s ease-in-out;
  height: ${(props) => (props.open ? "auto" : "0")};
`;

const AccordionBody = styled.div`
  font-weight: 400;
  color: #374151;
  font-size: 14px;
  line-height: 1.42;
  padding: 16px 32px;
`;

export default Accordion;
