import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getCompaniesFromApi, getMembersFromApi } from "../../../utils/api";

import { Input, PhoneInput } from "../../../components/newForm";
import SelectDropdown from "../../../components/newSelectDropdown";

import Button from "./button";
import CreateSidebar from "./createSidebar";

const UpdateContact = ({ currentContact, isOpen, close, handleUpdate }) => {
  const [id, setId] = useState("");
  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [availOwners, setAvailOwners] = useState([]);

  const availStatuses = [
    "New",
    "Target",
    "Contacted",
    "Prospect",
    "Open Deal",
    "Client",
    "Abandoned",
    "Referral Partner",
    "Past Client",
    "Vendor",
  ];
  const [availCompanies, setAvailCompanies] = useState([]);
  const availStates = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District of Columbia",
    "Federated States of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virgin Island",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    setupData();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Update existing data when the currentContact changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (currentContact) {
      setId(currentContact._id);
      setOwner(
        currentContact.owner
          ? {
              label: currentContact.owner.name,
              value: currentContact.owner._id,
            }
          : "",
      );
      setStatus(
        currentContact.status
          ? { label: currentContact.status, value: currentContact.status }
          : "",
      );
      setCompany(
        currentContact.company
          ? {
              label: currentContact.company.name,
              value: currentContact.company._id,
            }
          : "",
      );
      setFirstName(currentContact.firstName ? currentContact.firstName : "");
      setLastName(currentContact.lastName ? currentContact.lastName : "");
      setTitle(currentContact.title ? currentContact.title : "");
      setEmail(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.email
          : "",
      );
      setPhone(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.phone
          : "",
      );
      setAddress1(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.address1
          : "",
      );
      setAddress2(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.address2
          : "",
      );
      setCity(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.city
          : "",
      );
      setState(
        currentContact && currentContact.contactInfo
          ? {
              label: currentContact.contactInfo.state,
              value: currentContact.contactInfo.state,
            }
          : "",
      );
      setZipcode(
        currentContact && currentContact.contactInfo
          ? currentContact.contactInfo.zipcode
          : "",
      );
    }
  }, [currentContact]);

  const setupData = async () => {
    const newAvailOwners = await getMembersFromApi();
    const newAvailCompanies = await getCompaniesFromApi();

    setAvailOwners(newAvailOwners);
    setAvailCompanies(newAvailCompanies);
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();

      handleUpdate(id, {
        owner: owner.value,
        status: status.value,
        company: company.value,
        firstName,
        lastName,
        title,
        contactInfo: {
          email,
          phone,
          address1,
          address2,
          city,
          state: state.value,
          zipcode,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CreateSidebar
      title="Update Contact"
      isOpen={isOpen}
      close={close}
      onSubmit={handleSubmit}
    >
      <CreateDropdown
        placeholder="Owner"
        value={owner}
        onChange={(e) => {
          setOwner(e);
        }}
        options={
          availOwners
            ? availOwners.map((owner) => ({
                label: owner.name,
                value: owner._id,
              }))
            : []
        }
      />
      <CreateDropdown
        placeholder="Status"
        value={status}
        onChange={(e) => {
          setStatus(e);
        }}
        options={
          availStatuses
            ? availStatuses.map((status) => ({
                label: status,
                value: status,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <CreateInput
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <CreateInput
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="email"
      />
      <CreateInput
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <CreateDropdown
        placeholder="Company"
        value={company}
        onChange={(e) => {
          setCompany(e);
        }}
        options={
          availCompanies
            ? availCompanies.map((company) => ({
                label: company.name,
                value: company._id,
              }))
            : []
        }
      />
      <CreatePhoneInput
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 1"
        value={address1}
        onChange={(e) => setAddress1(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 2"
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
      />
      <CreateInput
        placeholder="City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <CreateDropdown
        placeholder="State"
        value={state}
        onChange={(e) => {
          setState(e);
        }}
        options={
          availStates
            ? availStates.map((state) => ({
                label: state,
                value: state,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="Zip Code"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
      />
      <Button>Update</Button>
    </CreateSidebar>
  );
};

const CreateInput = styled(Input)`
  margin-bottom: 16px;
`;

const CreatePhoneInput = styled(PhoneInput)`
  margin-bottom: 16px;
`;

const CreateDropdown = styled(SelectDropdown)`
  margin-bottom: 16px;
`;

export default UpdateContact;
