import React from "react";

export default () => (
  <svg
    width="21"
    height="17"
    viewBox="0 0 21 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70508 15.75C3.17465 15.75 2.66594 15.5393 2.29086 15.1642C1.91579 14.7891 1.70508 14.2804 1.70508 13.75V3.75C1.70508 3.21957 1.91579 2.71086 2.29086 2.33579C2.66594 1.96071 3.17465 1.75 3.70508 1.75H7.70508L9.70508 3.75H13.7051C14.2355 3.75 14.7442 3.96071 15.1193 4.33579C15.4944 4.71086 15.7051 5.21957 15.7051 5.75V6.75M3.70508 15.75H17.7051C18.2355 15.75 18.7442 15.5393 19.1193 15.1642C19.4944 14.7891 19.7051 14.2804 19.7051 13.75V8.75C19.7051 8.21957 19.4944 7.71086 19.1193 7.33579C18.7442 6.96071 18.2355 6.75 17.7051 6.75H7.70508C7.17465 6.75 6.66594 6.96071 6.29086 7.33579C5.91579 7.71086 5.70508 8.21957 5.70508 8.75V13.75C5.70508 14.2804 5.49436 14.7891 5.11929 15.1642C4.74422 15.5393 4.23551 15.75 3.70508 15.75Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
