import React from "react";
import styled from "styled-components";

export default ({ ...rest }) => {
  return (
    <Svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="5" height="5" fill="#001940" />
      <rect y="6" width="5" height="5" fill="#001940" />
      <rect y="12" width="5" height="5" fill="#001940" />
      <rect x="6" width="5" height="5" fill="#001940" />
      <rect x="6" y="6" width="5" height="5" fill="#001940" />
      <rect x="6" y="12" width="5" height="5" fill="#001940" />
      <rect x="12" width="5" height="5" fill="#001940" />
      <rect x="12" y="6" width="5" height="5" fill="#001940" />
      <rect x="12" y="12" width="5" height="5" fill="#001940" />
    </Svg>
  );
};

const Svg = styled.svg`
  cursor: pointer;
  rect {
    transition: 0.3s ease-in-out;
    fill: ${(props) => props.theme.colors.mediumGray};
  }

  &:hover,
  &:focus {
    rect {
      fill: ${(props) => props.theme.colors.oldBlack2};
    }
  }
`;
