import React from "react";
import Select from "react-select";
import selectStyles from "../vendor/react-select/styles-alt";

const SelectDropdown = React.forwardRef(({ short = false, ...props }, ref) => {
  return (
    <Select
      ref={ref}
      styles={{
        ...selectStyles,
      }}
      isShort={short}
      {...props}
    />
  );
});

export default SelectDropdown;
