import React from "react";
import styled from "styled-components";

const ClockIcon = ({ ...props }) => {
  return (
    <Svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4923 4.86316V2.85895H14.8628C15.6852 2.85895 16.3248 2.21053 16.3248 1.44421C16.3248 0.677895 15.6852 0 14.8933 0H9.16745C8.34512 0 7.70553 0.648421 7.70553 1.41474C7.70553 2.18105 8.37558 2.82947 9.16745 2.82947H10.538V4.86316C4.59896 5.57053 0 10.4632 0 16.3874C0 22.7832 5.39083 28 11.9999 28C18.609 28 23.9998 22.7832 23.9998 16.3874C24.0303 10.4632 19.4313 5.57053 13.4923 4.86316ZM12.0304 25.1705C7.03549 25.1705 2.9543 21.2211 2.9543 16.3874C2.9543 11.5537 7.03549 7.60421 12.0304 7.60421C17.0253 7.60421 21.1065 11.5537 21.1065 16.3874C21.1065 21.2211 17.0253 25.1705 12.0304 25.1705Z"
        fill="black"
      />
      <path
        d="M16.416 14.9715H13.4922V11.3757C13.4922 10.5799 12.8222 9.96094 12.0303 9.96094C11.2384 9.96094 10.5684 10.6094 10.5684 11.3757V16.3862C10.5684 17.182 11.2384 17.8009 12.0303 17.8009H16.416C17.2384 17.8009 17.878 17.1525 17.878 16.3862C17.9084 15.5904 17.2384 14.9715 16.416 14.9715Z"
        fill="black"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default ClockIcon;
