import React from "react";
import styled from "styled-components";

const UploadIcon = ({ ...props }) => {
  return (
    <Svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19995 15.3001C3.19995 15.0614 3.29477 14.8325 3.46356 14.6637C3.63234 14.4949 3.86126 14.4001 4.09995 14.4001H14.9C15.1386 14.4001 15.3676 14.4949 15.5363 14.6637C15.7051 14.8325 15.8 15.0614 15.8 15.3001C15.8 15.5388 15.7051 15.7677 15.5363 15.9365C15.3676 16.1053 15.1386 16.2001 14.9 16.2001H4.09995C3.86126 16.2001 3.63234 16.1053 3.46356 15.9365C3.29477 15.7677 3.19995 15.5388 3.19995 15.3001ZM6.16365 6.0364C5.99493 5.86763 5.90014 5.63875 5.90014 5.4001C5.90014 5.16145 5.99493 4.93258 6.16365 4.7638L8.86365 2.0638C9.03243 1.89508 9.2613 1.80029 9.49995 1.80029C9.7386 1.80029 9.96748 1.89508 10.1363 2.0638L12.8363 4.7638C13.0002 4.93354 13.0909 5.16088 13.0889 5.39686C13.0868 5.63284 12.9922 5.85857 12.8253 6.02544C12.6584 6.1923 12.4327 6.28696 12.1967 6.28901C11.9607 6.29106 11.7334 6.20034 11.5637 6.0364L10.4 4.8727V11.7001C10.4 11.9388 10.3051 12.1677 10.1363 12.3365C9.96756 12.5053 9.73865 12.6001 9.49995 12.6001C9.26126 12.6001 9.03234 12.5053 8.86356 12.3365C8.69477 12.1677 8.59995 11.9388 8.59995 11.7001V4.8727L7.43625 6.0364C7.26748 6.20512 7.0386 6.29991 6.79995 6.29991C6.5613 6.29991 6.33243 6.20512 6.16365 6.0364Z"
        fill="#374151"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    transition: 0.2s ease-in-out;
  }
`;

export default UploadIcon;
