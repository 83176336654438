import React from "react";

import { useNotifications } from "../../context/notificationsContext";

import AlertView from "./AlertView";

const Alert = () => {
  const {
    isAlertOpen,
    alertHeader,
    alertMessage,
    closeAlertPopup,
  } = useNotifications();

  return (
    <AlertView
      title={alertHeader}
      message={alertMessage}
      isOpen={isAlertOpen}
      handleClose={closeAlertPopup}
    />
  );
};

export default Alert;
