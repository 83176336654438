import React from "react";
import styled from "styled-components";

const Toggle = ({ label, client, ...props }) => {
  return (
    <ToggleTrigger client={client}>
      {label && <span>{label}</span>}
      <input {...props} />
      <span className="toggle"></span>
    </ToggleTrigger>
  );
};

export const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  width: ${(props) => (props.width ? props.width : "300px")};
  min-height: 55px;

  color: #373040;
  background-color: ${(props) => (props.disabled ? "#fafbf9" : "white")};
  border: 1px solid #dae1f5;
  border-radius: 3px;

  font-size: 14px;

  outline: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  * {
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  }

  [disabled] & {
    background-color: #fafbf9;
    border: 1px solid #dae1f5;
    cursor: no-drop;
  }
`;

const ToggleTrigger = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  padding: 0 15px;
  margin-bottom: 10px;

  cursor: pointer;
  user-select: none;

  [disabled] & {
    cursor: no-drop;
  }

  &:first-child {
    padding-top: ${(props) => (props.client ? "0px" : "10px")};
    margin-bottom: ${(props) => (props.client ? "0px" : "10px")};
  }

  &:last-child {
    padding-bottom: ${(props) => (props.client ? "0px" : "10px")};
    margin-bottom: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .toggle {
      background-color: #554668;
    }
  }

  .toggle {
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 50%;
    transition: background-color 200ms ease;
    position: relative;
    flex-shrink: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid #8c8391;
    }
  }

  span {
    line-height: 1.25;
  }
`;

export default Toggle;
