import React, { useState, useEffect, useContext } from "react";
import { io } from "socket.io-client";

import { useAuth } from "../context/authContext";
import { useNotifications } from "../context/notificationsContext";

const SocketsContext = React.createContext();

// * This is the context used for both the alert popup
// * and the notifications in the sidebar

const SocketsProvider = (props) => {
  const { user, updateStatuses } = useAuth();
  const {
    openAlertPopup,
    closeAlertPopup,
    pushNewNotification,
  } = useNotifications();

  const [socket, setSocket] = useState(null);

  // When we start up, we create the socket connection with the server.
  useEffect(() => {
    if (user) {
      const newSocket = io(process.env.REACT_APP_SERVER_URL, {
        path: `/socket.io`,
        reconnect: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });

      setSocket(newSocket);
    }
  }, [user]);

  useEffect(() => {
    if (socket) {
      setupInitListeners(user._id);
    }
  }, [socket]); //eslint-disable-line

  // Creates the initial listeners that are used globally
  // (like on connect and disconnect)
  const setupInitListeners = (userId) => {
    socket.on("connect", () => {
      console.info("Socket connected");
      socket.emit("set_user", userId);
      closeAlertPopup();
    });

    socket.on("disconnect", () => {
      console.info("SOCKET DISCONNECTED");

      openAlertPopup(
        "Disconnected from server",
        "You are disconnected from the server. Your internet may be down or you may have to refresh the page. If this continues to happen, please contact the dev team.",
      );
    });

    // Pushes a notification going to this user
    socket.on(`user-${user._id}:new-notification`, (newNotification) => {
      pushNewNotification(newNotification);
    });

    // Pushes a notification going out to all users
    socket.on(`user:new-notification`, (newNotification) => {
      pushNewNotification(newNotification);
    });

    // When the member statuses change on server, we update accordingly
    socket.on(`user:new-statuses`, (newStatus) => {
      updateStatuses(newStatus);
    });
  };

  // When user is on a project page, this starts the associated listeners
  const startProjectListeners = (
    projectId,
    {
      commentCallback,
      commentEditCallback,
      commentDeleteCallback,
      expenseCallback,
      activeMembersCallback,
      phasesCallback,
    },
  ) => {
    if (projectId) {
      socket.emit(`project:joined`, projectId);

      if (commentCallback) {
        socket.on(`project-${projectId}:comment-posted`, (newComment) => {
          commentCallback(newComment);
        });
      }

      if (commentEditCallback) {
        socket.on(`project-${projectId}:comment-edited`, (updatedComment) => {
          commentEditCallback(updatedComment);
        });
      }
      if (commentDeleteCallback) {
        socket.on(`project-${projectId}:comment-deleted`, (comment) => {
          commentDeleteCallback(comment);
        });
      }

      if (expenseCallback) {
        socket.on(`project-${projectId}:expense-posted`, (newExpense) => {
          expenseCallback(newExpense);
        });
      }

      if (activeMembersCallback) {
        socket.on(
          `project-${projectId}:active-members-updated`,
          (newMembers) => {
            activeMembersCallback(newMembers);
          },
        );
      }

      if (phasesCallback) {
        socket.on(`project-${projectId}:phases-updated`, (phases) => {
          phasesCallback(phases);
        });
      }
    }
  };

  // When user leaves a project page, this stops the associated listeners
  const stopProjectListeners = (projectId) => {
    socket.emit(`project:left`, projectId);
    socket.off(`project-${projectId}:comment-posted`);
    socket.off(`project-${projectId}:comment-edited`);
    socket.off(`project-${projectId}:comment-deleted`);
    socket.off(`project-${projectId}:expense-posted`);
    socket.off(`project-${projectId}:active-members-updated`);
    socket.off(`project-${projectId}:phases-updated`);
  };

  const startProjectTaskListeners = (
    projectId,
    { taskCreateCallback, taskEditCallback, taskDeleteCallback },
  ) => {
    socket.on(`project-${projectId}:task-created`, (newTask) => {
      taskCreateCallback(newTask);
    });
    socket.on(`project-${projectId}:task-edited`, (updatedTask) => {
      taskEditCallback(updatedTask);
    });
    socket.on(`project-${projectId}:task-deleted`, (deletedTaskId) => {
      taskDeleteCallback(deletedTaskId);
    });
  };

  const stopProjectTaskListeners = (projectId) => {
    socket.off(`project-${projectId}:task-created`);
    socket.off(`project-${projectId}:task-edited`);
    socket.off(`project-${projectId}:task-deleted`);
  };

  // When user is on a project page, this starts the associated listeners
  const startFileListeners = (
    fileId,
    { fileCommentCallback, fileEditCommentCallback },
  ) => {
    socket.on(`file-${fileId}:comment-posted`, (newComment) => {
      fileCommentCallback(newComment);
    });

    socket.on(`file-${fileId}:comment-edited`, (comments) => {
      fileEditCommentCallback(comments);
    });
  };

  // When user leaves a project page, this stops the associated listeners
  const stopFileListeners = (fileId) => {
    socket.off(`file-${fileId}:comment-posted`);
    socket.off(`file-${fileId}:comment-edited`);
  };

  // When user is on a project page, this starts the associated listeners
  const startProjectDeliverableListeners = (
    projectId,
    {
      deliverableCreateCallback,
      deliverableEditCallback,
      deliverableDeleteCallback,
    },
  ) => {
    socket.on(`project-${projectId}:deliverable-created`, (newDeliverable) => {
      deliverableCreateCallback(newDeliverable);
    });

    socket.on(
      `project-${projectId}:deliverable-edited`,
      (updatedDeliverable) => {
        deliverableEditCallback(updatedDeliverable);
      },
    );

    socket.on(
      `project-${projectId}:deliverable-deleted`,
      (deletedDeliverableId) => {
        deliverableDeleteCallback(deletedDeliverableId);
      },
    );
  };

  // When user leaves a project page, this stops the associated listeners
  const stopProjectDeliverableListeners = (projectId) => {
    socket.off(`project-${projectId}:deliverable-created`);
    socket.off(`project-${projectId}:deliverable-edited`);
    socket.off(`project-${projectId}:deliverable-deleted`);
  };

  const startActiveTimerListeners = ({ refreshActiveTimerCallback }) => {
    if (socket) {
      socket.on(`user-${user._id}:active-timer-changed`, () => {
        refreshActiveTimerCallback();
      });
    }
  };

  const startEntriesListeners = ({ callback }) => {
    if (socket) {
      socket.on(`user-${user._id}:entries-changed`, () => {
        callback();
      });
    }
  };

  // When user leaves a project page, this stops the associated listeners
  const stopEntriesListeners = () => {
    socket.off(`user-${user._id}:entries-changed`);
  };

  // When user is authenticating with a third party service
  const startQuickbooksListener = (callback) => {
    if (socket) {
      socket.on(`quickbooks-auth:success`, () => {
        callback();
      });
    }
  };
  const stopQuickbooksListener = () => {
    if (socket) {
      socket.off(`quickbooks-auth:success`);
    }
  };

  // invoices
  const startInvoicesListener = (invoiceId, callback) => {
    if (socket) {
      socket.on(`invoice-${invoiceId}:comment-posted`, (newComment) => {
        callback(newComment);
      });
    }
  };
  const stopInvoicesListener = (invoiceId) => {
    if (socket) {
      socket.off(`invoice-${invoiceId}:comment-posted`);
    }
  };

  // Deals
  const startDealNotesListeners = (
    dealId,
    { blockCallback, unblockCallback, updateNotesCallback },
  ) => {
    if (socket) {
      socket.on(`deal-${dealId}:block-notes`, (newMember) => {
        blockCallback(newMember);
      });

      socket.on(`deal-${dealId}:unblock-notes`, (newMember) => {
        unblockCallback(newMember);
      });

      socket.on(`deal-${dealId}:update-notes`, ({ editor, notes }) => {
        updateNotesCallback(editor, notes);
      });
    }
  };
  const stopDealNotesListeners = (dealId) => {
    if (socket) {
      socket.off(`deal-${dealId}:block-notes`);
      socket.off(`deal-${dealId}:unblock-notes`);
    }
  };

  const startCompanyNotesListeners = (
    companyId,
    { blockCallback, unblockCallback, updateNotesCallback },
  ) => {
    if (socket) {
      socket.on(`company-${companyId}:block-notes`, (newMember) => {
        blockCallback(newMember);
      });

      socket.on(`company-${companyId}:unblock-notes`, (newMember) => {
        unblockCallback(newMember);
      });

      socket.on(`company-${companyId}:update-notes`, ({ editor, notes }) => {
        updateNotesCallback(editor, notes);
      });
    }
  };

  const stopCompanyNotesListeners = (companyId) => {
    if (socket) {
      socket.off(`company-${companyId}:block-notes`);
      socket.off(`company-${companyId}:unblock-notes`);
    }
  };

  // contacts
  const startContactNotesListeners = (
    contactId,
    { blockCallback, unblockCallback, updateNotesCallback },
  ) => {
    if (socket) {
      socket.on(`contact-${contactId}:block-notes`, (newMember) => {
        blockCallback(newMember);
      });

      socket.on(`contact-${contactId}:unblock-notes`, (newMember) => {
        unblockCallback(newMember);
      });

      socket.on(`contact-${contactId}:update-notes`, ({ editor, notes }) => {
        updateNotesCallback(editor, notes);
      });
    }
  };

  const stopContactNotesListeners = (contactId) => {
    if (socket) {
      socket.off(`contact-${contactId}:block-notes`);
      socket.off(`contact-${contactId}:unblock-notes`);
    }
  };

  // Tasks
  const startTaskNotesListeners = (
    taskId,
    { blockCallback, unblockCallback, updateNotesCallback },
  ) => {
    if (socket) {
      socket.on(`task-${taskId}:block-notes`, (newMember) => {
        blockCallback(newMember);
      });

      socket.on(`task-${taskId}:unblock-notes`, (newMember) => {
        unblockCallback(newMember);
      });

      socket.on(`task-${taskId}:update-notes`, ({ editor, notes }) => {
        updateNotesCallback(editor, notes);
      });
    }
  };
  const stopTaskNotesListeners = (taskId) => {
    if (socket) {
      socket.off(`task-${taskId}:block-notes`);
      socket.off(`task-${taskId}:unblock-notes`);
    }
  };

  // Lets other components send out custom socket events
  const sendSocketEvent = (eventName, data) => {
    if (socket) {
      socket.emit(eventName, data);
    }
  };

  return (
    <SocketsContext.Provider
      value={{
        socket,
        sendSocketEvent,

        startProjectListeners,
        stopProjectListeners,

        startProjectTaskListeners,
        stopProjectTaskListeners,

        startFileListeners,
        stopFileListeners,

        startProjectDeliverableListeners,
        stopProjectDeliverableListeners,

        startActiveTimerListeners,

        startEntriesListeners,
        stopEntriesListeners,

        startQuickbooksListener,
        stopQuickbooksListener,

        startInvoicesListener,
        stopInvoicesListener,

        startDealNotesListeners,
        stopDealNotesListeners,

        startCompanyNotesListeners,
        stopCompanyNotesListeners,

        startContactNotesListeners,
        stopContactNotesListeners,

        startTaskNotesListeners,
        stopTaskNotesListeners,
      }}
      {...props}
    >
      {props.children}
    </SocketsContext.Provider>
  );
};

const useSockets = () => useContext(SocketsContext);

export { SocketsProvider, useSockets, SocketsContext };
