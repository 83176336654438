import React from "react";
import styled from "styled-components";

const EditIcon = () => {
  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.414 2.58606C17.0389 2.21112 16.5303 2.00049 16 2.00049C15.4697 2.00049 14.9611 2.21112 14.586 2.58606L7 10.1721V13.0001H9.828L17.414 5.41406C17.7889 5.03901 17.9996 4.53039 17.9996 4.00006C17.9996 3.46973 17.7889 2.96112 17.414 2.58606Z"
        fill="#A1A1AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4H8C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5C9 5.26522 8.89464 5.51957 8.70711 5.70711C8.51957 5.89464 8.26522 6 8 6H4V16H14V12C14 11.7348 14.1054 11.4804 14.2929 11.2929C14.4804 11.1054 14.7348 11 15 11C15.2652 11 15.5196 11.1054 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H4C3.46957 18 2.96086 17.7893 2.58579 17.4142C2.21071 17.0391 2 16.5304 2 16V6Z"
        fill="#A1A1AA"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  display: inline-block;
`;

export default EditIcon;
