import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Textarea } from "../../../components/Form";
import Button from "../../../components/buttons/Button";
import Accordion from "./Accordion";

const BriefForm = ({ brief, projectBriefQuestions, onSave }) => {
  const [questions, setQuestions] = useState([]);
  const tempQuestions = [];

  useEffect(() => {
    // Set the questions if they have been filled out
    if (brief) {
      // Loop through each of the brief questions that were set in the company settings
      projectBriefQuestions.forEach((item) => {
        const tempItem = { q: item._id };

        if (brief instanceof Array) {
          // Check to see if there's a question in the results that has the same id as the brief
          brief.forEach((result) => {
            if (item._id === result.q) {
              tempItem.a = result.a;
            }
          });
        }

        tempQuestions.push(tempItem);
      });

      setQuestions(tempQuestions);
    }
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Update client brief answers
  |--------------------------------------------------------------------------
  */
  const updateAnswer = (index, answer) => {
    const newQuestions = [...questions];
    newQuestions[index].a = answer;
    setQuestions(newQuestions);
  };

  return (
    <Container>
      <AccordionList>
        {questions &&
          questions.length &&
          questions.map((question, index) => {
            return (
              <Accordion
                key={question.q}
                header={
                  projectBriefQuestions.find((item) => item._id === question.q)
                    .question
                }
              >
                <Textarea
                  style={{ width: "100%" }}
                  value={question.a}
                  onChange={(e) => updateAnswer(index, e.target.value)}
                />
              </Accordion>
            );
          })}
      </AccordionList>

      {onSave ? (
        <Actions>
          <Button onClick={() => onSave(questions)}>Save Brief</Button>
        </Actions>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  width: 1100px;
  flex-grow: 0;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const AccordionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px 30px;
`;

BriefForm.propTypes = {
  brief: PropTypes.array,
  onSave: PropTypes.func,
  disabled: PropTypes.bool,
};
BriefForm.defaultProps = {
  brief: {},
  onSave: null,
  disabled: false,
};

export default BriefForm;
