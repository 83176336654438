import React from "react";

const TrendingDownIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60003 10.3999C9.38786 10.3999 9.18438 10.4842 9.03435 10.6342C8.88432 10.7843 8.80003 10.9878 8.80003 11.1999C8.80003 11.4121 8.88432 11.6156 9.03435 11.7656C9.18438 11.9156 9.38786 11.9999 9.60003 11.9999H13.6C13.8122 11.9999 14.0157 11.9156 14.1657 11.7656C14.3157 11.6156 14.4 11.4121 14.4 11.1999V7.19994C14.4 6.98776 14.3157 6.78428 14.1657 6.63425C14.0157 6.48422 13.8122 6.39994 13.6 6.39994C13.3879 6.39994 13.1844 6.48422 13.0343 6.63425C12.8843 6.78428 12.8 6.98776 12.8 7.19994V9.26874L9.36563 5.83434C9.21561 5.68436 9.01216 5.60011 8.80003 5.60011C8.5879 5.60011 8.38446 5.68436 8.23443 5.83434L6.40003 7.66874L2.96563 4.23434C2.81475 4.08861 2.61267 4.00797 2.40291 4.0098C2.19315 4.01162 1.9925 4.09575 1.84418 4.24408C1.69585 4.39241 1.61172 4.59306 1.60989 4.80281C1.60807 5.01257 1.68871 5.21465 1.83443 5.36554L5.83443 9.36554C5.98446 9.51551 6.1879 9.59976 6.40003 9.59976C6.61216 9.59976 6.81561 9.51551 6.96563 9.36554L8.80003 7.53113L11.6688 10.3999H9.60003Z"
        fill="#F43F5E"
      />
    </svg>
  );
};

export default TrendingDownIcon;
