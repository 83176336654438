import React from "react";
import styled from "styled-components";

const SalesIcon = ({ ...rest }) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.0004 21.6004C17.3023 21.6004 21.6004 17.3023 21.6004 12.0004C21.6004 6.69846 17.3023 2.40039 12.0004 2.40039C6.69846 2.40039 2.40039 6.69846 2.40039 12.0004C2.40039 17.3023 6.69846 21.6004 12.0004 21.6004Z"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1998 8H11.1109C9.83447 8 8.7998 8.89543 8.7998 10C8.7998 11.1046 9.83447 12 11.1109 12H12.8887C14.1651 12 15.1998 12.8954 15.1998 14C15.1998 15.1046 14.1651 16 12.8887 16H8.7998"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.40039V8.00039"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V17.6"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default SalesIcon;
