import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import { createClientOnApi, getMembersFromApi } from "../utils/api";
import { getSettings } from "../../../utils/api";
import { useNotifications } from "../../../context/notificationsContext";

import Button from "../../../components/buttons/Button";
import { Field, Label, Input } from "../../../components/Form";
import SelectDropdown from "../../../components/SelectDropdown";

// TODO: consider using a single view for clientCreate and clientEdit, since they share so much code

const ClientCreate = () => {
  const { openAlertPopup } = useNotifications();
  const history = useHistory();

  const [clientName, setClientName] = useState("");
  const [acronym, setAcronym] = useState("");
  const [tier, setTier] = useState(null);
  const [availProducers, setAvailProducers] = useState([]);
  const [availPartners, setAvailPartners] = useState([]);
  const [members, setMembers] = useState([]);
  const [accountingId, setAccountingId] = useState(null);
  const [invoiceApprovers, setInvoiceApprovers] = useState([]);
  const [status, setStatus] = useState(null);
  const [harvestId, setHarvestId] = useState("");
  const [partner, setPartner] = useState(null);
  const [producer, setProducer] = useState(null);
  const [accountOwner, setAccountOwner] = useState(null);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [googleSheet, setGoogleSheet] = useState("");
  const [googleFolder, setGoogleFolder] = useState("");
  const [productionGoogleFolder, setProductionGoogleFolder] = useState("");

  const [page, setPage] = useState(1);
  const [slackMembers, setSlackMembers] = useState(null);

  // ? retainers are now handled on contracts
  // const [retainers, setRetainers] = useState([]);

  const [questions, setQuestions] = useState([]);
  const [briefQuestions, setBriefQuestions] = useState([]);

  const tiers = ["Tier 1", "Tier 2", "Tier 3"];

  const statuses = [
    "New",
    "Target",
    "Contacted",
    "Prospect",
    "Open Deal",
    "Client",
    "Abandoned",
    "Referral Partner",
    "Past Client",
    "Vendor",
  ];

  useEffect(() => {
    getMembers();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Get producer and partner from list of slack users
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members.length && (producer || partner || accountOwner)) {
      const slackMembers = members
        .filter(
          (member) =>
            (member._id === producer ||
              member._id === partner ||
              member._id === accountOwner) &&
            member.slackId !== "U9AP4MKGA",
        )
        .join(",");

      setSlackMembers(slackMembers);
    }
  }, [members, partner, producer, accountOwner]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get client brief questions from company settings
  |--------------------------------------------------------------------------
  */
  const getQuestions = async () => {
    try {
      const settings = await getSettings();

      if (settings && settings.clientBrief && settings.clientBrief.length) {
        const tempQuestions = [];
        settings.clientBrief.forEach((brief) => {
          tempQuestions.push({
            q: brief._id,
            a: "",
          });
        });
        setBriefQuestions(settings.clientBrief);
        setQuestions(tempQuestions);
      }
    } catch (error) {
      console.error("Error getting questions", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Set up members, clients
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getMembers();
    getSettings();
    getQuestions();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Get producer and partner from list of slack users
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members && (producer || partner || accountOwner)) {
      let slackMembers = [];

      members.forEach((member) => {
        if (
          (member._id === producer ||
            member._id === partner ||
            member._id === accountOwner) &&
          member.slackId !== "U9AP4MKGA"
        ) {
          slackMembers.push(member.slackId);
        }
      });

      slackMembers = slackMembers.join(",");
      setSlackMembers(slackMembers);
    }
  }, [members, partner, producer, accountOwner]);

  /*
  |--------------------------------------------------------------------------
  * Create a channel in slack
  |--------------------------------------------------------------------------
  */
  const createChannel = async () => {
    let channelName = `client-${acronym}`;

    channelName = channelName.replace(/\s+/g, "-").toLowerCase();

    try {
      const response = await axios.post(
        `https://slack.com/api/conversations.create?token=${process.env.REACT_APP_SLACK_ACCESS_TOKEN}&name=${channelName}`,
      );

      return response.data.channel.id;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all values in state and sends to the API to save
  |--------------------------------------------------------------------------
  */
  const addClient = async () => {
    try {
      const data = {
        name: clientName,
        acronym,
        tier,
        status,
        pointOfContact: {
          name: contactName,
          email: contactEmail,
        },
        accountOwner,
        partner,
        producer,
        harvestId,
        questions,
        // retainers,
        startDate,
        endDate,
        googleSheet,
        googleFolder,
        productionGoogleFolder,
        accountingId,
        invoiceApprovers,
      };

      await createClientOnApi(data);

      openAlertPopup("Success", "Client has been created successfully", true);

      try {
        // ? would be nice to not create the channel again if project was already planned, but might not be a big deal right now.
        // (we can't just not run this if the project has already been planned, because a new user could have been added to the plan, so we would want to add them to the slack channel as well)

        if (process.env.REACT_APP_ENVIRONMENT !== "development") {
          const channelId = await createChannel();

          await axios.post(
            `https://slack.com/api/conversations.invite?token=${process.env.REACT_APP_SLACK_ACCESS_TOKEN}&channel=${channelId}&users=${slackMembers}`,
          );
        }
      } catch (err) {
        console.error(err);
        throw "Failed to create slack channel";
      }

      history.push(`/manage/clients`);
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "";
      openAlertPopup(
        "Failure",
        `Sorry, we couldn't create the client. ${errorMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      // to be a producer, the user must have a role that includes the following text:
      const producers = membersFromApi.filter(
        (member) =>
          member.role.role.toLowerCase().includes("production manager") ||
          member.role.role.toLowerCase().includes("partnership manager"),
      );

      // to be a partner, the user must have a role that includes the following text:
      const partners = membersFromApi.filter((member) =>
        member.role.role.toLowerCase().includes("partnership manager"),
      );

      setAvailProducers(producers);
      setAvailPartners(partners);
      setMembers(membersFromApi);
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update client brief answers
  |--------------------------------------------------------------------------
  */
  const updateAnswer = (index, answer) => {
    const newQuestions = [...questions];
    newQuestions[index].a = answer;
    setQuestions(newQuestions);
  };

  /*
  |--------------------------------------------------------------------------
  | Retainers
  |--------------------------------------------------------------------------
  */
  // const addRetainer = () => {
  //   let tempRetainers = [...retainers];
  //   tempRetainers.push({});

  //   setRetainers(tempRetainers);
  // };

  // const setRetainerStartDate = (startDate, index) => {
  //   let tempRetainers = [...retainers];
  //   tempRetainers[index].startDate = startDate;
  //   setRetainers(tempRetainers);
  // };

  // const setRetainerEndDate = (endDate, index) => {
  //   let tempRetainers = [...retainers];
  //   tempRetainers[index].endDate = endDate;
  //   setRetainers(tempRetainers);
  // };

  // const setRetainerAmount = (amount, index) => {
  //   let tempRetainers = [...retainers];
  //   tempRetainers[index].amount = amount;
  //   setRetainers(tempRetainers);
  // };

  // const deleteRetainer = (index) => {
  //   let tempRetainers = [...retainers];
  //   tempRetainers.splice(index, 1);
  //   setRetainers(tempRetainers);
  // };

  return (
    <>
      <ManageHeader>
        <h1>New Client</h1>
        <p>Fill out this one form to rapidly set everything up.</p>
      </ManageHeader>

      <ClientSetup>
        {page === 1 ? (
          <ClientDetails>
            <Heading>Client Details</Heading>
            <FlexContainer>
              <div>
                <Field>
                  <Label>Client Name</Label>
                  <Input
                    name="clientName"
                    type="text"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                    required
                  />
                </Field>
                <Field>
                  <Label>Client Acronym</Label>
                  <Input
                    name="clientAcronym"
                    type="text"
                    value={acronym}
                    onChange={(e) => setAcronym(e.target.value.toUpperCase())}
                    required
                  />
                </Field>
                <Field>
                  <Label>Client Status</Label>
                  <StyledSelectDropdown
                    id="status"
                    onChange={(e) => {
                      setStatus(e.value);
                    }}
                    placeholder="Select Status"
                    options={statuses.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  ></StyledSelectDropdown>
                </Field>
                <Field>
                  <Label>Client Tier</Label>
                  <StyledSelectDropdown
                    id="tier"
                    onChange={(e) => {
                      setTier(e.value);
                    }}
                    placeholder="Select Tier"
                    options={tiers.map((option) => ({
                      label: option,
                      value: option,
                    }))}
                  ></StyledSelectDropdown>
                </Field>
                <Field>
                  <Label>Relationship Owner</Label>
                  <StyledSelectDropdown
                    id="accountOwner"
                    onChange={(e) => {
                      setAccountOwner(e.value);
                    }}
                    placeholder="Select Member"
                    options={members.map((option) => ({
                      label: option.name,
                      value: option._id,
                    }))}
                  />
                </Field>
                <Field>
                  {/* Partner */}
                  <Label>Partnership Manager</Label>
                  <StyledSelectDropdown
                    id="partners"
                    onChange={(e) => {
                      setPartner(e.value);
                    }}
                    placeholder="Select Member"
                    options={availPartners.map((option) => ({
                      label: option.name,
                      value: option._id,
                    }))}
                  />
                </Field>
                <Field>
                  {/* Producer */}
                  <Label>Production Manager</Label>
                  <StyledSelectDropdown
                    id="producers"
                    onChange={(e) => setProducer(e.value)}
                    placeholder="Select Member"
                    options={availProducers.map((option) => ({
                      label: option.name,
                      value: option._id,
                    }))}
                  />
                </Field>
                <Field>
                  <Label>Client Start Date</Label>
                  <Input
                    name="startDate"
                    type="date"
                    placeholder=""
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Field>
                <Field>
                  <Label>Client End Date</Label>
                  <Input
                    name="endDate"
                    type="date"
                    placeholder=""
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </Field>

                <Field>
                  <Label>Account Summary Google Sheet Link</Label>
                  <Input
                    name="googleSheet"
                    type="text"
                    value={googleSheet}
                    onChange={(e) => setGoogleSheet(e.target.value)}
                  />
                </Field>
                <Field>
                  <Label>Client Delivery Google Folder Link</Label>
                  <Input
                    name="googleFolder"
                    type="text"
                    value={googleFolder}
                    onChange={(e) => setGoogleFolder(e.target.value)}
                  />
                </Field>
                <Field>
                  <Label>Production Google Folder Link</Label>
                  <Input
                    name="productionGoogleFolder"
                    type="text"
                    value={productionGoogleFolder}
                    onChange={(e) => setProductionGoogleFolder(e.target.value)}
                  />
                </Field>

                <Field>
                  <Label>Harvest Id</Label>
                  <Input
                    name="harvest"
                    type="text"
                    placeholder="(optional)"
                    value={harvestId}
                    onChange={(e) => setHarvestId(e.target.value)}
                  />
                </Field>

                <Subheading>Primary Client Contact</Subheading>
                <Field>
                  <Label>Name</Label>
                  <Input
                    name="contactName"
                    type="text"
                    placeholder=""
                    value={contactName}
                    required
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Field>
                <Field>
                  <Label>Email</Label>
                  <Input
                    name="contactEmail"
                    type="text"
                    placeholder=""
                    value={contactEmail}
                    required
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </Field>

                <Subheading>Billing</Subheading>

                <Field>
                  <Label>QuickBooks ID</Label>
                  <Input
                    name="accountingId"
                    type="text"
                    value={accountingId}
                    onChange={(e) => setAccountingId(e.target.value)}
                    required
                  />
                </Field>

                <Field>
                  <Label>Invoice Approvers</Label>
                  <StyledSelectDropdown
                    id="invoiceApprovers"
                    onChange={(e) => {
                      const approvers = e || [];
                      const newApprovers = approvers.map(
                        (approver) => approver.value,
                      );
                      setInvoiceApprovers(newApprovers);
                    }}
                    placeholder="Select Approvers"
                    options={members.map((option) => ({
                      label: option.name,
                      value: option._id,
                    }))}
                    isMulti
                  />
                </Field>

                {/* <Field>
                  <Label>Rates</Label>
                  <Input
                    name="rates"
                    type="text"
                    placeholder="Select Rate Table"
                  />
                </Field> */}
              </div>

              {/* <div>
                <Subheading style={{ marginTop: "0px" }}>Retainers</Subheading>
                <RetainerContainer>
                  {retainers.map((retainer, index) => {
                    return (
                      <Retainer key={index}>
                        <RetainerHeader>
                          <RetainerTitle>Retainer {index + 1}</RetainerTitle>
                          <RetainerDelete onClick={() => deleteRetainer(index)}>
                            Delete
                          </RetainerDelete>
                        </RetainerHeader>
                        <RetainerField>
                          <Label>Start Date</Label>
                          <RetainerInput
                            name="retainerStartDate"
                            type="date"
                            placeholder=""
                            value={retainer.startDate}
                            onChange={(e) =>
                              setRetainerStartDate(e.target.value, index)
                            }
                          />
                        </RetainerField>
                        <RetainerField>
                          <Label>End Date</Label>
                          <RetainerInput
                            name="retainerEndDate"
                            type="date"
                            placeholder=""
                            value={retainer.endDate}
                            onChange={(e) =>
                              setRetainerEndDate(e.target.value, index)
                            }
                          />
                        </RetainerField>
                        <RetainerField>
                          <Label>Amount</Label>
                          <RetainerInput
                            name="retainerEndDate"
                            type="text"
                            placeholder="$420"
                            value={retainer.amount}
                            onChange={(e) =>
                              setRetainerAmount(e.target.value, index)
                            }
                          />
                        </RetainerField>
                      </Retainer>
                    );
                  })}
                  <CreateLink
                    onClick={() => {
                      addRetainer();
                    }}
                  >
                    Add a Retainer
                  </CreateLink>
                </RetainerContainer>
              </div> */}
            </FlexContainer>
          </ClientDetails>
        ) : page === 2 ? (
          <ClientBrief>
            <Heading>Client Brief</Heading>
            {briefQuestions &&
              briefQuestions.length &&
              questions &&
              questions.length &&
              questions.map((question, index) => {
                return (
                  <Field key={index}>
                    <Label>
                      {
                        briefQuestions.find((item) => item._id === question.q)
                          .question
                      }
                    </Label>
                    <Input
                      name={`question${index}`}
                      type="text"
                      placeholder=""
                      value={question.a}
                      onChange={(e) => updateAnswer(index, e.target.value)}
                    />
                  </Field>
                );
              })}
          </ClientBrief>
        ) : null}
      </ClientSetup>

      <SaveContainer>
        {page === 1 ? (
          <Continue
            onClick={() => setPage(2)}
            disabled={
              !(
                clientName &&
                tier &&
                status &&
                acronym &&
                producer &&
                partner &&
                accountOwner &&
                contactName &&
                contactEmail &&
                startDate
              )
            }
          >
            Continue
          </Continue>
        ) : page === 2 ? (
          <ButtonRow>
            <Back onClick={() => setPage(1)}>Back</Back>

            <Button
              onClick={() => addClient()}
              disabled={
                !(
                  clientName &&
                  acronym &&
                  tier &&
                  status &&
                  producer &&
                  partner &&
                  accountOwner &&
                  contactName &&
                  contactEmail
                )
              }
            >
              Save
            </Button>
          </ButtonRow>
        ) : null}
      </SaveContainer>
    </>
  );
};

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 40px;
`;

const Subheading = styled.h4`
  font-size: 16px;
  color: #373040;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const ManageHeader = styled.div`
  padding: 40px 50px;
  border-bottom: 1px solid #cec9d4;
  margin-bottom: 30px;

  h1 {
    font-size: 28px;
    color: #373040;
    margin-bottom: 10px;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    color: #373040;
    margin-bottom: 0px;
  }
`;

const ClientSetup = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 0px 50px;

  input,
  textarea,
  label {
    width: 400px;
  }
`;

const ClientDetails = styled.div``;

const ClientBrief = styled.div``;

const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 900px;
  margin: 50px 0px 100px 50px;
`;

// const RetainerContainer = styled.div`
//   width: 400px;
//   margin-top: 30px;
// `;

// const Retainer = styled.div`
//   margin-bottom: 40px;
// `;

// const RetainerHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   margin-bottom: 1rem;
// `;

// const RetainerDelete = styled.p`
//   margin: 0px;
//   color: #df5757;
//   font-weight: bold;
//   font-size: 12px;
//   cursor: pointer;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const RetainerTitle = styled.p`
//   font-weight: 500;
//   margin-bottom: 0px;
// `;

// const RetainerField = styled(Field)`
//   flex-direction: column;
//   align-items: flex-start;
// `;

// const RetainerInput = styled(Input)`
//   margin-top: 5px;
// `;

const Continue = styled(Button)``;

const Back = styled(Button)`
  margin-right: 50px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 400px;
`;

export default ClientCreate;
