import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Editable from "../../../components/Editable";
import { respondTo } from "../../../styles/styleHelpers";
import { Input } from "../../../components/newForm";

const CompanyDetails = ({
  id,
  handleEdit,
  name,
  code,
  tier,
  t1type,
  industry,
  industries,
  website,
  address,
  parentCompany,
  stockTicker,
  marketCap,
  annualRevenue,
  description,
  timezone,
  availTimezones,
  products,
  social,
  createIndustry,
}) => {
  const [editProducts, setEditProducts] = useState([]);
  const [editSocial, setEditSocial] = useState([]);

  useEffect(() => {
    if (products) {
      setEditProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (social) {
      setEditSocial(social);
    }
  }, [social]);

  const handleProductRemove = (removeIndex) => {
    const newItems = editProducts.filter((product, i) => i !== removeIndex);
    setEditProducts(newItems);
  };

  const handleAddNewProduct = () => {
    const newItems = [...editProducts, ""];
    setEditProducts(newItems);
  };

  const handleSocialRemove = (removeIndex) => {
    const newItems = editSocial.filter((product, i) => i !== removeIndex);
    setEditSocial(newItems);
  };

  const handleAddNewSocial = () => {
    const newItems = [...editSocial, ""];
    setEditSocial(newItems);
  };

  return (
    <Container>
      <HeaderRow>
        <Header>Company Details</Header>
      </HeaderRow>
      <StatsMain>
        <StatsCol>
          <Stat>
            <Label>Company Name</Label>
            <Value>
              <Editable
                id={`${id}-name`}
                onSave={(value) => {
                  handleEdit({ name: value });
                }}
              >
                {name}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Tier</Label>
            <Value>
              <Editable
                id={`${id}-tier`}
                onSave={(value) => {
                  handleEdit({ tier: value });
                }}
                options={[
                  { label: "Tier 1", value: "Tier 1" },
                  { label: "Tier 2", value: "Tier 2" },
                  { label: "Tier 3", value: "Tier 3" },
                ]}
                defaultValue={
                  tier
                    ? {
                        label: tier,
                        value: tier,
                      }
                    : undefined
                }
              >
                <ValueColor color="teal">{tier}</ValueColor>
              </Editable>
            </Value>
          </Stat>
          <Break />
          <Stat>
            <Label>Address</Label>
            <Value>{address}</Value>
          </Stat>
          <Stat>
            <Label>T1Type</Label>
            <Value>
              <Editable
                id={`${id}-t1type`}
                onSave={(value) => {
                  handleEdit({ t1type: value });
                }}
              >
                {t1type || "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Social Media Channels:</Label>
            <Value>
              <Editable
                id={`${id}-social`}
                onSave={(newValues) => {
                  const newItems = Object.values(newValues);
                  handleEdit({ social: newItems }, "company", "info");
                }}
                edit={(editRef) => (
                  <>
                    {editSocial
                      ? editSocial.map((item, itemIndex) => (
                          <InputRow key={itemIndex}>
                            <Input
                              ref={(el) => (editRef[itemIndex] = el)}
                              defaultValue={item}
                            />
                            <RemoveButton
                              onClick={() => handleSocialRemove(itemIndex)}
                            >
                              Remove
                            </RemoveButton>
                          </InputRow>
                        ))
                      : null}

                    <AddButton onClick={handleAddNewSocial}>Add +</AddButton>
                  </>
                )}
                iconClickOnly
              >
                {editSocial && editSocial.length > 0
                  ? editSocial.map((item) => (
                      <Item>
                        <ExLink href={item} target="_blank">
                          {item} <LinkIcon />
                        </ExLink>
                      </Item>
                    ))
                  : "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Website</Label>
            <Value>
              <Editable
                id={`${id}-company-website`}
                onSave={(value) => {
                  handleEdit({ website: value }, "company", "info");
                }}
                defaultValue={website}
                iconClickOnly
              >
                {website ? (
                  <ExLink href={website} target="_blank">
                    {website} <LinkIcon />
                  </ExLink>
                ) : (
                  "N/A"
                )}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Annual Revenue:</Label>
            <Value>
              <Editable
                id={`${id}-annualRevenue`}
                onSave={(value) => {
                  handleEdit({ annualRevenue: value }, "company", "info");
                }}
              >
                {annualRevenue}
              </Editable>
            </Value>
          </Stat>
          <Stat newLine>
            <Label>Description:</Label>
            <Value left>
              <Editable
                id={`${id}-description`}
                onSave={(value) => {
                  handleEdit({ description: value }, "company", "info");
                }}
              >
                {description}
              </Editable>
            </Value>
          </Stat>
        </StatsCol>
        <StatsCol>
          <Stat>
            <Label>Company Code</Label>
            <Value>
              <Editable
                id={`${id}-company-code`}
                onSave={(value) => {
                  handleEdit({ acronym: value });
                }}
                defaultValue={code || ""}
              >
                {code || <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Industry</Label>
            <Value>
              <Editable
                id={`${id}-industry`}
                creatable
                handleDropdownCreate={(value) => {
                  createIndustry(value);
                  handleEdit({ industry: value }, "company");
                }}
                options={
                  industries?.length
                    ? industries.map((industry) => ({
                        label: industry,
                        value: industry,
                      }))
                    : null
                }
                onSave={
                  handleEdit
                    ? (value) => {
                        handleEdit({ industry: value });
                      }
                    : undefined
                }
                defaultValue={industry || ""}
              >
                {industry ? (
                  <ValueColor color="blue">{industry}</ValueColor>
                ) : (
                  "N/A"
                )}
              </Editable>
            </Value>
          </Stat>
          <Break />
          <Stat>
            <Label>Parent Company</Label>
            <Value>
              <Editable
                id={`${id}-parent`}
                onSave={(value) => {
                  handleEdit({ parentCompany: value }, "company", "info");
                }}
              >
                {parentCompany || "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Location & Time Zone</Label>
            <Value>
              <Editable
                id={`${id}-timezone`}
                onSave={(value) => {
                  handleEdit({ timezone: value }, "company", "info");
                }}
                options={availTimezones.map((zone) => ({
                  label: zone,
                  value: zone,
                }))}
              >
                {timezone || "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Products/Services:</Label>
            <Value>
              <Editable
                id={`${id}-products`}
                onSave={(newValues) => {
                  const newItems = Object.values(newValues);
                  handleEdit({ products: newItems }, "company", "info");
                }}
                edit={(editRef) => (
                  <>
                    {editProducts
                      ? editProducts.map((item, itemIndex) => (
                          <InputRow key={itemIndex}>
                            <Input
                              ref={(el) => (editRef[itemIndex] = el)}
                              defaultValue={item}
                            />
                            <RemoveButton
                              onClick={() => handleProductRemove(itemIndex)}
                            >
                              Remove
                            </RemoveButton>
                          </InputRow>
                        ))
                      : null}

                    <AddButton onClick={handleAddNewProduct}>Add +</AddButton>
                  </>
                )}
              >
                {editProducts && editProducts.length > 0
                  ? editProducts.map((item) => <Item>{item}</Item>)
                  : "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Stock Ticker</Label>
            <Value>
              <Editable
                id={`${id}-ticker`}
                onSave={(value) => {
                  handleEdit({ stockTicker: value }, "company", "info");
                }}
              >
                {stockTicker || "N/A"}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Market Cap</Label>
            <Value>
              <Editable
                id={`${id}-market-cap`}
                onSave={(value) => {
                  handleEdit({ marketCap: value }, "company", "info");
                }}
              >
                {marketCap || "N/A"}
              </Editable>
            </Value>
          </Stat>
        </StatsCol>
      </StatsMain>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray100};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Header = styled.h4`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Label = styled.p`
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray400};
  width: 75px;

  ${respondTo("xlarge")} {
    width: 146px;
  }
`;

const StatsMain = styled.div`
  display: flex;
  gap: 40px;
`;

const StatsCol = styled.div`
  flex: 1;
`;

const Stat = styled.div`
  display: flex;

  ${(props) =>
    props.newLine
      ? css`
          flex-direction: column;
          align-items: flex-start;
        `
      : css`
          justify-content: space-between;
        `}

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Value = styled.div`
  text-align: ${(props) => (props.left ? "left" : "right")};
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const ValueColor = styled.span`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal600
      : props.theme.colors.blue600};
  padding: 4px 8px;

  background: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal100
      : props.theme.colors.blue100};
  border-radius: 4px;
`;

const ExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

const Break = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.coolGray100};
  margin: 12px 0;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const RemoveButton = styled.button`
  align-self: flex-end;
`;

const AddButton = styled.button`
  align-self: flex-start;
`;

const Item = styled.p`
  margin-bottom: 5px;
`;

export default CompanyDetails;

const LinkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 4.00008H4.0013C3.64768 4.00008 3.30854 4.14056 3.05849 4.39061C2.80844 4.64065 2.66797 4.97979 2.66797 5.33341V12.0001C2.66797 12.3537 2.80844 12.6928 3.05849 12.9429C3.30854 13.1929 3.64768 13.3334 4.0013 13.3334H10.668C11.0216 13.3334 11.3607 13.1929 11.6108 12.9429C11.8608 12.6928 12.0013 12.3537 12.0013 12.0001V9.33341M9.33464 2.66675H13.3346M13.3346 2.66675V6.66675M13.3346 2.66675L6.66797 9.33341"
      stroke="#5048E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
