// import { em, rem } from 'polished';
// import theme from '../../utils/styles';

// // Styling for the select boxes
// // const inputHeight = 34;
// const inputHeight = 42;
// // const inputBorderRadius = '4px';
// const inputBorderRadius = '0';
// const labelPadding = 5;

const selectStyles = {
  // Outermost wrapper
  // container: (styles, { isDisabled }) => ({
  //   ...styles,
  //   // pointerEvents: "auto",
  //   // display: 'inline-block',

  //   cursor: isDisabled ? "not-allowed" : "default",
  // }),
  // Only direct child of the wrapper. Has the border.
  control: (
    styles,
    { /*isFocused,*/ menuIsOpen, selectProps, isDisabled },
  ) => ({
    ...styles,

    // position: "relative",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    // flexWrap: "wrap",

    // color: theme.colors.text,
    // border: "1px solid #DAE1F5",
    border: selectProps.isInvalid
      ? "1px solid #DF5757"
      : menuIsOpen
      ? "1px solid #0079FF"
      : isDisabled
      ? "1px solid #dae1f5"
      : "1px solid #DAE1F5",
    backgroundColor: isDisabled ? "#fafbf9" : "white",
    borderRadius: "3px",
    cursor: isDisabled ? "not-allowed" : "pointer",
    width: "100%",
    padding: "0px 10px",
    transition: ".2s ease-in-out",
    // borderColor: isFocused ? theme.colors.oldBlack : theme.colors.concrete,
    // borderBottomLeftRadius: menuIsOpen ? '0' : inputBorderRadius,
    // borderBottomRightRadius: menuIsOpen ? '0' : inputBorderRadius,
    // boxShadow: 'none',

    // padding: em(`${labelPadding}px`),
    // paddingLeft: em(`${labelPadding * 4}px`),
    // paddingRight: 0,
    // minHeight: `55px`,
    minHeight: selectProps.isShort ? "30px" : "55px",
    boxShadow: "none",

    ":hover": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },

    ":focus": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },

    ":active": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },

    // outline: 0,
    // // cursor: 'default',
    // transition: `border-color 200ms ${theme.easings.default}`,
  }),
  // // Direct child of control. Contains the Multivalues
  // valueContainer: (styles, { isMulti }) => ({
  //   ...styles,
  //   padding: 0,
  //   marginBottom: 0,
  //   // marginBottom: isMulti ? `-${labelPadding}px` : 0,
  // }),

  // // A selected item (the little box with the text and the 'X')
  // multiValue: (styles, { data }) => {
  //   return {
  //     ...styles,
  //     backgroundColor: 'transparent',
  //     color: theme.colors.text,
  //     borderRadius: inputBorderRadius,
  //     margin: 0,
  //     marginRight: `20px`,
  //   };
  // },
  // // The text inside the item
  // multiValueLabel: (styles, { data }) => ({
  //   color: theme.colors.text,
  //   lineHeight: '1',
  //   padding: `${rem('8px')} ${rem('10px')}`,
  //   paddingLeft: 0,
  //   fontSize: theme.fontSize,
  //   borderRadius: inputBorderRadius,
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  // }),
  // // The 'X' inside the item
  // multiValueRemove: (styles, { data }) => ({
  //   ...styles,
  //   padding: 0,
  //   ':hover': {
  //     color: 'inherit',
  //     backgroundColor: 'inherit',
  //   },
  // }),

  // singleValue: styles => ({
  //   ...styles,
  //   color: theme.colors.text,
  //   fontWeight: '500',

  //   position: 'relative',
  //   top: 0,
  //   transform: 'none',
  //   whiteSpace: 'normal',
  //   maxWidth: 'none',
  //   // lineHeight: '1',
  //   marginTop: '1px', // account for the extra spacing below the font. (so the text looks centered in the box)
  // }),

  // // When nothing has been selected yet, this is the text that appears
  // placeholder: (styles, { isMulti }) => ({
  //   ...styles,
  //   fontWeight: '500',
  //   position: 'relative',
  //   top: 'auto',
  //   lineHeight: '1',
  //   marginTop: '1px',
  //   marginLeft: '0',
  //   whiteSpace: 'nowrap',
  //   transform: 'none',
  //   // top: isMulti ? `calc(50% - ${labelPadding / 2}px)` : '50%',

  //   // color: theme.colors.gray,
  //   color: theme.colors.text,
  // }),

  // // The flashing cursor where the user can type and search for items
  // input: (styles, { isMulti }) => ({
  //   ...styles,
  //   margin: 0,
  //   padding: 0,
  //   color: theme.colors.text,
  //   position: 'relative',
  //   zIndex: 1,
  //   // marginLeft: `${labelPadding}px`,
  //   // marginBottom: isMulti && `${labelPadding}px`,
  // }),

  // Down arrow on the right
  dropdownIndicator: (styles /*, { isDisabled } */) => ({
    ...styles,
    // display: 'flex',
    // alignItems: 'center',
    padding: "0",
    // cursor: isDisabled ? undefined : 'pointer',
    // height: '100%',
    // lineHeight: 0,
  }),
  // 'X' icon that appears to the left of the down arrow after you start selecting items
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    // display: 'none',
  }),
  // Vertical line that separates the down arrow from the 'X'
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),

  // Dropdown menu container
  menu: (styles) => ({
    ...styles,
    borderRadius: "3px",

    // This is so the dropdown menu goes above buttons & other elements
    zIndex: "2",

    // borderBottomRightRadius: '2px',
    // borderBottomLeftRadius: '2px',
    // overflow: "hidden",
    boxShadow: "0px 15px 25px 5px rgba(185,188,192,0.3)",
    marginTop: "-1px",

    left: 0,
    // minWidth: "100%",
    right: 0,
  }),
  // List inside the dropdown container
  menuList: (styles) => ({
    ...styles,
    borderRadius: "3px",

    // border: `1px solid blue`,

    // padding: '7px 0',

    padding: "0",
  }),
  // Option in the dropdown menu
  option: (styles /*, { data, isDisabled, isFocused, isSelected }*/) => ({
    ...styles,
    cursor: "pointer",
    // backgroundColor: 'transparent',
    // color: isSelected ? theme.colors.primary : theme.colors.text,
    // opacity: isFocused || isSelected ? '1' : '0.85',
    // lineHeight: '1',
    // transition: `color 200ms ${theme.easings.transition}, opacity 200ms ${theme.easings.transition}`,
    // cursor: isDisabled ? 'not-allowed' : 'pointer',

    // // fontWeight: '500',
    // // padding: '7px 22px',

    padding: "12px 20px",
    // paddingRight: '40px',
    // whiteSpace: 'nowrap',
    // borderBottom: `1px solid ${theme.colors.concrete}`,
    // fontWeight: '400',

    // ':active': {
    //   backgroundColor: 'transparent',
    // },

    // ':last-child': {
    //   borderBottom: '0',
    // },
  }),
  // noOptionsMessage: styles => ({
  //   ...styles,
  //   fontSize: theme.fontSize_xs,
  //   color: theme.colors.gray,
  // }),
};

export default selectStyles;
