import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Stage, Layer, Line, Circle } from "react-konva";

import { downloadFileFromApi } from "../../utils/api";
import { highlightTags } from "../../utils/helpers";

import FileComment from "../FileComment";
import SendIcon from "../icons/Send";
import { Input } from "../Form";

const Workspace = ({
  fileInfo,
  fileType,
  defaultFileVersion,
  isFileLoading,
  setIsFileLoading,
  setImageDimensions,
  onDocumentLoadSuccess,
  pageNumber,
  dimensions,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  comments,
  newComment,
  tool,
  highlightComment,
  hoverComment,
  latestVersion,
  noDrawing,
  workspaceNumber,
  position,
  highlightedComment,
  setHighlightedComment,
  completeComment,
  canComment,
  saveNewComment,
  editComment,
  editedMessage,
  setEditedMessage,
  deleteComment,
  user,
  creator,
}) => {
  const [fileData, setFileData] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null); //eslint-disable-line
  const [fileVersion, setFileVersion] = useState(null); // current version object {_id, date}
  const [fileLoadingMessage, setFileLoadingMessage] = useState("Loading...");
  const [replies, setReplies] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  /*
  |--------------------------------------------------------------------------
  | Get replies for the highlightedComment
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (highlightedComment && comments) {
      let tempComments = [...comments];

      tempComments = tempComments.filter((comment) => {
        return comment.parent === highlightedComment.id;
      });

      setReplies(tempComments);
    }
  }, [highlightedComment, comments]);

  /*
  |--------------------------------------------------------------------------
  | Get points
  |--------------------------------------------------------------------------
  */
  const getPoints = (mark) => {
    if (!highlightedComment) return null;
    if (!dimensions?.w || !dimensions?.h) return null;
    if (highlightedComment.page !== pageNumber) return null;
    if (highlightedComment.version !== fileVersion._id) return null;

    const points = mark.points.map((point, pointIndex) => {
      // y values are the odd indexes [x,y,x,y,x,y,...]
      const isY = pointIndex % 2;

      const dimension = isY ? dimensions.h : dimensions.w;

      return point * dimension;
    });

    if (points && points.length) {
      return points.slice(0, 2);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Generates a mark for a comment
  |--------------------------------------------------------------------------
  */
  const generateMark = (comment, i) => {
    // skip if:
    // - comment is null
    // - we don't have the dimensions yet
    // - comment doesn't belong on the current page
    // - comment doesn't belong on the current version
    if (!comment) return null;
    if (comment.parent) return null;
    if (!dimensions?.w || !dimensions?.h) return null;
    if (comment.page !== pageNumber) return null;
    if (comment.version !== fileVersion._id) return null;

    // convert the points from their relative values into absolute values, according to the dimensions
    const points = comment.mark.points.map((point, pointIndex) => {
      // y values are the odd indexes [x,y,x,y,x,y,...]
      const isY = pointIndex % 2;

      const dimension = isY ? dimensions.h : dimensions.w;

      return point * dimension;
    });

    const shapeProps = {
      shadowColor: comment.highlighted ? "#0079FF" : "#df4b26",
      shadowOpacity: 0.8,
      shadowBlur: 10,
      shadowEnabled: comment.hovered,

      onClick:
        tool === "none"
          ? () => {
              const commentScroll = document.getElementById(comment.id);
              commentScroll.scrollIntoView({
                behavior: "smooth",
              });

              setHighlightedComment({ ...comment });
              highlightComment(comment.highlighted ? null : comment.id);
            }
          : undefined,
      onMouseOver: tool === "none" ? () => hoverComment(comment.id) : undefined,
      onMouseOut: tool === "none" ? () => hoverComment(null) : undefined,
    };

    return comment.mark.tool === "draw" ? (
      <Line
        key={i}
        points={points}
        stroke={comment.highlighted ? "#0079FF" : "#df4b26"}
        strokeWidth={comment.highlighted ? 12 : 5}
        hitStrokeWidth={20}
        tension={0.5}
        lineCap="round"
        globalCompositeOperation="source-over"
        {...shapeProps}
      />
    ) : (
      <Circle
        key={i}
        radius={comment.highlighted ? 12 : 7}
        hitStrokeWidth={15}
        x={points[0]}
        y={points[1]}
        fill={comment.highlighted ? "#0079FF" : "#df4b26"}
        {...shapeProps}
      />
    );
  };

  /*
  |--------------------------------------------------------------------------
  | Set default file version
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (defaultFileVersion) {
      setFileVersion(defaultFileVersion);
    }
  }, [defaultFileVersion]);

  /*
  |--------------------------------------------------------------------------
  | Download the file version every time the file version changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    // whenever the version ID changes
    if (fileVersion?._id) {
      // download the version
      downloadFile(fileVersion._id);
    }
  }, [fileVersion]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Downloads the file
  |--------------------------------------------------------------------------
  */
  const downloadFile = async (versionId = null) => {
    try {
      const fileId = fileInfo._id;

      setIsFileLoading(true);

      const params = versionId
        ? {
            versionId,
          }
        : null;

      const previewStream = await downloadFileFromApi(
        fileId,
        params,
        (percentage) => {
          setFileLoadingMessage(`Loading... ${percentage}%`);
        },
      );

      const urlCreator = window.URL || window.webkitURL;
      // Create a URL of the image blob
      const downloadUrl = URL.createObjectURL(previewStream);
      setDownloadUrl(downloadUrl);

      const preview = urlCreator.createObjectURL(previewStream);
      setFileData(preview);
    } catch (err) {
      console.error("Downloading the file failed");
    }

    setIsFileLoading(false);
    setFileLoadingMessage(`Loading...`);
  };

  return (
    <Container position={position}>
      <WorkspaceContainer>
        <WorkspaceOuter>
          <WorkspaceInner id={`workspace-${workspaceNumber}`}>
            {fileVersion ? (
              <>
                {isFileLoading && !latestVersion && (
                  <Loading>{fileLoadingMessage || "Loading..."}</Loading>
                )}
                {fileData && (!isFileLoading || latestVersion) ? (
                  fileType === "image" ? (
                    <Image
                      id="file-img"
                      src={fileData}
                      onLoad={setImageDimensions}
                      draggable={false}
                    />
                  ) : fileType === "pdf" ? (
                    <StyledDocument
                      file={fileData}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={(error) => console.error(error)}
                    >
                      <Page
                        id="file-page"
                        pageNumber={pageNumber}
                        onLoadSuccess={setImageDimensions}
                      />
                    </StyledDocument>
                  ) : (
                    <Unsup>Unsupported file type</Unsup>
                  )
                ) : null}

                {!isFileLoading && !noDrawing ? (
                  <DrawComponent
                    width={dimensions.w}
                    height={dimensions.h}
                    onMouseDown={(e) => handleMouseDown(e, fileVersion)}
                    onMousemove={(e) => handleMouseMove(e, fileVersion)}
                    onMouseup={(e) => handleMouseUp(e, fileVersion)}
                    tool={tool}
                    shapeHovered={
                      comments.find((comment) => comment.hovered) ? true : false
                    }
                  >
                    <Layer>
                      {[...comments, newComment].map((comment, i) =>
                        generateMark(comment, i),
                      )}
                    </Layer>
                  </DrawComponent>
                ) : null}
              </>
            ) : null}

            {highlightedComment &&
            dimensions &&
            !isFileLoading &&
            fileVersion &&
            pageNumber === highlightedComment.page ? (
              <FloatingComment points={getPoints(highlightedComment.mark)}>
                <FileComment
                  commentId={highlightedComment.id}
                  className="comment"
                  author={highlightedComment.comment.author}
                  postedDate={highlightedComment.comment.postedDate}
                  message={highlightTags(highlightedComment.comment.message)}
                  commentNum={highlightedComment.commentNum}
                  page={highlightedComment.page}
                  completed={highlightedComment.comment.completed}
                  completable={user._id === creator._id ? true : false}
                  completeComment={completeComment}
                  floating
                  canComment={canComment}
                  saveNewComment={saveNewComment}
                  highlightedComment={highlightedComment}
                  setHighlightedComment={setHighlightedComment}
                  editable={user._id === highlightedComment.comment.author._id}
                  editComment={() => {
                    const tempComment = { ...highlightedComment };
                    tempComment.comment.message = editedMessage;
                    setHighlightedComment({ ...tempComment });
                    editComment(highlightedComment.id, editedMessage);
                  }}
                  editedMessage={editedMessage}
                  setEditedMessage={setEditedMessage}
                  deletable={user._id === highlightedComment.comment.author._id}
                  deleteComment={() => {
                    const deleteConfirmation = window.confirm(
                      "Are you sure you want to delete this comment? This will delete all replies associted with this comment.",
                    );

                    if (deleteConfirmation) {
                      deleteComment(highlightedComment.id);
                      setHighlightedComment(null);
                    }
                  }}
                />

                {comments && comments.length && replies && replies.length
                  ? replies.map((reply) =>
                      reply.comment && reply.parent ? (
                        <FileComment
                          key={reply.id}
                          commentId={reply.id}
                          className="comment"
                          author={reply.comment.author}
                          postedDate={reply.comment.postedDate}
                          message={highlightTags(reply.comment.message)}
                          commentNum={reply.commentNum}
                          page={reply.page}
                          completed={reply.comment.completed}
                          completable={user._id === creator._id ? true : false}
                          completable
                          completeComment={completeComment}
                          highlightedComment={highlightedComment}
                          setHighlightedComment={setHighlightedComment}
                          editable={user._id === reply.comment.author._id}
                          editedMessage={editedMessage}
                          setEditedMessage={setEditedMessage}
                          editComment={() => {
                            const tempComment = { ...highlightedComment };
                            setHighlightedComment({ ...tempComment });
                            editComment(reply.id, editedMessage);
                          }}
                          deletable={user._id === reply.comment.author._id}
                          deleteComment={() => {
                            const deleteConfirmation = window.confirm(
                              "Are you sure you want to delete this comment?",
                            );

                            if (deleteConfirmation) {
                              deleteComment(reply.id);
                            }
                          }}
                          floating
                        />
                      ) : null,
                    )
                  : null}

                <NewCommentContainer>
                  <NewComment
                    onSubmit={(e) => {
                      e.preventDefault();
                      saveNewComment(newMessage, highlightedComment.id);
                      setNewMessage("");
                    }}
                  >
                    <NewMessage
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Start Typing..."
                    />

                    <Submit
                      type="submit"
                      title="Save"
                      aria-label="Save comment"
                    >
                      <SendIcon aria-hidden="true" />
                    </Submit>
                  </NewComment>
                </NewCommentContainer>
              </FloatingComment>
            ) : null}
          </WorkspaceInner>
        </WorkspaceOuter>
      </WorkspaceContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-drag: none;

  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  flex: 1;
  height: 85%;
  margin: ${(props) =>
    props.position && props.position === "left"
      ? "auto 8px auto auto"
      : props.position && props.position === "right"
      ? "auto auto auto 8px"
      : "auto"};

  .react-pdf__message {
    color: ${(props) => props.theme.colors.oldWhite};
  }
`;

const WorkspaceContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const WorkspaceOuter = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  * {
    cursor: grab;
  }
`;

const WorkspaceInner = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
   */
  position: relative;
  max-height: 100%;
`;

const StyledDocument = styled(Document)`
  /* transform-origin: top left; */
  /* transform: ${(props) => (props.zoom ? `scale(${props.zoom})` : "")}; */
  display: block;
`;

const DrawComponent = styled(Stage)`
  position: absolute;
  /* top: 0;
  left: 0;
  width: 100%;
  height: 100%; */

  cursor: ${(props) =>
    props.tool === "draw"
      ? "nw-resize"
      : props.tool === "point"
      ? "crosshair"
      : props.shapeHovered
      ? "pointer"
      : "default"};

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  height: ${(props) => (props.height ? `${props.height}px` : "100%")};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  -webkit-user-drag: none;
  /* transform-origin: top left; */
  /* transform: ${(props) => (props.zoom ? `scale(${props.zoom})` : "")}; */
  display: block;
`;

const Loading = styled.p`
  color: ${(props) => props.theme.colors.oldWhite};
`;

const FloatingComment = styled.div`
  background-color: ${(props) => props.theme.colors.coolGray700};
  position: absolute;
  z-index: 2;
  width: 330px;
  left: ${(props) =>
    props.points && props.points.length ? `${props.points[0]}px` : 0};
  top: ${(props) =>
    props.points && props.points.length ? `${props.points[1]}px` : 0};
`;

const NewCommentContainer = styled.div`
  padding: 24px;
  background-color: ${(props) => props.theme.colors.coolGray600};
`;

const NewComment = styled.form`
  position: relative;
`;

const NewMessage = styled(Input)`
  height: 36px;
  width: 100%;
  padding: 0px 10px;
`;

const Submit = styled.button`
  position: absolute;
  right: 1px;
  bottom: 1px;

  /* so it doesn't overlap the 1px border on top and bottom of the input */
  height: calc(100% - 2px);

  max-height: 40px;

  display: flex;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: background-color 200ms;

  svg {
    path {
      fill: ${(props) => props.theme.colors.indigo};
      transition: fill 200ms;
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo50};
  }
`;

export default Workspace;
