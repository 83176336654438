import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import { xor } from "lodash";

import {
  getInvoiceFromApi,
  updateInvoiceOnApi,
  deleteInvoiceOnApi,
  deleteInvoiceLineItemOnApi,
  publishInvoiceOnApi,
  sendInvoiceOnApi,
  syncInvoiceOnApi,
  postInvoiceCommentOnApi,
} from "../../../utils/api";
import {
  capitalize,
  getCurrency,
  getStandardizedDate,
  ClientLink,
} from "../../../utils/helpers";
import { respondTo } from "../../../styles/styleHelpers";

import { useNotifications } from "../../../context/notificationsContext";
import { useSockets } from "../../../context/socketsContext";
import { useAuth } from "../../../context/authContext";

import { Field } from "../../../components/Form";
import SimpleLink from "../../../components/links/SimpleLink";
import Tooltip from "../../../components/Tooltip";
import ProfileImage from "../../../components/ProfileImage";
import Button from "../../../components/buttons/Button";
import TrashIcon from "../../../components/icons/TrashIcon";

import InvoiceSidebar from "./components/invoiceSidebar";

const InvoiceEdit = () => {
  const history = useHistory();
  const { id } = useParams();

  const { openAlertPopup } = useNotifications();
  const { user, isManagerOrAdmin } = useAuth();
  const { startInvoicesListener, stopInvoicesListener } = useSockets();

  const [invoice, setInvoice] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [isApprover, setIsApprover] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [comments, setComments] = useState(null);
  const [commentMessage, setCommentMessage] = useState("");

  // on load
  useEffect(() => {
    // fetch the invoice's data
    getInvoice();
  }, [id]);

  // when approvers are set
  useEffect(() => {
    if (approvers.length && user) {
      // check if this user is an approver
      const thisApprover = approvers.find(
        (approver) => approver._id === user._id,
      );

      setIsApprover(thisApprover ? true : false);
    }
  }, [approvers, user]);

  useEffect(() => {
    if (invoice && approvers.length) {
      let invoiceIsApproved = true;

      // loop over everyone who needs to approve
      approvers.forEach((approver) => {
        // check if they've approved
        const hasApproved = invoice.approvedBy.find(
          (approverId) => approverId === approver._id,
        );

        if (!hasApproved) {
          invoiceIsApproved = false;
        }
      });

      setIsApproved(invoiceIsApproved);
    }
  }, [approvers, invoice]);

  useEffect(() => {
    if (comments) {
      startInvoicesListener(id, (newComment) => {
        setComments([...comments, newComment]);
      });

      return () => {
        stopInvoicesListener(id);
      };
    }
  }, [comments]);

  const getInvoice = async () => {
    try {
      const invoiceData = await getInvoiceFromApi(id);

      setInvoice(invoiceData);

      // get the approvers off of the client
      setApprovers(invoiceData.client?.invoiceApprovers || []);

      setComments(invoiceData.comments || []);
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to get that invoice. ${error}`,
      );
    }
  };

  const changeStatus = async (newStatus) => {
    try {
      const updatedState = {
        status: newStatus,
      };

      // if the invoice got rejected, reset all the approvals
      if (newStatus === "revise") {
        updatedState.approvedBy = [];
      }

      // update status in state
      const updatedInvoice = {
        ...invoice,
        ...updatedState,
      };

      setInvoice(updatedInvoice);

      // update invoice on API
      await updateInvoiceOnApi(invoice._id, updatedState);

      if (newStatus === "review") {
        handleComment("[Submitted the invoice for approval]");
      } else if (newStatus === "revise") {
        handleComment("[Rejected the invoice]");
      }
    } catch (error) {
      console.error("error changing invoice status", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able change the status of the invoice. ${errMessage}`,
      );
    }
  };

  const handleApprove = async (shouldApprove) => {
    const originalApprovedBy = invoice.approvedBy;

    try {
      // will remove the user id if its already in the array, or add it if its absent
      const newApprovedBy = xor(invoice.approvedBy, [user._id]);

      // optimistically update invoice in state
      setInvoice({
        ...invoice,
        approvedBy: newApprovedBy,
      });

      // update invoice on API
      await updateInvoiceOnApi(invoice._id, {
        approver: { id: user._id, hasApproved: shouldApprove },
      });

      handleComment(
        `[${shouldApprove ? "Approved" : "Unapproved"} the invoice]`,
      );
    } catch (error) {
      console.error("error changing approval status", error);

      // reset approvedBy back to the original
      setInvoice({
        ...invoice,
        approvedBy: originalApprovedBy,
      });

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able change your approval status. ${errMessage}`,
      );
    }
  };

  const handleLineItemDelete = async (lineItemId) => {
    const originalInvoice = invoice;

    try {
      // remove line item from state
      const newLineItems = invoice.lineItems.filter(
        (lineItem) => lineItem.id !== lineItemId,
      );

      // find the line item's details
      const lineItem = invoice.lineItems.find(
        (lineItem) => lineItem.id === lineItemId,
      );

      // subtract the amount of the removed line item in state
      const newTotal = invoice.total - lineItem.amount;
      const newBalance = invoice.balance - lineItem.amount;

      // update state optimistically
      const updatedInvoice = {
        ...invoice,
        lineItems: newLineItems,
        total: newTotal,
        balance: newBalance,
      };

      setInvoice(updatedInvoice);

      // delete the line item on harvest
      await deleteInvoiceLineItemOnApi(id, lineItemId);
    } catch (error) {
      // if the API request failed, revert back to the original invoice data
      setInvoice(originalInvoice);

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to delete that line item. ${error}`,
      );
    }
  };

  const handleDelete = async () => {
    try {
      await deleteInvoiceOnApi(id);

      openAlertPopup("Success", "Invoice deleted.", true);

      // go to the invoices list view
      history.push(`/manage/invoices/`);
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to delete this invoice. ${error}`,
      );
    }
  };

  const handleSyncInvoice = async () => {
    try {
      setIsProcessing(true);

      const updatedInvoice = await syncInvoiceOnApi(id);

      // remove the client from the returned result, since the client's data was already populated earlier
      delete updatedInvoice.client;

      setInvoice({
        ...invoice,
        ...updatedInvoice,
      });

      setIsProcessing(false);

      openAlertPopup("Success", "Invoice synced.", true);
    } catch (error) {
      setIsProcessing(false);

      const errorMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to sync the invoice. You may need to re-authenticate with QuickBooks in Sherpa Settings. ${errorMessage}`,
      );
    }
  };

  const handlePublishInvoice = async () => {
    try {
      // show user confirmation message in browser
      const confirmationMessage = `Are you sure you wish to publish this invoice in QuickBooks? We won't send it to the client just yet.`;

      const confirmationResult = window.confirm(confirmationMessage);

      // if user accepted the confirmation message
      if (confirmationResult) {
        setIsProcessing(true);

        const publishedInvoice = await publishInvoiceOnApi(id);

        // remove the client from the returned result, since the client's data was already populated earlier
        delete publishedInvoice.client;

        setInvoice({
          ...invoice,
          ...publishedInvoice,
        });

        handleComment("[Published invoice to QuickBooks]");

        setIsProcessing(false);

        openAlertPopup("Success", "Invoice published to QuickBooks.", true);
      }
    } catch (error) {
      setIsProcessing(false);

      const errorMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to publish the invoice. You may need to re-authenticate with QuickBooks in Sherpa Settings. ${errorMessage}`,
      );
    }
  };

  const handleSendInvoice = async () => {
    try {
      // show user confirmation message in browser
      const confirmationMessage = `Are you sure you wish to send this ${getCurrency(
        invoice.total,
        true,
      )} invoice to ${invoice.client.name}?`;

      const confirmationResult = window.confirm(confirmationMessage);

      // if user accepted the confirmation message
      if (confirmationResult) {
        setIsProcessing(true);

        const sentInvoice = await sendInvoiceOnApi(id);

        // remove the client from the returned result, since the client's data was already populated earlier
        delete sentInvoice.client;

        setInvoice({
          ...invoice,
          ...sentInvoice,
        });

        handleComment("[Sent invoice to client]");

        setIsProcessing(false);

        openAlertPopup("Success", "Invoice sent to client.", true);
      }
    } catch (error) {
      setIsProcessing(false);

      const errorMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to send the invoice. You may need to re-authenticate with QuickBooks in Sherpa Settings. ${errorMessage}`,
      );
    }
  };

  const handleComment = async (message = commentMessage) => {
    try {
      if (message !== "") {
        await postInvoiceCommentOnApi(id, {
          userId: user._id,
          message,
        });

        // if the comment came from the sidebar
        if (message === commentMessage) {
          // clear the sidebar comment input
          setCommentMessage("");
        }
      }
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to send that comment. ${errorMessage}`,
      );
    }
  };

  const isVoid = invoice?.status === "void";

  const isPublished =
    invoice?.status === "published" || invoice?.status === "sent" || isVoid;

  return invoice ? (
    <Container>
      <OptionsCol>
        {invoice.accountingNumber ? (
          <Field>
            <Heading>Invoice #</Heading>
            <div>{invoice.accountingNumber}</div>
          </Field>
        ) : null}

        <Field>
          <Heading>Client</Heading>
          <ClientLink
            name={`${
              invoice.client.acronym ? `${invoice.client.acronym} -` : ""
            } ${invoice.client.name}`}
            id={invoice.client._id}
          />
        </Field>

        <Field>
          <Heading>Status</Heading>
          <div>{capitalize(invoice.status)}</div>
        </Field>

        {invoice.dueDate ? (
          <Field>
            <Heading>Due</Heading>
            <div style={{ textDecoration: isVoid ? "line-through" : "none" }}>
              {format(getStandardizedDate(invoice.dueDate), "E, MMM do")}
            </div>
          </Field>
        ) : null}

        {/* don't show the delete option if the invoice has been sent */}
        {!isPublished ? (
          <OptionsFooter>
            {deleteConfirmation ? (
              <>
                <p>
                  Are you sure you want to delete this invoice? Harvest does not
                  archive invoices, so once it's deleted, it's gone.
                </p>
                <p>
                  Once deleted, the time entries associated with this invoice
                  will be unlocked and may be used in a new invoice.
                </p>

                <SimpleLink onClick={() => setDeleteConfirmation(false)}>
                  Nevermind
                </SimpleLink>
                <SimpleLink
                  danger
                  onClick={handleDelete}
                  disabled={isProcessing}
                >
                  Delete
                </SimpleLink>
              </>
            ) : (
              <SimpleLink
                danger
                onClick={() => setDeleteConfirmation(true)}
                disabled={isProcessing}
              >
                Delete Invoice
              </SimpleLink>
            )}
          </OptionsFooter>
        ) : !isVoid ? (
          <OptionsFooter>
            <SimpleLink
              onClick={handleSyncInvoice}
              data-tip
              data-for="invoice-sync"
              disabled={isProcessing}
            >
              {isProcessing ? "Syncing..." : "Sync with QuickBooks"}
            </SimpleLink>

            <Tooltip id="invoice-sync" place="top">
              Ensure that this invoice's data is up to date with the data in
              QuickBooks.
            </Tooltip>
          </OptionsFooter>
        ) : null}
      </OptionsCol>

      <InvoiceCol>
        <ColumnPad>
          <Heading>{isPublished ? "" : "Review"} Invoice</Heading>
        </ColumnPad>

        <TableContainer>
          {isVoid ? (
            <Void>Void</Void>
          ) : invoice?.lineItems?.length ? (
            <Table>
              <thead>
                <tr>
                  {!isPublished ? <th>{/* Trash can */}</th> : null}
                  <th>Activity</th>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice.lineItems.map((lineItem) => (
                  <tr key={lineItem.id}>
                    {!isPublished ? (
                      <td>
                        <TrashButton
                          onClick={() => handleLineItemDelete(lineItem.id)}
                          data-tip
                          data-for={`line-confirmation-${lineItem.id}`}
                          disabled={isProcessing}
                        >
                          <TrashIcon />
                        </TrashButton>

                        <Tooltip id={`line-confirmation-${lineItem.id}`}>
                          Once a line item is removed, it can't be added back to
                          this invoice.
                        </Tooltip>
                      </td>
                    ) : null}

                    <td>{lineItem.kind}</td>
                    <td>{lineItem.description}</td>
                    <td>{lineItem.quantity}</td>
                    <td>{getCurrency(lineItem.unit_price, true)}</td>
                    <td>{getCurrency(lineItem.amount, true)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            "No line items"
          )}
        </TableContainer>

        <InvoiceSection>
          <Sum style={{ textDecoration: isVoid ? "line-through" : "none" }}>
            <span>Total</span>
            <b>{getCurrency(invoice.total, true)}</b>
          </Sum>

          {/* only show balance if its been sent to the client */}
          {isPublished ? (
            <Sum style={{ textDecoration: isVoid ? "line-through" : "none" }}>
              <span>Balance Due</span>
              <b>{getCurrency(invoice.balance, true)}</b>
            </Sum>
          ) : null}
        </InvoiceSection>

        <InvoiceFooter>
          {isPublished ? (
            <>
              <Notice>
                <b>Invoice {invoice.sentDate ? "Sent" : "Published"}</b>

                {invoice.sentDate ? (
                  <div>
                    on{" "}
                    {format(
                      getStandardizedDate(invoice.sentDate),
                      "EEEE, MMM do yyyy",
                    )}
                  </div>
                ) : (
                  <div>but not sent to client</div>
                )}
              </Notice>

              {/* invoice is published, but not sent yet */}
              {!invoice.sentDate ? (
                <ButtonRow>
                  <div>
                    {/* placeholder so the button appears on the right */}
                  </div>
                  <div>
                    <Button
                      color="green"
                      onClick={handleSendInvoice}
                      disabled={isProcessing}
                    >
                      Send Invoice to Client
                    </Button>
                  </div>
                </ButtonRow>
              ) : null}
            </>
          ) : (
            <>
              {/* if its not sent to client yet, display the approvals status */}

              {approvers.length ? (
                <Notice>
                  <p>Approvals</p>

                  <Approvers>
                    {approvers.map((approver) => (
                      <Approver
                        key={approver._id}
                        hasApproved={
                          invoice.approvedBy.indexOf(approver._id) > -1
                        }
                      >
                        <ProfileImage
                          key={approver._id}
                          handle={approver.handle}
                          name={approver.name}
                          small
                        />
                      </Approver>
                    ))}
                  </Approvers>
                </Notice>
              ) : null}

              <ButtonRow>
                <div>
                  {/*
                    - user is an approver
                    - user has approved
                  */}
                  {isApprover && invoice.approvedBy.indexOf(user._id) > -1 ? (
                    <HollowButton
                      onClick={() => handleApprove(false)}
                      disabled={isProcessing}
                    >
                      Unapprove
                    </HollowButton>
                  ) : invoice.status === "review" &&
                    isApprover &&
                    invoice.approvedBy.indexOf(user._id) === -1 ? (
                    <HollowButton onClick={() => changeStatus("revise")}>
                      {/*
                        - invoice is in review
                        - user is an approver
                        - user hasn't approved yet
                      */}
                      Reject Back
                    </HollowButton>
                  ) : invoice.status !== "review" ? (
                    <HollowButton onClick={() => changeStatus("review")}>
                      {/* anyone can submit for review */}
                      Submit for Review
                    </HollowButton>
                  ) : null}
                </div>

                <div>
                  {/*
                    - invoice isn't in draft
                    - user is an approver
                    - user hasn't approved yet
                  */}
                  {invoice.status !== "draft" &&
                  isApprover &&
                  invoice.approvedBy.indexOf(user._id) === -1 ? (
                    <Button onClick={() => handleApprove(true)}>Approve</Button>
                  ) : isApproved && isManagerOrAdmin ? (
                    <Button
                      color="green"
                      onClick={handlePublishInvoice}
                      disabled={isProcessing}
                    >
                      Publish to QuickBooks
                    </Button>
                  ) : null}
                </div>
              </ButtonRow>
            </>
          )}
        </InvoiceFooter>
      </InvoiceCol>

      <InvoiceSidebar
        comments={comments}
        commentMessage={commentMessage}
        handleCommentChange={(e) => setCommentMessage(e.target.value)}
        onComment={() => handleComment()}
      />
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Column = styled.div`
  padding: 50px 40px;
`;

const ColumnPad = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const Heading = styled.h2`
  font-size: ${(props) => props.theme.fontSize_xxs};
  color: ${(props) => props.theme.colors.darkSlate};
`;

const OptionsCol = styled(Column)`
  flex-basis: 25%;
  max-width: 25%;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  ${respondTo("xlarge")} {
    flex-basis: 30%;
    max-width: 30%;
  }
`;

const OptionsFooter = styled.div`
  margin-top: auto;
  text-align: center;

  p {
    font-size: ${(props) => props.theme.fontSize_xxxs};
  }
  button {
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const InvoiceCol = styled.div`
  /* flex-basis: 70%;
  max-width: 70%; */
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  padding: 50px 0;
  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const TableContainer = styled(ColumnPad)`
  overflow-y: auto;
  margin-bottom: 30px;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 0;
  font-size: ${(props) => props.theme.fontSize_xxxs};

  thead {
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.cardBackground};

    th {
      text-align: left;
    }
  }

  th,
  td {
    padding-right: 20px;
    padding-bottom: 15px;

    &:last-child,
    &:nth-last-child(2),
    &:nth-last-child(3) {
      text-align: right;
    }

    &:last-child {
      padding-right: 0;
    }

    ${respondTo("xlarge")} {
      padding-right: 30px;
    }
  }

  tbody {
    tr {
      &:last-child {
        td,
        th {
          padding-bottom: 0;
        }
      }
    }
  }
`;

const TrashButton = styled.button`
  line-height: 0;

  path {
    transition: fill 200ms;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      path {
        fill: ${(props) => props.theme.colors.red};
      }
    }
  }
`;

const InvoiceSection = styled(ColumnPad)`
  margin-bottom: 30px;

  > *:last-child {
    margin-bottom: 0;
  }
`;

const Sum = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  span {
    padding-right: 30px;
    text-transform: uppercase;
  }
`;

const InvoiceFooter = styled(ColumnPad)`
  margin-top: auto;
`;

const Void = styled.div`
  font-size: 100px;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  opacity: 0.25;
`;

const Notice = styled.div`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 500;
  text-align: center;

  p {
    margin-bottom: 0.5em;
  }
`;

const Approvers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;

  ${respondTo("xlarge")} {
    margin-bottom: 0;
  }
`;

const Approver = styled.div`
  border-radius: 50%;
  border: 3px solid;

  border-color: ${(props) =>
    props.hasApproved
      ? props.theme.colors.green
      : props.theme.colors.mediumGray};

  opacity: ${(props) => (props.hasApproved ? 1 : 0.5)};

  transition: border-color 200ms, opacity 200ms;

  ${Approvers} & {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HollowButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.blue};
  border-color: ${(props) => props.theme.colors.blue};

  &::after {
    display: none;
  }
`;

export default InvoiceEdit;
