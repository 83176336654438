import axios from "axios";
import { headers } from "../../../utils/api";

export const lookForActiveTimeEntry = async (user) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/member/${user}`,
      headers,
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

export const getEverything = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/everything`,
      headers,
    };

    const results = await axios(config);
    return results.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const getPastEntriesFromApi = async ({
  projectId = null,
  taskId = null,
  email = null,
  calculate = false,
}) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/past/entries`,
      headers,
      params: {
        projectId,
        taskId,
        email,
        calculate,
      },
    };

    const results = await axios(config);

    return results;
  } catch (err) {
    console.error(err);
  }
};

export const updatePastEntryFromApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/past/entry`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const stopTimerOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/timer/stop`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const editTimerOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/timer/edit`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteTimerOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/timer/delete`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

export const deletePastEntryOnApi = async (id) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/time/past/entries/${id}`,
      headers,
    };

    await axios(config);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

/*
|--------------------------------------------------------------------------
| Recaps
|--------------------------------------------------------------------------
*/
export const createRecapOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/recaps/`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteRecapOnApi = async (data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/recaps/${data.recap._id}`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const finishDayOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/recaps/`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};
