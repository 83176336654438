import React from "react";
import styled from "styled-components";

const DrawIcon = ({ ...props }) => {
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2344 0.993877C11.4189 0.802857 11.6396 0.650492 11.8836 0.545674C12.1276 0.440856 12.3901 0.385684 12.6556 0.383376C12.9212 0.381069 13.1846 0.431672 13.4303 0.532234C13.6761 0.632796 13.8994 0.781302 14.0872 0.969087C14.275 1.15687 14.4235 1.38018 14.5241 1.62597C14.6246 1.87176 14.6752 2.13512 14.6729 2.40068C14.6706 2.66624 14.6155 2.92868 14.5106 3.17269C14.4058 3.41669 14.2535 3.63738 14.0624 3.82188L13.2694 4.61488L10.4414 1.78688L11.2344 0.993877V0.993877ZM9.02744 3.20088L0.648438 11.5799V14.4079H3.47644L11.8564 6.02888L9.02644 3.20088H9.02744Z"
        fill="#F3F4F6"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default DrawIcon;
