import React from "react";
import styled from "styled-components";

const CloseIcon = () => {
  return (
    <Svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9703 1.70725C13.3612 1.31725 13.3612 0.68325 12.9703 0.29325C12.5802 -0.09775 11.9472 -0.09775 11.5562 0.29325L6.63225 5.21725L1.70725 0.29325C1.31725 -0.09775 0.68325 -0.09775 0.29325 0.29325C-0.09775 0.68325 -0.09775 1.31725 0.29325 1.70725L5.21725 6.63225L0.29325 11.5562C-0.09775 11.9472 -0.09775 12.5802 0.29325 12.9703C0.68325 13.3612 1.31725 13.3612 1.70725 12.9703L6.63225 8.04625L11.5562 12.9703C11.9472 13.3612 12.5802 13.3612 12.9703 12.9703C13.3612 12.5802 13.3612 11.9472 12.9703 11.5562L8.04625 6.63225L12.9703 1.70725Z"
        fill="#4E5A6B"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transition: opacity 0.2s;
`;

export default CloseIcon;
