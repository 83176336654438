import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { buttonReset } from "../styles/styleHelpers";

import { NumberChip } from "./ValueChip";

/*
  tabs [
    {
      name,
      id,
      number: optional

      # for navLinks
      to: string,
      exact: bool,
    }
  ]
*/

const Tabs = ({ activeTab, tabs, handleChange }) => {
  return (
    <Container>
      <TabList>
        {tabs.map((tab, index) => (
          <TabItem
            onClick={handleChange ? () => handleChange(tab.id) : undefined}
            isactive={activeTab === tab.id ? "true" : undefined}
            key={`${tab.id}-${index}`}
            as={tab.to ? NavLink : undefined}
            to={tab.to ? tab.to : undefined}
            exact={tab.exact ? true : undefined}
          >
            {tab.name}
            {tab.number ? <Number>{tab.number}</Number> : null}
          </TabItem>
        ))}
      </TabList>
    </Container>
  );
};

const Container = styled.div``;

const TabList = styled.div`
  display: flex;
  gap: 24px;
`;

const TabItem = styled.button`
  ${buttonReset()}

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.darkGray};
  text-decoration: none;

  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;

  transition: color 200ms, border-color 200ms;

  &:hover, &:focus {
    color: ${(props) => props.theme.colors.darkerGray};
  }

  ${(props) =>
    props.isactive
      ? css`
          color: ${(props) => props.theme.colors.indigo};
          border-color: ${(props) => props.theme.colors.indigo};
        `
      : ``}

  /* navLink active styles */
  &.active {
    color: ${(props) => props.theme.colors.indigo};
    border-color: ${(props) => props.theme.colors.indigo};
  }

`;

const Number = styled(NumberChip)`
  margin-left: 10px;
`;

export const TabContent = styled.div`
  display: ${(props) => (props.isactive ? "block" : "none")};
  width: 100%;

  padding: 40px;
  background-color: ${(props) => (props.dark ? "#f9fafb" : undefined)};
`;

Tabs.propTypes = {
  tabs: PropTypes.array,
  handleChange: PropTypes.func,
};
Tabs.defaultProps = {
  tabs: [],
};

export default Tabs;
