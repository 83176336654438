import React from "react";
import styled from "styled-components";

const SuccessIcon = (props) => {
  return (
    <Svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.34473 8.55173L3.24204 10.4643C3.95335 11.98 6.07631 12.0673 6.90969 10.6151L11.6551 2.34483"
        stroke="#7BC1A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default SuccessIcon;
