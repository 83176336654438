import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Widget = ({ title, children, ...rest }) => {
  return (
    <Container {...rest}>
      {title ? <Title>{title}</Title> : null}

      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  background: white;
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: 4px;
  padding: 24px;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 22px;
  color: ${(props) => props.theme.colors.darkerGray};
`;

Widget.propTypes = {
  title: PropTypes.string,
};

export default Widget;
