import React from "react";
import styled from "styled-components";

import { getCurrency, isNumber } from "../../../utils/helpers";

import ProfileImage from "../../../components/ProfileImage";
import TrendingUpIcon from "../../../components/icons/TrendingUpIcon";
import TrendingDownIcon from "../../../components/icons/TrendingDownIcon";

const BudgetTable = ({ teams }) => {
  return (
    <Container>
      {teams
        ? teams.map((team, index) => (
            <TeamContainer key={index}>
              <HeaderRow>
                <HeaderValue>
                  {isNumber(team.totalSpent)
                    ? getCurrency(team.totalSpent)
                    : "..."}
                </HeaderValue>
                <HeaderText>
                  {team.name && team.name !== "undefined" ? team.name : "N/A"}
                </HeaderText>
                <HeaderValue>
                  {isNumber(team.totalBudgeted)
                    ? getCurrency(team.totalBudgeted)
                    : "..."}
                </HeaderValue>
              </HeaderRow>

              <ProgressBar
                actual={team.totalSpent}
                total={team.totalBudgeted}
              />

              <Table>
                <Row>
                  <THeader>Tracked</THeader>
                  <THeader>Budgeted</THeader>
                  <THeader>Spent</THeader>
                  <THeader>Remaining</THeader>
                </Row>
                {team.members
                  ? team.members.map((member) => {
                      let remainingValue = null;
                      let remainingStatus = "zero";

                      // only set remaining value if budgeted and spent are numbers
                      // if one or the other doesn't exist (and isn't 0), then we can't determine the remaining
                      if (
                        isNumber(member.budgetedDollars) &&
                        isNumber(member.spentDollars)
                      ) {
                        remainingValue =
                          member.budgetedDollars - member.spentDollars;

                        remainingStatus =
                          remainingValue < 0
                            ? "negative"
                            : remainingValue > 0
                            ? "positive"
                            : "zero";
                      }

                      return (
                        <Row fixedHeight key={member._id}>
                          <Name>
                            <ProfileImage
                              name={member.name}
                              handle={member.handle}
                              xsmall
                            />
                            {member.name}
                          </Name>
                          <Value>
                            {isNumber(member.budgetedDollars)
                              ? getCurrency(member.budgetedDollars)
                              : "..."}
                          </Value>
                          <Value>
                            {isNumber(member.spentDollars)
                              ? getCurrency(member.spentDollars)
                              : "..."}
                          </Value>

                          <Remaining status={remainingStatus}>
                            {remainingStatus === "positive" ? (
                              <TrendingUpIcon />
                            ) : remainingStatus === "negative" ? (
                              <TrendingDownIcon />
                            ) : null}

                            {isNumber(remainingValue)
                              ? getCurrency(Math.abs(remainingValue))
                              : "..."}
                          </Remaining>
                        </Row>
                      );
                    })
                  : "No members available"}
              </Table>
            </TeamContainer>
          ))
        : "No team members available"}
    </Container>
  );
};

const Container = styled.div``;

const TeamContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 26px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const HeaderText = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
  text-align: center;
`;

const HeaderValue = styled.p`
  min-width: 100px;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;

  &:last-child {
    text-align: right;
  }
`;

const ProgressBar = styled.div`
  height: 12px;
  position: relative;
  background: ${(props) => props.theme.colors.coolGray200};
  margin-bottom: 12px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${(props) =>
      props.actual > props.total
        ? 100
        : props.actual < 0
        ? 0
        : (props.actual / props.total) * 100}%;
    background-color: ${(props) =>
      props.actual > props.total
        ? props.theme.colors.red600
        : props.theme.colors.green500};
  }
`;

const Table = styled.div``;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.fixedHeight ? `46px` : `auto`)};

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const THeader = styled.p`
  margin-bottom: 0;
  flex: 1;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray700};
`;

const Cell = styled.div`
  flex: 1;
`;

const Name = styled(Cell)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.black};
`;

const Value = styled(Cell)`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray500};
`;

const Remaining = styled(Cell)`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.status === "negative"
      ? props.theme.colors.rose500
      : props.status === "positive"
      ? props.theme.colors.green500
      : props.theme.colors.coolGray500};

  svg {
    margin-right: 4px;
  }
`;

export default BudgetTable;
