import React from "react";

const StarIcon = ({ ...rest }) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.3321 3.06144C11.0705 2.25556 9.93034 2.25556 9.66784 3.06144L8.33871 7.15119C8.28146 7.32676 8.17014 7.47971 8.02067 7.58816C7.8712 7.69661 7.69125 7.75499 7.50659 7.75494H3.20684C2.35984 7.75494 2.00634 8.83994 2.69234 9.33869L6.17134 11.8657C6.32078 11.9743 6.432 12.1275 6.48906 12.3032C6.54611 12.4789 6.54608 12.6682 6.48896 12.8439L5.16071 16.9337C4.89821 17.7396 5.82134 18.4107 6.50646 17.9119L9.98546 15.3849C10.135 15.2762 10.3151 15.2177 10.5 15.2177C10.6848 15.2177 10.8649 15.2762 11.0145 15.3849L14.4935 17.9119C15.1786 18.4107 16.1017 17.7404 15.8392 16.9337L14.511 12.8439C14.4538 12.6682 14.4538 12.4789 14.5109 12.3032C14.5679 12.1275 14.6791 11.9743 14.8286 11.8657L18.3076 9.33869C18.9927 8.83994 18.641 7.75494 17.7931 7.75494H13.4925C13.3079 7.7548 13.1282 7.69634 12.9789 7.5879C12.8296 7.47947 12.7184 7.32661 12.6612 7.15119L11.3321 3.06144Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
