import React from "react";
import styled from "styled-components";

const FireIcon = (props) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0002 21.0002C14.122 21.0002 16.1569 20.1573 17.6572 18.657C18.4012 17.9149 18.9912 17.0331 19.3932 16.0623C19.7953 15.0915 20.0016 14.0508 20.0002 13C20.0015 11.9491 19.795 10.9084 19.3928 9.93756C18.9905 8.96674 18.4004 8.08499 17.6562 7.343C16.0902 5.777 14.0002 5 11.9862 3C9.50021 5 9.00021 8 9.00021 10C7.00021 9 6.34321 7.343 6.34321 7.343C4.84288 8.84333 4 10.8782 4 13C4 15.1218 4.84288 17.1567 6.34321 18.657C7.84354 20.1573 9.87842 21.0002 12.0002 21.0002Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1277 16.8702C10.6563 16.7268 10.2274 16.4695 9.879 16.121C9.293 15.536 9 14.768 9 14H11L12.015 11C12.5078 11.0024 12.9923 11.1261 13.4259 11.3602C13.8595 11.5943 14.2288 11.9316 14.5011 12.3423C14.7733 12.753 14.9402 13.2245 14.987 13.715C15.0338 14.2056 14.9591 14.7001 14.7694 15.1548C14.5797 15.6096 14.2809 16.0107 13.8994 16.3226C13.5179 16.6345 13.0655 16.8476 12.5821 16.9431C12.0986 17.0386 11.5991 17.0135 11.1277 16.8702Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default FireIcon;
