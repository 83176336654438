import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { getStandardizedDate } from "../../utils/helpers";
import { respondTo } from "../../styles/styleHelpers";

import { useNotifications } from "../../context/notificationsContext";
import { useAuth } from "../../context/authContext";

import {
  getDealFromApi,
  updateDealOnApi,
  deleteDealsOnApi,
  updateClientOnApi,
  getContactsFromApi,
  updateContactOnApi,
  getMembersFromApi,
  getCompaniesFromApi,
} from "../../utils/api";
import {
  dealStages,
  dealStagesMain,
  contractStages,
  clientStages,
} from "../../utils/constants";

import ActivityLog from "../../components/ActivityLog";
import Button from "../../components/newButtons/Button";

import DealHeader from "./components/singleDealHeader";
import DealDetails from "./components/singleDealDetails";
import DealNotes from "./components/singleDealNotes";
import DealModal from "./components/singleDealModal";

const SingleDeal = () => {
  const history = useHistory();
  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
    openModal,
    closeModal,
  } = useNotifications();
  const { isImportant } = useAuth();

  const { dealId } = useParams();
  const [deal, setDeal] = useState(null);
  const [availMembers, setAvailMembers] = useState([]);
  const [availCompanies, setAvailCompanies] = useState([]);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (dealId) {
      getDeal();
    }
  }, [dealId]);

  useEffect(() => {
    if (deal?.status) {
      // Set the status index, for the progress bars

      // ? if its in a contract stage, use the length of the deal stages so the progress bar will be full
      const statusIndex = contractStages.includes(deal.status)
        ? dealStages.length
        : dealStages.indexOf(deal.status) + 1;

      if (deal.statusIndex !== statusIndex) {
        setDeal({
          ...deal,
          statusIndex,
        });
      }
    }
  }, [deal]);

  const getDeal = async () => {
    setLoading(true);

    try {
      const dealFromApi = await getDealFromApi(dealId);
      const companies = await getCompaniesFromApi();

      const companyIndustries = companies
        // filter out companies that don't have an industry
        .filter((company) => company.industry)
        // return the industry of those that do
        .map((company) => company.industry);

      setIndustries([...new Set(companyIndustries)]);
      setDeal(dealFromApi);
    } catch (error) {
      console.error("error getting deal", error);
    }

    setLoading(false);
  };

  const createIndustry = (newIndustry) => {
    setIndustries([...new Set([...industries, newIndustry])]);
  };

  const getData = async () => {
    try {
      const [membersFromApi, companiesFromApi] = await Promise.all([
        getMembersFromApi(),
        getCompaniesFromApi(),
      ]);

      setAvailMembers(membersFromApi);
      setAvailCompanies(companiesFromApi);
    } catch (error) {
      console.error("error getting deal data");
    }
  };

  const handleDetailsEdit = async (
    updatedProps,
    context = "deal",
    secondaryContext = null,
  ) => {
    if (!deal) return;

    try {
      const tempDeal = { ...deal };

      // get the keys for the new props
      let keys = Object.keys(updatedProps);
      let formattedProps = { ...updatedProps };

      switch (context) {
        case "deal":
          // Update the deal
          await updateDealOnApi(dealId, formattedProps);

          if (keys) {
            keys.forEach((key) => {
              if (secondaryContext === "company") {
                const newCompany = availCompanies.find(
                  (company) => company._id === formattedProps[key],
                );
                if (newCompany) {
                  tempDeal[key] = newCompany;
                }
              } else if (secondaryContext === "contact") {
                const newContact = tempDeal.contacts.find(
                  (contact) => contact._id === formattedProps[key],
                );
                if (newContact) {
                  tempDeal[key] = newContact;
                }
              } else {
                tempDeal[key] = formattedProps[key];
              }
            });
          }
          break;

        case "company":
          // Update the company

          // If these are supposed to be in info, bring in the original info obj
          // ? If you don't do this, it will wipe all the info fields
          if (secondaryContext === "info") {
            formattedProps = {
              info: { ...tempDeal.company.info, ...updatedProps },
            };
            keys = ["info"];
          }

          // Has to add database parent obj to prop because that's what server is expecting
          await updateClientOnApi(deal.company._id, {
            database: formattedProps,
          });

          // Attaches any props to the deal's company and updates
          if (keys) {
            keys.forEach((key) => {
              tempDeal.company[key] = formattedProps[key];
            });
          }

          break;
        case "contact":
          // If these are supposed to be in info, bring in the original info obj
          // ? If you don't do this, it will wipe all the info fields
          if (secondaryContext === "contactInfo") {
            formattedProps = {
              contactInfo: {
                ...tempDeal.primaryContact.contactInfo,
                ...updatedProps,
              },
            };
            keys = ["contactInfo"];
          }

          // Update contact
          await updateContactOnApi(tempDeal.primaryContact._id, formattedProps);

          // Attaches any props to the deal's company and updates
          if (keys) {
            keys.forEach((key) => {
              tempDeal.primaryContact[key] = formattedProps[key];
            });
          }

          break;
      }

      setDeal(tempDeal);

      return tempDeal;
    } catch (err) {
      console.error("Something went wrong with editing deal", err);

      throw err;
    }
  };

  const handleDelete = async () => {
    openPromptPopup({
      header: "Delete deal",
      body: "Are you sure you want to delete this deal?",
      confirmCallback: async () => {
        try {
          await deleteDealsOnApi({ ids: [dealId] });
          openAlertPopup("Deal deleted successfully!", null, true);
          closePromptPopup();
          history.push("/sales/deals");
        } catch (err) {
          openAlertPopup("Delete deal failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const menuItems = [
    {
      handleClick: () => handleDelete(),
      text: "Delete",
      danger: true,
    },
  ];

  return (
    <Container>
      <DealHeader
        title={deal ? deal.name : ""}
        breadcrumbs={[
          { name: "Sales", link: "/sales" },
          { link: "/sales/deals", name: "Deals" },
          { name: deal ? deal.name : "" },
        ]}
        isActive={deal ? !deal.archived : null}
        created={deal ? deal.createdAt : ""}
        updated={deal ? deal.updatedAt : ""}
        stages={dealStages}
        numStages={dealStagesMain.length}
        currentStage={deal ? deal.statusIndex : null}
        stage={deal ? deal.status : ""}
        menuItems={menuItems}
        handleTitleEdit={
          isImportant
            ? (value) => {
                handleDetailsEdit({ name: value });
              }
            : undefined
        }
        // ? can't edit progress if its in a contract stage
        handleProgressEdit={
          isImportant && !contractStages.includes(deal?.status)
            ? (value) => {
                let statusHistory = [
                  { status: value, date: getStandardizedDate(new Date()) },
                ];

                if (deal && deal.statusHistory?.length) {
                  statusHistory = [
                    ...deal.statusHistory,
                    { status: value, date: getStandardizedDate(new Date()) },
                  ];
                }

                handleDetailsEdit({ status: value, statusHistory });
              }
            : undefined
        }
        statusHistory={deal ? deal.statusHistory : null}
      />
      <Main>
        {console.log("Deal", deal)}
        <LeftCol>
          {deal?.status === "Won" &&
          isImportant &&
          clientStages.includes(deal.company.status) ? (
            <Cta>
              <Button
                onClick={() => {
                  openModal(
                    <DealModal
                      deal={deal}
                      handleEdit={handleDetailsEdit}
                      close={closeModal}
                    />,
                  );
                }}
              >
                Convert Deal into Contract
              </Button>
            </Cta>
          ) : contractStages.includes(deal?.status) ? (
            <Cta>
              <Button
                as={Link}
                to={`/manage/clients/${deal.company._id}/contracts/${deal._id}`}
                small
              >
                Go To Contract
              </Button>
            </Cta>
          ) : null}

          <DealDetails
            id={dealId}
            handleEdit={isImportant ? handleDetailsEdit : undefined}
            company={deal?.company}
            industries={industries}
            contacts={deal?.contacts}
            createIndustry={createIndustry}
            primaryContact={deal?.primaryContact}
            team={deal ? deal.team : []}
            estimatedBudget={deal ? deal.estimatedBudget : ""}
            chanceClosing={deal ? deal.chanceClosing : null}
            description={deal ? deal.description : ""}
            renewalPeriod={deal ? deal.renewalPeriod : null}
            doesRenew={deal ? deal.doesRenew : null}
            website={
              deal?.company?.info?.website ? deal.company.info.website : null
            }
            address={
              deal && deal.company.info?.address1 ? (
                <>
                  {deal.company.info.address1}
                  <br />
                  {deal.company.info.address2 ? (
                    <>
                      {deal.company.info.address2}
                      <br />
                    </>
                  ) : null}
                  {deal.company.info.city}, {deal.company.info.state}{" "}
                  {deal.company.info.zipcode}
                </>
              ) : (
                "N/A"
              )
            }
            availMembers={availMembers?.length ? availMembers : []}
            availCompanies={availCompanies?.length ? availCompanies : []}
          />
          <ActivityLog reference={dealId} />
        </LeftCol>
        <RightCol>
          <DealNotes
            dealId={deal ? deal._id : ""}
            initialNotes={deal?.notes || ""}
            blockedBy={deal?.blockedBy ? deal.blockedBy : null}
          />
        </RightCol>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  padding: 27px 40px 75px;
  gap: 20px;
`;

const Col = styled.div`
  position: relative;
  height: 100%;
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const LeftCol = styled(Col)`
  flex: 0 1 40%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
  }
`;

const RightCol = styled(Col)`
  flex: 0 1 60%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
    width: 50%;
  }
`;

const Cta = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  transform: translateY(-100%);
`;

export default SingleDeal;
