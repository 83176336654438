import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { getActivityLogsFromApi } from "../utils/api";
import ProfileImage from "./ProfileImage";
import { formatDuration, intervalToDuration } from "date-fns";

/**
 *
 * @param {string} reference The ref id to get activities by
 * @param {string} [header] Optional header text to be displayed
 * @param {boolean} [noHeader] Don't show the header at all if this is true
 * @param {boolean} [naked] Don't show the header or outer styling
 * @returns {component}
 */
const ActivityLog = ({ reference, header, noHeader, naked, ...props }) => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    getActivities();
  }, [reference]);

  const getActivities = async () => {
    let results = await getActivityLogsFromApi({ ref: reference });

    results.sort(function(a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    results = results.slice(0, 100);

    setActivities(results);
  };

  const formatDate = (originalDate) => {
    const duration = intervalToDuration({
      start: new Date(originalDate),
      end: new Date(),
    });

    // Clears out everything except the largest duration
    // That way you don't get times that say "5 days 6 hours and 57 minutes ago"
    if (duration?.years > 0) {
      duration.months = 0;
      duration.weeks = 0;
      duration.days = 0;
      duration.hours = 0;
      duration.minutes = 0;
    } else if (duration?.months > 0) {
      duration.weeks = 0;
      duration.days = 0;
      duration.hours = 0;
      duration.minutes = 0;
    } else if (duration?.weeks > 0) {
      duration.days = 0;
      duration.hours = 0;
      duration.minutes = 0;
    } else if (duration?.days > 0) {
      duration.hours = 0;
      duration.minutes = 0;
    } else if (duration?.hours > 0) {
      duration.minutes = 0;
    }

    const formattedDuration = formatDuration(duration, {
      format: ["years", "months", "weeks", "days", "hours", "minutes"],
    });

    const finalForm = formattedDuration
      ? `${formattedDuration} ago`
      : `Just now`;

    return finalForm;
  };

  return (
    <Container naked={naked} {...props}>
      {noHeader || naked ? null : (
        <Header>{header ? header : "Recent Activity"}</Header>
      )}
      <List>
        {activities
          ? activities.map((activity) => (
              <Activity key={activity._id}>
                <ProfileImage
                  name={activity.author.name}
                  handle={activity.author.handle}
                  xsmall
                />
                <MainContent>
                  <Name>{activity.author.name}</Name>
                  <Message>{activity.message}</Message>
                </MainContent>
                <Time>
                  {activity.createdAt ? formatDate(activity.createdAt) : ""}
                </Time>
              </Activity>
            ))
          : null}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 24px;
  min-height: 326px;
  max-height: 396px;

  ${(props) =>
    props.naked
      ? ``
      : css`
          border: 1px solid ${(props) => props.theme.colors.coolGray100};
          border-radius: 8px;
        `}
`;

const Header = styled.h2`
  padding-left: 24px;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 24px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px 24px;

  overflow: auto;
`;

const Activity = styled.div`
  display: flex;
  padding: 8px;

  &:hover {
    background-color: ${(props) => rgba(props.theme.colors.indigo100, 0.2)};
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

const Name = styled.h4`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;
`;

const Time = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;
`;

export default ActivityLog;
