import React from "react";
import styled from "styled-components";

const LinkIcon = () => {
  return (
    <Svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80016 3.2334C8.58799 3.2334 8.38451 3.31768 8.23448 3.46771C8.08445 3.61774 8.00016 3.82123 8.00016 4.0334C8.00016 4.24557 8.08445 4.44905 8.23448 4.59908C8.38451 4.74911 8.58799 4.8334 8.80016 4.8334H10.869L5.83456 9.8678C5.75815 9.9416 5.69721 10.0299 5.65528 10.1275C5.61335 10.2251 5.59129 10.3301 5.59036 10.4363C5.58944 10.5425 5.60968 10.6478 5.64991 10.7462C5.69013 10.8445 5.74953 10.9338 5.82465 11.0089C5.89976 11.084 5.98908 11.1434 6.0874 11.1837C6.18572 11.2239 6.29106 11.2441 6.39728 11.2432C6.50351 11.2423 6.60848 11.2202 6.70609 11.1783C6.80369 11.1364 6.89196 11.0754 6.96576 10.999L12.0002 5.9646V8.0334C12.0002 8.24557 12.0844 8.44905 12.2345 8.59908C12.3845 8.74911 12.588 8.8334 12.8002 8.8334C13.0123 8.8334 13.2158 8.74911 13.3658 8.59908C13.5159 8.44905 13.6002 8.24557 13.6002 8.0334V4.0334C13.6002 3.82123 13.5159 3.61774 13.3658 3.46771C13.2158 3.31768 13.0123 3.2334 12.8002 3.2334H8.80016Z"
        fill="#5048E5"
      />
      <path
        d="M3.9999 4.8335C3.57556 4.8335 3.16859 5.00207 2.86853 5.30213C2.56847 5.60218 2.3999 6.00915 2.3999 6.4335V12.8335C2.3999 13.2578 2.56847 13.6648 2.86853 13.9649C3.16859 14.2649 3.57556 14.4335 3.9999 14.4335H10.3999C10.8242 14.4335 11.2312 14.2649 11.5313 13.9649C11.8313 13.6648 11.9999 13.2578 11.9999 12.8335V10.4335C11.9999 10.2213 11.9156 10.0178 11.7656 9.86781C11.6156 9.71778 11.4121 9.6335 11.1999 9.6335C10.9877 9.6335 10.7842 9.71778 10.6342 9.86781C10.4842 10.0178 10.3999 10.2213 10.3999 10.4335V12.8335H3.9999V6.4335H6.3999C6.61208 6.4335 6.81556 6.34921 6.96559 6.19918C7.11562 6.04915 7.1999 5.84567 7.1999 5.6335C7.1999 5.42132 7.11562 5.21784 6.96559 5.06781C6.81556 4.91778 6.61208 4.8335 6.3999 4.8335H3.9999Z"
        fill="#5048E5"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    transition: fill 0.2s ease-in-out;
  }
`;

export default LinkIcon;
