import React from "react";
import styled from "styled-components";
import Editable from "../../../components/Editable";
import { respondTo } from "../../../styles/styleHelpers";

const ContactDetails = ({
  id,
  handleEdit,
  name,
  code,
  tier,
  industry,
  contactName,
  phone,
  email,
  address,
  availCompanies,
  title,
  division,
  roleDescription,
  officeAddress,
  tenure,
  preferredContact,
  linkedin,
  kpiResponsible,
  painsGains,
  success,
  reportingStructure,
  roleWithinPartnership,
  reviewerApprover,
  personalContactInfo,
  additionalNotes,
  amNotes,
}) => {
  return (
    <Container>
      <HeaderRow>
        <Header>Contact Details</Header>
        {/* <EditButton onClick={() => console.log("clicked")}>
          <EditIcon />
        </EditButton> */}
      </HeaderRow>
      {/* <ProgressRow>
        <Label>Status</Label>
        <ProgressBar />
        <ProgressPercentage>28%</ProgressPercentage>
      </ProgressRow> */}
      <StatsMain>
        <StatsCol>
          <Stat>
            <Label>Company Name</Label>
            <Value>
              <Editable
                id={`${id}-company-name`}
                onSave={(value) => {
                  handleEdit({ company: value }, "contact", "company");
                }}
                options={availCompanies.map((company) => ({
                  label: company.name,
                  value: company._id,
                }))}
              >
                {name}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Tier</Label>
            <Value>
              <Editable
                id={`${id}-tier`}
                onSave={(value) => {
                  handleEdit({ tier: value }, "company");
                }}
                options={[
                  { label: "Tier 1", value: "Tier 1" },
                  { label: "Tier 2", value: "Tier 2" },
                  { label: "Tier 3", value: "Tier 3" },
                ]}
              >
                <ValueColor color="teal">{tier}</ValueColor>
              </Editable>
            </Value>
          </Stat>
          <Break />
          <Stat>
            <Label>Name</Label>
            <Value>
              <Editable
                id={`${id}-contact-name`}
                onSave={(value) => {
                  const firstName = value.substr(0, value.indexOf(" "));
                  const lastName = value.substr(value.indexOf(" ") + 1);
                  handleEdit({ firstName, lastName }, "contact");
                }}
              >
                {contactName}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Role Description</Label>
            <Value>
              <Editable
                id={`${id}-roleDescription`}
                onSave={(value) => {
                  handleEdit({ roleDescription: value });
                }}
              >
                <Value>{roleDescription || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Office Address</Label>
            <Value>{officeAddress}</Value>
          </Stat>
          <Stat>
            <Label>Company Tenure</Label>
            <Value>
              <Editable
                id={`${id}-tenure`}
                onSave={(value) => {
                  handleEdit({ tenure: value });
                }}
              >
                <Value>{tenure || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Email</Label>
            <Value>
              <Editable
                id={`${id}-contact-email`}
                onSave={(value) => {
                  handleEdit({ email: value }, "contact", "contactInfo");
                }}
                defaultValue={email}
                iconClickOnly
              >
                {email ? (
                  <ExLink href={`mailto:${email}`}>
                    {email} <MailIcon />
                  </ExLink>
                ) : (
                  "N/A"
                )}
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Preferred Contact</Label>
            <Value>
              <Editable
                id={`${id}-preferredContact`}
                onSave={(value) => {
                  handleEdit({ preferredContact: value });
                }}
              >
                <Value>{preferredContact || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>KPI Responsible For</Label>
            <Value>
              <Editable
                id={`${id}-kpi`}
                onSave={(value) => {
                  handleEdit({ kpiResponsible: value });
                }}
              >
                <Value>{kpiResponsible || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Reporting Structure</Label>
            <Value>
              <Editable
                id={`${id}-reportingStructure`}
                onSave={(value) => {
                  handleEdit({ reportingStructure: value });
                }}
              >
                <Value>{reportingStructure || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Reviewer/Approver?</Label>
            <Value>
              <Editable
                id={`${id}-reviewerApprover`}
                onSave={(value) => {
                  handleEdit({ reviewerApprover: value });
                }}
              >
                <Value>{reviewerApprover || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Additional notes</Label>
            <Value>
              <Editable
                id={`${id}-additionalNotes`}
                onSave={(value) => {
                  handleEdit({ additionalNotes: value });
                }}
              >
                <Value>{additionalNotes || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
        </StatsCol>
        <StatsCol>
          <Stat>
            <Label>Company Code</Label>
            <Value>
              <Editable
                id={`${id}-company-code`}
                onSave={(value) => {
                  handleEdit({ acronym: value }, "company");
                }}
              >
                {code}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Industry</Label>
            <Value>
              <Editable
                id={`${id}-industry`}
                onSave={(value) => {
                  handleEdit({ industry: value }, "company");
                }}
              >
                <Value>{industry || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Break />
          <Stat>
            <Label>Title</Label>
            <Value>
              <Editable
                id={`${id}-title`}
                onSave={(value) => {
                  handleEdit({ title: value });
                }}
              >
                <Value>{title}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Company/Division</Label>
            <Value>
              <Editable
                id={`${id}-title`}
                onSave={(value) => {
                  handleEdit({ division: value });
                }}
              >
                <Value>{division || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Personal Address</Label>
            <Value>{address}</Value>
          </Stat>
          <Stat>
            <Label>Phone</Label>
            <Value>
              <Editable
                id={`${id}-phone`}
                onSave={(value) => {
                  handleEdit({ phone: value }, "contact", "contactInfo");
                }}
              >
                {phone}
              </Editable>
            </Value>
          </Stat>
          <Stat>
            <Label>Linkedin</Label>
            <Value>
              <Editable
                id={`${id}-linkedin`}
                onSave={(value) => {
                  handleEdit({ linkedin: value });
                }}
                defaultValue={linkedin}
                iconClickOnly
              >
                {linkedin ? (
                  <ExLink href={linkedin} target="_blank">
                    {linkedin} <LinkIcon />
                  </ExLink>
                ) : (
                  "N/A"
                )}
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Pains/Gains</Label>
            <Value>
              <Editable
                id={`${id}-painsGains`}
                onSave={(value) => {
                  handleEdit({ painsGains: value });
                }}
              >
                <Value>{painsGains || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>What does success look like to you?</Label>
            <Value>
              <Editable
                id={`${id}-success`}
                onSave={(value) => {
                  handleEdit({ success: value });
                }}
              >
                <Value>{success || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Role Within Partnership</Label>
            <Value>
              <Editable
                id={`${id}-roleWithinPartnership`}
                onSave={(value) => {
                  handleEdit({ roleWithinPartnership: value });
                }}
              >
                <Value>{roleWithinPartnership || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>Personal Contact Info</Label>
            <Value>
              <Editable
                id={`${id}-personalContactInfo`}
                onSave={(value) => {
                  handleEdit({ personalContactInfo: value });
                }}
              >
                <Value>{personalContactInfo || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
          <Stat column>
            <Label>AM Notes</Label>
            <Value>
              <Editable
                id={`${id}-amNotes`}
                onSave={(value) => {
                  handleEdit({ amNotes: value });
                }}
              >
                <Value>{amNotes || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
        </StatsCol>
      </StatsMain>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray100};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Header = styled.h4`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Label = styled.p`
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray400};
`;

const StatsMain = styled.div`
  display: flex;
  gap: 40px;
`;

const StatsCol = styled.div`
  flex: 1;
`;

const Value = styled.div`
  text-align: right;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.column ? "column" : "row")};

  ${Value} {
    text-align: ${(props) => (props.column ? "left" : "right")};
  }

  ${Label} {
    width: ${(props) => (props.column ? "100%" : "75px")};

    ${respondTo("xlarge")} {
      width: ${(props) => (props.column ? "100%" : "146px")};
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ValueColor = styled.span`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal600
      : props.theme.colors.blue600};
  padding: 4px 8px;

  background: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal100
      : props.theme.colors.blue100};
  border-radius: 4px;
`;

const ExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

const Break = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.coolGray100};
  margin: 12px 0;
`;

export default ContactDetails;

const MailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.60156 4.70715L7.99916 7.90555L14.3968 4.70715C14.3731 4.29951 14.1944 3.91634 13.8974 3.63613C13.6004 3.35592 13.2075 3.19988 12.7992 3.19995H3.19916C2.79083 3.19988 2.39792 3.35592 2.1009 3.63613C1.80389 3.91634 1.62525 4.29951 1.60156 4.70715V4.70715Z"
      fill="#5048E5"
    />
    <path
      d="M14.4016 6.49438L8.00156 9.69438L1.60156 6.49438V11.2C1.60156 11.6243 1.77013 12.0313 2.07019 12.3314C2.37025 12.6314 2.77722 12.8 3.20156 12.8H12.8016C13.2259 12.8 13.6329 12.6314 13.9329 12.3314C14.233 12.0313 14.4016 11.6243 14.4016 11.2V6.49438Z"
      fill="#5048E5"
    />
  </svg>
);

const LinkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 4.00008H4.0013C3.64768 4.00008 3.30854 4.14056 3.05849 4.39061C2.80844 4.64065 2.66797 4.97979 2.66797 5.33341V12.0001C2.66797 12.3537 2.80844 12.6928 3.05849 12.9429C3.30854 13.1929 3.64768 13.3334 4.0013 13.3334H10.668C11.0216 13.3334 11.3607 13.1929 11.6108 12.9429C11.8608 12.6928 12.0013 12.3537 12.0013 12.0001V9.33341M9.33464 2.66675H13.3346M13.3346 2.66675V6.66675M13.3346 2.66675L6.66797 9.33341"
      stroke="#5048E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
