import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";
import { startCase } from "lodash";

import { getStandardizedDate, isValidDate } from "../../../utils/helpers";

import Checkbox from "../../tasks/components/Checkbox";
import { Field, Label, Input } from "../../../components/Form";
import StageApprovals from "./StageApprovals";

const Stage = ({
  name,
  checkToComplete,

  approvals,
  project,
  setProject,
  user,

  dueDate,
  updateDueDate,
  completed,
  completedDate,
  changeStage,
  isManagerOrAdmin,
  unlocked,

  render,
}) => {
  const [isApproved, setIsApproved] = useState(null);

  return (
    <div>
      {name ? <h1>{startCase(name)}</h1> : null}
      {unlocked && checkToComplete ? (
        <div>
          <Checkbox
            label="Completed"
            checked={completed}
            onChange={() => changeStage(!completed)}
            disabled={!isManagerOrAdmin}
          ></Checkbox>
        </div>
      ) : null}
      {unlocked && approvals.length ? (
        <p>
          {isApproved !== null ? (
            isApproved ? (
              "Completed"
            ) : (
              "Not completed"
            )
          ) : (
            <>&nbsp;</>
          )}
        </p>
      ) : null}
      {completedDate && (
        <CompletedDate>
          Completed on {format(getStandardizedDate(completedDate), "MM/dd/yy")}
        </CompletedDate>
      )}
      {updateDueDate ? (
        <Field>
          <Label>Due Date</Label>
          <Input
            type="date"
            value={
              dueDate && isValidDate(new Date(dueDate))
                ? format(getStandardizedDate(dueDate), "yyyy-MM-dd")
                : ""
            }
            onChange={(e) =>
              updateDueDate(
                isValidDate(new Date(e.target.value))
                  ? getStandardizedDate(e.target.value)
                  : null,
              )
            }
            disabled={!isManagerOrAdmin}
          />
        </Field>
      ) : null}
      {unlocked && approvals.length ? (
        <StageApprovals
          approverTypes={approvals}
          project={project}
          setProject={setProject}
          changeStage={changeStage}
          stageName={name}
          user={user}
          setIsApproved={setIsApproved}
        />
      ) : null}
      {unlocked && render ? render : null}
    </div>
  );
};

const CompletedDate = styled.p`
  margin-bottom: 30px;
`;

Stage.propTypes = {
  name: PropTypes.string,
  checkToComplete: PropTypes.bool,
  dueDate: PropTypes.string,
  updateDueDate: PropTypes.func,
  completed: PropTypes.bool,
  changeStage: PropTypes.func,
  isManagerOrAdmin: PropTypes.bool,
  approvals: PropTypes.array,
  project: PropTypes.object,
  setProject: PropTypes.func,
  user: PropTypes.object,
  unlocked: PropTypes.bool,
};
Stage.defaultProps = {
  name: "",
  checkToComplete: false,
  dueDate: "",
  updateDueDate: null,
  completed: false,
  changeStage: null,
  isManagerOrAdmin: false,
  approvals: [],
  project: {},
  setProject: null,
  user: {},
  unlocked: true,
};

export default Stage;
