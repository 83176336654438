import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getMembersFromApi } from "../../utils/api";
import ProfileImage from "../../components/ProfileImage";

import SimpleLink from "../../components/links/SimpleLink";
import CreateLink from "../../components/links/CreateLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../components/Table";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState("name-asc");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const results = await getMembersFromApi({ showArchived: true });

    // sort them alphabetically from the start
    results.sort(function(a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    setUsers(results);
  };

  // Handles the actual sorting of the users
  useEffect(() => {
    const sortedUsers = [...users];

    if (sortBy === "name-asc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "email-asc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.email.toUpperCase();
        var textB = b.email.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "email-desc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.email.toUpperCase();
        var textB = b.email.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "role-asc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.role.role.toUpperCase();
        var textB = b.role.role.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "role-desc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.role.role.toUpperCase();
        var textB = b.role.role.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "permissions-asc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.accessLvl.toUpperCase();
        var textB = b.accessLvl.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "permissions-desc") {
      sortedUsers.sort(function(a, b) {
        var textA = a.accessLvl.toUpperCase();
        var textB = b.accessLvl.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setUsers(sortedUsers);
  }, [sortBy]); // eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  // split the users into un-archived and archived
  const allUsers = [
    users.filter((user) => !user.isArchived),
    users.filter((user) => user.isArchived),
  ];

  return (
    <UsersContainer>
      <UsersHeader>
        <h1>Users</h1>
        <CreateLink as={Link} to={`/manage/users/new`}>
          Create User
        </CreateLink>
      </UsersHeader>

      {users.length ? (
        <UsersList id="usersTable">
          <thead>
            <tr>
              <th>{/* Avatar */}</th>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
              >
                Name
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("email")}
                isArrowUp={sortBy === "email-desc"}
              >
                Email
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("role")}
                isArrowUp={sortBy === "role-desc"}
              >
                Role
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("permissions")}
                isArrowUp={sortBy === "permissions-desc"}
              >
                Permissions
              </TableHeader>
              <th>{/* Edit */}</th>
            </tr>
          </thead>
          <tbody>
            {allUsers.map((usersArray) =>
              usersArray.map((user) => (
                <TableRow
                  key={user._id}
                  style={{ opacity: user.isArchived ? 0.3 : 1 }}
                >
                  <TableCell>
                    <ProfileImage
                      // use the name in their email to grab their photo, in case their handle gets changed for whatever reason
                      handle={user.email.split("@")[0]}
                      name={user.name}
                      showStatus
                    />
                  </TableCell>
                  <TableCell align="left">
                    <TableCellTitle>
                      {user.name}{" "}
                      {user.isArchived ? <Archived>(archived)</Archived> : null}
                    </TableCellTitle>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role.role}</TableCell>
                  <TableCell capitalize>{user.accessLvl}</TableCell>
                  <TableCell>
                    <SimpleLink as={Link} to={`/manage/users/${user._id}`}>
                      Edit
                    </SimpleLink>
                  </TableCell>
                </TableRow>
              )),
            )}
          </tbody>
        </UsersList>
      ) : null}
    </UsersContainer>
  );
};

const UsersContainer = styled.div`
  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const UsersHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const UsersList = styled.table`
  padding: 50px;
`;

const Archived = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export default Users;
