import React from "react";
import styled from "styled-components";
import { ClientLink, ProjectLink } from "../../../utils/helpers";

const AdminCard = ({ project, budgetHours }) => {
  return (
    <CardContainer>
      <CardInner>
        <HeaderSection>
          <ClientHeader>
            <ClientLink name={project.client.name} id={project.client._id} />
          </ClientHeader>
          <ProjectLink
            name={
              project.code
                ? `${project.code.toUpperCase()} - ${project.name}`
                : project.name
            }
            id={project._id}
          />
        </HeaderSection>

        <FooterSection>
          <Hours>
            <HoursLabel>Budget</HoursLabel>
            <HoursQty>
              {budgetHours
                ? Math.round((budgetHours + Number.EPSILON) * 100) / 100
                : 0}
            </HoursQty>
          </Hours>
        </FooterSection>
      </CardInner>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  flex-basis: 100%;
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 23px;
  margin-right: 23px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  filter: drop-shadow(4px 8px 14px rgba(185, 188, 192, 0.3));
  transition: 0.3s ease-in-out;

  .task-name {
    font-size: 16px;
  }
`;

const CardInner = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
`;

const FooterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Hours = styled.div`
  margin-bottom: 0;

  &:last-child {
    p {
      text-align: right;
    }
  }
`;

const HoursLabel = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs} !important;
  font-weight: 300;
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 0px;
`;

const HoursQty = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_s} !important;
  margin-bottom: 0;
`;

// const User = styled.p`
//   margin-bottom: 0px;
//   font-size: ${(props) => props.theme.fontSize_xxxxxs};
//   color: ${(props) => props.theme.colors.darkSlate};
// `;

const HeaderSection = styled.div``;

const ClientHeader = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  margin-bottom: 12px;
`;

export default AdminCard;
