import React, { useState, useContext } from "react";

import { updateNotificationOnApi } from "../utils/api";

const NotificationsContext = React.createContext();

// * This is the context used for both the alert popup
// * and the notifications in the sidebar

const NotificationsProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [isPromptOpen, setIsPromptOpen] = useState(false);

  const promptDataInit = {
    header: "Are you sure?",
    body: "",
    cancelCallback: null,
    confirmCallback: null,
    cancelText: "Cancel",
    confirmText: "Confirm",
  };

  const [promptData, setPromptData] = useState(promptDataInit);

  const [notifications, setNotifications] = useState([]);

  const openModal = (newContent) => {
    setModalContent(newContent);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setIsModalOpen(false);
  };

  const openAlertPopup = (headerMsg, msg, isTimed = false) => {
    setAlertHeader(headerMsg);
    setAlertMessage(msg);
    setIsAlertOpen(true);

    // If it's a timed window, we set a delay then close the window
    if (isTimed) {
      setTimeout(() => {
        closeAlertPopup();
      }, 5000);
    }
  };

  const closeAlertPopup = () => {
    setIsAlertOpen(false);
    setAlertHeader("");
    setAlertMessage("");
  };

  /**
   *
   * @param {obj} data
   * @param {string} data.header
   * @param {string} [data.body]
   * @param {function} [data.cancelCallback]
   * @param {function} data.confirmCallback
   */
  const openPromptPopup = (data) => {
    setPromptData(data);
    setIsPromptOpen(true);
  };

  const closePromptPopup = () => {
    setIsPromptOpen(false);
    setPromptData(promptDataInit);
  };

  // Pushes out a new notification to the user
  const pushNewNotification = async (newNotification) => {
    // Have to pass in as a callback to get most up to date array
    const tempNotifications = (notifications) => [
      ...notifications,
      newNotification,
    ];

    setNotifications(tempNotifications);

    // Checks if we can push a notification to the OS
    if (window.Notification && Notification.permission !== "denied") {
      // If they haven't approved or denied, we ask for permission
      if (Notification.permission !== "granted") {
        await Notification.requestPermission();
      }

      const author = newNotification.author?.handle || "Sherpa";

      // We push the notification to them
      new Notification(`Message from ${author}`, {
        body: newNotification.message,
      });
    }
  };

  const updateNotification = async (noteId, toUpdate) => {
    const unchangedNotifications = [...notifications];

    try {
      // loop over all notifications
      const updatedNotifications = notifications.map((note) => {
        // if this is the one to upate, add the updated props
        if (note._id === noteId) {
          return {
            ...note,
            ...toUpdate,
          };
        } else {
          // otherwise, leave it as is
          return {
            ...note,
          };
        }
      });

      // update notifications in state
      setNotifications(updatedNotifications);

      // update on api
      await updateNotificationOnApi(noteId, toUpdate);
    } catch (err) {
      // revert the notifications back to how they were before
      setNotifications(unchangedNotifications);

      console.error("Failed to update notification", err);
      openAlertPopup(
        "Failure",
        "Failed to update notification. Please try again.",
      );
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        isAlertOpen,
        alertHeader,
        alertMessage,
        loading,
        setLoading,

        isModalOpen,
        modalContent,
        openModal,
        closeModal,

        openAlertPopup,
        closeAlertPopup,

        isPromptOpen,
        promptData,
        openPromptPopup,
        closePromptPopup,

        notifications,
        setNotifications,
        updateNotification,
        pushNewNotification,
      }}
      {...props}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => useContext(NotificationsContext);

export { NotificationsProvider, useNotifications, NotificationsContext };
