import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";

import { Input } from "./newForm";
import CreatableSelect from "react-select/creatable";
import SelectDropdown from "./newSelectDropdown";
import Tooltip from "./Tooltip";
import Pencil from "./icons/Pencil";

// * NOTE: for custom edit component
// if passing a custom component into the "edit" prop, make sure the component is using React.forwardRef, so the input ref can be forwarded to the custom element

// * NOTE: for multiple edit components
// - pass a function into the "edit" prop that returns the desired JSX
// - the function will have a single argument that is a ref:  (editRef) => (<>JSX here</>)
// - Each input should be given a ref prop like:  ref={(el) => (editRef["firstName"] = el)}
// - onSave will return an object with those keys:  newValues.firstName: "your new value"

// * what the edit element will be:
// - default: text input
// - provide "options" prop: dropdown
// - provide "edit" prop: whatever you pass in as the "edit" prop

// * what the defaultValue of the edit element will be:
// - default: will attempt to guess based on the children
// - provide "edit" prop: the defaultValue of the component in the "edit" prop
// - provide "defaultValue" prop: or just directly provide the defaultValue

const Editable = ({
  id,
  edit,
  options,
  defaultValue,
  onSave,
  showIcon, // whether to show a button that opens the edit tooltip (for when the editable element is a link)
  iconClickOnly, // if the edit button should be the only way to open the edit tooltip
  dropdownProps,
  inputProps,
  children,
  creatable,
  handleDropdownCreate,
  danger,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const container = useRef();
  const toggle = useRef();
  const input = useRef();
  const inputs = useRef({});

  // when "isEditing" changes
  useEffect(() => {
    // ? don't run this on the inital load, otherwise if there are many of these in the document at the same time the page will get bogged down and load very slowly
    if (hasLoaded && toggle.current) {
      if (isEditing) {
        // display the edit tooltip
        ReactTooltip.show(toggle.current);

        // listen for "ESC" key
        document.addEventListener("keydown", handleEsc);

        // listen for click
        document.addEventListener("mousedown", handleDocumentClick);

        // place focus on the input element (after it has a moment to render)
        setTimeout(() => {
          focusInput();
        }, 200);
      } else {
        ReactTooltip.hide(toggle.current);

        // ? sometimes the tooltip wouldn't completely go away afterwards, so this makes sure it goes away
        setTimeout(() => {
          ReactTooltip.hide(toggle.current);
        }, 500);
      }

      // cleanup
      return () => {
        // remove ESC key listener
        document.removeEventListener("keydown", handleEsc);

        // remove click listener
        document.removeEventListener("mousedown", handleDocumentClick);
      };
    }

    // denote that the initial load has happened
    setHasLoaded(true);
  }, [isEditing]);

  const handleDocumentClick = (e) => {
    // if click was outside the menu
    if (container.current) {
      if (!container.current.contains(e.target) && isEditing) {
        setIsEditing(false);
      }
    }
  };

  const handleEsc = (e) => {
    if (e.key === "Escape") {
      closeEditor();
    }
  };

  const handleInputKeyDown = (e) => {
    // if "enter" was pressed
    if (e.key === "Enter") {
      handleSave(e.target.value);
    }
  };

  const handleDropdownChange = (e) => {
    let newValue = null;

    // If the value is a multi select, we return the entire array
    if (Array.isArray(e)) {
      newValue = e;
    } else if (e) {
      newValue = e.value || e.target.value || "";
    } else {
      // value must be null
      newValue = e;
    }

    handleSave(newValue);
  };

  const focusInput = () => {
    if (input.current) {
      input.current.focus();
    }
  };

  const focusToggle = () => {
    if (toggle.current) {
      toggle.current.focus();
    }
  };

  const closeEditor = () => {
    // put focus back on the toggle
    focusToggle();

    setIsEditing(false);
  };

  const handleSave = (newValue) => {
    if (onSave) {
      onSave(newValue);
    }

    closeEditor();
  };

  const findOption = (optionsArray, findText) =>
    optionsArray.find(
      (option) => option.label === findText || option.value === findText,
    );

  // text thats inside the regular element
  // we'll use this to help guess what the defaultValue should be, if one wasn't provided
  const childText =
    typeof children === "string"
      ? children
      : Array.isArray(children)
      ? children[0]?.value ||
        children[0]?.defaultValue ||
        children[0]?.innerText ||
        ""
      : typeof children?.props?.children === "string" ||
        typeof children?.props?.children === "number"
      ? children.props.children
      : "";

  // determine what the default value of the input field should be
  // - the prop OR
  // - the defaultValue of the "edit" element passed in OR
  // - a dropdown option that matches the childText OR
  // - the childText
  const inputDefaultValue =
    typeof defaultValue !== "undefined"
      ? defaultValue
      : edit?.props?.value ||
        edit?.props?.defaultValue ||
        (options?.length
          ? findOption(options, childText) || childText
          : edit?.props?.options?.length
          ? findOption(edit.props.options, childText) || childText
          : childText);

  // new props that we'll add onto the passed in "edit" element
  const editElProps = {
    ref: input,
    defaultValue: inputDefaultValue,
  };

  // if the edit input will be a dropdown
  const isDropdown = options || edit?.props?.options || edit?.type === "select";

  // if an "edit" element was passed in
  if (edit) {
    // onChange if its a dropdown
    if (isDropdown) {
      editElProps.onChange = handleDropdownChange;
    }
    // onKeyDown if its a text input
    else {
      editElProps.onKeyDown = handleInputKeyDown;
    }
  }

  const editDropdownProps = {
    options,
    // ? automatically open its dropdown menu
    autoOpen: true,
  };

  // the edit element
  // - the passed in edit component OR
  // - a dropdown, if "options" were passed in OR
  // - default: a text input
  const editInput =
    typeof edit === "function" ? (
      edit(inputs.current)
    ) : edit ? (
      React.cloneElement(edit, editElProps)
    ) : options && creatable ? (
      <CreatableSelect
        onCreateOption={handleDropdownCreate}
        onChange={handleDropdownChange}
        {...editElProps}
        {...editDropdownProps}
        {...dropdownProps}
      />
    ) : options ? (
      <SelectDropdown
        onChange={handleDropdownChange}
        {...editElProps}
        {...editDropdownProps}
        {...dropdownProps}
      />
    ) : (
      <Input onKeyDown={handleInputKeyDown} {...editElProps} {...inputProps} />
    );

  return (
    <Container ref={container}>
      {(showIcon || iconClickOnly) && !isEditing && onSave ? (
        <EditButton
          aria-label="Edit"
          title="Edit"
          onClick={() => {
            setIsEditing(!isEditing);
          }}
        >
          <Pencil />
        </EditButton>
      ) : null}

      <Toggle
        ref={toggle}
        tabIndex={onSave ? 0 : undefined}
        data-tip
        data-for={id}
        // set these to "none" so the only way to reveal the tooltip is programatically
        data-event="none"
        data-event-off="none"
        danger={danger}
        onClick={
          onSave && !iconClickOnly
            ? () => {
                setIsEditing(!isEditing);
              }
            : undefined
        }
        onKeyDown={
          onSave
            ? (e) => {
                // If they press "enter"
                if (e.key === "Enter") {
                  setIsEditing(!isEditing);
                }
              }
            : undefined
        }
      >
        {children}
      </Toggle>

      {/* isInteractive is false to make sure the tooltip goes away after we're done editing. TipInner has pointer-events: all; to counteract this */}
      {isEditing ? (
        <Tooltip id={id} place="bottom" isInteractive={false} delayHide={0}>
          <TipInner>
            {/* {isEditing ? ( */}
            <>
              {editInput}

              <BtnGroup>
                <Btn onClick={closeEditor} color="neutral">
                  Cancel
                </Btn>

                {!isDropdown ? (
                  <Btn
                    onClick={() => {
                      let newValue = "";

                      // if theres a single input, use its value
                      if (input.current) {
                        newValue =
                          input.current?.state?.value?.value || // ? react-select
                          input.current?.value ||
                          "";
                      }
                      // if there are multiple inputs, put all of their values into an object
                      else if (Object.keys(inputs.current).length) {
                        newValue = {};

                        Object.entries(inputs.current).map(
                          ([inputName, inputEl]) => {
                            newValue[inputName] =
                              inputEl?.state?.value?.value || // ? react-select
                              inputEl?.value ||
                              "";
                          },
                        );
                      }

                      handleSave(newValue);
                    }}
                  >
                    Save
                  </Btn>
                ) : null}
              </BtnGroup>
            </>
            {/* ) : null} */}
          </TipInner>
        </Tooltip>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const EditButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(75%, -75%);

  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.blue};

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;

  transition: 200ms 200ms;

  svg {
    width: 50%;

    path {
      stroke: ${(props) => props.theme.colors.white};
    }
  }

  ${Container}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const Toggle = styled.div`
  outline: 1px solid transparent;
  outline-offset: 4px;
  transition: 200ms 200ms;
  color: ${(props) => (props.danger ? props.theme.colors.red500 : "#373040")};
  &:focus {
    outline-color: ${(props) => props.theme.colors.blue};
  }

  ${(props) =>
    props.onClick
      ? css`
          cursor: pointer;

          &:hover {
            outline-color: ${(props) => props.theme.colors.blue};
          }
        `
      : ``}
`;

const TipInner = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 150px;

  pointer-events: all;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;

  button {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const Btn = styled.button`
  padding: 5px;
  background-color: ${(props) =>
    props.color === "neutral"
      ? props.theme.colors.coolGray100
      : props.theme.colors.indigo100};
  border-radius: 4px;
  transition: 200ms;

  &:focus,
  &:hover {
    background-color: ${(props) =>
      props.color === "neutral"
        ? props.theme.colors.coolGray200
        : props.theme.colors.indigo200};
  }
`;

Editable.propTypes = {
  id: PropTypes.string,
  edit: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  options: PropTypes.array,
  showIcon: PropTypes.bool,
  iconClickOnly: PropTypes.bool,
  dropdownProps: PropTypes.object,
  inputProps: PropTypes.object,
  onSave: PropTypes.func,
};
Editable.defaultProps = {
  id: "editable",
  options: null, // ? don't default to an empty array. Empty array is a valid option for user to pass in if they want a dropdown with no options
  showIcon: false,
  dropdownProps: {},
  inputProps: {},
  iconClickOnly: false,
};

export default Editable;
