import React from "react";

// ! OLD

const TrashIcon = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.25 9.625V14.875M12.75 9.625V14.875M4 6.125H18M17.125 6.125L16.3664 16.7493C16.3349 17.1908 16.1374 17.604 15.8135 17.9056C15.4896 18.2073 15.0634 18.375 14.6208 18.375H7.37925C6.93662 18.375 6.51043 18.2073 6.18652 17.9056C5.86262 17.604 5.66505 17.1908 5.63363 16.7493L4.875 6.125H17.125ZM13.625 6.125V3.5C13.625 3.26794 13.5328 3.04538 13.3687 2.88128C13.2046 2.71719 12.9821 2.625 12.75 2.625H9.25C9.01794 2.625 8.79538 2.71719 8.63128 2.88128C8.46719 3.04538 8.375 3.26794 8.375 3.5V6.125H13.625Z"
        stroke="#6B7280"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
