import React, { useEffect, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { debounce } from "lodash";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useAuth } from "../../../context/authContext";
import { useSockets } from "../../../context/socketsContext";
import { respondTo } from "../../../styles/styleHelpers";
import { formatNotesObject } from "../../../utils/helpers";
import { updateTaskOnApi } from "../../../utils/api";

const TaskNotes = ({ taskId, initialNotes, blockedBy }) => {
  const { user } = useAuth();
  const {
    sendSocketEvent,
    startTaskNotesListeners,
    stopTaskNotesListeners,
  } = useSockets();
  // Instead of tracking the value state, we are tracking the entire
  // editor's state
  const [currentEditorState, setCurrentEditorState] = useState();
  const [currentBlocker, setCurrentBlocker] = useState(null);

  // When getting initialNotes, convert them into a ContentState object,
  // and build the Editor State object
  useEffect(() => {
    if (initialNotes) {
      updateNotes(initialNotes);
    }
  }, [initialNotes]);

  useEffect(() => {
    if (blockedBy) {
      setCurrentBlocker(blockedBy);
    }
  }, [blockedBy]);

  useEffect(() => {
    if (taskId && user) {
      startTaskNotesListeners(taskId, {
        blockCallback: blockNotes,
        unblockCallback: unblockNotes,
        updateNotesCallback: liveUpdateNotes,
      });

      return () => {
        stopTaskNotesListeners(taskId);
      };
    }
  }, [taskId, user]);

  const blockNotes = (newMember) => {
    // Only block if our current user is not the member
    // that is triggering the blocking action
    if (user._id !== newMember.id) {
      setCurrentBlocker(newMember.name);
    }
  };

  // Unblock the notes
  const unblockNotes = () => {
    setCurrentBlocker(null);
  };

  const liveUpdateNotes = (editor, newNotes) => {
    if (user._id !== editor) {
      updateNotes(newNotes);
    }
  };

  const updateNotes = (newNotes) => {
    // Convert raw object to a ContentState object
    const newContent = convertFromRaw(formatNotesObject(newNotes));
    // Create EditorState with our new ContentState
    const newEditorState = EditorState.createWithContent(newContent);

    setCurrentEditorState(EditorState.moveFocusToEnd(newEditorState));
  };

  // uploads to server every X millisecons
  // Is a callback so it doesn't run again every rerender (breaking debounce)
  // Is debounced so that waits until the user stops typing to save
  const uploadNotesDebounced = useCallback(
    debounce((taskId, newContent) => {
      updateTaskOnApi(taskId, { notes: newContent });
    }, 1000),
    [],
  );

  // Updates the state every letter,
  // only uploads data when user stops typing
  const updateState = (newState) => {
    setCurrentEditorState(newState);

    // Gets the ContentState object and converts to raw obj
    const newNotes = convertToRaw(newState.getCurrentContent());
    uploadNotesDebounced(taskId, newNotes);
  };

  const startEditing = () => {
    sendSocketEvent("task:editing-notes", { user: user._id, task: taskId });
  };

  const stopEditing = () => {
    sendSocketEvent("task:stop-editing-notes", {
      user: user._id,
      task: taskId,
    });
  };

  return (
    <Main>
      <EditorWrapper isdisabled={currentBlocker ? true : false}>
        <Editor
          editorState={currentEditorState}
          onEditorStateChange={updateState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-body"
          readOnly={currentBlocker ? true : false}
          onFocus={startEditing}
          onBlur={stopEditing}
        />
      </EditorWrapper>
      {currentBlocker ? (
        <Blocker>{currentBlocker} is editing right now.</Blocker>
      ) : null}
    </Main>
  );
};

const Main = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .editor-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .editor-body {
    //padding: 10px 30px;
    flex: 1;

    ${respondTo("xlarge")} {
      //padding: 38px 64px;
    }
  }
`;

const EditorWrapper = styled.div`
  ${(props) =>
    props.isdisabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
          filter: blur(1px);
        `
      : ``}
`;

const Blocker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 16px;
  line-height: 24px;

  color: ${(props) => props.theme.colors.indigo600};
  background: ${(props) => props.theme.colors.pureWhite};

  padding: 10px 18px;

  border: 1px solid ${(props) => props.theme.colors.coolGray300};
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
`;

export default TaskNotes;
