import React from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled, { css } from "styled-components";
import { differenceInMinutes, isPast, isToday, format } from "date-fns";

import Timeslot from "./timeslot";

import { respondTo } from "../../../styles/styleHelpers";

const Calendar = ({
  events,
  dragging,
  showWeekends,
  handleEventRemove,
  budgets,
  resources,
  calendarRef,
  handleTimerStart,
  isManager,
  user,
  dayOfWeek,
  ...rest
}) => {
  const handleEventContent = ({ event }) => {
    // duration of this event
    const minutes = differenceInMinutes(event.end, event.start);
    const hours = minutes / 60;

    // check if this event is in the past, before today
    const eventPast = isPast(new Date(event.end));
    const eventToday = isToday(new Date(event.end));

    // figure out the completion percentage for this particular timeslot
    let completionPct = 0;

    // find the budget that corresponds to this event
    const thisBudget = budgets.find((budget) => budget.id === event.id);

    if (thisBudget) {
      // get the total amount of hours that have been tracked for the budget so far
      let { trackedHours } = thisBudget;

      // sort the budget's breakdowns by date (earliest to latest)
      thisBudget.breakdown.sort((a, b) => {
        return new Date(a.start) - new Date(b.start);
      });

      // loop over all the timeslots in the breakdown
      thisBudget.breakdown.forEach((timeslot) => {
        // get the duration of the timeslot
        const timeslotMinutes = differenceInMinutes(
          new Date(timeslot.end),
          new Date(timeslot.start),
        );
        const timeslotHours = timeslotMinutes / 60;

        // subtract the duration from the total amount of tracked hours
        trackedHours -= timeslotHours;

        // if this timeslot corresponds to this event on the calendar
        if (
          new Date(event.start).getTime() === new Date(timeslot.start).getTime()
        ) {
          // determine the completion percentage for this timeslot

          // if trackedHours is still positive, then this timeslot has been filled up
          if (trackedHours >= 0) {
            completionPct = 1;
          }
          // if trackedHours just became negative due to the subtraction above, then this timeslot must only be partially filled
          else if (trackedHours + timeslotHours > 0) {
            completionPct = (trackedHours + timeslotHours) / timeslotHours;
          }
          // else: the timeslot hasn't been filled yet, so leave its completionPct as 0
        }
      });
    }

    return (
      <Timeslot
        title={event.extendedProps.code}
        subtitle={event.title}
        duration={hours}
        completionPct={completionPct}
        dragging={dragging}
        isPast={eventPast && !eventToday}
        handleRemove={() => {
          handleEventRemove(event);
        }}
        handleStart={
          // can only start a timer for a timeslot that belongs to the current user
          thisBudget && thisBudget.member._id === user._id
            ? () => {
                handleTimerStart(thisBudget.id);
              }
            : undefined
        }
      />
    );
  };

  const now = new Date();
  const currentHours = now.getHours();

  const minTime = 7;
  const maxTime = 19;

  // initially scroll the calendar down to the current hour minus 2
  const scrollTime = currentHours - 2 < minTime ? minTime : currentHours - 2;

  return (
    <Container
      dragging={dragging}
      days={showWeekends ? 7 : 5}
      dayOfWeek={isManager ? dayOfWeek : undefined}
    >
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        ref={calendarRef}
        plugins={[timeGridPlugin, resourceTimeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={events}
        resources={resources}
        // allow events to be dragged and resized
        editable={true}
        // allow events to be dropped onto the calendar
        droppable={true}
        // only allow the [data-event] elements to be dropped onto the calendar
        dropAccept="[data-event]"
        // events can't overlap when dragging/resizing
        eventOverlap={false}
        // week starts on Monday
        firstDay={1}
        // show/hide weekends
        // (always show when in manager view)
        weekends={isManager ? true : showWeekends}
        // format the text in the column headings
        dayHeaderFormat={{
          weekday: "short",
        }}
        // content of the column headings
        dayHeaderContent={({ date, text }) => {
          // get the day number (1 - 31)
          const day = new Date(date).getUTCDate();

          // render the text of the day (Mon, Tue, etc), followed by the day number
          return (
            <div>
              <div className="header-day-name">{text}</div>
              <div className="header-day-number">{day}</div>
            </div>
          );
        }}
        // display time slots for every 15mins
        slotDuration="00:15:00"
        // only label the time slots by every hour
        slotLabelInterval="01:00:00"
        // formatting for the time slot labels
        slotLabelFormat={{
          // hour: "numeric",
          hour: "2-digit",
          minute: "2-digit", // show the minutes at the end
          // meridiem: "narrow", // "a" or "p"
          meridiem: false,
        }}
        // earliest time to display
        slotMinTime={`${minTime}:00:00`}
        // latest time to display
        slotMaxTime={`${maxTime}:00:00`}
        // how far down to initially scroll the calendar
        scrollTime={`${scrollTime}:00:00`}
        slotMinWidth={1000}
        // hide the "All Day" time slot at the top
        allDaySlot={false}
        // don't allow events to overlap
        slotEventOverlap={false}
        // indicate the current time
        nowIndicator={true}
        nowIndicatorContent={() => {
          const currentTime = format(new Date(), "hh:mm");
          return <div className="indicator-inner">{currentTime}</div>;
        }}
        // remove the header toolbar (like the week navigation)
        headerToolbar={false}
        // height of entire calendar
        height="100%"
        // time displayed on events
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "narrow",
        }}
        // to hide the time displayed on events
        displayEventTime={false}
        // content that goes inside the timeslot
        eventContent={handleEventContent}
        {...rest}
      />
    </Container>
  );
};

const Container = styled.div`
  /* border color used throughout the calendar */
  --fc-border-color: #e9ebf2;

  position: relative;
  flex-grow: 1;

  /* day of week in upper left corner for manager view */
  &::before {
    ${(props) =>
      props.dayOfWeek
        ? // only show the first 3 characters on laptop
          css`
            content: "${props.dayOfWeek.substring(0, 3)}";
          `
        : ``}

    position: absolute;
    top: 0;
    left: 0;

    font-size: 14px;
    font-weight: 700;
    color: #554668;
    line-height: 1;
    text-transform: uppercase;
    pointer-events: none;

    ${respondTo("xlarge")} {
      ${(props) =>
        props.dayOfWeek
          ? css`
            content: "${props.dayOfWeek}";
          `
          : ``}
    }
  }

  .fc {
    width: 100%;
  }

  table {
    margin: 0;
  }

  /* remove border around entire calendar */
  .fc-scrollgrid {
    border: 0;
  }

  /* remove border on bottom and left of calendar */
  .fc-scrollgrid-section-liquid > td {
    border: 0;
  }

  /* top left empty cell */
  .fc-timegrid-axis {
    border: 0;
  }

  /* entire header at top */
  .fc-col-header {
    border-bottom: 1px solid var(--fc-border-color);
  }

  /* header cells */
  .fc-col-header-cell {
    line-height: 1;
    border: 0;

    /* regular days of week (Mon, Tue, etc.) */
    .header-day-name {
      color: #8698ad;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .header-day-number {
      font-size: 18px;
      font-weight: 600;
    }

    /* resource columns (for manager view) */
    &.fc-resource {
      color: ${(props) => props.theme.colors.slate};
      font-weight: 600;
    }
  }

  /* padding between header cells and body of calendar */
  .fc-col-header-cell-cushion {
    padding: 0;
    padding-bottom: 9px;
  }

  .fc-scrollgrid-section-header {
    /* remove border-right on header */
    > td {
      border: 0;
    }

    /* remove scrollbar on header cells */
    .fc-scroller {
      overflow: hidden !important;
    }
  }

  /* slots on the left */
  .fc-timegrid-slot-label {
    border-right-color: transparent;
    color: #8698ad;
  }

  /* times on the left */
  .fc-timegrid-slot-label-frame {
    text-align: left;
    transform: translateY(150%);
  }
  .fc-timegrid-slot-label-cushion {
    padding-left: 0;
  }

  /* force the labels col on the left to be 75px (is 50px by default, and can't find any other way to change it) */
  colgroup > col:first-child {
    ${respondTo("xlarge")} {
      width: 75px !important;
    }
  }

  /* add the last time to the time labels on the left */
  /* .fc-timegrid-slots tr:last-child .fc-timegrid-slot-label {
    text-align: right;

    &::before {
      content: "7:00p";
      padding: 0 4px;
    }
  } */

  /* all the columns except the time col on the left */
  .fc-timegrid-col:not(.fc-timegrid-axis) {
    background-color: transparent;
    border-color: transparent;
    transition: border-color 200ms linear;
  }

  .fc-timegrid-now-indicator-container {
    overflow: visible;
  }

  /* current time arrow */
  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  /* today's current time indicator horizontal line */
  .fc-timegrid-now-indicator-line {
    border-color: #e55541;
    pointer-events: none;
    z-index: 1;

    /* current time text */
    .indicator-inner {
      position: absolute;
      top: 0;
      left: 25px;
      transform: translateY(-50%);

      color: white;
      font-weight: 500;
      padding: 5px 8px;
      border-radius: 4px;
      background-color: #ee442c;
    }
  }

  /* the 75px width of the label col */
  --indicator-left-pad: -75px;

  /* indicator positioning based on what day it is (have to do it manually like this otherwise the calendar will horizontally scroll if we make the indicator wider than the whole calendar) */
  .fc-day-mon .fc-timegrid-now-indicator-line {
    left: calc(var(--indicator-left-pad));

    right: ${(props) => `calc(-${props.days - 1}00% - ${props.days - 1}px)`};
  }
  .fc-day-tue .fc-timegrid-now-indicator-line {
    left: calc(-100% - 1px + var(--indicator-left-pad));

    right: ${(props) => `calc(-${props.days - 2}00% - ${props.days - 2}px)`};
  }
  .fc-day-wed .fc-timegrid-now-indicator-line {
    left: calc(-200% - 2px + var(--indicator-left-pad));

    right: ${(props) => `calc(-${props.days - 3}00% - ${props.days - 3}px)`};
  }
  .fc-day-thu .fc-timegrid-now-indicator-line {
    left: calc(-300% - 3px + var(--indicator-left-pad));
    right: calc(-300% - 3px);

    right: ${(props) => `calc(-${props.days - 4}00% - ${props.days - 4}px)`};
  }
  .fc-day-fri .fc-timegrid-now-indicator-line {
    left: calc(-400% - 4px + var(--indicator-left-pad));

    right: ${(props) => `calc(-${props.days - 5}00% - ${props.days - 5}px)`};
  }
  .fc-day-sat .fc-timegrid-now-indicator-line {
    left: calc(-500% - 5px + var(--indicator-left-pad));

    right: ${(props) => `calc(-${props.days - 6}00% - ${props.days - 6}px)`};
  }
  .fc-day-sun .fc-timegrid-now-indicator-line {
    left: calc(-600% - 6px + var(--indicator-left-pad));
    right: 0;
  }

  /* when in the manager view */
  .fc-day.fc-resource {
    /* the second col (which is the first resource. the first col is the time labels) */
    &:nth-of-type(2) {
      /* position it like we would for Monday, since its the first of the resource cols */
      .fc-timegrid-now-indicator-line {
        left: calc(var(--indicator-left-pad));

        right: ${(props) =>
          `calc(-${props.days - 1}00% - ${props.days - 1}px)`};
      }
    }

    /* hide the indicator line for all but the first 2 cols (the first col is the time labels) */
    /* (the indicator line appears in all the cols, so the current time would appear duplicated several times) */
    &:nth-of-type(n + 3) {
      .fc-timegrid-now-indicator-line {
        display: none;
      }
    }
  }

  /* the lighter horizontal lines in between the main hour lines that mark the 15, 30, and 45min marks */
  .fc-timegrid-slot-minor {
    border-color: transparent;
  }

  .fc-timegrid-event {
    border: 0;
    border-radius: 0;
    background-color: transparent;

    left: 10px;
    right: 10px;

    /* so it overlaps with the calendar's border's */
    bottom: -1px;

    cursor: ${(props) => (props.dragging ? "grabbing" : "grab")};

    &:active {
      cursor: grabbing;
    }
    &:focus {
      outline: none;
    }

    ${respondTo("xlarge")} {
      left: 15px;
      right: 15px;
    }
  }

  .fc-v-event .fc-event-main {
    color: black;
    padding: 0;
    display: flex;
  }

  /* remove the small margin around the timeslots */
  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }

  /* when one of the day columns is being hovered, give it a higher z-index than the others (so the elements on the outside that appear on hover can easily be hovered on without hovering onto the next column and having them disappear) */
  .fc-timegrid-col-events {
    &:hover {
      z-index: 10;
    }
  }
`;

Calendar.propTypes = {
  events: PropTypes.array,
  dragging: PropTypes.bool,
  showWeekends: PropTypes.bool,
  handleEventRemove: PropTypes.func,
  budgets: PropTypes.array,
  resources: PropTypes.array,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  handleTimerStart: PropTypes.func,
  isManager: PropTypes.bool,
  user: PropTypes.object,
  dayOfWeek: PropTypes.string,
};

Calendar.defaultProps = {
  events: [],
  dragging: false,
  showWeekends: false,
  handleEventRemove: null,
  budgets: [],
  resources: [],
  calendarRef: null,
  handleTimerStart: null,
  isManager: false,
  user: null,
  dayOfWeek: null,
};

export default Calendar;
