import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import SimpleLink from "../../components/links/SimpleLink";
import { Field, Label, Input } from "../../components/Form";
import Button from "../../components/buttons/Button";
import Toggle, { ToggleContainer } from "../../components/Toggle";
import SelectDropdown from "../../components/SelectDropdown";

import { getCompaniesFromApi } from "../../utils/api";
import {
  createAccessLevelOnApi,
  getAllProjectsFromApi,
  getTeamsFromApi,
} from "./utils/api";

const AccessLevelCreate = () => {
  const history = useHistory();
  const { openAlertPopup, setLoading } = useNotifications();

  const [name, setName] = useState("");
  const [clients, setClients] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [viewClients, setViewClients] = useState(false);
  const [modifyClients, setModifyClients] = useState(false);
  const [projects, setProjects] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [viewProjects, setViewProjects] = useState(false);
  const [modifyProjects, setModifyProjects] = useState(false);
  const [teams, setTeams] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [viewTeams, setViewTeams] = useState(false);
  const [modifyTeams, setModifyTeams] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [modifyUsers, setModifyUsers] = useState(false);
  const [viewRates, setViewRates] = useState(false);
  const [modifyRates, setModifyRates] = useState(false);
  const [viewAccessLevels, setViewAccessLevels] = useState(false);
  const [modifyAccessLevels, setModifyAccessLevels] = useState(false);
  const [viewBookingReports, setViewBookingReports] = useState(false);
  const [modifyBookingReports, setModifyBookingReports] = useState(false);
  const [viewSalesReports, setViewSalesReports] = useState(false);
  const [modifySalesReports, setModifySalesReports] = useState(false);
  const [viewTasks, setViewTasks] = useState(false);
  const [modifyTasks, setModifyTasks] = useState(false);
  const [viewAdminDashboard, setViewAdminDashboard] = useState(false);

  /*
  |--------------------------------------------------------------------------
  | Create the access level upon submission
  |--------------------------------------------------------------------------
  */
  const createAccessLevel = async (e) => {
    e.preventDefault();

    let tempClients, tempProjects, tempTeams;

    if (selectedClients) {
      tempClients = [...selectedClients];

      tempClients = tempClients.map((client) => {
        return client.value;
      });
    } else {
      tempClients = [];
    }
    if (selectedProjects) {
      tempProjects = [...selectedProjects];

      tempProjects = tempProjects.map((project) => {
        return project.value;
      });
    } else {
      tempProjects = [];
    }
    if (selectedTeams) {
      tempTeams = [...selectedTeams];

      tempTeams = tempTeams.map((team) => {
        return team.value;
      });
    } else {
      tempTeams = [];
    }

    const data = {
      name,
      clients: {
        view: viewClients,
        modify: modifyClients,
      },
      specificClients: tempClients,
      projects: {
        view: viewProjects,
        modify: modifyProjects,
      },
      specificProjects: tempProjects,
      teams: {
        view: viewTeams,
        modify: modifyTeams,
      },
      specificTeams: tempTeams,
      users: {
        view: viewUsers,
        modify: modifyUsers,
      },
      rates: {
        view: viewRates,
        modify: modifyRates,
      },
      accessLevels: {
        view: viewAccessLevels,
        modify: modifyAccessLevels,
      },
      bookingReports: {
        view: viewBookingReports,
        modify: modifyBookingReports,
      },
      salesReports: {
        view: viewSalesReports,
        modify: modifySalesReports,
      },
      tasks: {
        view: viewTasks,
        modify: modifyTasks,
      },
      adminDashboard: {
        view: viewAdminDashboard,
      },
    };

    try {
      await createAccessLevelOnApi(data);
      openAlertPopup("Success", `${name} has been created.`, true);
      history.push(`/manage/accessLevels`);
    } catch (error) {
      console.error("error", error);
      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't create the access level.`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Clients
  |--------------------------------------------------------------------------
  */
  const getClients = async () => {
    try {
      const results = await getCompaniesFromApi();
      setClients(results);
      return;
    } catch (error) {
      console.error("Get clients error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      const results = await getAllProjectsFromApi();
      setProjects(results);
      return;
    } catch (error) {
      console.error("Get projects error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Teams
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    try {
      const results = await getTeamsFromApi();
      setTeams(results);
      return;
    } catch (error) {
      console.error("Get teams error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all api data for projects, clients, teams
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setLoading(true);
    getProjects();
    getClients();
    getTeams();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (projects?.length && clients?.length && teams?.length) {
      setLoading(false);
    }
  }, [projects, clients]); //eslint-disable-line

  return (
    <Container>
      <HeaderSection>
        <Heading>Create Access Level</Heading>
        <SimpleLink as={Link} to={`/manage/accessLevels/`}>
          Back to Access Levels
        </SimpleLink>
      </HeaderSection>
      {projects &&
      projects.length &&
      clients &&
      clients.length &&
      teams &&
      teams.length ? (
        <EditSection onSubmit={createAccessLevel}>
          <Field style={{ width: "300px" }}>
            <Label>Name</Label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Access Level Name"
              required
            />
          </Field>

          <PermissionsTitle>Permissions</PermissionsTitle>

          <FlexContainer>
            <LeftSection>
              <Field>
                <Label>Clients</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Clients"
                    type="checkbox"
                    onChange={() => {
                      setViewClients(!viewClients);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Clients"
                    type="checkbox"
                    onChange={() => {
                      setModifyClients(!modifyClients);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Clients</Label>
                <SelectDropdown
                  placeholder="Select Client"
                  isMulti
                  onChange={(e) => {
                    setSelectedClients(e);
                  }}
                  options={clients.map((client) => {
                    return {
                      label: client.name,
                      value: client._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Projects</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Projects"
                    type="checkbox"
                    onChange={() => {
                      setViewProjects(!viewProjects);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Projects"
                    type="checkbox"
                    onChange={() => {
                      setModifyProjects(!modifyProjects);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Projects</Label>
                <SelectDropdown
                  placeholder="Select Project"
                  isMulti
                  onChange={(e) => {
                    setSelectedProjects(e);
                  }}
                  options={projects.map((project) => {
                    return {
                      label: project.name,
                      value: project._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Teams</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Teams"
                    type="checkbox"
                    onChange={() => {
                      setViewTeams(!viewTeams);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Teams"
                    type="checkbox"
                    onChange={() => {
                      setModifyTeams(!modifyTeams);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Teams</Label>
                <SelectDropdown
                  placeholder="Select Team"
                  isMulti
                  onChange={(e) => {
                    setSelectedTeams(e);
                  }}
                  options={teams.map((team) => {
                    return {
                      label: team.name,
                      value: team._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Users</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Users"
                    type="checkbox"
                    onChange={() => {
                      setViewUsers(!viewUsers);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Users"
                    type="checkbox"
                    onChange={() => {
                      setModifyUsers(!modifyUsers);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Button type="submit">Create Access Level</Button>
            </LeftSection>

            <RightSection>
              <Field>
                <Label>Rates</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Rates"
                    type="checkbox"
                    onChange={() => {
                      setViewRates(!viewRates);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Rates"
                    type="checkbox"
                    onChange={() => {
                      setModifyRates(!modifyRates);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Access Levels</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Access Levels"
                    type="checkbox"
                    onChange={() => {
                      setViewAccessLevels(!viewAccessLevels);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Access Levels"
                    type="checkbox"
                    onChange={() => {
                      setModifyAccessLevels(!modifyAccessLevels);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Booking Reports</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Booking Reports"
                    type="checkbox"
                    onChange={() => {
                      setViewBookingReports(!viewBookingReports);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Booking Reports"
                    type="checkbox"
                    onChange={() => {
                      setModifyBookingReports(!modifyBookingReports);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Sales Reports</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Sales Reports"
                    type="checkbox"
                    onChange={() => {
                      setViewSalesReports(!viewSalesReports);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Sales Reports"
                    type="checkbox"
                    onChange={() => {
                      setModifySalesReports(!modifySalesReports);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Tasks</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Tasks"
                    type="checkbox"
                    onChange={() => {
                      setViewTasks(!viewTasks);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Tasks"
                    type="checkbox"
                    onChange={() => {
                      setModifyTasks(!modifyTasks);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Admin Dashboard</Label>
                <ToggleContainer>
                  <Toggle
                    label="View Admin Dashboard"
                    type="checkbox"
                    onChange={() => {
                      setViewAdminDashboard(!viewAdminDashboard);
                    }}
                  />
                </ToggleContainer>
              </Field>
            </RightSection>
          </FlexContainer>
        </EditSection>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const HeaderSection = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0px;
`;

const PermissionsTitle = styled.h3`
  font-size: 22px;
  color: #373040;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 10px;
`;

const EditSection = styled.form``;

const FlexContainer = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  width: 300px;
  margin-right: 50px;
`;

const RightSection = styled.div`
  width: 300px;
`;

export default AccessLevelCreate;
