import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import * as QueryString from "query-string";
import { isPast } from "date-fns";
import { startCase } from "lodash";

import { colors } from "../../styles/theme";
import { respondTo } from "../../styles/styleHelpers";
import { getStandardizedDate, isNumber } from "../../utils/helpers";

import { useActiveTimer } from "../../context/activeTimerContext";
import { useSockets } from "../../context/socketsContext";

import {
  getProjectFromApi,
  getMembersFromApi,
  editProjectOnApi,
} from "../manage/utils/api";
import { getSettings, updateProjectChatLastViewed } from "../../utils/api";

import { useFile } from "../../context/fileContext";
import { useAuth } from "../../context/authContext";

import SimpleLink from "../../components/links/SimpleLink";
import Tabs from "../../components/Tabs";
import ValueChip, { NumberChip } from "../../components/ValueChip";
import Breadcrumbs from "../../components/breadcrumbs";

import Pencil from "../../components/icons/PencilEditFill";
import NotificationsIcon from "../../components/icons/NotificationsIcon";

import Button from "../../components/newButtons/Button";

import ProjectChat from "./components/ProjectChat";
import Dashboard from "./components/Dashboard";
import Stages from "./components/Stages";
import BriefContainer from "./components/BriefContainer";
import Client from "./components/Client";
import BudgetTab from "./components/BudgetTab";
import Recaps from "./components/Recaps";
import ProjectProgress from "./components/ProjectProgress";
import TasksList from "./components/TasksList";
import DeliverablesList from "./components/DeliverablesList";
import Phases from "./components/Phases";
import Tooltip from "../../components/Tooltip";

const ProjectView = () => {
  const { id } = useParams();
  const location = useLocation();
  const match = useRouteMatch();

  const { openFile } = useFile();
  const { isImportant, isAdmin, user } = useAuth();
  const { socket, startProjectListeners, stopProjectListeners } = useSockets();
  const { openStopModal, currentProjectId } = useActiveTimer();

  const [project, setProject] = useState(null);
  const [harvestProject, setHarvestProject] = useState(null);
  const [projectName, setProjectName] = useState(null);
  // const [projectManagers, setProjectManagers] = useState(null);
  // const [projectManager, setProjectManager] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [comments, setComments] = useState(null);
  const [expenses, setExpenses] = useState(null);
  const [expenseBudget, setExpenseBudget] = useState(null);
  const [activeMembers, setActiveMembers] = useState([]);
  const [stageNumber, setStageNumber] = useState(null);
  const [stages, setStages] = useState([]);
  const [clientBriefQuestions, setClientBriefQuestions] = useState([]);
  const [projectBriefQuestions, setProjectBriefQuestions] = useState([]);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [approvedDeliverables, setApprovedDeliverables] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalDeliverables, setTotalDeliverables] = useState(0);
  const [chatMembers, setChatMembers] = useState(null);
  const [allMembers, setAllMembers] = useState([]);
  const [phases, setPhases] = useState(null);

  useEffect(() => {
    getProject();
    getQuestions();
    // getMembers();

    // If the url passes in a query of file=, we open the file automatically
    const params = QueryString.parse(location.search);
    if (params.file) {
      openFile(params.file);
    }

    // keydown event for the sidebar collapse shortcut
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [id]); //eslint-disable-line

  useEffect(() => {
    if (currentProjectId && currentProjectId !== id) {
      openStopModal("I see you're changing projects. Let's clock out first");
    }
  }, [currentProjectId]); //eslint-disable-line

  useEffect(() => {
    if (project) {
      setTotalDeliverables(project.deliverables?.length || 0);

      setTotalTasks(project.projectTasks?.length || 0);

      const deliverablesApproved = project.deliverables?.filter(
        (deliverable) => deliverable.status.toLowerCase() === "approved",
      );

      const tasksCompleted = project.projectTasks?.filter(
        (task) => task.status.toLowerCase() === "completed",
      );

      setApprovedDeliverables(deliverablesApproved?.length || 0);

      setCompletedTasks(tasksCompleted?.length || 0);
    }
  }, [project]);

  /*
  |--------------------------------------------------------------------------
  | Opt in/out to recap notifications
  |--------------------------------------------------------------------------
  */
  const toggleNotification = async () => {
    const projectData = { ...project };

    try {
      const ignoreRecaps = project.ignoreRecaps || [];

      // If there is a list of users who opted out, and it includes the user, remove them from the list
      if (ignoreRecaps?.length && ignoreRecaps.includes(user._id)) {
        const index = ignoreRecaps.indexOf(user._id);
        ignoreRecaps.splice(index, 1);
      }
      // If there is no users who opted out of recap notifications, add the current user to the list
      else {
        ignoreRecaps.push(user._id);
      }

      const data = {
        projectId: project._id,
        database: {
          ignoreRecaps,
        },
      };

      await editProjectOnApi(data);

      setProject({
        ...projectData,
        ignoreRecaps,
      });
    } catch (error) {
      console.error("error subscribing to recap notifications", error);

      setProject(projectData);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Sets stage number
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    // stage number that we should jump to
    let jumpTo = 1;

    if (project?.stages) {
      // to track whether a previous stage is complete
      let prevIncomplete = false;

      // loop over all the stages
      stages.forEach((stageName, stageIndex) => {
        const stageCompleted = project.stages?.[stageName]?.completed;

        // if this stage has been completed (and previous stages are complete)
        if (stageCompleted && !prevIncomplete) {
          // jump to the stage after this one (plus 2 since the indexes start at 0 but our stage numbers start at 1)
          jumpTo = stageIndex + 2;
        } else {
          prevIncomplete = true;
        }
      });
    }

    setStageNumber(jumpTo);
  }, [project, stages]); //eslint-disable-line

  // when chat is toggled or new messages are added
  useEffect(() => {
    // if chat is open
    if (isChatOpen && chatMembers) {
      // update lastViewed time
      // ? but don't update it in state yet, because we want the proper messages to still show up as unread
      updateChatLastViewed(false);
    }
  }, [isChatOpen, comments]);

  // Whenever comments or expenses changes, we start the socket listener
  // When it's all done, we stop the listener NOTE: This is important so the listener is only running on this page
  useEffect(() => {
    if (id && socket) {
      const listenerObject = {
        activeMembersCallback: (newMembers) => {
          setActiveMembers(newMembers);
        },
        phasesCallback: (newPhases) => {
          setPhases(newPhases);
        },
      };

      if (comments) {
        listenerObject.commentCallback = liveUpdateComments;
        listenerObject.commentEditCallback = liveUpdateEditedComment;
        listenerObject.commentDeleteCallback = liveUpdateDeletedComment;
      }
      if (expenses) {
        listenerObject.expenseCallback = (newExpense) => {
          setExpenses([...expenses].unshift(newExpense));
        };
      }

      startProjectListeners(id, listenerObject);

      return () => {
        stopProjectListeners(id);
      };
    }
  }, [socket, id, comments, expenses]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
| Get project information
  |--------------------------------------------------------------------------
  */
  async function getProject() {
    const projectResult = await getProjectFromApi(id);
    const membersResult = await getMembersFromApi();

    const dbProject = projectResult.databaseProject;
    const harvestProject = projectResult.harvestProject;

    setHarvestProject(harvestProject);
    setAllMembers(membersResult);

    setProject(dbProject);
    setProjectName(dbProject.name);
    setStages(dbProject.stages ? Object.keys(dbProject.stages) : []);

    setActiveMembers(dbProject.activeMembers);
    setExpenseBudget(dbProject.expenseBudget);

    // If no comments come in, we set it to an empty array so the chat shows none message
    setComments(dbProject.comments || []);

    setChatMembers(dbProject.chatMembers || []);

    setPhases(dbProject.phases || []);

    // If no expenses come in, we set it to an empty array so the budget shows none message
    setExpenses(dbProject.expenses?.reverse() || []);
  }

  // get the questions for the client and project brief from the general company settings
  const getQuestions = async () => {
    try {
      const settings = await getSettings();

      if (settings?.clientBrief?.length) {
        setClientBriefQuestions(settings.clientBrief);
      }
      if (settings?.projectBrief?.length) {
        setProjectBriefQuestions(settings.projectBrief);
      }
    } catch (error) {
      console.error("Error getting questions", error);
    }
  };

  const updateChatLastViewed = (updateInState = true) => {
    try {
      // update state
      if (updateInState) {
        // this user with their updated lastViewed time
        const chatMember = {
          member: user._id,
          lastViewed: new Date(),
        };

        const updatedChatMembers = [...chatMembers];

        // check if this user is already in the chatMembers array
        const chatMemberIndex = updatedChatMembers.findIndex(
          (thisMember) => thisMember.member === chatMember.member,
        );

        // if user is in the array already
        if (chatMemberIndex > -1) {
          // update that index in the array
          updatedChatMembers[chatMemberIndex] = chatMember;
        } else {
          // user isn't in array yet, so add them
          updatedChatMembers.push(chatMember);
        }

        setChatMembers(updatedChatMembers);
      }

      // update api
      updateProjectChatLastViewed(id);
    } catch (error) {
      console.error("chat last viewed error", error);
    }
  };

  const handleTasksUpdate = (newTasks) => {
    setTotalTasks(newTasks?.length || 0);

    const tasksCompleted = newTasks?.filter(
      (task) => task.status.toLowerCase() === "completed",
    );

    setCompletedTasks(tasksCompleted?.length || 0);
  };

  const handleDeliverablesUpdate = (newDeliverables) => {
    setTotalDeliverables(newDeliverables?.length || 0);

    const deliverablesApproved = newDeliverables?.filter(
      (deliverable) => deliverable.status.toLowerCase() === "approved",
    );

    setApprovedDeliverables(deliverablesApproved?.length || 0);
  };

  // This only runs if a socket message is recieved to live update the comments
  const liveUpdateComments = (newComment) => {
    setComments([...comments, newComment]);
  };

  const liveUpdateEditedComment = (updatedComment) => {
    setComments(
      // loop over all the comments
      comments.map((comment) => ({
        ...comment,
        message:
          // if the author and date of the edited comment match this one, update its message
          comment.author._id === updatedComment.author._id &&
          comment.postedDate === updatedComment.postedDate
            ? updatedComment.message
            : comment.message,
      })),
    );
  };

  const liveUpdateDeletedComment = (deletedComment) => {
    // filter out the deleted comment
    setComments(
      comments.filter(
        (comment) =>
          // if the author or postedDate are different, then its not the one that was deleted
          comment.author._id !== deletedComment.author._id ||
          comment.postedDate !== deletedComment.postedDate,
      ),
    );
  };

  // Hides the sidebar when you press CMD+K
  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "k") {
      setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
    }
  };

  const totalEverything = totalTasks + totalDeliverables;
  const totalDone = completedTasks + approvedDeliverables;

  return (
    <>
      <MainContainer isSidebarOpen={isSidebarOpen}>
        {project?.endDate && isPast(getStandardizedDate(project?.endDate)) ? (
          <OverdueBanner>Project is Overdue</OverdueBanner>
        ) : null}

        <Header>
          {project ? (
            <>
              <TopHeader>
                <Breadcrumbs
                  crumbs={[
                    {
                      name: project.client.name,
                      link: isAdmin
                        ? `/manage/clients/${project.client._id}`
                        : undefined,
                    },
                    { name: projectName },
                  ]}
                />

                {stages.length && isNumber(stageNumber) ? (
                  <ValueChip
                    color="indigo"
                    as={isImportant ? Link : undefined}
                    to={isImportant ? `/projects/${id}/stages` : undefined}
                    hasHover={isImportant}
                  >
                    {stages?.[stageNumber - 1]
                      ? startCase(stages[stageNumber - 1])
                      : null}
                  </ValueChip>
                ) : null}
              </TopHeader>

              <ProjectHeader>
                <h1>
                  {projectName
                    ? `${
                        harvestProject ? `${harvestProject.code} - ` : ""
                      }${projectName}`
                    : ``}{" "}
                  {project.archived ? (
                    <small style={{ color: colors.red }}>(ARCHIVED)</small>
                  ) : null}
                </h1>

                <OpenTasks color="blueGray">
                  {totalEverything - totalDone}
                </OpenTasks>

                <ProjectProgress current={totalDone} total={totalEverything} />

                {isImportant ? (
                  <NotificationAlerts
                    data-tip={true}
                    data-for="recap"
                    onClick={() => toggleNotification()}
                  >
                    <Tooltip id="recap" place="top">
                      {project.ignoreRecaps?.length &&
                      project.ignoreRecaps.includes(user._id)
                        ? "Turn on recap notifications"
                        : "Turn off recap notifications"}
                    </Tooltip>
                    <IconContainer
                      inActive={
                        project.ignoreRecaps?.length &&
                        project.ignoreRecaps.includes(user._id)
                      }
                    >
                      <NotificationsIcon />
                    </IconContainer>
                  </NotificationAlerts>
                ) : null}

                <div style={{ marginLeft: "auto" }}>
                  {isImportant ? (
                    <EditButton
                      forwardedAs={Link}
                      to={`/projects/${id}/edit`}
                      small
                    >
                      <Pencil />
                      <span>Edit</span>
                    </EditButton>
                  ) : null}
                </div>
              </ProjectHeader>
            </>
          ) : null}
        </Header>

        <TabsContainer>
          <Tabs
            tabs={[
              {
                name: "Dashboard",
                id: "dashboard",
                to: `/projects/${id}`,
                exact: true,
              },
              {
                name: "Client Brief",
                id: "client-brief",
                to: `/projects/${id}/client`,
                exact: true,
              },
              {
                name: "Project Brief",
                id: "project-brief",
                to: `/projects/${id}/brief`,
                exact: true,
              },
              {
                name: "Phase",
                id: "phase",
                to: `/projects/${id}/phase`,
                exact: true,
              },
              {
                name: "Deliverables",
                id: "deliverables",
                to: `/projects/${id}/deliverables`,
                exact: true,
                number: totalDeliverables - approvedDeliverables,
              },
              {
                name: "Tasks",
                id: "tasks",
                to: `/projects/${id}/tasks`,
                exact: true,
                number: totalTasks - completedTasks,
              },
              {
                name: "Recurring Tasks",
                id: "recurring-tasks",
                to: `/projects/${id}/recurring`,
                exact: true,
                number: project?.projectTasks.filter(
                  (task) => task.recurring?.active,
                ).length,
              },
              {
                name: "Recaps",
                id: "recaps",
                to: `/projects/${id}/recaps`,
                exact: true,
              },
              user.accessLvl === "admin" || user.accessLvl === "super"
                ? {
                    name: "Budget",
                    id: "budget",
                    to: `/projects/${id}/budget`,
                    exact: true,
                  }
                : {},
            ]}
          />

          {comments ? (
            <ProjectChat
              isOpen={isChatOpen}
              projectId={id}
              messages={comments}
              activeMembers={activeMembers}
              chatMembers={chatMembers}
              onToggle={() => {
                // if chat is open and is now being closed
                if (isChatOpen) {
                  // update lastViewed for this user
                  updateChatLastViewed();
                }

                setIsChatOpen(!isChatOpen);
              }}
              onClose={() => {
                // update lastViewed when user closes chat
                updateChatLastViewed();

                setIsChatOpen(false);
              }}
              onSend={() => {
                // update lastViewed when user sends a new message
                updateChatLastViewed();
              }}
            />
          ) : null}
        </TabsContainer>

        <ProjectBody>
          <ProjectBodyInner>
            {project ? (
              <Switch>
                <Route
                  exact
                  path={`${match.path}/stages`}
                  render={(props) =>
                    stageNumber && project && stages?.length ? (
                      <Stages
                        {...props}
                        stages={stages}
                        project={project}
                        setProject={setProject}
                        stageNumber={stageNumber}
                        setStageNumber={setStageNumber}
                        projectBriefQuestions={projectBriefQuestions}
                      />
                    ) : (
                      <></>
                    )
                  }
                />

                <Route
                  exact
                  path={`${match.path}/brief`}
                  render={(props) => (
                    <BriefContainer
                      {...props}
                      project={project}
                      setProject={setProject}
                      isImportant={isImportant}
                      projectBriefQuestions={projectBriefQuestions}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/phase`}
                  render={(props) => (
                    <Phases {...props} projectId={id} phases={phases || []} />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/deliverables`}
                  render={(props) => (
                    <DeliverablesList
                      {...props}
                      projectId={id}
                      clientId={project.client._id}
                      phases={phases || []}
                      onDeliverablesUpdate={handleDeliverablesUpdate}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/tasks`}
                  render={(props) => (
                    <TasksList
                      {...props}
                      project={project}
                      allMembers={allMembers}
                      projectId={id}
                      projectName={project ? project.name : ""}
                      phases={phases || []}
                      onTasksUpdate={handleTasksUpdate}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/recurring`}
                  render={(props) => (
                    <TasksList
                      {...props}
                      recurring
                      project={project}
                      allMembers={allMembers}
                      projectId={id}
                      projectName={project ? project.name : ""}
                      phases={phases || []}
                      onTasksUpdate={handleTasksUpdate}
                    />
                  )}
                />

                <Route
                  exact
                  path={`${match.path}/client`}
                  render={(props) => (
                    <Client
                      {...props}
                      client={project.client}
                      clientBriefQuestions={clientBriefQuestions}
                    />
                  )}
                />

                {user.accessLvl === "admin" || user.accessLvl === "super" ? (
                  <Route
                    exact
                    path={`${match.path}/budget`}
                    render={(props) => (
                      <BudgetTab
                        {...props}
                        project={project}
                        expenseBudget={expenseBudget}
                        expenses={expenses}
                        activeMembers={activeMembers}
                      />
                    )}
                  />
                ) : null}

                <Route
                  exact
                  path={`${match.path}/recaps`}
                  render={(props) => (
                    <Recaps
                      {...props}
                      project={project._id}
                      initialRecaps={project.recaps.sort(
                        (a, b) => new Date(b.date) - new Date(a.date),
                      )}
                    />
                  )}
                />

                <Route
                  path={match.path}
                  render={(props) => (
                    <Dashboard
                      {...props}
                      projectId={id}
                      project={project}
                      phases={phases || []}
                    />
                  )}
                />
              </Switch>
            ) : null}
          </ProjectBodyInner>
        </ProjectBody>
      </MainContainer>
    </>
  );
};

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;

  font-family: ${(props) => props.theme.fontFamily_Inter};

  /* background-color: ${(props) => props.theme.colors.coolGray50}; */
  overflow-x: auto;

  ${(props) =>
    props.isSidebarOpen
      ? css`
          margin-right: 300px;

          ${respondTo("xlarge")} {
            margin-right: 340px;
          }
        `
      : ``}
`;

const Header = styled.div`
  position: sticky;
  left: 0;
  z-index: 8;

  background-color: white;
  padding: 25px 40px 0;
`;

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ProjectHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  h1 {
    font-size: ${(props) => props.theme.fontSize};
    font-weight: 500;
    color: ${(props) => props.theme.colors.darkSlate};
    margin-bottom: 0;
    margin-right: 8px;
  }
`;

const TabsContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 7;
  display: flex;
  align-items: center;

  padding: 15px 40px 0;
  background-color: #fff;
  /* slight border bottom so theres some space between the sticky tabs and the body content */
  border-bottom: 2px solid #fff;
`;

const EditButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;

    path {
      fill: white;
    }
  }
`;

// this one has the bg color and the padding
const ProjectBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* padding: 40px; */
  padding: 15px 40px;
  /* background-color: ${(props) => props.theme.colors.coolGray50}; */
`;

// this one is the direct parent of the subroutes, so it applies the flex-grow to whatever the subroutes render
const ProjectBodyInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > * {
    flex-grow: 1;
  }
`;

const OverdueBanner = styled.div`
  background-color: #df5757;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3px 0px;
  font-weight: 500;
`;

const OpenTasks = styled(NumberChip)`
  margin-right: 47px;
`;

const shakeBottom = keyframes`
   0%,
    50%,
    100% {
      transform: scale(1) rotate(0deg);
      transform-origin: 50% 100%;
    }
    5% {
      transform: scale(1.1) rotate(2deg);
    }
    10%,
    20%,
    30% {
      transform: scale(1.1) rotate(-4deg);
    }
    15%,
    25%,
    35% {
      transform: scale(1.1) rotate(4deg);
    }
    40% {
      transform: scale(1.1) rotate(-2deg);
    }
    45% {
      transform: scale(1.1) rotate(2deg);
    }
`;

const NotificationAlerts = styled.div`
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    svg {
      animation: ${shakeBottom} 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
        infinite;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  opacity: ${(props) => (props.inActive ? ".3" : "1")};
  transition: 0.3s ease-in-out;

  path {
    stroke: ${(props) =>
      props.inactive ? "black" : props.theme.colors.indigo600};
  }
`;

export default ProjectView;
