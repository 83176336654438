import React from "react";
import styled from "styled-components";

export default ({ ...rest }) => {
  return (
    <Svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#7BC1A8" />
      <path d="M4 8L6 10.5L11.5 5" stroke="#FEFFFF" strokeWidth="2" />
    </Svg>
  );
};

const Svg = styled.svg``;
