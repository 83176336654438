import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { darken, rgba } from "polished";
import { buttonReset } from "../../styles/styleHelpers";

const Button = ({
  children,
  small,
  disabled,
  color,
  noBorderRadius,
  hollow,
  ...rest
}) => {
  return (
    <MainButton
      disabled={disabled}
      small={small}
      noBorderRadius={noBorderRadius}
      color={color}
      isHollow={hollow ? true : false}
      {...rest}
    >
      {children}
    </MainButton>
  );
};

const MainButton = styled.button`
  ${buttonReset()}
  position: relative;
  min-width: ${(props) => (props.small ? `0` : `200px`)};
  overflow: hidden;
  z-index: 1;

  font-weight: 500;
  font-size: ${(props) => props.theme.fontSize_xxs};
  text-align: center;
  border-radius: 3px;
  padding: ${(props) => (props.small ? `12px 10px 12px` : `17px 15px 17px`)};
  transition: transform 0.2s;

  cursor: pointer;
  color: ${(props) => props.theme.colors.pureWhite};

  background-color: ${(props) =>
    props.color
      ? props.theme.colors[props.color]
      : props.isHollow
      ? props.theme.colors.pureWhite
      : props.theme.colors.blue};

  border: 1px solid;
  border-color: ${(props) =>
    props.color
      ? rgba(props.theme.colors[props.color], 0.4)
      : rgba(props.theme.colors.blue, 0.4)};

  &:disabled {
    cursor: default;
    pointer-events: none;
    color: ${(props) => props.theme.colors.slate};
    background-color: ${(props) => props.theme.colors.lightSlate};
    border: 1px solid rgba(140, 131, 145, 0.4);
  }

  ${(props) =>
    !props.disabled
      ? css`
          &::after {
            content: "";
            position: absolute;
            top: -10%;
            left: -10%;
            height: 120%;
            width: 120%;
            background-color: ${(props) =>
              props.color
                ? darken(0.1, props.theme.colors[props.color])
                : darken(0.1, props.theme.colors.blue)};
            z-index: -1;
            transform: translate(-120%, 120%);
            transform-origin: center;
            opacity: 0;
            transition: transform 0.4s, opacity 0.4s;
            border-radius: ${(props) =>
              props.noBorderRadius ? "0px" : "30px"};
          }

          &:hover {
            &::after {
              opacity: 1;
              transform: none;
              transition-delay: 0, 0.1s;
            }
          }

          &:active {
            transform: scale(0.97);
          }
        `
      : ``}
`;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

Button.defaultProps = {
  children: "Submit",
  small: false,
  disabled: false,
  color: null,
};

export default Button;
