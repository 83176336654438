import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import { darken } from "polished";

import { useNotifications } from "../../context/notificationsContext";
import { useAuth } from "../../context/authContext";
import { getTaskFromApi, updateTaskOnApi, deleteTaskOnApi } from "./utils/api";
import { getAllProjectsFromApi } from "../manage/utils/api";

import { respondTo } from "../../styles/styleHelpers";

import Button from "../../components/buttons/Button";
import { Field, Label, Input, Textarea } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";

const SingleTask = () => {
  const { user } = useAuth();
  const history = useHistory();
  const { openAlertPopup } = useNotifications();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState("");
  const [projectId, setProjectId] = useState("");
  const [status, setStatus] = useState(null);
  const [week, setWeek] = useState(null);
  const [date, setDate] = useState(null);
  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [position, setPosition] = useState("");

  /*
  |--------------------------------------------------------------------------
  | Get All Tasks
  |--------------------------------------------------------------------------
  */
  async function getTask() {
    const result = await getTaskFromApi(id);

    setTitle(result.title);
    setDescription(result.description);
    setProjectId(result.projectId);
    setWeek(result.week.substring(0, 10));
    setPosition(result.position);
    setDate(result.date.substring(0, 10));
    setStatus(result.status);
    // setTags(result.tags);

    if (result.link) {
      setLinkText(result.link.text);
      setLinkUrl(result.link.url);
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  async function getProjects() {
    const result = await getAllProjectsFromApi();
    setProjects(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get tasks on start if the user is loaded
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (user) {
      getTask();
      getProjects();
    }
  }, [user]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Update task on api
  |--------------------------------------------------------------------------
  */
  async function updateTask() {
    try {
      const data = {
        title,
        description,
        projectId,
        status,
        week,
        position,
        date,
        // tags,
        link: {
          url: linkUrl,
          text: linkText,
        },
      };

      const result = await updateTaskOnApi(id, data);

      if (result) {
        openAlertPopup("Success", "Task edited successfully!", true);
      }

      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task edit failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Delete task on api
  |--------------------------------------------------------------------------
  */
  async function deleteTask() {
    try {
      const result = await deleteTaskOnApi(id);

      if (result) {
        openAlertPopup("Success", "Task deleted successfully!", true);
        history.push(`/tasks/manage`);
      }

      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task delete failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Add a new tag
  |--------------------------------------------------------------------------
  */
  // const addTag = () => {
  //   let tempTags = [...tags];

  //   if (newTag && newTag !== "") {
  //     tempTags.push(newTag);
  //   }

  //   setNewTag("");
  //   setTags(tempTags);
  // };

  /*
  |--------------------------------------------------------------------------
  | Delete an existing tag
  |--------------------------------------------------------------------------
  */
  // const deleteTag = (tag) => {
  //   let tempTags = [...tags];

  //   const index = tempTags.indexOf(tag);

  //   if (index > -1) {
  //     tempTags.splice(index, 1);
  //   }

  //   setTags(tempTags);
  // };

  return (
    <TasksContainer>
      <Header>
        <MainHeader>Edit Task</MainHeader>
        <SimpleLink as={Link} to="/tasks/manage">
          View all tasks
        </SimpleLink>
      </Header>
      <Task>
        <Field>
          <Label>Title</Label>
          <Input
            type="text"
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Description</Label>
          <Textarea
            defaultValue={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Project</Label>
          {projects && projectId && (
            <StyledSelectDropdown
              onChange={(e) => setProjectId(e.value)}
              placeholder="Select Project"
              defaultValue={projects
                .filter((item) => {
                  return item._id === projectId;
                })
                .map((item) => {
                  return {
                    label: item.name,
                    value: item._id,
                  };
                })}
              options={projects.map((item) => {
                return {
                  label: item.name,
                  value: item._id,
                };
              })}
            ></StyledSelectDropdown>
          )}
        </Field>
        <Field>
          <Label>Status</Label>
          {status && (
            <StyledSelectDropdown
              onChange={(e) => setStatus(e.value)}
              placeholder="Select Status"
              defaultValue={{
                label: status,
                value: status,
              }}
              options={[
                {
                  label: "To do",
                  value: "To do",
                },
                {
                  label: "In progress",
                  value: "In progress",
                },
                {
                  label: "Completed",
                  value: "Completed",
                },
              ]}
            ></StyledSelectDropdown>
          )}
        </Field>
        <Field>
          <Label>Position</Label>
          <Input
            type="number"
            defaultValue={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Week (what week this task is scheduled for)</Label>
          <Input
            type="date"
            defaultValue={week && week.substring(0, 10)}
            onChange={(e) => setWeek(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Date (what weekday this task is scheduled for)</Label>
          <Input
            type="date"
            defaultValue={date && date.substring(0, 10)}
            onChange={(e) => setDate(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Link Text</Label>
          <Input
            type="text"
            defaultValue={linkText}
            onChange={(e) => setLinkText(e.target.value)}
          />
        </Field>
        <Field>
          <Label>Link Url</Label>
          <Input
            type="text"
            defaultValue={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
        </Field>
        {/* <Field>
          <Label>Tags</Label>
          <Tags>
            {tags &&
              tags.map((tag, index) => {
                return (
                  <Tag key={index}>
                    <TagIcon
                      onClick={() => {
                        deleteTag(tag);
                      }}
                    >
                      &times;
                    </TagIcon>
                    {tag}
                  </Tag>
                );
              })}
            <Tag>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  addTag();
                }}
              >
                <TagIcon
                  type="submit"
                  onClick={() => {
                    addTag();
                  }}
                >
                  +
                </TagIcon>
                <TagInput
                  type="text"
                  placeholder="Add Tag"
                  value={newTag}
                  onChange={(e) => {
                    setNewTag(e.target.value);
                  }}
                />
              </Form>
            </Tag>
          </Tags>
        </Field> */}

        <ButtonRow>
          <Save onClick={() => updateTask()}>Save</Save>
          <Delete onClick={() => deleteTask()}>Delete</Delete>
        </ButtonRow>
      </Task>
    </TasksContainer>
  );
};

const TasksContainer = styled.div`
  padding: 53px 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const MainHeader = styled.h2`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 0px;
  line-height: 1;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const Task = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 300px;

  * {
    font-size: 14px;
  }
  margin-bottom: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 10px;
`;

const Save = styled(Button)`
  width: 100px;
  margin-right: 20px;
`;

const Delete = styled(Button)`
  width: 100px;
  background-color: #df5757;
  border-color: #df5757;

  &::after {
    background-color: ${darken(0.1, "#df5757")};
  }
`;

export default SingleTask;
