import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import useClickOutside from "../../hooks/useClickOutside";

import Tooltip from "../Tooltip";
import Menu from "../Menu";

const ToggleButton = ({
  id,
  actions,
  menuPosition,
  toggle,
  reveal,
  ...rest
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const wrapper = useRef(null);
  const toggleElRef = useRef(null);

  // close it when click is outside the wrapper
  useClickOutside(wrapper, isOpen, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    // ? don't run this on the inital load, otherwise if there are many of these in the document at the same time the page will get bogged down and load very slowly
    if (hasLoaded && toggleElRef.current) {
      if (isOpen) {
        ReactTooltip.show(toggleElRef.current);
      } else {
        ReactTooltip.hide(toggleElRef.current);
      }
    }

    // denote that the initial load has happened
    setHasLoaded(true);
  }, [isOpen]);

  const toggleElProps = {
    ref: toggleElRef,
    // associate the toggle with the tooltip
    "data-tip": true,
    "data-for": id,
    "data-event": "none",
    "data-event-off": "none",
    // display/hide the tooltip on toggle click
    onClick: () => {
      setIsOpen(!isOpen);

      if (toggle?.props?.onClick) {
        toggle.props.onClick();
      }
    },
  };

  const toggleEl = React.cloneElement(toggle, toggleElProps);

  return (
    <div ref={wrapper} {...rest}>
      {toggleEl}

      <Tooltip id={id} place={menuPosition}>
        {actions?.length ? <Menu items={actions} /> : null}
        {reveal ? reveal : null}
      </Tooltip>
    </div>
  );
};

ToggleButton.propTypes = {
  actions: PropTypes.array,
  menuPosition: PropTypes.string,
  toggle: PropTypes.node,
  reveal: PropTypes.node,
};
ToggleButton.defaultProps = {
  id: "toggle-button",
  actions: [],
  menuPosition: "bottom",
  toggle: null,
  reveal: null,
};

export default ToggleButton;
