import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ChevronRight from "./icons/ChevronRight";

const Breadcrumbs = ({ crumbs }) => {
  return (
    <Container>
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <Item>
            {crumb.link ? (
              <Link to={crumb.link}>{crumb.name}</Link>
            ) : (
              crumb.name
            )}
          </Item>

          {index + 1 < crumbs.length ? (
            <Icon>
              <ChevronRight />
            </Icon>
          ) : null}
        </Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  font-weight: 500;

  display: inline-block;
  color: ${(props) => props.theme.colors.coolerGray};
  margin-bottom: 0;

  a {
    color: ${(props) => props.theme.colors.coolGray600};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Icon = styled.div`
  display: inline-block;
  padding-right: 16px;
  padding-left: 16px;
  line-height: 0;

  svg {
    path {
      fill: ${(props) => props.theme.colors.coolGray300};
    }
  }
`;

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};
Breadcrumbs.defaultProps = {
  crumbs: [],
};

export default Breadcrumbs;
