import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format, isPast, isToday } from "date-fns";
import { motion } from "framer-motion";

import { getAllProjectsFromApi } from "../../../../utils/api";
import { getProjectFromApi } from "../../utils/api";
import {
  getCurrency,
  getSortFunction,
  getStandardizedDate,
} from "../../../../utils/helpers";

import { TableHeader, TableRow, TableCell } from "../../../../components/Table";
import SimpleLink from "../../../../components/links/SimpleLink";
import CreateLink from "../../../../components/links/CreateLink";
import ProgressBar from "../../../dashboard/components/ProgressBar";

const ClientProjects = ({ clientId }) => {
  const [projects, setProjects] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [openedSection, setOpenedSection] = useState("inProgress");

  // array to store the projects with their billed dollars and tasks info. Properties that aren't present in the original array of projects
  // used for sorting purposes
  const updatedProjects = useRef([]);

  useEffect(() => {
    if (clientId) {
      getProjects(clientId);
    }
  }, [clientId]);

  // when sort is changed
  useEffect(() => {
    if (projects && sortBy) {
      const sortedProjects = [...projects];

      let sortFunc;

      if (Object.prototype.hasOwnProperty.call(customSortFunctions, sortBy)) {
        sortFunc = customSortFunctions[sortBy];
      } else {
        const sortParts = sortBy.split("-");
        const sortField = sortParts[0];
        const sortDirection = sortParts[1];
        const sortFieldInfo = tableHeadings.find(
          (item) => item.value === sortField,
        );
        sortFunc = getSortFunction({
          type: sortFieldInfo.type,
          direction: sortDirection,
          field: sortField,
        });
      }

      sortedProjects.sort(sortFunc);

      setProjects(sortedProjects);
    }
  }, [sortBy]);

  const getProjects = async (id) => {
    try {
      const projectsFromApi = await getAllProjectsFromApi({
        clientId: id,
        bookingPlan: true,
        archived: "any",
      });

      setProjects(projectsFromApi);
    } catch (error) {
      console.error("error getting client projects", error);
    }
  };

  const handleSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  const customSortFunctions = {
    "percentage-asc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const billedA = projectA?.billed || 0;
      const billedB = projectB?.billed || 0;

      const numberA = billedA / a.budget || 0;
      const numberB = billedB / b.budget || 0;
      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    },
    "percentage-desc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const billedA = projectA?.billed || 0;
      const billedB = projectB?.billed || 0;

      const numberA = billedA / a.budget || 0;
      const numberB = billedB / b.budget || 0;
      return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
    },
    "billed-asc": (a, b) => {
      // ? get the project from the updated projects array so we can access the "billed" property, which didn't exist in the original array of projects
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.billed || 0;
      const numberB = projectB?.billed || 0;
      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    },
    "billed-desc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.billed || 0;
      const numberB = projectB?.billed || 0;
      return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
    },
    "overdue-asc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.overdueTasks || 0;
      const numberB = projectB?.overdueTasks || 0;
      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    },
    "overdue-desc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.overdueTasks || 0;
      const numberB = projectB?.overdueTasks || 0;
      return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
    },
    "completed-asc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.completedTasks || 0;
      const numberB = projectB?.completedTasks || 0;
      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    },
    "completed-desc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.completedTasks || 0;
      const numberB = projectB?.completedTasks || 0;
      return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
    },
    "total-asc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.totalTasks || 0;
      const numberB = projectB?.totalTasks || 0;
      return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
    },
    "total-desc": (a, b) => {
      const projectA = updatedProjects.current.find(
        (project) => project._id === a._id,
      );
      const projectB = updatedProjects.current.find(
        (project) => project._id === b._id,
      );

      const numberA = projectA?.totalTasks || 0;
      const numberB = projectB?.totalTasks || 0;
      return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
    },
  };

  const tableHeadings = [
    {
      label: "Code",
      value: "code",
      type: "text",
    },
    {
      label: "Name",
      value: "name",
      type: "text",
    },
    {
      label: "Revenue Booked",
      value: "budget",
      type: "number",
    },
    {
      label: "Progress",
      value: "percentage",
    },
    {
      label: "Revenue Billed",
      value: "billed",
    },
    {
      label: "Due Date",
      value: "endDate",
      type: "date",
    },
    {
      label: "Overdue Tasks",
      value: "overdue",
    },
    {
      label: "Completed Tasks",
      value: "completed",
    },
    {
      label: "Total Tasks",
      value: "total",
    },
  ];

  const inProgressProjects = projects
    ? projects.filter((project) => !project.archived)
    : [];
  const completedProjects = projects
    ? projects.filter((project) => project.archived)
    : [];

  const toggleSection = (newSection) => {
    if (openedSection === newSection) {
      setOpenedSection("");
    } else {
      setOpenedSection(newSection);
    }
  };

  return (
    <>
      {projects ? (
        <>
          <Header>
            <Heading>Projects</Heading>

            <CreateLink as={Link} to={`/projects/new/?client=${clientId}`}>
              Create Project
            </CreateLink>
          </Header>

          {projects.length ? (
            <>
              <SectionContainer>
                <SectionHeader onClick={() => toggleSection("inProgress")}>
                  <SectionHeaderTitle>In Progress</SectionHeaderTitle>{" "}
                </SectionHeader>
                <SectionContent
                  animate={openedSection === "inProgress" ? "open" : "closed"}
                  variants={{
                    open: { height: "auto" },
                    closed: { height: 0 },
                  }}
                  transition={{ duration: 0.4 }}
                >
                  <Table>
                    <thead>
                      <tr>
                        {tableHeadings.map((heading) => (
                          <TableHeader
                            key={heading.value}
                            onClick={() => handleSort(heading.value)}
                            isArrowUp={sortBy === `${heading.value}-desc`}
                            align="left"
                          >
                            {heading.label}
                          </TableHeader>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {inProgressProjects.length ? (
                        inProgressProjects.map((project) => (
                          <ProjectRow
                            key={project._id}
                            project={project}
                            updateProject={(toUpdate) => {
                              // so we have the billed dollars and tasks info
                              updatedProjects.current.push({
                                ...project,
                                ...toUpdate,
                              });
                            }}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={tableHeadings.length}>
                            No projects in progress for this client.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </SectionContent>
              </SectionContainer>

              <SectionContainer>
                <SectionHeader onClick={() => toggleSection("completed")}>
                  <SectionHeaderTitle>Completed</SectionHeaderTitle>{" "}
                </SectionHeader>
                <SectionContent
                  animate={openedSection === "completed" ? "open" : "closed"}
                  variants={{
                    open: { height: "auto" },
                    closed: { height: 0 },
                  }}
                  transition={{ duration: 0.4 }}
                >
                  <Table>
                    <thead>
                      <tr>
                        {tableHeadings.map((heading) => (
                          <TableHeader
                            key={heading.value}
                            onClick={() => handleSort(heading.value)}
                            isArrowUp={sortBy === `${heading.value}-desc`}
                            align="left"
                          >
                            {heading.label}
                          </TableHeader>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {completedProjects.length ? (
                        completedProjects.map((project) => (
                          <ProjectRow
                            key={project._id}
                            project={project}
                            updateProject={(toUpdate) => {
                              // so we have the billed dollars and tasks info
                              updatedProjects.current.push({
                                ...project,
                                ...toUpdate,
                              });
                            }}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={tableHeadings.length}>
                            No projects completed for this client.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </SectionContent>
              </SectionContainer>
            </>
          ) : (
            "No projects have been created with this client."
          )}
        </>
      ) : null}
    </>
  );
};

const ProjectRow = ({ project, updateProject }) => {
  const [billed, setBilled] = useState(null);
  const [tasks, setTasks] = useState(null);

  const [overdueTasks, setOverdueTasks] = useState(null);
  const [completedTasks, setCompletedTasks] = useState(null);
  const [totalTasks, setTotalTasks] = useState(null);

  useEffect(() => {
    if (project) {
      getProject();
    }
  }, []);

  useEffect(() => {
    // once the tasks are set
    if (tasks) {
      let tasksOverdue = 0;
      let tasksCompleted = 0;

      tasks.forEach((task) => {
        // completed task
        if (task.status === "Completed") {
          tasksCompleted++;
        }
        // incomplete task past its due date
        else if (
          isPast(getStandardizedDate(task.date)) &&
          !isToday(getStandardizedDate(task.date))
        ) {
          tasksOverdue++;
        }
      });

      setOverdueTasks(tasksOverdue);
      setCompletedTasks(tasksCompleted);
      setTotalTasks(tasks.length);
    }
  }, [tasks]);

  useEffect(() => {
    // when we have the billed info and tasks info
    if (
      billed !== null &&
      overdueTasks !== null &&
      completedTasks !== null &&
      totalTasks !== null
    ) {
      // update the project in the parent, so the projects can be sorted by this new info we've retrieved
      updateProject({
        billed,
        overdueTasks,
        completedTasks,
        totalTasks,
      });
    }
  }, [billed, overdueTasks, completedTasks, totalTasks]);

  const getProject = async () => {
    try {
      const result = await getProjectFromApi(project._id, { billed: true });

      // set billed dollars
      setBilled(result.databaseProject.billed);

      setTasks(result.databaseProject.projectTasks || []);
    } catch (error) {
      console.error("error getting project details", error);
    }
  };

  const calculatePace = (project) => (project.budget / billed) * 100;

  return (
    <TableRow>
      <TableCell align="left">
        <SimpleLink as={Link} to={`/projects/${project._id}`}>
          {project.code ? project.code : "N/A"}
        </SimpleLink>
      </TableCell>
      <TitleCell align="left">
        <SimpleLink as={Link} to={`/projects/${project._id}`}>
          {project.name}
        </SimpleLink>
      </TitleCell>
      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
        {typeof project.budget === "number"
          ? getCurrency(project.budget)
          : "N/A"}
      </TableCell>
      <TableCell align="left">
        {billed !== null && billed !== 0 ? (
          <ProgressBar
            actual={billed}
            pace={billed > project.budget ? calculatePace(project) : null}
            budgeted={project.budget}
            style={{ width: "200px", margin: "0px" }}
          />
        ) : (
          "..."
        )}
      </TableCell>
      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
        {billed !== null ? getCurrency(billed) : "..."}
      </TableCell>
      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
        {format(new Date(project.endDate), "MM-dd-yyyy")}
      </TableCell>
      <TableCell align="left">{overdueTasks}</TableCell>
      <TableCell align="left">{completedTasks}</TableCell>
      <TableCell align="left">{totalTasks}</TableCell>
    </TableRow>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #373040;
  margin-right: 30px;
  margin-bottom: 0;
`;

const Table = styled.table`
  margin-top: 30px;
  margin-bottom: 70px;

  thead {
    th {
      padding-left: 30px;
      padding-right: 30px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  tbody {
    td,
    th {
      padding: 25px 30px;

      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }

    th {
      text-align: left;
      font-weight: 500;

      border: 1px solid;
      border-left: 0;
      border-right: 0;
    }
  }
`;

const TitleCell = styled(TableCell)`
  min-width: 200px;
`;

const SectionContainer = styled.div`
  padding: 40px 0;
  border-top: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const SectionHeader = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const SectionHeaderTitle = styled.h3`
  margin-bottom: 0;
`;

const SectionContent = styled(motion.div)`
  height: 0;
  overflow: auto;
`;

ClientProjects.propTypes = {};

export default ClientProjects;
