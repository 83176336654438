import React from "react";
import styled from "styled-components";

import { NumberChip } from "../../../components/ValueChip";
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "./button";

const PageLayout = ({
  title,
  number,
  breadcrumbs,
  createButton,
  handleCreate,
  children,
}) => {
  return (
    <Container>
      <Header>
        <CrumbContainer>
          <Breadcrumbs crumbs={breadcrumbs} />
        </CrumbContainer>

        <HeaderRow>
          <Title>
            {title} {number ? <Number color="blueGray">{number}</Number> : null}
          </Title>
          {createButton ? (
            <BtnGroup>
              <Button onClick={handleCreate}>{createButton}</Button>
            </BtnGroup>
          ) : null}
        </HeaderRow>
      </Header>
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const Header = styled.div`
  width: 100%;
  padding: 24px 40px 16px;
`;

const CrumbContainer = styled.div`
  margin-bottom: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  color: #374151;

  display: flex;
  align-items: center;
`;

const Number = styled(NumberChip)`
  margin-left: 10px;
`;

export default PageLayout;
