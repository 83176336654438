import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getCompaniesFromApi } from "../../utils/api";
import {
  getMembersFromApi,
  getClientEntriesFromApi,
  getAllProjectsFromApi,
} from "../manage/utils/api";

import { respondTo } from "../../styles/styleHelpers";

import { useNotifications } from "../../context/notificationsContext";

import { TableHeader, TableRow, TableCell } from "../../components/Table";
import Budget from "../projects/components/Budget";

const Sales = () => {
  const [sortBy, setSortBy] = useState("newest");
  const [members, setMembers] = useState(null);
  const [clients, setClients] = useState(null);
  const [projects, setProjects] = useState(null);
  const [salesMembers, setSalesMembers] = useState(null);
  const { setLoading } = useNotifications();

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the teams
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members) {
      const sortedMembers = [...salesMembers];

      if (sortBy === "name-asc") {
        sortedMembers.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "name-desc") {
        sortedMembers.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tracked-asc") {
        sortedMembers.sort(function(a, b) {
          var textA = a.tracked;
          if (!a.tracked) {
            textA = -1;
          }
          var textB = b.tracked;
          if (!b.tracked) {
            textB = -1;
          }
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tracked-desc") {
        sortedMembers.sort(function(a, b) {
          var textA = a.tracked;
          if (!a.tracked) {
            textA = -1;
          }
          var textB = b.tracked;
          if (!b.tracked) {
            textB = -1;
          }
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      }
      setSalesMembers(sortedMembers);
    }
  }, [sortBy]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Toggle the sort to asc or desc
  |--------------------------------------------------------------------------
  */
  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get teams, members, and budget plans
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setLoading(true);
    getClients();
    getMembers();
    getProjects();
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get All Members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();
      setMembers(results);
      setSalesMembers(results);
      return;
    } catch (error) {
      console.error("Get members error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      const results = await getAllProjectsFromApi({ bookingPlan: true });
      setProjects(results);
      return;
    } catch (error) {
      console.error("Get members error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Clients
  |--------------------------------------------------------------------------
  */
  const getClients = async () => {
    try {
      const results = await getCompaniesFromApi();
      setClients(results);
      return;
    } catch (error) {
      console.error("Get clients error: " + error);
    }
  };

  useEffect(() => {
    if (clients && members && projects) {
      getMemberRevenues();
    }
  }, [clients, members, projects]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get the tracked amount in dollars from a client
  |--------------------------------------------------------------------------
  */
  const getClientRevenue = async (id) => {
    try {
      const result = await getClientEntriesFromApi(id, true);
      return result;
    } catch (error) {
      console.error("Get project error: " + error);
    }
  };

  const getMemberRevenues = async () => {
    const tempMembers = [...members];
    const tempClients = [...clients];

    await Promise.all(
      tempMembers.map(async (member) => {
        let tracked = 0;
        let total = 0;
        let contracted = 0;
        const memberClients = [];

        await Promise.all(
          tempClients.map(async (client) => {
            if (client.accountOwner === member._id) {
              const revenue = await getClientRevenue(client.harvestId);
              if (revenue > 0) {
                tracked += revenue;
              }
              total += getTotalPlanned(client._id);
              contracted += getContractCompleted(client._id);
              memberClients.push(client.name);
            }
          }),
        );

        member.clients = memberClients;
        member.tracked = tracked;
        member.contracted = contracted;
        member.total = total;
        return member;
      }),
    );

    setSalesMembers(tempMembers);
    setLoading(false);
    setSortBy("tracked-desc");
  };

  /*
  |--------------------------------------------------------------------------
  | Get total amount of budget planned for all the projects of a client
  |--------------------------------------------------------------------------
  */
  const getTotalPlanned = (clientId) => {
    const tempProjects = [...projects];
    let totalPlanned = 0;

    tempProjects.forEach((project) => {
      if (project.client._id === clientId && project.budget > 0) {
        totalPlanned += project.budget;
      }
    });

    return totalPlanned;
  };

  /*
  |--------------------------------------------------------------------------
  | Get the total amount of budget planned that also has contract completed
  |--------------------------------------------------------------------------
  */
  const getContractCompleted = (clientId) => {
    const tempProjects = [...projects];
    let contractCompleted = 0;

    tempProjects.forEach((project) => {
      if (
        project.client._id === clientId &&
        project.budget > 0 &&
        project.stages &&
        project.stages.contract &&
        project.stages.contract.completed
      ) {
        contractCompleted += project.budget;
      }
    });

    return contractCompleted;
  };

  return (
    <Container>
      <Name>Sales Report</Name>

      <Table>
        <thead>
          <tr>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("name")}
              isArrowUp={sortBy === "name-desc"}
            >
              Member
            </StyledTableHeader>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("tracked")}
              isArrowUp={sortBy === "tracked-desc"}
            >
              2021 Sales Quota
            </StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {salesMembers &&
            salesMembers.map((member, memberIndex) => {
              return (
                <TableRow key={memberIndex}>
                  <StyledTableCell align="left">
                    <MemberName>{member.name}</MemberName>
                    <MemberInfo>
                      {member.clients &&
                        member.clients.map((client) => {
                          return <p>{client}</p>;
                        })}
                    </MemberInfo>
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ paddingRight: "0px" }}>
                    <StyledBudget
                      sales
                      tracked={Math.round(member.tracked) || 0}
                      budget={Math.round(member.total) || 0}
                      salesQuota={member.salesQuota || 0}
                      contracted={Math.round(member.contracted) || 0}
                    />
                    <SalesInfo>
                      <p>
                        Tracked: ${Math.round(member.tracked).toLocaleString()}
                      </p>
                      <p>
                        Contract completed: $
                        {Math.round(member.contracted).toLocaleString()}
                      </p>
                      <p>
                        Total planned: $
                        {Math.round(member.total).toLocaleString()}
                      </p>

                      <p>
                        Sales Quota: $
                        {member.salesQuota
                          ? member.salesQuota.toLocaleString()
                          : 0}
                      </p>
                    </SalesInfo>
                  </StyledTableCell>
                </TableRow>
              );
            })}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  padding: 53px;
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontSize_l};
  color: ${(props) => props.theme.colors.darkSlate};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 80px;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const Table = styled.table`
  transition: 0.3s ease-in-out;
`;

const StyledTableHeader = styled(TableHeader)`
  padding: 0px 40px 20px 40px;

  &:first-of-type {
    padding-left: 0px;
    padding-right: 40px;
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 30px 40px;
  position: relative;

  &:first-of-type {
    padding-right: 60px;
  }
`;

const SalesInfo = styled.div`
  position: absolute;
  left: 130px;
  bottom: 0;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(90%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const MemberInfo = styled.div`
  position: absolute;
  left: 0px;
  top: 50px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(60px);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const StyledBudget = styled(Budget)`
  cursor: help;

  &:hover ~ ${SalesInfo} {
    opacity: 1;
    transform: translateY(69%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

const MemberName = styled.p`
  cursor: help;
  margin-bottom: 0px;
  padding: 20px 0px;

  &:hover ~ ${MemberInfo} {
    opacity: 1;
    transform: translateY(27px);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

export default Sales;
