import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { differenceInDays } from "date-fns";

import { getStandardizedDate } from "../utils/helpers";

import ProfileImage from "./ProfileImage";
import DefaultLink from "./links/DefaultLink";
import CommentIcon from "./icons/CommentIcon";
import SendIcon from "./icons/Send";

import { Input } from "./Form";

const FileComment = ({
  commentId,
  commentNum,
  page,
  author,
  postedDate,
  message,
  editable,
  editComment,
  deletable,
  deleteComment,
  editedMessage,
  setEditedMessage,
  completed,
  completable,
  completeComment,
  floating,
  highlightedComment,
  setHighlightedComment,
  replies,
  ...rest
}) => {
  const [editingMessage, setEditingMessage] = useState(false);
  const days = differenceInDays(
    getStandardizedDate(new Date()),
    getStandardizedDate(postedDate),
  );

  return (
    <Container>
      <ContainerInner completed={completed} {...rest}>
        <ProfileImage
          xsmall
          handle={author.handle}
          name={author.name}
          showStatus
        />
        <Main>
          {floating ? (
            <>
              <Header floating>
                <CommentLeft>
                  <Name>{author.handle}</Name>
                </CommentLeft>

                {completable ? (
                  <Resolve
                    onClick={() => {
                      if (
                        highlightedComment &&
                        highlightedComment.id === commentId
                      ) {
                        const tempComment = { ...highlightedComment };
                        tempComment.comment.completed = !completed;

                        setHighlightedComment({ ...tempComment });
                      }
                      completeComment(commentId, !completed);
                    }}
                  >
                    {completed ? "Resolved" : "Resolve"}
                  </Resolve>
                ) : null}
              </Header>

              <Subheader>
                <PostedDate floating>
                  {days === 1
                    ? "Yesterday"
                    : days === 0
                    ? "Today"
                    : `${days} Days ago`}{" "}
                  {page ? `on Pg. ${page}` : null}
                </PostedDate>
              </Subheader>
            </>
          ) : (
            <>
              <Header>
                <CommentLeft>
                  <CommentIcon completed={completed} />
                  <CommentNumber>{commentNum}</CommentNumber>
                </CommentLeft>

                {completable ? (
                  <Resolve
                    onClick={(e) => {
                      e.stopPropagation();
                      completeComment(commentId, !completed);
                    }}
                  >
                    {completed ? "Resolved" : "Resolve"}
                  </Resolve>
                ) : null}
              </Header>

              <Subheader>
                <Name>{author.handle},&nbsp;</Name>
                <PostedDate>
                  {days === 1
                    ? "Yesterday"
                    : days === 0
                    ? "Today"
                    : `${days} Days ago`}
                  {page ? ` on Pg. ${page}` : null}
                </PostedDate>
              </Subheader>
            </>
          )}

          {editingMessage ? (
            <InputContainer
              onSubmit={(e) => {
                e.preventDefault();
                setEditingMessage(false);
                editComment();
              }}
            >
              <MessageInput
                value={editedMessage}
                onChange={(e) => setEditedMessage(e.target.value)}
              />

              <Submit type="submit" title="Save" aria-label="Save comment">
                <SendIcon aria-hidden="true" />
              </Submit>
            </InputContainer>
          ) : (
            <Message
              dangerouslySetInnerHTML={{
                __html: message,
              }}
            ></Message>
          )}

          {replies ? (
            <Replies>{`${replies} ${
              replies > 1 ? "Replies" : "Reply"
            }`}</Replies>
          ) : null}

          {deletable || editable ? (
            <Controls>
              {editingMessage ? (
                <Edit white onClick={() => setEditingMessage(false)}>
                  Cancel
                </Edit>
              ) : null}

              {editable && !editingMessage ? (
                <Edit
                  white
                  onClick={() => {
                    setEditedMessage(message);
                    setEditingMessage(true);
                  }}
                >
                  Edit
                </Edit>
              ) : null}

              {deletable ? (
                <Delete white onClick={deleteComment}>
                  Delete
                </Delete>
              ) : null}
            </Controls>
          ) : null}
        </Main>
      </ContainerInner>
    </Container>
  );
};

const CommentNumber = styled.span`
  color: ${(props) => props.theme.colors.coolGray400};
  font-size: 14px;
  font-weight: 500;
  margin-left: 7px;
`;

const Resolve = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerInner = styled.div`
  position: relative;
  display: flex;
  background-color: ${(props) =>
    props.completed
      ? props.theme.colors.coolGray600
      : props.theme.colors.coolGray700};
  padding: 20px;

  ${CommentNumber} {
    color: ${(props) =>
      props.completed
        ? props.theme.colors.green400
        : props.theme.colors.coolGray400};
  }

  ${Resolve} {
    color: ${(props) =>
      props.completed
        ? props.theme.colors.coolGray100
        : props.theme.colors.coolGray400};
  }

  &::before {
    display: ${(props) => (props.isHighlighted ? "block" : "none")};
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border: 3px solid #4ade80;
  }
`;

const CommentLeft = styled.div`
  display: flex;
`;

const Main = styled.div`
  margin-left: 15px;
  width: 100%;
`;

const Subheader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Name = styled.p`
  color: ${(props) => props.theme.colors.coolGray50};
  margin-bottom: 0px;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${(props) => (props.floating ? "5px" : "10px")};

  ${Name} {
    font-size: ${(props) => (props.floating ? "14px" : "12px")};
  }
`;

const PostedDate = styled.p`
  color: ${(props) =>
    props.floating
      ? props.theme.colors.coolGray300
      : props.theme.colors.coolGray50};
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 1;
`;

const Message = styled.p`
  color: ${(props) => props.theme.colors.coolGray50};
  font-size: 14px;
  margin-bottom: 0px;
  text-align: left;

  // * Only applies word break to links (because they are urls)
  a {
    word-break: break-all;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Edit = styled(DefaultLink)`
  margin-left: 10px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.coolGray200};
`;

const Delete = styled(DefaultLink)`
  margin-left: 10px;
  font-size: 10px;
  color: ${(props) => props.theme.colors.coolGray200};
`;

const MessageInput = styled(Input)`
  width: 100%;
  height: 36px;
  padding: 10px;
  font-size: 12px;
`;

const InputContainer = styled.form`
  position: relative;
`;

const Submit = styled.button`
  position: absolute;
  right: 1px;
  bottom: 1px;

  /* so it doesn't overlap the 1px border on top and bottom of the input */
  height: calc(100% - 2px);

  max-height: 40px;

  display: flex;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: background-color 200ms;

  svg {
    path {
      fill: ${(props) => props.theme.colors.indigo};
      transition: fill 200ms;
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo50};
  }
`;

const Replies = styled.div`
  color: ${(props) => props.theme.colors.coolGray200};
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
`;

Comment.propTypes = {
  author: PropTypes.object,
  postedDate: PropTypes.string,
  message: PropTypes.string,
};

export default FileComment;
