import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

import { colors } from "../styles/theme";

const Tooltip = ({
  id,
  type,
  place,
  isInteractive,
  delayHide,
  children,
  ...rest
}) => {
  // const tip = useRef(null);

  const textColor = type === "dark" ? colors.pureWhite : colors.oldBlack;

  const bgColor =
    type === "dark"
      ? colors.darkSlate
      : type === "success"
      ? colors.lightGreen
      : type === "warning"
      ? colors.lightYellow
      : type === "error"
      ? colors.lightRed
      : type === "info"
      ? colors.cardBackground
      : colors.pureWhite;

  return (
    <Tip
      id={id}
      effect="solid"
      place={place}
      type={type}
      delayHide={delayHide} // so user can hover their mouse onto the tip without it fading away
      isInteractive={isInteractive}
      // ref={tip}
      textColor={textColor}
      backgroundColor={bgColor}
      borderColor={bgColor}
      arrowColor={bgColor}
      className="tooltip-theme"
      {...rest}
    >
      {children}
    </Tip>
  );
};

const Tip = styled(ReactTooltip)`
  /* custom class to override the default class styles */
  &.tooltip-theme {
    font-size: ${(props) => props.theme.fontSize_xxxxs};

    text-align: left;
    padding: 5px 10px;

    border-radius: 5px;
    box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);

    max-width: 285px;

    z-index: ${(props) => (props.zIndex ? props.zIndex : undefined)};

    pointer-events: ${(props) => (props.isInteractive ? "auto" : "none")};
    transition: opacity 150ms, visibility 150ms;

    /* keep the tooltip visible when the user move's their mouse off of the trigger and onto the tooltip itself */
    &:hover {
      visibility: visible;
      opacity: 1;
    }

    &.show {
      opacity: 1;
    }

    /* After the X button is pressed */
    &.hide {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }

    ${(props) =>
      props.hideCaret
        ? `
      &::before, &::after {
        display: none;
      }
      `
        : ``}
  }
`;

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "success",
    "warning",
    "error",
    "info",
    "light",
    "dark",
  ]),
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  isInteractive: PropTypes.bool,
  hideCaret: PropTypes.bool,
  delayHide: PropTypes.number,
  children: PropTypes.node,
};
Tooltip.defaultProps = {
  id: null,
  type: "light",
  place: "left",
  isInteractive: true,
  hideCaret: false,
  delayHide: 300,
  children: null,
};

export default Tooltip;
