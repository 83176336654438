import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { listReset } from "../styles/styleHelpers";

const Menu = ({ items }) => {
  return (
    <List>
      {items.map((item, index) => (
        <li key={index}>
          <Item
            as={item.to ? Link : undefined}
            to={item.to ? item.to : undefined}
            onClick={item.onClick ? item.onClick : undefined}
            hasDanger={item.hasDanger}
          >
            {item.icon ? <Icon>{item.icon}</Icon> : null}

            <span>{item.text}</span>
          </Item>
        </li>
      ))}
    </List>
  );
};

const List = styled.ul`
  ${listReset()}

  background-color:white;

  li {
    margin-bottom: 0;
  }
`;

const Item = styled.button`
  display: flex;
  align-items: center;
  width: 100%;

  font-size: 14px;
  text-align: left;
  padding: 8px;
  border-radius: 2px;

  color: ${(props) =>
    props.hasDanger ? props.theme.colors.rose500 : undefined};

  transition: background-color 200ms;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;

const Icon = styled.div`
  margin-right: 10px;
  line-height: 0;
`;

Menu.propTypes = {
  items: PropTypes.array,
};
Menu.defaultProps = {
  items: [],
};

export default Menu;
