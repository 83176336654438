import React, { useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { isValidDate, getStandardizedDate } from "../../../utils/helpers";
import { contractStages } from "../../../utils/constants";

import { Field, Input, Label } from "../../../components/newForm";
import Button from "../../../components/newButtons/Button";
import CloseButton from "../../../components/buttons/CloseButton";

const DealModal = ({ deal, handleEdit, close }) => {
  const [startDate, setStartDate] = useState(deal?.startDate || "");
  const [sow, setSow] = useState(deal?.sow || "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // if it has the required fields
      if (sow && startDate) {
        // put it into the first contract stage
        await handleEdit({ sow, startDate, status: contractStages[0] });

        if (close) {
          close();
        }
      }
    } catch (error) {
      console.error("error signing deal", error);
    }
  };

  return (
    <Container>
      {close ? <Close onClick={close} /> : null}

      <form onSubmit={handleSubmit}>
        <p>Before this deal becomes a contract, we need a few more details.</p>

        <Field>
          <Label>SOW Number</Label>
          <Input
            value={sow}
            onChange={(e) => setSow(e.target.value)}
            autoComplete="off"
            required
          />
        </Field>

        <Field>
          <Label>Effective Date</Label>
          <Input
            type="date"
            value={
              startDate && isValidDate(new Date(startDate))
                ? format(getStandardizedDate(startDate), "yyyy-MM-dd")
                : undefined
            }
            onChange={(e) =>
              setStartDate(
                isValidDate(new Date(e.target.value))
                  ? getStandardizedDate(e.target.value)
                  : null,
              )
            }
            required
          />
        </Field>

        <Button type="submit">Submit</Button>
      </form>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 25px;
  font-family: ${(props) => props.theme.fontFamily_Inter};
`;

const Close = styled(CloseButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

DealModal.propTypes = {};

export default DealModal;
