import React from "react";
import styled from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

const DefaultLink = ({ children, danger = false, ...rest }) => {
  return (
    <Button danger={danger ? 1 : 0} {...rest}>
      {children}
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset()}
  position: relative;
  text-decoration: none;
  color: ${(props) =>
    props.danger ? props.theme.colors.red : props.theme.colors.blue};
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  font-weight: bold;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${(props) =>
      props.danger
        ? props.theme.colors.red
        : props.white
        ? "white"
        : props.theme.colors.blue};
    transition: transform 0.2s;
    transform-origin: left;
    transform: scaleX(0);
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
`;

export default DefaultLink;
