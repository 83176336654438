import React from "react";
import styled from "styled-components";

const RecurringIcon = ({ isActive, ...rest }) => {
  return (
    <Svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isActive={isActive}
      {...rest}
    >
      <circle cx="12" cy="12.5" r="12" />
      <path
        d="M18.3998 7.37793V11.2187H14.5591"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7933 14.4193C16.3772 15.597 15.5896 16.6077 14.5491 17.2989C13.5087 17.9901 12.2718 18.3244 11.0248 18.2514C9.77778 18.1785 8.58828 17.7022 7.63552 16.8944C6.68276 16.0866 6.01835 14.991 5.74242 13.7728C5.46649 12.5545 5.59398 11.2796 6.10569 10.1401C6.6174 9.00058 7.48559 8.05826 8.57945 7.45512C9.6733 6.85198 10.9336 6.62069 12.1703 6.79612C13.407 6.97154 14.5533 7.54416 15.4362 8.4277L18.4 11.2186"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  circle {
    fill: ${(props) =>
      props.isActive
        ? props.theme.colors.indigo100
        : props.theme.colors.gray200};
  }

  path {
    stroke: ${(props) =>
      props.isActive
        ? props.theme.colors.indigo600
        : props.theme.colors.gray400};
  }
`;

export default RecurringIcon;
