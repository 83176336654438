import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ProfileImage from "../../../components/ProfileImage";

const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

const findMember = (members, memberId) => {
  const tempMember = members.filter((member) => {
    return member._id === memberId;
  });

  if (tempMember && tempMember.length) {
    return tempMember[0].handle;
  } else {
    return "No Owner";
  }
};

const Tasks = ({
  tasks,
  selectedProject,
  selectedMember,
  filterType,
  allMembers,
}) => {
  let groupedTasks = groupBy(tasks, "project");

  if (filterType === "Project") {
    if (selectedProject && selectedProject.value !== "reset") {
      tasks = tasks.filter((task) => {
        return task.projectId === selectedProject.value;
      });
    }

    groupedTasks = groupBy(tasks, "project");
  } else if (filterType === "Member") {
    if (selectedMember && selectedMember.value !== "reset") {
      tasks = tasks.filter((task) => {
        return task.memberId === selectedMember.value;
      });
    }

    groupedTasks = groupBy(tasks, "memberId");
  }

  return (
    <List>
      {Object.keys(groupedTasks).map(function(key) {
        const projectTasks = [...groupedTasks[key]];

        return (
          <Project key={key}>
            <ProjectHeader>
              <ProjectName>
                {filterType === "Member" && allMembers && allMembers.length
                  ? findMember(allMembers, key)
                  : key}
              </ProjectName>
              <UnscheduledContainer>
                <UnscheduledTitle>Unscheduled</UnscheduledTitle>
                <Unscheduled>{projectTasks?.length}</Unscheduled>
              </UnscheduledContainer>
            </ProjectHeader>

            {projectTasks.map((task, index) => {
              // round down to the nearest hour (and pad with a 0 in case the number isn't 2 digits)
              const hours = `${Math.floor(task.hours)}`;
              const hoursString = hours.padStart(2, "0");

              // get the decimal of hours and multiply by 60 to get the minutes (and pad with a 0 in case the number isn't 2 digits)
              const minutes = `${(task.hours % 1) * 60}`;
              const minutesString = minutes.padStart(2, "0");

              // data expected by the calendar to create an event
              const eventData = {
                // don't let the calendar automatically create the event. We'll handle it ourselves so all the events just exist in our state
                create: false,
                id: task.id,
                title: task.title,
                code: task.code,
                projectId: task.projectId,
                role: task.role || null,
                member: task.memberId || null,
                duration: `${hoursString}:${minutesString}`,
                hours: task.hours,
                resourceId: task.resourceId,
                // denote that this timeslot is being dropped on the calendar, so we can style it accordingly
                isDrop: true,
              };
              const eventDataAttr = JSON.stringify(eventData);

              const owner = task.memberId
                ? allMembers.find((member) => member._id === task.memberId)
                : null;

              return (
                <Item
                  key={index}
                  hasError={false}
                  // attribute must be "data-event" so the calendar can grab the data
                  data-event={eventDataAttr}
                  title={undefined}
                >
                  <ItemMain>
                    {owner ? (
                      <ProfileImage
                        handle={owner.handle}
                        name={owner.name}
                        small
                        showStatus
                      />
                    ) : task.role ? (
                      <ProfileImage name={task.role.role} small />
                    ) : null}

                    <Details>
                      <Title>{task.code ? task.code : "???"}</Title>
                      {!owner && task.role.role ? (
                        <Subtitle>{task.role.role}</Subtitle>
                      ) : null}
                      {/* <Text>{task.title}</Text> */}
                    </Details>

                    <Time>{`${hours}:${minutesString}`}</Time>
                  </ItemMain>
                  <Text>{task.title}</Text>
                </Item>
              );
            })}
          </Project>
        );
      })}
    </List>
  );
};

const List = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  padding: 0 15px;
  margin: 0;
  list-style-type: none;
  overflow: auto;
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  border: 3px solid;
  border-color: ${(props) => props.theme.colors.oldLightGray};
  background-color: #fff;

  padding: 5px 10px;
  margin-bottom: 5px;

  cursor: ${(props) => (props.hasError ? "default" : "grab")};

  &:active {
    cursor: ${(props) => (props.hasError ? "default" : "grabbing")};
  }
`;

const ItemMain = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 10px;
`;

const Time = styled.div`
  font-size: 12px;

  strong {
    font-weight: 600;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
`;

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.25;

  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 1.25;
  margin-top: 5px;
`;

const Project = styled.div`
  margin-bottom: 30px;
`;

const ProjectName = styled.h3`
  font-size: 12px;
  margin-bottom: 0px;
  margin-right: 10px;
`;

const ProjectHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
`;

const UnscheduledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const UnscheduledTitle = styled.p`
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 0px;
  margin-right: 5px;
`;

const Unscheduled = styled.div`
  background-color: #df5757;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 1;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: 700;
  font-size: 10px;
`;

Tasks.propTypes = {
  tasks: PropTypes.array,
  groups: PropTypes.array,
};

Tasks.defaultProps = {
  events: [],
  groups: [],
};

export default Tasks;
