import React, { useState, useEffect } from "react";
import Select from "react-select";
import selectStyles from "../vendor/react-select/newStyles";

const SelectDropdown = React.forwardRef(
  ({ borderless, autoOpen, ...props }, ref) => {
    // start it as undefined so the menu can control its open state on its own if we aren't controlling it ourselves
    const [shouldOpen, setShouldOpen] = useState(undefined);

    // ? handling menuIsOpen prop this way because if we pass it in directly, it will ignore the value of menuPlacement.
    // ? so it we were to pass in menuPlacement: "top" and menuIsOpen: true, the menu would just open downward, ignoring the menuPlacement prop.
    // ? this way, menuPlacement has time to get set, then the menu can be auto-opened
    useEffect(() => {
      setTimeout(() => {
        setShouldOpen(autoOpen ? true : undefined);
      }, 100);
    }, [autoOpen]);

    return (
      <Select
        ref={ref}
        styles={{
          ...selectStyles,
          control: (provided) => ({
            ...provided,
            border: borderless ? "none" : "1px solid #e5e7eb",
            fontFamily: "Inter",
            fontSize: "14px",
            boxShadow: borderless
              ? "none"
              : "0px 1px 2px rgba(31, 41, 55, 0.08)",
            borderRadius: "4px",
          }),

          // This is so the dropdown menu goes above buttons & other elements
          menu: (provided) => ({ ...provided, zIndex: 2 }),

          option: (provided) => ({
            ...provided,
            fontFamily: "Roboto",
            fontSize: "14px",
          }),
        }}
        menuIsOpen={shouldOpen}
        {...props}
      />
    );
  },
);

export default SelectDropdown;
