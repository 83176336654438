import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

const SimpleLink = ({ danger, small, nounderline, children, ...rest }) => {
  return (
    <Button
      danger={danger ? 1 : 0}
      small={small ? 1 : 0}
      nounderline={nounderline ? 1 : 0}
      {...rest}
    >
      {children}
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset()};
  position: relative;

  font-size: ${(props) => (props.small ? `10px` : `14px`)};
  font-weight: 600;
  text-decoration: none;

  color: ${(props) =>
    props.danger ? props.theme.colors.red : props.theme.colors.oldBlack};

  transition: 0.2s;

  ${(props) =>
    !props.nounderline
      ? css`
          &::before,
          &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: ${(props) => (props.small ? `0px` : `1px`)};
            left: 0;
            width: 100%;
            height: 1px;
            background-color: ${(props) =>
              props.danger
                ? props.theme.colors.red
                : props.theme.colors.oldBlack};
          }

          &::before {
            transform-origin: right;
            transform: scaleX(1);
          }

          &::after {
            transform-origin: left;
            transform: scaleX(0);
          }
        `
      : ``}

  &:hover, &:focus-visible {
    color: ${(props) => props.theme.colors.slate};

    &::before {
      background-color: ${(props) => props.theme.colors.slate};

      animation: shrinkUnderline 1.2s infinite;
    }

    &::after {
      background-color: ${(props) => props.theme.colors.slate};
      animation: growUnderline 1.2s infinite;
    }
  }

  @keyframes shrinkUnderline {
    0% {
      transform: scaleX(1);
    }

    50% {
      transform: scaleX(0);
    }

    100% {
      transform: scaleX(0);
    }
  }

  @keyframes growUnderline {
    0% {
      transform: scaleX(0);
    }

    15% {
      transform: scaleX(0);
    }

    65% {
      transform: scaleX(1);
    }

    100% {
      transform: scaleX(1);
    }
  }
`;

SimpleLink.propTypes = {
  danger: PropTypes.bool,
  small: PropTypes.bool,
  nounderline: PropTypes.bool,
};

SimpleLink.defaultProps = {
  danger: false,
  small: false,
  nounderline: false,
};

export default SimpleLink;
