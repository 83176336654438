import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { darken, rgba } from "polished";

import StartIcon from "../../../components/icons/StartIcon";
import CloseButton from "../../../components/buttons/CloseButton";
import { respondTo } from "../../../styles/styleHelpers";

const Timeslot = ({
  title,
  subtitle,
  duration,
  completionPct,
  dragging,
  isPast,
  handleRemove,
  handleStart,
}) => {
  // round down to the nearest hour (and pad with a 0 in case the number isn't 2 digits)
  const hours = `${Math.floor(duration)}`;
  const hoursString = hours.padStart(2, "0");

  // get the decimal of hours and multiply by 60 to get the minutes (and pad with a 0 in case the number isn't 2 digits)
  const minutes = `${(duration % 1) * 60}`;
  const minutesString = minutes.padStart(2, "0");

  return (
    <Container
      status={
        dragging
          ? "default"
          : completionPct >= 1
          ? "green"
          : isPast
          ? "red"
          : "yellow"
      }
    >
      {duration && (
        <TimeMeter
          completionPct={completionPct * 100}
          showCompletion={!dragging}
          danger={isPast}
        />
      )}

      <Body condensed={duration <= 0.75}>
        {title && <Title condensed={duration <= 0.75}>{title}</Title>}

        {subtitle && duration > 0.5 && (
          <Subtitle condensed={duration <= 0.75}>{subtitle}</Subtitle>
        )}
      </Body>

      <Time condensed={duration < 0.5}>
        {hoursString}:{minutesString}
      </Time>

      {handleStart ? (
        <Timer
          isHidden={dragging}
          size={duration <= 0.5 ? "small" : duration <= 1 ? "medium" : "large"}
          duration={duration}
        >
          <TimerButton onClick={handleStart}>
            <StartIcon />
          </TimerButton>
        </Timer>
      ) : null}

      <Actions isHidden={dragging}>
        <StyledCloseButton onClick={handleRemove} />
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;

  border: 4px solid;
  border-color: ${(props) =>
    props.status === "green"
      ? rgba(props.theme.colors.lightGreen, 0.6)
      : props.status === "yellow"
      ? rgba(props.theme.colors.lightYellow, 0.6)
      : props.status === "red"
      ? rgba(props.theme.colors.lightRed, 0.6)
      : "#e9ebf2"};

  border-radius: 8px;
  background-color: white;

  box-shadow: 4px 8px 14px ${rgba("#b9bcc0", 0.3)};
`;

const TimeMeter = styled.div`
  position: absolute;
  top: 3px;
  left: 2px;
  bottom: 3px;
  width: 4px;
  border-radius: 999px;
  background-color: ${(props) =>
    !props.showCompletion
      ? "#e9ebf2"
      : props.completionPct >= 100
      ? props.theme.colors.lightGreen
      : props.danger
      ? props.theme.colors.lightRed
      : props.theme.colors.lightYellow};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${(props) => `${props.completionPct}%`};
    background-color: ${(props) =>
      props.completionPct >= 100
        ? props.theme.colors.green
        : props.danger
        ? props.theme.colors.red
        : props.theme.colors.yellow};
    border-radius: 999px;
    opacity: ${(props) => (props.showCompletion ? 1 : 0)};
  }
`;

const Body = styled.div`
  padding-top: ${(props) => (props.condensed ? "7px" : "23px")};
  padding-left: 17px;
  padding-right: 10px;
  padding-bottom: 7px;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.condensed ? "center" : "flex-start")};
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: ${(props) => (props.condensed ? "16px" : "18px")};
  font-weight: 700;
  line-height: 1.25;
`;

const Subtitle = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.25;

  /* to prevent a long subtitle from spilling out of the timeslot */
  overflow: hidden;

  ${respondTo("xlarge")} {
    font-size: ${(props) => (props.condensed ? "14px" : "16px")};
  }
`;

const Time = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  padding: 5px;
  font-size: 12px;

  display: flex;
  align-items: ${(props) => (props.condensed ? "center" : "flex-start")};
`;

const Hideable = styled.div`
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.isHidden
      ? ``
      : css`
          transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

          ${Container}:hover & {
            opacity: 1;
            visibility: visible;
          }
        `}
`;

const Actions = styled(Hideable)`
  position: absolute;
  top: 0;
  left: 100%;
  padding: 0 5px;

  /* if the timeslot is in the last day on the right, put the Actions in the gutter to the left, rather than right */
  .fc-timegrid-col.fc-day:last-child & {
    left: auto;
    right: 100%;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: static;
`;

const Timer = styled(Hideable)`
  line-height: 0;

  ${(props) =>
    props.duration < 0.5
      ? css`
          /* 15 min duration = next to the project code */
          display: flex;
          align-items: center;
          padding-right: 40px;
          padding-right: ${props.duration < 0.5 ? "40px" : "50px"};
        `
      : css`
          position: absolute;
          bottom: 0;
          padding: ${props.duration === 0.5 ? "2px 5px" : "5px"};

          /* 30 min or above 1hr duration = lower right corner, inside timeslot */
          /* 45 min or 1hr duration = lower right corner, outside timeslot in gutter */
          left: ${props.duration > 1 || props.duration === 0.5
            ? "auto"
            : "100%"};
          right: ${props.duration > 1 || props.duration === 0.5 ? "0" : "auto"};

          /* if the timeslot is in the last day on the right, put the this in the gutter to the left, rather than right */
          .fc-timegrid-col.fc-day:last-child & {
            ${props.duration > 1 || props.duration === 0.5
              ? ``
              : css`
                  left: auto;
                  right: 100%;
                `}
          }
        `}
`;

const TimerButton = styled.button`
  line-height: 0;

  svg {
    /* width: 24px;
    height: 24px; */
    width: 18px;
    height: 18px;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    svg {
      transform: scale(1.15);
    }

    path {
      ${(props) =>
        props.isStop
          ? css`
              fill: ${darken(0.1, props.theme.colors.red)};
            `
          : css`
              animation: startColor 10s infinite linear;
            `}
    }
  }
`;

Timeslot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  duration: PropTypes.number,
  completionPct: PropTypes.number,
  dragging: PropTypes.bool,
  isPast: PropTypes.bool,
  handleRemove: PropTypes.func,
  handleStart: PropTypes.func,
};

Timeslot.defaultProps = {
  title: "",
  subtitle: "",
  duration: 1,
  completionPct: 0,
  dragging: false,
  isPast: false,
  handleRemove: null,
  handleStart: null,
};

export default Timeslot;
