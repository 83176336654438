import React from "react";
import styled from "styled-components";

const SplitIcon = ({ ...props }) => {
  return (
    <Svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.64844"
        y="1.90796"
        width="9"
        height="17"
        rx="1"
        stroke="#F9FAFB"
        strokeWidth="2"
      />
      <rect
        x="13.6484"
        y="0.907959"
        width="11"
        height="19"
        rx="2"
        fill="#F9FAFB"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default SplitIcon;
