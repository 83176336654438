import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { format } from "date-fns";
import { getStandardizedDate } from "../../../utils/helpers";
import Button from "../../../components/newButtons/Button";
import { Input, Textarea } from "../../../components/newForm";
import SelectDropdown from "../../../components/newSelectDropdown";

/**
 * This is the edit/create popup modal for the Finish Week Modal
 * @param {object} entry // The harvest entry object
 * @param {number} entry.id // Harvest id
 * @param {string} entry.spent_date // Date the entry is for
 * @param {string} entry.started_time // Start time
 * @param {string} entry.ended_time // End time
 * @param {string} entry.notes // Notes (can be null)
 * @param {boolean} [entry.new] // If the entry should be created as a new entry (as opposed to editing it)
 * @param {[object]} availProjects // Available projects for this week
 * @param {function} close // Function to close the modal
 * @param {function} handleSubmit // Funtion to handle submitting the data
 */
const FinishEdit = ({ entry, availProjects, close, handleSubmit }) => {
  const [date, setDate] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (entry) {
      setupData();
    }
  }, [entry]);

  const setupData = async () => {
    if (entry.spent_date) {
      const newDate = format(
        getStandardizedDate(new Date(entry.spent_date)),
        "yyyy-MM-dd",
      );
      setDate(newDate);
    }

    if (entry.started_time) {
      setStartTime(entry.started_time);
    }

    if (entry.ended_time) {
      setEndTime(entry.ended_time);
    }

    if (entry.notes) {
      setNotes(entry.notes);
    }

    if (entry.project?.id && availProjects) {
      const newProject = availProjects.find(
        (project) => project.harvestId === entry.project?.id,
      );

      if (newProject) {
        setSelectedProject(newProject._id);
      }
    }
  };

  const formatProjectValue = () => {
    if (selectedProject && availProjects) {
      const projectInfo = availProjects.find(
        (project) => project._id === selectedProject,
      );

      if (projectInfo) {
        return {
          label: projectInfo.name,
          value: projectInfo._id,
        };
      }
    }

    return null;
  };

  const submit = () => {
    if (entry.new) {
      const projectInfo = availProjects.find(
        (project) => project._id === selectedProject,
      );

      if (projectInfo && date && startTime && endTime) {
        const data = {
          id: entry.id,
          projectId: projectInfo.harvestId,
          spentDate: format(getStandardizedDate(new Date(date)), "yyyy-MM-dd"),
          start: startTime,
          end: endTime,
          notes: notes ? notes : null,
        };

        handleSubmit(data);
      }
    } else {
      const data = {
        id: entry.id,
      };

      if (date) {
        data.day = format(getStandardizedDate(new Date(date)), "yyyy-MM-dd");
      }

      if (selectedProject && availProjects) {
        const projectInfo = availProjects.find(
          (project) => project._id === selectedProject,
        );

        if (projectInfo) {
          data.project = projectInfo.harvestId;
        }
      }

      if (startTime) {
        data.start = startTime;
      }

      if (endTime) {
        data.end = endTime;
      }

      if (notes) {
        data.notes = notes;
      }

      handleSubmit(data);
    }
  };

  return (
    <Wrapper>
      <Container>
        <InputRow>
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.currentTarget.value)}
          />
        </InputRow>
        <InputRow>
          <Dropdown
            value={selectedProject ? formatProjectValue() : null}
            options={availProjects.map((project) => ({
              label: project.name,
              value: project._id,
            }))}
            onChange={(e) => setSelectedProject(e.value)}
          />
        </InputRow>
        <TimeRow>
          <TimeInput
            placeholder="Start"
            value={startTime}
            onChange={(e) => setStartTime(e.currentTarget.value)}
          />
          <To>to</To>
          <TimeInput
            placeholder="End"
            value={endTime}
            onChange={(e) => setEndTime(e.currentTarget.value)}
          />
        </TimeRow>
        <InputRow>
          <Textarea
            placeholder="Notes"
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
            style={{ width: "100%" }}
          />
        </InputRow>
        <ButtonRow>
          <Button onClick={() => submit()}>
            {entry.new ? `Create` : `Update`} Entry
          </Button>
          <Button onClick={close} light>
            Cancel
          </Button>
        </ButtonRow>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: ${(props) => rgba(props.theme.colors.oldBlack2, 0.15)};
  transition: opacity 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  height: 393px;
  width: 367px;
  padding: 19px 20px;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const TimeRow = styled(InputRow)`
  max-width: 253px;
`;

const TimeInput = styled(Input)`
  width: 105px;
  min-width: none;
`;

const To = styled.p`
  display: inline-block;
  margin-bottom: 0;
  padding-left: 14px;
  padding-right: 14px;
`;

const Dropdown = styled(SelectDropdown)`
  width: 100%;
`;

export default FinishEdit;
