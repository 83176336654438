import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";

import {
  getProjectHoursFromApi,
  getMembersFromApi,
  getProjectPaceFromApi,
} from "../../../utils/api";
import { getPastEntriesFromApi } from "../../dashboard/utils/api";
import { isNumber } from "../../../utils/helpers";

import Widget from "./Widget";
import BudgetWheel from "./BudgetWheel";
import ProjectExpenses from "./ProjectExpenses";
import BudgetTable from "./BudgetTable";
import Burndown from "./Burndown";

const BudgetTab = ({ project, expenseBudget, expenses, activeMembers }) => {
  const [members, setMembers] = useState(null);

  // team members with their hours spent
  const [projectHours, setProjectHours] = useState(null);
  // project team with their dollar amounts
  const [teamsDollars, setTeamsDollars] = useState(null);
  // project team grouped by department
  const [teamsData, setTeamsData] = useState(null);

  // total tracked dollars on the project
  const [trackedDollars, setTrackedDollars] = useState(null);
  // total budgeted dollars on the project
  const [timeEntries, setTimeEntries] = useState(null);
  const [budgetedDollars, setBudgetedDollars] = useState(0);
  // amount of dollars that should have been spent by now
  const [paceDollars, setPaceDollars] = useState(null);

  useEffect(() => {
    getMembers();

    // for the budget table
    // - hours tracked by project team members
    getProjectHours();

    // for the budget wheel
    // - pace bar
    getProjectPace();

    // - bar fill
    if (project.harvestId) {
      getTrackedDollars(project.harvestId);
    }
  }, []);

  // total budget for the budget wheel
  useEffect(() => {
    let totalRate = 0;

    // roles assigned to the project
    project?.tasks?.forEach((roleBudget) => {
      // total dollars budgeted for this role
      totalRate += roleBudget.totalRate;
    });

    setBudgetedDollars(totalRate);
  }, [project]);

  // get the budgeted dollars for each team member from the project model
  useEffect(() => {
    if (project?.teamMembers && project?.tasks && members) {
      // * team member hours
      // loop over the team members on the project
      const memberDollars = project.teamMembers.map((teamMember) => {
        // get the member's full props
        const member = members.find(
          (member) => member._id === teamMember.teamMember,
        );

        // find the roleBudget associated with this member's role
        const roleBudget = project.tasks.find(
          (roleBudget) => roleBudget.taskId._id === member.role._id,
        );

        // use the roleBudget to determine the member's rate
        const rate = roleBudget
          ? roleBudget.totalRate / roleBudget.totalHours
          : 0;

        return {
          ...member,
          budgetedDollars: Math.ceil(teamMember.bookingQty * rate),
          rate,
        };
      });

      // * role hours
      // loop over the roles budgeted on the project
      const backlogDollars = project.tasks
        .map((roleBudget) => {
          let roleAssignedHours = 0;

          // get the team members who have this role
          project.teamMembers.forEach((teamMember) => {
            const member = members.find(
              (thisMember) => thisMember._id === teamMember.teamMember,
            );

            // add up all of their hours
            if (member?.role._id === roleBudget.taskId._id) {
              roleAssignedHours += teamMember.bookingQty;
            }
          });

          // subtract them all from the roleBudget's totalHours
          // what's left is the amount of hours budgeted for that role that aren't assigned to a specific user
          const backlogHours = roleBudget.totalHours - roleAssignedHours;

          // rate for this role
          const rate = roleBudget.totalRate / roleBudget.totalHours;

          return {
            _id: roleBudget.taskId._id,
            name: roleBudget.taskId.role,
            department: "Backlog",
            budgetedDollars: Math.ceil(backlogHours * rate),
            rate,
          };
        })
        // filter out any roles that don't have any unassigned dollars
        .filter((roleDollars) => roleDollars.budgetedDollars > 0);

      // team members + the remaining roles that have time budgeted but aren't yet assigned to a team member
      setTeamsDollars([...memberDollars, ...backlogDollars]);
    }
  }, [members]);

  // update the team members with the amount of dollars that they have tracked
  useEffect(() => {
    // once we have projectHours
    if (teamsDollars && projectHours) {
      // loop over the team
      const updatedTeamsDollars = teamsDollars.map((teamMember) => {
        // find this member in projectHours
        const timeMember = projectHours.find(
          (thisTimeMember) => thisTimeMember._id === teamMember._id,
        );

        // calculate the spent amount for this user
        const spentDollars = timeMember
          ? Math.ceil(timeMember.hoursBilled * teamMember.rate)
          : 0;

        return {
          ...teamMember,
          spentDollars,
        };
      });

      // ? clear the project hours so this doesn't run in an infinite loop
      setProjectHours(null);

      setTeamsDollars(updatedTeamsDollars);
    }
  }, [projectHours]);

  // group the team dollars by department
  useEffect(() => {
    if (teamsDollars) {
      const departments = groupMembers(teamsDollars, "department")
        // Totals up the dollar values for each department
        .map((team) => {
          // let totalBudgeted = 0;
          // let totalSpent = 0;
          let totalBudgeted = null;
          let totalSpent = null;

          team.members.forEach((member) => {
            if (isNumber(member.budgetedDollars)) {
              totalBudgeted += member.budgetedDollars;
            }
            if (isNumber(member.spentDollars)) {
              totalSpent += member.spentDollars;
            }
          });

          return {
            ...team,
            totalBudgeted,
            totalSpent,
          };
        });

      setTeamsData(departments);
    }
  }, [teamsDollars]);

  const groupMembers = (entries, groupBy) => {
    const newGroupedEntries = _(entries)
      .groupBy(groupBy)
      .map((items, key) => ({
        name: key,
        members: items.sort((a, b) =>
          // sort members by alpha
          a.handle > b.handle ? 1 : a.handle < b.handle ? -1 : 0,
        ),
      }))
      .value();

    return newGroupedEntries;
  };

  const getMembers = async () => {
    try {
      const apiMembers = await getMembersFromApi({ showArchived: true });
      setMembers(apiMembers || []);
    } catch (error) {
      console.error("getMembers failed", error);
    }
  };

  const getProjectHours = async () => {
    try {
      const apiProjectHours = await getProjectHoursFromApi(project._id);
      setProjectHours(apiProjectHours || []);
    } catch (error) {
      console.error("get project hours failed", error);
    }
  };

  const getProjectPace = async () => {
    try {
      const { dollars } = await getProjectPaceFromApi(project._id);
      setPaceDollars(dollars || 0);
    } catch (error) {
      console.error("get project pace failed", error);
    }
  };

  const getTrackedDollars = async (harvestId) => {
    try {
      // get the tracked time in dollars
      const timeEntries = await getPastEntriesFromApi({
        projectId: harvestId,
        calculate: true,
      });

      setTrackedDollars(timeEntries.data.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Burndown budget={budgetedDollars} timeEntries={timeEntries} />

      <Columns>
        <LeftCol>
          <Widget>
            <WheelContainer>
              <BudgetWheel
                label="Tracked Time"
                current={trackedDollars}
                total={budgetedDollars}
                pace={paceDollars}
                small
              />
            </WheelContainer>
          </Widget>
        </LeftCol>

        <MiddleWidget>
          {teamsData ? <BudgetTable teams={teamsData} /> : <BudgetSkeleton />}
        </MiddleWidget>

        <RightCol>
          <Widget>
            <ProjectExpenses
              projectId={project._id}
              expenseBudget={expenseBudget}
              expenses={expenses}
              activeMembers={activeMembers}
            />
          </Widget>
        </RightCol>

        <LastCol />
      </Columns>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;
`;

const Columns = styled.div`
  display: flex;
  gap: 17px;
`;

const LeftCol = styled.div`
  flex: 0 0 16%;
`;

const WheelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 381px;
`;

const MiddleWidget = styled(Widget)`
  flex: 0 0 56.25%;
`;

const RightCol = styled.div`
  flex: 0 0 24.25%;
`;

// placeholder at the end so we always have padding on the right edge
const LastCol = styled.div`
  flex: 0 0 20px;
`;

const BudgetSkeleton = styled.div`
  background-color: ${(props) => props.theme.colors.lightGray};
  height: 300px;
`;

BudgetTab.propTypes = {
  project: PropTypes.object,
};

export default BudgetTab;
