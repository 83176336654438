import axios from "axios";
import { headers } from "../../../utils/api";

export const getTaskFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/${id}`,
      headers,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const createTaskOnApi = async (data) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/tasks/`,
    headers,
    data,
  };

  const result = await axios(config);

  return result;
};

export const updateTaskOnApi = async (taskId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
      headers,
      data,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTaskOnApi = async (taskId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
      headers,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Task Generator
|--------------------------------------------------------------------------
*/

export const getAllTaskGeneratorsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/generators`,
      headers,
      params,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTaskGeneratorFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/generators/${id}`,
      headers,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const createTaskGeneratorOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/tasks/generators`,
      headers,
      data,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateTaskGeneratorOnApi = async (taskId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/generators/${taskId}`,
      headers,
      data,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTaskGeneratorOnApi = async (taskId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/tasks/generators/${taskId}`,
      headers,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Task Template
|--------------------------------------------------------------------------
*/

export const getAllTaskTemplatesFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/templates`,
      headers,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTaskTemplateFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/templates/${id}`,
      headers,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const createTaskTemplateOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/tasks/templates`,
      headers,
      data,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateTaskTemplateOnApi = async (taskId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/templates/${taskId}`,
      headers,
      data,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTaskTemplateOnApi = async (taskId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/tasks/templates/${taskId}`,
      headers,
    };

    const result = await axios(config);

    return result;
  } catch (err) {
    throw new Error(err);
  }
};
