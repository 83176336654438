import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format, addHours } from "date-fns";

import { getStandardizedDate, getWeek } from "../../../utils/helpers";

import {
  StatsMain,
  StatsCol,
  Stat,
  StatLabel,
  StatValue,
} from "../../../components/NewTable/components";
import Switch from "../../../components/newSwitch";
import Editable from "../../../components/Editable";
import { NumberChip } from "../../../components/ValueChip";
import { Input } from "../../../components/newForm";

import CloseButton from "../../../components/buttons/CloseButton";
import ProfileImage from "../../../components/ProfileImage";

import TaskNotes from "./TaskNotes";
import RecurringModal from "./RecurringModal";

const TaskModal = ({
  task,
  taskEditable,
  toggleTaskCompleted,
  stepDetails,
  userId,
  isImportant,
  isSuper,
  deliverables,
  phases,
  close,
}) => {
  const [currentTask, setCurrentTask] = useState(task);

  useEffect(() => {
    if (task) {
      setCurrentTask(task);
    }
  }, [task]);

  const toggleComplete = () => {
    const newStatus =
      currentTask.status === "Completed" ? "In progress" : "Completed";

    const tempTask = { ...currentTask, status: newStatus };

    setCurrentTask(tempTask);
    toggleTaskCompleted(currentTask._id);
  };

  const taskPhase =
    phases?.find((phase) => phase._id === currentTask.phase) || null;

  const taskDeliverable =
    deliverables?.find(
      (deliverable) => deliverable._id === currentTask.deliverableId,
    ) || null;

  const taskWeekNumberString = currentTask.weekNumber
    ? `${currentTask.weekNumber.split("w")[0]} - W${
        currentTask.weekNumber.split("w")[1]
      }`
    : "N/A";

  // whether the task belongs to this user
  const belongsToUser =
    userId && currentTask.memberId?._id && userId === currentTask.memberId._id;

  return (
    <Container>
      <Close onClick={close} />
      <Header>
        <Title>
          <Editable
            id={`${currentTask._id}-title-modal`}
            {...taskEditable(task, "title")}
          >
            {currentTask.title || "N/A"}
          </Editable>
        </Title>

        <Editable
          id={`${task._id}-status-modal`}
          {...taskEditable(task, "status")}
        >
          <NumberChip color="indigo">{currentTask.status}</NumberChip>
        </Editable>
      </Header>

      <StatsSection>
        <StatsCol>
          <Stat>
            <StatLabel>Owner</StatLabel>
            <StatValue>
              <Editable
                id={`${currentTask._id}-owner-modal`}
                {...taskEditable(task, "owner")}
              >
                <ProfileImage
                  handle={currentTask.memberId?.handle || undefined}
                  name={
                    currentTask.memberId?.name || currentTask.role?.role || ""
                  }
                  showStatus={currentTask.memberId ? true : undefined}
                  small
                />
              </Editable>
            </StatValue>
          </Stat>

          <Stat>
            <StatLabel>Due Date</StatLabel>
            <Editable
              id={`${currentTask._id}-due-modal`}
              {...taskEditable(task, "due")}
            >
              <StatValue>
                {currentTask.date
                  ? format(getStandardizedDate(currentTask.date), "MM/dd/yyyy")
                  : "N/A"}
              </StatValue>
            </Editable>
          </Stat>

          <Stat>
            <StatLabel>Step</StatLabel>
            <StatValue>
              <Editable
                id={`${currentTask._id}-step-modal`}
                {...taskEditable(task, "step")}
              >
                {currentTask.step ? (
                  <NumberChip color={stepDetails.color}>
                    {currentTask.step}
                  </NumberChip>
                ) : (
                  "N/A"
                )}
              </Editable>
            </StatValue>
          </Stat>

          {/* <Stat>
            <StatLabel>Company Name</StatLabel>
            <StatValue>
              <Editable
                id={`${currentTask._id}-company-name-modal`}
                onSave={(value) =>
                  handleDetailsEdit(currentTask._id, { name: value }, "company")
                }
              >
                {currentTask.projectId?.client
                  ? currentTask.projectId.client.name
                  : currentTask.clientId
                  ? currentTask.clientId.name
                  : "N/A"}
              </Editable>
            </StatValue>
          </Stat> */}

          <Stat>
            <StatLabel>Project</StatLabel>
            <StatValue>
              {task.projectId.name}

              {/* <Editable
                id={`${currentTask._id}-project-name-modal`}
                onSave={(value) =>
                  handleDetailsEdit(currentTask._id, { name: value }, "project")
                }
              >
                {task.projectId.name}
              </Editable> */}
            </StatValue>
          </Stat>

          <Stat>
            <StatLabel>Role</StatLabel>
            <Editable
              id={`${currentTask._id}-role-modal`}
              {...taskEditable(task, "role")}
            >
              <StatValue>{currentTask.role.role}</StatValue>
            </Editable>
          </Stat>
        </StatsCol>

        <StatsCol>
          <Stat>
            <StatLabel>Completed</StatLabel>
            <StatValue>
              <Switch
                id={`${currentTask._id}-toggle-modal`}
                isActive={currentTask.status === "Completed"}
                handleSwitch={() => toggleComplete()}
                disabled={
                  isSuper ? false : !belongsToUser || stepDetails?.disabled
                }
              />
            </StatValue>
          </Stat>

          {/* // ? weekNumber is only editable if there is no due date */}
          <Stat>
            <StatLabel>Week</StatLabel>
            <Editable
              id={`${currentTask._id}-weekNumber-modal`}
              {...taskEditable(task, "weekNumber")}
            >
              <StatValue>{taskWeekNumberString}</StatValue>
            </Editable>
          </Stat>

          <Stat>
            <StatLabel>Hours</StatLabel>
            <Editable
              id={`${currentTask._id}-hours-modal`}
              {...taskEditable(task, "hours")}
            >
              <StatValue>{currentTask.hoursToComplete || "0"}</StatValue>
            </Editable>
          </Stat>

          {/* <Stat>
            <StatLabel>Company Code</StatLabel>
            <StatValue>
              <Editable
                id={`${currentTask._id}-company-acronym-modal`}
                onSave={(value) =>
                  handleDetailsEdit(currentTask._id, { acronym: value }, "company")
                }
              >
                {currentTask.projectId?.client
                  ? currentTask.projectId.client.acronym
                  : currentTask.clientId
                  ? currentTask.clientId.acronym
                  : "N/A"}
              </Editable>
            </StatValue>
          </Stat> */}

          <Stat>
            <StatLabel>Deliverable</StatLabel>
            <Editable
              id={`${currentTask._id}-deliverable-modal`}
              {...taskEditable(currentTask, "deliverable", {
                taskDeliverable,
              })}
            >
              <StatValue>
                {taskDeliverable ? taskDeliverable.name : "N/A"}
              </StatValue>
            </Editable>
          </Stat>

          <Stat>
            <StatLabel>Phase</StatLabel>
            <Editable
              id={`${currentTask._id}-phase-modal`}
              {...taskEditable(task, "phase", {
                taskPhase,
              })}
            >
              <StatValue>{taskPhase ? taskPhase.name : "N/A"}</StatValue>
            </Editable>
          </Stat>

          <Stat>
            <StatLabel>Recurring</StatLabel>
            <RecurringModal
              task={currentTask}
              isActive={currentTask.recurring?.active ? true : false}
              canOpen={isImportant}
            />
          </Stat>
        </StatsCol>
      </StatsSection>

      <Notes>
        <TaskNotes
          taskId={task._id}
          initialNotes={task.notes || ""}
          blockedBy={null}
        />
      </Notes>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 14px 24px 36px;
  min-width: 681px;
`;

const Close = styled(CloseButton)`
  position: absolute;
  top: 16px;
  right: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-bottom: 12px;
`;

const Title = styled.h2`
  display: inline-block;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;

  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const StatsSection = styled(StatsMain)`
  padding-bottom: 24px;
`;

const Notes = styled.div`
  width: 100%;
  height: 400px;

  border-top: 1px solid #f3f4f6;
  padding-top: 14px;
`;

const WeekNumber = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;

  input {
    width: 75px;
  }
`;

export default TaskModal;
