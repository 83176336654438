import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format, addHours } from "date-fns";

import { getWeek, getStandardizedDate, isNumber } from "../../../utils/helpers";
import { taskStatuses } from "../../../utils/constants";
import {
  getAllRolesFromApi,
  getAllTasksFromApi,
  updateTaskStatusOnApi,
  updateClientOnApi,
  updateProjectOnApi,
  updateTaskOnApi,
  getMembersFromApi,
  createTaskOnApi,
  getProjectDeliverablesFromApi,
  deleteTasksOnApi,
} from "../../../utils/api";

import { useAuth } from "../../../context/authContext";
import { useNotifications } from "../../../context/notificationsContext";
import { useSockets } from "../../../context/socketsContext";

import { Input } from "../../../components/newForm";
import TaskModal from "./TaskModal";
import TasksTable from "./TasksTable";
import { WeekNumberFields } from "./ProjectTable";

const TasksList = ({
  project,
  projectId,
  phases,
  onTasksUpdate,
  allMembers,
  recurring,
}) => {
  const { startProjectTaskListeners, stopProjectTaskListeners } = useSockets();
  const { user, isImportant, isSuper } = useAuth();
  const {
    openModal,
    closeModal,
    isModalOpen,
    openPromptPopup,
    openAlertPopup,
    closePromptPopup,
  } = useNotifications();

  const [roles, setRoles] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [highlightedTask, setHighlightedTask] = useState(null);

  // const [availRoles, setAvailRoles] = useState([]);
  const [availMembers, setAvailMembers] = useState([]);
  const [availDeliverables, setAvailDeliverables] = useState([]);

  // object where the keys are deliverable IDs and the values are the current step of the tasks associated with that deliverable
  // { abc: 2, def: 1, ghi: 1, ... }
  const [deliverableSteps, setDeliverableSteps] = useState({});

  useEffect(() => {
    getTasks();
    getAvail();
  }, []);

  useEffect(() => {
    if (tasks) {
      startProjectTaskListeners(projectId, {
        taskCreateCallback: (newTask) => {
          setTasks((tasks) => [...tasks, newTask]);
        },
        taskEditCallback: (updatedTask) => {
          updateTask(updatedTask);
        },
        taskDeleteCallback: (deletedTaskId) => {
          removeTasks(deletedTaskId);
        },
      });

      return () => {
        stopProjectTaskListeners(projectId);
      };
    }
  }, [tasks]);

  useEffect(() => {
    if (tasks?.length) {
      // get all the unique task deliverable IDs
      const taskDeliverables = [
        ...new Set(
          tasks
            // filter out any tasks that don't have a deliverable
            .filter((task) => task.deliverableId)
            // return just the deliverable ID
            .map((task) => task.deliverableId),
        ),
      ];

      // object where the keys are deliverable IDs and values are the current step for that deliverable's tasks
      const newDeliverableSteps = {};

      // tasks that aren't associated with a deliverable
      const tasksWithoutDeliverables = tasks.filter(
        (task) => !task.deliverableId,
      );

      const theCurrentStep = getTasksCurrentStep(tasksWithoutDeliverables);

      // current step for tasks that don't have deliverables
      // ? "none" will be used as the identifier for tasks that don't have a deliverable
      newDeliverableSteps["none"] = theCurrentStep;

      // loop over each deliverable
      taskDeliverables.forEach((deliverableId) => {
        // get the tasks associated with this deliverable
        const relatedTasks = tasks.filter(
          (task) => task.deliverableId === deliverableId,
        );

        // get the current steps that those tasks are on
        const theCurrentStep = getTasksCurrentStep(relatedTasks);

        newDeliverableSteps[deliverableId] = theCurrentStep;
      });

      setDeliverableSteps(newDeliverableSteps);
    }
  }, [tasks]);

  // when tasks are updated
  useEffect(() => {
    if (tasks && onTasksUpdate) {
      // update main project view
      onTasksUpdate(tasks);
    }
  }, [tasks]);

  // when tasks are updated
  useEffect(() => {
    // if a task is selected
    if (tasks?.length && selectedTask) {
      // update it with the most up to date data
      const updatedSelectedTask = tasks.find(
        (task) => task._id === selectedTask._id,
      );
      setSelectedTask(updatedSelectedTask);
    } else {
      setSelectedTask(null);
    }
  }, [tasks]);

  useEffect(() => {
    // if theres a selected task
    if (selectedTask) {
      // open it in the task modal
      openModal(
        <TaskModal
          task={selectedTask}
          userId={user ? user._id : null}
          deliverables={availDeliverables}
          members={availMembers}
          phases={phases || []}
          stepDetails={getTaskStepDetails(selectedTask)}
          toggleTaskCompleted={toggleTaskCompleted}
          handleDetailsEdit={handleDetailsEdit}
          close={closeModal}
          isImportant={isImportant}
          isSuper={isSuper}
          taskEditable={taskEditable}
        />,
      );
    }
  }, [selectedTask]);

  useEffect(() => {
    // when modal is closed
    if (!isModalOpen) {
      //  deselect the selected task
      setSelectedTask(null);
    }
  }, [isModalOpen]);

  const getAvail = async () => {
    try {
      const [
        rolesFromApi,
        membersFromApi,
        deliverablesFromApi,
      ] = await Promise.all([
        getAllRolesFromApi(),
        getMembersFromApi(),
        getProjectDeliverablesFromApi(projectId),
      ]);
      setRoles(rolesFromApi);
      setAvailMembers(membersFromApi);
      setAvailDeliverables(deliverablesFromApi);
    } catch (error) {
      console.error("error getting data from API", error);
    }
  };

  const getTasks = async () => {
    try {
      const tasksFromApi = await getAllTasksFromApi({ projectId });

      setTasks(tasksFromApi || []);
    } catch (error) {
      console.error("error getting tasks from API", error);
    }
  };

  // get the current step for a group of tasks
  const getTasksCurrentStep = (taskList) => {
    // get all the possible steps for those tasks
    const possibleSteps = [
      ...new Set(
        taskList
          .map((task) => task.step)
          // filter out anything that isn't a number
          .filter((step) => isNumber(step))
          // sort from smallest to largest number
          .sort((a, b) => a - b),
      ),
    ];

    // start the current step as the largest step number, plus 1
    // ? steps are sorted from smallest to largest, so the last one in the array is the largest
    let theCurrentStep = possibleSteps[possibleSteps.length - 1] + 1;

    // loop over the steps
    possibleSteps.forEach((step) => {
      // check for tasks with this step that are incomplete
      const incompleteStepTasks = taskList.filter(
        (task) =>
          task.step === step && task.status.toLowerCase() !== "completed",
      );

      // if any of this step's tasks are not Completed, this is the current step
      if (incompleteStepTasks.length && step < theCurrentStep) {
        theCurrentStep = step;
      }
    });

    return theCurrentStep;
  };

  // check if a task's step is the current step, past, future, etc.
  const getTaskStepDetails = (task) => {
    // get the current step that applies to this task based on its deliverable
    // ? if it has no deliverable, the identifier is "none"
    const thisCurrentStep = deliverableSteps?.[task.deliverableId || "none"];

    const stepStatus =
      isNumber(thisCurrentStep) && isNumber(task.step)
        ? thisCurrentStep > task.step
          ? "completed"
          : thisCurrentStep === task.step
          ? "current"
          : "future"
        : null;

    // ? future steps are the default gray
    const stepColor =
      stepStatus === "completed"
        ? "teal"
        : stepStatus === "current"
        ? "indigo"
        : "default";

    return {
      status: stepStatus,
      color: stepColor,
      // if its a future step, it should be disabled for now
      disabled: stepStatus === "future",
    };
  };

  const toggleTaskCompleted = async (taskId) => {
    const originalTasks = [...tasks];

    try {
      const thisTask = tasks.find((task) => task._id === taskId);

      const newStatus =
        thisTask?.status === "Completed" ? "In progress" : "Completed";

      const newTasks = tasks.map((task) => {
        const tempTask = { ...task };

        if (tempTask._id === thisTask?._id) {
          tempTask.status = newStatus;
        }
        return tempTask;
      });

      // optimistically update state
      setTasks(newTasks);

      // update on API
      await updateTaskStatusOnApi(taskId, newStatus);
    } catch (error) {
      // revert to original task state
      setTasks(originalTasks);

      console.error("updating task error", error);
    }
  };

  const handleDetailsEdit = async (taskId, updatedProps, context = "task") => {
    const editingTask = tasks.find((task) => task._id === taskId);

    try {
      if (!editingTask) {
        throw "Task not found";
      }

      const tempTask = { ...editingTask };

      // get the keys for the new props
      const keys = Object.keys(updatedProps);
      const formattedProps = { ...updatedProps };

      switch (context) {
        case "task":
          if (keys) {
            if (formattedProps["date"]) {
              setHighlightedTask(tempTask);
            }

            keys.forEach((key) => {
              // If the role has been changed, reset the member Id
              if (key === "role" && tempTask.memberId && tempTask.role) {
                formattedProps.memberId = null;
              }
              // tempTask[key] = formattedProps[key];
            });
          }

          // update task in state
          // updateTask(tempTask);

          // Update the task on api
          await updateTaskOnApi(tempTask._id, formattedProps);

          break;

        case "company": {
          const companyId = tempTask.projectId?.client
            ? tempTask.projectId.client._id
            : tempTask.clientId
            ? tempTask.clientId._id
            : null;
          // Update the company

          if (companyId) {
            // Attaches any props to the task's client and updates
            if (keys) {
              keys.forEach((key) => {
                if (tempTask.projectId?.client) {
                  tempTask.projectId.client[key] = formattedProps[key];
                } else if (tempTask.clientId) {
                  tempTask.clientId[key] = formattedProps[key];
                }
              });
            }

            // updateTask(tempTask);

            // Has to add database parent obj to prop because that's what server is expecting
            await updateClientOnApi(companyId, {
              database: formattedProps,
            });
          }

          break;
        }
        case "project":
          // Attaches any props to the task's project and updates
          if (keys) {
            keys.forEach((key) => {
              tempTask.projectId[key] = formattedProps[key];
            });
          }

          // updateTask(tempTask);

          // Update project
          await updateProjectOnApi(tempTask.projectId._id, {
            database: formattedProps,
          });

          break;
      }
    } catch (error) {
      console.error("Something went wrong with editing the task", error);

      // reset the task to its orgiginal props
      if (editingTask) {
        updateTask({ ...editingTask });
      }

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to edit that task. ${errMessage}`,
      );
    }
  };

  const updateTask = (updatedTask) => {
    const tempTasks = tasks.map((task) =>
      task._id === updatedTask._id ? { ...task, ...updatedTask } : task,
    );

    setTasks(tempTasks);
  };

  const removeTasks = (taskIds) => {
    // array of task IDs
    const tasksToRemove = Array.isArray(taskIds) ? taskIds : [taskIds];

    // filter out the tasks to delete
    const newTasks = tasks.filter((task) => !tasksToRemove.includes(task._id));

    setTasks(newTasks);
  };

  const createTask = async (createData) => {
    try {
      // required fields: title, role, hours
      if (createData.title && createData.role && createData.hoursToComplete) {
        const taskData = { ...createData };

        if (createData.date) {
          // start date
          taskData.week = getStandardizedDate(createData.date);
          // due date (add the hours onto the date)
          taskData.date = addHours(
            getStandardizedDate(createData.date),
            createData.hoursToComplete,
          );
        }

        const newTask = await createTaskOnApi(taskData);

        setTasks([...tasks, newTask]);
      }
    } catch (error) {
      console.error("error creating task", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to create that task. ${errMessage}`,
      );
    }
  };

  const handleDelete = async (items, callback) => {
    openPromptPopup({
      header: "Delete selected tasks",
      body: `Are you sure you want to delete the ${items.length} selected task${
        items.length === 1 ? "" : "s"
      }?`,
      confirmCallback: async () => {
        try {
          // delete tasks on API
          await deleteTasksOnApi({ tasks: items });

          // remove tasks from state
          removeTasks(items);

          openAlertPopup("Tasks deleted successfully!", null, true);

          closePromptPopup();

          if (callback) {
            callback();
          }
        } catch (err) {
          openAlertPopup("Delete tasks failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  // store all the <Editable> props here, since they are used both in the table and in the TaskModal
  // - task: task object
  // - property: string. The property that this Editable is editing
  // - extra: object. Any extra props that need to be provided for the Editable
  const taskEditable = (task, property, extra) => {
    let editableProps = {};

    switch (property) {
      case "title":
        editableProps = {
          onSave: isImportant
            ? (value) => handleDetailsEdit(task._id, { title: value })
            : undefined,
        };

        break;

      case "phase":
        const { taskPhase } = extra;

        editableProps = {
          options: phases?.length
            ? phases.map((phase) => ({
                label: phase.name,
                value: phase._id,
              }))
            : [],
          defaultValue: taskPhase
            ? {
                label: taskPhase.name,
                value: taskPhase._id,
              }
            : null,
          dropdownProps: { isClearable: true },
          onSave: isImportant
            ? (value) => {
                handleDetailsEdit(task._id, { phase: value });
              }
            : undefined,
        };

        break;

      case "deliverable":
        const { taskDeliverable } = extra;

        editableProps = {
          onSave: isImportant
            ? (value) =>
                handleDetailsEdit(task._id, {
                  deliverableId: value,
                })
            : undefined,
          dropdownProps: { isClearable: true },
          options: availDeliverables?.length
            ? availDeliverables.map((deliverable) => ({
                label: deliverable.name,
                value: deliverable._id,
              }))
            : [],
          defaultValue: taskDeliverable
            ? {
                label: taskDeliverable.name,
                value: taskDeliverable._id,
              }
            : null,
        };

        break;

      case "step":
        editableProps = {
          onSave: isImportant
            ? (value) =>
                handleDetailsEdit(task._id, {
                  step: parseInt(value),
                })
            : undefined,
          inputProps: { type: "number" },
        };

        break;

      case "hours":
        editableProps = {
          onSave: isImportant
            ? (value) => {
                // must have a value for hours, and must be positive number
                if (value && value > 0) {
                  // new value for hours
                  const newTaskData = {
                    hoursToComplete: value,
                  };

                  // if the task has a due date, adjust the due date according to the new hours
                  if (task.week) {
                    // `week` = start date
                    // `date` = end date (start date + hours)
                    newTaskData.date = addHours(
                      getStandardizedDate(task.week),
                      newTaskData.hoursToComplete,
                    );
                  }

                  handleDetailsEdit(task._id, newTaskData);
                }
              }
            : undefined,
        };

        break;

      case "role":
        editableProps = {
          onSave: isImportant
            ? (value) => handleDetailsEdit(task._id, { role: value })
            : undefined,
          options: roles?.length
            ? roles.map((role) => ({
                label: role.role,
                value: role._id,
              }))
            : [],
          defaultValue: task.role
            ? {
                label: task.role.role,
                value: task.role._id,
              }
            : null,
        };

        break;

      case "owner":
        editableProps = {
          onSave: isImportant
            ? (value) => {
                const newMember = availMembers.find(
                  (member) => member._id === value,
                );

                handleDetailsEdit(task._id, {
                  memberId: newMember || null,
                });
              }
            : undefined,
          defaultValue: task.memberId
            ? {
                label: task.memberId.name,
                value: task.memberId._id,
              }
            : null,
          options: availMembers?.length
            ? availMembers
                .filter(
                  // only show the members that have the selected role
                  (member) => !task.role || member.role._id === task.role._id,
                )
                .map((member) => ({
                  label: member.name,
                  value: member._id,
                }))
            : [],
          dropdownProps: { isClearable: true },
        };

        break;

      case "weekNumber":
        editableProps = {
          onSave:
            !task.date && isImportant
              ? (newValues) => {
                  // if both values are provided, update the weekNumber
                  if (newValues.year && newValues.week) {
                    const newWeekNumber = `${newValues.year}w${newValues.week}`;

                    handleDetailsEdit(task._id, {
                      weekNumber: newWeekNumber,
                    });
                  }
                  // if neither are provided, clear the weekNumber
                  else if (!newValues.year && !newValues.week) {
                    handleDetailsEdit(task._id, {
                      weekNumber: null,
                    });
                  }
                  // if only one or the other was provided, do nothing
                }
              : undefined,
          edit: (editRef) => (
            <>
              <WeekNumberFields>
                <Input
                  ref={(el) => (editRef["year"] = el)}
                  type="number"
                  placeholder="Year"
                  defaultValue={
                    task.weekNumber ? task.weekNumber.split("w")[0] : ""
                  }
                />
                w
                <Input
                  ref={(el) => (editRef["week"] = el)}
                  type="number"
                  placeholder="Week"
                  defaultValue={
                    task.weekNumber ? task.weekNumber.split("w")[1] : ""
                  }
                />
              </WeekNumberFields>
            </>
          ),
        };

        break;

      case "due":
        editableProps = {
          onSave: isImportant
            ? (value) => {
                const newDate = value ? getStandardizedDate(value) : null;

                const taskHours = task.hoursToComplete || 0;

                // week: start date
                // date: due date (add the hours onto the date)
                const newTaskData = {
                  week: newDate,
                  date: newDate ? addHours(newDate, taskHours) : null,
                };

                if (newDate) {
                  // set the weekNumber string appropriately based on the date
                  const newWeekNumber = newDate
                    ? `${newDate.getFullYear()}w${getWeek(newDate)}`
                    : null;
                  newTaskData.weekNumber = newWeekNumber;
                }

                handleDetailsEdit(task._id, newTaskData);
              }
            : undefined,
          defaultValue: task.date
            ? format(getStandardizedDate(task.date), "yyyy-MM-dd")
            : "",
          inputProps: { type: "date" },
        };

        break;

      case "status":
        editableProps = {
          onSave: isImportant
            ? (value) => handleDetailsEdit(task._id, { status: value })
            : undefined,

          options: taskStatuses.map((status) => ({
            label: status,
            value: status,
          })),
        };

        break;
    }

    return editableProps;
  };

  return (
    <TasksTable
      recurring={recurring}
      project={project}
      projectId={projectId}
      roles={roles}
      allMembers={allMembers}
      tasks={tasks}
      phases={phases}
      availDeliverables={availDeliverables}
      availMembers={availMembers}
      userId={user ? user._id : null}
      isImportant={isImportant}
      isSuper={isSuper}
      createTask={createTask}
      toggleTaskCompleted={toggleTaskCompleted}
      taskEditable={taskEditable}
      onDelete={handleDelete}
      getTaskStepDetails={getTaskStepDetails}
      setSelectedTask={setSelectedTask}
      highlightedTask={highlightedTask}
    />
  );
};

TasksList.propTypes = {
  phases: PropTypes.array,
};
TasksList.defaultProps = {
  phases: [],
};

export default TasksList;
