import React from "react";
import styled from "styled-components";
import CloseButton from "../buttons/CloseButton";

const SelectedFilters = ({ filters, updateFilters, shouldWrap, ...rest }) => {
  /*
  |--------------------------------------------------------------------------
  | Remove a selected filter
  |--------------------------------------------------------------------------
  */
  const removeFilter = (index) => {
    const tempFilters = [...filters];
    tempFilters.splice(index, 1);

    updateFilters(tempFilters);
  };

  return (
    <Container shouldWrap={shouldWrap} {...rest}>
      {filters
        ? filters.map((filter, index) => {
            return filter.label && filter.value ? (
              <SelectedFilter key={index}>
                <SelectedFilterGroup>
                  <SelectedFilterLabel>{filter.label}</SelectedFilterLabel>
                  <SelectedFilterValue>{filter.value}</SelectedFilterValue>
                </SelectedFilterGroup>
                <SelectedFilterDelete onClick={() => removeFilter(index)} />
              </SelectedFilter>
            ) : null;
          })
        : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: ${(props) => (props.shouldWrap ? "wrap" : undefined)};
`;

const SelectedFilter = styled.div`
  border-radius: 4px;
  background-color: #e0e8ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
`;

const SelectedFilterGroup = styled.div`
  margin-right: 10px;
`;

const SelectedFilterLabel = styled.span`
  color: #828df8;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
`;

const SelectedFilterValue = styled.span`
  color: #5048e5;
  font-weight: 500;
  font-size: 14px;
`;

const SelectedFilterDelete = styled(CloseButton)`
  position: static;
  height: 14px;

  * {
    opacity: 1 !important;
  }

  path {
    fill: #5048e5;
  }
`;

export default SelectedFilters;
