import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { startCase, toLower } from "lodash";
import {
  createClientOnApi,
  getCompaniesFromApi,
  archiveClientsOnApi,
} from "../../utils/api";
import { updateClientOnApi } from "../manage/utils/api";
import { checkAndAppendHttps } from "../../utils/helpers";
import { useNotifications } from "../../context/notificationsContext";

import useBulkSelect from "../../hooks/useBulkSelect";

import Filters, { FilterGroup } from "../../components/NewTable/Filters";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import { TableCell, Checkbox } from "../../components/NewTable/components";
import Table from "../../components/NewTable/index";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../utils/sorting";

import { Input } from "../../components/newForm";
import Tabs, { TabContent } from "../../components/Tabs";
import PageLayout from "./components/pageLayout";
import CreateCompany from "./components/createCompany";
import UpdateCompany from "./components/updateCompany";
import CheckMenu from "./components/CheckMenu";
import ProfileImage from "../../components/ProfileImage";
import LinkIcon from "./components/LinkIcon";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  owner: "accountOwner.name",
};

const filters = ["color", "status", "owner"];

const tabs = [
  // {
  //   name: "Overview",
  //   id: "overview",
  // },
  // {
  //   name: "Tasks",
  //   id: "tasks",
  //   number: 8,
  // },
  {
    name: "Table",
    id: "table",
  },
  // {
  //   name: "Kanban",
  //   id: "kanban",
  // },
  // {
  //   name: "Reports",
  //   id: "reports",
  // },
];

const Companies = () => {
  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const [originalClients, setOriginalClients] = useState([]);
  const [clients, setClients] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [currentCompany, setCurrentCompany] = useState(null);
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    clearSelectedItems,
  } = useBulkSelect(clients);

  useEffect(() => {
    getCompanies();
  }, [searchText, selectedFilters]);

  const getCompanies = async (shouldRefresh = false) => {
    setLoading(true);

    let tempClients;

    if (originalClients && originalClients.length && !shouldRefresh) {
      tempClients = [...originalClients];
    } else {
      tempClients = await getCompaniesFromApi({ contacts: true });
    }

    setOriginalClients(tempClients);

    // If a filter was selected or a sort is active, filter the entries
    if ((selectedFilters && selectedFilters.length) || searchText) {
      filterCompanies(selectedFilters);
    } else {
      setClients(tempClients);
    }

    setLoading(false);
  };

  /*
  |--------------------------------------------------------------------------
  | Filter the companies
  |--------------------------------------------------------------------------
  */
  const filterCompanies = (tempFilters) => {
    let tempClients = [...originalClients];

    // Filters the clients
    if (tempFilters && tempFilters.length) {
      tempClients = filterItems(
        tempFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempClients,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempClients = searchItems(searchText, tempClients, [
        "contacts.firstName",
        "contacts.lastName",
        "status",
        "name",
        "info.website",
        "info.phone",
        "info.address1",
        "info.address2",
        "info.city",
        "info.state",
        "info.zipcode",
      ]);
    }

    setClients(tempClients);
  };

  /*
  |--------------------------------------------------------------------------
  | Create a new company
  |--------------------------------------------------------------------------
  */
  const createNewCompany = async (data) => {
    try {
      await createClientOnApi(data);

      setCreateOpen(false);
      openAlertPopup("Company created successfully!", null, true);
      getCompanies(true);
    } catch (err) {
      const errorMessage = typeof err === "string" ? err : "";

      openAlertPopup(
        "Create company failed",
        `Please check the entered data and try again. ${errorMessage}`,
      );

      throw new Error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update an existing company
  |--------------------------------------------------------------------------
  */
  const updateCompany = async (data) => {
    try {
      let updatedClient = await updateClientOnApi(data);
      updatedClient = updatedClient.result.database;

      const tempClients = [...clients];
      const tempClientsIndex = tempClients.findIndex(
        (client) => client._id === data.clientId,
      );
      tempClients[tempClientsIndex] = updatedClient;

      setClients(tempClients);

      const tempOriginalClients = [...originalClients];
      const tempOriginalClientsIndex = tempClients.findIndex(
        (client) => client._id === data.clientId,
      );
      tempOriginalClients[tempOriginalClientsIndex] = updatedClient;
      setOriginalClients(tempOriginalClients);

      setUpdateOpen(false);
      openAlertPopup("Client updated successfully!", null, true);
    } catch (err) {
      openAlertPopup(
        "Update client failed",
        "Please check the entered data and try again",
      );
      throw new Error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete companies
  |--------------------------------------------------------------------------
  */
  const handleDelete = async () => {
    openPromptPopup({
      header: "Delete selected companies",
      body: `Are you sure you want to delete the ${
        selectedItems.length
      } selected compan${selectedItems.length === 1 ? "y" : "ies"}?`,
      confirmCallback: async () => {
        try {
          await archiveClientsOnApi({ clients: selectedItems });
          openAlertPopup("Companies deleted successfully!", null, true);
          clearSelectedItems();
          getCompanies(true);
          closePromptPopup();
        } catch (err) {
          openAlertPopup("Delete companies failed", "Please try again");
          closePromptPopup();
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const handleEdit = () => {
    const newCompanyId = selectedItems[0];
    const newCurrentCompany = clients.find(
      (company) => company._id === newCompanyId,
    );
    setCurrentCompany(newCurrentCompany);
    setUpdateOpen(true);
  };

  return (
    <PageLayout
      title="Companies"
      createButton="Create Company"
      number={clients ? clients.length : 0}
      handleCreate={() => setCreateOpen(true)}
    >
      <CreateCompany
        isOpen={createOpen}
        close={() => setCreateOpen(false)}
        handleCreate={createNewCompany}
      />
      <UpdateCompany
        isOpen={updateOpen}
        close={() => setUpdateOpen(false)}
        handleUpdate={updateCompany}
        currentCompany={currentCompany}
      />

      <TabContentSection>
        <TabContent isactive={true}>
          {clients ? (
            <>
              <StyledFilterGroup>
                <CheckMenu
                  disabled={selectedItems.length > 0 ? false : true}
                  numItems={selectedItems.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />

                <Filters
                  selectedFilters={selectedFilters}
                  updateFilters={setSelectedFilters}
                  filters={filters}
                  getUniqueOptions={(filter) =>
                    getUniqueOptions(filter, originalClients, {
                      exclude: selectedFilters,
                      propPaths: filterPaths,
                    })
                  }
                />

                <SearchInput
                  placeholder="Search items..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </StyledFilterGroup>

              {selectedFilters?.length ? (
                <SelectedFilters
                  filters={selectedFilters}
                  updateFilters={setSelectedFilters}
                />
              ) : null}

              <TableContainer>
                <Table
                  // so the scrolling is handled by the whole viewport, rather than just the table
                  scrollable={false}
                  noPadding
                  headers={[
                    {
                      component: (groupedEntries = clients) => (
                        <Checkbox
                          isChecked={
                            groupedEntries?.length &&
                            // ? in case the items are grouped, reference the adjacent grouped items, rather than the entire array
                            groupedEntries.every((item) =>
                              selectedItems.includes(item._id),
                            )
                          }
                          onClick={() => handleSelectAll(groupedEntries)}
                        />
                      ),
                    },
                    {
                      name: "Color",
                      accessor: "color",
                    },
                    {
                      name: "Status",
                      accessor: "status",
                    },
                    {
                      name: "Company",
                      accessor: "name",
                    },
                    {
                      name: "Code",
                      accessor: "acronym",
                    },
                    {
                      name: "Owner",
                      accessor: "accountOwner.name",
                    },
                    {
                      name: "Website",
                      accessor: "info.website",
                    },
                    {
                      name: "Phone",
                      accessor: "info.phone",
                    },
                    {
                      name: "Mailing Address",
                      accessor: "info.address1",
                    },
                    {
                      name: "Contacts",
                      accessor: "contacts[0].firstName",
                    },
                  ]}
                  entries={
                    clients
                      ? clients.map((client) => {
                          return {
                            ...client,
                            row: (sortedEntries) => (
                              <>
                                <TableCell>
                                  <Checkbox
                                    isChecked={selectedItems.includes(
                                      client._id,
                                    )}
                                    onClick={(e) => {
                                      // ? include the sorted entries from the table so if the user sorted or grouped the items, the correct ones will be selected if they shift+click to multi-select
                                      handleSelect(
                                        client._id,
                                        e.shiftKey,
                                        sortedEntries,
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell full>
                                  <Color
                                    color={client.color ? client.color : null}
                                  />
                                </TableCell>
                                <TableCell full>
                                  {client.status
                                    ? startCase(toLower(client.status))
                                    : "N/A"}
                                </TableCell>
                                <TableCell full>
                                  <Link to={`/sales/companies/${client._id}`}>
                                    {client.name}
                                  </Link>
                                </TableCell>
                                <TableCell full>
                                  {client.acronym || "N/A"}
                                </TableCell>
                                <TableCell full>
                                  {client?.accountOwner ? (
                                    <ProfileImage
                                      name={client.accountOwner.name}
                                      handle={client.accountOwner.handle}
                                      small
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>
                                <TableCell full>
                                  {client.info?.website ? (
                                    <ExLink
                                      href={checkAndAppendHttps(
                                        client.info.website,
                                      )}
                                      target="_blank"
                                    >
                                      {client.info.website} <LinkIcon />
                                    </ExLink>
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>
                                <TableCell full>
                                  {client.info?.phone
                                    ? client.info?.phone
                                    : "N/A"}
                                </TableCell>
                                <TableCell full>
                                  {client.info?.address1 ? (
                                    <>
                                      {client.info.address1}
                                      <br />
                                      {client.info.address2 ? (
                                        <>
                                          {client.info.address2}
                                          <br />
                                        </>
                                      ) : null}
                                      {client.info.city}, {client.info.state}{" "}
                                      {client.info.zipcode}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>
                                <TableCell full>
                                  {client.contacts && client.contacts.length
                                    ? client.contacts.map((contact, index) => (
                                        <React.Fragment key={index}>
                                          {contact.firstName} {contact.lastName}
                                          ,
                                          <br />
                                        </React.Fragment>
                                      ))
                                    : "N/A"}
                                </TableCell>
                              </>
                            ),
                          };
                        })
                      : []
                  }
                />
              </TableContainer>
            </>
          ) : null}
        </TabContent>
      </TabContentSection>
    </PageLayout>
  );
};

const Header = styled.div`
  padding: 0 40px;
`;

const TabContentSection = styled.div`
  width: 100%;
`;

const Color = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  background-color: ${(props) => (props.color ? props.color : "#F9FAFB")};
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  z-index: 6;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const SearchInput = styled(Input)`
  margin-left: auto;
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

const ExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

export default Companies;
