import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const LineGraph = ({
  yLabel,
  entryLabel,
  data,
  lineColor,
  useDollars,
  annotations = {},
}) => {
  const [series, setSeries] = useState([]);

  // Graph options
  const options = {
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: yLabel,
      },
      labels: {
        formatter: function(value) {
          return useDollars && value > 0
            ? `$${value.toLocaleString()}`
            : useDollars && value < 0
            ? `-$${(value * -1).toLocaleString()}`
            : value.toLocaleString();
        },
      },
    },
    colors: [lineColor],
    stroke: {
      width: 2,
    },
    annotations,
  };

  useEffect(() => {
    const chartSeries = [
      {
        name: entryLabel,
        data,
      },
    ];

    setSeries(chartSeries);
  }, [data]);

  return <Chart options={options} series={series} type="line" />;
};

export default LineGraph;
