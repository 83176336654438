import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";
import {
  format,
  addDays,
  addMonths,
  addYears,
  differenceInDays,
} from "date-fns";
import { startCase } from "lodash";

import { useNotifications } from "../../../../context/notificationsContext";
import SimpleLink from "../../../../components/links/SimpleLink";

import { Field, Label, Input, MoneyInput } from "../../../../components/Form";
import Button from "../../../../components/buttons/Button";
import SelectDropdown from "../../../../components/SelectDropdown";
import Toggle, { ToggleContainer } from "../../../../components/Toggle";

import {
  getMembersFromApi,
  getContractFromApi,
  updateContractOnApi,
} from "../../../../utils/api";
import {
  capitalize,
  isValidDate,
  getStandardizedDate,
} from "../../../../utils/helpers";
import { contractStages } from "../../../../utils/constants";

import Rates from "./rates";

const termTypeOptions = ["days", "months", "years"];

const ContractModify = () => {
  const history = useHistory();
  const {
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();
  const { id, contractId } = useParams();

  const [name, setName] = useState("");
  const [sow, setSow] = useState("");
  const [startDate, setStartDate] = useState("");
  const [budget, setBudget] = useState(0);
  const [owner, setOwner] = useState(null);
  const [status, setStatus] = useState(contractStages[0]);
  const [doesRenew, setDoesRenew] = useState(false);
  const [rates, setRates] = useState([]);

  // default term = 60 days
  const [termType, setTermType] = useState(termTypeOptions[0]);
  const [termAmount, setTermAmount] = useState(60);
  const [endDate, setEndDate] = useState("");
  const [hasExactEndDate, setHasExactEndDate] = useState(false);

  const [members, setMembers] = useState([]);
  const [isExisting, setIsExisting] = useState(false);

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    // if user is creating a new contract, the :id in the url will be "new", or something along those lines
    // if they're editing an existing one, the :id will be a long string (the object ID of the contract)
    const alreadyExists = contractId.length > 7;

    setIsExisting(alreadyExists);

    // ? not allowing creating new contracts in this view, so just get the existing contract
    // // if it exists, fetch the contract's data
    // if (alreadyExists) {
    //   getContract();
    // } else {
    //   getDefaultRates();
    // }

    getContract();
  }, [contractId]);

  useEffect(() => {
    // if user toggled to manually set the end date,
    // and there is no end date set yet
    if (hasExactEndDate && !endDate) {
      // calculate the end date from the terms
      const newEndDate = calculateEndDate();

      if (newEndDate) {
        setEndDate(newEndDate);
      }
    }
  }, [hasExactEndDate]);

  /*
  |--------------------------------------------------------------------------
  | Get the contract
  |--------------------------------------------------------------------------
  */
  const getContract = async () => {
    try {
      const contractFromApi = await getContractFromApi(contractId);

      if (contractFromApi) {
        setName(contractFromApi.name);
        setSow(contractFromApi.sow || "");
        setStartDate(contractFromApi.startDate);
        setBudget(contractFromApi.budget);
        setOwner({
          label: contractFromApi.owner.name,
          value: contractFromApi.owner._id,
        });
        setStatus(contractFromApi.status);
        setDoesRenew(contractFromApi.doesRenew);
        setRates(contractFromApi.rates ? contractFromApi.rates : []);

        if (contractFromApi.termDays) {
          setTermAmount(contractFromApi.termDays);
          setTermType("days");
        }
      } else {
        openAlertPopup(
          "Failure",
          "Sorry, we couldn't find the contract you're looking for.",
        );
      }
    } catch (error) {
      console.error("error getting contract", error);

      const errMessage = typeof error === "string" ? error : "";

      setTimeout(() => {
        openAlertPopup(
          "Failure",
          `Something went wrong and we couldn't get that contract. ${errMessage}`,
        );
      }, 1000);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get the list of members from the api
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();
      setMembers(membersFromApi);
    } catch (error) {
      console.error("error getting members", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Calculate end date
  |--------------------------------------------------------------------------
  */
  const calculateEndDate = () => {
    try {
      // must have valid start date and term amount
      if (!startDate || !isValidDate(new Date(startDate)) || termAmount <= 0) {
        throw "invalid startDate or termAmount";
      }

      // use appropriate date addition function based on the type of term
      const dateFunc =
        termType === "days"
          ? addDays
          : termType === "months"
          ? addMonths
          : termType === "years"
          ? addYears
          : null;

      if (!dateFunc) {
        throw "invalid termType";
      }

      // determine the end date by adding the proper amount of time onto the start date
      const newEndDate = dateFunc(getStandardizedDate(startDate), termAmount);

      return getStandardizedDate(newEndDate);
    } catch (error) {
      // return null if anything was invalid
      return null;
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Calculate term days
  |--------------------------------------------------------------------------
  */
  const calculateTermDays = () => {
    try {
      // must have valid start date
      if (!startDate || !isValidDate(new Date(startDate))) {
        throw "invalid startDate";
      }

      // must have a valid end date or a valid term
      const contractEnd =
        hasExactEndDate && endDate && isValidDate(new Date(endDate))
          ? endDate
          : calculateEndDate();

      if (!contractEnd) {
        throw "invalid term or endDate";
      }

      // amount of days between start and end dates
      const daysDiff = differenceInDays(
        getStandardizedDate(contractEnd),
        getStandardizedDate(startDate),
      );

      return daysDiff;
    } catch (error) {
      // return null if anything was invalid
      return null;
    }
  };

  // ? since contracts now start as deals, default rates are applied on the backend when the deal is created
  // const getDefaultRates = async () => {
  //   try {
  //     // get most recently created contracts for this client
  //     const mostRecentContracts = await getContractsFromApi({
  //       company: id,
  //       latest: 1, // only get 1, the most recent
  //     });

  //     // if the client has a previous contract
  //     if (mostRecentContracts.length) {
  //       // set the rates to those of the most recent contract
  //       setRates(mostRecentContracts[0].rates);
  //     } else {
  //       // get default rates from settings
  //       const settingsFromApi = await getSettings();

  //       if (settingsFromApi.rates) {
  //         setRates(settingsFromApi.rates);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("error getting default contract rates", error);
  //   }
  // };

  /*
  |--------------------------------------------------------------------------
  | Saves the contract
  |--------------------------------------------------------------------------
  */
  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const data = {
        name,
        sow,
        startDate,
        budget,
        owner: owner?.value || null,
        doesRenew,
        status,
        rates,
      };

      // apply termDays to the data, if valid
      const termDays = calculateTermDays();
      if (termDays) {
        data.termDays = termDays;
      }

      if (isExisting) {
        // edit contract
        await updateContractOnApi(contractId, data);
      } else {
        // ? contracts start out as Deals in the Sales section. So the user can't create contracts from here anymore
        // // create contract
        // data.company = id;
        // await createContractOnApi(data);

        throw new Error(
          "Please use the Sales section to create a new deal and move it into the contract status.",
        );
      }

      openAlertPopup(
        "Success",
        `${name} has been ${isExisting ? "updated" : "created"}.`,
        true,
      );

      // if a new one was just created, go back to the single client view
      if (!isExisting) {
        history.push(`/manage/clients/${id}`);
      }
    } catch (error) {
      console.error("error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't ${
          isExisting ? "edit" : "create"
        } the contract. ${errMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Create a new rate on the API
  |--------------------------------------------------------------------------
  */
  const createRate = async (rate) => {
    try {
      if (rate.team && rate.tier && rate.type && rate.amount) {
        const existingRates = [...rates];

        const foundRate = existingRates.find(
          (item) =>
            item.team === rate.team &&
            item.tier === rate.tier &&
            item.type === rate.type,
        );

        // Throw an error if an existing rate combination already exists
        if (foundRate) {
          openAlertPopup("Error", `Existing rate already exists`);
          return;
        }

        // If there is an existing id, remove it
        if (rate._id) {
          delete rate._id;
        }

        const updatedContract = await updateContractOnApi(contractId, {
          rates: [...rates, rate],
        });

        setRates(updatedContract.rates);
      }
    } catch (error) {
      console.error("error creating rate", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to create that rate. ${errMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Edit a new rate on the API
  |--------------------------------------------------------------------------
  */
  const editRate = async (rateId, data) => {
    try {
      let tempRates = [...rates];

      tempRates = tempRates.map((rate) => {
        if (rate._id === rateId) {
          rate = { ...data };
          console.log("Rate", rate);
        }

        return rate;
      });

      const updatedContract = await updateContractOnApi(contractId, {
        rates: tempRates,
      });

      setRates(updatedContract.rates);
    } catch (error) {
      console.error("error editing rate", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to edit that rate. ${errMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles deletion of a rate
  |--------------------------------------------------------------------------
  */
  const deleteRate = async (rateId, callback) => {
    openPromptPopup({
      header: "Delete selected rate",
      body: `Are you sure you want to delete the  selected rate?`,
      confirmCallback: async () => {
        try {
          let newRates = [...rates];

          newRates = newRates.filter((rate) => {
            return rate._id !== rateId;
          });

          await updateContractOnApi(contractId, {
            rates: newRates,
          });

          setRates(newRates);

          openAlertPopup("Rate deleted successfully!", null, true);

          closePromptPopup();

          if (callback) {
            callback();
          }
        } catch (err) {
          openAlertPopup("Delete rate failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  return (
    <Container>
      <Header>
        <Heading>{isExisting ? "Edit" : "New"} Contract</Heading>

        <SimpleLink as={Link} to={`/manage/clients/${id}`}>
          Back to Contracts
        </SimpleLink>
      </Header>

      <EditSection onSubmit={handleSave}>
        <Wrapper>
          <LeftSection>
            <SectionTitle>Contract Details</SectionTitle>
            <Field>
              <Label htmlFor="contract-name">Contract Name</Label>
              <Input
                id="contract-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required
              />
            </Field>

            <Field>
              <Label htmlFor="contract-sow">SOW Number</Label>
              <Input
                id="contract-sow"
                value={sow}
                onChange={(e) => setSow(e.target.value)}
                autoComplete="off"
                required
              />
            </Field>

            <Field>
              <Label htmlFor="contract-status">Status</Label>
              <SelectDropdown
                id="contract-status"
                onChange={(e) => setStatus(e.value)}
                placeholder="Status"
                options={contractStages.map((status) => ({
                  label: startCase(status),
                  value: status,
                }))}
                value={
                  status
                    ? {
                        label: startCase(status),
                        value: status,
                      }
                    : null
                }
                required
              />
            </Field>

            <Field>
              <Label htmlFor="contract-owner">Owner</Label>
              <SelectDropdown
                id="contract-owner"
                onChange={(e) => setOwner(e)}
                placeholder="Select Owner"
                options={members.map((member) => ({
                  label: member.name,
                  value: member._id,
                }))}
                value={owner || null}
              />
            </Field>

            <Field>
              <Label htmlFor="contract-budget">Total Budget</Label>
              <MoneyInput
                id="contract-budget"
                placeholder="Budget"
                value={budget}
                onChange={(e) => setBudget(e.target.rawValue.replace("$", ""))}
                autoComplete="off"
              />
            </Field>

            <Field>
              <Label htmlFor="contract-start">Effective Date</Label>
              <Input
                id="contract-start"
                type="date"
                value={
                  startDate && isValidDate(new Date(startDate))
                    ? format(getStandardizedDate(startDate), "yyyy-MM-dd")
                    : undefined
                }
                onChange={(e) =>
                  setStartDate(
                    isValidDate(new Date(e.target.value))
                      ? getStandardizedDate(e.target.value)
                      : null,
                  )
                }
              />
            </Field>

            {!doesRenew ? (
              <Field>
                <TermLabel>
                  <Label>Term</Label>

                  {/* switch between editing the term length or just selecting an exact end date */}
                  <div>
                    <button
                      style={{
                        textDecoration: hasExactEndDate ? "none" : "underline",
                      }}
                      type="button"
                      onClick={() => setHasExactEndDate(false)}
                    >
                      Length
                    </button>{" "}
                    |{" "}
                    <button
                      style={{
                        textDecoration: hasExactEndDate ? "underline" : "none",
                      }}
                      type="button"
                      onClick={() => setHasExactEndDate(true)}
                    >
                      Date
                    </button>
                  </div>
                </TermLabel>

                {!hasExactEndDate ? (
                  <>
                    <Flex>
                      <Input
                        type="number"
                        placeholder={`# of ${termType}`}
                        value={termAmount}
                        onChange={(e) => setTermAmount(e.target.value)}
                      />

                      <SelectDropdown
                        onChange={(e) => setTermType(e.value)}
                        options={termTypeOptions.map((option) => ({
                          label: capitalize(option),
                          value: option,
                        }))}
                        value={
                          termType
                            ? {
                                label: capitalize(termType),
                                value: termType,
                              }
                            : null
                        }
                      />
                    </Flex>

                    {/* // ? just for display purposes. endDate is kept separate from this so it can be edited independently from the terms */}
                    {calculateEndDate() ? (
                      <Input
                        type="date"
                        disabled
                        value={format(calculateEndDate(), "yyyy-MM-dd")}
                      />
                    ) : null}
                  </>
                ) : (
                  <Input
                    type="date"
                    disabled={!hasExactEndDate}
                    value={
                      endDate && isValidDate(new Date(endDate))
                        ? format(getStandardizedDate(endDate), "yyyy-MM-dd")
                        : undefined
                    }
                    onChange={(e) =>
                      setEndDate(
                        isValidDate(new Date(e.target.value))
                          ? getStandardizedDate(e.target.value)
                          : null,
                      )
                    }
                  />
                )}
              </Field>
            ) : null}

            <Field>
              <Label>Auto Renewal</Label>

              <ToggleContainer width="100%">
                <Toggle
                  label="Does this contract auto renew?"
                  name="autoRenew"
                  type="checkbox"
                  onChange={(e) => setDoesRenew(e.target.checked)}
                  checked={doesRenew}
                />
              </ToggleContainer>
            </Field>
          </LeftSection>

          <RightSection>
            <Rates
              rates={rates}
              createRate={createRate}
              editRate={editRate}
              deleteRate={deleteRate}
            />
          </RightSection>
        </Wrapper>

        <Actions>
          <Button type="submit">Save</Button>
        </Actions>
      </EditSection>
    </Container>
  );
};

const Container = styled.div`
  ${Input} {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 60px;
`;

const Heading = styled.h2`
  font-size: 24px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0px;

  margin-right: 30px;
`;

const EditSection = styled.form`
  max-width: 1100px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 400px;
  padding-right: 70px;
`;

const RightSection = styled.div`
  flex: 1;
`;

const Flex = styled.div`
  display: flex;

  > * {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0%;
  }

  ${Input} {
    width: auto;
  }
`;

const TermLabel = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
`;

const RateColumn = styled.div`
  width: 30%;
`;

const Actions = styled.div`
  text-align: right;
`;

const SectionTitle = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.colors.oldBlack};
  font-weight: 500;
  margin-bottom: 20px;
`;

export default ContractModify;
