import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { getAllRolesFromApi } from "../../utils/api";

import CreateLink from "../../components/links/CreateLink";
import SimpleLink from "../../components/links/SimpleLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../components/Table";
import ProfileImage from "../../components/ProfileImage";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [sortBy, setSortBy] = useState("newest");

  useEffect(() => {
    getRoles();
  }, []);

  async function getRoles() {
    const results = await getAllRolesFromApi({ showArchived: true });

    setRoles(results);
  }

  // Handles the actual sorting of the roles
  useEffect(() => {
    const sortedRoles = [...roles];

    if (sortBy === "name-asc") {
      sortedRoles.sort(function(a, b) {
        var textA = a.role.toUpperCase();
        var textB = b.role.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedRoles.sort(function(a, b) {
        var textA = a.role.toUpperCase();
        var textB = b.role.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setRoles(sortedRoles);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  // split the users into un-archived and archived
  const allRoles = [
    roles.filter((role) => !role.archived),
    roles.filter((role) => role.archived),
  ];

  return (
    <RolesContainer>
      <RolesHeader>
        <h1>Roles</h1>
        <CreateLink as={Link} to={`/manage/roles/new`}>
          Create Role
        </CreateLink>
      </RolesHeader>

      {allRoles.length ? (
        <RolesList>
          <thead>
            <tr>
              <th></th>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
              >
                Role Name
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {allRoles.map((rolesArray) =>
              rolesArray.map((role) => (
                <TableRow
                  key={role._id}
                  style={{ opacity: role.archived ? 0.3 : 1 }}
                >
                  <RolesCell>
                    <ProfileImage
                      type="role"
                      handle={role.role}
                      name={role.role}
                      small
                    />
                  </RolesCell>
                  <TableCell align="left">
                    <TableCellTitle style={{ display: "block" }}>
                      {role.role}{" "}
                      {role.archived ? <Archived>(archived)</Archived> : null}
                    </TableCellTitle>
                    <SimpleLink as={Link} to={`/manage/roles/${role._id}`}>
                      edit
                    </SimpleLink>
                  </TableCell>
                </TableRow>
              )),
            )}
          </tbody>
        </RolesList>
      ) : null}
    </RolesContainer>
  );
};

const RolesContainer = styled.div`
  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const RolesHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const RolesList = styled.table`
  padding: 50px;
  width: 100%;
  max-width: 1400px;
`;

const RolesCell = styled(TableCell)`
  &:first-child {
    width: 30px;
  }
`;

const Archived = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

export default Roles;
