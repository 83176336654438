import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const ProgressBar = ({
  actual,
  budgeted,
  pace,
  paceLabelTop,
  paceLabelBottom,
  secondPace,
  tall,
  ...rest
}) => {
  const calculateProgress = (actual, budgeted) => {
    if (budgeted === 0 && actual !== 0) {
      return "over";
    } else if (budgeted === 0 && actual === 0) {
      return 0;
    } else if (actual <= budgeted) {
      return (actual / budgeted) * 100;
    } else {
      return "over";
    }
  };

  // Figures out if we are on track, compared to the pace
  const calculateOnTrack = (actual, budgeted, pace) => {
    const currentProgress = calculateProgress(actual, budgeted);

    if (currentProgress === "over") {
      return "over";
    } else {
      const diff = currentProgress - pace;

      // If the progress is over pace, it's over
      if (diff >= 0) {
        return "over";

        //If progress is within 20%, it's close
      } else if (diff > -20) {
        return "close";
      } else {
        return "under";
      }
    }
  };

  return (
    <Container
      whereOnTrack={calculateOnTrack(actual, budgeted, pace)}
      tall={tall ? true : false}
      {...rest}
    >
      <Progress
        percentage={calculateProgress(actual, budgeted)}
        whereOnTrack={calculateOnTrack(actual, budgeted, pace)}
      />
      {pace && pace >= 0 ? (
        <PaceLine
          tall={tall ? true : false}
          percentage={pace}
          labelTop={paceLabelTop}
          labelBottom={paceLabelBottom}
        />
      ) : null}
      {secondPace && secondPace >= 0 ? (
        <PaceLine tall={tall ? true : false} percentage={secondPace} />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  height: ${(props) => (props.tall ? `27px` : `12px`)};
  margin-bottom: 22px;
  position: relative;
  border-radius: 2px;
`;

const Progress = styled.div`
  background-color: ${(props) =>
    props.whereOnTrack === "over"
      ? "#22C55E"
      : props.whereOnTrack === "close"
      ? "#FACC15"
      : "#EF4444"};
  height: 100%;
  width: ${(props) =>
    props.percentage === "over" ? "100" : props.percentage}%;
  border-radius: 2px;
`;

const PaceLine = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => props.percentage}%;
  transform: translateY(-50%);
  width: 1px;
  height: ${(props) => (props.tall ? `32px` : `18px`)};
  background-color: ${(props) => props.theme.colors.darkSlate};

  &::before,
  &::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    color: white;
    background-color: inherit;
    padding: 5px;
    border-radius: 5px;

    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;

    opacity: 0;
    transition: opacity 200ms;
  }
  &::before {
    ${(props) =>
      props.labelTop
        ? css`
            content: "${props.labelTop}";
          `
        : ``}

    bottom: 100%;
  }
  &::after {
    ${(props) =>
      props.labelBottom
        ? css`
            content: "${props.labelBottom}";
          `
        : ``}

    top: 100%;
  }

  ${Container}:hover & {
    &::before,
    &::after {
      opacity: 1;
    }
  }
`;

ProgressBar.propTypes = {
  actual: PropTypes.number,
  budgeted: PropTypes.number,
  pace: PropTypes.number,
};

ProgressBar.defaultProps = {
  actual: 0,
  budgeted: 0,
  pace: 0,
};

export default ProgressBar;
