import { useState, useEffect } from "react";

export default (items, options = {}) => {
  const { identifier = "_id" } = options;

  const [selectedItems, setSelectedItems] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);

  useEffect(() => {
    //  update the selected items to only include those that are still visible
    // ? that way any selected items that become hidden as a result of a filter or search query, for instance, are no longer selected
    const newSelectedItems = selectedItems.filter((itemID) =>
      items.some((item) => item[identifier] === itemID),
    );
    setSelectedItems(newSelectedItems);
  }, [items]);

  // ? adjacentItems: can pass in array of items in case they are sorted or grouped differently than they were originally
  const handleSelect = (id, isMulti, adjacentItems = items) => {
    let ids = [id];

    // if user shift-clicked and there is a previously selected item
    if (isMulti && lastSelected) {
      // get just the IDs of the items
      const itemIds = adjacentItems.map((item) => item[identifier]);

      // index of this selected item
      const selectedIndex = itemIds.indexOf(id);
      // index of previously selected item
      const lastIndex = itemIds.indexOf(lastSelected);

      const lowerIndex = lastIndex > selectedIndex ? selectedIndex : lastIndex;
      const upperIndex = lastIndex > selectedIndex ? lastIndex : selectedIndex;

      // only proceed with the multi-select if both indexes exist
      if (lowerIndex !== -1 && upperIndex !== -1) {
        // all the IDs between the last selected item and this selected item
        ids = itemIds.filter(
          (item, index) => index >= lowerIndex && index <= upperIndex,
        );
      }
    }

    const newSelectedItems = selectedItems.includes(id)
      ? // remove it if its present
        selectedItems.filter((item) => !ids.includes(item))
      : // add it if its not
        // ? prevent any duplicates
        [...new Set([...selectedItems, ...ids])];

    // remember that this item was selected
    setLastSelected(id);

    setSelectedItems(newSelectedItems);
  };

  // ? adjacentItems: can pass in array of items in case they are grouped. So selectAll can be applied just to a group of items, rather than the entire array
  const handleSelectAll = (adjacentItems = items) => {
    adjacentItems = Array.isArray(adjacentItems) ? adjacentItems : items;

    let newSelectedItems = [];

    // check if all the adjacent items are selected
    const isEverythingSelected = adjacentItems.every((item) =>
      selectedItems.includes(item[identifier]),
    );

    if (isEverythingSelected) {
      // all adjacent items selected, so we want to remove them
      // filter out any selected items that match the identifier of an adjacent item
      newSelectedItems = selectedItems.filter(
        (itemId) => !adjacentItems.some((item) => item[identifier] === itemId),
      );
    } else {
      // not all the adjacent items are selected yet
      // so we'll add them to the selected items, while preventing any duplicates
      newSelectedItems = [
        ...new Set([
          ...selectedItems,
          ...adjacentItems.map((item) => item[identifier]),
        ]),
      ];
    }

    setSelectedItems(newSelectedItems);
  };

  const clearSelectedItems = () => setSelectedItems([]);

  return { selectedItems, handleSelect, handleSelectAll, clearSelectedItems };
};
