import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CheckmarkIcon from "./icons/CheckmarkIcon";

import { buttonReset } from "../styles/styleHelpers";

const CheckCircle = ({ onClick, isDisabled, isChecked }) => {
  return (
    <Container
      as={onClick ? "button" : "div"}
      onClick={onClick}
      disabled={isDisabled}
      isChecked={isChecked}
    >
      <Check />
    </Container>
  );
};

const Check = styled(CheckmarkIcon)``;

const Container = styled.button`
  ${buttonReset()}
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isChecked ? props.theme.colors.green : props.theme.colors.oldSilver};

  opacity: ${(props) => (props.disabled ? 0.4 : 1)};

  transition: background-color 200ms;

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  ${Check} {
    opacity: ${(props) => (props.isChecked ? 1 : 0)};
    transition: opacity 200ms;
  }
`;

CheckCircle.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
};
CheckCircle.defaultProps = {
  onClick: undefined,
  isDisabled: false,
  isChecked: false,
};

export default CheckCircle;
