import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { rgba } from "polished";
import { formatDuration, intervalToDuration } from "date-fns";
import { cloneDeep } from "lodash";

import { respondTo } from "../../styles/styleHelpers";
import { useNotifications } from "../../context/notificationsContext";
import { useActiveTimer } from "../../context/activeTimerContext";

import ProfileImage from "../../components/ProfileImage";
import InboxIcon from "../../components/icons/InboxIcon";
import TrashIcon from "../../components/icons/TrashIcon";
import StarIcon from "../../components/icons/StarIcon";

const DashboardInbox = () => {
  const history = useHistory();
  const { startTimer, isTimerRunning } = useActiveTimer();
  const { notifications, updateNotification } = useNotifications();

  const [displayedNotes, setDisplayedNotes] = useState(null);
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [notesFilter, setNotesFilter] = useState(null);

  // number of unread notifications
  useEffect(() => {
    const unreadNotes = notifications.filter(getUnreadNotes);
    setUnreadNumber(unreadNotes.length);
  }, [notifications]);

  useEffect(() => {
    // use the proper filtering function depending on which filter was selected
    const filterFunc =
      notesFilter === "unread"
        ? getUnreadNotes
        : notesFilter === "starred"
        ? getStarredNotes
        : notesFilter === "archived"
        ? getArchivedNotes
        : getUnarchivedNotes;

    // filter the notes
    const filteredNotes = notifications.filter(filterFunc);

    setDisplayedNotes(filteredNotes);
  }, [notifications, notesFilter]);

  const getUnreadNotes = (note) => !note.hasRead && !note.isArchived;

  const getStarredNotes = (note) => note.isStarred && !note.isArchived;

  const getArchivedNotes = (note) => note.isArchived;

  const getUnarchivedNotes = (note) => !note.isArchived;

  const clickNotification = (noteId, link) => {
    // mark it as read
    updateNotification(noteId, { hasRead: true });

    if (link) {
      //Starts timer if a timer isn't already running
      if (!isTimerRunning) {
        // Makes sure the link is a project with id
        // ? This is wierd, because the notification doesn't give us a project id
        // ? So we have to pull it from the string to start the new timer
        const linkRegex = /\/projects\/([a-f\d]{24})/i;
        const match = link.match(linkRegex);
        // If this is a project, we grab the ID and start the timer
        if (match?.length === 2) {
          startTimer(match[1]);
        }
      }

      history.push(link);
    }
  };

  return (
    <Inbox>
      <InboxHeader>
        <HeaderLeft>
          <InboxIcon />
          <InboxTitle>My Inbox</InboxTitle>
        </HeaderLeft>

        <HeaderRight>
          <p>Unread</p>
          <Badge
            small={unreadNumber.toString().length >= 3}
            isVisible={unreadNumber}
          >
            {unreadNumber}
          </Badge>
        </HeaderRight>
      </InboxHeader>

      <Tabs>
        <Tab onClick={() => setNotesFilter(null)} isActive={!notesFilter}>
          All
        </Tab>
        <Tab
          onClick={() => setNotesFilter("unread")}
          isActive={notesFilter === "unread"}
        >
          Unread
        </Tab>
        <Tab
          onClick={() => setNotesFilter("starred")}
          isActive={notesFilter === "starred"}
        >
          Starred
        </Tab>
        <Tab
          onClick={() => setNotesFilter("archived")}
          isActive={notesFilter === "archived"}
        >
          Archive
        </Tab>
      </Tabs>

      <NoteList>
        {displayedNotes ? (
          <>
            {displayedNotes.length ? (
              cloneDeep(displayedNotes) // copy the array and reverse it so the newest ones come first
                .reverse()
                .map((notification, index) => {
                  const duration = intervalToDuration({
                    start: new Date(notification.createdAt),
                    end: new Date(),
                  });

                  // Clears out everything except the largest duration
                  // That way you don't get times that say "5 days 6 hours and 57 minutes ago"
                  if (duration?.years > 0) {
                    duration.months = 0;
                    duration.weeks = 0;
                    duration.days = 0;
                    duration.hours = 0;
                    duration.minutes = 0;
                  } else if (duration?.months > 0) {
                    duration.weeks = 0;
                    duration.days = 0;
                    duration.hours = 0;
                    duration.minutes = 0;
                  } else if (duration?.weeks > 0) {
                    duration.days = 0;
                    duration.hours = 0;
                    duration.minutes = 0;
                  } else if (duration?.days > 0) {
                    duration.hours = 0;
                    duration.minutes = 0;
                  } else if (duration?.hours > 0) {
                    duration.minutes = 0;
                  }

                  const formattedDuration = formatDuration(duration, {
                    format: [
                      "years",
                      "months",
                      "weeks",
                      "days",
                      "hours",
                      "minutes",
                    ],
                  });

                  return (
                    <NoteItem
                      key={notification._id || index}
                      isFaded={notification.hasRead && !notification.isStarred}
                      isStarred={notification.isStarred}
                    >
                      <NoteInner>
                        <NoteIcon>
                          <ProfileImage
                            handle={notification?.author?.handle || undefined}
                            name={notification?.author?.name || "Sherpa P"}
                            xsmall
                          />

                          <ReadContainer>
                            <ReadButton
                              hasRead={notification.hasRead}
                              isShown={!notification.hasRead}
                              title={
                                notification.hasRead
                                  ? "Mark as Unread"
                                  : "Mark as Read"
                              }
                              onClick={() =>
                                updateNotification(notification._id, {
                                  hasRead: !notification.hasRead,
                                })
                              }
                            />
                          </ReadContainer>
                        </NoteIcon>

                        <ItemContainer>
                          <ItemRow>
                            {/* since buttons can't be nested, use the author to trigger the click on the notification */}
                            <Author
                              onClick={() =>
                                clickNotification(
                                  notification._id,
                                  notification.link,
                                )
                              }
                            >
                              {notification?.author?.handle || "Sherpa"}
                            </Author>

                            <TypeOfNote>Sent you a message</TypeOfNote>
                          </ItemRow>

                          <Message>{notification.message}</Message>

                          <Time>
                            {formattedDuration
                              ? `${formattedDuration} ago`
                              : `Just now`}
                          </Time>
                        </ItemContainer>
                      </NoteInner>

                      <NoteFooter>
                        <StarButton
                          isActive={notification.isStarred}
                          onClick={() =>
                            updateNotification(notification._id, {
                              isStarred: !notification.isStarred,
                            })
                          }
                          title={notification.isStarred ? "Unstar" : "Star"}
                        >
                          <StarIcon />
                        </StarButton>

                        <TrashButton
                          onClick={() =>
                            updateNotification(notification._id, {
                              isArchived: !notification.isArchived,
                            })
                          }
                          title={
                            notification.isArchived ? "Unarchive" : "Archive"
                          }
                          hasDanger={!notification.isArchived}
                        >
                          {notesFilter === "archived" ? (
                            <UnarchiveIcon />
                          ) : (
                            <TrashIcon />
                          )}
                        </TrashButton>
                      </NoteFooter>
                    </NoteItem>
                  );
                })
            ) : (
              <NoNew>No {notesFilter || ""} notifications</NoNew>
            )}

            {/* <AllButton
                onClick={(e) => {
                  e.target.blur();
                }}
              >
                {true ? `See Less` : `See All`}
              </AllButton> */}
          </>
        ) : null}
      </NoteList>
    </Inbox>
  );
};

const Inbox = styled.div``;

const InboxHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  padding-top: 30px;

  > *:last-child {
    margin-left: auto;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;

  path {
    fill: #554668;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #b7bec6;
    font-weight: 500;
    margin-bottom: 0px;
    margin-right: 15px;
  }
`;

const Badge = styled.div`
  ${(props) =>
    props.isVisible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 1;
  font-weight: 700;
  user-select: none;
  color: ${(props) => props.theme.colors.pureWhite};
  background-color: ${(props) => props.theme.colors.red};
  font-size: ${(props) => (props.small ? "12px" : "16px")};
`;

const InboxTitle = styled.h2`
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 30px;
  font-size: 20px;
`;

const NoteList = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const NoteItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 500;
  color: ${(props) => props.theme.colors.oldBlack2};
  text-decoration: none;
  background-color: ${(props) =>
    props.isStarred
      ? rgba(props.theme.colors.lightYellow, 0.25)
      : "transparent"};

  padding-top: 30px;
  padding-left: 17px;
  padding-right: 28px;
  padding-bottom: 15px;

  opacity: ${(props) => (props.isFaded ? 0.7 : 1)};

  transition: color 0.2s, opacity 0.2s, background-color 0.2s;

  &:hover {
    background-color: ${(props) =>
      props.isStarred
        ? rgba(props.theme.colors.lightYellow, 0.33)
        : rgba(props.theme.colors.slate, 0.05)};
  }
`;

const NoteInner = styled.div`
  display: flex;
  align-items: flex-start;
`;

const NoteFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NoteIcon = styled.div`
  margin-right: 8px;
  margin-top: 3px;
`;

const ItemContainer = styled.div`
  width: 100%;
  flex: 1;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-bottom: 5px;
`;

const Author = styled.button`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 700;
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 0;

  /* give it a clickable area that will cover the entire notification. So clicking the notification will trigger a click here */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
`;

const TypeOfNote = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark2};
  opacity: 0.7;
  margin-bottom: 3px;
`;

const Message = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark2};
  margin-bottom: 5px;
`;

const Time = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dark2};
  margin-bottom: 0;
`;

const NoNew = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.slate};
  margin: auto;
  padding-top: 80px;
  opacity: 0.7;

  ${respondTo("xlarge")} {
    padding-top: 70px;
  }
`;

// const AllButton = styled(FancyLink)`
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: auto;
//   text-align: center;
//   font-weight: normal;
//   font-size: 12.9591px;
//   line-height: 16px;

//   margin-bottom: 25px;

//   ${respondTo("xlarge")} {
//     margin-bottom: 45px;
//   }

//   &::before,
//   &::after {
//     height: 1px;
//   }
// `;

// const CloseButton = styled.button`
//   path {
//     transition: fill 200ms;
//   }

//   &:hover,
//   &:focus-visible {
//     path {
//       fill: ${(props) => props.theme.colors.red};
//     }
//   }
// `;

const ReadContainer = styled.div`
  margin-top: 15px;
  text-align: center;
  line-height: 1;
`;

const NoteButton = styled.button`
  /* give it a higher z-index so clicking the button won't trigger a general click on the notification */
  position: relative;
  z-index: 4;

  opacity: ${(props) => (props.isShown ? 1 : 0)};
  visibility: ${(props) => (props.isShown ? "visible" : "hidden")};

  transition: all 200ms;

  ${NoteItem}:hover & {
    opacity: 1;
    visibility: visible;
  }

  ${NoteFooter} & {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ReadButton = styled(NoteButton)`
  width: 15px;
  height: 15px;

  background-color: ${(props) =>
    props.hasRead ? "transparent" : props.theme.colors.red};
  border-radius: 50%;
  border: 1px solid;
  border-color: ${(props) =>
    props.hasRead ? props.theme.colors.slate : props.theme.colors.red};

  &:hover,
  &:focus-visible {
    background-color: ${(props) =>
      props.hasRead ? props.theme.colors.red : "transparent"};
    border-color: ${(props) => props.theme.colors.red};
  }
`;

const TrashButton = styled(NoteButton)`
  path {
    transition: fill 200ms;
  }

  &:hover {
    path {
      fill: ${(props) =>
        props.hasDanger ? props.theme.colors.red : props.theme.colors.blue};
    }
  }
`;

const UnarchiveIcon = styled(InboxIcon)`
  width: 20px;
  height: auto;

  path {
    fill: ${(props) => props.theme.colors.darkSlate};
  }
`;

const StarButton = styled(NoteButton)`
  path {
    fill: ${(props) => (props.isActive ? props.theme.colors.blue : undefined)};
    transition: fill 200ms;
  }

  &:hover {
    path {
      fill: ${(props) =>
        props.isActive ? props.theme.colors.slate : props.theme.colors.blue};
    }
  }
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.button`
  position: relative;
  flex-grow: 1;
  min-width: 60px;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  padding: 10px;
  color: ${(props) =>
    props.isActive ? props.theme.colors.text : props.theme.colors.slate};

  /* underline */
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.blue};
    height: 2px;

    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transition: opacity 200ms;
  }

  &:hover,
  &:focus-visible {
    color: ${(props) => props.theme.colors.text};
  }
`;

export default DashboardInbox;
