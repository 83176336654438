import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { format, getYear } from "date-fns";
import { startCase } from "lodash";

import { useAuth } from "../../../context/authContext";
import { useFile } from "../../../context/fileContext";
import { useNotifications } from "../../../context/notificationsContext";
import { useSockets } from "../../../context/socketsContext";

import {
  getProjectDeliverablesFromApi,
  editDeliverableOnApi,
  deleteDeliverablesOnApi,
  createDeliverableOnApi,
  uploadFileToApi,
  uploadLinkFileToApi,
  updateFileOnApi,
  getMembersFromApi,
  getSettings,
} from "../../../utils/api";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../../utils/sorting";
import { getStandardizedDate, findItemProperty } from "../../../utils/helpers";

import useBulkSelect from "../../../hooks/useBulkSelect";

import ProfileImage from "../../../components/ProfileImage";
import ProfileImageGroup from "../../../components/ProfileImageGroup";
import Filters from "../../../components/NewTable/Filters";
import FilterSelect from "../../../components/NewTable/FilterSelect";
import SelectedFilters from "../../../components/NewTable/SelectedFilters";
import Table from "../../../components/NewTable/index";
import {
  TableCell,
  SearchInput,
  Checkbox,
  AddBtn,
} from "../../../components/NewTable/components";
import CheckMenu from "../../../components/NewTable/CheckMenu";
import DeliverableUpload from "./DeliverableUpload";
import Editable from "../../../components/Editable";
import { Input } from "../../../components/newForm";
import PopoutMenu from "./PopoutMenu";
import LinkIcon from "../../sales/components/LinkIcon";
import CreateIcon from "../../../components/icons/CreateIcon";
import CheckCircleFill from "../../../components/icons/CheckCircleFill";
import CheckCircleHollow from "../../../components/icons/CheckCircleHollow";

import {
  ViewContainer,
  TableContainer,
  FiltersContainer,
  SelectedFiltersContainer,
  FiltersLeft,
  FiltersRight,
  CreateSelect,
  CreateInput,
  CreateLabel,
  CreateValue,
} from "./ProjectTable";

// dropdown options
const deliverableTypeNames = [
  "PDF",
  "Image",
  "Figma File",
  "Google Doc",
  "Google Sheet",
  "Staging URL",
  "Notion Page",
];

const recipientOptions = ["Client", "Internal"];

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  owner: "owner.name",
  "file type": "typeName",
};

// filter chips
const filters = ["status", "owner", "file type", "recipient"];

// options for the group by dropdown
const groupByOptions = ["status", "owner", "file type", "recipient"];

// group by dropdown options
const groupBySelectOptions = [
  // first option is "None"
  { label: "None", value: null },
  // remaining options
  ...groupByOptions.map((option) => {
    // grab the proper path for this filter
    const value = filterPaths.hasOwnProperty(option)
      ? filterPaths[option]
      : option;

    return {
      label: startCase(option),
      value,
    };
  }),
];

const DeliverablesList = ({
  projectId,
  clientId,
  phases,
  onDeliverablesUpdate,
}) => {
  const location = useLocation();

  const { openFile, setCurrentDeliverables } = useFile();
  const { user, isImportant } = useAuth();
  const {
    openPromptPopup,
    openAlertPopup,
    closePromptPopup,
  } = useNotifications();
  const {
    startProjectDeliverableListeners,
    stopProjectDeliverableListeners,
  } = useSockets();

  const [deliverables, setDeliverables] = useState(null);
  const [displayDeliverables, setDisplayDeliverables] = useState([]);

  const [members, setMembers] = useState([]);
  const [availQATypes, setAvailQATypes] = useState([]);
  const [clientReviewOptions, setClientReviewOptions] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [groupBy, setGroupBy] = useState(null);

  const [uploadMessage, setUploadMessage] = useState(""); //eslint-disable-line

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    clearSelectedItems,
  } = useBulkSelect(displayDeliverables);

  useEffect(() => {
    getSettingsData();
    getMembers();
    getDeliverables();

    // check URL params for search query
    const urlParams = new URLSearchParams(location.search);
    const searchQuery = urlParams.get("search");
    const fileQuery = urlParams.get("file");
    if (fileQuery) {
      openFile(fileQuery);
    }
    if (searchQuery) {
      setSearchText(searchQuery);
    }
  }, []);

  useEffect(() => {
    if (deliverables) {
      const newDisplay = filterDelivs();
      setDisplayDeliverables(newDisplay);
    }
  }, [deliverables, searchText, selectedFilters]);

  useEffect(() => {
    if (deliverables) {
      setCurrentDeliverables(deliverables);

      startProjectDeliverableListeners(projectId, {
        deliverableCreateCallback: (newDeliverable) => {
          setDeliverables([...deliverables, newDeliverable]);
        },
        deliverableEditCallback: (updatedDeliverable) => {
          liveUpdateDeliverable(updatedDeliverable);
        },
        deliverableDeleteCallback: (deletedDeliverableId) => {
          removeDeliverables(deletedDeliverableId);
        },
      });

      return () => {
        stopProjectDeliverableListeners(projectId);
      };
    }
  }, [deliverables]);

  // when deliverables are updated
  useEffect(() => {
    if (deliverables && onDeliverablesUpdate) {
      // update main project view
      onDeliverablesUpdate(deliverables);
    }
  }, [deliverables]);

  /*
  |--------------------------------------------------------------------------
  | Live update deliverable
  |--------------------------------------------------------------------------
  */
  const liveUpdateDeliverable = (updatedDeliverable) => {
    const tempDeliverables = deliverables.map((deliverable) =>
      deliverable._id === updatedDeliverable._id
        ? { ...deliverable, ...updatedDeliverable }
        : deliverable,
    );

    setDeliverables(tempDeliverables);
  };

  const filterDelivs = () => {
    let tempDelivs = [...deliverables];

    // Filters the delivs
    if (selectedFilters && selectedFilters.length > 0) {
      tempDelivs = filterItems(
        // Formats filters
        selectedFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempDelivs,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      const searchDelivs = tempDelivs.map((deliv) => {
        const tempFormattedDeliv = { ...deliv };

        if (
          tempFormattedDeliv.shipBy?.length &&
          tempFormattedDeliv.shipBy[tempFormattedDeliv.shipBy.length - 1].date
        ) {
          tempFormattedDeliv.formattedDueDate = format(
            new Date(
              tempFormattedDeliv.shipBy[
                tempFormattedDeliv.shipBy.length - 1
              ].date,
            ),
            "MMMM do yyyy",
          );
        }

        return tempFormattedDeliv;
      });
      tempDelivs = searchItems(searchText, searchDelivs, [
        "name",
        "status",
        "owner.name",
        "approvers.name",
        "optionalApprovers.name",
        "formattedDueDate",
        "recipient",
        "typeName",
        // ? these caused issues with Fuse search:
        // "week",
        // "dueDate",
      ]);
    }

    // sorts the results by due date / completion status

    const withoutDueDate = tempDelivs.filter((deliv) => !deliv.shipBy);
    const withDueDate = tempDelivs.filter((deliv) => deliv.shipBy);

    // incomplete delivs, sorted with oldest due dates at the top
    const incompleteDelivs = withDueDate
      .filter((deliv) => deliv.status.toLowerCase() !== "approved")
      .sort(
        (a, b) =>
          new Date(a.shipBy?.length ? a.shipBy[a.shipBy.length - 1].date : 0) -
          new Date(b.shipBy?.length ? b.shipBy[b.shipBy.length - 1].date : 0),
      );

    // approved delivs, sorted with recent due dates at the top
    const approvedDelivs = withDueDate
      .filter((deliv) => deliv.status.toLowerCase() === "approved")
      .sort(
        (a, b) =>
          new Date(b.shipBy?.length ? b.shipBy[b.shipBy.length - 1].date : 0) -
          new Date(a.shipBy?.length ? a.shipBy[a.shipBy.length - 1].date : 0),
      );

    // sorted delivs:
    // - no due date
    // - incomplete (oldest due dates first)
    // - approved (recent due dates first)
    const sortedDelivs = [
      ...withoutDueDate,
      ...incompleteDelivs,
      ...approvedDelivs,
    ];

    return sortedDelivs;
  };

  /*
  |--------------------------------------------------------------------------
  | Get the company settings to grab the client review options
  |--------------------------------------------------------------------------
  */
  const getSettingsData = async () => {
    try {
      const settings = await getSettings();

      if (settings?.deliverableCheckListTypes) {
        setAvailQATypes(settings.deliverableCheckListTypes);
      }

      if (settings?.clientReview) {
        setClientReviewOptions(settings.clientReview);
      }
    } catch (error) {
      console.log("error getting settings date", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      const formattedMembers = membersFromApi.map((member) => ({
        _id: member._id,
        name: member.name,
        handle: member.handle,
      }));

      setMembers(formattedMembers);
    } catch (error) {
      console.log("error getting members from API", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the deliverables
  |--------------------------------------------------------------------------
  */
  const getDeliverables = async () => {
    try {
      const deliverablesFromApi = await getProjectDeliverablesFromApi(
        projectId,
      );

      // Tag each approver that has approved the deliverable
      const formattedDeliverables = deliverablesFromApi.map((deliverable) => {
        const approvers =
          deliverable.approvers?.map((approver) => {
            let deliverableApproved = false;

            // Check to see if an approver is in the approvedBy array
            deliverable.approvedBy?.forEach((approved) => {
              if (approver._id === approved.approver) {
                deliverableApproved = true;
              }
            });

            return {
              ...approver,
              approved: deliverableApproved,
            };
          }) || [];

        return {
          ...deliverable,
          approvers,
        };
      });

      setDeliverables(formattedDeliverables);
    } catch (error) {
      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to get the deliverables. ${errMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update the deliverable
  |--------------------------------------------------------------------------
  */
  const updateDeliverable = async (
    deliverableId,
    index,
    property,
    value,
    updateOnApi = false,
  ) => {
    // map over the current deliverables to create a new array of new deliverable objects
    // (so that the objects that were passed in as props don't get mutated)
    const newDeliverables = deliverables.map(
      (deliverable, deliverableIndex) => {
        let newQAList = null;

        if (deliverableIndex === index && property === "checklistName") {
          newQAList = availQATypes.find((type) => type._id === value);
          newQAList.items = newQAList.items.map((item) => {
            return {
              text: item,
              done: false,
            };
          });
        }

        return {
          ...deliverable,
          // if this index matches the one we are updating:
          // - apply the new value to the desired property
          // - Otherwise, use the task's existing value for this property
          [property]:
            deliverableIndex === index ? value : deliverable[property],
          file:
            deliverableIndex === index && property === "typeName"
              ? null
              : deliverableIndex === index &&
                (property === "file" || property === "fileName")
              ? value
              : deliverable.file,
          link:
            deliverableIndex === index && property === "typeName"
              ? null
              : deliverableIndex === index && property === "link"
              ? value
              : deliverableIndex === index && property === "fileName"
              ? value.fileName
              : deliverable.link,
          checklistName: newQAList ? newQAList.name : deliverable.checklistName,
          checklist: newQAList ? newQAList.items : deliverable.checklist,
          type:
            deliverableIndex === index &&
            property === "typeName" &&
            (value === "Image" || value === "PDF")
              ? "Upload"
              : deliverableIndex === index &&
                property === "typeName" &&
                value !== "Image" &&
                value !== "PDF"
              ? "Link"
              : deliverable.type,
        };
      },
    );

    if (updateOnApi) {
      let data = {
        [property]: value,
      };

      // If the user is uploading a link, pass up the file id and the link file path
      if (property === "fileName") {
        data = {
          file: value._id,
          link: value.fileName,
        };
      }
      // If the user is uploading a file, only pass up the file id instead of the whole object
      else if (property === "file") {
        data = {
          file: value._id,
        };

        openFile(value._id);
      }
      // If the user is changing the owner, only pass up the owner id instead of the whole object
      else if (property === "owner") {
        data = {
          owner: value._id,
        };
      }
      // If the user is changing the approvers, only pass up the approver Ids instead of the whole object
      else if (property === "approvers") {
        if (value && value.length) {
          data = {
            approvers: value.map((approver) => {
              return approver._id;
            }),
          };
        }
      } // If the user is changing the optional approvers, only pass up the approver Ids instead of the whole object
      else if (property === "optionalApprovers") {
        if (value && value.length) {
          data = {
            optionalApprovers: value.map((approver) => {
              return approver._id;
            }),
          };
        }
      } else if (property === "checklistName") {
        if (value) {
          let newQAList = null;

          newQAList = availQATypes.find((type) => type._id === value);
          newQAList.items = newQAList.items.map((item) => ({
            text: item.text,
            done: false,
          }));

          data = {
            checklistName: newQAList.name,
            checklist: newQAList.items,
          };
        }
      }
      // Remove any existing files or links if the user changes the typeName of a deliverable
      else if (property === "typeName") {
        data = {
          type: value === "Image" || value === "PDF" ? "Upload" : "Link",
          typeName: value,
          file: null,
          link: null,
        };
      }

      // update the deliverables on the API
      editDeliverableOnApi(deliverableId, {
        data,
      });
    }

    return newDeliverables;
  };

  /*
  |--------------------------------------------------------------------------
  | Upload a deliverable
  |--------------------------------------------------------------------------
  */
  const uploadDeliverable = async (deliverableId, deliverableIndex, file) => {
    // upload user's file to the API and associate with this project
    const uploadedFile = await uploadFileToApi(
      file,
      user._id,
      { project: projectId },
      (percentage) => setUploadMessage(`Loading... ${percentage}%`),
    );

    // update the deliverable in state with the uploaded file
    updateDeliverable(
      deliverableId,
      deliverableIndex,
      "file",
      uploadedFile,
      true,
    );

    return;
  };

  /*
  |--------------------------------------------------------------------------
  | Upload a link
  |--------------------------------------------------------------------------
  */
  const uploadLink = async (deliverableId, deliverableIndex, link, file) => {
    let uploadedFile;

    // If no file exists, upload a link file
    if (!file) {
      const data = { project: projectId, link };
      uploadedFile = await uploadLinkFileToApi(user._id, data);
    }
    // Else update the fileName with the link
    else if (file) {
      const data = { fileName: link };
      uploadedFile = await updateFileOnApi(file._id, data);
    }

    // Update the deliverable in state with the uploaded file
    updateDeliverable(
      deliverableId,
      deliverableIndex,
      "fileName",
      uploadedFile,
      true,
    );

    // Update the deliverable in state with the uploaded link
    // updateDeliverable(deliverableId, deliverableIndex, "link", link, true);
    return;
  };

  /*
  |--------------------------------------------------------------------------
  | Creates the deliverable
  |--------------------------------------------------------------------------
  */
  const createDeliverable = async (createData) => {
    try {
      const newDeliverable = await createDeliverableOnApi(createData);

      setDeliverables([...deliverables, newDeliverable]);
    } catch (error) {
      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to create that deliverable. ${errMessage}`,
      );
    }
  };

  const duplicateDeliverable = (deliverable) => {
    const newDeliverable = { ...deliverable };

    // props that shouldn't be carried over from the deliverable being duplicated
    const propsToReset = [
      "_id",
      "dueDate",
      "completedDate",
      "iterations",
      "submitBy",
      "reviewBy",
      "shipBy",
      "file",
      "link",
      "status",
      "approvedBy",
      "optionalApprovedBy",
    ];

    propsToReset.forEach((propName) => {
      delete newDeliverable[propName];
    });

    createDeliverable(newDeliverable);
  };

  /*
  |--------------------------------------------------------------------------
  | Delete deliverables
  |--------------------------------------------------------------------------
  */
  const removeDeliverables = (deliverableIds) => {
    // array of deliverable IDs
    const deliverablesToRemove = Array.isArray(deliverableIds)
      ? deliverableIds
      : [deliverableIds];

    // filter out the deliverables to delete
    const newDeliverables = deliverables.filter(
      (deliverable) => !deliverablesToRemove.includes(deliverable._id),
    );

    setDeliverables(newDeliverables);
  };

  const handleDelete = async (items) => {
    openPromptPopup({
      header: "Delete selected deliverables",
      body: `Are you sure you want to delete the ${
        items.length
      } selected deliverable${items.length === 1 ? "" : "s"}?`,
      confirmCallback: async () => {
        try {
          // Delete deliverables on APi
          await deleteDeliverablesOnApi({ deliverables: items });

          // Remove deliverables from state
          removeDeliverables(items);

          clearSelectedItems();

          openAlertPopup("Deliverables deleted successfully!", null, true);
          closePromptPopup();
        } catch (err) {
          openAlertPopup("Delete deliverables failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Update date arrays for submitBy, reviewBy, and shipBy
  |--------------------------------------------------------------------------
  */
  const updateDates = (deliverable, type, value) => {
    // Use the array if it already exists on the deliverable, else use an empty array
    let dateArray = deliverable[type]?.length ? [...deliverable[type]] : [];

    // Get the latest index of the iteration by grabbing the length and subtracing 1
    const currentIterationIndex = deliverable.iterations?.length
      ? deliverable.iterations.length - 1
      : 0;

    let found = false;

    // For each item in the array, check to see if if there is an entry with the same index that already exists
    dateArray = dateArray.map((submit) => {
      if (submit.iterationIndex === currentIterationIndex) {
        submit.date = value;
        found = true;
      }

      return submit;
    });

    // If there are no matches, add a new entry to the array with the currentIterationIndex as the index
    if (!found) {
      dateArray.push({
        date: value,
        iterationIndex: currentIterationIndex,
      });
    }

    return dateArray;
  };

  return (
    <Container>
      <FiltersContainer>
        <FiltersLeft>
          {isImportant ? (
            <CheckMenu
              disabled={!selectedItems.length}
              numItems={selectedItems.length}
              handleDelete={() => handleDelete(selectedItems)}
            />
          ) : null}

          <Filters
            selectedFilters={selectedFilters}
            updateFilters={setSelectedFilters}
            filters={filters}
            getUniqueOptions={(filter) =>
              getUniqueOptions(filter, deliverables, {
                exclude: selectedFilters,
                propPaths: filterPaths,
              })
            }
          />

          <SelectedFiltersContainer>
            <SelectedFilters
              filters={selectedFilters}
              updateFilters={setSelectedFilters}
              shouldWrap
            />
          </SelectedFiltersContainer>
        </FiltersLeft>

        <FiltersRight>
          <FilterSelect
            label="Group By"
            handleChange={(label, value) => setGroupBy(value)}
            defaultValue={groupBySelectOptions[0]}
            options={groupBySelectOptions}
          />

          <Input
            placeholder="Search items..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FiltersRight>
      </FiltersContainer>

      <TableContainer>
        <Table
          // so the scrolling is handled by the whole viewport, rather than just the table
          scrollable={false}
          noPadding
          groupBy={groupBy}
          headers={[
            {
              component: isImportant
                ? (groupedEntries = displayDeliverables) => (
                    <Checkbox
                      isChecked={
                        groupedEntries?.length &&
                        // ? in case the items are grouped, reference the adjacent grouped items, rather than the entire array
                        groupedEntries.every((item) =>
                          selectedItems.includes(item._id),
                        )
                      }
                      onClick={() => handleSelectAll(groupedEntries)}
                    />
                  )
                : undefined,
            },
            {
              // approved checkmark
              name: "",
            },
            {
              name: "Deliverable",
              accessor: "name",
            },
            {
              name: "Owner",
              accessor: "owner._id",
            },
            {
              name: "Phase",
              accessor: "phase",
            },
            {
              name: "Status",
              accessor: "status",
            },
            {
              name: "Submit By",
              accessor: "submitBy",
            },
            {
              name: "Review By",
              accssor: "reviewBy",
            },
            {
              name: "Ship By",
              accessor: "shipBy",
            },
            {
              name: "Reviewers",
              accessor: "approvers",
            },
            {
              name: "Opt. Reviewers",
              title: "Optional Reviewers",
              accessor: "optionalApprovers",
            },
            // {
            //   name: "Internal Review",
            //   accessor: "internalReview",
            // },
            {
              name: "Client Review",
              accessor: "clientReview",
            },
            {
              name: "Recipient",
              accessor: "recipient",
            },
            {
              name: "QA Type",
              accessor: "checklistName",
            },
            {
              name: "File Type",
              accessor: "typeName",
            },
            {
              name: "Upload",
            },
            {
              name: "Uploaded File",
            },
            {
              // 3 dot menu
              name: "",
            },
          ]}
          // defaultSort="dueDate-asc"
          entries={
            displayDeliverables
              ? displayDeliverables.map((deliverable, index) => {
                  const deliverablePhase = phases.find(
                    (phase) => phase._id === deliverable.phase,
                  );

                  return {
                    ...deliverable,
                    submitBy: deliverable.submitBy?.length
                      ? new Date(
                          deliverable.submitBy[
                            deliverable.submitBy.length - 1
                          ].date,
                        ).toISOString()
                      : new Date(0).toISOString(),
                    reviewBy: deliverable.reviewBy?.length
                      ? new Date(
                          deliverable.reviewBy[
                            deliverable.reviewBy.length - 1
                          ].date,
                        ).toISOString()
                      : new Date(0).toISOString(),
                    shipBy: deliverable.shipBy?.length
                      ? new Date(
                          deliverable.shipBy[
                            deliverable.shipBy.length - 1
                          ].date,
                        ).toISOString()
                      : new Date(0).toISOString(),
                    row: (sortedEntries) => (
                      <>
                        <TableCell>
                          {isImportant ? (
                            <Checkbox
                              isChecked={selectedItems.includes(
                                deliverable._id,
                              )}
                              onClick={(e) => {
                                // ? include the sorted entries from the table so if the user sorted or grouped the items, the correct ones will be selected if they shift+click to multi-select
                                handleSelect(
                                  deliverable._id,
                                  e.shiftKey,
                                  sortedEntries,
                                );
                              }}
                            />
                          ) : null}
                        </TableCell>

                        <TableCell style={{ paddingRight: 0 }}>
                          <div style={{ lineHeight: 0 }}>
                            {deliverable.status.toLowerCase() === "approved" ? (
                              <CheckCircleFill />
                            ) : (
                              <CheckCircleHollow />
                            )}
                          </div>
                        </TableCell>

                        <TableCell data-deliv-name data-sticky-col="left">
                          <FlexContainer>
                            <Editable
                              id={`${deliverable._id}-name`}
                              onSave={
                                isImportant
                                  ? (value) => {
                                      updateDeliverable(
                                        deliverable._id,
                                        index,
                                        "name",
                                        value,
                                        true,
                                      );
                                    }
                                  : undefined
                              }
                            >
                              {deliverable.name}
                            </Editable>

                            {deliverable.file?.versions?.length >= 2 ? (
                              <Version>
                                V{deliverable.file.versions.length}
                              </Version>
                            ) : null}
                          </FlexContainer>
                        </TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-owner`}
                            options={
                              members
                                ? members.map((member) => ({
                                    label: member.handle,
                                    value: member._id,
                                  }))
                                : []
                            }
                            defaultValue={
                              deliverable.owner
                                ? {
                                    label: deliverable.owner.handle,
                                    value: deliverable.owner._id,
                                  }
                                : null
                            }
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "owner",
                                      members.find(
                                        (member) => member._id === value,
                                      ),
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverable.owner ? (
                              <ProfileImage
                                handle={deliverable.owner.handle}
                                name={deliverable.owner.name}
                                xsmall
                              />
                            ) : null}
                          </Editable>
                        </TableCell>

                        <TableCell>
                          <Editable
                            id={`${deliverable._id}-phase`}
                            options={
                              phases?.length
                                ? phases.map((phase) => ({
                                    label: phase.name,
                                    value: phase._id,
                                  }))
                                : []
                            }
                            defaultValue={
                              deliverablePhase
                                ? {
                                    label: deliverablePhase.name,
                                    value: deliverablePhase._id,
                                  }
                                : null
                            }
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "phase",
                                      value,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverablePhase ? deliverablePhase.name : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell full>{deliverable.status}</TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-submitDate`}
                            onSave={
                              deliverable.owner._id === user._id
                                ? (value) => {
                                    const dateArray = updateDates(
                                      deliverable,
                                      "submitBy",
                                      value,
                                    );

                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "submitBy",
                                      dateArray,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                            defaultValue={
                              deliverable.submitBy?.length
                                ? format(
                                    getStandardizedDate(
                                      deliverable.submitBy[
                                        deliverable.submitBy.length - 1
                                      ].date,
                                    ),
                                    "yyyy-MM-dd",
                                  )
                                : ""
                            }
                            inputProps={{ type: "date" }}
                          >
                            {deliverable.submitBy?.length
                              ? format(
                                  getStandardizedDate(
                                    deliverable.submitBy[
                                      deliverable.submitBy.length - 1
                                    ].date,
                                  ),
                                  "MM/dd/yyyy",
                                )
                              : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-reviewDate`}
                            onSave={
                              deliverable.owner._id === user._id
                                ? (value) => {
                                    const dateArray = updateDates(
                                      deliverable,
                                      "reviewBy",
                                      value,
                                    );

                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "reviewBy",
                                      dateArray,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                            defaultValue={
                              deliverable.reviewBy?.length
                                ? format(
                                    getStandardizedDate(
                                      deliverable.reviewBy[
                                        deliverable.reviewBy.length - 1
                                      ].date,
                                    ),
                                    "yyyy-MM-dd",
                                  )
                                : ""
                            }
                            inputProps={{ type: "date" }}
                          >
                            {deliverable.reviewBy?.length
                              ? format(
                                  getStandardizedDate(
                                    deliverable.reviewBy[
                                      deliverable.reviewBy.length - 1
                                    ].date,
                                  ),
                                  "MM/dd/yyyy",
                                )
                              : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-shipDate`}
                            onSave={
                              isImportant
                                ? (value) => {
                                    const dateArray = updateDates(
                                      deliverable,
                                      "shipBy",
                                      value,
                                    );

                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "shipBy",
                                      dateArray,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                            defaultValue={
                              deliverable.shipBy?.length
                                ? format(
                                    getStandardizedDate(
                                      deliverable.shipBy[
                                        deliverable.shipBy.length - 1
                                      ].date,
                                    ),
                                    "yyyy-MM-dd",
                                  )
                                : ""
                            }
                            inputProps={{ type: "date" }}
                          >
                            {deliverable.shipBy?.length
                              ? format(
                                  getStandardizedDate(
                                    deliverable.shipBy[
                                      deliverable.shipBy.length - 1
                                    ].date,
                                  ),
                                  "MM/dd/yyyy",
                                )
                              : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-approvers`}
                            onSave={
                              isImportant
                                ? (value) => {
                                    if (Array.isArray(value)) {
                                      const newApprovers = [];

                                      value.forEach((approver) => {
                                        const foundMember = members.find(
                                          (member) =>
                                            member._id === approver.value,
                                        );
                                        newApprovers.push(foundMember);
                                      });

                                      updateDeliverable(
                                        deliverable._id,
                                        index,
                                        "approvers",
                                        newApprovers,
                                        true,
                                      );
                                    }
                                  }
                                : undefined
                            }
                            options={
                              members
                                ? members.map((member) => ({
                                    label: member.handle,
                                    value: member._id,
                                  }))
                                : []
                            }
                            defaultValue={
                              deliverable.approvers
                                ? deliverable.approvers.map((member) => ({
                                    label: member.handle,
                                    value: member._id,
                                  }))
                                : []
                            }
                            dropdownProps={{ isMulti: true }}
                          >
                            <Approvers>
                              {deliverable.approvers &&
                              deliverable.approvers.length
                                ? deliverable.approvers.map(
                                    (approver, approverIndex) => (
                                      <ProfileImage
                                        key={approverIndex}
                                        handle={approver.handle}
                                        name={approver.name}
                                        position={approverIndex}
                                        xsmall
                                        hasBorder
                                        borderColor={
                                          deliverable.approvedBy
                                            ? deliverable.approvedBy.some(
                                                (item) =>
                                                  item.approver ===
                                                    approver._id &&
                                                  item.status === "approve" &&
                                                  item.iteration ===
                                                    deliverable.iterations[
                                                      deliverable.iterations
                                                        .length - 1
                                                    ]?._id,
                                              ) &&
                                              !deliverable.approvedBy.some(
                                                (item) =>
                                                  item.approver ===
                                                    approver._id &&
                                                  item.status === "deny" &&
                                                  item.iteration ===
                                                    deliverable.iterations[
                                                      deliverable.iterations
                                                        .length - 1
                                                    ]?._id,
                                              )
                                              ? "green400"
                                              : deliverable.approvedBy.some(
                                                  (item) =>
                                                    item.approver ===
                                                      approver._id &&
                                                    item.status === "deny" &&
                                                    item.iteration ===
                                                      deliverable.iterations[
                                                        deliverable.iterations
                                                          .length - 1
                                                      ]?._id,
                                                )
                                              ? "red600"
                                              : undefined
                                            : undefined
                                        }
                                      />
                                    ),
                                  )
                                : isImportant
                                ? "+ Add"
                                : "N/A"}
                            </Approvers>
                          </Editable>
                        </TableCell>

                        <TableCell full>
                          <Editable
                            id={`${deliverable._id}-optionalApprovers`}
                            onSave={
                              isImportant
                                ? (value) => {
                                    if (Array.isArray(value)) {
                                      const newApprovers = [];

                                      value.forEach((approver) => {
                                        const foundMember = members.find(
                                          (member) =>
                                            member._id === approver.value,
                                        );
                                        newApprovers.push(foundMember);
                                      });

                                      updateDeliverable(
                                        deliverable._id,
                                        index,
                                        "optionalApprovers",
                                        newApprovers,
                                        true,
                                      );
                                    }
                                  }
                                : undefined
                            }
                            options={
                              members
                                ? members.map((member) => ({
                                    label: member.handle,
                                    value: member._id,
                                  }))
                                : []
                            }
                            defaultValue={
                              deliverable.optionalApprovers
                                ? deliverable.optionalApprovers.map(
                                    (member) => ({
                                      label: member.handle,
                                      value: member._id,
                                    }),
                                  )
                                : []
                            }
                            dropdownProps={{ isMulti: true }}
                          >
                            <Approvers>
                              {deliverable.optionalApprovers &&
                              deliverable.optionalApprovers.length
                                ? deliverable.optionalApprovers.map(
                                    (approver, approverIndex) => (
                                      <ProfileImage
                                        key={approverIndex}
                                        handle={approver.handle}
                                        name={approver.name}
                                        position={approverIndex}
                                        xsmall
                                        hasBorder
                                        borderColor={
                                          deliverable.optionalApprovedBy?.some(
                                            (item) =>
                                              item.approver === approver._id &&
                                              item.status === "approve" &&
                                              item.iteration ===
                                                deliverable.iterations[
                                                  deliverable.iterations
                                                    .length - 1
                                                ]?._id,
                                          )
                                            ? "green400"
                                            : undefined
                                        }
                                      />
                                    ),
                                  )
                                : isImportant
                                ? "+ Add"
                                : "N/A"}
                            </Approvers>
                          </Editable>
                        </TableCell>

                        {/* <TableCell full>
                        {deliverable.internalReview
                          ? deliverable.internalReview
                          : "N/A"}
                      </TableCell> */}

                        <TableCell>
                          <Editable
                            id={`${deliverable._id}-clientReview`}
                            options={
                              clientReviewOptions?.length
                                ? clientReviewOptions.map((option) => ({
                                    label: option.option,
                                    value: option.option,
                                  }))
                                : null
                            }
                            defaultValue={
                              deliverable.clientReview
                                ? {
                                    label: deliverable.clientReview,
                                    value: deliverable.clientReview,
                                  }
                                : null
                            }
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "clientReview",
                                      value,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverable.clientReview
                              ? deliverable.clientReview
                              : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell>
                          <Editable
                            id={`${deliverable._id}-recipient`}
                            options={recipientOptions.map((option) => ({
                              label: option,
                              value: option,
                            }))}
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "recipient",
                                      value,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverable.recipient}
                          </Editable>
                        </TableCell>

                        <TableCell>
                          <Editable
                            id={`${deliverable._id}-QA`}
                            options={
                              availQATypes
                                ? availQATypes.map((type) => ({
                                    label: type.name,
                                    value: type._id,
                                  }))
                                : []
                            }
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "checklistName",
                                      value,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverable.checklistName
                              ? deliverable.checklistName
                              : "N/A"}
                          </Editable>
                        </TableCell>

                        <TableCell style={{ textTransform: "uppercase" }}>
                          <Editable
                            id={`${deliverable._id}-type`}
                            options={deliverableTypeNames.map((typeName) => ({
                              label: typeName,
                              value: typeName,
                            }))}
                            onSave={
                              isImportant
                                ? (value) => {
                                    updateDeliverable(
                                      deliverable._id,
                                      index,
                                      "typeName",
                                      value,
                                      true,
                                    );
                                  }
                                : undefined
                            }
                          >
                            {deliverable.typeName}
                          </Editable>
                        </TableCell>

                        <TableCell full>
                          {deliverable.type === "Upload" ? (
                            <DeliverableUpload
                              id={deliverable._id}
                              typeName={deliverable.typeName}
                              name={deliverable.name}
                              isDisabled={user._id !== deliverable.owner._id}
                              onUpload={async (file) => {
                                // ? async so the component can await this function and display "loading"
                                await uploadDeliverable(
                                  deliverable._id,
                                  index,
                                  file,
                                );
                                return;
                              }}
                            />
                          ) : (
                            <Editable
                              id={`${deliverable._id}-link`}
                              defaultValue={deliverable.link}
                              onSave={async (value) => {
                                await uploadLink(
                                  deliverable._id,
                                  index,
                                  value,
                                  deliverable.file,
                                );
                              }}
                              inputProps={{ placeholder: "https://..." }}
                              iconClickOnly={deliverable.link ? true : false}
                            >
                              {deliverable.link ? (
                                <ExLink href={deliverable.link} target="_blank">
                                  {deliverable.link.length > 20
                                    ? `${deliverable.link.substring(0, 20)}...`
                                    : deliverable.link}
                                  <LinkIcon />
                                </ExLink>
                              ) : (
                                "Add link"
                              )}
                            </Editable>
                          )}
                        </TableCell>

                        <TableCell>
                          <DeliverableFile
                            disabled={!deliverable.file}
                            onClick={() => {
                              deliverable.file && deliverable.file._id
                                ? openFile(
                                    deliverable.file._id,
                                    deliverable._id,
                                  )
                                : null;
                            }}
                          >
                            {deliverable.link
                              ? `Link for ${deliverable.name}`
                              : deliverable.file
                              ? deliverable.file.fileName
                              : "N/A"}
                          </DeliverableFile>
                        </TableCell>

                        <TableCell data-sticky-col="right">
                          {isImportant ? (
                            <PopoutMenu
                              actions={[
                                {
                                  text: "Duplicate",
                                  action: () =>
                                    duplicateDeliverable(deliverable),
                                },
                                {
                                  text: "Delete",
                                  action: () => handleDelete([deliverable._id]),
                                  danger: true,
                                },
                              ]}
                            />
                          ) : null}
                        </TableCell>
                      </>
                    ),
                  };
                })
              : []
          }
          handleCreate={false}
          createRow={
            isImportant ? (
              <CreateRow
                projectId={projectId}
                clientId={clientId}
                clientReviewOptions={clientReviewOptions}
                phases={phases}
                members={members}
                recipientOptions={recipientOptions}
                availQATypes={availQATypes}
                deliverableTypeNames={deliverableTypeNames}
                onCreate={createDeliverable}
              />
            ) : (
              undefined
            )
          }
        />
      </TableContainer>
    </Container>
  );
};

// ? this is its own component so the table doesn't re-render every time the user types in an input field
const CreateRow = ({
  projectId,
  clientId,
  onCreate,
  clientReviewOptions,
  phases,
  members,
  recipientOptions,
  availQATypes,
  deliverableTypeNames,
}) => {
  const defaultInputValues = {
    name: "",
    project: projectId,
    client: clientId,
    status: "In progress",
    owner: null,
    approvers: [],
    optionalApprovers: [],
    file: null,
    link: null,
    checklistName: "",
    checklist: [],
    type: null,
    typeName: null,
    clientReview: null,
    // clientReview: clientReviewOptions[0].option,
  };

  const [createInputs, setCreateInputs] = useState(defaultInputValues);
  const [isLoading, setIsLoading] = useState(false);

  // Updates the inputs in state
  const updateCreateInputs = (newValue, key) => {
    const newInputs = { ...createInputs };

    if (key === "typeName") {
      newInputs["type"] =
        newValue === "Image" || newValue === "PDF" ? "Upload" : "Link";
    }

    if (key === "checklistName") {
      const newQAType = availQATypes.find((type) => type._id === newValue);

      if (newQAType) {
        newInputs.checklistName = newQAType.name;
        newInputs.checklist = newQAType.items.map((item) => ({
          text: item,
          done: false,
        }));
      }
    } else {
      newInputs[key] = newValue;
    }

    setCreateInputs(newInputs);
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      if (onCreate) {
        await onCreate(createInputs);
      }
    } catch (error) {}

    // Clears out inputs
    setCreateInputs(defaultInputValues);

    setIsLoading(false);
  };

  return (
    <>
      <TableCell>{/* placeholder for checkbox */}</TableCell>
      <TableCell>{/* placeholder for approved checkmark */}</TableCell>

      <TableCell full>
        <CreateInput
          placeholder="Name"
          value={createInputs.name}
          onChange={(e) => updateCreateInputs(e.currentTarget.value, "name")}
        />
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-owner"
          onSave={(value) => updateCreateInputs(value, "owner")}
          options={
            members?.length
              ? members.map((member) => ({
                  label: member.handle,
                  value: member._id,
                }))
              : []
          }
          defaultValue={
            createInputs.owner
              ? {
                  label: findItemProperty(
                    "handle",
                    members,
                    "_id",
                    createInputs.owner,
                  ),
                  value: createInputs.owner,
                }
              : null
          }
          dropdownProps={{
            menuPlacement: "top",
            isClearable: true,
            placeholder: "Owner",
          }}
        >
          {createInputs.owner ? (
            <ProfileImage
              handle={findItemProperty(
                "handle",
                members,
                "_id",
                createInputs.owner,
              )}
              name={findItemProperty(
                "name",
                members,
                "_id",
                createInputs.owner,
              )}
              xsmall
            />
          ) : (
            <CreateLabel>Owner</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell>
        <Editable
          id="create-row-phase"
          onSave={(value) => updateCreateInputs(value, "phase")}
          options={
            phases?.length
              ? phases.map((phase) => ({
                  label: phase.name,
                  value: phase._id,
                }))
              : []
          }
          defaultValue={
            createInputs.phase
              ? {
                  label: findItemProperty(
                    "name",
                    phases,
                    "_id",
                    createInputs.phase,
                  ),
                  value: createInputs.phase,
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "Phase" }}
        >
          {createInputs.phase ? (
            <CreateValue>
              {findItemProperty("name", phases, "_id", createInputs.phase)}
            </CreateValue>
          ) : (
            <CreateLabel>Phase</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        {/* status */}
        In progress
      </TableCell>

      <TableCell full>
        {/* submitBy placeholder */}
        N/A
      </TableCell>

      <TableCell full>
        {/* reviewBy placeholder */}
        N/A
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-shipby"
          onSave={(value) => {
            const dateArray = [
              {
                date: value,
                iterationIndex: 0,
              },
            ];

            updateCreateInputs(dateArray, "shipBy");
          }}
          defaultValue={
            createInputs.shipBy?.length
              ? createInputs.shipBy[createInputs.shipBy.length - 1].date
              : ""
          }
          inputProps={{ type: "date" }}
        >
          {createInputs.shipBy?.length ? (
            <CreateValue>
              {format(
                getStandardizedDate(
                  createInputs.shipBy[createInputs.shipBy.length - 1].date,
                ),
                "MM/dd/yyyy",
              )}
            </CreateValue>
          ) : (
            <CreateLabel>Ship By</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-reviewers"
          onSave={(values) => {
            updateCreateInputs(
              values.map((item) => item.value),
              "approvers",
            );
          }}
          options={
            members?.length
              ? members.map((member) => ({
                  label: member.handle,
                  value: member._id,
                }))
              : []
          }
          defaultValue={
            createInputs.approvers?.length
              ? createInputs.approvers.map((approver) => ({
                  label: findItemProperty("handle", members, "_id", approver),
                  value: approver,
                }))
              : []
          }
          dropdownProps={{
            menuPlacement: "top",
            placeholder: "Reviewers",
            isMulti: true,
          }}
        >
          {createInputs.approvers?.length ? (
            <ProfileImageGroup
              maxToShow={-1}
              xsmall
              members={members.filter((member) =>
                createInputs.approvers.includes(member._id),
              )}
            />
          ) : (
            <CreateLabel>Reviewers</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-optionalApprovers"
          onSave={(values) => {
            updateCreateInputs(
              values.map((item) => item.value),
              "optionalApprovers",
            );
          }}
          options={
            members?.length
              ? members.map((member) => ({
                  label: member.handle,
                  value: member._id,
                }))
              : []
          }
          defaultValue={
            createInputs.optionalApprovers?.length
              ? createInputs.optionalApprovers.map((approver) => ({
                  label: findItemProperty("handle", members, "_id", approver),
                  value: approver,
                }))
              : []
          }
          dropdownProps={{
            menuPlacement: "top",
            placeholder: "Optional Reviewers",
            isMulti: true,
          }}
        >
          {createInputs.optionalApprovers?.length ? (
            <ProfileImageGroup
              maxToShow={-1}
              xsmall
              members={members.filter((member) =>
                createInputs.optionalApprovers.includes(member._id),
              )}
            />
          ) : (
            <CreateLabel>Opt. Reviewers</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-clientReview"
          onSave={(value) => updateCreateInputs(value, "clientReview")}
          options={
            clientReviewOptions?.length
              ? clientReviewOptions.map((option) => ({
                  label: option.option,
                  value: option.option,
                }))
              : []
          }
          defaultValue={
            createInputs.clientReview
              ? {
                  label: createInputs.clientReview,
                  value: createInputs.clientReview,
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "Client Review" }}
        >
          {createInputs.clientReview ? (
            <CreateValue>{createInputs.clientReview}</CreateValue>
          ) : (
            <CreateLabel>Client Review</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-recipient"
          onSave={(value) => updateCreateInputs(value, "recipient")}
          options={recipientOptions.map((option) => ({
            label: option,
            value: option,
          }))}
          defaultValue={
            createInputs.recipient
              ? {
                  label: createInputs.recipient,
                  value: createInputs.recipient,
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "Recipient" }}
        >
          {createInputs.recipient ? (
            <CreateValue>{createInputs.recipient}</CreateValue>
          ) : (
            <CreateLabel>Recipient</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-qa"
          onSave={(value) => updateCreateInputs(value, "checklistName")}
          options={
            availQATypes?.length
              ? availQATypes.map((type) => ({
                  label: type.name,
                  value: type._id,
                }))
              : []
          }
          defaultValue={
            createInputs.checklistName
              ? {
                  label: createInputs.checklistName,
                  value: findItemProperty(
                    "_id",
                    availQATypes,
                    "name",
                    createInputs.checklistName,
                  ),
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "QA Type" }}
        >
          {createInputs.checklistName ? (
            <CreateValue>{createInputs.checklistName}</CreateValue>
          ) : (
            <CreateLabel>QA Type</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-fileType"
          onSave={(value) => updateCreateInputs(value, "typeName")}
          options={deliverableTypeNames.map((typeName) => ({
            label: typeName,
            value: typeName,
          }))}
          defaultValue={
            createInputs.typeName
              ? {
                  label: createInputs.typeName,
                  value: createInputs.typeName,
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "File Type" }}
        >
          {createInputs.typeName ? (
            <CreateValue>{createInputs.typeName}</CreateValue>
          ) : (
            <CreateLabel>File Type</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <AddBtn
          onClick={handleCreate}
          disabled={
            isLoading ||
            !createInputs.name ||
            !createInputs.owner ||
            !createInputs.approvers ||
            !createInputs.recipient ||
            !createInputs.typeName
          }
          style={{ display: "flex" }}
        >
          <CreateIcon />
        </AddBtn>
      </TableCell>

      <TableCell full>{/* placeholder for upload column */}</TableCell>
      <TableCell full>{/* placeholder for 3-dot menu */}</TableCell>
    </>
  );
};

const Container = styled(ViewContainer)`
  table {
    th,
    td {
      &[data-deliv-name] {
        min-width: 150px;
      }
    }
  }
`;

const Approvers = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: -8px;
  }
`;

const DeliverableFile = styled.p`
  margin: 0px;
  font-size: 12px;
  line-height: 16px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) => (props.disabled ? "#6B7280" : "#5048e5")};
  text-decoration: ${(props) => (props.disabled ? "none" : "underline")};
  text-overflow: ellipsis;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;

const ExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;
  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Version = styled.div`
  background-color: ${(props) => props.theme.colors.coolGray200};
  color: ${(props) => props.theme.colors.coolGray700};
  border-radius: 4px;
  font-size: 12px;
  padding: 0px 5px;
  margin-left: 8px;
  display: inline;
`;

export default DeliverablesList;
