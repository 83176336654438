import React, { useState, useEffect, useContext } from "react";
import { addDays, isPast } from "date-fns";
import { useHistory } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";
import store from "store";

import { useNotifications } from "./notificationsContext";
import { refreshToken, getUserInfoFromApi, loginOnApi } from "../utils/api";

const AuthContext = React.createContext();

// * This is the context used for the user and auth

const AuthProvider = (props) => {
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    jsSrc: "https://apis.google.com/js/api.js",
    onLogoutSuccess: () => console.info("logout succeeded"),
    onFailure: () => console.error("logout failed"),
  });
  const history = useHistory();

  const {
    setNotifications,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const [email, setEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [isDev, setIsDev] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const [isSuper, setIsSuper] = useState(null);
  const [isManagerOrAdmin, setIsManagerOrAdmin] = useState(null);
  const [isImportant, setIsImportant] = useState(null);
  const [isOpen, setIsOpen] = useState(true); // Left sidebar

  // To keep track of user's status so we can show it on their profile images
  const [membersStatuses, setMembersStatuses] = useState([]);

  const [authenticating, setAuthenticating] = useState(true);

  const setupUser = async () => {
    try {
      // Refreshes the auth token for api requests. Had to do it at beginning or
      // sometimes it wouldn't be set in time for this other stuff
      refreshToken();

      const userResults = await getUserInfoFromApi();

      // Checks if the result exists and is successful
      if (userResults?.status === "success") {
        const thisUser = userResults.data.user;

        const newStatuses = userResults.data.memberStatuses;

        // Sorts the notifications from newest to oldest
        const newNotifications = userResults.data.notifications
          ? userResults.data.notifications.sort(function(a, b) {
              return new Date(a.createdAt) - new Date(b.createdAt);
            })
          : [];

        // Assigns user privaleges
        // ? set these before we set the user below
        if (thisUser.accessLvl === "super") {
          setIsSuper(true);
          setIsAdmin(true);
          setIsManagerOrAdmin(true);
          setIsImportant(true);
        } else if (thisUser.accessLvl === "admin") {
          setIsAdmin(true);
          setIsManagerOrAdmin(true);
          setIsImportant(true);
        } else if (thisUser.accessLvl === "manager") {
          setIsManagerOrAdmin(true);
          setIsImportant(true);
        } else if (thisUser.role.tier === "director") {
          setIsImportant(true);
        }

        setEmail(thisUser.email);
        setUser(thisUser);
        setMembersStatuses(newStatuses);
        setNotifications(newNotifications);

        // If the user has not logged in to the desktop app ever
        if (!thisUser.hasDesktopApp) {
          const nextDate = addDays(new Date(), 1);

          // Get the local storage date of when they should get the prompt next
          const storeNextDesktopPrompt = store.get("nextDesktopPrompt");

          // Checks if showing the prompt is overdue
          const isOverdue = storeNextDesktopPrompt
            ? isPast(new Date(storeNextDesktopPrompt))
            : true;

          if (isOverdue) {
            openPromptPopup({
              header: "Get the desktop app",
              body:
                "Download the desktop app to get quick access to your timers and make it easier to switch back and forth.",
              cancelCallback: () => {
                store.set("nextDesktopPrompt", nextDate);
                closePromptPopup();
              },
              confirmCallback: () => {
                window.open(
                  `${process.env.REACT_APP_SERVER_URL}/desktop/download/mac`,
                  "_blank",
                );
                store.set("nextDesktopPrompt", nextDate);
                closePromptPopup();
              },
              cancelText: "Not now",
              confirmText: "Download",
            });
          }
        }

        // Checks if they are dev
        const storeIsDev = store.get("isDev");
        if (storeIsDev) {
          console.info("dev set true");

          setIsDev(true);
        } else {
          setIsDev(false);
        }
      } else {
        throw new Error("Status of user info request was unsuccessful");
      }
    } catch (err) {
      console.error(err);
      history.push("/login");
    }
  };

  const login = async (email, googleToken) => {
    const newToken = await loginOnApi(email, googleToken);
    store.set("sherpaToken", newToken);

    setupUser();
  };

  const logout = () => {
    store.remove("sherpaToken");
    setEmail(null);
    setUser(null);
    setIsSuper(null);
    setIsAdmin(null);
    setIsManagerOrAdmin(null);
    setIsImportant(null);
    signOut();
    history.push("/login");
  };

  useEffect(() => {
    const storedToken = store.get("sherpaToken");
    if (storedToken) {
      setupUser().then(() => {
        setAuthenticating(false);
      });
    } else {
      setUser(null);
      setAuthenticating(false);
    }
  }, []); // eslint-disable-line

  const updateStatuses = (newStatuses) => {
    setMembersStatuses(newStatuses);
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        user,
        isDev,
        isSuper,
        isAdmin,
        isManagerOrAdmin,
        isImportant,
        setupUser,
        login,
        logout,

        membersStatuses,
        updateStatuses,

        isOpen,
        setIsOpen,

        authenticating,
      }}
      {...props}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
