import React from "react";
import styled from "styled-components";

const TrendingUpIcon = (props) => {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66667 4.66675H14M14 4.66675V10.0001M14 4.66675L8.66667 10.0001L6 7.33341L2 11.3334"
        stroke="#22C55E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  display: inline-block;
`;

export default TrendingUpIcon;
