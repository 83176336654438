import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format, eachDayOfInterval } from "date-fns";
import { startCase } from "lodash";

import { getAllTasksFromApi, getMembersFromApi } from "../../utils/api";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../utils/sorting";

import ProfileImage from "../../components/ProfileImage";
import Filters, { FilterGroup } from "../../components/NewTable/Filters";
import FilterSelect from "../../components/NewTable/FilterSelect";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import Table from "../../components/NewTable/index";
import { TableCell, SearchInput } from "../../components/NewTable/components";
import PageLayout from "../sales/components/pageLayout";
import LineGraph from "../projects/components/LineGraph";
import { getStandardizedDate } from "../../utils/helpers";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  project: "projectId.name",
  client: "projectId.client.name",
  owner: "memberId.name",
};

// filter chips
const filters = ["title", "project", "client", "owner", "status"];

// options for the group by dropdown
const groupByOptions = ["owner", "project", "client", "status"];

// group by dropdown options
const groupBySelectOptions = [
  // first option is "None"
  { label: "None", value: null },
  // remaining options
  ...groupByOptions.map((option) => {
    // grab the proper path for this filter
    const value = filterPaths.hasOwnProperty(option)
      ? filterPaths[option]
      : option;

    return {
      label: startCase(option),
      value,
    };
  }),
];

const ArchivedTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [displayTasks, setDisplayTasks] = useState([]);
  const [activeTasks, setActiveTasks] = useState([]);
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [members, setMembers] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [groupBy, setGroupBy] = useState(null);

  useEffect(() => {
    getMembers();
    getTasks();
  }, []);

  useEffect(() => {
    if (tasks) {
      const newDisplay = filterTasks();
      setDisplayTasks(newDisplay);
    }
  }, [tasks, searchText, selectedFilters]);

  /*
  |--------------------------------------------------------------------------
  | Filters the tasks
  |--------------------------------------------------------------------------
  */
  const filterTasks = () => {
    let tempTasks = [...tasks];

    // Filters the tasks
    if (selectedFilters && selectedFilters.length > 0) {
      tempTasks = filterItems(
        // Formats filters
        selectedFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempTasks,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempTasks = searchItems(searchText, tempTasks, [
        "title",
        "memberId.name",
        "projectId.name",
        "projectId.client.name",
        "date",
      ]);
    }

    return tempTasks;
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    let tempMembers = await getMembersFromApi();

    tempMembers = tempMembers.map((member) => {
      return {
        _id: member._id,
        name: member.name,
        handle: member.handle,
      };
    });

    setMembers(tempMembers);
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the tasks
  |--------------------------------------------------------------------------
  */
  const getTasks = async () => {
    const tempTasks = await getAllTasksFromApi({
      projects: true,
      showArchived: true,
    });

    const tempActiveTasks = [];
    const tempOverdueTasks = [];

    // Only return tasks that have a date set
    let sortedTasks = tempTasks.filter((task) => {
      return task.date;
    });

    // Sort the tasks by date
    sortedTasks = sortedTasks.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    const start = getStandardizedDate(sortedTasks[0].date);
    const end = getStandardizedDate(new Date());
    const dates = eachDayOfInterval({ start, end });

    // Loop over each date from the earliest createdAt date of the tasks to today
    dates.forEach((oldDate) => {
      const date = getStandardizedDate(oldDate);
      let overdue = 0;
      let active = 0;

      // Loop over each task to see which one will be marked as overdue or active
      sortedTasks.forEach((task) => {
        const dueDate = getStandardizedDate(task.date);

        // If the task is due before the current looped date AND it doesn't have a status of completed, mark it as overdue
        // If a task is due on 2-10, and the current looped date is 2-15, the task is OVERDUE
        if (dueDate < date && task.status !== "Completed") {
          overdue++;
        }
        // If the task is due after the current looped date AND it doesn't have a status of completed, mark it as active
        // If a task is due on 2-15, and the current looped date is 2-10, the task is ACTIVE and not yet overdue
        else if (dueDate > date && task.status !== "Completed") {
          active++;
        }
      });

      // For each date, log the amount of active and overdue tasks
      tempActiveTasks.push({
        x: date,
        y: active,
      });
      tempOverdueTasks.push({
        x: date,
        y: overdue,
      });
    });

    setTasks(tempTasks);
    setActiveTasks(tempActiveTasks);
    setOverdueTasks(tempOverdueTasks);
  };

  return (
    <PageLayout
      title="Archived Tasks"
      breadcrumbs={[{ name: "Reports" }, { name: "Archived Tasks" }]}
    >
      <Container>
        <Graphs>
          <GraphContainer>
            <LineGraph
              yLabel="Tasks"
              entryLabel="Active Tasks"
              lineColor="#22C55E"
              data={activeTasks}
            />
          </GraphContainer>
          <GraphContainer>
            <LineGraph
              yLabel="Overdue Tasks"
              entryLabel="Overdue Tasks"
              lineColor="#EF4444"
              data={overdueTasks}
            />
          </GraphContainer>
        </Graphs>

        <StyledFilterGroup>
          <Filters
            selectedFilters={selectedFilters}
            updateFilters={setSelectedFilters}
            filters={filters}
            getUniqueOptions={(filter) =>
              getUniqueOptions(filter, tasks, {
                exclude: selectedFilters,
                propPaths: filterPaths,
              })
            }
          />
          <FilterSelect
            label="Group By"
            handleChange={(label, value) => setGroupBy(value)}
            defaultValue={groupBySelectOptions[0]}
            options={groupBySelectOptions}
          />

          <SearchInput
            placeholder="Search items..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </StyledFilterGroup>

        <StyledSelectedFilters
          filters={selectedFilters}
          updateFilters={setSelectedFilters}
        />

        <Table
          noPadding
          noShadow
          showAll={groupBy ? true : false}
          groupBy={groupBy}
          headers={[
            {
              name: "Task",
              accessor: "title",
            },
            { name: "Project", accessor: "projectId.name" },
            { name: "Client", accessor: "projectId.client.name" },
            {
              name: "Owner",
              accessor: "memberId.name",
            },
            {
              name: "Due Date",
              accessor: "date",
            },
            {
              name: "Status",
              accessor: "status",
            },
          ]}
          entries={
            displayTasks && tasks.length && members && members.length
              ? displayTasks.map((task) => {
                  return {
                    ...task,
                    dueDate: new Date(task.date).toISOString(),
                    row: (
                      <>
                        <TableCell full>
                          <StyledLink
                            as={Link}
                            to={`/projects/${task.projectId._id}/tasks`}
                          >
                            {task.title}
                          </StyledLink>
                        </TableCell>

                        <TableCell full>
                          {task.projectId ? (
                            <StyledLink
                              as={Link}
                              to={`/projects/${task.projectId._id}`}
                            >
                              {task.projectId.name}
                            </StyledLink>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell full>
                          {task.projectId.client &&
                          task.projectId.client.acronym
                            ? task.projectId.client.acronym
                            : task.projectId.client &&
                              task.projectId.client.name
                            ? task.projectId.client.name
                            : "N/A"}
                        </TableCell>
                        <TableCell full>
                          <ProfileImage
                            handle={task.memberId.handle}
                            name={task.memberId.name}
                            xsmall
                          />
                        </TableCell>

                        <TableCell full>
                          {format(new Date(task.date), "MM/dd/yyyy")}
                        </TableCell>

                        <TableCell full>{task.status}</TableCell>
                      </>
                    ),
                  };
                })
              : []
          }
        />
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;
  background-color: white;
`;

const StyledLink = styled.p`
  margin: 0px;
  cursor: pointer;
  color: ${(props) => (props.disabled ? "#6B7280" : "#5048e5")};
  text-decoration: none;
`;

const Tier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 50px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${(props) =>
    props.tier === "Tier 1"
      ? "#CCFBF1"
      : props.tier === "Tier 2"
      ? "#FFEDD5"
      : "#DBEAFE"};
  color: ${(props) =>
    props.tier === "Tier 1"
      ? "#0D9488"
      : props.tier === "Tier 2"
      ? "#EA580C"
      : "#2563EB"};
`;

const Risk = styled.span`
  color: ${(props) => (props.danger ? "#EF4444" : "#6B7280")};
`;

const Graphs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GraphContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 24px 24px 10px 24px;
  width: 49%;
  margin-bottom: 50px;

  text {
    fill: ${(props) => props.theme.colors.coolGray400};
    font-weight: 500;
    font-size: 12px;
  }

  .apexcharts-yaxis-title {
    transform: translateX(-5px);
  }
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  z-index: 6;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledSelectedFilters = styled(SelectedFilters)`
  margin-bottom: 24px;
`;

export default ArchivedTasks;
