import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/theme";
import SuccessIcon from "./icons/successIcon";
import FailIcon from "./icons/failIcon";

const Circle = ({ status, amount, small, ...rest }) => {
  const color =
    status === "success"
      ? colors.green
      : status === "fail"
      ? colors.red
      : colors.mediumGray;

  return (
    <Container color={color} small={small} {...rest}>
      {Number.isInteger(amount) ? (
        <Amount color={color}>{amount}</Amount>
      ) : status === "success" ? (
        <SuccessIcon />
      ) : status === "fail" ? (
        <FailIcon />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.small ? "15px" : "33px")};
  width: ${(props) => (props.small ? "15px" : "33px")};
  border-radius: 50%;
  border: ${(props) => (props.small ? "1px" : "3px")} solid
    ${(props) => props.color};

  &:not(:last-child) {
    margin-right: 6px;
  }

  ${(props) =>
    props.small
      ? css`
          padding-top: 1px;

          svg {
            height: 7px;
            width: 7px;
          }
        `
      : ``}
`;

const Amount = styled.p`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${(props) => (props.small ? "8px" : "12px")};
  line-height: 1;
  margin-bottom: 0;
`;

Circle.propTypes = {
  status: PropTypes.oneOf(["success", "fail", "neutral"]),
  number: PropTypes.number,
  small: PropTypes.bool,
};

Circle.defaultProps = {
  status: "neutral",
  number: null,
  small: false,
};

export default Circle;
