/*
  |--------------------------------------------------------------------------
  | Favicon logic
  |--------------------------------------------------------------------------
  */
const linkForFavicon = document.querySelector(`head > link[rel='icon']`);

const redDot = `
 <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
   <text y=%22.9em%22 font-size=%2290%22>
     🔴
   </text>
 </svg>
`.trim();

const whiteDot = `
 <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
   <text y=%22.9em%22 font-size=%2290%22>
   ⚪
   </text>
 </svg>
`.trim();

const greenDot = `
 <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
   <text y=%22.9em%22 font-size=%2290%22>
   🟢
   </text>
 </svg>
`.trim();

const mountain = `
 <svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
   <text y=%22.9em%22 font-size=%2290%22>
   🏔️
   </text>
 </svg>
`.trim();

let newFavicon = "";

export const toggleFavicon = (color) => {
  if (color === "red") {
    newFavicon = whiteDot;
  } else if (color === "white") {
    newFavicon = redDot;
  } else if (color === "green") {
    newFavicon = greenDot;
  } else {
    newFavicon = mountain;
  }

  linkForFavicon.setAttribute(`href`, `data:image/svg+xml,${newFavicon}`);
};

export const setTabTitle = (text) => {
  document.title = text;
};
