import { useState, useEffect } from "react";

import { getSortFunction } from "../utils/helpers";

export default (toSort = [], sortOptions = [], customSortFunctions = {}) => {
  const [sorted, setSorted] = useState(toSort);
  const [sortBy, setSortBy] = useState("");

  // Handles the sorting
  useEffect(() => {
    if (toSort) {
      const sortedItems = [...toSort];

      if (sortBy) {
        let sortFunc;

        // if we have a custom sort function, use that
        if (Object.prototype.hasOwnProperty.call(customSortFunctions, sortBy)) {
          sortFunc = customSortFunctions[sortBy];
        } else {
          // otherwise, generate a generic sort function
          const sortParts = sortBy.split("-");
          const sortField = sortParts[0];
          const sortDirection = sortParts[1];
          const sortFieldInfo = sortOptions.find(
            (heading) => heading.value === sortField,
          );
          sortFunc = getSortFunction({
            type: sortFieldInfo.type,
            direction: sortDirection,
            field: sortField,
          });
        }

        if (sortFunc) {
          sortedItems.sort(sortFunc);
        }
      }

      setSorted(sortedItems);
    }
  }, [sortBy, toSort]);

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  return [sorted, sortBy, changeSort];
};
