import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { format, getDay, isPast, isSameDay, isToday } from "date-fns";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import store from "store";

import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";
import { useActiveTimer } from "../../context/activeTimerContext";

import {
  startOfWeek,
  endOfWeek,
  getWeek,
  getStandardizedDate,
  getSafariDate,
  ClientLink,
  roundDownTo,
} from "../../utils/helpers";
import { respondTo } from "../../styles/styleHelpers";

import {
  getProjectsFromApi,
  uploadFileToApi,
  downloadFileFromApi,
  getDeliverablesFromApi,
  getDeliverablesToReviewFromApi,
  getTeamTasksFromApi,
  getTodayCompletionsFromApi,
  getAllTasksFromApi,
} from "../../utils/api";
import { getKpisFromApi, updateKpiOnApi } from "../manage/utils/api";

import { createRecapOnApi, finishDayOnApi } from "../dashboard/utils/api";

import ClockIcon from "../../components/icons/ClockIcon";
import TaskIcon from "../../components/icons/TasksIcon";
import GridViewIcon from "../../components/icons/GridViewIcon";
import ListViewIcon from "../../components/icons/ListViewIcon";
import { TableHeader, TableRow, TableCell } from "../../components/Table";
import DefaultLink from "../../components/links/DefaultLink";
import SimpleLink from "../../components/links/SimpleLink";

import ProgressBar from "../dashboard/components/ProgressBar";
import Dashboard from "../dashboard/index";

import FinishSection from "./components/FinishSection";

import DashboardInbox from "./DashboardInbox";
import FinishModal from "./finishModal";
import FinishWeekModal from "./components/FinishWeekModal";
import MessageSection from "./components/MessageSection";

const Home = () => {
  const history = useHistory();
  const { user } = useAuth();
  const {
    loading,
    setLoading,
    openAlertPopup,
    openModal,
    closeModal,
  } = useNotifications();
  const { setFaviconEnabled, startTimer, isTimerRunning } = useActiveTimer();

  const [sortBy, setSortBy] = useState(null);
  const [userTasks, setUserTasks] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [kpis, setKpis] = useState([]); //eslint-disable-line
  const [projects, setProjects] = useState([]);
  const [currentProjects, setCurrentProjects] = useState([]);
  const [tasksOverdue, setTasksOverdue] = useState(0);
  const [reviewsOverdue, setReviewsOverdue] = useState(0);
  const [weekHoursRemaining, setWeekHoursRemaining] = useState(0);
  const [todayHoursRemaining, setTodayHoursRemaining] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [view, setView] = useState("grid");
  const [viewTeam, setViewTeam] = useState(false);
  const [viewAllReviews, setViewAllReviews] = useState(false);
  const [teamTasks, setTeamTasks] = useState([]);
  const [dailyCompletions, setDailyCompletions] = useState([]);

  const currentDate = new Date();
  const weekNumber = getWeek(getStandardizedDate(currentDate));
  const weekPace = (getDay(currentDate) / 5) * 100;
  const day = getDay(currentDate);

  const currentYear = new Date(currentDate).getFullYear();

  let storedDate = store.get("dayFinished");
  storedDate = new Date(storedDate);
  let dayFinished = false;
  const storedFinishedDay = getDay(storedDate);

  // Check to see if the dayFinished local variable is the same date as today
  if (
    (storedDate && isSameDay(storedDate, currentDate)) ||
    (storedFinishedDay >= 5 && day >= 5)
  ) {
    dayFinished = true;
  }

  useEffect(() => {
    setLoading(true);

    if (user) {
      getTasks(user._id);
      getKpis(user._id);
      getProjects();
      getDailyCompletions();
    }
  }, [user]);

  /*
  |--------------------------------------------------------------------------
  | Get number of overdue tasks
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (tasks.length) {
      let tempOverdue = 0;

      tasks.forEach((task) => {
        if (
          task.date &&
          isPast(getStandardizedDate(task.date)) &&
          !isSameDay(currentDate, getStandardizedDate(task.date))
        ) {
          tempOverdue++;
        }
      });

      setTasksOverdue(tempOverdue);
    }
  }, [tasks]);

  /*
  |--------------------------------------------------------------------------
  | Get number of overdue reviews
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (reviews.length) {
      const overdueReviews = reviews.filter((review) =>
        isDeliverableOverdue(review),
      );

      setReviewsOverdue(overdueReviews.length);
    }
  }, [reviews]);

  /*
  |--------------------------------------------------------------------------
  | Get number of hours remaining for this week
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (projects.length) {
      const thisWeekStart = startOfWeek(currentDate);
      const thisWeekEnd = endOfWeek(currentDate);

      let remainingHours = 0;

      projects.forEach((project) => {
        let projectWeekTracked = 0;
        let projectWeekTotal = 0;

        // sum hours for the time entries
        project.project.entries.forEach((entry) => {
          // convert it to local time
          const spentDate = new Date(`${entry.spent_date}T00:01`);

          const thisWeek =
            spentDate >= thisWeekStart && spentDate <= thisWeekEnd;

          // only for this week
          if (thisWeek) {
            projectWeekTracked += entry.rounded_hours;
          }
        });

        // sum hours for the tasks
        project.project.tasks.forEach((task) => {
          const dueThisWeek =
            new Date(task.date) >= thisWeekStart &&
            new Date(task.date) <= thisWeekEnd;

          // only for those due this week
          if (dueThisWeek) {
            projectWeekTotal += task.hoursToComplete;
          }
        });

        // amount of hours remaining that need to be spent
        const difference = projectWeekTotal - projectWeekTracked;
        // don't allow a negative number if more time has been tracked than was budgetted in the tasks
        const remaining = difference < 0 ? 0 : difference;

        // add to the total remaining hours
        remainingHours += remaining;
      });

      // round down to the nearest 15min interval
      const remaining = roundDownTo(remainingHours, 0.25);

      setWeekHoursRemaining(remaining);
    }
  }, [projects]);

  /*
  |--------------------------------------------------------------------------
  | Sort the table
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    let sortedItems;

    if (sortBy) {
      if (sortBy.includes("tasks")) {
        sortedItems = [...tasks];
      } else if (sortBy.includes("projects")) {
        sortedItems = [...projects];
      } else if (sortBy.includes("reviews")) {
        sortedItems = [...filteredReviews];
      }

      if (sortBy === "tasks-title-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-title-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-member-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.memberId ? a.memberId.handle.toUpperCase() : 0;
          var textB = b.memberId ? b.memberId.handle.toUpperCase() : 0;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-member-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.memberId ? a.memberId.handle.toUpperCase() : 0;
          var textB = b.memberId ? b.memberId.handle.toUpperCase() : 0;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-client-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId ? a.projectId.client.name.toUpperCase() : 0;
          var textB = b.projectId ? b.projectId.client.name.toUpperCase() : 0;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-client-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId ? a.projectId.client.name.toUpperCase() : 0;
          var textB = b.projectId ? b.projectId.client.name.toUpperCase() : 0;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-project-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId ? a.projectId.name.toUpperCase() : 0;
          var textB = b.projectId ? b.projectId.name.toUpperCase() : 0;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-project-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId ? a.projectId.name.toUpperCase() : 0;
          var textB = b.projectId ? b.projectId.name.toUpperCase() : 0;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-hours-asc") {
        sortedItems.sort(function(a, b) {
          var textA =
            a.hoursToComplete && !isNaN(a.hoursToComplete)
              ? a.hoursToComplete
              : a.hoursToComplete && isNaN(a.hoursToComplete)
              ? -1
              : 0;
          var textB =
            b.hoursToComplete && !isNaN(b.hoursToComplete)
              ? b.hoursToComplete
              : b.hoursToComplete && isNaN(b.hoursToComplete)
              ? -1
              : 0;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-hours-desc") {
        sortedItems.sort(function(a, b) {
          var textA =
            a.hoursToComplete && !isNaN(a.hoursToComplete)
              ? a.hoursToComplete
              : a.hoursToComplete && isNaN(a.hoursToComplete)
              ? -1
              : 0;
          var textB =
            b.hoursToComplete && !isNaN(b.hoursToComplete)
              ? b.hoursToComplete
              : b.hoursToComplete && isNaN(b.hoursToComplete)
              ? -1
              : 0;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "tasks-date-asc") {
        sortedItems.sort(function(a, b) {
          // If one of the dates is missing, we push it to the end
          if (!a.date) return 1;
          if (!b.date) return -1;
          return new Date(a.date) - new Date(b.date);
        });
      } else if (sortBy === "tasks-date-desc") {
        sortedItems.sort(function(a, b) {
          // If one of the dates is missing, we push it to the end
          if (!b.date) return 1;
          if (!a.date) return -1;
          return new Date(b.date) - new Date(a.date);
        });
      } else if (sortBy === "projects-name-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.project.name;
          var textB = b.project.name;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "projects-name-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.project.name;
          var textB = b.project.name;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "projects-hours-asc") {
        sortedItems.sort(function(a, b) {
          var textA = getHours(a) / a.weekBudget || 0;
          if (!a.weekBudget) {
            textA = -1;
          }
          var textB = getHours(b) / b.weekBudget || 0;
          if (!b.weekBudget) {
            textB = -1;
          }
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "projects-hours-desc") {
        sortedItems.sort(function(a, b) {
          var textA = getHours(a) / a.weekBudget || 0;
          if (!a.weekBudget) {
            textA = -1;
          }
          var textB = getHours(b) / b.weekBudget || 0;
          if (!b.weekBudget) {
            textB = -1;
          }
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-title-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-title-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.title.toUpperCase();
          var textB = b.title.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-status-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.status?.toUpperCase();
          var textB = b.status?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-status-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.status?.toUpperCase();
          var textB = b.status?.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-client-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId?.client?.name?.toUpperCase();
          var textB = b.projectId?.client?.name?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-client-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId?.client?.name?.toUpperCase();
          var textB = b.projectId?.client?.name?.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-project-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId?.name?.toUpperCase();
          var textB = b.projectId?.name?.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-project-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.projectId?.name?.toUpperCase();
          var textB = b.projectId?.name?.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-date-asc") {
        sortedItems.sort(function(a, b) {
          var textA = a.dueDate;
          var textB = b.dueDate;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-date-desc") {
        sortedItems.sort(function(a, b) {
          var textA = a.dueDate;
          var textB = b.dueDate;
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
      } else if (sortBy === "reviews-shipBy-asc") {
        sortedItems.sort(function(a, b) {
          const shipByA = a.shipBy?.length
            ? getStandardizedDate(a.shipBy[a.shipBy.length - 1].date).getTime()
            : 0;
          const shipByB = b.shipBy?.length
            ? getStandardizedDate(b.shipBy[b.shipBy.length - 1].date).getTime()
            : 0;

          return shipByA < shipByB ? -1 : shipByA > shipByB ? 1 : 0;
        });
      } else if (sortBy === "reviews-shipBy-desc") {
        sortedItems.sort(function(a, b) {
          const shipByA = a.shipBy?.length
            ? getStandardizedDate(a.shipBy[a.shipBy.length - 1].date).getTime()
            : 0;
          const shipByB = b.shipBy?.length
            ? getStandardizedDate(b.shipBy[b.shipBy.length - 1].date).getTime()
            : 0;

          return shipByA > shipByB ? -1 : shipByA < shipByB ? 1 : 0;
        });
      } else if (sortBy === "reviews-reviewBy-asc") {
        sortedItems.sort(function(a, b) {
          const reviewByA = a.reviewBy?.length
            ? getStandardizedDate(
                a.reviewBy[a.reviewBy.length - 1].date,
              ).getTime()
            : 0;
          const reviewByB = b.reviewBy?.length
            ? getStandardizedDate(
                b.reviewBy[b.reviewBy.length - 1].date,
              ).getTime()
            : 0;

          return reviewByA < reviewByB ? -1 : reviewByA > reviewByB ? 1 : 0;
        });
      } else if (sortBy === "reviews-reviewBy-desc") {
        sortedItems.sort(function(a, b) {
          const reviewByA = a.reviewBy?.length
            ? getStandardizedDate(
                a.reviewBy[a.reviewBy.length - 1].date,
              ).getTime()
            : 0;
          const reviewByB = b.reviewBy?.length
            ? getStandardizedDate(
                b.reviewBy[b.reviewBy.length - 1].date,
              ).getTime()
            : 0;

          return reviewByA > reviewByB ? -1 : reviewByA < reviewByB ? 1 : 0;
        });
      }

      if (sortBy.includes("tasks")) {
        setTasks(sortedItems);
      } else if (sortBy.includes("projects")) {
        setProjects(sortedItems);
      } else if (sortBy.includes("reviews")) {
        setFilteredReviews(sortedItems);
      }
    }
  }, [sortBy]);

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  useEffect(() => {
    if (dailyCompletions?.length) {
      const timeCompletion = dailyCompletions.find(
        (completion) => completion.name === "time",
      );

      if (timeCompletion) {
        const remaining = roundDownTo(
          timeCompletion.goal - timeCompletion.progress,
          0.25,
        );

        setTodayHoursRemaining(remaining);
      }
    }
  }, [dailyCompletions]);

  /*
  |--------------------------------------------------------------------------
  | Get logged hours for each task
  |--------------------------------------------------------------------------
  */
  const getHours = (project, today) => {
    let totalHours = 0;

    let timeEntries = project.project.entries.filter((item) => {
      // convert spent_date string into date
      const spentDate = getStandardizedDate(item.spent_date);
      // get the week for this entry
      const entryWeekNumber = getWeek(spentDate);

      // only show it if its the same week number and correct user
      return entryWeekNumber === weekNumber && item.user.id === user.harvestId;
    });

    // Only get today's entries
    if (today) {
      timeEntries = timeEntries.filter((item) => {
        const spentDate = getStandardizedDate(item.spent_date);
        if (isToday(spentDate)) {
          return true;
        }
      });
    }

    timeEntries.forEach((time) => {
      totalHours += time.rounded_hours;
    });

    if (totalHours > 0) {
      totalHours = Math.ceil(totalHours * 4) / 4;
      totalHours = Math.round(totalHours * 100) / 100;
    }

    return totalHours;
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      let results = await getProjectsFromApi(
        user.email,
        format(currentDate, "yyyy-MM-dd"),
      );

      results = results.data.data;

      const tempProjects = results.projects.map((project) => {
        let weekBudget = 0;

        project.bookingPlan.forEach((plan) => {
          plan.weeks.forEach((item) => {
            const planWeekNumber = getWeek(getStandardizedDate(item.weekDate));

            const planYear = new Date(
              getStandardizedDate(item.weekDate),
            ).getFullYear();

            if (planWeekNumber === weekNumber && planYear === currentYear) {
              weekBudget += item.hours;
            }
          });
        });

        // Rounds the number if it has more than 2 decimal places
        weekBudget = Math.round((weekBudget + Number.EPSILON) * 100) / 100;

        return {
          weekBudget,
          project,
        };
      });

      // let filteredProjects = tempProjects.filter((project) => {
      //   let show = false;

      //   if (project.project.bookingPlan) {
      //     project.project.bookingPlan.forEach((plan) => {
      //       plan.weeks.forEach((item) => {
      //         const planWeekNumber = getWeek(
      //           getStandardizedDate(item.weekDate),
      //         );
      //         if (planWeekNumber === weekNumber) {
      //           show = true;
      //         }
      //       });
      //     });
      //   }

      //   return show ? project : false;
      // });

      let filteredProjects = [...tempProjects];

      // For each project, grab recaps that were created today and download the file
      // If there are notes grab the notes instead
      filteredProjects = await Promise.all(
        filteredProjects.map(async (project) => {
          // Only get files if they were recorded today
          const filteredRecaps = project.project.recaps?.filter((recap) => {
            if (isToday(new Date(recap.date)) && recap.owner === user._id) {
              return true;
            }

            return false;
          });

          if (filteredRecaps && filteredRecaps.length) {
            if (filteredRecaps[filteredRecaps.length - 1].file) {
              const downloadedVideo = await downloadFileFromApi(
                filteredRecaps[filteredRecaps.length - 1].file,
              );

              project.file = filteredRecaps[filteredRecaps.length - 1];
              project.downloadedVideo = downloadedVideo;
              project.view = "video";
            } else if (filteredRecaps[filteredRecaps.length - 1].notes) {
              project.notes = filteredRecaps[filteredRecaps.length - 1].notes;
              project.view = "notes";
            }
          } else {
            project.view = "video";
          }
          return project;
        }),
      );

      const tempCurrentProjects = filteredProjects.filter((project) => {
        let isToday = false;

        project.project.entries.forEach((entry) => {
          if (isSameDay(getSafariDate(entry.spent_date), new Date())) {
            isToday = true;
          }
        });

        return isToday;
      });

      // getFiles(filteredProjects);
      setCurrentProjects(tempCurrentProjects);
      setProjects(filteredProjects);
      setLoading(false);
    } catch (error) {
      console.error("Error getting projects", error);
    }
  };

  const getDailyCompletions = async () => {
    try {
      const newDailyCompletionsArray = await getTodayCompletionsFromApi({
        member: user._id,
      });

      setDailyCompletions(newDailyCompletionsArray[0].completions);
    } catch (error) {
      console.error("error getting daily completion", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get Team Tasks
  |--------------------------------------------------------------------------
  */
  const getTeamTasks = async () => {
    try {
      if (!teamTasks.length) {
        const teamTasksFromApi = await getTeamTasksFromApi();

        const incompleteTeamTasks = teamTasksFromApi.filter(
          (task) => task.status !== "Completed",
        );

        incompleteTeamTasks.sort(function(a, b) {
          var dateA = a.date;
          var dateB = b.date;
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        });

        setTeamTasks(incompleteTeamTasks);
        setTasks(incompleteTeamTasks);
      } else {
        setTasks(teamTasks);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error getting tasks", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Tasks
  |--------------------------------------------------------------------------
  */
  const getTasks = async (userId) => {
    try {
      if (!userTasks.length) {
        const [
          tasksFromApi,
          deliverables,
          reviewDeliverables,
        ] = await Promise.all([
          getAllTasksFromApi({
            memberId: userId,
            projects: true,
            hideArchived: true,
          }),
          getDeliverablesFromApi(),
          getDeliverablesToReviewFromApi(),
        ]);

        const incompleteTasks = tasksFromApi.filter(
          (task) => task.status !== "Completed",
        );

        deliverables.forEach((deliverable) => {
          if (deliverable.shipBy?.length) {
            deliverable.date =
              deliverable.shipBy[deliverable.shipBy.length - 1].date;
          }
          incompleteTasks.push(deliverable);
        });

        // sort by date
        incompleteTasks.sort(function(a, b) {
          var dateA = a.date;
          var dateB = b.date;
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        });

        reviewDeliverables.sort(function(a, b) {
          var dateA = a.dueDate;
          var dateB = b.dueDate;
          return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        });

        setUserTasks(incompleteTasks);
        setTasks(incompleteTasks);
        setReviews(reviewDeliverables);

        filterReviews(reviewDeliverables, "priority");
      } else {
        setTasks(userTasks);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error getting tasks", error);
    }
  };

  const filterReviews = (allReviews, type) => {
    let reviewDeliverables = [...allReviews];

    // Show all the items that are overdue
    if (type === "priority") {
      reviewDeliverables = reviewDeliverables.filter((review) => {
        const isPriority = isDeliverableOverdue(review);

        return isPriority;
      });
    }

    setFilteredReviews(reviewDeliverables);
  };

  const isDeliverableOverdue = (deliverable) => {
    const dueDate = deliverable.dueDate
      ? getStandardizedDate(deliverable.dueDate)
      : null;
    const shipBy = deliverable.shipBy?.length
      ? getStandardizedDate(
          deliverable.shipBy[deliverable.shipBy.length - 1].date,
        )
      : null;
    const reviewBy = deliverable.reviewBy?.length
      ? getStandardizedDate(
          deliverable.reviewBy[deliverable.reviewBy.length - 1].date,
        )
      : null;

    // check shipBy or reviewBy. fallback to legacy dueDate if neither exist
    const datesToCheck = shipBy || reviewBy ? [shipBy, reviewBy] : [dueDate];

    let isOverdue = false;

    datesToCheck.forEach((thisDate) => {
      // if date is in past and isn't today
      if (
        thisDate &&
        isPast(thisDate) &&
        !isSameDay(currentDate, getStandardizedDate(thisDate))
      ) {
        isOverdue = true;
      }
    });

    return isOverdue;
  };

  /*
  |--------------------------------------------------------------------------
  | Get All KPIs
  |--------------------------------------------------------------------------
  */
  const getKpis = async (userId) => {
    try {
      let results = await getKpisFromApi(userId, true);

      results = results.filter((kpi) => {
        return day >= 5
          ? kpi.frequency === "Weekly"
          : kpi.frequency === "Daily";
      });

      setKpis(results);
    } catch (error) {
      console.error("Error getting KPIs");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Save recap
  |--------------------------------------------------------------------------
  */
  const saveRecap = async (index, type, notes) => {
    try {
      setLoading(true);
      const tempProjects = [...currentProjects];

      // ? server will set the date automatically
      const recap = { owner: user._id };

      if (type === "video") {
        if (tempProjects[index].file && !tempProjects[index].downloadedVideo) {
          const upload = await uploadFileToApi(
            tempProjects[index].file,
            user._id,
            {
              name: `${format(
                getStandardizedDate(new Date()),
                "yyyyMMdd",
              )}-${tempProjects[index].project.code ||
                tempProjects[index].project.name}`,
              project: tempProjects[index].project._id,
              category: "recaps",
            },
          );

          const downloadedVideo = await downloadFileFromApi(upload._id);
          tempProjects[index].file = upload;
          tempProjects[index].downloadedVideo = downloadedVideo;

          recap.file = upload._id;
        }
      } else if (type === "notes") {
        tempProjects[index].notes = notes;
        recap.notes = notes;
      }

      const projectData = {
        projectId: tempProjects[index].project._id,
        recap,
      };

      const updatedData = await createRecapOnApi(projectData);
      tempProjects[index].project.recaps = updatedData.recaps;

      setProjects(tempProjects);
      setLoading(false);
      openAlertPopup("Success", `Your recap has been successfully saved`, true);
    } catch (err) {
      console.error("Error with saving recap", err);
      openAlertPopup(
        "Error",
        "Something went wrong, and your recap couldn't be saved",
        true,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Submit day info
  |--------------------------------------------------------------------------
  */
  const finishDay = async (submittedKpis) => {
    try {
      setLoading(true);

      if (submittedKpis && submittedKpis.length) {
        await Promise.all(
          submittedKpis.forEach(async (kpi) => {
            const results = kpi.results;

            results.push({
              date: new Date(),
              value: kpi.amount,
            });

            const data = {
              id: kpi._id,
              results,
            };

            await updateKpiOnApi(data);
          }),
        );
      }

      const projectData = {
        projects: currentProjects,
      };

      await finishDayOnApi(projectData);

      setLoading(false);
      setModalOpen(false);
      setFaviconEnabled(false);

      // If today is friday, we also show the finish week modal
      if (day >= 5) {
        openModal(
          <FinishWeekModal
            finishWeek={() => {
              // Opens the Harvest timesheet page so user can submit
              window.open(
                process.env.REACT_APP_HARVEST_TIMESHEET_URL,
                "_blank",
              );
              // Closes finish week modal
              closeModal();
              // Stores the day completion
              store.set("dayFinished", new Date());
              openAlertPopup("Success", `Your week is finished!`, true);
            }}
            close={closeModal}
          />,
        );
        // If today is friday, we just complete the finish stuff as normal
      } else {
        openAlertPopup(
          "Success",
          `Your day is finished and the timer alert will now be disabled...temporarily.`,
          true,
        );
        store.set("dayFinished", new Date());
      }
    } catch (err) {
      console.error("Error Finishing day", err);
      openAlertPopup(
        "Error",
        "Something went wrong, and your day couldn't be finished",
        true,
      );
    }
  };

  // Takes user to a project on click
  const goToProject = (projectId, tasks) => {
    // Starts timer if a timer isn't already running
    if (!isTimerRunning) {
      startTimer(projectId);
    }

    // Sends them to the project view
    // If a timer is already running, the stop modal will deal with that on project view
    history.push(`/projects/${projectId}${tasks ? "/tasks" : ""}`);
  };

  return (
    <Container>
      <Columns>
        <MessageColumn height="small">
          <MessageSection name={user.handle} />
        </MessageColumn>
        <FinishDayColumn height="small">
          <FinishSection
            completions={dailyCompletions}
            day={day}
            hasFinished={dayFinished}
            handleFinishClick={() => {
              setModalOpen(true);
            }}
          />
        </FinishDayColumn>
      </Columns>

      <Columns>
        <Column>
          <DashboardInbox />
        </Column>

        <Column>
          <ColumnHeader>
            <ColumnLeft>
              <TaskIcon />
              <ColumnTitle style={{ marginRight: "30px" }}>
                My Reviews
              </ColumnTitle>

              {viewAllReviews ? (
                <DefaultLink
                  style={{ marginTop: "3px" }}
                  onClick={() => {
                    setViewAllReviews(false);
                    filterReviews(reviews, "priority");
                  }}
                >
                  View Priority Reviews
                </DefaultLink>
              ) : (
                <DefaultLink
                  style={{ marginTop: "3px" }}
                  onClick={() => {
                    setViewAllReviews(true);
                    filterReviews(reviews);
                  }}
                >
                  View All Reviews
                </DefaultLink>
              )}
            </ColumnLeft>

            <ColumnRight>
              <NotificationTitle>Overdue</NotificationTitle>
              <NotificationCounter warning={reviewsOverdue > 0}>
                {reviewsOverdue}
              </NotificationCounter>
            </ColumnRight>
          </ColumnHeader>

          {reviews.length ? (
            <Table id="tasksTable">
              <thead>
                <tr>
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-title")}
                    isArrowUp={sortBy === "reviews-title-desc"}
                  >
                    Deliverable
                  </TableHeader>
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-status")}
                    isArrowUp={sortBy === "reviews-status-desc"}
                  >
                    Status
                  </TableHeader>
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-client")}
                    isArrowUp={sortBy === "reviews-client-desc"}
                  >
                    Client
                  </TableHeader>
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-project")}
                    isArrowUp={sortBy === "reviews-project-desc"}
                  >
                    Project
                  </TableHeader>
                  {/* <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-date")}
                    isArrowUp={sortBy === "reviews-date-desc"}
                  >
                    Due Date
                  </TableHeader> */}
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-reviewBy")}
                    isArrowUp={sortBy === "reviews-reviewBy-desc"}
                  >
                    Review By
                  </TableHeader>
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("reviews-shipBy")}
                    isArrowUp={sortBy === "reviews-shipBy-desc"}
                  >
                    Ship By
                  </TableHeader>
                </tr>
              </thead>
              <tbody>
                {filteredReviews.map((review, index) => {
                  const shipBy = review.shipBy?.length
                    ? getStandardizedDate(
                        review.shipBy[review.shipBy.length - 1].date,
                      )
                    : null;
                  const reviewBy = review.reviewBy?.length
                    ? getStandardizedDate(
                        review.reviewBy[review.reviewBy.length - 1].date,
                      )
                    : null;

                  return (
                    <TableRow key={index}>
                      <TableCell align="left">{review.title}</TableCell>
                      <TableCell align="left">
                        {review.status ? review.status : "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {review.projectId ? (
                          <ClientLink
                            name={review.projectId.client.name}
                            id={review.projectId.client._id}
                          />
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {review.projectId ? (
                          <SimpleLink
                            onClick={() => goToProject(review.projectId._id)}
                          >
                            {review.projectId.name}
                          </SimpleLink>
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      {/* <TableCell
                      align="left"
                      style={{ whiteSpace: "nowrap", paddingRight: "0px" }}
                    >
                      <DueDate
                        overdue={
                          !isSameDay(
                            currentDate,
                            getStandardizedDate(review.dueDate),
                          ) && isPast(getStandardizedDate(review.dueDate))
                        }
                      >
                        {review.date
                          ? format(
                              getStandardizedDate(review.dueDate),
                              "MM-dd-yyyy",
                            )
                          : "N/A"}
                      </DueDate>
                    </TableCell> */}
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        <DueDate
                          overdue={
                            reviewBy &&
                            !isSameDay(currentDate, reviewBy) &&
                            isPast(reviewBy)
                          }
                        >
                          {reviewBy ? format(reviewBy, "MM-dd-yyyy") : "N/A"}
                        </DueDate>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ whiteSpace: "nowrap", paddingRight: "0px" }}
                      >
                        <DueDate
                          overdue={
                            shipBy &&
                            !isSameDay(currentDate, shipBy) &&
                            isPast(shipBy)
                          }
                        >
                          {shipBy ? format(shipBy, "MM-dd-yyyy") : "N/A"}
                        </DueDate>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </Column>
      </Columns>

      {projects?.length ? (
        <FinishModal
          //kpis={kpis}
          currentProjects={currentProjects}
          setCurrentProjects={setCurrentProjects}
          getHours={getHours}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          finishDay={finishDay}
          saveRecap={saveRecap}
        />
      ) : null}

      <Column mode="single">
        <ColumnHeader>
          <ColumnLeft>
            <TaskIcon />
            <ColumnTitle style={{ marginRight: "30px" }}>My Tasks</ColumnTitle>
            {viewTeam ? (
              <DefaultLink
                style={{ marginTop: "3px" }}
                onClick={() => {
                  setLoading(true);
                  setViewTeam(false);
                  getTasks(user._id);
                }}
              >
                Default View
              </DefaultLink>
            ) : (
              <DefaultLink
                style={{ marginTop: "3px" }}
                onClick={() => {
                  setLoading(true);
                  setViewTeam(true);
                  getTeamTasks();
                }}
              >
                View Team
              </DefaultLink>
            )}
          </ColumnLeft>

          <ColumnRight>
            <NotificationTitle>Overdue</NotificationTitle>
            <NotificationCounter warning={tasksOverdue > 0}>
              {tasksOverdue}
            </NotificationCounter>
          </ColumnRight>
        </ColumnHeader>

        {tasks.length ? (
          <Table id="tasksTable">
            <thead>
              <tr>
                {viewTeam && !loading ? (
                  <TableHeader
                    align="left"
                    onClick={() => changeSort("tasks-member")}
                    isArrowUp={sortBy === "tasks-member-desc"}
                  >
                    Member
                  </TableHeader>
                ) : null}
                <TableHeader
                  align="left"
                  onClick={() => changeSort("tasks-title")}
                  isArrowUp={sortBy === "tasks-title-desc"}
                >
                  Name
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("tasks-client")}
                  isArrowUp={sortBy === "tasks-client-desc"}
                >
                  Client
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("tasks-project")}
                  isArrowUp={sortBy === "tasks-project-desc"}
                >
                  Project
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("tasks-hours")}
                  isArrowUp={sortBy === "tasks-hours-desc"}
                >
                  Hours
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("tasks-date")}
                  isArrowUp={sortBy === "tasks-date-desc"}
                >
                  Start +<br />
                  Due Date
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, index) => (
                <TableRow key={index}>
                  {viewTeam && !loading ? (
                    <TableCell align="left">
                      {task.memberId && task.memberId.handle}
                    </TableCell>
                  ) : null}
                  <TableCell align="left">{task.title}</TableCell>
                  <TableCell align="left">
                    {task.projectId?.client ? (
                      <ClientLink
                        name={task.projectId.client.name}
                        id={task.projectId.client._id}
                      />
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {task.projectId ? (
                      <SimpleLink
                        onClick={() => goToProject(task.projectId._id, true)}
                      >
                        {task.projectId.name}
                      </SimpleLink>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell align="left" className="small">
                    {task.hoursToComplete ? task.hoursToComplete : 0}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ whiteSpace: "nowrap", paddingRight: "0px" }}
                  >
                    <StartDate>
                      {task.week
                        ? format(getStandardizedDate(task.week), "MM-dd-yyyy")
                        : "Backlog"}
                    </StartDate>
                    <DueDate
                      overdue={
                        !isSameDay(
                          currentDate,
                          getStandardizedDate(task.date),
                        ) && isPast(getStandardizedDate(task.date))
                      }
                    >
                      {task.date
                        ? format(getStandardizedDate(task.date), "MM-dd-yyyy")
                        : "Backlog"}
                    </DueDate>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        ) : null}
      </Column>

      <Column mode="single" height="full">
        <ColumnHeader>
          <ColumnLeft>
            <ClockIcon />
            <ColumnTitle>My Time</ColumnTitle>
          </ColumnLeft>

          <ColumnMiddle>
            <IconContainer
              active={view === "grid"}
              onClick={() => setView("grid")}
            >
              <GridViewIcon />
            </IconContainer>
            <IconContainer
              active={view === "list"}
              onClick={() => setView("list")}
            >
              <ListViewIcon />
            </IconContainer>
          </ColumnMiddle>

          <ColumnRight
            style={{ flexDirection: "column", alignItems: "flex-end" }}
          >
            <NotificationRow>
              <NotificationTitle>Hours Remaining Today</NotificationTitle>

              <NotificationCounter
                warning={todayHoursRemaining > 0}
                small={todayHoursRemaining.toString().length >= 3}
              >
                {todayHoursRemaining > 0 ? todayHoursRemaining : 0}
              </NotificationCounter>
            </NotificationRow>

            <NotificationRow>
              <NotificationTitle>Hours Remaining This Week</NotificationTitle>
              <NotificationCounter
                small={weekHoursRemaining.toString().length >= 3}
              >
                {weekHoursRemaining > 0 ? weekHoursRemaining : 0}
              </NotificationCounter>
            </NotificationRow>
          </ColumnRight>
        </ColumnHeader>

        {projects.length && view === "list" ? (
          <Table id="projectsTable">
            <thead>
              <tr>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("projects-name")}
                  isArrowUp={sortBy === "projects-name-desc"}
                >
                  Project
                </TableHeader>
                <TableHeader
                  align="left"
                  onClick={() => changeSort("projects-hours")}
                  isArrowUp={sortBy === "projects-hours-desc"}
                >
                  Billed This Week
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {projects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    style={{ whiteSpace: "nowrap", paddingRight: "100px" }}
                  >
                    <DefaultLink
                      onClick={() => goToProject(project.project._id)}
                    >
                      {project.project.code
                        ? project.project.code.toUpperCase()
                        : project.project.name}
                    </DefaultLink>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ whiteSpace: "nowrap", width: "100%" }}
                  >
                    <ProgressContainer>
                      <ProgressBar
                        style={{
                          marginBottom: "0px",
                          width: "calc(100% - 150px)",
                        }}
                        pace={weekPace}
                        actual={getHours(project)}
                        budgeted={project.weekBudget}
                      />

                      <ProgressHours>
                        {getHours(project)} / {project.weekBudget} hrs
                      </ProgressHours>
                    </ProgressContainer>
                  </TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        ) : (
          <Dashboard />
        )}
      </Column>
    </Container>
  );
};

const Container = styled.div`
  padding: 40px 40px 20px 40px;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px -20px;
`;

const Column = styled.div`
  transition: box-shadow 0.2s ease-in-out;
  box-shadow: none;
  border: 1px solid ${rgba("#b7bec6", 0.5)};
  border-radius: 4px;
  flex: 1;
  padding: 10px 40px 40px 40px;
  margin: ${(props) =>
    props.mode === "single" ? "0px 0px 50px 0px" : "0px 20px"};
  position: relative;
  height: ${(props) =>
    props.height === "full"
      ? "auto"
      : props.height === "small"
      ? "150px"
      : "770px"};
  resize: ${(props) => (props.height === "small" ? "none" : "vertical")};
  overflow-y: auto;

  &:hover {
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  }
`;

const MessageColumn = styled(Column)`
  border: none;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 20px 40px;

  color: ${(props) => props.theme.colors.darkSlate};

  &:hover {
    box-shadow: none;
  }
`;

const FinishDayColumn = styled(Column)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  overflow: visible;
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 30px;
`;

const ColumnLeft = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 35px;
  }

  path {
    fill: #554668;
  }
`;

const ColumnTitle = styled.h2`
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 30px;
  font-size: 20px;
`;

const ColumnRight = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationTitle = styled.p`
  color: #b7bec6;
  font-weight: 500;
  margin-bottom: 0px;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const NotificationCounter = styled.div`
  background-color: ${(props) => (props.warning ? "#df5757" : "#0079FF00")};
  color: ${(props) => (props.warning ? "white" : "#b7bec6")};
  border-radius: 50%;
  height: 35px;
  width: 35px;
  line-height: 1;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: 700;
  font-size: ${(props) => (props.small ? "12px" : "16px")};

  ${respondTo("xlarge")} {
    font-size: ${(props) => (props.small ? "14px" : "16px")};
  }
`;

const NotificationRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Table = styled.table`
  position: relative;
  width: 100%;
  margin-bottom: 0px;

  th,
  td {
    padding-left: 0px;
    padding-right: 15px;
    font-size: 14px;
  }

  th {
    line-height: 1.2;
    padding-bottom: 20px;
    vertical-align: bottom;
    /* background-color: white; */
    /* position: sticky;
    top: 0;
    padding-top: 20px; */

    &:last-of-type {
      padding-right: 0px;
    }
  }

  td {
    width: auto;
    padding: 20px 15px 20px 0px;

    &:first-of-type {
      padding-right: 15px;
    }
  }

  .hours {
    width: 1%;
    white-space: nowrap;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProgressHours = styled.p`
  margin: 0px;
`;

const StartDate = styled.p`
  margin-bottom: 0px;
`;

const DueDate = styled.p`
  color: ${(props) => (props.overdue ? "#df5757" : "#373040")};
  margin: 0px;
  font-weight: 700;
`;

const IconContainer = styled.div`
  padding: 15px;
  display: flex;

  ${(props) =>
    props.active
      ? css`
          rect {
            fill: ${(props) => props.theme.colors.oldBlack2};
          }
        `
      : ``}
`;

const ColumnMiddle = styled.div`
  display: flex;
  align-items: center;

  ${IconContainer}:first-of-type {
    border-right: 1px solid #e1e0e4;
  }
`;

export default Home;
