import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import SimpleLink from "../../components/links/SimpleLink";
import { Field, Label, Input } from "../../components/Form";
import Button from "../../components/buttons/Button";
import Toggle, { ToggleContainer } from "../../components/Toggle";
import SelectDropdown from "../../components/SelectDropdown";

import { getCompaniesFromApi } from "../../utils/api";
import {
  getAllProjectsFromApi,
  getTeamsFromApi,
  getAccessLevelFromApi,
  updateAccessLevelOnApi,
} from "./utils/api";

const AccessLevelCreate = () => {
  const { id } = useParams();
  const { openAlertPopup, setLoading } = useNotifications();

  const [accessLevel, setAccessLevel] = useState(null);
  const [name, setName] = useState("");
  const [clients, setClients] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [viewClients, setViewClients] = useState(false);
  const [modifyClients, setModifyClients] = useState(false);
  const [projects, setProjects] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [viewProjects, setViewProjects] = useState(false);
  const [modifyProjects, setModifyProjects] = useState(false);
  const [teams, setTeams] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [viewTeams, setViewTeams] = useState(false);
  const [modifyTeams, setModifyTeams] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [modifyUsers, setModifyUsers] = useState(false);
  const [viewRates, setViewRates] = useState(false);
  const [modifyRates, setModifyRates] = useState(false);
  const [viewAccessLevels, setViewAccessLevels] = useState(false);
  const [modifyAccessLevels, setModifyAccessLevels] = useState(false);
  const [viewTasks, setViewTasks] = useState(false);
  const [modifyTasks, setModifyTasks] = useState(false);
  const [viewBookingReports, setViewBookingReports] = useState(false);
  const [viewSalesReports, setViewSalesReports] = useState(false);
  const [viewAdminDashboard, setViewAdminDashboard] = useState(false);

  /*
  |--------------------------------------------------------------------------
  | Edit the access level upon submission
  |--------------------------------------------------------------------------
  */
  const editAccessLevel = async (e) => {
    e.preventDefault();

    let tempClients, tempProjects, tempTeams;

    // Only pass up the id's for the selected clients, projects, teams array
    if (selectedClients) {
      tempClients = [...selectedClients];

      tempClients = tempClients.map((client) => {
        return client.value;
      });
    } else {
      tempClients = [];
    }
    if (selectedProjects) {
      tempProjects = [...selectedProjects];

      tempProjects = tempProjects.map((project) => {
        return project.value;
      });
    } else {
      tempProjects = [];
    }
    if (selectedTeams) {
      tempTeams = [...selectedTeams];

      tempTeams = tempTeams.map((team) => {
        return team.value;
      });
    } else {
      tempTeams = [];
    }

    const data = {
      accessId: id,
      name,
      clients: {
        view: viewClients,
        modify: modifyClients,
      },
      specificClients: tempClients,
      projects: {
        view: viewProjects,
        modify: modifyProjects,
      },
      specificProjects: tempProjects,
      teams: {
        view: viewTeams,
        modify: modifyTeams,
      },
      specificTeams: tempTeams,
      users: {
        view: viewUsers,
        modify: modifyUsers,
      },
      rates: {
        view: viewRates,
        modify: modifyRates,
      },
      accessLevels: {
        view: viewAccessLevels,
        modify: modifyAccessLevels,
      },
      bookingReports: {
        view: viewBookingReports,
      },
      salesReports: {
        view: viewSalesReports,
      },
      tasks: {
        view: viewTasks,
        modify: modifyTasks,
      },
      adminDashboard: {
        view: viewAdminDashboard,
      },
    };

    try {
      await updateAccessLevelOnApi(data);
      openAlertPopup("Success", `${name} has been edited.`, true);
    } catch (error) {
      console.error("error", error);
      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't edit the access level.`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get Access Level
  |--------------------------------------------------------------------------
  */
  const getAccessLevel = async () => {
    try {
      const result = await getAccessLevelFromApi(id);

      if (result) {
        setAccessLevel(result);
        setName(result.name);
        setViewClients(result.clients.view);
        setModifyClients(result.clients.modify);
        // Grab the client names for each client id
        if (result.specificClients?.length) {
          let tempClients = [...result.specificClients];
          tempClients = tempClients.map((selectedClient) => {
            const client = clients.find((item) => item._id === selectedClient);

            return {
              label: client.name,
              value: client._id,
            };
          });
          setSelectedClients(tempClients);
        }
        setViewProjects(result.projects.view);
        setModifyProjects(result.projects.modify);
        // Grab the project names for each project id
        if (result.specificProjects?.length) {
          let tempProjects = [...result.specificProjects];
          tempProjects = tempProjects.map((selectedProject) => {
            const project = projects.find(
              (item) => item._id === selectedProject,
            );

            return {
              label: project.name,
              value: project._id,
            };
          });
          setSelectedProjects(tempProjects);
        }
        setViewTeams(result.teams.view);
        setModifyTeams(result.teams.modify);
        // Grab the team names for each team id
        if (result.specificTeams?.length) {
          let tempTeams = [...result.specificTeams];
          tempTeams = tempTeams.map((selectedTeam) => {
            const team = teams.find((item) => item._id === selectedTeam);

            return {
              label: team.name,
              value: team._id,
            };
          });
          setSelectedTeams(tempTeams);
        }
        setViewUsers(result.users.view);
        setModifyUsers(result.users.modify);
        setViewRates(result.rates.view);
        setModifyRates(result.rates.modify);
        setViewAccessLevels(result.accessLevels.view);
        setModifyAccessLevels(result.accessLevels.modify);
        setViewTasks(result.tasks.view);
        setModifyTasks(result.tasks.modify);
        setViewBookingReports(result.bookingReports.view);
        setViewSalesReports(result.salesReports.view);
        setViewAdminDashboard(result.adminDashboard.view);
        return;
      }
    } catch (error) {
      console.error("Get access levels error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Clients
  |--------------------------------------------------------------------------
  */
  const getClients = async () => {
    try {
      const results = await getCompaniesFromApi();
      setClients(results);
      return;
    } catch (error) {
      console.error("Get clients error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      const results = await getAllProjectsFromApi();
      setProjects(results);
      return;
    } catch (error) {
      console.error("Get projects error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Teams
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    try {
      const results = await getTeamsFromApi();
      setTeams(results);
      return;
    } catch (error) {
      console.error("Get teams error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all api data for projects, clients, teams
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setLoading(true);
    getProjects();
    getClients();
    getTeams();
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get the access level once all the projects, clients, teams have loaded
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (projects?.length && clients?.length && teams?.length) {
      getAccessLevel();
    }
  }, [projects, clients, teams]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Once the access level is loaded, set loading to false
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (accessLevel) {
      setLoading(false);
    }
  }, [accessLevel]); //eslint-disable-line

  return (
    <Container>
      <HeaderSection>
        <Heading>Edit Access Level</Heading>
        <SimpleLink as={Link} to={`/manage/accessLevels/`}>
          Back to Access Levels
        </SimpleLink>
      </HeaderSection>
      {accessLevel ? (
        <EditSection onSubmit={editAccessLevel}>
          <Field style={{ width: "300px" }}>
            <Label>Name</Label>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Access Level Name"
              required
            />
          </Field>

          <PermissionsTitle>Permissions</PermissionsTitle>

          <FlexContainer>
            <LeftSection>
              <Field>
                <Label>Clients</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Clients"
                    checked={viewClients}
                    type="checkbox"
                    onChange={() => {
                      setViewClients(!viewClients);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Clients"
                    checked={modifyClients}
                    type="checkbox"
                    onChange={() => {
                      setModifyClients(!modifyClients);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Clients</Label>
                <SelectDropdown
                  placeholder="Select Client"
                  isMulti
                  value={selectedClients && selectedClients}
                  onChange={(e) => {
                    setSelectedClients(e);
                  }}
                  options={clients.map((client) => {
                    return {
                      label: client.name,
                      value: client._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Projects</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Projects"
                    checked={viewProjects}
                    type="checkbox"
                    onChange={() => {
                      setViewProjects(!viewProjects);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Projects"
                    checked={modifyProjects}
                    type="checkbox"
                    onChange={() => {
                      setModifyProjects(!modifyProjects);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Projects</Label>
                <SelectDropdown
                  placeholder="Select Project"
                  isMulti
                  onChange={(e) => {
                    setSelectedProjects(e);
                  }}
                  value={selectedProjects && selectedProjects}
                  options={projects.map((project) => {
                    return {
                      label: project.name,
                      value: project._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Teams</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Teams"
                    checked={viewTeams}
                    type="checkbox"
                    onChange={() => {
                      setViewTeams(!viewTeams);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Teams"
                    checked={modifyTeams}
                    type="checkbox"
                    onChange={() => {
                      setModifyTeams(!modifyTeams);
                    }}
                  />
                </ToggleContainer>
              </Field>
              <Field>
                <Label>Specific Teams</Label>
                <SelectDropdown
                  placeholder="Select Team"
                  isMulti
                  onChange={(e) => {
                    setSelectedTeams(e);
                  }}
                  value={selectedTeams && selectedTeams}
                  options={teams.map((team) => {
                    return {
                      label: team.name,
                      value: team._id,
                    };
                  })}
                />
              </Field>

              <Field>
                <Label>Users</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Users"
                    checked={viewUsers}
                    type="checkbox"
                    onChange={() => {
                      setViewUsers(!viewUsers);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Users"
                    checked={modifyUsers}
                    type="checkbox"
                    onChange={() => {
                      setModifyUsers(!modifyUsers);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Button type="submit">Edit Access Level</Button>
            </LeftSection>

            <RightSection>
              <Field>
                <Label>Rates</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Rates"
                    checked={viewRates}
                    type="checkbox"
                    onChange={() => {
                      setViewRates(!viewRates);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Rates"
                    checked={modifyRates}
                    type="checkbox"
                    onChange={() => {
                      setModifyRates(!modifyRates);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Access Levels</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Access Levels"
                    checked={viewAccessLevels}
                    type="checkbox"
                    onChange={() => {
                      setViewAccessLevels(!viewAccessLevels);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Access Levels"
                    checked={modifyAccessLevels}
                    type="checkbox"
                    onChange={() => {
                      setModifyAccessLevels(!modifyAccessLevels);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Tasks</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Tasks"
                    checked={viewTasks}
                    type="checkbox"
                    onChange={() => {
                      setViewTasks(!viewTasks);
                    }}
                  />
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    label="Modify All Tasks"
                    checked={modifyTasks}
                    type="checkbox"
                    onChange={() => {
                      setModifyTasks(!modifyTasks);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Booking Reports</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Booking Reports"
                    checked={viewBookingReports}
                    type="checkbox"
                    onChange={() => {
                      setViewBookingReports(!viewBookingReports);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Sales Reports</Label>
                <ToggleContainer>
                  <Toggle
                    label="View All Sales Reports"
                    checked={viewSalesReports}
                    type="checkbox"
                    onChange={() => {
                      setViewSalesReports(!viewSalesReports);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Admin Dashboard</Label>
                <ToggleContainer>
                  <Toggle
                    label="View Admin Dashboard"
                    checked={viewAdminDashboard}
                    type="checkbox"
                    onChange={() => {
                      setViewAdminDashboard(!viewAdminDashboard);
                    }}
                  />
                </ToggleContainer>
              </Field>
            </RightSection>
          </FlexContainer>
        </EditSection>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const HeaderSection = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0px;
`;

const PermissionsTitle = styled.h3`
  font-size: 22px;
  color: #373040;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 10px;
`;

const EditSection = styled.form``;

const FlexContainer = styled.div`
  display: flex;
`;

const LeftSection = styled.div`
  width: 300px;
  margin-right: 50px;
`;

const RightSection = styled.div`
  width: 300px;
`;

export default AccessLevelCreate;
