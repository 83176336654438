import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useRouteMatch, Link } from "react-router-dom";
import { motion } from "framer-motion";

import {
  getContractsFromApi,
  getContractBudgetDetailsFromApi,
} from "../../../../utils/api";
import { getCurrency } from "../../../../utils/helpers";

import useSort from "../../../../hooks/useSort";

import SimpleLink from "../../../../components/links/SimpleLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../../../components/Table";

const ClientContracts = ({ clientId }) => {
  const match = useRouteMatch();

  const [contracts, setContracts] = useState(null);
  const [openedSection, setOpenedSection] = useState("active");

  const tableHeadings = [
    {
      label: "Name",
      value: "name",
      type: "string",
    },
    {
      label: "SOW",
      value: "sow",
      type: "string",
    },
    {
      label: "Value",
      value: "budget",
      type: "number",
    },
    {
      label: "Remaining",
      value: "remaining",
      type: "number",
    },
    {
      label: "Status",
      value: "status",
      type: "string",
    },
  ];

  // TODO: in order to sort the "remaining" field, will need to handle it similar to the client projects table, where we pass up the "remaining" value to an array in a ref and use a custom sort function to sort via that array

  const [sortedContracts, sortBy, changeSort] = useSort(
    contracts,
    tableHeadings,
  );

  useEffect(() => {
    if (clientId) {
      getContracts(clientId);
    }
  }, [clientId]);

  const getContracts = async (id) => {
    try {
      const contractsFromApi = await getContractsFromApi({ company: id });

      // reverse so the newest ones are at the top
      contractsFromApi.reverse();

      setContracts(contractsFromApi);
    } catch (error) {
      console.error("error getting client contracts", error);
    }
  };

  const toggleSection = (newSection) => {
    if (openedSection === newSection) {
      setOpenedSection("");
    } else {
      setOpenedSection(newSection);
    }
  };

  const activeContracts = sortedContracts
    ? sortedContracts.filter(
        (contract) => contract.status === "Active Contract",
      )
    : [];

  const closedContracts = sortedContracts
    ? sortedContracts.filter(
        (contract) => contract.status === "Closed Contract",
      )
    : [];

  return (
    <>
      {sortedContracts ? (
        <>
          <Header>
            <Heading>Contracts</Heading>

            {/* <CreateLink as={Link} to={`${match.url}/contracts/new`}>
              Create Contract
            </CreateLink> */}
          </Header>

          {sortedContracts.length ? (
            <>
              <SectionContainer>
                <SectionHeader onClick={() => toggleSection("active")}>
                  <SectionHeaderTitle>Active</SectionHeaderTitle>{" "}
                </SectionHeader>
                <SectionContent
                  animate={openedSection === "active" ? "open" : "closed"}
                  variants={{
                    open: { height: "auto" },
                    closed: { height: 0 },
                  }}
                  transition={{ duration: 0.4 }}
                >
                  <Table>
                    <thead>
                      <tr>
                        {tableHeadings.map((heading) => (
                          <TableHeader
                            onClick={() => changeSort(heading.value)}
                            isArrowUp={sortBy === `${heading.value}-desc`}
                            align={
                              heading.value === "name" ? "left" : undefined
                            }
                            key={heading.value}
                          >
                            {heading.label}
                          </TableHeader>
                        ))}

                        <th>{/* Edit */}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeContracts.length ? (
                        activeContracts.map((contract) => (
                          <ContractRow
                            key={contract._id}
                            id={contract._id}
                            name={contract.name}
                            sow={contract.sow}
                            budget={contract.budget}
                            status={contract.status}
                            url={`${match.url}/contracts/${contract._id}`}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={tableHeadings.length}>
                            No active contracts for this client.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </SectionContent>
              </SectionContainer>

              <SectionContainer>
                <SectionHeader onClick={() => toggleSection("closed")}>
                  <SectionHeaderTitle>Closed</SectionHeaderTitle>{" "}
                </SectionHeader>
                <SectionContent
                  animate={openedSection === "closed" ? "open" : "closed"}
                  variants={{
                    open: { height: "auto" },
                    closed: { height: 0 },
                  }}
                  transition={{ duration: 0.4 }}
                >
                  <Table>
                    <thead>
                      <tr>
                        {tableHeadings.map((heading) => (
                          <TableHeader
                            onClick={() => changeSort(heading.value)}
                            isArrowUp={sortBy === `${heading.value}-desc`}
                            align={
                              heading.value === "name" ? "left" : undefined
                            }
                            key={heading.value}
                          >
                            {heading.label}
                          </TableHeader>
                        ))}

                        <th>{/* Edit */}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {closedContracts.length ? (
                        closedContracts.map((contract) => (
                          <ContractRow
                            key={contract._id}
                            id={contract._id}
                            name={contract.name}
                            sow={contract.sow}
                            budget={contract.budget}
                            status={contract.status}
                            url={`${match.url}/contracts/${contract._id}`}
                          />
                        ))
                      ) : (
                        <tr>
                          <td colSpan={tableHeadings.length}>
                            No closed contracts for this client.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </SectionContent>
              </SectionContainer>
            </>
          ) : (
            "No contracts have been created with this client."
          )}
        </>
      ) : null}
    </>
  );
};

const ContractRow = ({ id, name, sow, budget, status, url }) => {
  const [remaining, setRemaining] = useState(null);

  useEffect(() => {
    getContractBudget();
  }, []);

  const getContractBudget = async () => {
    try {
      const budgetDetails = await getContractBudgetDetailsFromApi(id);

      // remaining = total budget minus the greater of Budgeted or Billed amount

      const { total, budgeted, billed } = budgetDetails;

      const greaterValue = budgeted > billed ? budgeted : billed;

      setRemaining(total - greaterValue);
    } catch (error) {
      console.error("error getting contract budget details", error);
    }
  };

  return (
    <TableRow>
      <TableCell align="left">
        <TableCellTitle>{name}</TableCellTitle>
      </TableCell>
      <TableCell>
        <TableCellTitle>{sow}</TableCellTitle>
      </TableCell>
      <TableCell>{getCurrency(budget)}</TableCell>
      <TableCell>
        {remaining !== null ? getCurrency(remaining) : "..."}
      </TableCell>
      <TableCell capitalize>{status}</TableCell>
      <TableCell>
        <SimpleLink as={Link} to={url}>
          Edit
        </SimpleLink>
      </TableCell>
    </TableRow>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const Heading = styled.h3`
  font-size: 24px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0px;

  margin-right: 30px;
`;

const Table = styled.table`
  margin-top: 30px;

  thead {
    th {
      &:first-child {
        padding-left: 0;
      }
    }
  }
`;

const SectionContainer = styled.div`
  padding: 40px 0;
  border-top: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const SectionHeader = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const SectionHeaderTitle = styled.h3`
  margin-bottom: 0;
`;

const SectionContent = styled(motion.div)`
  height: 0;
  overflow: auto;
`;

ClientContracts.propTypes = {
  clientId: PropTypes.string,
};

export default ClientContracts;
