import React, { useState } from "react";
import styled from "styled-components";

import { visuallyHidden } from "../../../styles/styleHelpers";
import UploadIcon from "../../../components/icons/UploadIcon";

const DeliverableUpload = ({ id, name, isDisabled, typeName, onUpload }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (e) => {
    try {
      if (onUpload) {
        setIsLoading(true);

        const upload = e.target.files[0];
        await onUpload(upload);
      }
    } catch (err) {
      console.error("handleUpload failed");
    }

    setIsLoading(false);
  };

  const fieldName = `${id}-upload`;

  // if file exists: button to open the file modal
  // if no file: file upload field
  return (
    <UploadContainer
      as="label"
      name={name}
      htmlFor={fieldName}
      disabled={isDisabled || isLoading}
    >
      <Upload>
        <span>Upload&nbsp;Here</span>
        <UploadIcon />
      </Upload>

      <FileInput
        type="file"
        name={fieldName}
        id={fieldName}
        accept={typeName === "PDF" ? ".pdf" : ".png, .jpeg, .jpg, .gif"}
        onChange={!isDisabled ? handleUpload : undefined}
        disabled={isDisabled}
      />
    </UploadContainer>
  );
};

const UploadContainer = styled.div`
  * {
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
    opacity: ${(props) => (props.disabled ? ".6" : "1")};
  }
`;

const FileInput = styled.input`
  ${visuallyHidden()}
`;

const Upload = styled.div`
  display: flex;
  color: #6b7280;
  font-size: 12px;
  font-weight: 500;
  background: #f3f4f6;
  border-radius: 4px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  width: 110px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  span {
    margin-right: 5px;
  }

  &:hover {
    color: #5048e5;

    path {
      fill: #5048e5;
    }
  }
`;

export default DeliverableUpload;
