import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import _ from "lodash";

import { dealStagesMain, contractStages } from "../../utils/constants";
import { useNotifications } from "../../context/notificationsContext";
import { getDealsFromApi } from "../../utils/api";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../utils/sorting";
import { getStandardizedDate } from "../../utils/helpers";
import { TableCell, SearchInput } from "../../components/NewTable/components";
import Table from "../../components/NewTable/index";
import Filters, { FilterGroup } from "../../components/NewTable/Filters";

import FilterSelect from "../../components/NewTable/FilterSelect";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import ProfileImage from "../../components/ProfileImage";

import { TabContent } from "../../components/Tabs";
import { buttonReset } from "../../styles/styleHelpers";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  owner: "owner.name",
  company: "company.name",
  tier: "company.tier",
  "close date": "estimatedCloseDateString",
};

// filter chips
const filters = ["company", "owner", "tier", "status"];

// options for the group by dropdown
const groupByOptions = ["company", "tier", "owner", "status", "close date"];

// group by dropdown options
const groupBySelectOptions = [
  // first option is "None"
  { label: "None", value: null },
  // remaining options
  ...groupByOptions.map((option) => {
    // grab the proper path for this filter
    const value = filterPaths.hasOwnProperty(option)
      ? filterPaths[option]
      : option;

    return {
      label: _.startCase(option),
      value,
    };
  }),
];

const tabs = [
  {
    name: "Dashboard",
    id: "dashboard",
  },
];

const SalesDashboard = () => {
  const { setLoading } = useNotifications();

  const [originalDeals, setOriginalDeals] = useState([]);
  const [deals, setDeals] = useState([]);

  const [pipelineData, setPipelineData] = useState([]);
  const [pipeType, setPipeType] = useState("quantity"); // quantity, value, weighted

  //const [createOpen, setCreateOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [groupBy, setGroupBy] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Get all deals
  |--------------------------------------------------------------------------
  */
  const getDeals = async (shouldRefresh = false) => {
    setLoading(true);

    let tempDeals;

    if (originalDeals && originalDeals.length && !shouldRefresh) {
      tempDeals = [...originalDeals];
    } else {
      //{ queryItems: [{ owner: user._id }] }
      tempDeals = await getDealsFromApi();
    }

    setOriginalDeals(tempDeals);

    // If a filter was selected or a sort is active, filter the entries
    if ((selectedFilters && selectedFilters.length) || searchText) {
      filterDeals(selectedFilters);
    } else {
      setDeals(tempDeals);
    }

    setLoading(false);
  };

  const generatePipelineData = (rawData) => {
    // The highest possible value (which will be 100% width)
    let highest = 0;

    rawData = rawData.map((deal) => ({
      ...deal,
      // any deals that have become contracts, mark them as "Won" instead of "Active Contract"
      status: contractStages.includes(deal.status) ? "Won" : deal.status,
    }));

    // Groups all the data by status
    let groupedData = _(rawData)
      .groupBy("status")
      // Converts the grouping into an array with the key as a value
      .map((items, key) => {
        const numDeals = items.length || 0;

        // Add up the total of whatever is viewed by
        let total = 0;

        // If quantity, we set to number of deals
        if (pipeType === "quantity") {
          total = numDeals;

          // If value, we add up the amounts
        } else if (pipeType === "value") {
          items.forEach((item) => {
            if (item.estimatedBudget) {
              total += item.estimatedBudget;
            }
          });

          // If weighted, we calculate the weighted amount for each and add them together
        } else if (pipeType === "weighted") {
          items.forEach((item) => {
            // default the probability of closing to 100%
            const chanceClosing = item.chanceClosing || 100;

            if (item.estimatedBudget) {
              total += item.estimatedBudget * (chanceClosing / 100);
            }
          });
        }

        return {
          status: key,
          entries: items,
          count: numDeals,
          value: total,
        };
      })
      .value();

    const wonItems = groupedData.find((item) => item.status === "Won");
    const lostItems = groupedData.find((item) => item.status === "Lost");

    // Removes anything that's not a valid stage
    groupedData = groupedData.filter((item) =>
      dealStagesMain.includes(item.status),
    );

    // Gets the highest value of any stages
    // * Has to be after the invalid and Won/Lost stages are stripped out
    // * otherwise the bar widths won't be correct
    groupedData.forEach((item) => {
      if (item.value > highest) {
        highest = item.value;
      }
    });

    return {
      highestValue: highest,
      items: groupedData,
      wonItems: wonItems || {
        status: "Won",
        entries: [],
        count: 0,
        value: 0,
      },
      lostItems: lostItems || {
        status: "Lost",
        entries: [],
        count: 0,
        value: 0,
      },
    };
  };

  useEffect(() => {
    if (deals) {
      const newPipeData = generatePipelineData(deals);
      setPipelineData(newPipeData);
    }
  }, [deals, pipeType]);

  /*
  |--------------------------------------------------------------------------
  | Get the appropriate deals based on the page number
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getDeals();
  }, [searchText, selectedFilters]);

  /*
  |--------------------------------------------------------------------------
  | Filter the deals
  |--------------------------------------------------------------------------
  */
  const filterDeals = (tempFilters) => {
    let tempDeals = [...originalDeals];

    // Filters the deals
    if (tempFilters && tempFilters.length) {
      tempDeals = filterItems(
        tempFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempDeals,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempDeals = searchItems(searchText, tempDeals, [
        "contacts.firstName",
        "contacts.lastName",
        "owner.name",
        "tier",
        "name",
        "status",
        "description",
        "amount",
        "company.name",
      ]);
    }

    setDeals(tempDeals);
  };

  const calculateWonLostWidth = (won, lost) => {
    if (won === 0 && lost === 0) return 50;

    const total = won + lost;
    const decimal = won / total;
    const result = decimal * 100;

    return result;
  };

  return (
    <TabContentSection>
      <TabContent isactive={true} white>
        <Dashboard>
          <Col>
            <PipeHeader>
              <PipeHeaderText>View Pipeline By: </PipeHeaderText>
              <PipeHeaderButton
                active={pipeType === "quantity"}
                onClick={() => setPipeType("quantity")}
              >
                Quantity
              </PipeHeaderButton>
              <PipeHeaderButton
                active={pipeType === "value"}
                onClick={() => setPipeType("value")}
              >
                Value
              </PipeHeaderButton>
              <PipeHeaderButton
                active={pipeType === "weighted"}
                onClick={() => setPipeType("weighted")}
              >
                Weighted
              </PipeHeaderButton>
            </PipeHeader>
            <PipeList>
              {pipelineData?.items
                ? // loop over each of the deal stages
                  dealStagesMain.map((stage, index) =>
                    // loop over the pipeline data
                    pipelineData.items
                      // get only the pipeline data that falls in this stage
                      .filter((item) => item.status === stage)
                      .map((item) => {
                        const colorStep = Math.ceil(
                          (index / pipelineData.items.length) * 4,
                        );

                        return (
                          <PipeGroup
                            key={index}
                            as={Link}
                            to={`/sales/deals?status=${item.status}`}
                          >
                            <PipeBar
                              width={
                                (item.value / pipelineData.highestValue) * 100
                              }
                              shade={colorStep}
                            />
                            <PipeText>
                              <PipeStatus>{item.status}</PipeStatus>

                              <PipeValue>
                                {pipeType !== "quantity" ? "$" : ""}
                                {item.value.toLocaleString()}
                                {pipeType === "quantity"
                                  ? item.value === 1
                                    ? " Deal"
                                    : " Deals"
                                  : ""}
                              </PipeValue>

                              {pipeType !== "quantity" ? (
                                <PipeTotal>
                                  {item.count}{" "}
                                  {item.count === 1 ? "Deal" : "Deals"}
                                </PipeTotal>
                              ) : null}
                            </PipeText>
                          </PipeGroup>
                        );
                      }),
                  )
                : null}
              <PipeGroup split>
                <PipeBar
                  width={
                    pipelineData
                      ? calculateWonLostWidth(
                          pipelineData?.wonItems?.value || 0,
                          pipelineData?.lostItems?.value || 0,
                        )
                      : 0
                  }
                  shade={6}
                />
                <PipeText>
                  <PipeStatus>Decision - Won</PipeStatus>
                  <PipeValue>
                    {pipelineData.wonItems ? (
                      <>
                        {pipeType !== "quantity" ? "$" : ""}
                        {pipelineData.wonItems.value.toLocaleString() || "0"}
                        {pipeType === "quantity"
                          ? pipelineData.wonItems.value === 1
                            ? " Deal"
                            : " Deals"
                          : ""}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </PipeValue>
                  {pipeType !== "quantity" ? (
                    <PipeTotal>
                      {pipelineData.wonItems ? (
                        <>
                          {pipelineData.wonItems.count}{" "}
                          {pipelineData.wonItems.count === 1 ? "Deal" : "Deals"}
                        </>
                      ) : (
                        "0 Deals"
                      )}
                    </PipeTotal>
                  ) : null}
                </PipeText>
                <PipeLostText>
                  <PipeStatus>Decision - Lost</PipeStatus>
                  <PipeValue>
                    {pipelineData.lostItems ? (
                      <>
                        {pipeType !== "quantity" ? "$" : ""}
                        {pipelineData.lostItems.value.toLocaleString() || "0"}
                        {pipeType === "quantity"
                          ? pipelineData.lostItems.value === 1
                            ? " Deal"
                            : " Deals"
                          : ""}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </PipeValue>
                  {pipeType !== "quantity" ? (
                    <PipeTotal>
                      {pipelineData.lostItems ? (
                        <>
                          {pipelineData.lostItems.count}{" "}
                          {pipelineData.lostItems.count === 1
                            ? "Deal"
                            : "Deals"}
                        </>
                      ) : (
                        "0 Deals"
                      )}
                    </PipeTotal>
                  ) : null}
                </PipeLostText>
              </PipeGroup>
            </PipeList>
          </Col>

          <Col>
            <StyledFilterGroup>
              <Filters
                selectedFilters={selectedFilters}
                updateFilters={setSelectedFilters}
                filters={filters}
                getUniqueOptions={(filter) =>
                  getUniqueOptions(filter, originalDeals, {
                    exclude: selectedFilters,
                    propPaths: filterPaths,
                  })
                }
              />
              <FilterSelect
                label="Group By"
                handleChange={(label, value) => setGroupBy(value)}
                defaultValue={groupBySelectOptions[0]}
                options={groupBySelectOptions}
              />

              <SearchInput
                placeholder="Search items..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </StyledFilterGroup>

            {selectedFilters?.length ? (
              <SelectedFilters
                filters={selectedFilters}
                updateFilters={setSelectedFilters}
              />
            ) : null}

            <TableContainer>
              <Table
                noPadding
                noShadow
                groupBy={groupBy}
                headers={[
                  {
                    name: "Deals",
                    accessor: "name",
                  },
                  {
                    name: "Company",
                    accessor: "company.name",
                  },
                  {
                    name: "Tier",
                    accessor: "company.tier",
                  },
                  {
                    name: "Owner",
                    accessor: "owner.name",
                  },
                  {
                    name: "Status",
                    accessor: "status",
                  },
                  {
                    name: "Closed",
                    accessor: "estimatedCloseDate",
                  },
                ]}
                entries={
                  deals
                    ? deals.map((deal) => {
                        const dealClose = deal.estimatedCloseDate
                          ? format(
                              getStandardizedDate(deal.estimatedCloseDate),
                              "MM/dd/yyyy",
                            )
                          : "";

                        return {
                          ...deal,
                          // for filter/grouping purposes
                          estimatedCloseDateString: dealClose,
                          row: (
                            <>
                              <TableCell data-name-col>
                                <Link to={`/sales/deals/${deal._id}`}>
                                  {deal.name || "N/A"}
                                </Link>
                              </TableCell>

                              <TableCell full>
                                {deal.company?.name || "N/A"}
                              </TableCell>

                              <TableCell full>
                                {deal.company.tier || "N/A"}
                              </TableCell>

                              <TableCell full>
                                {deal.owner ? (
                                  <ProfileImage
                                    name={deal.owner?.name || ""}
                                    handle={deal.owner?.handle || ""}
                                    xsmall
                                  />
                                ) : (
                                  "N/A"
                                )}
                              </TableCell>

                              <TableCell>
                                {deal.status ? deal.status : "N/A"}
                              </TableCell>

                              <TableCell full>{dealClose || "N/A"}</TableCell>
                            </>
                          ),
                        };
                      })
                    : []
                }
              />
            </TableContainer>
          </Col>
        </Dashboard>
      </TabContent>
    </TabContentSection>
  );
};

const Header = styled.div`
  padding: 0 40px;
`;

const TabContentSection = styled.div`
  width: 100%;
`;

const Dashboard = styled.div`
  display: flex;
  gap: 15px;
  min-height: 500px;
  width: 100%;
`;

const Col = styled.div`
  flex: 0 0 50%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #fff;
  padding: 30px;
  overflow: auto;
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  z-index: 6;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const TableContainer = styled.div`
  margin-top: 24px;

  table {
    [data-name-col] {
      min-width: 150px;
    }
  }
`;

const PipeHeader = styled.div`
  width: 100%;
  display: flex;
`;

const PipeHeaderText = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.theme.colors.coolGray800};

  margin-right: 24px;
  margin-bottom: 0;
`;

const PipeHeaderButton = styled.button`
  ${buttonReset()}
  padding: 4px 8px;
  background: ${(props) =>
    props.active
      ? props.theme.colors.indigo100
      : props.theme.colors.coolGray200};
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.indigo600
      : props.theme.colors.coolGray700};

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

const PipeList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 26px;
`;

const PipeGroup = styled.div`
  width: 100%;
  height: 103px;
  display: flex;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  background-color: ${(props) =>
    props.split ? props.theme.colors.red100 : "transparent"};

  &:not(:last-child) {
    margin-bottom: 17px;
  }

  &:hover {
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.3);
  }
`;

const PipeBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) =>
    props.width > 100
      ? "100"
      : props.width < 1 || !props.width
      ? ".3"
      : props.width}%;

  background-color: ${(props) =>
    props.shade === 0
      ? props.theme.colors.green50
      : props.shade === 1
      ? props.theme.colors.green100
      : props.shade === 2
      ? props.theme.colors.green200
      : props.shade === 3
      ? props.theme.colors.green300
      : props.shade === 4
      ? props.theme.colors.green400
      : props.theme.colors.green500};

  z-index: -1;
  transition: width 200ms;
`;

const PipeText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
`;

const PipeLostText = styled(PipeText)`
  margin-left: auto;
  align-items: flex-end;
`;

const PipeStatus = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;

  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

const PipeValue = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

const PipeTotal = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

export default SalesDashboard;
