import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  format,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  addMonths,
} from "date-fns";

import { useNotifications } from "../../context/notificationsContext";

import { getWeek, getStandardizedDate, getCurrency } from "../../utils/helpers";

import { getExtraTimesFromApi, updateExtraTimeOnApi } from "./utils/api";

import { TableHeader, TableRow, TableCell } from "../../components/Table";
import WeekFilter from "./clients/components/WeekFilter";
import ProfileImage from "../../components/ProfileImage";
import SimpleLink from "../../components/links/SimpleLink";

const Time = () => {
  const { loading, setLoading } = useNotifications();
  const [sortBy, setSortBy] = useState("name");

  const [currentDate, setCurrentDate] = useState(new Date());
  const [extraTime, setExtraTime] = useState([]);

  /*
  |--------------------------------------------------------------------------
  | Calcluations for all the week info
  |--------------------------------------------------------------------------
  */
  const startWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  const startWeek = format(startWeekDate, "d");
  const endWeek = format(endWeekDate, "d");

  const thisMonth = format(startWeekDate, "MMMM");
  const thisMonthShort = format(startWeekDate, "MMM");
  const nextMonthShort = format(addMonths(startWeekDate, 1), "MMM");

  const weekDuration =
    // if the end of the week is a smaller number than the beginning of the week, then the week must end with a different month
    parseInt(endWeek) < parseInt(startWeek)
      ? `${thisMonthShort} ${startWeek} - ${nextMonthShort} ${endWeek}`
      : `${thisMonth} ${startWeek} - ${thisMonthShort} ${endWeek}`;

  const weekNumber = getWeek(getStandardizedDate(currentDate));

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the extra time
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedExtraTime = [...extraTime];

    if (sortBy === "member-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.member.handle.toUpperCase();
        var textB = b.member.handle.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "member-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.member.handle.toUpperCase();
        var textB = b.member.handle.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "project-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.project.name.toUpperCase();
        var textB = b.project.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "project-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.project.name.toUpperCase();
        var textB = b.project.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.budget;
        var textB = b.budget;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.budget;
        var textB = b.budget;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "spent-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.spent;
        var textB = b.spent;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "spent-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.spent;
        var textB = b.spent;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "overage-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = (a.overage * a.rate).toFixed(2);
        var textB = (b.overage * b.rate).toFixed(2);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "overage-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = (a.overage * a.rate).toFixed(2);
        var textB = (b.overage * b.rate).toFixed(2);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "remaining-asc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.remaining ? a.remaining : 0;
        var textB = b.remaining ? b.remaining : 0;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "remaining-desc") {
      sortedExtraTime.sort(function(a, b) {
        var textA = a.remaining ? a.remaining : 0;
        var textB = b.remaining ? b.remaining : 0;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setExtraTime(sortedExtraTime);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get projects every time the current date changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (currentDate) {
      setLoading(true);
      getExtraTime();
    }
  }, [currentDate]);

  /*
  |--------------------------------------------------------------------------
  | Gets all the extra time
  |--------------------------------------------------------------------------
  */
  const getExtraTime = async () => {
    // Only get the extra times that are in the same week
    const date = new Date(currentDate);
    const entries = await getExtraTimesFromApi({ date });

    setExtraTime(entries);
    setLoading(false);
  };

  /*
  |--------------------------------------------------------------------------
  | Update extra time on api
  |--------------------------------------------------------------------------
  */
  const updateExtraTime = async (id, type, undo) => {
    setLoading(true);

    const data = {
      id,
      type,
      undo,
    };

    try {
      await updateExtraTimeOnApi(data);
      const tempExtraTime = [...extraTime];

      // Change the updated extra time in state
      const index = tempExtraTime.findIndex((time) => time._id === id);

      // Remove the type if undo was set to true
      tempExtraTime[index].type = undo ? null : type;

      setExtraTime(tempExtraTime);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <DashboardContainer>
      <Header>
        <HeaderLeft>Extra Time</HeaderLeft>

        <HeaderRight>
          <WeekFilter
            weekNumber={weekNumber}
            weekDuration={weekDuration}
            handlePrevWeek={() => setCurrentDate(subWeeks(currentDate, 1))}
            handleNextWeek={() => setCurrentDate(addWeeks(currentDate, 1))}
          />
        </HeaderRight>
      </Header>

      {extraTime && extraTime.length ? (
        <>
          {extraTime.some(
            (time) => time.type !== "extra" && time.type !== "bill",
          ) ? (
            <TableContainer>
              <ExtraTimeTable>
                <thead>
                  <tr>
                    <TableHeader
                      noArrow
                      align="left"
                      style={{ width: "225px" }}
                    >
                      To Review
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("member")}
                      isArrowUp={sortBy === "member-desc"}
                      isActive={sortBy.includes("member")}
                      align="left"
                      style={{ width: "115px" }}
                    >
                      User
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("project")}
                      isArrowUp={sortBy === "project-desc"}
                      isActive={sortBy.includes("project")}
                      align="left"
                      style={{ width: "400px" }}
                    >
                      Project
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("budget")}
                      isArrowUp={sortBy === "budget-desc"}
                      isActive={sortBy.includes("budget")}
                      align="left"
                    >
                      Booked
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("spent")}
                      isArrowUp={sortBy === "spent-desc"}
                      isActive={sortBy.includes("spent")}
                      align="left"
                    >
                      Spent
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("overage")}
                      isArrowUp={sortBy === "overage-desc"}
                      isActive={sortBy.includes("overage")}
                      align="left"
                    >
                      Overage
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("remaining")}
                      isArrowUp={sortBy === "remaining-desc"}
                      isActive={sortBy.includes("remaining")}
                      align="left"
                    >
                      Remaining
                      <br />
                      on Contract
                    </TableHeader>

                    <TableHeader
                      noArrow
                      align="left"
                      style={{ width: "220px" }}
                    >
                      Submitted
                      <br />
                      Notes
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {extraTime
                    .filter(
                      (time) => time.type !== "extra" && time.type !== "bill",
                    )
                    .map((time, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <ButtonRow>
                            <Button
                              active={time.type === "extra"}
                              onClick={() => {
                                updateExtraTime(time._id, "extra");
                              }}
                            >
                              Extra Time
                            </Button>
                            <Button
                              active={time.type === "bill"}
                              onClick={() => {
                                updateExtraTime(time._id, "bill");
                              }}
                            >
                              Bill Time
                            </Button>
                          </ButtonRow>
                        </TableCell>
                        <TableCell align="left">
                          <ProfileImage
                            handle={time.member.handle}
                            name={time.member.name}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SimpleLink
                            as={Link}
                            to={`/projects/${time.project._id}`}
                          >
                            {time.project.code
                              ? `[${time.project.code}] - ${time.project.name}`
                              : time.project.name}
                          </SimpleLink>
                        </TableCell>
                        <TableCell align="left">{time.budget}</TableCell>
                        <TableCell align="left">{time.spent}</TableCell>
                        <TableCell align="left">
                          {getCurrency(time.overage * time.rate, true)}
                        </TableCell>
                        <TableCell align="left">
                          {time.remaining
                            ? getCurrency(time.remaining, true)
                            : "(No Contract)"}
                        </TableCell>
                        <TableCell align="left">
                          <Notes>{time.notes}</Notes>
                        </TableCell>
                      </TableRow>
                    ))}
                </tbody>
              </ExtraTimeTable>
            </TableContainer>
          ) : null}

          {extraTime.some((time) => time.type === "bill") ? (
            <TableContainer>
              <ExtraTimeTable>
                <thead>
                  <tr>
                    <TableHeader
                      onClick={() => changeSort("type")}
                      noArrow
                      align="left"
                      style={{ width: "225px" }}
                    >
                      Billed Extra Time
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("member")}
                      isArrowUp={sortBy === "member-desc"}
                      isActive={sortBy.includes("member")}
                      align="left"
                      style={{ width: "115px" }}
                    >
                      User
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("project")}
                      isArrowUp={sortBy === "project-desc"}
                      isActive={sortBy.includes("project")}
                      align="left"
                      style={{ width: "400px" }}
                    >
                      Project
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("budget")}
                      isArrowUp={sortBy === "budget-desc"}
                      isActive={sortBy.includes("budget")}
                      align="left"
                    >
                      Booked
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("spent")}
                      isArrowUp={sortBy === "spent-desc"}
                      isActive={sortBy.includes("spent")}
                      align="left"
                    >
                      Spent
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("overage")}
                      isArrowUp={sortBy === "overage-desc"}
                      isActive={sortBy.includes("overage")}
                      align="left"
                    >
                      Overage
                    </TableHeader>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {extraTime
                    .filter((time) => time.type === "bill")
                    .map((time, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <ButtonRow>
                            <Button active={time.type === "extra"} disabled>
                              Extra Time
                            </Button>
                            <Button active={time.type === "bill"} disabled>
                              Bill Time
                            </Button>
                          </ButtonRow>
                        </TableCell>
                        <TableCell align="left">
                          <ProfileImage
                            handle={time.member.handle}
                            name={time.member.name}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SimpleLink
                            as={Link}
                            to={`/projects/${time.project._id}`}
                          >
                            {time.project.code
                              ? `[${time.project.code}] - ${time.project.name}`
                              : time.project.name}
                          </SimpleLink>
                        </TableCell>
                        <TableCell align="left">{time.budget}</TableCell>
                        <TableCell align="left">{time.spent}</TableCell>
                        <TableCell align="left">
                          {getCurrency(time.overage * time.rate, true)}
                        </TableCell>
                        <TableCell align="left" style={{ paddingLeft: "0px" }}>
                          <Undo
                            onClick={() =>
                              updateExtraTime(time._id, time.type, true)
                            }
                          >
                            Undo
                          </Undo>
                        </TableCell>
                      </TableRow>
                    ))}
                </tbody>
              </ExtraTimeTable>
            </TableContainer>
          ) : null}

          {extraTime.some((time) => time.type === "extra") ? (
            <TableContainer>
              <ExtraTimeTable>
                <thead>
                  <tr>
                    <TableHeader
                      onClick={() => changeSort("type")}
                      noArrow
                      align="left"
                      style={{ width: "225px" }}
                    >
                      Unbilled Extra Time
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("member")}
                      isArrowUp={sortBy === "member-desc"}
                      isActive={sortBy.includes("member")}
                      align="left"
                      style={{ width: "115px" }}
                    >
                      User
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("project")}
                      isArrowUp={sortBy === "project-desc"}
                      isActive={sortBy.includes("project")}
                      align="left"
                      style={{ width: "400px" }}
                    >
                      Project
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("budget")}
                      isArrowUp={sortBy === "budget-desc"}
                      isActive={sortBy.includes("budget")}
                      align="left"
                    >
                      Booked
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("spent")}
                      isArrowUp={sortBy === "spent-desc"}
                      isActive={sortBy.includes("spent")}
                      align="left"
                    >
                      Spent
                    </TableHeader>

                    <TableHeader
                      onClick={() => changeSort("overage")}
                      isArrowUp={sortBy === "overage-desc"}
                      isActive={sortBy.includes("overage")}
                      align="left"
                    >
                      Overage
                    </TableHeader>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {extraTime
                    .filter((time) => time.type === "extra")
                    .map((time, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <ButtonRow>
                            <Button active={time.type === "extra"} disabled>
                              Extra Time
                            </Button>
                            <Button active={time.type === "bill"} disabled>
                              Bill Time
                            </Button>
                          </ButtonRow>
                        </TableCell>
                        <TableCell align="left">
                          <ProfileImage
                            handle={time.member.handle}
                            name={time.member.name}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SimpleLink
                            as={Link}
                            to={`/projects/${time.project._id}`}
                          >
                            {time.project.code
                              ? `[${time.project.code}] - ${time.project.name}`
                              : time.project.name}
                          </SimpleLink>
                        </TableCell>
                        <TableCell align="left">{time.budget}</TableCell>
                        <TableCell align="left">{time.spent}</TableCell>
                        <TableCell align="left">
                          {getCurrency(time.overage * time.rate, true)}
                        </TableCell>
                        <TableCell align="left" style={{ paddingLeft: "0px" }}>
                          <Undo
                            onClick={() =>
                              updateExtraTime(time._id, time.type, true)
                            }
                          >
                            Undo
                          </Undo>
                        </TableCell>
                      </TableRow>
                    ))}
                </tbody>
              </ExtraTimeTable>
            </TableContainer>
          ) : null}
        </>
      ) : !extraTime || (!extraTime.length && !loading) ? (
        <NoExtraTimes>No extra time has been logged for this week</NoExtraTimes>
      ) : null}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  padding: 50px;
`;

const Header = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 80px;
`;

const HeaderLeft = styled.div`
  font-size: 28px;
  color: #373040;
  font-weight: 600;
  margin: 0px;
`;

const HeaderRight = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const TableContainer = styled.div`
  overflow: auto;
  margin-bottom: 120px;
`;

const ExtraTimeTable = styled.table`
  table-layout: fixed;
  margin-bottom: 0;

  ${TableHeader} {
    padding: 0px 30px 10px 30px;
    vertical-align: bottom;

    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0px;
    }
  }

  ${TableCell} {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;

    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0px;
    }

    a {
      font-size: 16px;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.active ? props.theme.colors.blue : props.theme.colors.mediumGray};
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.blue : props.theme.colors.mediumGray};
  border-radius: 5px;
  width: 90px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  transition: 0.2s ease-in-out;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  flex-shrink: 0;

  &:hover {
    border-color: ${(props) => props.theme.colors.blue};
    background-color: ${(props) => props.theme.colors.blue};
    color: white;
  }

  &:first-of-type {
    margin-right: 15px;
  }
`;

const Undo = styled(Button)`
  &:first-of-type {
    margin-left: auto;
    margin-right: 0px !important;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
    border-color: ${(props) => props.theme.colors.red};
  }
`;

const NoExtraTimes = styled.p`
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
`;

const Notes = styled.p`
  line-height: 1.25;
  font-size: 14px;
  margin-bottom: 0;
  min-width: 25ch;
`;

export default Time;
