import React from "react";
import styled, { css } from "styled-components";

const SortIcon = ({ isArrowUp, isActive }) => {
  return (
    <Svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isArrowUp={isArrowUp}
      isActive={isActive}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.858862 6.00317C0.983629 5.87844 1.15283 5.80837 1.32925 5.80837C1.50567 5.80837 1.67487 5.87844 1.79963 6.00317L3.99056 8.19409L6.18148 6.00317C6.24286 5.93962 6.31627 5.88893 6.39745 5.85406C6.47862 5.8192 6.56592 5.80084 6.65426 5.80007C6.74261 5.79931 6.83022 5.81614 6.91198 5.84959C6.99375 5.88305 7.06803 5.93245 7.1305 5.99492C7.19297 6.05739 7.24237 6.13167 7.27583 6.21344C7.30928 6.29521 7.32612 6.38282 7.32535 6.47116C7.32458 6.5595 7.30623 6.6468 7.27136 6.72798C7.23649 6.80915 7.1858 6.88256 7.12226 6.94394L4.46095 9.60525C4.33618 9.72998 4.16698 9.80005 3.99056 9.80005C3.81414 9.80005 3.64494 9.72998 3.52017 9.60525L0.858862 6.94394C0.734132 6.81917 0.664062 6.64997 0.664062 6.47355C0.664062 6.29713 0.734132 6.12793 0.858862 6.00317Z"
        fill="#6B7280"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13057 3.99659C7.00581 4.12132 6.83661 4.19139 6.66019 4.19139C6.48377 4.19139 6.31457 4.12132 6.1898 3.99659L3.99888 1.80567L1.80795 3.99659C1.74658 4.06014 1.67316 4.11082 1.59199 4.14569C1.51082 4.18056 1.42351 4.19891 1.33517 4.19968C1.24683 4.20045 1.15922 4.18361 1.07745 4.15016C0.995687 4.11671 0.921402 4.06731 0.858933 4.00484C0.796464 3.94237 0.747061 3.86808 0.713608 3.78632C0.680154 3.70455 0.66332 3.61694 0.664088 3.5286C0.664855 3.44026 0.683209 3.35295 0.718078 3.27178C0.752948 3.19061 0.803634 3.11719 0.86718 3.05582L3.52849 0.394506C3.65326 0.269776 3.82246 0.199707 3.99888 0.199707C4.1753 0.199707 4.3445 0.269776 4.46926 0.394506L7.13057 3.05582C7.2553 3.18058 7.32537 3.34978 7.32537 3.5262C7.32537 3.70262 7.2553 3.87182 7.13057 3.99659Z"
        fill="#6B7280"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  margin-left: 8px;

  path {
    opacity: 0.5;
    transition: fill 0.2s;
  }

  ${(props) => {
    return props.isActive
      ? props.isArrowUp
        ? css`
            path:first-of-type {
              opacity: 1;
            }
          `
        : props.noArrow
        ? css`
            display: none;
          `
        : css`
            path:last-of-type {
              opacity: 1;
            }
          `
      : "";
  }}
`;

export default SortIcon;
