import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import {
  getOfficeFromApi,
  updateOfficeOnApi,
  createOfficeOnApi,
  getMembersFromApi,
} from "../../utils/api";

import { Field, Label, Input } from "../../components/Form";
import Button from "../../components/buttons/Button";
import SimpleLink from "../../components/links/SimpleLink";
import ProfileImage from "../../components/ProfileImage";

const OfficeModify = () => {
  const history = useHistory();
  const { openAlertPopup } = useNotifications();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [officeMembers, setOfficeMembers] = useState(null);
  const [isExisting, setIsExisting] = useState(false);

  useEffect(() => {
    // if user is creating a new office, the :id in the url will be "new", or something along those lines
    // if they're editing an existing one, the :id will be a long string (the object ID)
    const alreadyExists = id.length > 7;

    setIsExisting(alreadyExists);

    // if it exists, fetch its data
    if (alreadyExists) {
      getOffice();
      getMembers();
    }
  }, [id]);

  const getOffice = async () => {
    try {
      const officeFromApi = await getOfficeFromApi(id);

      if (officeFromApi) {
        setName(officeFromApi.name);
      }
    } catch (error) {
      console.error("error getting office", error);
    }
  };

  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi({ office: id });

      setOfficeMembers(membersFromApi || []);
    } catch (error) {
      console.error("error getting office members", error);
    }
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const data = {
        name,
      };

      if (isExisting) {
        // edit
        await updateOfficeOnApi(id, data);
      } else {
        await createOfficeOnApi(data);
      }

      openAlertPopup(
        "Success",
        `${name} office has been ${isExisting ? "updated" : "created"}.`,
        true,
      );

      // if a new one was just created, go back to the offices view
      if (!isExisting) {
        history.push(`/manage/offices`);
      }
    } catch (error) {
      console.error(
        `error ${isExisting ? "updating" : "creating"} office`,
        error,
      );
    }
  };

  return (
    <Container>
      <Header>
        <Heading>{isExisting ? "Edit" : "New"} Office</Heading>

        <SimpleLink as={Link} to={`/manage/offices`}>
          Back to Offices
        </SimpleLink>
      </Header>

      <Flex>
        <Col>
          <Form onSubmit={handleSave}>
            <Field>
              <Label htmlFor="office-name">Office Name</Label>
              <Input
                id="office-name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
                required
              />
            </Field>

            <div>
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </Col>

        {isExisting ? (
          <Col>
            <Label as="div">Office Members</Label>
            <Members>
              {officeMembers ? (
                officeMembers.length ? (
                  officeMembers.map((member) => (
                    <ProfileImage
                      handle={member.handle}
                      name={member.name}
                      small
                      showStatus
                    />
                  ))
                ) : (
                  <small>(none)</small>
                )
              ) : null}
            </Members>
          </Col>
        ) : null}
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  font-size: 24px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0px;

  margin-right: 30px;
`;

const Form = styled.form`
  width: 400px;
`;

const Flex = styled.div`
  display: flex;
`;

const Col = styled.div`
  &:not(:last-child) {
    padding-right: 70px;
  }
`;

const Members = styled.div`
  display: flex;

  > * {
    margin-right: 20px;
  }
`;

OfficeModify.propTypes = {};

export default OfficeModify;
