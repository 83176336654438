import React from "react";
import styled from "styled-components";
import Pagination from "./pagination";
import SelectDropdown from "../newSelectDropdown";

/**
 * The table footer, with pagination included
 * @param {number} numEntries The total number of entries
 * @param {number} perPage Entries per page
 * @param {number[]} perPageOptions The available options for the number of entries per page
 * @param {number} currentPage The current page we're on
 * @param {function} changePage The function to change the current page
 * @returns {component}
 */

const TableFooter = ({
  numEntries,
  perPage,
  perPageOptions,
  changePerPage,
  currentPage,
  changePage,
}) => {
  return (
    <Footer>
      <Pagination
        totalEntries={numEntries}
        perPage={perPage}
        currentPage={currentPage}
        setCurrentPage={changePage}
      />

      <ShowRows>
        <p>Show: </p>
        <ShowDropdown>
          <SelectDropdown
            menuPlacement="top"
            value={{
              label: `${perPage} rows`,
              value: perPage,
            }}
            options={perPageOptions.map((option) => {
              return {
                label: `${option} rows`,
                value: option,
              };
            })}
            onChange={(e) => changePerPage(e.value)}
          />
        </ShowDropdown>
      </ShowRows>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const ShowRows = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
  }
`;

const ShowDropdown = styled.div`
  width: 104px;
  cursor: pointer;

  * {
    font-size: 14px;
    cursor: pointer;
  }
`;

export default TableFooter;
