import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";
import { motion } from "framer-motion";
import store from "store";

import { respondTo } from "../../styles/styleHelpers";
import { getSafariDate } from "../../utils/helpers";

import { MenuItem, MenuIcon, MenuText } from "./sidebarView";
import CloseIcon from "../icons/CloseIcon";
import FireIcon from "../icons/FireIcon";

import changelog from "../../changelog.json";

const Changelog = ({ isSidebarOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    // once the notes are set
    if (notes.length) {
      // grab the stored version number
      const storedVersionNumber = store.get("versionNumber");

      // get the version number from the most recent release
      const latestVersionNumber = notes[0].version;

      // check if they're equal
      const isNewVersion = latestVersionNumber !== storedVersionNumber;

      // denote whether a new version is present
      setIsNew(isNewVersion);
    }
  }, [notes]);

  useEffect(() => {
    // when changelog is opened and there are releases
    if (isOpen && notes.length) {
      // get the version number from the most recent release
      const latestVersionNumber = notes[0].version;

      // update the stored number with the new number
      store.set("versionNumber", latestVersionNumber);

      // hide the new version styles
      setIsNew(false);
    }
  }, [isOpen]); //eslint-disable-line

  const getNotes = async () => {
    const changelogNotes = await Promise.all(
      changelog.map(async (item) => {
        const markdownFile = await fetch(`/changelog/${item.version}.md`);
        const markdown = await markdownFile.text();

        return {
          ...item,
          details: markdown,
        };
      }),
    );

    setNotes(changelogNotes);
  };

  return (
    <Container>
      <MenuItem onClick={() => setIsOpen(!isOpen)} isactive={isOpen}>
        <MenuIcon>
          <FireIcon />
          {!isSidebarOpen && isNew ? <Badge /> : null}
        </MenuIcon>
        <MenuText>
          Release Notes
          {notes.length ? (
            <VersionNumber isNew={isNew}>{notes[0].version}</VersionNumber>
          ) : null}
        </MenuText>
      </MenuItem>

      <Popout isVisible={isOpen}>
        <PopoutHeader>
          <PopoutTitle>Releases</PopoutTitle>

          <CloseButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </CloseButton>
        </PopoutHeader>

        <List>
          {notes.map((note, index) => (
            <Release
              key={note.version}
              title={note.version}
              date={note.date}
              details={note.details}
              pill={index === 0 ? "Latest" : undefined}
              initiallyOpen={index === 0}
            />
          ))}
        </List>
      </Popout>
    </Container>
  );
};

const Release = ({ title, date, details, pill, initiallyOpen }) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen || false);

  return (
    <ReleaseContainer isOpen={isOpen}>
      <ReleaseHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <ReleaseTitle>{title}</ReleaseTitle>

        {pill ? <Pill>{pill}</Pill> : null}
        <ReleaseDate>{format(getSafariDate(date), "MMM d, yyyy")}</ReleaseDate>
      </ReleaseHeader>

      <ReleaseNotes
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { height: "auto" },
          closed: { height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        <ReactMarkdown>{details}</ReactMarkdown>
      </ReleaseNotes>
    </ReleaseContainer>
  );
};

const Container = styled.div`
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  right: -1px;
  bottom: 6px;

  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.indigo600};
`;

const VersionNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 20px;
  border-radius: 4px;
  line-height: 1;
  margin-left: 10px;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  color: ${(props) =>
    props.isNew ? props.theme.colors.indigo600 : props.theme.colors.mediumGray};
  line-height: 1;

  background-color: ${(props) =>
    props.isNew ? props.theme.colors.indigo100 : "transparent"};

  transition: color 200ms, background-color 200ms;
  user-select: none;
`;

const Popout = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;

  width: 400px;
  height: 600px;

  display: flex;
  flex-direction: column;

  padding-top: 18px;
  background-color: #fff;
  box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.2);
  border-radius: 10px;

  transition: opacity 0.12s, visibility 0.12s, transform 0.12s ease-out;

  transform: translate(101%, 0px); // 15px
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};

  ${respondTo("xlarge")} {
    width: 450px;
    height: 650px;
    padding-top: 40px;
  }
`;

const PopoutHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 17px 5px;

  > *:last-child {
    margin-left: auto;
  }
`;

const PopoutTitle = styled.h2`
  color: ${(props) => props.theme.colors.darkSlate};
  font-weight: 700;
  letter-spacing: 0.02em;
  font-size: ${(props) => props.theme.fontSize_xs};
  margin-right: 15px;
  margin-bottom: 0;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_s};
  }
`;

const CloseButton = styled.button`
  path {
    transition: fill 200ms;
  }

  &:hover,
  &:focus-visible {
    path {
      fill: ${(props) => props.theme.colors.red};
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  overflow: auto;
`;

const Pill = styled.div`
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 50px;
  color: #fff;

  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;

  padding: 5px;
  padding-bottom: 4px;
`;

const ReleaseContainer = styled.article`
  border-bottom: 1px solid ${(props) => props.theme.colors.dark};
  padding-bottom: ${(props) => (props.isOpen ? "25px" : 0)};

  transition: padding 400ms;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }
`;

const ReleaseHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  padding: 25px 17px;

  cursor: pointer;
`;

const ReleaseTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize_xs};
  font-weight: 700;
  margin-right: 15px;
`;

const ReleaseDate = styled.div`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.dark2};
  opacity: 0.7;
  margin-left: auto;
`;

const ReleaseNotes = styled(motion.div)`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  padding: 0 17px;

  height: 0;
  overflow: hidden;

  p {
    margin-bottom: 6.5px;
  }

  ul,
  ol {
    padding-left: 1.25em;
    margin-bottom: 10px;

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: 7.5px;
  }

  h1 {
    font-size: ${(props) => props.theme.fontSize_xs};
  }
  h2 {
    font-size: ${(props) => props.theme.fontSize_xxs};
  }
  h3 {
    font-size: ${(props) => props.theme.fontSize_xxxs};
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export default Changelog;
