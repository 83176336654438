import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

import AlertIcon from "../icons/AlertIcon";
import CloseButton from "../buttons/CloseButton";

const AlertView = ({ title, message, isOpen, handleClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Container
          initial={{ opacity: 0.5, y: "150%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0.5, y: "150%" }}
          transition={{ type: "spring", duration: 0.8 }}
        >
          <Icon>
            <AlertIcon />
          </Icon>
          <TextContainer>
            <Title>{title}</Title>
            <Message>{message}</Message>
          </TextContainer>
          <CloseButton onClick={handleClose} />
        </Container>
      )}
    </AnimatePresence>
  );
};

const Container = styled(motion.div)`
  position: fixed;
  bottom: 25px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(233, 233, 233, 0.5);
  border-radius: 4px;
  padding: 48px 40px;
  margin-left: 25px;
  margin-right: 25px;
  z-index: 1000;
  transition: opacity 0.2s;
`;

const Icon = styled.div`
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 32px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.colors.blue};
    opacity: 0.1;
    border-radius: 50%;
  }
`;

const TextContainer = styled.div``;

const Title = styled.h3`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_xxs};

  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${(props) => props.theme.colors.oldBlack2};

  margin-bottom: 8px;
`;

const Message = styled.p`
  font-size: ${(props) => props.theme.fontSize_xs};
  color: ${(props) => props.theme.colors.oldBlack2};
  margin-bottom: 0;
`;

AlertView.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

AlertView.defaultProps = {
  title: "",
  message: "",
  isOpen: false,
  handleClose: null,
};

export default AlertView;
