import React, { useState } from "react";
import styled from "styled-components";

import FilterSelect from "./FilterSelect";
import FilterIcon from "../icons/FilterIcon";
import SelectDropdown from "../newSelectDropdown";

const Filters = ({
  selectedFilters,
  updateFilters,
  filters,
  getUniqueOptions,
}) => {
  const [filterActive, setFilterActive] = useState(false);

  /*
  |--------------------------------------------------------------------------
  | Add Filter
  |--------------------------------------------------------------------------
  */
  const addFilterLabel = (item) => {
    const tempFilters = [...selectedFilters];

    tempFilters.push({ label: item, value: "" });
    updateFilters(tempFilters);

    setTimeout(() => {
      setFilterActive(false);
    }, 0);
  };

  const addFilterValue = (label, value) => {
    let tempFilters = [...selectedFilters];

    tempFilters = tempFilters.map((filter) => {
      return {
        ...filter,
        // if this label matches, and it doesn't have a value yet, apply the value. Otherwise, keep its current value
        value: filter.label === label && !filter.value ? value : filter.value,
      };
    });

    updateFilters(tempFilters);
  };

  return (
    <>
      {selectedFilters
        ? selectedFilters.map((filter, index) => {
            if (filter.label && !filter.value) {
              return (
                <FilterSelect
                  key={index}
                  label={filter.label}
                  handleChange={addFilterValue}
                  options={getUniqueOptions(filter.label)}
                />
              );
            }
          })
        : null}

      <AddFilter
        filterActive={filterActive}
        onClick={() => {
          setFilterActive(true);
        }}
      >
        <FilterIcon />

        {filterActive ? (
          <AllFilters>
            <SelectDropdown
              placeholder="Add Filter"
              onChange={(e) => addFilterLabel(e.value)}
              options={filters.map((filter) => ({
                label: filter,
                value: filter,
              }))}
            />
          </AllFilters>
        ) : null}
      </AddFilter>
    </>
  );
};

export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const AllFilters = styled.div`
  width: 110px;
  position: absolute;
  left: -1px;
  bottom: -1px;

  * {
    cursor: pointer !important;
    text-transform: capitalize;
  }
`;

const AddFilter = styled.div`
  position: relative;
  width: ${(props) => (props.filterActive ? "110px" : "38px")};
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: white;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    border-color: #0079ff;
  }
`;

export default Filters;
