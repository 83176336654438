import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

import SimpleLink from "../links/SimpleLink";

const CheckMenu = ({
  disabled,
  numItems,
  handleEdit,
  handleDelete,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!numItems) {
      setIsOpen(false);
    }
  }, [numItems]);

  return (
    <Wrapper>
      <Button
        {...props}
        disabled={disabled}
        onClick={() => (!disabled ? setIsOpen(!isOpen) : null)}
      >
        <Icon />
      </Button>
      <Menu isOpen={isOpen}>
        {numItems === 1 && handleEdit ? (
          <CheckMenuItem
            onClick={() => {
              setIsOpen(false);
              handleEdit();
            }}
            small
            nounderline
          >
            Edit Item
          </CheckMenuItem>
        ) : null}

        {handleDelete ? (
          <CheckMenuItem
            onClick={() => {
              setIsOpen(false);
              handleDelete();
            }}
            small
            nounderline
            danger
          >
            {numItems > 1 ? `Delete ${numItems} Items` : "Delete Item"}
          </CheckMenuItem>
        ) : null}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const Button = styled.button`
  ${buttonReset()}
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: 38px;
  width: 38px;
  position: relative;

  &:not(:disabled) {
    &:hover,
    &:focus {
      border-color: #0079ff;
    }
  }

  &:disabled {
    cursor: default;

    svg {
      opacity: 0.3;
      pointer-events: none;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid #e5e7eb;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  z-index: 2;
  padding: 20px 20px;
  transition: transform 0.2s, opacity 0.2s;

  ${(props) =>
    props.isOpen
      ? css`
          opacity: 1;
          transform: translateY(100%);
        `
      : css`
          opacity: 0;
          transform: translateY(120%);
          pointer-events: none;
        `}
`;

const CheckMenuItem = styled(SimpleLink)`
  ${buttonReset()}

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Icon = ({ ...rest }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M9.41205 4.75584C9.56833 4.91212 9.78029 4.99992 10.0013 4.99992C10.2223 4.99992 10.4343 4.91212 10.5906 4.75584C10.7468 4.59956 10.8346 4.3876 10.8346 4.16659C10.8346 3.94557 10.7468 3.73361 10.5906 3.57733C10.4343 3.42105 10.2223 3.33325 10.0013 3.33325C9.78029 3.33325 9.56833 3.42105 9.41205 3.57733C9.25577 3.73361 9.16797 3.94557 9.16797 4.16659C9.16797 4.3876 9.25577 4.59956 9.41205 4.75584Z"
      fill="#374151"
    />
    <path
      d="M9.41205 10.5892C9.56833 10.7455 9.78029 10.8333 10.0013 10.8333C10.2223 10.8333 10.4343 10.7455 10.5906 10.5892C10.7468 10.4329 10.8346 10.2209 10.8346 9.99992C10.8346 9.7789 10.7468 9.56694 10.5906 9.41066C10.4343 9.25438 10.2223 9.16658 10.0013 9.16658C9.78029 9.16658 9.56833 9.25438 9.41205 9.41066C9.25577 9.56694 9.16797 9.7789 9.16797 9.99992C9.16797 10.2209 9.25577 10.4329 9.41205 10.5892Z"
      fill="#374151"
    />
    <path
      d="M9.41205 16.4225C9.56833 16.5788 9.78029 16.6666 10.0013 16.6666C10.2223 16.6666 10.4343 16.5788 10.5906 16.4225C10.7468 16.2662 10.8346 16.0543 10.8346 15.8333C10.8346 15.6122 10.7468 15.4003 10.5906 15.244C10.4343 15.0877 10.2223 14.9999 10.0013 14.9999C9.78029 14.9999 9.56833 15.0877 9.41205 15.244C9.25577 15.4003 9.16797 15.6122 9.16797 15.8333C9.16797 16.0543 9.25577 16.2662 9.41205 16.4225Z"
      fill="#374151"
    />
    <path
      d="M10.0013 4.16659V4.17492M10.0013 9.99992V10.0083M10.0013 15.8333V15.8416M10.0013 4.99992C9.78029 4.99992 9.56833 4.91212 9.41205 4.75584C9.25577 4.59956 9.16797 4.3876 9.16797 4.16659C9.16797 3.94557 9.25577 3.73361 9.41205 3.57733C9.56833 3.42105 9.78029 3.33325 10.0013 3.33325C10.2223 3.33325 10.4343 3.42105 10.5906 3.57733C10.7468 3.73361 10.8346 3.94557 10.8346 4.16659C10.8346 4.3876 10.7468 4.59956 10.5906 4.75584C10.4343 4.91212 10.2223 4.99992 10.0013 4.99992ZM10.0013 10.8333C9.78029 10.8333 9.56833 10.7455 9.41205 10.5892C9.25577 10.4329 9.16797 10.2209 9.16797 9.99992C9.16797 9.7789 9.25577 9.56694 9.41205 9.41066C9.56833 9.25438 9.78029 9.16658 10.0013 9.16658C10.2223 9.16658 10.4343 9.25438 10.5906 9.41066C10.7468 9.56694 10.8346 9.7789 10.8346 9.99992C10.8346 10.2209 10.7468 10.4329 10.5906 10.5892C10.4343 10.7455 10.2223 10.8333 10.0013 10.8333ZM10.0013 16.6666C9.78029 16.6666 9.56833 16.5788 9.41205 16.4225C9.25577 16.2662 9.16797 16.0543 9.16797 15.8333C9.16797 15.6122 9.25577 15.4003 9.41205 15.244C9.56833 15.0877 9.78029 14.9999 10.0013 14.9999C10.2223 14.9999 10.4343 15.0877 10.5906 15.244C10.7468 15.4003 10.8346 15.6122 10.8346 15.8333C10.8346 16.0543 10.7468 16.2662 10.5906 16.4225C10.4343 16.5788 10.2223 16.6666 10.0013 16.6666Z"
      stroke="#374151"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckMenu;
