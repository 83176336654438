import axios from "axios";
import { headers } from "../../../utils/api";

export const getWeekBookingPlanFromApi = async (user, week) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/bookingPlans/${user}/${week}`,
      headers,
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

export const updateWeekBookingPlanOnApi = async (id, week, breakdown) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/time/bookingPlans/${id}/${week}`,
      headers,
      data: {
        breakdown,
      },
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};
