import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";

import {
  getProjectFromApi,
  getMembersFromApi,
  editProjectOnApi,
} from "../manage/utils/api";
import {
  getContractsFromApi,
  getContractBudgetDetailsFromApi,
} from "../../utils/api";
import { colors } from "../../styles/theme";
import { capitalize, getCurrency } from "../../utils/helpers";

import SimpleLink from "../../components/links/SimpleLink";
import FancyLink from "../../components/links/FancyLink";
import Button from "../../components/buttons/Button";
import {
  Field,
  Label,
  Input,
  MoneyInput,
  FieldGroup,
} from "../../components/Form";
import Toggle, { ToggleContainer } from "../../components/Toggle";
import SelectDropdown from "../../components/SelectDropdown";
import ProjectRates from "./components/ProjectRates";

const ProjectEdit = () => {
  const { id } = useParams();
  const history = useHistory();

  const { openAlertPopup } = useNotifications();

  const [project, setProject] = useState(null);
  const [harvestProject, setHarvestProject] = useState(null);
  const [harvestId, setHarvestId] = useState(null);
  const [projectClient, setProjectClient] = useState(null);
  const [projectContract, setProjectContract] = useState(null);
  const [strategyDirectors, setStrategyDirectors] = useState(null);
  // const [members, setMembers] = useState(null);
  const [leadDirector, setLeadDirector] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectCode, setProjectCode] = useState(null);
  const [projectManagers, setProjectManagers] = useState([]);
  const [projectManager, setProjectManager] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lowEstimate, setLowEstimate] = useState(null);
  const [highEstimate, setHighEstimate] = useState(null);
  const [expenseBudget, setExpenseBudget] = useState(null);
  const [budgetReset, setBudgetReset] = useState(false);
  const [designation, setDesignation] = useState("");

  const [contracts, setContracts] = useState(null);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [loadingContracts, setLoadingContracts] = useState(false);
  const [contractRemaining, setContractRemaining] = useState(null);

  const projectTypes = [
    { label: "Retainer", value: "retainer" },
    { label: "Project", value: "project" },
    { label: "Rush", value: "rush" },
  ];

  // on load
  useEffect(() => {
    getProject();
    getMembers();
  }, []);

  // when a contract is selected
  useEffect(() => {
    if (selectedContractId) {
      // reset the contract budget and remaining values
      setContractRemaining(null);

      // fetch this contract's budget details
      getContractBudget();
    }
  }, [selectedContractId]);

  /*
  |--------------------------------------------------------------------------
  | Get all values in state and sends to the API to save
  |--------------------------------------------------------------------------
  */
  const editProject = async () => {
    try {
      const data = {
        harvestId,
        projectId: id,
        database: {
          name: projectName,
          leadDirector,
          code: projectCode,
          projectManager,
          startDate,
          endDate,
          lowEstimate,
          highEstimate,
          designation,
          expenseBudget,
        },
        harvest: {
          name: projectName,
          code: projectCode,
          starts_on: startDate,
          ends_on: endDate,
          budget_is_monthly: budgetReset,
        },
      };

      // if a contract was selected
      if (selectedContractId) {
        data.database.contract = selectedContractId;
      }

      await editProjectOnApi(data);

      openAlertPopup("Success", "Project has been updated successfully", true);

      // route back to the project dashboard
      history.push(`/projects/${id}`);
    } catch (err) {
      console.error("error updating project", err);

      const errMessage = typeof err === "string" ? err : "";

      openAlertPopup("Failure", `Project failed to update. ${errMessage}`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      const strategyDirectorMembers = membersFromApi.filter(
        (member) =>
          member.role.tier === "director" || member.role.tier === "partner",
      );

      const managerMembers = membersFromApi.filter(
        (member) =>
          member.role.role === "Production Manager" ||
          member.role.role === "Sr Partnership Manager",
      );

      setStrategyDirectors(strategyDirectorMembers);
      setProjectManagers(managerMembers);
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get project information
  |--------------------------------------------------------------------------
  */
  async function getProject() {
    try {
      const result = await getProjectFromApi(id);

      setProject(result.databaseProject);
      setHarvestProject(result.harvestProject);
      setHarvestId(result.databaseProject.harvestId);
      setProjectClient(result.databaseProject.client);
      setProjectContract(result.databaseProject.contract);

      setProjectName(result.databaseProject.name);
      setProjectManager(result.databaseProject.projectManager);
      setProjectCode(result.databaseProject.code);
      setStartDate(result.databaseProject.startDate.substring(0, 10));
      setEndDate(result.databaseProject.endDate.substring(0, 10));
      setLowEstimate(result.databaseProject.lowEstimate);
      setHighEstimate(result.databaseProject.highEstimate);
      setExpenseBudget(result.databaseProject.expenseBudget);
      setBudgetReset(result.harvestProject?.budget_is_monthly);
      setLeadDirector(result.databaseProject.leadDirector._id);

      if (result.databaseProject.designation) {
        setDesignation(result.databaseProject.designation);
      }

      // if the project doesn't have a contract
      if (!result.databaseProject.contract) {
        // get the client's contracts
        getContracts(result.databaseProject.client._id);
      }
    } catch (error) {
      console.error("error getting project details", error);

      openAlertPopup(
        "Sorry",
        "There was an error fetching this project's details.",
      );
    }
  }

  // get the client's contracts
  const getContracts = async (clientId) => {
    try {
      setLoadingContracts(true);

      // get this client's contracts
      const contractsFromApi = await getContractsFromApi({
        company: clientId,
      });

      // filter out the closed contracts
      const activeContracts = contractsFromApi.filter(
        (thisContract) =>
          thisContract.status.toLowerCase().indexOf("closed") === -1,
      );

      // sort contracts by alpha
      activeContracts.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setContracts(activeContracts);
    } catch (error) {
      console.error("error getting client contracts", error);
    }

    setLoadingContracts(false);
  };

  const getContractBudget = async () => {
    try {
      const contractBudgetDetails = await getContractBudgetDetailsFromApi(
        selectedContractId,
      );

      // remaining = total budget minus the greater of Budgeted or Billed amount

      const { total, budgeted, billed } = contractBudgetDetails;

      const greaterValue = budgeted > billed ? budgeted : billed;

      setContractRemaining(total - greaterValue);
    } catch (error) {
      console.error("error getting contract budget details", error);
    }
  };

  const handleArchiveToggle = async (shouldArchive) => {
    const projectData = { ...project };

    try {
      const confirmed = window.confirm(
        `Are you sure you want to ${
          shouldArchive ? "archive" : "unarchive"
        } this project?`,
      );

      if (confirmed) {
        // update in state
        setProject({
          ...projectData,
          archived: shouldArchive,
        });

        const data = {
          harvestId,
          projectId: id,
          database: {
            archived: shouldArchive,
          },
          harvest: {
            is_active: !shouldArchive,
          },
        };

        await editProjectOnApi(data);
      }
    } catch (error) {
      console.error("error archiving project", error);

      setProject(projectData);
    }
  };

  const selectedContract = contracts
    ? contracts.find((thisContract) => thisContract._id === selectedContractId)
    : null;

  return (
    <>
      {project ? (
        <>
          <ProjectsHeader>
            <div>
              <h1>
                {projectName}{" "}
                {project.archived ? (
                  <small style={{ color: colors.red }}>(ARCHIVED)</small>
                ) : null}
              </h1>
              <p>
                {projectClient ? (
                  <>
                    Client:{" "}
                    <SimpleLink
                      as={Link}
                      to={`/manage/clients/${projectClient._id}`}
                    >
                      {projectClient.acronym
                        ? `${projectClient.acronym} -`
                        : ""}{" "}
                      {projectClient.name}
                    </SimpleLink>
                  </>
                ) : null}
              </p>
              <p>
                {projectContract ? (
                  <>
                    Contract:{" "}
                    <SimpleLink
                      as={Link}
                      to={`/manage/clients/${projectClient._id}/contracts/${projectContract._id}`}
                    >
                      {projectContract.name}
                    </SimpleLink>
                  </>
                ) : null}
              </p>
            </div>

            <div>
              <FancyLink
                color="red"
                onClick={() => {
                  handleArchiveToggle(!project.archived);
                }}
              >
                {project.archived ? "Unarchive" : "Archive"} Project
              </FancyLink>
            </div>
          </ProjectsHeader>

          <ProjectDetails archived={project.archived}>
            {project.archived ? (
              <ArchivedContainer>
                <ArchivedMessage>
                  To edit the project, please unarchive the project
                </ArchivedMessage>
              </ArchivedContainer>
            ) : null}

            <div>
              <Field>
                <Label>Project Name</Label>
                <Input
                  required
                  name="projectName"
                  type="text"
                  defaultValue={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </Field>
              <Field>
                <Label>Project Code</Label>
                <Input
                  required
                  name="projectCode"
                  type="text"
                  defaultValue={projectCode}
                  onChange={(e) => setProjectCode(e.target.value)}
                />
              </Field>
              {projectContract ? (
                <Field>
                  <Label>Project Type</Label>

                  <StyledSelectDropdown
                    onChange={(e) => setDesignation(e.value)}
                    placeholder="Select Project Type"
                    value={
                      designation &&
                      projectTypes.filter((type) => {
                        return type.value === designation;
                      })
                    }
                    options={projectTypes}
                  />
                </Field>
              ) : null}
              <Field>
                <Label>Start Date</Label>
                <Input
                  required
                  name="startDate"
                  type="date"
                  defaultValue={startDate && startDate.substring(0, 10)}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Field>
              <Field>
                <Label>End Date</Label>
                <Input
                  required
                  name="endDate"
                  type="date"
                  defaultValue={endDate && endDate.substring(0, 10)}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Field>

              <FieldGroup>
                <Field>
                  <Label>Low Estimate</Label>
                  <MoneyInput
                    value={lowEstimate}
                    onChange={(e) => {
                      setLowEstimate(e.target.rawValue.replace("$", ""));
                    }}
                  />
                </Field>

                <Field>
                  <Label>High Estimate</Label>
                  <MoneyInput
                    value={highEstimate}
                    onChange={(e) => {
                      setHighEstimate(e.target.rawValue.replace("$", ""));
                    }}
                  />
                </Field>
              </FieldGroup>

              <Field>
                <Label>Expense Budget</Label>
                <MoneyInput
                  value={expenseBudget}
                  onChange={(e) => {
                    setExpenseBudget(e.target.rawValue.replace("$", ""));
                  }}
                />
              </Field>

              <Field>
                <Label>Budget Resets Every Month?</Label>

                <ToggleContainer width="400px">
                  <Toggle
                    label="Budget Resets Every Month?"
                    name="budgetReset"
                    type="checkbox"
                    checked={budgetReset}
                    onChange={() => {
                      setBudgetReset(!budgetReset);
                    }}
                  />
                </ToggleContainer>
              </Field>

              <Field>
                <Label>Strategy Director</Label>
                {leadDirector && strategyDirectors && (
                  <StyledSelectDropdown
                    id="directors"
                    onChange={(e) => setLeadDirector(e.value)}
                    placeholder="Select Director"
                    defaultValue={
                      leadDirector &&
                      strategyDirectors
                        ?.filter((director) => director._id === leadDirector)
                        ?.map((item) => ({
                          label: item.name,
                          value: item._id,
                        }))
                    }
                    options={strategyDirectors?.map((director) => ({
                      label: director.name,
                      value: director._id,
                    }))}
                  ></StyledSelectDropdown>
                )}
              </Field>

              <Field>
                <Label>Production Manager</Label>
                {projectManager && projectManagers ? (
                  <StyledSelectDropdown
                    id="projectManagers"
                    onChange={(e) => setProjectManager(e.value)}
                    placeholder="Select Production Manager"
                    value={
                      projectManager &&
                      projectManagers &&
                      projectManagers
                        .filter((manager) => {
                          return manager._id === projectManager;
                        })
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item._id,
                          };
                        })
                    }
                    options={
                      projectManagers &&
                      projectManagers.map((manager) => {
                        return {
                          label: manager.name,
                          value: manager._id,
                        };
                      })
                    }
                  ></StyledSelectDropdown>
                ) : (
                  <StyledSelectDropdown
                    id="projectManagers"
                    onChange={(e) => setProjectManager(e.value)}
                    placeholder="Select Production Manager"
                    options={
                      projectManagers &&
                      projectManagers.map((manager) => {
                        return {
                          label: manager.name,
                          value: manager._id,
                        };
                      })
                    }
                  ></StyledSelectDropdown>
                )}
              </Field>

              <SaveContainer>
                <Button
                  onClick={() => editProject()}
                  disabled={
                    !(
                      projectName &&
                      projectCode &&
                      startDate &&
                      endDate &&
                      leadDirector &&
                      projectManager
                    )
                  }
                >
                  Save
                </Button>
              </SaveContainer>
            </div>

            <div style={{ width: "300px" }}>
              {!projectContract ? (
                <>
                  <Field>
                    <Label>Contract</Label>
                    <StyledSelectDropdown
                      id="contract"
                      onChange={(e) => setSelectedContractId(e.value)}
                      isLoading={loadingContracts}
                      defaultValue={
                        selectedContract
                          ? {
                              label: `${selectedContract.name} — ${capitalize(
                                selectedContract.status,
                              )}`,
                              value: selectedContract._id,
                            }
                          : null
                      }
                      options={
                        contracts
                          ? contracts.map((thisContract) => ({
                              label: `${thisContract.name} — ${capitalize(
                                thisContract.status,
                              )}`,
                              value: thisContract._id,
                            }))
                          : null
                      }
                    />
                  </Field>

                  <Field>
                    {selectedContract ? (
                      <>
                        <Label>Contract Budget</Label>

                        <div>Total: {getCurrency(selectedContract.budget)}</div>

                        <PotentiallyDangerous
                          hasDanger={
                            contractRemaining !== null && contractRemaining <= 0
                          }
                        >
                          Remaining:{" "}
                          {contractRemaining !== null
                            ? getCurrency(contractRemaining)
                            : "..."}
                        </PotentiallyDangerous>
                      </>
                    ) : null}
                  </Field>

                  <Field>
                    <Label>Project Type</Label>

                    <StyledSelectDropdown
                      onChange={(e) => setDesignation(e.value)}
                      placeholder="Select Project Type"
                      value={
                        designation &&
                        projectTypes.filter((type) => {
                          return type.value === designation;
                        })
                      }
                      options={projectTypes}
                    />
                  </Field>
                </>
              ) : null}
            </div>
          </ProjectDetails>
        </>
      ) : null}
    </>
  );
};

const ProjectsHeader = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 40px 50px;
  border-bottom: 1px solid #cec9d4;
  margin-bottom: 30px;

  h1 {
    font-size: 28px;
    color: #373040;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    color: #373040;
    margin-bottom: 0.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ProjectDetails = styled.div`
  display: flex;
  gap: 30px;
  position: relative;

  margin-left: 50px;
  margin-bottom: 100px;

  input {
    width: 400px;
  }
`;

const ArchivedContainer = styled.div`
  position: absolute;
  height: calc(100% + 130px);
  width: calc(100% + 50px);
  left: -50px;
  top: -30px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArchivedMessage = styled.div`
  margin-bottom: 0px;
  background-color: white;
  padding: 20px;
  font-size: 18px;
`;

const SaveContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 70px;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 400px;
`;

const PotentiallyDangerous = styled.span`
  font-weight: ${(props) => (props.hasDanger ? 700 : undefined)};
  color: ${(props) => (props.hasDanger ? props.theme.colors.red : undefined)};
`;

export default ProjectEdit;
