import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import panzoom from "panzoom";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { motion, AnimatePresence } from "framer-motion";

import { useAuth } from "../../context/authContext";

import { respondTo, visuallyHidden } from "../../styles/styleHelpers";
import { highlightTags } from "../../utils/helpers";

import ZoomInIcon from "../icons/ZoomInIcon";
import ZoomOutIcon from "../icons/ZoomOutIcon";
import DrawIcon from "../icons/DrawIcon";
import DotIcon from "../icons/DotIcon";
import SplitIcon from "../icons/SplitIcon";
import SendIcon from "../icons/Send";
import PaginationLeftIcon from "../icons/PaginationLeftIcon";
import PaginationRightIcon from "../icons/PaginationRightIcon";

import DefaultLink from "../links/DefaultLink";
import CloseButton from "../buttons/CloseButton";
import { Input } from "../Form";
import FileComment from "../FileComment";
import ProfileImage from "../ProfileImage";
import SelectDropdown from "../SelectDropdown";
import Button from "../newButtons/Button";
import Checklist from "./Checklist";
import Workspace from "./workspace";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileModalView = ({
  id,
  isOpen,
  close,
  fileInfo,
  fileType,
  fileName,
  fileVersions,
  uploadNewVersion,
  isLoading,
  setIsLoading,
  creator,
  tool,
  setTool,
  dimensions,
  setImageDimensions,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  comments,
  newComment,
  saveNewComment,
  clearNewComment,
  newMessage,
  setNewMessage,
  highlightComment,
  hoverComment,
  numPages,
  setNumPages,
  pageNumber,
  incPage,
  decPage,
  editComment,
  deleteComment,
  completeComment,
  feedbackCompleted,
  setSortBy,
  createdDate,
  updatedDate,
  latestVersion,
  currentDeliverable,
  denyDeliverable,
  approveDeliverable,
  optionalApproveDeliverable,
  submitForReview,
}) => {
  const { user } = useAuth();
  const [editedMessage, setEditedMessage] = useState("");
  const [versionModalActive, setVersionModalActive] = useState(false);
  const [zoomPercentage, setZoomPercentage] = useState(100);
  const [zoomContainer1, setZoomContainer1] = useState(null);
  const [zoomContainer2, setZoomContainer2] = useState(null);
  const [splitView, setSplitView] = useState(false);
  const [fileVersion, setFileVersion] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isOptionalApprover, setIsOptionalApprover] = useState(false);
  const [highlightedComment, setHighlightedComment] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Get the file version number
  |--------------------------------------------------------------------------
  */
  const versionNumber = fileVersion
    ? fileVersions.findIndex((version) => version._id === fileVersion._id) + 1
    : 0;

  /*
  |--------------------------------------------------------------------------
  | Set the file version to the latest version by default
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setFileVersion(latestVersion);
  }, [latestVersion]);

  /*
  |--------------------------------------------------------------------------
  | Reset the highlighted comment every time the file version changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (highlightedComment) {
      setHighlightedComment(null);
    }
  }, [fileVersion]);

  /*
  |--------------------------------------------------------------------------
  | Set isApprover and isOptionalApprover on currentDeliverable change
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (currentDeliverable) {
      // currentDeliverable.comments
      // Set whether this user is an owner of the current deliverable
      if (currentDeliverable.owner._id === user._id) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }

      // Set whether this user is an approver of the current deliverable
      if (
        currentDeliverable.approvers &&
        currentDeliverable.approvers.some(
          (approver) => approver._id === user._id,
        )
      ) {
        setIsApprover(true);
      } else {
        setIsApprover(false);
      }

      // Set whether this user is an optional approver of the current deliverable
      if (
        currentDeliverable.optionalApprovers &&
        currentDeliverable.optionalApprovers.some(
          (approver) => approver._id === user._id,
        )
      ) {
        setIsOptionalApprover(true);
      } else {
        setIsOptionalApprover(false);
      }
    }
  }, [currentDeliverable]);

  /*
  |--------------------------------------------------------------------------
  | Whenever splitView is toggled on, set the file version to the 2nd to last
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (splitView) {
      setFileVersion(fileVersions[fileVersions.length - 2]);
    }
  }, [splitView]);

  /*
  |--------------------------------------------------------------------------
  | Set up the zoom container
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (isOpen) {
      const element1 = document.getElementById(`workspace-1`);
      const element2 = document.getElementById(`workspace-2`);

      // Dipose of the existing zoom containers
      if (zoomContainer1) {
        zoomContainer1.dispose();
      }

      if (zoomContainer2) {
        zoomContainer2.dispose();
      }

      // Set the zoom containers in state if they exist
      if (element1) {
        setZoomContainer1(
          panzoom(element1, {
            zoomDoubleClickSpeed: 1,
          }),
        );
      }

      if (element2) {
        setZoomContainer2(
          panzoom(element2, {
            zoomDoubleClickSpeed: 1,
          }),
        );
      }
    }
  }, [isOpen, splitView, fileVersion, currentDeliverable]);

  /*
  |--------------------------------------------------------------------------
  | Pause or resume panzoom based on if a tool is selected
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (tool === "draw" || tool === "point") {
      const element1 = document.getElementById(`workspace-1`);
      if (zoomContainer1 && element1) {
        zoomContainer1.pause();
      }
      if (zoomContainer2) {
        zoomContainer2.pause();
      }
    } else if (tool !== "draw" && tool !== "point") {
      if (zoomContainer1) {
        zoomContainer1.resume();
      }
      if (zoomContainer2) {
        zoomContainer2.resume();
      }
    }
  }, [tool]);

  /*
  |--------------------------------------------------------------------------
  | Display the zoom percentage whenever it changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (zoomContainer1) {
      zoomContainer1.on("zoom", () => {
        let scale = zoomContainer1.getTransform().scale;
        scale = Math.round(scale * 100);
        setZoomPercentage(scale);
      });

      if (zoomContainer2) {
        zoomContainer1.on("transform", (e) => {
          const position = e.getTransform();
          zoomContainer2.moveTo(position.x, position.y);
          zoomContainer2.zoomAbs(0, 0, position.scale);
        });
      }
    }
  }, [zoomContainer1, zoomContainer2]);

  /*
  |--------------------------------------------------------------------------
  | Zoom based on the control that was clicked
  |--------------------------------------------------------------------------
  */
  const controlZoom = (direction) => {
    if (zoomContainer1) {
      if (direction === "out") {
        zoomContainer1.smoothZoom(0, 0, 0.8);
      } else {
        zoomContainer1.smoothZoom(0, 0, 1.2);
      }
    }

    if (zoomContainer2) {
      if (direction === "out") {
        zoomContainer2.smoothZoom(0, 0, 0.8);
      } else {
        zoomContainer2.smoothZoom(0, 0, 1.2);
      }
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Open the version modal after all the feedback has been completed
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setVersionModalActive(feedbackCompleted);
  }, [feedbackCompleted]);

  /*
  |--------------------------------------------------------------------------
  | Set the number of pages
  |--------------------------------------------------------------------------
  */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fieldName = `${id}-modal-upload`;

  return (
    <AnimatePresence>
      {isOpen && currentDeliverable ? (
        <Modal
          initial={{ opacity: 0.0, x: "0", y: "50%" }}
          animate={{ opacity: 1, x: "0", y: "0" }}
          exit={{ opacity: 0.0, x: "0", y: "50%" }}
          transition={{ type: "default", duration: 0.3 }}
        >
          <MainContainer>
            {fileType !== "link" && feedbackCompleted && versionModalActive ? (
              <ScreenBlocker>
                <VersionModal
                  initial={{ opacity: 0.0, x: "-50%", y: "0" }}
                  animate={{ opacity: 1, x: "-50%", y: "-50%" }}
                  exit={{ opacity: 0.0, x: "-50%", y: "0" }}
                  transition={{ type: "default", duration: 0.3 }}
                >
                  <p>
                    All feedback has been completed, please upload a new version
                  </p>

                  <AddVersion>
                    <label htmlFor={fieldName}>
                      <span>+</span> Add new version
                    </label>
                  </AddVersion>

                  <CloseButton onClick={() => setVersionModalActive(false)} />
                </VersionModal>
              </ScreenBlocker>
            ) : null}

            <HeaderRow>
              <HeaderLeft>
                <HeaderLeftTop>
                  <Name isLink={fileType === "link"}>
                    {fileType === "link" ? (
                      <a href={fileName} target="_blank">
                        Link for {currentDeliverable.name}
                      </a>
                    ) : (
                      fileName
                    )}
                  </Name>
                  {fileType !== "link" && fileVersion ? (
                    <VersionDropdown>
                      <ActiveVersion isActive>
                        Version {versionNumber}
                      </ActiveVersion>

                      <VersionReveal>
                        {fileVersions && fileVersions.length
                          ? fileVersions.map((version, versionIndex) => (
                              <Version
                                key={version._id}
                                as="button"
                                isActive={version._id === fileVersion._id}
                                onClick={
                                  version._id !== fileVersion._id
                                    ? () => {
                                        setIsLoading(true);
                                        setFileVersion(version);
                                      }
                                    : undefined
                                }
                              >
                                Version {versionIndex + 1}
                                <br />
                                <small>
                                  {format(new Date(version.date), "MM/dd/yy")}
                                </small>
                              </Version>
                            ))
                          : null}
                      </VersionReveal>
                    </VersionDropdown>
                  ) : null}

                  {fileType !== "link" ? (
                    <DeliverableAction>
                      <AddVersion>
                        <label htmlFor={fieldName}>
                          <span>+</span> Add new version
                        </label>
                      </AddVersion>

                      <FileInput
                        type="file"
                        name={fieldName}
                        id={fieldName}
                        onChange={(e) => {
                          if (e.target.files.length) {
                            const fileUpload = e.target.files[0];
                            uploadNewVersion(fileUpload);
                            setVersionModalActive(false);
                          }
                        }}
                      />
                    </DeliverableAction>
                  ) : null}
                </HeaderLeftTop>

                {createdDate ? (
                  <CreatedDate noMargin={fileType === "link"}>
                    Created: {format(new Date(createdDate), "MM/dd/yyyy")}
                  </CreatedDate>
                ) : null}
              </HeaderLeft>

              <UploadInfo>
                <Creator>
                  <ProfileImage
                    type="profile"
                    handle={creator.handle}
                    name={creator.name}
                    xsmall
                    showStatus
                  />

                  <UploaderName>{creator.name}</UploaderName>
                </Creator>

                {updatedDate ? (
                  <UploadText>
                    Last Updated: {format(new Date(updatedDate), "MM/dd/yyyy")}
                  </UploadText>
                ) : null}
              </UploadInfo>

              <CloseButton onClick={close} color={"white"} />
            </HeaderRow>

            {splitView && fileType !== "link" ? (
              <WorkspaceContainer>
                <Workspace
                  user={user}
                  creator={creator}
                  saveNewComment={saveNewComment}
                  fileInfo={fileInfo}
                  fileType={fileType}
                  isFileLoading={isLoading}
                  setIsFileLoading={setIsLoading}
                  fileVersions={fileVersions}
                  defaultFileVersion={fileVersion}
                  setImageDimensions={setImageDimensions}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  pageNumber={pageNumber}
                  dimensions={dimensions}
                  handleMouseDown={handleMouseDown}
                  handleMouseMove={handleMouseMove}
                  handleMouseUp={handleMouseUp}
                  comments={comments}
                  newComment={newComment}
                  tool={tool}
                  setHighlightedComment={setHighlightedComment}
                  hoverComment={hoverComment}
                  workspaceNumber={1}
                  noDrawing
                  position="left"
                  editComment={editComment}
                  editedMessage={editedMessage}
                  setEditedMessage={setEditedMessage}
                  deleteComment={deleteComment}
                  canComment={isOwner || isApprover || isOptionalApprover}
                  completeComment={completeComment}
                />
                <Workspace
                  user={user}
                  creator={creator}
                  saveNewComment={saveNewComment}
                  fileInfo={fileInfo}
                  fileType={fileType}
                  isFileLoading={isLoading}
                  setIsFileLoading={setIsLoading}
                  fileVersions={fileVersions}
                  defaultFileVersion={latestVersion}
                  setImageDimensions={setImageDimensions}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  pageNumber={pageNumber}
                  dimensions={dimensions}
                  handleMouseDown={handleMouseDown}
                  handleMouseMove={handleMouseMove}
                  handleMouseUp={handleMouseUp}
                  comments={comments}
                  newComment={newComment}
                  tool={tool}
                  highlightComment={highlightComment}
                  highlightedComment={highlightedComment}
                  setHighlightedComment={setHighlightedComment}
                  hoverComment={hoverComment}
                  noVersionDropdown
                  latestVersion
                  workspaceNumber={2}
                  position="right"
                  editComment={editComment}
                  editedMessage={editedMessage}
                  setEditedMessage={setEditedMessage}
                  deleteComment={deleteComment}
                  canComment={isOwner || isApprover || isOptionalApprover}
                  completeComment={completeComment}
                />
              </WorkspaceContainer>
            ) : !splitView && fileType !== "link" ? (
              <WorkspaceContainer>
                <Workspace
                  user={user}
                  creator={creator}
                  saveNewComment={saveNewComment}
                  fileInfo={fileInfo}
                  fileType={fileType}
                  isFileLoading={isLoading}
                  setIsFileLoading={setIsLoading}
                  fileVersions={fileVersions}
                  defaultFileVersion={fileVersion}
                  setImageDimensions={setImageDimensions}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  pageNumber={pageNumber}
                  dimensions={dimensions}
                  handleMouseDown={handleMouseDown}
                  handleMouseMove={handleMouseMove}
                  handleMouseUp={handleMouseUp}
                  comments={comments}
                  newComment={newComment}
                  tool={tool}
                  highlightComment={highlightComment}
                  highlightedComment={highlightedComment}
                  setHighlightedComment={setHighlightedComment}
                  hoverComment={hoverComment}
                  workspaceNumber={1}
                  editComment={editComment}
                  editedMessage={editedMessage}
                  setEditedMessage={setEditedMessage}
                  deleteComment={deleteComment}
                  canComment={isOwner || isApprover || isOptionalApprover}
                  completeComment={completeComment}
                />
              </WorkspaceContainer>
            ) : (
              <LinkWorkspace>
                <Iframe src={fileName}></Iframe>
              </LinkWorkspace>
            )}

            <Footer>
              {fileType !== "link" ? (
                <Controls>
                  <ZoomControls>
                    <ZoomButton
                      onClick={() => {
                        controlZoom("in");
                      }}
                    >
                      <ZoomInIcon />
                    </ZoomButton>
                    <ZoomButton
                      onClick={() => {
                        controlZoom("out");
                      }}
                    >
                      <ZoomOutIcon />
                    </ZoomButton>

                    <ZoomAmount>{zoomPercentage}%</ZoomAmount>
                  </ZoomControls>

                  {isOwner || isApprover || isOptionalApprover ? (
                    <>
                      <BorderedControlButton
                        isactive={tool === "draw"}
                        onClick={() =>
                          tool !== "draw" ? setTool("draw") : setTool("none")
                        }
                      >
                        <DrawIcon />
                      </BorderedControlButton>
                      <BorderedControlButton
                        isactive={tool === "point"}
                        onClick={() =>
                          tool !== "point" ? setTool("point") : setTool("none")
                        }
                      >
                        <DotIcon />
                      </BorderedControlButton>
                    </>
                  ) : null}

                  {fileVersions &&
                  fileVersions.length &&
                  fileVersions.length > 1 ? (
                    <BorderedControlButton
                      isactive={splitView}
                      onClick={() => setSplitView(!splitView)}
                    >
                      <SplitIcon />
                    </BorderedControlButton>
                  ) : null}

                  {/* <BorderedControlButton>
                  <a
                    href={downloadUrl}
                    download={`${fileName}.${fileInfo.filePath
                      .split(".")
                      .pop()}`}
                  >
                    <DownloadIcon />
                  </a>
                </BorderedControlButton> */}

                  {fileType === "pdf" && (
                    <Pagination>
                      <ControlButton
                        disabled={
                          pageNumber === 1 || pageNumber === 0 ? true : false
                        }
                        onClick={() => decPage()}
                      >
                        <PaginationLeftIcon />
                      </ControlButton>
                      <PageNumber>
                        Pg. {pageNumber} of {numPages}
                      </PageNumber>
                      <ControlButton
                        disabled={pageNumber === numPages ? true : false}
                        onClick={() => incPage()}
                      >
                        <PaginationRightIcon />
                      </ControlButton>
                    </Pagination>
                  )}
                </Controls>
              ) : null}

              {isOwner ? (
                <SubmitForReview
                  disabled={currentDeliverable.status === "In review"}
                  state="success"
                  onClick={() => submitForReview()}
                >
                  {currentDeliverable.status === "In review"
                    ? "Submitted"
                    : "Submit For Review"}
                </SubmitForReview>
              ) : isApprover ? (
                <DeliverableAction>
                  <Approve
                    disabled={
                      currentDeliverable.approvedBy?.some(
                        (approver) =>
                          approver.approver === user._id &&
                          approver.status === "approve" &&
                          approver.iteration ===
                            currentDeliverable.iterations[
                              currentDeliverable.iterations.length - 1
                            ]?._id,
                      ) || currentDeliverable.status !== "In review"
                    }
                    state="success"
                    onClick={() => approveDeliverable()}
                  >
                    {currentDeliverable.approvedBy?.some(
                      (approver) =>
                        approver.approver === user._id &&
                        approver.status === "approve" &&
                        approver.iteration ===
                          currentDeliverable.iterations[
                            currentDeliverable.iterations.length - 1
                          ]?._id,
                    ) &&
                    !currentDeliverable.approvedBy?.some(
                      (approver) =>
                        approver.approver === user._id &&
                        approver.status === "deny" &&
                        approver.iteration ===
                          currentDeliverable.iterations[
                            currentDeliverable.iterations.length - 1
                          ]?._id,
                    )
                      ? "Approved"
                      : "Approve"}
                  </Approve>
                  <Deny
                    disabled={currentDeliverable.status !== "In review"}
                    state="error"
                    onClick={() => denyDeliverable()}
                  >
                    {currentDeliverable.approvedBy?.some(
                      (approver) =>
                        approver.approver === user._id &&
                        approver.status === "deny" &&
                        approver.iteration ===
                          currentDeliverable.iterations[
                            currentDeliverable.iterations.length - 1
                          ]?._id,
                    )
                      ? "Denied"
                      : "Deny"}
                  </Deny>
                </DeliverableAction>
              ) : isOptionalApprover ? (
                <DeliverableAction>
                  <Approve
                    disabled={
                      currentDeliverable.optionalApprovedBy?.some(
                        (approver) => approver.approver === user._id,
                      ) || currentDeliverable.status !== "In review"
                    }
                    state="success"
                    onClick={() => optionalApproveDeliverable()}
                  >
                    {currentDeliverable.optionalApprovedBy?.some(
                      (approver) => approver.approver === user._id,
                    )
                      ? "Approved"
                      : "Approve"}
                  </Approve>
                </DeliverableAction>
              ) : null}
            </Footer>
          </MainContainer>

          <Sidebar>
            <SidebarInner>
              <CommentsContainer>
                <CommentsHeader>
                  <CommentsCount>
                    <CommentsNumber>
                      {comments && comments.length ? comments.length : 0}
                    </CommentsNumber>
                    <CommentsTitle>Comments</CommentsTitle>
                  </CommentsCount>
                  <SortComments>
                    <SelectDropdown
                      placeholder="Sort By"
                      short
                      options={[
                        {
                          label: "Date",
                          value: "Date",
                        },
                        {
                          label: "Page",
                          value: "Page",
                        },
                      ]}
                      onChange={(e) => setSortBy(e.value)}
                    />
                  </SortComments>
                </CommentsHeader>

                <Comments>
                  {comments && comments.length ? (
                    comments.map((item) => {
                      if (
                        item.comment &&
                        !item.parent &&
                        ((splitView &&
                          latestVersion &&
                          item.version === latestVersion._id) ||
                          (!splitView &&
                            fileVersion &&
                            item.version === fileVersion._id))
                      ) {
                        return (
                          <FileComment
                            id={item.id}
                            key={item.id}
                            commentId={item.id}
                            commentNum={item.commentNum}
                            page={item.page}
                            className="comment"
                            author={item.comment.author}
                            postedDate={item.comment.postedDate}
                            message={highlightTags(item.comment.message)}
                            comments={comments}
                            isHighlighted={item.highlighted}
                            replies={item.replies}
                            editComment={() =>
                              editComment(item.id, editedMessage)
                            }
                            editedMessage={editedMessage}
                            setEditedMessage={setEditedMessage}
                            deleteComment={() => {
                              const deleteConfirmation = window.confirm(
                                "Are you sure you want to delete this comment? This will delete all replies associted with this comment.",
                              );

                              if (deleteConfirmation) {
                                deleteComment(item.id);
                              }
                            }}
                            completed={item.comment.completed}
                            completable={
                              user._id === creator._id ? true : false
                            }
                            completeComment={completeComment}
                            editable={user._id === item.comment.author._id}
                            deletable={user._id === item.comment.author._id}
                            onClick={() => {
                              if (item.highlighted) {
                                setHighlightedComment(null);
                              } else {
                                setHighlightedComment({ ...item });
                              }
                              highlightComment(
                                item.highlighted ? null : item.id,
                              );
                            }}
                          />
                        );
                      }
                    })
                  ) : (
                    <NoComments>There are no comments yet</NoComments>
                  )}

                  {newComment ||
                  (fileType === "link" &&
                    currentDeliverable &&
                    (isOwner || isApprover || isOptionalApprover)) ? (
                    <NewCommentContainer>
                      <NewComment
                        onSubmit={(e) => {
                          e.preventDefault();
                          saveNewComment(newMessage);
                        }}
                      >
                        <NewMessage
                          id="new-comment-message"
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                        />

                        <Submit
                          type="submit"
                          title="Save"
                          aria-label="Save comment"
                        >
                          <SendIcon aria-hidden="true" />
                        </Submit>
                      </NewComment>

                      <Cancel>
                        {fileType !== "link" ? (
                          <CommentAction
                            type="button"
                            onClick={clearNewComment}
                            white
                          >
                            Cancel
                          </CommentAction>
                        ) : null}
                      </Cancel>
                    </NewCommentContainer>
                  ) : null}
                </Comments>
              </CommentsContainer>
              <Checklist
                delivId={currentDeliverable._id}
                items={
                  fileInfo?.deliverable?.checklist
                    ? fileInfo.deliverable.checklist
                    : []
                }
              />
            </SidebarInner>
          </Sidebar>
        </Modal>
      ) : null}
    </AnimatePresence>
  );
};

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  background-color: #1f2937;
  box-shadow: 50px 50px 100px #bbbbbb, -50px -50px 100px #e9e9e9;
`;

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 25, 64, 0.15);
  transition: opacity 0.2s;
`;

const VersionModal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1001;

  display: flex;
  flex-direction: column;

  padding: 50px;

  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.4);

  p {
    margin-bottom: 20px;
  }
`;

const WorkspaceContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

const MainContainer = styled.div`
  position: relative;
  margin-right: 300px;
  padding: 18px 27px;
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;

  ${respondTo("xlarge")} {
    margin-right: 400px;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.oldWhite};
  /* align-items: flex-end; */
  align-items: baseline;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 18px 27px 0;
`;

const DeliverableAction = styled.div`
  display: flex;
  align-items: center;
`;

const UploadInfo = styled.div`
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 50px;
  font-weight: 500;
  font-size: 14px;
  color: #d1d5db;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  ${respondTo("xlarge")} {
    margin-right: 90px;
  }
`;

const UploadText = styled.p`
  margin-bottom: 0;
  margin-left: 15px;
`;

const Footer = styled.div`
  position: absolute;
  left: 0px;
  padding: 0px 27px;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const ZoomControls = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    background-color: ${(props) => props.theme.colors.coolGray500};
  }
`;

const ZoomAmount = styled.span`
  color: white;
  font-weight: 500;
  margin-left: 10px;
  line-height: 1;
  font-size: 14px;
  width: 50px;
`;

const ControlButton = styled.button`
  opacity: ${(props) => (props.isactive ? "1" : "0.5")};
  transition: opacity 0.2s;
  cursor: pointer;
  line-height: 0px;

  ${(props) =>
    props.isactive
      ? ``
      : css`
          &:hover {
            opacity: 0.8;
          }
        `}
`;

const BorderedControlButton = styled(ControlButton)`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 15px;

  &::after {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    margin-left: 15px;
    background-color: ${(props) => props.theme.colors.coolGray500};
  }
`;

const ZoomButton = styled(ControlButton)`
  margin-right: 2px;
`;

// const Pagi = styled(Pagination)`
//   margin-left: 21px;

//   button,
//   p {
//     margin: 0px 5px;
//   }
// `;

const Sidebar = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.coolGray800};
  width: 300px;
  //padding: 10px 20px 20px 20px;

  ${respondTo("xlarge")} {
    width: 400px;
    //padding: 20px 30px 30px 30px;
  }
`;

const SidebarInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  height: 100%;
  padding: 86px 20px 85px;
`;

const CommentsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.coolGray600};
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const CommentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
`;

const CommentsCount = styled.div`
  display: flex;
  align-items: center;
`;

const CommentsNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.indigo100};
  color: ${(props) => props.theme.colors.indigo600};
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  margin-right: 7px;
`;

const CommentsTitle = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.coolGray200};
`;

const Comments = styled.div`
  overflow-y: auto;
  flex: 1;
`;

const NoComments = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.coolGray200};
  margin-top: auto;
  text-align: center;
  margin-bottom: 10px;
  opacity: 0.7;
`;

const NewCommentContainer = styled.div`
  margin-top: auto;
  padding: 20px;
`;

const NewComment = styled.form`
  position: relative;
`;

const NewMessage = styled(Input)`
  height: 36px;
  width: 100%;
  padding: 0px 10px;
`;

const Cancel = styled.div`
  display: flex;
  margin-top: 10px;

  color: ${(props) => props.theme.colors.coolGray200};
`;

const FileInput = styled.input`
  ${visuallyHidden()}
`;

const SortComments = styled.div`
  width: 150px;
  margin-right: 0px;
`;

const HeaderLeft = styled.div``;

const HeaderLeftTop = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.h2`
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0px;
  white-space: nowrap;

  a {
    color: white;
  }
`;

const CreatedDate = styled.p`
  margin: 0px;
  margin-top: ${(props) => (props.noMargin ? "0px" : "-10px")};
  color: ${(props) => props.theme.colors.coolGray300};
  font-size: 14px;
  font-weight: 500;
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const UploaderName = styled.p`
  margin: 0px 0px 0px 14px;
  color: ${(props) => props.theme.colors.coolGray300};
  font-size: 16px;
  font-weight: 500px;
`;

const AddVersion = styled(Button)`
  align-self: flex-start;

  * {
    cursor: pointer;
  }

  span {
    font-size: 20px;
    line-height: 0px;
    margin-right: 5px;
  }
`;

const Approve = styled(Button)`
  margin: 10px;
`;

const SubmitForReview = styled(Button)``;

const Deny = styled(Button)``;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 100px;
`;

const PageNumber = styled.p`
  margin: 0px 10px;
  color: white;
  font-weight: 500;
  font-size: 14px;
`;

const VersionDropdown = styled.div`
  position: relative;
  margin-left: 22px;
  margin-right: 40px;

  * {
    cursor: pointer;
  }
`;

const VersionReveal = styled.div`
  position: absolute;
  top: 25px;
  left: -5px;
  z-index: 1;

  display: flex;
  flex-direction: column;

  color: ${(props) => props.theme.colors.oldBlack2};
  padding: 15px 17.5px;
  background-color: #fff;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  white-space: nowrap;

  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;

  ${VersionDropdown}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const Version = styled.p`
  font-size: 14px;
  font-weight: ${(props) => (props.isActive ? 600 : 400)};
  text-decoration: ${(props) => (props.isActive ? "underline" : "none")};
  line-height: 1;
  margin-bottom: 15px;
  cursor: pointer;
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.indigo600
      : props.theme.colors.coolGray700};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${VersionReveal} & {
    text-decoration: none;
    cursor: ${(props) => (props.isActive ? "default" : "pointer")};

    &:hover {
      text-decoration: ${(props) => (props.isActive ? "none" : "underline")};
    }
  }
`;

const ActiveVersion = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  cursor: default;
  color: white;
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border: 2px solid white;
    border-top: none;
    border-left: none;
    margin-left: 15px;
    margin-top: -5px;
    transform: rotate(45deg);
  }
`;

const LinkWorkspace = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 85%;
  border: none;
`;

const CommentAction = styled(DefaultLink)`
  font-size: 10px;
  color: ${(props) => props.theme.colors.coolGray200};
`;

const Submit = styled.button`
  position: absolute;
  right: 1px;
  bottom: 1px;

  /* so it doesn't overlap the 1px border on top and bottom of the input */
  height: calc(100% - 2px);

  max-height: 40px;

  display: flex;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: background-color 200ms;

  svg {
    path {
      fill: ${(props) => props.theme.colors.indigo};
      transition: fill 200ms;
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo50};
  }
`;

FileModalView.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
};

FileModalView.defaultProps = {
  close: null,
  isOpen: false,
  fileVersions: [],
};

export default FileModalView;
