import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import { startCase, toLower } from "lodash";

import {
  createDealOnApi,
  getDealsFromApi,
  updateDealOnApi,
  deleteDealsOnApi,
  getMembersFromApi,
  getCompaniesFromApi,
  getContactsFromApi,
} from "../../utils/api";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../utils/sorting";
import { getCurrency, getStandardizedDate } from "../../utils/helpers";
import { dealStagesMain, dealStages } from "../../utils/constants";

import useBulkSelect from "../../hooks/useBulkSelect";
import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";

import {
  TableCell,
  Checkbox,
  SearchInput,
} from "../../components/NewTable/components";
import Table from "../../components/NewTable/index";
import Filters, { FilterGroup } from "../../components/NewTable/Filters";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import Tabs, { TabContent } from "../../components/Tabs";
import ProfileImage from "../../components/ProfileImage";
import CheckMenu from "../../components/NewTable/CheckMenu";

import PageLayout from "./components/pageLayout";
import ModifyDeal from "./components/modifyDeal";
import Editable from "../../components/Editable";

const filterPaths = {
  owner: "owner.name",
  company: "company.name",
  tier: "company.tier",
};

const filters = ["company", "owner", "tier", "status"];

const tabs = [
  // {
  //   name: "Overview",
  //   id: "overview",
  // },
  // {
  //   name: "Tasks",
  //   id: "tasks",
  //   number: 8,
  // },
  {
    name: "Table",
    id: "table",
  },
  // {
  //   name: "Kanban",
  //   id: "kanban",
  // },
  // {
  //   name: "Reports",
  //   id: "reports",
  // },
];

const Deals = () => {
  const history = useHistory();
  const { isImportant } = useAuth();
  const params = new URLSearchParams(document.location.search.substring(1));
  const status = params.get("status");

  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const [originalDeals, setOriginalDeals] = useState([]);
  const [deals, setDeals] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(
    status ? [{ label: "status", value: status }] : [],
  );

  const [currentDeal, setCurrentDeal] = useState(null);
  const [currentTab, setCurrentTab] = useState("table");
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [availOwners, setAvailOwners] = useState([]);
  const [availContacts, setAvailContacts] = useState([]);
  const [availCompanies, setAvailCompanies] = useState([]);

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    clearSelectedItems,
  } = useBulkSelect(deals);

  //  get all the deals and other data needed
  useEffect(() => {
    getDeals();
    setupData();
  }, []);

  // set the deals to display, based on the selected filters
  useEffect(() => {
    if (originalDeals) {
      // If a filter was selected or a sort is active, filter the entries
      if (selectedFilters?.length || searchText) {
        console.log("Selected filters", selectedFilters);
        filterDeals(selectedFilters);
      } else {
        setDeals(originalDeals);
      }
    }
  }, [originalDeals, searchText, selectedFilters]);

  // when update modal is closed
  useEffect(() => {
    // reset the current deal
    if (!updateOpen) {
      setCurrentDeal(null);
    }
  }, [updateOpen]);

  /*
  |--------------------------------------------------------------------------
  | Get all deals
  |--------------------------------------------------------------------------
  */
  const getDeals = async () => {
    setLoading(true);

    try {
      const dealsFromApi = await getDealsFromApi();

      // separate the deals that are and aren't in a terminal stage
      // and sort them so the ones that were last updated the longest ago are first
      const activeDeals = dealsFromApi
        .filter((deal) => dealStagesMain.includes(deal.status))
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
      const doneDeals = dealsFromApi
        .filter((deal) => !dealStagesMain.includes(deal.status))
        .sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

      // display all the active deals before the terminal deals
      const sortedDeals = [...activeDeals, ...doneDeals];

      setOriginalDeals(sortedDeals);
    } catch (error) {
      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we couldn't get the deals. ${errMessage}`,
      );
    }

    setLoading(false);
  };

  const setupData = async () => {
    try {
      const [
        ownersFromApi,
        companiesFromApi,
        contactsFromApi,
      ] = await Promise.all([
        getMembersFromApi(),
        // ? company must be a client
        // ? or not
        getCompaniesFromApi(),
        getContactsFromApi(),
      ]);

      setAvailOwners(ownersFromApi);
      setAvailCompanies(companiesFromApi);
      setAvailContacts(contactsFromApi);
    } catch (error) {
      console.error("error getting data for deals", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Filter the deals
  |--------------------------------------------------------------------------
  */
  const filterDeals = (tempFilters) => {
    let tempDeals = [...originalDeals];

    // Filters the deals
    if (tempFilters && tempFilters.length) {
      tempDeals = filterItems(
        tempFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempDeals,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempDeals = searchItems(searchText, tempDeals, [
        "contacts.firstName",
        "contacts.lastName",
        "owner.name",
        "tier",
        "name",
        "status",
        "description",
        "estimatedBudget",
        "company.name",
      ]);
    }

    setDeals(tempDeals);
  };

  /*
  |--------------------------------------------------------------------------
  | Create a deal in the database
  |--------------------------------------------------------------------------
  */
  const createNewDeal = async (data) => {
    try {
      // create deal on API
      const newDealFromApi = await createDealOnApi(data);

      setCreateOpen(false);
      openAlertPopup("Success", "Deal created.", true);

      // const owner = newDealFromApi.owner
      //   ? availOwners.find(
      //       (availOwner) => availOwner._id === newDealFromApi.owner,
      //     )
      //   : null;

      // const company = newDealFromApi.company
      //   ? availCompanies.find(
      //       (availCompany) => availCompany._id === newDealFromApi.company,
      //     )
      //   : null;

      // const contacts = newDealFromApi.contacts
      //   ? availContacts.filter((availContact) =>
      //       newDealFromApi.contacts.includes(availContact._id),
      //     )
      //   : [];

      // const newDeal = {
      //   ...newDealFromApi,
      //   owner,
      //   company,
      //   contacts,
      // };

      // // add deal to state
      // setOriginalDeals([...originalDeals, newDeal]);

      if (newDealFromApi) {
        // go to the deal
        history.push(`/sales/deals/${newDealFromApi._id}`);
      }
    } catch (err) {
      const errMessage = typeof err === "string" ? err : "";

      openAlertPopup(
        "Error",
        `Sorry, we couldn't create that deal. Please check the entered data and try again. ${errMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update an existing deal
  |--------------------------------------------------------------------------
  */
  const updateDeal = async ({ id, ...data }) => {
    try {
      const updatedDeal = await updateDealOnApi(id, data);

      console.log("Updated deal", updatedDeal);

      const updatedDeals = originalDeals.map((deal) =>
        deal._id === id ? updatedDeal : deal,
      );

      // update deals in state
      setOriginalDeals(updatedDeals);

      clearSelectedItems();

      setUpdateOpen(false);

      openAlertPopup("Deal updated successfully!", null, true);
    } catch (err) {
      openAlertPopup(
        "Update deal failed",
        "Please check the entered data and try again",
      );
      throw new Error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete deals
  |--------------------------------------------------------------------------
  */
  const handleDelete = async () => {
    openPromptPopup({
      header: "Delete selected deals",
      body: `Are you sure you want to delete the ${
        selectedItems.length
      } selected deal${selectedItems.length === 1 ? "" : "s"}?`,
      confirmCallback: async () => {
        try {
          await deleteDealsOnApi({ ids: selectedItems });

          openAlertPopup("Deals deleted successfully!", null, true);

          clearSelectedItems();

          // filter out the deleted deals from state
          setOriginalDeals(
            originalDeals.filter((deal) => !selectedItems.includes(deal._id)),
          );

          closePromptPopup();
        } catch (err) {
          openAlertPopup("Delete deals failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const handleEdit = () => {
    const newDealId = selectedItems[0];
    const newCurrentDeal = deals.find((deal) => deal._id === newDealId);
    setCurrentDeal(newCurrentDeal);
    setUpdateOpen(true);
  };

  return (
    <PageLayout
      title="Deals"
      createButton="Create Deal"
      number={deals.length || 0}
      handleCreate={() => setCreateOpen(true)}
    >
      <ModifyDeal
        isOpen={createOpen}
        close={() => setCreateOpen(false)}
        onSubmit={createNewDeal}
        availOwners={availOwners}
        availContacts={availContacts}
        availCompanies={availCompanies}
      />
      <ModifyDeal
        isOpen={updateOpen}
        close={() => setUpdateOpen(false)}
        onSubmit={updateDeal}
        currentDeal={currentDeal}
        availOwners={availOwners}
        availContacts={availContacts}
        availCompanies={availCompanies}
      />

      <TabContentSection>
        <TabContent isactive={currentTab === "overview"}>
          This is the overview
        </TabContent>

        <TabContent isactive={currentTab === "tasks"}>Another test</TabContent>

        <TabContent isactive={currentTab === "table"}>
          {deals ? (
            <>
              <StyledFilterGroup>
                <CheckMenu
                  disabled={!selectedItems.length}
                  numItems={selectedItems.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
                <Filters
                  selectedFilters={selectedFilters}
                  updateFilters={setSelectedFilters}
                  filters={filters}
                  getUniqueOptions={(filter) =>
                    getUniqueOptions(filter, originalDeals, {
                      exclude: selectedFilters,
                      propPaths: filterPaths,
                    })
                  }
                />

                <SearchInput
                  placeholder="Search items..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </StyledFilterGroup>

              {selectedFilters?.length ? (
                <SelectedFilters
                  filters={selectedFilters}
                  updateFilters={setSelectedFilters}
                />
              ) : null}

              <TableContainer>
                <Table
                  // so the scrolling is handled by the whole viewport, rather than just the table
                  scrollable={false}
                  noPadding
                  headers={[
                    {
                      component: (groupedEntries = deals) => (
                        <Checkbox
                          isChecked={
                            groupedEntries?.length &&
                            // ? in case the items are grouped, reference the adjacent grouped items, rather than the entire array
                            groupedEntries.every((item) =>
                              selectedItems.includes(item._id),
                            )
                          }
                          onClick={() => handleSelectAll(groupedEntries)}
                        />
                      ),
                    },
                    {
                      name: "Owner",
                      accessor: "owner.name",
                    },
                    {
                      name: "Company",
                      accessor: "company.name",
                    },
                    {
                      name: "Name",
                      accessor: "name",
                    },
                    {
                      name: "Status",
                      accessor: "status",
                    },
                    {
                      name: "Tier",
                      accessor: "tier",
                    },
                    {
                      name: "Description",
                      accessor: "description",
                    },
                    {
                      name: "$ Amount",
                      accessor: "estimatedBudget",
                    },
                    {
                      name: "% Chance",
                      accessor: "chanceClosing",
                    },
                    {
                      name: "$ Weighted",
                      accessor: "amountWeighted",
                    },
                  ]}
                  entries={
                    deals
                      ? deals.map((deal) => {
                          const amountWeighted =
                            deal.estimatedBudget && deal.chanceClosing
                              ? deal.estimatedBudget *
                                (deal.chanceClosing * 0.01)
                              : null;

                          return {
                            ...deal,
                            amountWeighted,
                            row: (sortedEntries) => (
                              <>
                                <TableCell>
                                  <Checkbox
                                    isChecked={selectedItems.includes(deal._id)}
                                    onClick={(e) => {
                                      // ? include the sorted entries from the table so if the user sorted or grouped the items, the correct ones will be selected if they shift+click to multi-select
                                      handleSelect(
                                        deal._id,
                                        e.shiftKey,
                                        sortedEntries,
                                      );
                                    }}
                                  />
                                </TableCell>

                                <TableCell full>
                                  {deal.owner ? (
                                    <ProfileImage
                                      name={deal.owner?.name || ""}
                                      handle={deal.owner?.handle || ""}
                                      small
                                    />
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>

                                <TableCell full>
                                  {deal.company ? (
                                    <Link
                                      to={`/sales/companies/${deal.company._id}`}
                                    >
                                      {deal.company?.name}
                                    </Link>
                                  ) : (
                                    "N/A"
                                  )}
                                </TableCell>

                                <TableCell full>
                                  <Link to={`/sales/deals/${deal._id}`}>
                                    {deal.name || "N/A"}
                                  </Link>
                                </TableCell>

                                <TableCell full>
                                  <Editable
                                    id={`${deal._id}-status`}
                                    options={
                                      dealStages
                                        ? dealStages.map((status) => ({
                                            label: status,
                                            value: status,
                                          }))
                                        : []
                                    }
                                    defaultValue={
                                      deal.status
                                        ? {
                                            label: deal.status,
                                            value: deal.status,
                                          }
                                        : null
                                    }
                                    onSave={
                                      isImportant
                                        ? (value) => {
                                            updateDeal({
                                              id: deal._id,
                                              status: value,
                                            });
                                          }
                                        : undefined
                                    }
                                  >
                                    {deal.status
                                      ? startCase(toLower(deal.status))
                                      : "N/A"}
                                  </Editable>
                                </TableCell>

                                <TableCell full>
                                  {deal.company?.tier || "N/A"}
                                </TableCell>

                                <TableCell>
                                  {deal.description || "N/A"}
                                </TableCell>

                                <TableCell full>
                                  {deal.estimatedBudget
                                    ? getCurrency(deal.estimatedBudget)
                                    : "N/A"}
                                </TableCell>

                                <TableCell full>
                                  {deal.chanceClosing
                                    ? `${deal.chanceClosing}%`
                                    : "N/A"}
                                </TableCell>

                                <TableCell full>
                                  {amountWeighted
                                    ? getCurrency(amountWeighted)
                                    : "N/A"}
                                </TableCell>
                              </>
                            ),
                          };
                        })
                      : []
                  }
                />
              </TableContainer>
            </>
          ) : null}
        </TabContent>

        <TabContent isactive={currentTab === "kanban"}>
          Oh no, a Kanban board!
        </TabContent>

        <TabContent isactive={currentTab === "reports"}>
          Some reports
        </TabContent>
      </TabContentSection>
    </PageLayout>
  );
};

const Header = styled.div`
  padding: 0 40px;
`;

const TabContentSection = styled.div`
  width: 100%;
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  z-index: 6;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

export default Deals;
