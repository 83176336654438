import styled, { css } from "styled-components";

export const TableHeader = styled.th`
  position: relative;
  font-size: 14px;
  font-weight: 700;

  padding: 0 60px;
  padding-bottom: 20px;

  transition: 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;

  text-align: ${(props) =>
    props.align === "left"
      ? "left"
      : props.align === "right"
      ? "right"
      : "center"};

  ${(props) =>
    props.onClick
      ? css`
          cursor: pointer;

          &:hover,
          &:focus {
            color: ${(props) => props.theme.colors.slate};
          }
        `
      : ``}

  &::after {
    ${(props) =>
      props.isArrowUp
        ? css`
            content: "↑";
          `
        : props.noArrow
        ? css`
            content: "";
          `
        : css`
            content: "↓";
          `}

    display: inline-block;
    right: 58px;
    top: 0px;
    transform: translateY(1px);
    margin-left: 5px;
    font-family: BlinkMacSystemFont;
    opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  }
`;

export const TableRow = styled.tr`
  font-size: 16px;
  margin-bottom: 25px;

  &:first-child td {
    border-top: 1px solid #f0f1f2;
  }
`;

export const TableCell = styled.td`
  padding: 25px 60px;
  border-bottom: 1px solid #f0f1f2;
  flex-shrink: 0;
  font-weight: ${(props) => (props.danger ? "700" : "500")};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
  text-align: ${(props) =>
    props.align === "left"
      ? "left"
      : props.align === "right"
      ? "right"
      : "center"};

  &:first-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  b {
    font-weight: 700;
  }

  color: ${(props) =>
    props.danger ? props.theme.colors.red : props.theme.colors.oldBlack};
`;

export const TableCellTitle = styled.span`
  font-weight: 700;
  white-space: nowrap;
`;
