import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { darken } from "polished";
import { isToday } from "date-fns";

import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";

import { deleteRecapOnApi } from "../dashboard/utils/api";

import { getStandardizedDate } from "../../utils/helpers";

import Button from "../../components/buttons/Button";
import { Input } from "../../components/Form";

const FinishModal = ({
  kpis,
  currentProjects,
  setCurrentProjects,
  modalOpen,
  getHours,
  setModalOpen,
  finishDay,
  saveRecap,
}) => {
  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();
  const { user } = useAuth();
  const [step, setStep] = useState(0);
  const [submittedKpis, setSubmittedKpis] = useState([]);
  const [amount, setAmount] = useState("");
  const [recorder, setRecorder] = useState("");
  const [stream, setStream] = useState("");
  const [isRecording, setIsRecording] = useState(false);

  const tempNotes = currentProjects.map(() => {
    return "";
  });

  const [notes, setNotes] = useState(tempNotes);

  /*
  |--------------------------------------------------------------------------
  | Merge the audio and video recordings together into 1 stream
  |--------------------------------------------------------------------------
  */
  const mergeAudioStreams = (stream1, stream2) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (stream1 && stream1.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(stream1);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (stream2 && stream2.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(stream2);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };

  /*
  |--------------------------------------------------------------------------
  | Start recording
  |--------------------------------------------------------------------------
  */
  const startRecording = async (index) => {
    const desktopStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: false,
    });

    const voiceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: true,
    });

    const tracks = [
      ...desktopStream.getVideoTracks(),
      ...mergeAudioStreams(desktopStream, voiceStream),
    ];

    const tempStream = new MediaStream(tracks);

    const tempRecorder = new MediaRecorder(tempStream, {
      mimeType: "video/webm; codecs=vp8,opus",
    });

    const chunks = [];
    tempRecorder.ondataavailable = (e) => chunks.push(e.data);
    tempRecorder.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: "video/webm" });
      const recording = document.getElementById(`recording-${index}`);
      recording.src = URL.createObjectURL(recordedBlob);

      const tempProjects = [...currentProjects];
      tempProjects[index].file = new File(
        [recordedBlob],
        `${currentProjects[index].project.name}.webm`,
        {
          type: "video/webm",
        },
      );

      tempProjects[index].isRecording = false;
      setCurrentProjects(tempProjects);
    };

    const tempProjects = [...currentProjects];
    tempProjects[index].isRecording = true;
    setCurrentProjects(tempProjects);
    setStream(tempStream);
    setRecorder(tempRecorder);
    setIsRecording(true);

    tempRecorder.start();
  };

  /*
  |--------------------------------------------------------------------------
  | Stop recording
  |--------------------------------------------------------------------------
  */
  const stopRecording = (index) => {
    recorder.stop();
    stream.getVideoTracks()[0].stop();

    const tempProjects = [...currentProjects];
    tempProjects[index].isRecording = false;
    setCurrentProjects(tempProjects);
    setIsRecording(false);
  };

  /*
  |--------------------------------------------------------------------------
  | Check to see if all the projects have a recording before proceeding
  |--------------------------------------------------------------------------
  */
  const checkRecaps = () => {
    let recaps = 0;

    currentProjects.forEach((project) => {
      if (project.downloadedVideo || project.notes) {
        recaps += 1;
      }
    });

    if (recaps > 0 && recaps === currentProjects.length) {
      return false;
    } else {
      return true;
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get files
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (currentProjects && currentProjects.length && modalOpen) {
      getRecapsData();
    }
    // Remove videos that weren't saved
    else if (currentProjects && currentProjects.length && !modalOpen) {
      let tempProjects = [...currentProjects];

      tempProjects = tempProjects.map((project) => {
        if (!project.downloadedVideo) {
          project.file = null;
        }

        return project;
      });

      setCurrentProjects(tempProjects);
    }
  }, [modalOpen]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get any existing videos or notes that were created today
  |--------------------------------------------------------------------------
  */
  const getRecapsData = () => {
    const tempProjects = [...currentProjects];
    tempProjects.forEach((project, index) => {
      if (project.downloadedVideo) {
        const recording = document.getElementById(`recording-${index}`);

        if (recording) {
          recording.src = URL.createObjectURL(project.downloadedVideo);
        }
      } else if (project.notes) {
        const note = document.getElementById(`notes-${index}`);

        if (note) {
          note.innerHTML = project.notes;
        }
      }
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Confirm delete video
  |--------------------------------------------------------------------------
  */
  const confirmDeleteRecap = (index, type) => {
    let header = "";

    if (type === "video") {
      header = "Delete the video?";
    } else if (type === "notes") {
      header = "Delete the note?";
    }

    openPromptPopup({
      header,
      confirmCallback: () => {
        deleteRecap(index, type);
        closePromptPopup();
      },
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Delete recap
  |--------------------------------------------------------------------------
  */
  const deleteRecap = async (index, type) => {
    try {
      const tempProjects = [...currentProjects];

      // Get the recaps for today of the current user
      let currentRecap = tempProjects[index].project.recaps.filter((recap) => {
        if (
          isToday(getStandardizedDate(recap.date)) &&
          recap.owner === user._id
        ) {
          return true;
        }

        return false;
      });

      // Get the latest recap for today of the current user
      currentRecap = currentRecap[currentRecap.length - 1];

      // Filter out the current recap
      tempProjects[index].project.recaps = tempProjects[
        index
      ].project.recaps.filter((recap) => {
        if (recap.file !== currentRecap.file) {
          return true;
        }

        return false;
      });

      // Remove the current recap from the list of recaps
      tempProjects[index].project.recaps.pop();

      // Edit the project with the new recaps that has the most recent item removed
      const projectData = {
        projectId: tempProjects[index].project._id,
        recap: currentRecap,
      };

      await deleteRecapOnApi(projectData);

      if (tempProjects[index].downloadedVideo && type === "video") {
        setLoading(true);

        const recording = document.getElementById(`recording-${index}`);
        recording.src = null;
        tempProjects[index].file = null;
        tempProjects[index].downloadedVideo = null;
      } else if (tempProjects[index].notes && type === "notes") {
        const notes = document.getElementById(`notes-${index}`);
        notes.innerHTML = "";
        tempProjects[index].notes = null;
      }

      setCurrentProjects(tempProjects);
      setLoading(false);
      openAlertPopup(
        "Success",
        `Your ${type} has been successfully deleted`,
        true,
      );
    } catch (err) {
      console.error("Error deleting video", err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Replace the recorded video with a new one
  |--------------------------------------------------------------------------
  */
  const replaceVideo = (index) => {
    const existingRecording = document.getElementById(`recording-${index}`);
    existingRecording.src = null;
    startRecording(index);
  };

  /*
  |--------------------------------------------------------------------------
  | Toggles between video and text view
  |--------------------------------------------------------------------------
  */
  const toggleView = (index, view) => {
    const tempCurrentProjects = [...currentProjects];
    tempCurrentProjects[index].view = view;
    setCurrentProjects(tempCurrentProjects);
  };

  /*
  |--------------------------------------------------------------------------
  | Set the KPIs to state
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setSubmittedKpis(kpis);
  }, [kpis]);

  return (
    <AnimatePresence>
      {modalOpen ? (
        <ScreenBlocker
          initial={{ opacity: 0.0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.0 }}
          transition={{ duration: 0.1 }}
        >
          <Modal
            initial={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            exit={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            transition={{ type: "spring", duration: 0.6 }}
            step={step}
          >
            <CloseModal onClick={() => setModalOpen(false)}>&times;</CloseModal>

            {step === 0 ? (
              <VideoModal>
                <Header>
                  Great work today,
                  <br />
                  let's wrap this up.
                </Header>
                <Projects>
                  {currentProjects &&
                    currentProjects.map((project, index) => {
                      return (
                        <Project key={index}>
                          <ProjectInfo>
                            {!project.downloadedVideo && !project.notes ? (
                              <SkipRecording
                                onClick={() => {
                                  if (project.view === "video") {
                                    toggleView(index, "notes");
                                  } else {
                                    toggleView(index, "video");
                                  }
                                }}
                              >
                                {project.view === "video"
                                  ? "Skip Recording"
                                  : project.view === "notes"
                                  ? "Use Recording"
                                  : null}
                              </SkipRecording>
                            ) : null}

                            <ProjectTitle>
                              {project.project.client?.name
                                ? `[${project.project.client.name}] - ${project.project.name}`
                                : project.project.name}
                            </ProjectTitle>
                            <ProjectSubtitle>
                              {getHours(project, true)} Hours spent
                            </ProjectSubtitle>
                          </ProjectInfo>

                          <Recording
                            hidden={project.view === "notes"}
                            id={`recording-${index}`}
                            controls
                          ></Recording>

                          {project.view === "notes" ? (
                            <Notes
                              id={`notes-${index}`}
                              onChange={(e) => {
                                const tempNotes = [...notes];
                                tempNotes[index] = e.target.value;
                                setNotes(tempNotes);
                              }}
                            ></Notes>
                          ) : null}

                          {project.view === "video" &&
                          project.file &&
                          project.downloadedVideo &&
                          !project.isRecording ? (
                            <VideoAction
                              style={{ width: "100%" }}
                              type="delete"
                              onClick={() => {
                                confirmDeleteRecap(index, "video");
                              }}
                            >
                              Delete
                            </VideoAction>
                          ) : project.view === "video" &&
                            project.file &&
                            !project.downloadedVideo &&
                            !project.isRecording ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <VideoAction
                                type="save"
                                onClick={() => {
                                  saveRecap(index, "video");
                                }}
                              >
                                Save
                              </VideoAction>
                              <VideoAction
                                type="replace"
                                onClick={() => {
                                  replaceVideo(index);
                                }}
                              >
                                Replace
                              </VideoAction>
                            </div>
                          ) : project.view === "video" ? (
                            <RecordScreen
                              disabled={!project.isRecording && isRecording}
                              recording={project.isRecording}
                              onClick={() => {
                                project.isRecording
                                  ? stopRecording(index)
                                  : startRecording(index);
                              }}
                            >
                              {project.isRecording
                                ? "Stop Recording"
                                : "Start Recording"}
                            </RecordScreen>
                          ) : (
                            <RecordScreen
                              disabled={!project.isRecording && isRecording}
                              recording={project.notes}
                              onClick={() => {
                                if (project.notes) {
                                  confirmDeleteRecap(index, "notes");
                                } else {
                                  saveRecap(index, "notes", notes[index]);
                                }
                              }}
                            >
                              {project.notes ? "Delete" : "Save Note"}
                            </RecordScreen>
                          )}
                        </Project>
                      );
                    })}
                </Projects>

                {/* <ModalButton
                  disabled={checkRecaps()}
                  small
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  Continue
                </ModalButton> */}

                {/* // ! temporary. once KPIs are setup, we can get rid of this button and use the Continue button above */}
                <ModalButton
                  disabled={checkRecaps()}
                  small
                  onClick={() => {
                    finishDay(submittedKpis);
                  }}
                >
                  Submit
                </ModalButton>
              </VideoModal>
            ) : (
              <KpiModal>
                <Header>{kpis[step - 1].modalTitle}</Header>

                <BodyText>{kpis[step - 1].modalBody}</BodyText>

                <Target>Required: {kpis[step - 1].target}</Target>

                <InputRow>
                  <TargetInput
                    placeholder="Enter amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      const tempKpis = [...submittedKpis];
                      tempKpis[step - 1].amount = e.target.value;
                      setSubmittedKpis(tempKpis);
                    }}
                  />
                  <ModalButton
                    disabled={!submittedKpis[step - 1]}
                    small
                    onClick={() => {
                      if (step === kpis.length) {
                        finishDay(submittedKpis, currentProjects);
                      } else {
                        setStep(step + 1);
                        setAmount("");
                      }
                    }}
                  >
                    {step === kpis.length ? "Submit" : "Continue"}
                  </ModalButton>
                </InputRow>
              </KpiModal>
            )}
          </Modal>
        </ScreenBlocker>
      ) : null}
    </AnimatePresence>
  );
};

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 50px 50px 100px #bbbbbb, -50px -50px 100px #e9e9e9;
  width: ${(props) => (props.step === 0 ? "1200px" : "560px")};
`;

const CloseModal = styled.div`
  line-height: 1;
  font-size: 40px;
  font-weight: 500;
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.2;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const KpiModal = styled.div``;

const VideoModal = styled.div``;

const Header = styled.h2`
  font-weight: 600;
  margin-bottom: 40px;
  line-height: 1.2;
`;

const BodyText = styled.p``;

const Target = styled.p`
  font-weight: bold;
  margin-bottom: 40px;
`;

const TargetInput = styled(Input)`
  height: 50px;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0px;
  width: 130px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Projects = styled.div`
  display: flex;
  margin-bottom: 50px;
  overflow: auto;
`;

const Project = styled.div`
  background-color: #f3f9fd;
  flex-shrink: 0;
  width: 400px;
  height: 500px;
  margin-right: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &:last-of-type {
    margin-right: 0px;
  }
`;

const ProjectInfo = styled.div``;

const ProjectTitle = styled.h3`
  font-weight: 400;
`;

const ProjectSubtitle = styled.p`
  font-weight: 700;
  font-size: 12px;
`;

const RecordScreen = styled(Button)`
  border-color: ${(props) =>
    props.recording ? props.theme.colors.red : props.theme.colors.blue};

  background-color: ${(props) =>
    props.recording ? props.theme.colors.red : props.theme.colors.blue};

  &::after {
    background-color: ${(props) =>
      darken(
        0.1,
        props.recording ? props.theme.colors.red : props.theme.colors.blue,
      )};
  }
`;

const Recording = styled.video`
  width: ${(props) => (props.hidden ? "0" : "100%")};
  height: ${(props) => (props.hidden ? "0" : "250px")};
  pointer-events: ${(props) => (props.hidden ? "none" : "all")};
  background-color: black;
`;

const VideoAction = styled(Button)`
  min-width: auto;
  width: 45%;

  border-color: ${(props) =>
    props.type === "save"
      ? props.theme.colors.blue
      : props.type === "replace"
      ? props.theme.colors.mediumGray
      : props.theme.colors.red};

  background-color: ${(props) =>
    props.type === "save"
      ? props.theme.colors.blue
      : props.type === "replace"
      ? props.theme.colors.mediumGray
      : props.theme.colors.red};

  color: ${(props) =>
    props.type === "replace"
      ? props.theme.colors.oldBlack2
      : props.theme.colors.oldWhite};

  &::after {
    background-color: ${(props) =>
      darken(
        0.1,
        props.type === "save"
          ? props.theme.colors.blue
          : props.type === "replace"
          ? props.theme.colors.mediumGray
          : props.theme.colors.red,
      )};
  }
`;

const SkipRecording = styled.p`
  margin-bottom: 0px;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #0079ff;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: #3279cc;
  }
`;

const Notes = styled.textarea`
  width: 100%;
  height: 250px;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
`;

export default FinishModal;
