import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ArrowsCollapse from "../icons/ArrowsCollapse";
import ProfileImageGroup from "../ProfileImageGroup";

const ChatWrapper = ({ title, members, footer, onClose, children }) => {
  return (
    <Container>
      {onClose || title || members.length ? (
        <Header>
          {onClose ? (
            <CloseButton
              aria-label="Close chat"
              title="Close"
              onClick={onClose}
            >
              <ArrowsCollapse aria-hidden="true" />
            </CloseButton>
          ) : null}

          <Title>{title}</Title>

          {members?.length ? (
            <ProfileImageGroup members={members} maxToShow={10} />
          ) : null}
        </Header>
      ) : null}

      <Body>{children}</Body>

      {footer ? footer : null}
    </Container>
  );
};

export const ChatModal = styled.div`
  display: flex;

  width: 455px;
  height: 75vh;

  box-shadow: ${(props) => props.theme.boxShadow_m};
  border-radius: 8px;

  z-index: 2;

  /* overflow: hidden; */

  > * {
    flex-grow: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: white;
  max-width: 100%;
  width: 100%;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 25px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  padding-top: 18px;
  padding-right: 18px;

  svg {
    path {
      transition: transform 200ms;
    }
  }

  &:hover,
  &:focus {
    svg {
      path {
        &:first-child {
          transform: translate(2px, 2px);
        }
        &:last-child {
          transform: translate(-2px, -2px);
        }
      }
    }
  }
`;

const Body = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

ChatWrapper.propTypes = {
  title: PropTypes.string,
  members: PropTypes.array,
  onClose: PropTypes.func,
};
ChatWrapper.defaultProps = {
  members: [],
};

export default ChatWrapper;
