import React from "react";
import styled from "styled-components";

const CaretRight = () => {
  return (
    <Svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M0.000288333 12.9003C0.000180167 12.6616 0.0949304 12.4326 0.263688 12.2637L5.62769 6.90029L0.263688 1.53689C-0.0878961 1.1853 -0.0878961 0.615273 0.263688 0.263688C0.615273 -0.0878961 1.1853 -0.0878961 1.53689 0.263688L7.53689 6.26369C7.7058 6.43249 7.8007 6.66149 7.8007 6.90029C7.8007 7.13908 7.7058 7.36809 7.53689 7.53689L1.53689 13.5369C1.27948 13.7945 0.892233 13.8715 0.555818 13.7322C0.219403 13.5928 0.000123274 13.2644 0.000288333 12.9003Z"
        fill="#8698AD"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default CaretRight;
