import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";

import { buttonReset } from "../../../styles/styleHelpers";

import BellIcon from "../../../components/icons/Bell";
// import Tooltip from "../../../components/Tooltip";

const Timeslot = ({
  title,
  duration,
  completionPct,
  isComplete,
  dragging,
  isPast,
  hasDeadline,
  link,
  toggleDeadline,
  handleRemove,
  isDisabled,
}) => {
  // round down to the nearest hour (and pad with a 0 in case the number isn't 2 digits)
  const hours = `${Math.floor(duration)}`;
  const hoursString = hours.padStart(2, "0");

  // get the decimal of hours and multiply by 60 to get the minutes (and pad with a 0 in case the number isn't 2 digits)
  const minutes = `${Math.floor((duration % 1) * 60)}`;
  const minutesString = minutes.padStart(2, "0");

  return (
    <Wrapper>
      <Content>
        <Time condensed={duration <= 1}>
          {hoursString}:{minutesString}
        </Time>

        {title ? (
          <Title
            as={link ? Link : undefined}
            to={link ? link : undefined}
            condensed={duration <= 1}
          >
            {title}
          </Title>
        ) : null}

        {handleRemove ? (
          <CloseButton
            squished={duration < 0.5}
            condensed={duration <= 1}
            onClick={handleRemove}
          />
        ) : null}

        {title && !isDisabled && !dragging && duration <= 3 ? (
          <FloatingTitle
            as={link ? Link : undefined}
            to={link ? link : undefined}
          >
            {title}
          </FloatingTitle>
        ) : null}
      </Content>

      <Container
        status={
          isDisabled
            ? "disabled"
            : dragging
            ? "default"
            : completionPct >= 1 || isComplete
            ? "green"
            : isPast
            ? "red"
            : "yellow"
        }
      >
        {/* <Content>
        <Time condensed={duration <= 1} data-tip data-for={`${id}-tip`}>
          {hoursString}:{minutesString}
        </Time>

        {title && (
          <Title condensed={duration <= 1} data-tip data-for={`${id}-tip`}>
            {title}
          </Title>
        )}

        {handleRemove ? <CloseButton onClick={handleRemove} /> : null}
      </Content> */}

        {duration && !isDisabled ? (
          <TimeMeter
            completionPct={completionPct * 100}
            showCompletion={!dragging}
            danger={isPast}
            isComplete={isComplete}
          />
        ) : null}

        {toggleDeadline ? (
          <Bell isActive={hasDeadline}>
            <BellButton onClick={toggleDeadline} isActive={hasDeadline}>
              <BellIcon />
            </BellButton>
          </Bell>
        ) : null}

        {/* // ? tooltip may have been causing the scheduler to be super laggy and janky */}
        {/* {!dragging && !isDisabled ? (
      <Tooltip id={`${id}-tip`} place="top" effect="float">
        {title}
      </Tooltip>
       ) : null}  */}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%; // has to be width, not flex-grow, for the really small ones (like 15min)
`;

const Container = styled.div`
  /* position: relative; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: flex-end;
  flex-grow: 1;
  width: 100%;

  height: 50px;
  padding: 8px;
  padding-bottom: 5px;

  border: 2px solid;
  border-color: ${(props) =>
    props.status === "green"
      ? rgba(props.theme.colors.lightGreen, 0.6)
      : props.status === "yellow"
      ? rgba(props.theme.colors.lightYellow, 0.6)
      : props.status === "red"
      ? rgba(props.theme.colors.lightRed, 0.6)
      : rgba("#B9BCC0", 0.3)};

  border-radius: 5px;
  background-color: ${(props) =>
    props.status === "disabled" ? props.theme.colors.oldLightGray : "white"};

  transition: box-shadow 200ms;

  &:hover,
  ${Wrapper}:hover & {
    box-shadow: ${(props) =>
      props.status !== "disabled" && props.status !== "default"
        ? `4px 8px 14px ${rgba("#b9bcc0", 0.3)}`
        : undefined};
  }
`;

// const Content = styled.div`
//   display: flex;
//   flex-grow: 1;
//   /* so it can't cover up the TimerMeter */
//   max-height: calc(100% - 4px);
// `;

const Content = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;

  padding: 10px;
  height: 50px;

  /* so it can't cover up the TimerMeter */
  max-height: calc(100% - 4px);
  max-width: 100%;
`;

const Title = styled.div`
  color: black;
  font-size: 12px;
  line-height: 1;
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;

  white-space: ${(props) => (props.condensed ? "nowrap" : undefined)};
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 1;
  flex-shrink: 0;

  margin-right: ${(props) => (props.condensed ? "8px" : "15px")};
`;

const TimeMeter = styled.div`
  position: relative;

  height: 3px;
  /* height: 4px; */

  flex-shrink: 0;

  border-radius: 999px;
  background-color: ${(props) =>
    !props.showCompletion
      ? "#e9ebf2"
      : props.completionPct >= 100 || props.isComplete
      ? props.theme.colors.lightGreen
      : props.danger
      ? props.theme.colors.lightRed
      : props.theme.colors.lightYellow};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: ${(props) => `${props.completionPct}%`};
    background-color: ${(props) =>
      props.completionPct >= 100 || props.isComplete
        ? props.theme.colors.green
        : props.danger
        ? props.theme.colors.red
        : props.theme.colors.yellow};
    border-radius: 999px;
    opacity: ${(props) => (props.showCompletion ? 1 : 0)};
  }
`;

const Bell = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  line-height: 0;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  visibility: ${(props) => (props.isActive ? "visible" : "hidden")};

  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

  ${Wrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const BellButton = styled.button`
  line-height: 0;

  path {
    stroke: ${(props) =>
      props.isActive ? props.theme.colors.blue : props.theme.colors.slate};
    transition: stroke 200ms;
  }

  &:hover,
  &:focus {
    path {
      stroke: ${(props) => props.theme.colors.blue};
    }
  }
`;

const CloseButton = styled.button`
  ${buttonReset()}
  position: relative;
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-radius: 50%;

  margin-left: ${(props) =>
    props.squished ? "0px" : props.condensed ? "8px" : "15px"};

  opacity: 0;
  visibility: hidden;

  transition: border-color 200ms, opacity 200ms, visibility 200ms;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;

    width: 6px;
    height: 1px;

    background-color: ${(props) => props.theme.colors.mediumGray};
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.colors.red};

    &::before,
    &::after {
      background-color: ${(props) => props.theme.colors.red};
    }
  }

  ${Wrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

const FloatingTitle = styled.div`
  position: absolute;
  left: 0%;
  bottom: 100%;
  z-index: 1;

  color: black;
  font-size: 12px;
  background-color: #fff;
  padding: 5px;
  white-space: nowrap;

  opacity: 0;
  visibility: hidden;

  transition: opacity 200ms, visibility 200ms;

  ${Wrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

Timeslot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  duration: PropTypes.number,
  completionPct: PropTypes.number,
  isComplete: PropTypes.bool,
  dragging: PropTypes.bool,
  isPast: PropTypes.bool,
  hasDeadline: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleRemove: PropTypes.func,
  toggleDeadline: PropTypes.func,
};

Timeslot.defaultProps = {
  title: "",
  subtitle: "",
  duration: 1,
  completionPct: 0,
  isComplete: false,
  dragging: false,
  isPast: false,
  hasDeadline: false,
  isDisabled: false,
};

export default Timeslot;
