import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const newSwitch = ({ id, isActive, handleSwitch, disabled }) => {
  return (
    <Container disabled={disabled}>
      <Input
        type="checkbox"
        id={id}
        checked={isActive}
        onChange={(e) => {
          handleSwitch(e.target.checked);
        }}
        disabled={disabled}
      />
      <Toggle htmlFor={id}>
        <ToggleSlide />
      </Toggle>
    </Container>
  );
};

const Toggle = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 26px;
  height: 16px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 100px;
  padding: 1px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray300};
  transition: background-color 0.2s, border-color 0.2s;
  cursor: pointer;
`;

const ToggleSlide = styled.div`
  position: absolute;
  left: 1px;
  background-color: ${(props) => props.theme.colors.coolGray300};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  z-index: 2;
  transition: left 0.2s, background-color 0.2s;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          opacity: 0.4;

          &:hover {
            ${Toggle} {
              background-color: ${(props) => props.theme.colors.coolGray100};
            }

            input:checked ~ ${Toggle} {
              border-color: ${(props) => props.theme.colors.indigo500};
              background-color: ${(props) => props.theme.colors.indigo500};
            }
          }
        `
      : css`
          pointer-events: all;
          opacity: 1;
        `}
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ ${Toggle} {
    background-color: ${(props) => props.theme.colors.indigo600};
    border-color: ${(props) => props.theme.colors.indigo600};
  }

  &:checked ~ ${Toggle} > ${ToggleSlide} {
    left: calc(100% - 12px - 1px);
    background-color: ${(props) => props.theme.colors.pureWhite};
  }
`;

newSwitch.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  handleSwitch: PropTypes.func,
};

newSwitch.defaultProps = {
  id: "switch",
  isActive: false,
  handleSwitch: null,
};

export default newSwitch;
