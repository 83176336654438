import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  differenceInMinutes,
  isSameDay,
  isSameYear,
  isAfter,
  format,
} from "date-fns";

import { listReset } from "../../styles/styleHelpers";

import ChatMessage from "./ChatMessage";

const ChatMessageList = ({
  items,
  userId,
  lastViewed,
  isVisible,
  onEdit,
  onDelete,
  canModifyOthers,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [listLength, setListLength] = useState(items.length);

  const chatEnd = useRef(null);
  const unreadStart = useRef(null);

  // when new items are added
  useEffect(() => {
    if (isVisible) {
      // if the changed list is longer than the prev list
      const messageRemoved = items.length < listLength;

      // only scroll if message was added
      if (!messageRemoved) {
        // smooth scroll, unless this is the first scroll
        const scrollType = hasScrolled ? "smooth" : "auto";

        // scroll to the end of the chat first
        // ? do this first so that if there are unread messages, we can scroll back up to them rather than scrolling down to them from the top which would result in only the unread marker being visible.
        if (chatEnd.current) {
          chatEnd.current.scrollIntoView({
            behavior: scrollType,
            block: "nearest",
          });
        }

        // then, check if there are unread messages. If so, and this is the first scroll, scroll back up to the first unread message
        // ? the scrolling is being handled this way so that only the chat modal will scroll. When not using `block: "nearest"`, this autoscrolling would cause the entire page to scroll.
        // ? using `block: "center"` caused the entire page to scroll.
        if (!hasScrolled && unreadStart.current) {
          unreadStart.current.scrollIntoView({
            behavior: scrollType,
            block: "nearest",
          });
        }

        setHasScrolled(true);
      }
    } else {
      // if the message list is no longer visible, reset the hasScrolled state
      setHasScrolled(false);
    }

    // update the length of items to check against next time
    setListLength(items.length);
  }, [items.length, isVisible]);

  // keep track of the previous message
  let prevAuthor, prevDate;

  let hasUnreadMarker = false;

  return (
    <>
      <List hasScrolled={hasScrolled}>
        {items.length ? (
          items.map((item) => {
            if (item.date) {
              item.postedDate = item.date;
            }

            const isSameAuthor = prevAuthor && item.author._id === prevAuthor;

            const minutesDiff = prevDate
              ? differenceInMinutes(
                  new Date(item.postedDate),
                  new Date(prevDate),
                )
              : 999;

            // denote that this message was made shortly after the previous one, by the same author
            const isCloseBy = isSameAuthor && minutesDiff < 5;

            // we'll add an item for the date if this message is from a diff day than the prev message
            const sameDay = prevDate
              ? isSameDay(new Date(item.postedDate), new Date(prevDate))
              : false;

            const sameYear = isSameYear(new Date(item.postedDate), new Date());

            // if the message if from a past year, include the year
            const dayFormat = `E, MMM d${!sameYear ? ", yyyy" : ""}`;

            const isMe = userId === item.author._id;

            // this message is the first unread message if its date is after the last time the user viewed the chat
            const isUnread =
              lastViewed && !hasUnreadMarker && !isMe
                ? isAfter(new Date(item.postedDate), new Date(lastViewed))
                : false;

            // if its unread, denote that we've placed the "unread" marker. So all the following messages don't also have that marker above them
            if (isUnread) {
              hasUnreadMarker = true;
            }

            // denote the author of this message to compare with the next one
            prevAuthor = item.author._id;

            // only reset the date once the message is no longer close enough to the original message that was being compared against
            // ? that way if we have messages from 10:00, 10:03, and 10:08; only the first 2 will be closeby
            if (!isCloseBy) {
              prevDate = item.postedDate;
            }

            const messageId = `${item.author._id}${new Date(
              item.postedDate,
            ).getTime()}`;

            return (
              <Fragment key={messageId}>
                {isUnread ? (
                  <UnreadItem ref={unreadStart}>
                    {/* <Meta>Unread</Meta> */}
                    <Meta>New</Meta>
                  </UnreadItem>
                ) : null}

                {!sameDay ? (
                  <CenteredItem>
                    <Meta>{format(new Date(item.postedDate), dayFormat)}</Meta>
                  </CenteredItem>
                ) : null}

                <Item isReversed={isMe} hasLessMargin={isCloseBy}>
                  <ChatMessage
                    message={item.message}
                    author={item.author}
                    postedDate={item.postedDate}
                    isMe={isMe}
                    hideMeta={isCloseBy && !isUnread}
                    onEdit={isMe || canModifyOthers ? onEdit : undefined}
                    onDelete={isMe || canModifyOthers ? onDelete : undefined}
                  />
                </Item>
              </Fragment>
            );
          })
        ) : (
          <CenteredItem>
            <Meta>No messages yet</Meta>
          </CenteredItem>
        )}
      </List>

      <div ref={chatEnd} />
    </>
  );
};

const List = styled.ol`
  ${listReset()}

  padding: 25px;

  > * {
    /* visually hide its direct children until the initial scroll has occurred, so we don't see the quick initial scroll */
    opacity: ${(props) => (props.hasScrolled ? 1 : 0)};
  }
`;

const Item = styled.li`
  max-width: 75%;
  margin-bottom: 10px;

  /* to make it closer to the one above it */
  margin-top: ${(props) => (props.hasLessMargin ? "-4px" : undefined)};

  /* so its flush with the right side */
  margin-left: ${(props) => (props.isReversed ? "auto" : undefined)};
`;

const CenteredItem = styled(Item)`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-transform: uppercase;
`;

const UnreadItem = styled(CenteredItem)`
  display: flex;
  align-items: center;

  max-width: 100%;
  color: ${(props) => props.theme.colors.indigo};

  &::before,
  &::after {
    content: "";
    height: 1px;
    background-color: ${(props) => props.theme.colors.indigo};
    flex-grow: 1;
  }

  > * {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Meta = styled.div`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.coolerGray};

  ${UnreadItem} & {
    color: ${(props) => props.theme.colors.indigo};
  }
`;

ChatMessageList.propTypes = {
  userId: PropTypes.string,
  lastViewed: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        handle: PropTypes.string,
      }),
      postedDate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      message: PropTypes.string,
    }),
  ),
  isVisible: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  canModifyOthers: PropTypes.bool,
};
ChatMessageList.defaultProps = {
  items: [],
  isVisible: true,
  canModifyOthers: false,
};

export default ChatMessageList;
