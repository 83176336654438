import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useMeasure } from "react-use";

const DonutChart = ({
  fill,
  total,
  pace,
  meta,
  title,
  subtitle,
  hasDanger,
  small = false,
  ...rest
}) => {
  const [ref, { width }] = useMeasure();

  const progress = fill > total ? 100 : fill / total;

  // if fill is greater than total
  const over =
    fill > total * 2 ? 100 : fill > total ? (fill - total) / total : 0;

  const pacePosition = pace ? pace / total : null;

  const strokeWidth = 35;
  // radius = half the width, minus half the stroke width
  const radius = width / 2 - strokeWidth / 2;

  return (
    <Container ref={ref} {...rest}>
      <ProgressContainer>
        <ProgressLabel>
          {meta ? (
            <Meta hasDanger={hasDanger} small={small}>
              {meta}
            </Meta>
          ) : null}

          {title ? (
            <Title hasDanger={hasDanger} small={small}>
              {title}
            </Title>
          ) : null}

          {subtitle ? (
            <Meta hasDanger={hasDanger} small={small}>
              {subtitle}
            </Meta>
          ) : null}
        </ProgressLabel>

        <ProgressInner progress={progress} containerWidth={width}>
          <ProgressLeft progress={progress} containerWidth={width} />
          <ProgressRight progress={progress} containerWidth={width} />
        </ProgressInner>

        {over ? (
          <ProgressInner progress={over} containerWidth={width}>
            <ProgressLeft progress={over} containerWidth={width} hasDanger />
            <ProgressRight progress={over} containerWidth={width} hasDanger />
          </ProgressInner>
        ) : null}

        {pacePosition !== null ? (
          <Svg size={width}>
            <Circle
              cx="50%"
              cy="50%"
              radius={radius}
              // C = 2 * pi * r
              circumference={2 * Math.PI * radius}
              position={pacePosition}
            />
          </Svg>
        ) : null}

        <ProgressOuter />
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 240px;
  min-width: 200px;

  &::before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ProgressOuter = styled.div`
  width: 100%;
  height: 100%;
  border: 35px solid ${(props) => props.theme.colors.coolGray200};
  border-radius: 50%;
`;

const ProgressInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  clip: ${(props) =>
    props.progress <= 0.5
      ? `rect(0, ${props.containerWidth}px, ${
          props.containerWidth
        }px, ${props.containerWidth / 2}px)`
      : `rect(auto, auto, auto, auto)`};
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 50%;
  border: 35px solid
    ${(props) =>
      props.hasDanger
        ? props.theme.colors.red600
        : props.theme.colors.green500};

  clip: ${(props) =>
    `rect(0, ${props.containerWidth / 2}px, ${props.containerWidth}px, 0)`};
`;

const ProgressLeft = styled(ProgressBar)`
  transform: ${(props) =>
    props.progress ? `rotate(${props.progress * 360}deg)` : `rotate(0deg)`};
`;

const ProgressRight = styled(ProgressBar)`
  ${(props) =>
    props.progress <= 0.5
      ? css`
          display: none;
        `
      : `
          transform: rotate(180deg);
        `}
`;

const ProgressLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  text-align: center;

  > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Meta = styled.p`
  font-size: ${(props) => (props.small ? `12px` : `14px`)};
  font-weight: 500;
  color: ${(props) =>
    props.hasDanger ? props.theme.colors.red600 : props.theme.colors.darkGray};
  line-height: 1;
`;

const Title = styled.p`
  font-size: ${(props) => (props.small ? `20px` : `24px`)};
  font-weight: 500;
  color: ${(props) =>
    props.hasDanger
      ? props.theme.colors.red600
      : props.theme.colors.darkerGray};
  line-height: 1;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  transform: rotate(-90deg);
`;

const Circle = styled.circle`
  stroke: ${(props) => props.theme.colors.coolGray600};
  stroke-width: 35px;
  fill: transparent;

  r: ${(props) => props.radius}px;

  stroke-dasharray: ${(props) =>
    `${props.circumference} ${props.circumference}`};

  /* the thickness of the line */
  /* circumference minus desired thickness */
  stroke-dashoffset: ${(props) => props.circumference - 2};

  transform-origin: 50% 50%;

  transform: ${(props) =>
    props.position ? `rotate(${360 * props.position}deg)` : `rotate(0deg)`};
`;

DonutChart.propTypes = {
  fill: PropTypes.number,
  total: PropTypes.number,
  pace: PropTypes.number,
  meta: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hasDanger: PropTypes.bool,
};
DonutChart.defaultProps = {
  hasDanger: false,
};

export default DonutChart;
