import React from "react";

const ScheduleIconSmall = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80156 2.3501C4.58939 2.3501 4.38591 2.43438 4.23588 2.58441C4.08585 2.73444 4.00156 2.93792 4.00156 3.1501V3.9501H3.20156C2.77722 3.9501 2.37025 4.11867 2.07019 4.41873C1.77013 4.71878 1.60156 5.12575 1.60156 5.5501V13.5501C1.60156 13.9744 1.77013 14.3814 2.07019 14.6815C2.37025 14.9815 2.77722 15.1501 3.20156 15.1501H12.8016C13.2259 15.1501 13.6329 14.9815 13.9329 14.6815C14.233 14.3814 14.4016 13.9744 14.4016 13.5501V5.5501C14.4016 5.12575 14.233 4.71878 13.9329 4.41873C13.6329 4.11867 13.2259 3.9501 12.8016 3.9501H12.0016V3.1501C12.0016 2.93792 11.9173 2.73444 11.7672 2.58441C11.6172 2.43438 11.4137 2.3501 11.2016 2.3501C10.9894 2.3501 10.7859 2.43438 10.6359 2.58441C10.4858 2.73444 10.4016 2.93792 10.4016 3.1501V3.9501H5.60156V3.1501C5.60156 2.93792 5.51728 2.73444 5.36725 2.58441C5.21722 2.43438 5.01374 2.3501 4.80156 2.3501ZM4.80156 6.3501C4.58939 6.3501 4.38591 6.43438 4.23588 6.58441C4.08585 6.73444 4.00156 6.93792 4.00156 7.1501C4.00156 7.36227 4.08585 7.56575 4.23588 7.71578C4.38591 7.86581 4.58939 7.9501 4.80156 7.9501H11.2016C11.4137 7.9501 11.6172 7.86581 11.7672 7.71578C11.9173 7.56575 12.0016 7.36227 12.0016 7.1501C12.0016 6.93792 11.9173 6.73444 11.7672 6.58441C11.6172 6.43438 11.4137 6.3501 11.2016 6.3501H4.80156Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};

export default ScheduleIconSmall;
