import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useParams,
  useRouteMatch,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import { getClientFromApi } from "../utils/api";

import SimpleLink from "../../../components/links/SimpleLink";
import ContractModify from "./components/contractModify";
import ClientDashboard from "./components/clientDashboard";

const Client = () => {
  const { id } = useParams();
  const match = useRouteMatch();

  const [name, setName] = useState("");
  const [acronym, setAcronym] = useState("");

  const [googleSheet, setGoogleSheet] = useState(null);
  const [googleFolder, setGoogleFolder] = useState(null);
  const [productionGoogleFolder, setProductionGoogleFolder] = useState(null);

  useEffect(() => {
    if (id) {
      getClient(id);
    }
  }, [id]);

  const getClient = async (clientId) => {
    try {
      const client = await getClientFromApi(clientId);

      setName(client.name);
      setAcronym(client.acronym);

      setGoogleSheet(client.googleSheet);
      setGoogleFolder(client.googleFolder);
      setProductionGoogleFolder(client.productionGoogleFolder);
    } catch (error) {
      console.error("error getting client", error);
    }
  };

  const quickLinks = [
    {
      name: "Account Summary Google Sheet",
      url: googleSheet,
    },
    {
      name: "Client Delivery Google Folder",
      url: googleFolder,
    },
    {
      name: "Production Google Folder",
      url: productionGoogleFolder,
    },
  ];

  return (
    <Container>
      <Header>
        <Heading>
          {acronym ? `${acronym} -` : ""} {name}
        </Heading>

        {name ? (
          <SimpleLink as={Link} to={`${match.url}/edit`}>
            Edit
          </SimpleLink>
        ) : null}
      </Header>

      <Switch>
        {/* Single contract */}
        <Route
          exact
          path={`${match.path}/contracts/:contractId`}
          render={(props) => <ContractModify {...props} />}
        />

        <Route
          path={match.path}
          render={(props) => (
            <ClientDashboard {...props} quickLinks={quickLinks} clientId={id} />
          )}
        />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 30px;
`;

export default Client;
