import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { rgba } from "polished";
import DefaultLink from "../links/DefaultLink";
import CloseButton from "../buttons/CloseButton";

const PromptView = ({
  title,
  message,
  isOpen,
  handleClose,
  handleCancel,
  handleConfirm,
  cancelText,
  confirmText,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ScreenBlocker
          initial={{ opacity: 0.0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.0 }}
          transition={{ duration: 0.1 }}
        >
          <Container
            initial={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            exit={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            transition={{ type: "spring", duration: 0.4 }}
          >
            <TextContainer>
              <Title>{title}</Title>
              {message && <Message>{message}</Message>}
              <Buttons>
                <DefaultLink onClick={handleCancel} danger={true}>
                  {cancelText}
                </DefaultLink>
                <DefaultLink onClick={handleConfirm}>{confirmText}</DefaultLink>
              </Buttons>
            </TextContainer>
            <CloseButton onClick={handleClose} />
          </Container>
        </ScreenBlocker>
      )}
    </AnimatePresence>
  );
};

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${(props) => rgba(props.theme.colors.oldBlack2, 0.15)};
`;

const Container = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 2px 14px rgba(233, 233, 233, 0.5);
  border-radius: 4px;
  padding: 48px 40px 35px;
  margin-left: 25px;
  margin-right: 25px;
  min-width: 200px;
  max-width: 550px;
  min-height: 200px;
  z-index: 1000;
  transition: opacity 0.2s;
`;

const TextContainer = styled.div``;

const Title = styled.h3`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_xxs};

  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${(props) => props.theme.colors.oldBlack2};

  margin-bottom: 12px;
`;

const Message = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  color: ${(props) => props.theme.colors.oldBlack2};
  margin-bottom: 0;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 35px;
`;

PromptView.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

PromptView.defaultProps = {
  title: "",
  message: "",
  isOpen: false,
  handleClose: null,
};

export default PromptView;
