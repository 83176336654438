import React from "react";

export default ({ ...rest }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9999 19.5999C12.546 19.5999 14.9878 18.5885 16.7881 16.7881C18.5885 14.9878 19.5999 12.546 19.5999 9.9999C19.5999 7.45382 18.5885 5.01203 16.7881 3.21168C14.9878 1.41133 12.546 0.399902 9.9999 0.399902C7.45382 0.399902 5.01203 1.41133 3.21168 3.21168C1.41133 5.01203 0.399902 7.45382 0.399902 9.9999C0.399902 12.546 1.41133 14.9878 3.21168 16.7881C5.01203 18.5885 7.45382 19.5999 9.9999 19.5999V19.5999ZM14.4483 8.4483C14.6669 8.22198 14.7878 7.91886 14.7851 7.60422C14.7824 7.28958 14.6562 6.98861 14.4337 6.76612C14.2112 6.54363 13.9102 6.41743 13.5956 6.41469C13.2809 6.41196 12.9778 6.53291 12.7515 6.7515L8.7999 10.7031L7.2483 9.1515C7.02198 8.93291 6.71886 8.81196 6.40422 8.81469C6.08958 8.81743 5.78861 8.94363 5.56612 9.16612C5.34363 9.38861 5.21743 9.68958 5.21469 10.0042C5.21196 10.3189 5.33291 10.622 5.5515 10.8483L7.9515 13.2483C8.17654 13.4733 8.4817 13.5996 8.7999 13.5996C9.1181 13.5996 9.42327 13.4733 9.6483 13.2483L14.4483 8.4483V8.4483Z"
      fill="#4ADE80"
    />
  </svg>
);
