import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Tooltip from "./Tooltip";

const RangeSlider = React.forwardRef(
  ({ id, value, defaultValue, showTip, tipPosition, ...rest }, ref) => {
    const [rangeValue, setRangeValue] = useState(value || defaultValue);

    const tipId = `${id}-tip`;

    return (
      <>
        <Input
          type="range"
          ref={ref}
          id={id}
          data-tip={showTip ? true : undefined}
          data-for={showTip ? tipId : undefined}
          onChange={(e) => setRangeValue(e.target.value)}
          onInput={(e) => setRangeValue(e.target.value)}
          value={value}
          defaultValue={defaultValue}
          {...rest}
        />

        {showTip ? (
          <Tooltip id={tipId} place={tipPosition}>
            {rangeValue}
          </Tooltip>
        ) : null}
      </>
    );
  },
);

const Input = styled.input`
  width: 100%;
`;

RangeSlider.propTypes = {
  id: PropTypes.string,
  showTip: PropTypes.bool,
  tipPosition: PropTypes.string,
};
RangeSlider.defaultProps = {
  id: "range-slider",
  showTip: true,
  tipPosition: "top",
};

export default RangeSlider;
