import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getMembersFromApi } from "../../../utils/api";
import { states, companyStages } from "../../../utils/constants";

import { Input, PhoneInput, Label } from "../../../components/newForm";
import SelectDropdown from "../../../components/newSelectDropdown";
import Button from "./button";
import CreateSidebar from "./createSidebar";

const CreateCompany = ({ isOpen, close, handleCreate }) => {
  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [harvestId, setHarvestId] = useState("");

  const [availOwners, setAvailOwners] = useState([]);

  useEffect(() => {
    setupData();
  }, []);

  const setupData = async () => {
    const newAvailOwners = await getMembersFromApi();

    setAvailOwners(newAvailOwners);
  };

  const clearState = () => {
    setOwner("");
    setStatus("");
    setName("");
    setColor("");
    setPhone("");
    setAddress1("");
    setAddress2("");
    setCity("");
    setState("");
    setZipcode("");
    setHarvestId("");
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      await handleCreate({
        accountOwner: owner.value,
        status: status.value,
        name,
        color,
        harvestId,
        info: {
          website,
          phone,
          address1,
          address2,
          city,
          state: state.value,
          zipcode,
        },
      });

      clearState();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CreateSidebar
      title="New Company"
      isOpen={isOpen}
      close={close}
      onSubmit={handleSubmit}
    >
      <CreateDropdown
        placeholder="Owner"
        value={owner}
        onChange={(e) => {
          setOwner(e);
        }}
        options={
          availOwners
            ? availOwners.map((owner) => ({
                label: owner.name,
                value: owner._id,
              }))
            : []
        }
      />
      <CreateDropdown
        placeholder="Status"
        value={status}
        onChange={(e) => {
          setStatus(e);
        }}
        options={
          companyStages
            ? companyStages.map((status) => ({
                label: status,
                value: status,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="Company Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <hr />

      <Label>If this company already exists in Harvest:</Label>
      <CreateInput
        placeholder="Harvest ID (optional)"
        value={harvestId}
        onChange={(e) => setHarvestId(e.target.value)}
      />

      <hr />

      <CreateInput
        placeholder="Color"
        value={color}
        type="color"
        onChange={(e) => setColor(e.target.value)}
      />
      <CreateInput
        placeholder="Website"
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
      />
      <CreatePhoneInput
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 1"
        value={address1}
        onChange={(e) => setAddress1(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 2"
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
      />
      <CreateInput
        placeholder="City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <CreateDropdown
        placeholder="State"
        value={state}
        onChange={(e) => {
          setState(e);
        }}
        options={
          states
            ? states.map((state) => ({
                label: state,
                value: state,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="Zip Code"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
      />

      <Button>Create</Button>
    </CreateSidebar>
  );
};

const CreateInput = styled(Input)`
  margin-bottom: 16px;
`;

const CreatePhoneInput = styled(PhoneInput)`
  margin-bottom: 16px;
`;

const CreateDropdown = styled(SelectDropdown)`
  margin-bottom: 16px;
`;

export default CreateCompany;
