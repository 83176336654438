import React from "react";
import styled from "styled-components";

const AccordionIcon = () => {
  return (
    <Svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79279 15.3737C7.60532 15.1861 7.5 14.9318 7.5 14.6667C7.5 14.4015 7.60532 14.1472 7.79279 13.9597L11.0858 10.6667L7.79279 7.37367C7.61063 7.18506 7.50983 6.93246 7.51211 6.67027C7.51439 6.40807 7.61956 6.15726 7.80497 5.97185C7.99038 5.78644 8.24119 5.68127 8.50339 5.67899C8.76558 5.67671 9.01818 5.77751 9.20679 5.95967L13.2068 9.95967C13.3943 10.1472 13.4996 10.4015 13.4996 10.6667C13.4996 10.9318 13.3943 11.1861 13.2068 11.3737L9.20679 15.3737C9.01926 15.5611 8.76495 15.6665 8.49979 15.6665C8.23462 15.6665 7.98031 15.5611 7.79279 15.3737Z"
        fill="#9CA3AF"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    transition: fill 0.2s ease-in-out;
  }
`;

export default AccordionIcon;
