import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import { useFormState } from "react-use-form-state";

import { useNotifications } from "../../context/notificationsContext";

import { Field, Label, Input } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import Button from "../../components/buttons/Button";
import SimpleLink from "../../components/links/SimpleLink";

import { departments } from "../../utils/constants";
import { capitalize, findItemProperty } from "../../utils/helpers";
import {
  getTeamsFromApi,
  getMembersFromApi,
  getOfficesFromApi,
  createMemberOnApi,
  updateMemberOnApi,
} from "../../utils/api";

import { getAccessLevelsFromApi, getAllRolesFromApi } from "./utils/api";
import { getRoles } from "@testing-library/react";

const UserModify = () => {
  const { id } = useParams();
  const history = useHistory();
  const {
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const [roles, setRoles] = useState([]);
  const [members, setMembers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [support, setSupport] = useState([]);
  const [accessLevels, setAccessLevels] = useState([]);
  const [teams, setTeams] = useState([]);
  const [offices, setOffices] = useState([]);

  const [isExisting, setIsExisting] = useState(false);

  const [isArchived, setIsArchived] = useState(false);
  const [endDate, setEndDate] = useState("");
  const endDateRef = useRef(endDate);

  const [formState, { text, email, date, raw }] = useFormState();
  const { values } = formState;

  // on load, get the access levels
  useEffect(() => {
    getOptions();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (accessLevels) {
      // if we're editing an existing user, rather than making a new one
      // (the IDs are going to be longer than 10 characters. So if the param is something shorter, like "new" or "add", it will assume we wish to add a new user)
      const editUser = id.length > 10;

      // get the members (and specify if we need to grab the details for the existing user, if there is one)
      getMembers(editUser);

      // remember whether we're editing an existing user. Will need to know which API call to make when we submit the form
      setIsExisting(editUser);
    }
  }, [accessLevels]); //eslint-disable-line

  // when a department is selected
  useEffect(() => {
    if (values.department?.value) {
      // get all the members who belong to that department
      const departmentMembers = members.filter(
        (member) => member.department === values.department.value,
      );

      // if there are other members in that department, use them. Otherwise, just use everyone
      const supportMembers = departmentMembers.length
        ? departmentMembers
        : members;

      // format it how the dropdown expects it
      const userSupport = supportMembers.map((member) => {
        return {
          label: member.name,
          value: member._id,
        };
      });

      setSupport(userSupport);
    }
  }, [values.department, members]); //esline-disable-line

  useEffect(() => {
    if (!isArchived) {
      const newEndDate = format(new Date(), "yyyy-MM-dd");
      setEndDate(newEndDate);
      endDateRef.current = newEndDate;
    } else {
      setEndDate("");
      endDateRef.current = "";
    }
  }, [isArchived]);

  const getOptions = async () => {
    try {
      // get access levels
      // const accessFromApi = await getAccessLevelsFromApi();
      // // get just their names
      // const accessNames = accessFromApi?.length
      //   ? accessFromApi.map((item) => item.name)
      //   : [];
      setAccessLevels(["user", "manager", "admin", "super"]);

      // get teams
      const teamsFromApi = await getTeamsFromApi();
      // get just their names
      const teamNames = teamsFromApi?.length
        ? teamsFromApi.map((item) => item.name)
        : [];
      setTeams(teamNames);

      // Get Roles
      const rolesFromApi = await getAllRolesFromApi();

      const newRoles = rolesFromApi
        // return them in the format expected by the dropdown
        .map((role) => {
          return {
            label: role.role,
            value: role._id,
          };
        });
      setRoles(newRoles);

      // get offices
      const officesFromApi = await getOfficesFromApi();
      const newOffices = officesFromApi
        // return them in the format expected by the dropdown
        .map((office) => {
          return {
            label: office.name,
            value: office._id,
          };
        });

      setOffices(newOffices);
    } catch (error) {
      console.error(error);
    }
  };

  const getMembers = async (getExisting) => {
    try {
      const membersFromApi = await getMembersFromApi({ showArchived: true });

      const coords = membersFromApi
        // get the project managers
        .filter((member) => member.role.role === "Production Manager")
        // sort them alphabetically
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        // return them in the format expected by the dropdown
        .map((member) => {
          return {
            label: member.name,
            value: member._id,
          };
        });

      // set the user's support staff to everyone at first
      const userSupport = membersFromApi.map((member) => {
        return {
          label: member.name,
          value: member._id,
        };
      });

      setCoordinators(coords);
      setSupport(userSupport);
      setMembers(membersFromApi);

      // if we need to grab the data for an existing user we wish to edit
      if (getExisting) {
        setupExistingMember(membersFromApi);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // pre-fill the fields with data for an existing member
  const setupExistingMember = (allMembers) => {
    const existingUser = allMembers.find((member) => member._id === id);

    const userFields = [
      "name",
      "handle",
      "email",
      "harvestId",
      "startDate",
      "role",
      "team",
      "department",
      "office",
      "manager",
      "coordinator",
      "accessLvl",
      "salesQuota",
      "capacityLow",
      "capacityHigh",
      "costRate",
    ];

    userFields.forEach((field) => {
      if (existingUser[field]) {
        // basic text fields
        if (
          field === "name" ||
          field === "handle" ||
          field === "email" ||
          field === "harvestId" ||
          field === "salesQuota" ||
          field === "costRate" ||
          field === "capacityLow" ||
          field === "capacityHigh"
        ) {
          formState.setField(field, existingUser[field]);
        } else if (field === "startDate") {
          formState.setField(
            field,
            format(new Date(existingUser[field]), "yyyy-MM-dd"),
          );
          // person dropdown fields
        } else if (field === "manager" || field === "coordinator") {
          // grab the person's name
          const person = allMembers.find(
            (member) => member._id === existingUser[field],
          );

          formState.setField(field, {
            label: person.name,
            value: existingUser[field],
          });
        } else if (field === "office") {
          formState.setField(field, {
            label: existingUser.office.name,
            value: existingUser.office._id,
          });
        } else if (field === "role") {
          formState.setField(field, {
            label: existingUser.role.role,
            value: existingUser.role._id,
          });
        }

        // all other dropdowns
        else {
          formState.setField(field, {
            label: capitalize(existingUser[field]),
            value: existingUser[field],
          });
        }
      }
    });

    setIsArchived(existingUser.isArchived || false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // data to send to API
    const data = {};

    // loop over all the values
    Object.entries(values).forEach(([name, value]) => {
      // if the field's value is an object with a property called "value", use that property (like for the dropdowns). Otherwise, just use the value as is
      const fieldValue = value?.value || value;

      data[name] = fieldValue;
    });

    try {
      // select the correct function based on whether we are adding or updating
      const userResult = isExisting
        ? await updateMemberOnApi(id, data)
        : await createMemberOnApi(data);

      openAlertPopup(
        "Success",
        `${userResult.handle} has been ${isExisting ? "updated" : "created"}.`,
        true,
      );

      // go to users list view
      history.push(`/manage/users`);
    } catch (error) {
      console.error("error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't ${
          isExisting ? "update" : "create"
        } the user. ${errMessage}`,
      );
    }
  };

  const handleArchive = async (shouldArchive) => {
    try {
      const userResult = await updateMemberOnApi(id, {
        isArchived: shouldArchive,
        endDate: endDateRef.current ? endDateRef.current : null,
      });

      openAlertPopup(
        "Success",
        `${userResult.handle} has been ${
          shouldArchive ? "archived" : "unarchived"
        }.`,
        true,
      );

      if (shouldArchive) {
        // go to users list view
        history.push(`/manage/users`);
      } else {
        setIsArchived(false);
      }
    } catch (error) {
      console.error("error", error);

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't ${
          shouldArchive ? "archive" : "unarchive"
        } the user.`,
      );
    }
  };

  // * Setup the options for dropdown fields
  const fieldOptions = {};

  const options = [
    { name: "roles", values: roles },
    { name: "accessLevels", values: accessLevels },
    { name: "teams", values: teams },
    { name: "departments", values: departments },
    { name: "offices", values: offices },
    // if more sets of options need to be added, add them here
  ];

  // loop over the options we've specified above
  options.forEach((option) => {
    // add a property to the fieldOptions object with the name of this option
    // set it equal to an array of objects with the format expected by the dropdowns
    fieldOptions[option.name] = option.values.map((optionValue) => {
      return typeof optionValue === "string"
        ? {
            label: capitalize(optionValue),
            value: optionValue,
          }
        : {
            label: optionValue.name,
            value: optionValue._id,
          };
    });
  });

  const handleEndDateChange = (e) => {
    endDateRef.current = e.target.value;
    setEndDate(e.target.value);
  };

  return (
    <Container>
      <Header>
        <Heading>
          {isExisting ? "Edit" : "New"} User
          {isArchived ? " - (Archived)" : null}
        </Heading>

        {isExisting && values.name ? (
          <SimpleLink
            onClick={() => {
              openPromptPopup({
                header: isArchived
                  ? "Are you sure you want to unarchive this user?"
                  : "Are you sure you want to archive this user?",
                body: !isArchived ? (
                  <PromptText>
                    <Field>
                      <Label>End Date *</Label>
                      <Input
                        defaultValue={endDate}
                        onChange={handleEndDateChange}
                        type="date"
                        required
                      />
                    </Field>
                  </PromptText>
                ) : null,
                confirmCallback: () => {
                  closePromptPopup();
                  handleArchive(!isArchived);
                },
              });
            }}
            danger
          >
            {isArchived ? "Unarchive" : "Archive"} User
          </SimpleLink>
        ) : null}
      </Header>

      {/* Show the form if: */}
      {/* - we're creating a new user OR */}
      {/* - we're editing an existing user and we've retrieved their details from the backend (so we can prevent the form from being interacted with until we get all the details filled in) */}
      {!isExisting || (isExisting && values.name) ? (
        <Form onSubmit={handleSubmit}>
          <Fieldset>
            <Field>
              <Label>Name *</Label>
              <Input {...text("name")} placeholder="Full Name" required />
            </Field>

            <Field>
              <Label>Handle *</Label>
              <Input
                {...text("handle")}
                placeholder="Nickname or Preferred Name"
                required
              />
            </Field>

            <Field>
              <Label>Email *</Label>
              <Input
                {...email("email")}
                placeholder="name@big.vision"
                required
              />
            </Field>

            <Field>
              <Label>Harvest ID *</Label>
              <Input {...text("harvestId")} placeholder="#######" required />
            </Field>
          </Fieldset>

          <Fieldset>
            <Field>
              <Label>Start Date *</Label>
              <Input {...date("startDate")} type="date" required />
            </Field>
            <Field>
              <Label>Role *</Label>

              <SelectDropdown {...raw("role")} options={roles} />
            </Field>

            <Field>
              <Label>Access Level</Label>

              <SelectDropdown
                {...raw("accessLvl")}
                options={fieldOptions["accessLevels"]}
              />
            </Field>
          </Fieldset>

          <Fieldset>
            <Field>
              <Label>Team</Label>

              <SelectDropdown
                {...raw("team")}
                options={fieldOptions["teams"]}
              />
            </Field>

            <Field>
              <Label>Department</Label>

              <SelectDropdown
                {...raw("department")}
                options={fieldOptions["departments"]}
              />
            </Field>

            <Field>
              <Label>Office</Label>

              <SelectDropdown {...raw("office")} options={offices} />
            </Field>
          </Fieldset>

          <Fieldset>
            <Field>
              <Label>Manager</Label>

              <SelectDropdown
                {...raw("manager")}
                options={members.map((member) => ({
                  label: member.name,
                  value: member._id,
                }))}
              />
            </Field>

            <Field>
              <Label>Coordinator</Label>

              <SelectDropdown {...raw("coordinator")} options={coordinators} />
            </Field>
          </Fieldset>

          <Fieldset>
            <Field>
              <Label>Capacity (low) *</Label>
              <Input {...text("capacityLow")} placeholder="##" required />
            </Field>

            <Field>
              <Label>Capacity (high) *</Label>
              <Input {...text("capacityHigh")} placeholder="##" required />
            </Field>

            <Field>
              <Label>Sales Quota</Label>
              <Input {...text("salesQuota")} placeholder="#######" />
            </Field>

            <Field>
              <Label>Cost Rate</Label>
              <Input {...text("costRate")} placeholder="#######" />
            </Field>
          </Fieldset>

          <Button type="submit">Submit</Button>
        </Form>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 0;
`;

const Form = styled.form`
  max-width: 430px;
`;

const Fieldset = styled.fieldset`
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.slate};
    padding-bottom: 30px;
    margin-bottom: 30px;

    ${Field}:last-child {
      margin-bottom: 0;
    }
  }
`;

const PromptText = styled.p``;

export default UserModify;
