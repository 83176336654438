import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getOfficesFromApi } from "../../utils/api";

import { useNotifications } from "../../context/notificationsContext";

import useSort from "../../hooks/useSort";

import { TableHeader, TableRow, TableCell } from "../../components/Table";
import CreateLink from "../../components/links/CreateLink";
import SimpleLink from "../../components/links/SimpleLink";

const Offices = () => {
  const { openAlertPopup } = useNotifications();

  const [offices, setOffices] = useState([]);

  const tableHeadings = [
    {
      label: "Name",
      value: "name",
      type: "string",
    },
  ];

  const [sortedOffices, sortBy, changeSort] = useSort(offices, tableHeadings);

  useEffect(() => {
    getOffices();
  }, []);

  const getOffices = async () => {
    try {
      const apiOffices = await getOfficesFromApi();

      setOffices(apiOffices);
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, something went wrong while fetching the offices. ${error}`,
      );
    }
  };

  return (
    <Container>
      <Header>
        <h1>Offices</h1>
        <CreateLink as={Link} to={`/manage/offices/new`}>
          Create Office
        </CreateLink>
      </Header>

      {sortedOffices.length ? (
        <Table>
          <thead>
            <tr>
              {tableHeadings.map((heading) => (
                <TableHeader
                  key={heading.value}
                  onClick={() => changeSort(heading.value)}
                  isArrowUp={sortBy === `${heading.value}-desc`}
                >
                  {heading.label}
                </TableHeader>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedOffices.map((office) => (
              <TableRow key={office._id}>
                <TableCell>
                  <SimpleLink as={Link} to={`/manage/offices/${office._id}`}>
                    {office.name}
                  </SimpleLink>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const Table = styled.table`
  thead {
    th {
      padding-left: 0;
    }
  }

  tbody {
    td {
      text-align: left;
    }
  }
`;

export default Offices;
