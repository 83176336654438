import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { format, getDay, isSameDay } from "date-fns";
import { useAuth } from "./authContext";
import { useNotifications } from "./notificationsContext";
import { useSockets } from "./socketsContext";

import {
  getActiveTimerFromApi,
  startTimerOnApi,
  stopActiveTimerOnApi,
} from "../utils/api";

import store from "store";

const currentDate = new Date();
let storedDate = store.get("dayFinished");
storedDate = new Date(storedDate);
let dayFinished = false;
const storedFinishedDay = getDay(storedDate);
const day = getDay(currentDate);

// Check to see if the dayFinished local variable is the same date as today
// Or check to see if the local variable is Friday, Saturday, or Sunday, and today is also Friday, Saturday, or Sunday
if (
  (storedDate && isSameDay(storedDate, currentDate)) ||
  (storedFinishedDay >= 5 && day >= 5)
) {
  dayFinished = true;
}

const ActiveTimerContext = React.createContext();

const defaultStopModalMessage = "Great work, let's wrap this up.";

// * This is the context used for handling the active timer
// * (displaying timer, starting and stopping, etc)

const ActiveTimerProvider = (props) => {
  const history = useHistory();
  const { user, authenticating } = useAuth();
  const { openAlertPopup, setLoading } = useNotifications();
  const { startActiveTimerListeners } = useSockets();

  // For tracking the currently active timer
  const [currentTimerStart, setCurrentTimerStart] = useState(null);
  const [currentProjectName, setCurrentProjectName] = useState(null);
  const [currentProjectCode, setCurrentProjectCode] = useState(null);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentProjectBudget, setCurrentProjectBudget] = useState(null);
  const [currentProjectHoursSpent, setCurrentProjectHoursSpent] = useState(
    null,
  );
  const [
    currentProjectWeekHoursSpent,
    setCurrentProjectWeekHoursSpent,
  ] = useState(null);
  const [currentProjectPhases, setCurrentProjectPhases] = useState(null);
  const [currentProjectManager, setCurrentProjectManager] = useState(null);
  const [currentExistingExtraTime, setCurrentExistingExtraTime] = useState(
    null,
  );
  const [timerText, setTimerText] = useState("00:00:00");
  const [timerHours, setTimerHours] = useState(0);
  const [activeTimer, setActiveTimer] = useState(null);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const [isOnProjectDashboard, setIsOnProjectDashboard] = useState(false);

  // For handling the stop modal
  const [isStopModalOpen, setIsStopModalOpen] = useState(false);
  const [isExtraTimeModalOpen, setIsExtraTimeModalOpen] = useState(false);
  const [stopModalText, setStopModalText] = useState(defaultStopModalMessage);

  const [faviconEnabled, setFaviconEnabled] = useState(
    dayFinished ? false : true,
  );

  useEffect(() => {
    startCounter();
  }, [currentTimerStart]); //eslint-disable-line

  useEffect(() => {
    if (!authenticating && user) {
      setupTimer();
      startActiveTimerListeners({ refreshActiveTimerCallback: setupTimer });
    }
  }, [user, authenticating]); //eslint-disable-line

  const startCounter = () => {
    if (currentTimerStart) {
      const newTimer = setInterval(() => {
        countUpFromTime(currentTimerStart);
      }, 1000);

      setActiveTimer(newTimer);
      setIsTimerRunning(true);
    } else {
      setTimerText("00:00:00");
      clearInterval(activeTimer);
      setActiveTimer(null);
      setIsTimerRunning(false);
    }
  };

  const countUpFromTime = (time) => {
    const countFrom = new Date(time);
    const now = new Date();
    const timeDifference = now - countFrom;

    const secondsInADay = 60 * 60 * 1000 * 24;
    const secondsInAHour = 60 * 60 * 1000;

    let hours = Math.floor(
      ((timeDifference % secondsInADay) / secondsInAHour) * 1,
    );
    let mins = Math.floor(
      (((timeDifference % secondsInADay) % secondsInAHour) / (60 * 1000)) * 1,
    );
    let secs = Math.floor(
      ((((timeDifference % secondsInADay) % secondsInAHour) % (60 * 1000)) /
        1000) *
        1,
    );

    setTimerHours(hours + mins / 60);

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (mins < 10) {
      mins = `0${mins}`;
    }

    if (secs < 10) {
      secs = `0${secs}`;
    }

    setTimerText(`${hours}:${mins}:${secs}`);
  };

  const setupTimer = async () => {
    try {
      const newTimerInfo = await getActiveTimerFromApi(
        user._id,
        user.harvestId,
      );

      if (!newTimerInfo) {
        throw new Error();
      }

      setCurrentTimerStart(newTimerInfo.startedAt);
      setCurrentProjectName(newTimerInfo.projectName);
      setCurrentProjectCode(newTimerInfo.projectCode);
      setCurrentProjectId(newTimerInfo.projectId);
      setCurrentProjectBudget(newTimerInfo.projectBudget);
      setCurrentProjectHoursSpent(newTimerInfo.projectHoursSpent);
      setCurrentProjectWeekHoursSpent(newTimerInfo.projectWeekHoursSpent);
      setCurrentProjectPhases(newTimerInfo.projectPhases);
      setCurrentProjectManager(newTimerInfo.projectManager);
      setCurrentExistingExtraTime(newTimerInfo.existingExtraTime);
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "";
      openAlertPopup(
        "Error",
        `There was an issue setting up your active timer. ${errorMessage}`,
      );
    }

    setLoading(false);
  };

  const refreshSidebarCounter = () => {
    setCurrentTimerStart(null);
    setCurrentProjectName(null);
    setCurrentProjectId(null);
    setCurrentProjectBudget(null);
    setCurrentProjectHoursSpent(null);
    setCurrentProjectWeekHoursSpent(null);
    setCurrentProjectPhases(null);
    setCurrentProjectManager(null);
    setCurrentExistingExtraTime(null);
    setupTimer();
  };

  const clearSidebarCounter = () => {
    setCurrentTimerStart(null);
    setCurrentProjectName(null);
    setCurrentProjectId(null);
    setCurrentProjectBudget(null);
    setCurrentProjectHoursSpent(null);
    setCurrentProjectWeekHoursSpent(null);
    setCurrentProjectPhases(null);
    setCurrentProjectManager(null);
    setCurrentExistingExtraTime(null);
  };

  /*
  |--------------------------------------------------------------------------
  | Real Timer stuff
  | This controls actually starting and stopping the timer in harvest
  |--------------------------------------------------------------------------
  */

  const startTimer = async (projectId, type = null) => {
    try {
      if (type === "personal") {
        setLoading(true);
      }

      const response = await startTimerOnApi({
        email: user.email,
        projectId: projectId,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "K:mma"),
        type,
      });

      refreshSidebarCounter();

      if (type !== "personal") {
        history.push(`/projects/${response.redirectProject}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const stopActiveTimer = async (notes, projectToStart = null, over) => {
    await stopActiveTimerOnApi(user.harvestId, notes);

    // if we also need to start a new timer, do that bad
    if (projectToStart) {
      await startTimer(projectToStart);
    } else {
      // If there isn't a new timer to do, redirect to the dashboard
      clearSidebarCounter();
      history.push("/");
    }

    closeStopModal();
    setLoading(false);

    if (over) {
      setIsExtraTimeModalOpen(true);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles opening stop modal
  | This is the modal to stop your time, opens when trying to leave the project
  |--------------------------------------------------------------------------
  */
  const openStopModal = (text = defaultStopModalMessage) => {
    setStopModalText(text);
    setIsStopModalOpen(true);
  };

  const closeStopModal = (needsRedirect = false) => {
    setStopModalText(defaultStopModalMessage);
    setIsStopModalOpen(false);

    // If we need to also do a redirect, do it
    if (needsRedirect && currentProjectId) {
      history.push(`/projects/${currentProjectId}`);
    }
  };

  const closeExtraTimeModal = (needsRedirect = false) => {
    setIsExtraTimeModalOpen(false);

    // If we need to also do a redirect, do it
    if (needsRedirect) {
      history.push(`/projects/${currentProjectId}`);
    }
  };

  return (
    <ActiveTimerContext.Provider
      value={{
        timerText,
        currentProjectId,
        currentProjectName,
        currentProjectCode,
        currentProjectBudget,
        currentProjectHoursSpent,
        currentProjectWeekHoursSpent,
        currentProjectPhases,
        currentProjectManager,
        currentExistingExtraTime,
        currentTimerStart,
        isTimerRunning,

        timerHours,
        setupTimer,
        refreshSidebarCounter,
        clearSidebarCounter,

        isStopModalOpen,
        stopModalText,
        openStopModal,
        closeStopModal,

        isExtraTimeModalOpen,
        closeExtraTimeModal,

        startTimer,
        stopActiveTimer,

        isOnProjectDashboard,
        setIsOnProjectDashboard,

        faviconEnabled,
        setFaviconEnabled,
      }}
      {...props}
    >
      {props.children}
    </ActiveTimerContext.Provider>
  );
};

const useActiveTimer = () => useContext(ActiveTimerContext);

export { ActiveTimerProvider, useActiveTimer, ActiveTimerContext };
