import { useEffect } from "react";

export default (ref, isActive, handler) => {
  useEffect(() => {
    if (isActive) {
      // ? only apply the listener when the thing is active (that way the document doesn't get bogged down with a bunch of listeners if there are many of these)
      document.addEventListener("mousedown", listener);
    }

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [isActive]);

  const listener = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      handler(e);
    }
  };
};
