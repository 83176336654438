import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

import { Input } from "../newForm";
import SendIcon from "../icons/Send";

const ChatCompose = ({ message, onMessageChange, onSend }) => {
  const handleSend = () => {
    // only send if message isn't blank
    if (message) {
      onSend();
    }
  };

  return (
    <Container>
      <InputContainer
        onSubmit={(e) => {
          e.preventDefault();
          handleSend();
        }}
      >
        <ChatInput
          as={TextareaAutosize}
          maxRows={10}
          placeholder="Start typing..."
          value={message}
          onChange={(e) => {
            onMessageChange(e.target.value);
          }}
          onKeyDown={(e) => {
            // If they press cmd+enter in the textarea, we submit
            if (e.metaKey && e.key === "Enter") {
              handleSend();
            }
          }}
          required
        />

        <Submit type="submit" title="Send" aria-label="Send message">
          <SendIcon aria-hidden="true" />
        </Submit>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 24px;
`;

const InputContainer = styled.form`
  position: relative;

  /* to remove the extra space under the textarea */
  line-height: 0;
`;

const ChatInput = styled(Input)`
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 40px;

  line-height: 1.25;

  resize: none;
`;

const Submit = styled.button`
  position: absolute;
  right: 1px;
  bottom: 1px;

  /* so it doesn't overlap the 1px border on top and bottom of the input */
  height: calc(100% - 2px);

  max-height: 40px;

  display: flex;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  transition: background-color 200ms;

  svg {
    path {
      fill: ${(props) => props.theme.colors.indigo};
      transition: fill 200ms;
    }
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo50};
  }
`;

ChatCompose.propTypes = {
  message: PropTypes.string,
  onMessageChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};
ChatCompose.defaultProps = {
  message: "",
};

export default ChatCompose;
