import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getInvoicesFromApi } from "../../../../utils/api";

import CreateLink from "../../../../components/links/CreateLink";
import InvoicesTable from "../../invoices/components/invoicesTable";

const ClientInvoices = ({ clientId }) => {
  const [invoices, setInvoices] = useState(null);

  useEffect(() => {
    if (clientId) {
      getInvoices(clientId);
    }
  }, [clientId]);

  const getInvoices = async (id) => {
    try {
      const invoicesFromApi = await getInvoicesFromApi({
        client: id,
      });

      setInvoices(invoicesFromApi);
    } catch (error) {
      console.error("error getting client invoices", error);
    }
  };

  return (
    <>
      <Header>
        <Heading>Invoices</Heading>

        <CreateLink as={Link} to={`/manage/invoices/new/?client=${clientId}`}>
          Create Invoice
        </CreateLink>
      </Header>

      {invoices ? (
        invoices.length ? (
          <InvoicesTable invoices={invoices} />
        ) : (
          "No invoices for this client."
        )
      ) : null}
    </>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #373040;
  margin-right: 30px;
  margin-bottom: 0;
`;

ClientInvoices.propTypes = {};

export default ClientInvoices;
