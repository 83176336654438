import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getMembersFromApi } from "../../../utils/api";
import { states, companyStages } from "../../../utils/constants";

import { Input, PhoneInput } from "../../../components/newForm";
import SelectDropdown from "../../../components/newSelectDropdown";

import Button from "./button";
import CreateSidebar from "./createSidebar";

const UpdateCompany = ({ currentCompany, isOpen, close, handleUpdate }) => {
  const [id, setId] = useState("");
  const [owner, setOwner] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [availOwners, setAvailOwners] = useState([]);

  useEffect(() => {
    setupData();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Update existing data when the currentCompany changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (currentCompany) {
      setId(currentCompany._id);
      setOwner(
        currentCompany.accountOwner
          ? {
              label: currentCompany.accountOwner.name,
              value: currentCompany.accountOwner._id,
            }
          : "",
      );
      setColor(currentCompany.color ? currentCompany.color : "");
      setStatus(
        currentCompany.status
          ? { label: currentCompany.status, value: currentCompany.status }
          : "",
      );
      setName(currentCompany.name ? currentCompany.name : "");
      setWebsite(
        currentCompany && currentCompany.info
          ? currentCompany.info.website
          : "",
      );
      setPhone(
        currentCompany && currentCompany.info ? currentCompany.info.phone : "",
      );
      setAddress1(
        currentCompany && currentCompany.info
          ? currentCompany.info.address1
          : "",
      );
      setAddress2(
        currentCompany && currentCompany.info
          ? currentCompany.info.address2
          : "",
      );
      setCity(
        currentCompany && currentCompany.info ? currentCompany.info.city : "",
      );
      setState(
        currentCompany && currentCompany.info
          ? {
              label: currentCompany.info.state,
              value: currentCompany.info.state,
            }
          : "",
      );
      setZipcode(
        currentCompany && currentCompany.info
          ? currentCompany.info.zipcode
          : "",
      );
    }
  }, [currentCompany]);

  const setupData = async () => {
    const newAvailOwners = await getMembersFromApi();

    setAvailOwners(newAvailOwners);
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();

      handleUpdate({
        clientId: id,
        harvestId: currentCompany.harvestId,
        database: {
          accountOwner: owner.value,
          status: status.value,
          name,
          color,
          info: {
            website,
            phone,
            address1,
            address2,
            city,
            state: state.value,
            zipcode,
          },
        },
        harvest: {
          name,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <CreateSidebar
      title="Edit Company"
      isOpen={isOpen}
      close={close}
      onSubmit={handleSubmit}
    >
      <CreateDropdown
        placeholder="Owner"
        value={owner}
        onChange={(e) => {
          setOwner(e);
        }}
        options={
          availOwners
            ? availOwners.map((owner) => ({
                label: owner.name,
                value: owner._id,
              }))
            : []
        }
      />
      <CreateDropdown
        placeholder="Status"
        value={status}
        onChange={(e) => {
          setStatus(e);
        }}
        options={
          companyStages
            ? companyStages.map((status) => ({
                label: status,
                value: status,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="Company Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <CreateInput
        placeholder="Color"
        value={color}
        type="color"
        onChange={(e) => setColor(e.target.value)}
      />
      <CreateInput
        placeholder="Website"
        value={website}
        onChange={(e) => setWebsite(e.target.value)}
      />
      <CreatePhoneInput
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 1"
        value={address1}
        onChange={(e) => setAddress1(e.target.value)}
      />
      <CreateInput
        placeholder="Address Line 2"
        value={address2}
        onChange={(e) => setAddress2(e.target.value)}
      />
      <CreateInput
        placeholder="City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <CreateDropdown
        placeholder="State"
        value={state}
        onChange={(e) => {
          setState(e);
        }}
        options={
          states
            ? states.map((state) => ({
                label: state,
                value: state,
              }))
            : []
        }
      />
      <CreateInput
        placeholder="Zip Code"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
      />
      <Button>Update</Button>
    </CreateSidebar>
  );
};

const CreateInput = styled(Input)`
  margin-bottom: 16px;
`;

const CreatePhoneInput = styled(PhoneInput)`
  margin-bottom: 16px;
`;

const CreateDropdown = styled(SelectDropdown)`
  margin-bottom: 16px;
`;

export default UpdateCompany;
