import React from "react";
import styled from "styled-components";

import ClientContracts from "./clientContracts";
import ClientProjects from "./clientProjects";
import ClientInvoices from "./clientInvoices";

const ClientDashboard = ({ clientId, quickLinks }) => {
  return (
    <>
      {quickLinks ? (
        <Section>
          <Heading>Quick Links</Heading>

          {quickLinks.map((quickLink, index) => (
            <QuickLink
              key={index}
              as={quickLink.url ? "a" : "div"}
              href={quickLink.url || undefined}
              target={quickLink.url ? "_blank" : undefined}
              isDisabled={!quickLink.url}
            >
              {quickLink.name}
            </QuickLink>
          ))}
        </Section>
      ) : null}

      <Section>
        <ClientContracts clientId={clientId} />
      </Section>

      <Section>
        <ClientProjects clientId={clientId} />
      </Section>

      <Section>
        <ClientInvoices clientId={clientId} />
      </Section>
    </>
  );
};

const Section = styled.section`
  margin-bottom: 140px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #373040;
  margin-bottom: 20px;
`;

const QuickLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 100px;

  font-weight: 700;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;

  color: ${(props) =>
    props.disabled
      ? props.theme.colors.oldSilver
      : props.theme.colors.oldBlack2};

  padding: 15px;
  margin-right: 40px;

  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-radius: 5px;

  opacity: ${(props) => (props.isDisabled ? ".5" : "1")};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "all")};
  transition: 0.2s;

  &:hover,
  &:focus {
    color: ${(props) =>
      !props.isDisabled ? props.theme.colors.blue : undefined};
    border-color: ${(props) =>
      !props.isDisabled ? props.theme.colors.blue : undefined};
  }
`;

ClientDashboard.propTypes = {};

export default ClientDashboard;
