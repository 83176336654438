import styled from "styled-components";

import { respondTo } from "../../../styles/styleHelpers";

import SelectDropdown from "../../../components/newSelectDropdown";
import { Input } from "../../../components/newForm";

export const ViewContainer = styled.div`
  display: grid;
  /* auto for the filters / 1fr for the table */
  grid-template-rows: auto auto 1fr;

  gap: 24px;

  table {
    line-height: 1.25;

    th,
    td {
      padding: 8.5px 7.5px;
      transition: padding 200ms, opacity 200ms;

      &:first-of-type {
        padding: 8.5px;
        padding-right: 0;
      }

      &[data-sticky-col] {
        position: sticky;
        background-color: white;
        z-index: 4;

        /* give it a higher zindex when hovered so anything that extends outside of the cell, like a dropdown or tooltip, doesn't get covered up by the other cells in the column */
        &:hover,
        &:focus-within {
          z-index: 5;
        }
      }
      &[data-sticky-col="left"] {
        left: 0;
      }
      &[data-sticky-col="right"] {
        right: 0;
      }
      &[data-highlight="highlight"] {
        background-color: ${(props) => props.theme.colors.lightSlate};
      }
    }

    thead {
      th {
        position: sticky;
        top: 61px; // height of the sticky project tabs. So it doesn't get covered by them.
      }
    }

    tbody {
      tr {
        font-size: 14px;

        /* give the createRow extra padding when its hovered on */
        &:last-child {
          &:hover,
          &:focus-within {
            td {
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .table__group-header {
    position: sticky;
    left: 40px;
  }
`;

export const TableContainer = styled.div`
  margin-left: -40px;
  margin-right: -40px;
`;

export const FiltersContainer = styled.div`
  position: relative;
  z-index: 6;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  /* so it stretches to the height of the container, rather than its default input el size */
  input {
    height: auto;
    align-self: stretch;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;
export const FiltersLeft = styled(FilterGroup)`
  position: sticky;
  left: 40px;
`;
export const FiltersRight = styled(FilterGroup)`
  position: sticky;
  right: 40px;

  display: flex;
  align-items: center;
`;

export const SelectedFiltersContainer = styled.div`
  /* max-width to prevent them from overlapping with the filters on the right */
  max-width: 30vw;

  ${respondTo("xlarge")} {
    max-width: 45vw;
  }
`;

export const CreateSelect = styled(SelectDropdown)`
  width: 100%;
  min-width: 100px;
`;

export const CreateInput = styled(Input)`
  width: 100%;
  min-width: 100px;

  &[type="date"] {
    max-width: 155px;
  }
`;

export const CreateValue = styled.div`
  padding: 5px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.red300 : props.theme.colors.coolGray200};
`;

export const CreateLabel = styled(CreateValue)`
  font-size: 12px;
  background-color: white;
`;

export const WeekNumberFields = styled.div`
  display: flex;
  align-items: baseline;
  gap: 3px;

  input {
    width: 75px;
  }
`;
