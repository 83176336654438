import React from "react";
import styled from "styled-components";

import { buttonReset } from "../../styles/styleHelpers";

export const CaretLeft = ({ ...rest }) => {
  return (
    <Button {...rest}>
      <Svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="8.03959" y1="0.353553" x2="1.35331" y2="7.03983" />
        <line x1="7.33248" y1="13.7266" x2="0.646207" y2="7.04033" />
      </Svg>
    </Button>
  );
};

export const CaretRight = ({ ...rest }) => {
  return (
    <Button {...rest}>
      <Svg
        width="9"
        height="15"
        viewBox="0 0 9 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="8.35307" y1="7.04008" x2="1.66679" y2="13.7264" />
        <line x1="7.64596" y1="7.04008" x2="0.959683" y2="0.353803" />
      </Svg>
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset()}
  cursor: pointer;
  width: 20px;

  svg {
    margin-top: 4px;
  }

  svg,
  svg line {
    transition: all 0.2s;
  }

  &:hover,
  &:focus {
    svg {
      transform: scale(0.8);
    }
  }

  &:active {
    svg {
      transform: scale(0.6);
    }
  }
`;

const Svg = styled.svg`
  line {
    stroke: ${(props) => props.theme.colors.darkSlate};
  }
`;
