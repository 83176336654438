import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "../../context/authContext";
import { useActiveTimer } from "../../context/activeTimerContext";

import { respondTo } from "../../styles/styleHelpers";

import { sidebarWidth } from "../../utils/constants";

import SidebarView from "./sidebarView";
import CaretLeft from "../icons/CaretLeft";
import CaretRight from "../icons/CaretRight";

const Sidebar = () => {
  const { user, isAdmin, isManagerOrAdmin, isOpen, setIsOpen } = useAuth();
  const {
    timerText,
    currentProjectWeekHoursSpent,
    currentProjectHoursSpent,
    currentProjectBudget,
    currentProjectName,
    currentProjectCode,
    currentProjectId,
    isTimerRunning,
    isOnProjectDashboard,
    openStopModal,
    timerHours,
  } = useActiveTimer();

  const history = useHistory();

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]); //eslint-disable-line

  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "b") {
      setIsOpen(!isOpen);
    }
  };

  const goToCurrentProject = () => {
    if (currentProjectId) {
      history.push(`/projects/${currentProjectId}`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Set active menu icon
  |--------------------------------------------------------------------------
  */
  const location = useLocation();
  const { pathname } = location;

  const paths = [
    "dashboard",
    "sales/contacts",
    "sales/companies",
    "sales/deals",
    "sales",
    "targets",
    "booking",
    "reports/sales",
    "week",
    "schedule",
    "manage/time",
    "manage/clients",
    "chunklets",
    "projects",
    "users",
    "accessLevels",
    "teams",
    "settings",
    "tasks",
    "rates",
    "kpi",
    "deliverables",
    "archivedDeliverables",
    "client-health",
    "project-health",
    "booked-vs-capacity",
    "production",
    "time",
    "invoices",
    "offices",
    "profile",
  ];

  let route = "";

  paths.forEach((path) => {
    // if the route hasn't been determined yet, and the current pathname contains this path
    if (!route && pathname.includes(path)) {
      // set the route name
      route = path;
    }
  });

  return user ? (
    <Container isOpen={isOpen}>
      <SidebarView
        isOpen={isOpen}
        route={route}
        user={user}
        isAdmin={isAdmin}
        isManagerOrAdmin={isManagerOrAdmin}
        timerText={timerText}
        currentProject={currentProjectName}
        currentProjectCode={currentProjectCode}
        currentProjectBudget={currentProjectBudget}
        currentProjectHoursSpent={currentProjectHoursSpent}
        currentProjectWeekHoursSpent={currentProjectWeekHoursSpent}
        isTimerRunning={isTimerRunning}
        isTimerWarning={!isOnProjectDashboard}
        openStopModal={openStopModal}
        goToCurrentProject={goToCurrentProject}
        timerHours={timerHours}
      />
      <ToggleContainer>
        <ToggleSidebar onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <CaretLeft /> : <CaretRight />}
        </ToggleSidebar>
        <ToggleInfo>CMD + B</ToggleInfo>
      </ToggleContainer>
    </Container>
  ) : null;
};

const Container = styled.div`
  position: fixed;
  left: 0;
  height: 100vh;
  width: ${(props) =>
    props.isOpen ? sidebarWidth.laptop : sidebarWidth.closed};
  z-index: 105;

  ${respondTo("xlarge")} {
    width: ${(props) =>
      props.isOpen ? sidebarWidth.full : sidebarWidth.closed};
  }
`;

const ToggleContainer = styled.div`
  position: absolute;
  top: 0;
  height: 30px;
  width: 30px;
  right: -30px;
`;

const ToggleInfo = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
  min-width: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(233, 233, 233, 0.5);
  transform: translateY(15%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 2;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;
`;

const ToggleSidebar = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.oldLightGray};
  cursor: pointer;

  &:hover ~ ${ToggleInfo} {
    opacity: 1;
    transform: translateY(0%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

export default Sidebar;
