import axios from "axios";
import store from "store";
import { format } from "date-fns";

const auth = process.env.REACT_APP_API_AUTH || "";
let token = "";
export let headers = { Authorization: auth, "x-access-token": token };

export const refreshToken = () => {
  const newToken = store.get("sherpaToken");

  if (newToken) {
    token = newToken;
    headers = { Authorization: auth, "x-access-token": token };
  }
};

export const loginOnApi = async (email, token) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/auth/login`,
      headers,
      data: {
        email,
        googleToken: token,
      },
    };

    const response = await axios(config);

    if (response.data.status === "success") {
      return response.data.token;
    } else {
      return false;
    }
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| User / Team Member calls
|--------------------------------------------------------------------------
*/
export const getUserInfoFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/members/me`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);

    const errMsg = !err.response // If we didn't get any response at all from the server
      ? "Failed to connect to the server. The server may not be responding or there may be some connection/internet issue"
      : err.response.status === 401 // If the server sends back forbidden, it was probably an auth thing
      ? "Failed to get user data from server. It was probably an issue with the auth or JWT (such as: expired, malformed, unavailable, deleted)."
      : "Failed to get user data from server. It is probably NOT related to the JWT or the server connection.";
    throw new Error(errMsg);
  }
};

export const getMembersFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/members`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createMemberOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/team/members`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data.teamMember;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const updateMemberOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/team/members/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data.teamMember;
  } catch (error) {
    console.error(error);
  }
};

export const getManagerChildrenFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/children/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Time related api calls
|--------------------------------------------------------------------------
*/

export const getProjectsFromApi = async (email, date, allTime) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/tasks/member/${email}`,
      headers,
      params: {
        date,
        allTime,
      },
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

export const getProductionProjectsFromApi = async (date, weekNumber) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/tasks/members`,
      headers,
      params: {
        date,
        weekNumber,
      },
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

export const getProjectHoursFromApi = async (projectId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/hours-billed`,
      headers,
    };

    const results = await axios(config);
    return results.data.hoursInfo;
  } catch (err) {
    console.error(err);
  }
};

export const getProjectPaceFromApi = async (projectId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/pace`,
      headers,
    };

    const results = await axios(config);
    return results.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const startTimerOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/timer/start`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Project comments
|--------------------------------------------------------------------------
*/
export const postCommentToProject = async (projectId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const editCommentOnProject = async (projectId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const deleteCommentOnProject = async (projectId, data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updateProjectChatLastViewed = async (projectId, data) => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/comments/lastViewed`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Post expense to project
|--------------------------------------------------------------------------
*/
export const postExpenseToProject = async (projectId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/expenses`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateProjectOnApi = async (projectId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Project phases
|--------------------------------------------------------------------------
*/

export const createPhaseOnApi = async (projectId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/phases`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updatePhaseOnApi = async (projectId, phaseId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/phases/${phaseId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deletePhaseOnApi = async (projectId, phaseId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/phases/${phaseId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Chunks
|--------------------------------------------------------------------------
*/
export const updateProjectChunksOnApi = async (
  projectId,
  chunkData,
  chunkIndex,
) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/chunks`,
      headers,
      data: {
        chunkData,
        chunkIndex,
      },
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const manageRecurringChunkOnApi = async (projectId, chunkId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/chunks/recurring/${chunkId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteRecurringOnApi = async (taskId, data = null) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}/recurring`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Chunklets
|--------------------------------------------------------------------------
*/
export const getChunkletsFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/chunklets`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getChunkletFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/chunklets/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createChunkletOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/chunklets`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const updateChunkletOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/chunklets/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const deleteChunkletOnApi = async (id, data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/projects/chunklets/${id}`,
      headers,
      data,
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteProjectChunkOnApi = async (projectId, chunkId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/chunks/${chunkId}`,
      headers,
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getMemberEntriesFromApi = async (id, from, to = null) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/time/entries?harvestId=${id}&from=${from}`;

    if (to) {
      url += `&to=${to}`;
    }

    const config = {
      method: "get",
      url,
      headers,
    };
    const results = await axios(config);
    return results;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const createEntryOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/entries/me`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updatePastEntryFromApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/past/entry`,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getWeekProjectsFromApi = async (dateInWeek = new Date()) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/tasks/member/me`,
      headers,
      params: {
        date: format(dateInWeek, "yyyy-MM-dd"),
      },
    };

    const response = await axios(config);
    if (response.data.status === "success") {
      return response.data.data.projects;
    } else throw new Error(response.data.message);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getActiveTimerFromApi = async (userId, userHarvestId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/timer/active?userId=${userId}&harvestId=${userHarvestId}`,
      headers,
    };

    const results = await axios(config);

    if (results.data.status === "success") {
      return results.data.data.activeTimer;
    } else throw new Error("Api call failed");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const stopActiveTimerOnApi = async (userHarvestId, notes = null) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/time/timer/active/stop?harvestId=${userHarvestId}`,
      headers,
      data: {
        notes,
      },
    };

    const results = await axios(config);

    if (results.data.status === "success") {
      return true;
    } else throw new Error(results.data.message);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updateNotificationOnApi = async (noteId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/notifications/${noteId}`,
      headers,
      data,
    };

    const results = await axios(config);

    if (results.data.status === "success") {
      return true;
    } else throw new Error(results.data.message);
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Team calls
|--------------------------------------------------------------------------
*/

export const getTeamsFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/teams`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getTeamFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/teams/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createTeamOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/teams`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateTeamOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/teams/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Tasks
|--------------------------------------------------------------------------
*/

export const getAllTasksFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/tasks/`,
      headers,
      params,
    };

    const result = await axios(config);

    return result.data.data.result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateTaskStatusOnApi = async (taskId, newStatus) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}/status`,
      headers,
      data: {
        status: newStatus,
      },
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const createTaskOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/tasks`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updateTaskOnApi = async (taskId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateTaskRecurringOnApi = async (taskId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}/recurring`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteTasksOnApi = async (data) => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/tasks`,
    headers,
    data,
  };

  const response = await axios(config);

  return response.data;
};

export const deleteTaskOnApi = async (taskId) => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/tasks/${taskId}`,
    headers,
  };

  const result = await axios(config);

  return result;
};

/*
|--------------------------------------------------------------------------
| File calls
|--------------------------------------------------------------------------
*/

export const getFilesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/files`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const getFileFromApi = async (fileId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const downloadFileFromApi = async (fileId, params, handleProgress) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}/download`,
      headers,
      responseType: "blob",

      params,

      onDownloadProgress: (progressEvent) => {
        // * This will appear to jump very quickly when testing,
        // * but in production, users are uploading huge files,
        // * and will see a decent amount of loading times

        const percentage = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100,
        );

        if (handleProgress) {
          handleProgress(percentage);
        }
      },
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const uploadFileToApi = async (
  file,
  userId,
  data = {},
  handleProgress,
) => {
  try {
    // since we're sending a file, gotta use formData
    const formData = new FormData();

    // append the file
    formData.append("file", file);

    // append any other included data
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/files/${userId}`,
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      data: formData,

      onUploadProgress: function(progressEvent) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        if (handleProgress) {
          handleProgress(percentage);
        }
      },
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const uploadLinkFileToApi = async (userId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/files/link/${userId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const updateFileOnApi = async (fileId, data = {}, handleProgress) => {
  try {
    // since we're sending a file, gotta use formData
    const formData = new FormData();

    // append any included data
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}`,
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      data: formData,

      onUploadProgress: function(progressEvent) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );

        if (handleProgress) {
          handleProgress(percentage);
        }
      },
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const deleteFileOnApi = async (fileId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const restoreFileOnApi = async (fileId) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}/restore`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Invoices
|--------------------------------------------------------------------------
*/

export const getInvoicesFromApi = async (params) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/invoices`,
    headers,
    params,
  };

  const response = await axios(config);

  return response.data;
};

export const getInvoiceFromApi = async (invoiceId) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`,
    headers,
  };

  const response = await axios(config);

  return response.data;
};

export const updateInvoiceOnApi = async (invoiceId, data) => {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`,
    headers,
    data,
  };

  const response = await axios(config);

  return response.data;
};

export const createInvoiceOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/invoices/`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const deleteInvoiceOnApi = async (invoiceId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const deleteInvoiceLineItemOnApi = async (invoiceId, lineItemId) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/lineItem/${lineItemId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const publishInvoiceOnApi = async (invoiceId) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/publish`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const sendInvoiceOnApi = async (invoiceId) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/send`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const syncInvoiceOnApi = async (invoiceId) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/sync`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const importInvoiceOnApi = async (invoiceId) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/import`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getUninvoicedFromApi = async ({ from, to }) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/invoices/uninvoiced?from=${from}&to=${to}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const postInvoiceCommentOnApi = async (invoiceId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const getQuickbooksAuthUri = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/invoices/auth`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const refreshQuickbooksAuthToken = async () => {
  try {
    const config = {
      method: "put",
      url: `${process.env.REACT_APP_API_URL}/invoices/token`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const revokeQuickbooksAuthToken = async () => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/invoices/token`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Post comment to project
|--------------------------------------------------------------------------
*/
export const postFileCommentOnApi = async (fileId, data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Edit comments on file
|--------------------------------------------------------------------------
*/
export const editFileCommentsOnApi = async (fileId, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/files/${fileId}/comments`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Get deliverables for a user
|--------------------------------------------------------------------------
*/
export const getDeliverablesFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/deliverables`,
      headers,
    };

    const results = await axios(config);
    return results.data.deliverables;
  } catch (err) {
    console.error(err);
  }
};

export const deleteDeliverablesOnApi = async (data) => {
  const config = {
    method: "delete",
    url: `${process.env.REACT_APP_API_URL}/deliverables`,
    headers,
    data,
  };

  const response = await axios(config);

  return response.data;
};

export const getDeliverableFromApi = async (deliverableId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/deliverables/${deliverableId}`,
      headers,
    };

    const results = await axios(config);
    return results.data.result;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Get project deliverables
|--------------------------------------------------------------------------
*/
export const getProjectDeliverablesFromApi = async (projectId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/deliverables/all/${projectId}`,
      headers,
    };

    const response = await axios(config);

    return response.data.deliverables;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Get all deliverables
|--------------------------------------------------------------------------
*/
export const getAllDeliverablesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/deliverables/all`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data.deliverables;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Edit project deliverable
|--------------------------------------------------------------------------
*/
export const editDeliverableOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/deliverables/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.deliverable;
  } catch (error) {
    console.error(error);
  }
};

export const checkOffDeliverableOnApi = async (id, checkId) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/deliverables/${id}/check/${checkId}`,
      headers,
    };

    await axios(config);
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Create project deliverable
|--------------------------------------------------------------------------
*/
export const createDeliverableOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/deliverables/`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.deliverable;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Get deliverables that a user needs to review
|--------------------------------------------------------------------------
*/
export const getDeliverablesToReviewFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/deliverables/review`,
      headers,
    };

    const results = await axios(config);
    return results.data.deliverables;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Get tasks and deliverables for a user and their team
|--------------------------------------------------------------------------
*/
export const getTeamTasksFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/team`,
      headers,
    };

    const results = await axios(config);
    return results.data.tasks;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Client stuff
|--------------------------------------------------------------------------
*/
export const getClientFromApi = async (clientId) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/companies/${clientId}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const getCompaniesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/companies`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const getClientsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/companies`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const createClientOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/companies`,
      headers,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const updateClientOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/companies/${id}`,
      headers,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const archiveClientsOnApi = async (data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/manage/companies`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const getClientHealthFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/companies/health`,
      headers,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const getIndustriesFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/industries`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Contact stuff
|--------------------------------------------------------------------------
*/
export const getContactFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/contacts/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const getContactsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/contacts`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const createContactOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/contacts`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const updateContactOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/contacts/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

export const deleteContactsOnApi = async (data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/manage/contacts`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Project Calls
|--------------------------------------------------------------------------
*/
export const getAllProjectsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getProjectHealthFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/health`,
      headers,
    };

    const response = await axios(config);

    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Day Completion api calls
|--------------------------------------------------------------------------
*/

export const getDayCompletionsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/reports/day-completion`,
      headers,
      params,
    };

    const results = await axios(config);
    if (results.data.status === "success") {
      return results.data.results;
    } else {
      throw new Error(results.data.message);
    }
  } catch (err) {
    console.error(err);
  }
};

export const getLast30FromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/reports/day-completion/last-30`,
      headers,
      params,
    };

    const results = await axios(config);
    if (results.data.status === "success") {
      return results.data.results;
    } else {
      throw new Error(results.data.message);
    }
  } catch (err) {
    console.error(err);
  }
};

export const getTodayCompletionsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/reports/day-completion/today`,
      headers,
      params,
    };

    const results = await axios(config);
    if (results.data.status === "success") {
      return results.data.results;
    } else {
      throw new Error(results.data.message);
    }
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Capacity Report api calls
|--------------------------------------------------------------------------
*/

export const getCapacityReportsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/reports/capacity-reports`,
      headers,
      params,
    };

    const results = await axios(config);
    if (results.data.status === "success") {
      return results.data.results;
    } else {
      throw new Error(results.data.message);
    }
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

export const getContractsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/contracts`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getContractFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/contracts/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const createContractOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/contracts`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updateContractOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/contracts/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getContractBudgetDetailsFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/contracts/${id}/budget`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Deals
|--------------------------------------------------------------------------
*/
export const getDealFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/deals/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const getDealsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/deals`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const createDealOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/deals`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const updateDealOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/deals/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

export const deleteDealsOnApi = async (data) => {
  try {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/manage/deals`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    throw errorMessage;
  }
};

/*
|--------------------------------------------------------------------------
| Offices
|--------------------------------------------------------------------------
*/

export const getOfficeFromApi = async (id) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/offices/${id}`,
    headers,
  };

  const response = await axios(config);

  return response.data;
};

export const getOfficesFromApi = async (params) => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/offices`,
    headers,
    params,
  };

  const response = await axios(config);

  return response.data;
};

export const createOfficeOnApi = async (data) => {
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/offices`,
    headers,
    data,
  };

  const response = await axios(config);

  return response.data;
};

export const updateOfficeOnApi = async (id, data) => {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/offices/${id}`,
    headers,
    data,
  };

  const response = await axios(config);

  return response.data;
};

/*
|--------------------------------------------------------------------------
| Settings
|--------------------------------------------------------------------------
*/

export const getSettings = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/settings`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (err) {
    console.error(err);
  }
};

export const editSettings = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/settings`,
      headers,
      data,
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

export const createWeeklyExperienceSurvey = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/wrapup/survey`,
      headers,
      data,
    };

    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Roles
|--------------------------------------------------------------------------
*/
export const getAllRolesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/role`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getRoleFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/role/${id}`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createRoleOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/role`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateRoleOnApi = async (id, data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/role/${id}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Activity Log api calls
|--------------------------------------------------------------------------
*/

export const getActivityLogsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/activities`,
      headers,
      params,
    };

    const results = await axios(config);
    if (results.data.status === "success") {
      return results.data.results;
    } else {
      throw new Error(results.data.message);
    }
  } catch (err) {
    console.error(err);
  }
};

// * helpers
const getErrorMessage = (err) => err?.response?.data?.message || err;
