import React from "react";

export default () => (
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.293 9.70701C0.105529 9.51948 0.000213623 9.26517 0.000213623 9.00001C0.000213623 8.73485 0.105529 8.48054 0.293 8.29301L3.586 5.00001L0.293 1.70701C0.110842 1.51841 0.0100473 1.26581 0.0123258 1.00361C0.0146042 0.741413 0.119773 0.4906 0.305182 0.305192C0.49059 0.119784 0.741402 0.0146148 1.0036 0.0123364C1.2658 0.010058 1.5184 0.110853 1.707 0.293011L5.707 4.29301C5.89447 4.48054 5.99979 4.73485 5.99979 5.00001C5.99979 5.26517 5.89447 5.51948 5.707 5.70701L1.707 9.70701C1.51947 9.89448 1.26516 9.9998 1 9.9998C0.734836 9.9998 0.480528 9.89448 0.293 9.70701Z"
      fill="#374151"
    />
  </svg>
);
