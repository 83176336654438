import React from "react";
import styled from "styled-components";
import CloseButton from "../../../components/buttons/CloseButton";

const CreateSidebar = ({ title, isOpen, close, children, ...rest }) => {
  return (
    <Container isOpen={isOpen} {...rest}>
      <Close onClick={close} type="button" />
      {title ? <Title>+ {title}</Title> : null}
      {children ? children : null}
    </Container>
  );
};

const Close = styled(CloseButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const Container = styled.form`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 14vw;
  padding: 26px 16px;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(100%)"};
  transition: transform 0.5s;

  background: #ffffff;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  z-index: 100;
  overflow: auto;
`;

const Title = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 26px;
`;

export default CreateSidebar;
