import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { normalize } from "polished";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { AuthProvider } from "./context/authContext";
import { SocketsProvider } from "./context/socketsContext";
import { ActiveTimerProvider } from "./context/activeTimerContext";
import { NotificationsProvider } from "./context/notificationsContext";
import { FileProvider } from "./context/fileContext";

import theme from "./styles/theme";
import { globalStyles } from "./styles/globalStyles";

import MainContainer from "./components/MainContainer";
import PrivateRoute from "./components/PrivateRoute";
import DesktopLogin from "./components/DesktopLogin";

//import Dashboard from "./views/dashboard";
import AdminDashboard from "./views/dashboard/adminDashboard";
import Survey from "./views/survey";
import Settings from "./views/settings";
import Targets from "./views/reports/targets";
import Booking from "./views/reports/booking";
import ReportSales from "./views/reports/sales";
import Production from "./views/reports/production";
import WorkPlanner from "./views/workPlanner";
import Login from "./views/login";
import Projects from "./views/projects";
import ProjectRequest from "./views/projects/projectRequest";
import ProjectView from "./views/projects/projectView";
import ProjectEdit from "./views/projects/projectEdit";
import ProjectPlan from "./views/projects/projectPlan";
//import ProjectBrief from "./views/projects/projectBrief";
import Client from "./views/manage/clients/client";
import Clients from "./views/manage/clients/clientsList";
import ClientCreate from "./views/manage/clients/clientCreate";
import ClientEdit from "./views/manage/clients/clientEdit";
import Teams from "./views/manage/teams";
import TeamCreate from "./views/manage/teamCreate";
import TeamEdit from "./views/manage/teamEdit";
import Roles from "./views/manage/roles";
import RoleCreate from "./views/manage/roleCreate";
import RoleEdit from "./views/manage/roleEdit";
import Users from "./views/manage/users";
import AccessLevels from "./views/manage/accessLevels";
import AccessLevelEdit from "./views/manage/accessLevelEdit";
import AccessLevelCreate from "./views/manage/accessLevelCreate";
import Invoices from "./views/manage/invoices/invoicesList";
import InvoiceCreate from "./views/manage/invoices/invoiceCreate";
import InvoiceEdit from "./views/manage/invoices/invoiceEdit";
import ManageTasks from "./views/tasks/manageTasks";
import Task from "./views/tasks/task";
import TaskGenerator from "./views/tasks/taskGenerator";
import TaskTemplate from "./views/tasks/taskTemplate";
import UserModify from "./views/manage/userModify";
import Kpis from "./views/manage/kpis";
import KpiCreate from "./views/manage/kpiCreate";
import KpiEdit from "./views/manage/kpiEdit";
import Time from "./views/manage/time";
import Offices from "./views/manage/offices";
import OfficeModify from "./views/manage/officeModify";
import Sidebar from "./components/Sidebar/index";
import Alert from "./components/Alert/index";
import Prompt from "./components/Prompt/index";
import StopTimerModal from "./components/StopTimerModal";
import FileModal from "./components/FileModal";
import Modal from "./components/Modal/index";
import DevPanel from "./components/DevPanel";
import Favicon from "./components/Favicon";
import CheckIfCorrectViewForActiveTimer from "./components/ProjectViewChecker";
import Chunklets from "./views/projects/chunklets";
import Chunklet from "./views/projects/chunklet";
import Home from "./views/home/index";
import Profile from "./views/profile/index";
import Deliverables from "./views/reports/deliverables";
import ArchivedDeliverables from "./views/reports/archivedDeliverables";
import Tasks from "./views/reports/tasks";
import ArchivedTasks from "./views/reports/archivedTasks";
import Scheduler from "./views/scheduler/index";
import ClientHealth from "./views/reports/clientHealth";
import ProjectHealth from "./views/reports/projectHealth";
import BookedVsCapacity from "./views/reports/bookedVsCapacity";

import Sales from "./views/sales/sales";
import Contacts from "./views/sales/contacts";
import Companies from "./views/sales/companies";
import Deals from "./views/sales/deals";
import SingleDeal from "./views/sales/singleDeal";
import SingleCompany from "./views/sales/singleCompany";
import SingleContact from "./views/sales/singleContact";

import Loading from "./components/Loading";

// global styles
const GlobalStyle = createGlobalStyle`
  ${normalize()}
  ${globalStyles}
`;

const App = () => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Router>
          <NotificationsProvider>
            <AuthProvider>
              <SocketsProvider>
                <FileProvider>
                  <ActiveTimerProvider>
                    <DndProvider backend={HTML5Backend}>
                      <Favicon />
                      <CheckIfCorrectViewForActiveTimer />

                      <MainContainer>
                        <Loading />
                        <Sidebar />
                        <Switch>
                          {/* <PrivateRoute
                          exact
                          path="/dashboard"
                          render={(props) => <Dashboard {...props} />}
                        /> */}
                          <PrivateRoute
                            exact
                            path="/desktop-login"
                            render={(props) => <DesktopLogin {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/dashboard"
                            render={(props) => <AdminDashboard {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/reports/targets"
                            render={(props) => <Targets {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/booking"
                            render={(props) => <Booking {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/deliverables"
                            render={(props) => <Deliverables {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/archivedDeliverables"
                            render={(props) => (
                              <ArchivedDeliverables {...props} />
                            )}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/archivedTasks"
                            render={(props) => <ArchivedTasks {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/tasks"
                            render={(props) => <Tasks {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/client-health"
                            render={(props) => <ClientHealth {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/project-health"
                            render={(props) => <ProjectHealth {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/booked-vs-capacity"
                            render={(props) => <BookedVsCapacity {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/production"
                            render={(props) => <Production {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/reports/sales"
                            render={(props) => <ReportSales {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/week"
                            render={(props) => <WorkPlanner {...props} />}
                          />
                          <PrivateRoute
                            exact
                            managerOrAdmin
                            path="/schedule"
                            render={(props) => <Scheduler {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/projects/new"
                            render={(props) => <ProjectRequest {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/projects/:id/edit"
                            render={(props) => <ProjectEdit {...props} />}
                          />
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/projects/:id/plan"
                            render={(props) => <ProjectPlan {...props} />}
                          /> */}
                          {/* <PrivateRoute
                        exact
                        path="/projects/:id/brief"
                        render={(props) => <ProjectBrief {...props} />}
                      /> */}
                          <PrivateRoute
                            exact
                            path="/projects/chunklets"
                            render={(props) => <Chunklets {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/projects/chunklets/:id"
                            render={(props) => <Chunklet {...props} />}
                          />
                          <PrivateRoute
                            path="/projects/:id"
                            render={(props) => <ProjectView {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/projects"
                            render={(props) => <Projects {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/clients/new"
                            render={(props) => <ClientCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/clients/:id/edit"
                            render={(props) => <ClientEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            path="/manage/clients/:id"
                            render={(props) => <Client {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/clients"
                            render={(props) => <Clients {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/teams/new"
                            render={(props) => <TeamCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/teams/:id"
                            render={(props) => <TeamEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/teams"
                            render={(props) => <Teams {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/roles/new"
                            render={(props) => <RoleCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/roles/:id"
                            render={(props) => <RoleEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/roles"
                            render={(props) => <Roles {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/users"
                            render={(props) => <Users {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/accessLevels/new"
                            render={(props) => <AccessLevelCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/accessLevels/:id"
                            render={(props) => <AccessLevelEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/accessLevels"
                            render={(props) => <AccessLevels {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/kpi"
                            render={(props) => <Kpis {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/kpi/new"
                            render={(props) => <KpiCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/kpi/:id"
                            render={(props) => <KpiEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/invoices"
                            render={(props) => <Invoices {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/invoices/new"
                            render={(props) => <InvoiceCreate {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/invoices/:id"
                            render={(props) => <InvoiceEdit {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/time"
                            render={(props) => <Time {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/offices"
                            render={(props) => <Offices {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/offices/:id"
                            render={(props) => <OfficeModify {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/tasks/manage"
                            render={(props) => <ManageTasks {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/tasks/:id"
                            render={(props) => <Task {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/tasks/generators/:id"
                            render={(props) => <TaskGenerator {...props} />}
                          />
                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/tasks/templates/:id"
                            render={(props) => <TaskTemplate {...props} />}
                          />

                          <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/manage/users/:id"
                            render={(props) => <UserModify {...props} />}
                          />
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/contacts"
                            render={(props) => <Contacts {...props} />}
                          /> */}
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/contacts/:contactId"
                            render={(props) => <SingleContact {...props} />}
                          /> */}
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/companies"
                            render={(props) => <Companies {...props} />}
                          /> */}
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/companies/:companyId"
                            render={(props) => <SingleCompany {...props} />}
                          /> */}
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/deals"
                            render={(props) => <Deals {...props} />}
                          /> */}
                          {/* <PrivateRoute
                            managerOrAdmin
                            exact
                            path="/sales/deals/:dealId"
                            render={(props) => <SingleDeal {...props} />}
                          /> */}
                          <PrivateRoute
                            managerOrAdmin
                            path="/sales"
                            render={(props) => <Sales {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/survey"
                            render={(props) => <Survey {...props} />}
                          />
                          <PrivateRoute
                            adminOnly
                            exact
                            path="/settings"
                            render={(props) => <Settings {...props} />}
                          />
                          <Route
                            exact
                            path="/profile"
                            render={(props) => <Profile {...props} />}
                          />
                          <Route
                            exact
                            path="/login"
                            render={(props) => <Login {...props} />}
                          />
                          <PrivateRoute
                            exact
                            path="/"
                            render={(props) => <Home {...props} />}
                          />
                          <Redirect to="/" />
                        </Switch>

                        <Modal />

                        <FileModal />

                        <StopTimerModal />

                        <Alert />

                        <Prompt />
                      </MainContainer>

                      {process.env.NODE_ENV === "development" ? (
                        <DevPanel />
                      ) : null}
                    </DndProvider>
                  </ActiveTimerProvider>
                </FileProvider>
              </SocketsProvider>
            </AuthProvider>
          </NotificationsProvider>
        </Router>
      </ThemeProvider>
    </>
  );
};

export default App;
