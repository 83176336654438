import React from "react";
import styled from "styled-components";

const InboxIcon = ({ ...rest }) => {
  return (
    <Svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M26.233 2.90786C26.2124 2.83335 26.1721 2.76773 26.1175 2.71979C26.0629 2.67186 25.9967 2.6439 25.9276 2.63965H4.0724C4.00335 2.6439 3.9371 2.67186 3.88251 2.71979C3.82792 2.76773 3.78761 2.83335 3.76697 2.90786L0.0339366 14.7859C0.0339366 14.8243 0 14.8243 0 14.8626V26.7407C0 27.3504 0.214527 27.9351 0.596389 28.3663C0.97825 28.7974 1.49617 29.0396 2.0362 29.0396H27.9638C28.5038 29.0396 29.0217 28.7974 29.4036 28.3663C29.7855 27.9351 30 27.3504 30 26.7407V14.8626C30 14.8243 29.9661 14.8243 29.9661 14.7859L26.233 2.90786ZM4.30995 3.40598H25.69L29.1855 14.5177H21.6176C21.5645 14.5143 21.5115 14.5267 21.4638 14.5536C21.4162 14.5805 21.3756 14.6209 21.3462 14.671C20.5483 15.722 19.5596 16.5671 18.447 17.1488C17.3345 17.7306 16.1242 18.0355 14.8982 18.0428H14.8303C13.6128 18.0449 12.4087 17.7557 11.2973 17.1944C10.1859 16.633 9.19244 15.8122 8.38235 14.7859L8.28054 14.6327C8.25253 14.5927 8.21613 14.5613 8.17466 14.5412C8.13318 14.5212 8.08793 14.5131 8.04299 14.5177H0.81448L4.30995 3.40598ZM29.3213 26.779C29.3213 27.1855 29.1782 27.5753 28.9237 27.8627C28.6691 28.1502 28.3238 28.3116 27.9638 28.3116H2.0362C1.67618 28.3116 1.3309 28.1502 1.07633 27.8627C0.821751 27.5753 0.678733 27.1855 0.678733 26.779V15.2841H7.8733C8.74576 16.3948 9.81696 17.284 11.0162 17.893C12.2154 18.502 13.5154 18.8169 14.8303 18.8169C16.1453 18.8169 17.4452 18.502 18.6445 17.893C19.8437 17.284 20.9149 16.3948 21.7873 15.2841H29.3213V26.779Z" />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    fill: ${(props) => props.theme.colors.mediumGray};
  }
`;

export default InboxIcon;
