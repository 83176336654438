import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useNotifications } from "../context/notificationsContext";
import llama from "../images/llama.jpg";

const Loading = () => {
  const { loading } = useNotifications();

  return (
    <AnimatePresence>
      {loading && (
        <Container
          loading={loading ? 1 : 0}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "spring", duration: 1 }}
        >
          <LoadingIcon>
            <img src={llama} alt="loading" />
          </LoadingIcon>
        </Container>
      )}
    </AnimatePresence>
  );
};

const Container = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  pointer-events: ${(props) => (props.loading ? "all" : "none")};
`;

const LoadingIcon = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 0 0 rgba(255, 196, 117, 0.5);
  animation: pulse 2s infinite, colorChange 5s infinite linear;

  @keyframes colorChange {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 196, 117, 0.5);
    }
    70% {
      box-shadow: 0 0 0 150px rgba(255, 196, 117, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 196, 117, 0);
    }
  }

  img {
    border-radius: 50%;
  }
`;

export default Loading;
