import React, { useEffect, useState, useRef } from "react";
import { Draggable } from "@fullcalendar/interaction";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { groupBy } from "lodash";
import {
  addDays,
  addMinutes,
  differenceInMilliseconds,
  getYear,
  startOfMonth,
  startOfYear,
  endOfMonth,
  endOfYear,
  isEqual,
  set,
} from "date-fns";

import { updateTaskOnApi } from "./utils/api";

import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";

import Calendar from "./components/calendar";
import Tasks from "./components/tasks";
import TimelineButtonGroup from "./components/timelineButtonGroup";
import CaretLeft from "../../components/icons/CaretLeft";
import CaretRight from "../../components/icons/CaretRight";
import SettingsIcon from "../../components/icons/SettingsIcon";
import TasksIcon from "../../components/icons/TasksIcon";
import { Field, Label, Input } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";

import {
  getAllProjectsFromApi,
  getMembersFromApi,
  getAllTasksFromApi,
} from "../../utils/api";

import {
  getWeek,
  getStandardizedDateTime,
  getDateTimeString,
  startOfWeek,
  endOfWeek,
} from "../../utils/helpers";
import DefaultLink from "../../components/links/DefaultLink";

const Scheduler = () => {
  const { user } = useAuth();
  const { setLoading } = useNotifications();

  const [currentView, setCurrentView] = useState("day"); // day, week, month
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isProjectView, setIsProjectView] = useState(false);
  const [isCapacityView, setIsCapacityView] = useState(true); //eslint-disable-line

  // tasks / settings
  const [sidebarView, setSidebarView] = useState("tasks");
  const [dayStart, setDayStart] = useState(7);
  const [dayEnd, setDayEnd] = useState(19);

  const [availableProjects, setAvailableProjects] = useState([]);
  const [tasks, setTasks] = useState(null);

  // the "events" on the calendar
  const [timeslots, setTimeslots] = useState([]);
  const [openCapacitySlots, setOpenCapacitySlots] = useState([]);
  const [availableTasks, setAvailableTasks] = useState([]);
  const [selectProjects, setSelectProjects] = useState([]);
  const [resources, setResources] = useState([]);

  const [members, setMembers] = useState([]);
  const [allMembers, setAllMembers] = useState([]);

  const [dragging, setDragging] = useState(false);
  const [backlogOpen, setBacklogOpen] = useState(true);
  const [errorLocation, setErrorLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [filterType, setFilterType] = useState(null);
  const [projectFilters, setProjectFilters] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [memberFilters, setMemberFilters] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  const calendarRef = useRef(null);

  // TODO: tasks listed in sidebar aren't updated when changing views.
  // - for instance, switching from D view to W view doesn't display the tasks for the following week in the sidebar
  // - perhaps fetching tasks should be handled differently. since it looks like all tasks are being fetched every time, rather than just those that pertain to the current timeline

  /*
  |--------------------------------------------------------------------------
  | Set the draggable area for the budgets
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    new Draggable(document.getElementById("budgets-drag"), {
      itemSelector: "[data-event]",
    });

    // keydown shortcut for toggling the backlog
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Changes view on calendar when view changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (calendarRef.current && currentView) {
      const calendarApi = calendarRef.current.getApi();

      const newCalendarView =
        currentView === "month"
          ? "resourceTimelineYear"
          : currentView === "week"
          ? "resourceTimelineMonth"
          : "resourceTimelineWeek";

      calendarApi.changeView(newCalendarView);
    }
  }, [currentView]);

  // get members on load
  useEffect(() => {
    if (user) {
      getMembers();
    }
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Update budgets when date changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (user) {
      setLoading(true);
      getTasks();
      getProjects();
    }
  }, [currentDate, user]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Update timeslots when tasks are updated
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    // if there are tasks
    if (tasks?.length) {
      const newTimeslots = [];
      const newAvailableTasks = [];

      // set the events for the calendar according to the breakdowns of the budgets
      tasks.forEach((task) => {
        const memberId =
          typeof task.memberId === "string"
            ? task.memberId
            : task.memberId?._id || null;

        const taskData = {
          id: task._id,
          title: task.title,
          memberId: memberId,
          role: task.role,
          code: task.projectId?.code ? task.projectId.code : "",
          project: task.projectId?.name || "Open Capacity",
          projectId: task.projectId?._id || "0",
          resourceId: task.projectId?._id
            ? `${memberId}-${task.projectId._id}`
            : `${memberId}-0`,
          status: task.status,
        };

        if (task.week && task.date && memberId) {
          // goes on the calendar
          newTimeslots.push({
            ...taskData,
            start: task.week
              ? getStandardizedDateTime(task.week)
              : getStandardizedDateTime(task.date),
            end: getStandardizedDateTime(task.date),
          });
        } else if (
          task.hoursToComplete > 0 &&
          isInCurrentTimeline(task.weekNumber)
        ) {
          // goes in the sidebar
          newAvailableTasks.push({
            ...taskData,
            hours: task.hoursToComplete,
          });
        }
      });

      setTimeslots(newTimeslots);
      setAvailableTasks(newAvailableTasks);
    } else if (timeslots.length) {
      // there are no budgets, but there are timeslots. so clear out the old timeslots
      setTimeslots([]);
      setAvailableTasks([]);
    }
  }, [tasks]); //eslint-disable-line

  useEffect(() => {
    if (availableTasks.length > 0) {
      const newSelectProjects = [];

      availableTasks.forEach((availTask) => {
        let isNew = true;
        newSelectProjects.forEach((project) => {
          if (project.value === availTask.projectId) {
            isNew = false;
          }
        });

        if (isNew)
          newSelectProjects.push({
            value: availTask.projectId,
            label: availTask.project,
          });
      });

      setProjectFilters([
        ...newSelectProjects,
        { value: "reset", label: "View all projects" },
      ]);
      setSelectProjects(newSelectProjects);
    }
  }, [availableTasks]);

  /*
  |--------------------------------------------------------------------------
  | Sorts projects into members
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members.length && availableProjects.length) {
      const newMembers = members.map((member) => {
        const theirProjects = [];

        availableProjects.forEach((project) => {
          project.teamMembers.forEach((projectMember) => {
            if (projectMember.teamMember._id === member._id) {
              theirProjects.push(project);
            }
          });
        });

        return { ...member, projects: theirProjects };
      });

      setMembers(newMembers);
    }
  }, [availableProjects]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Formats the resources used for the calendar
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    // check that the availableProjects have been set, so we know the members will have the "projects" prop on them
    if (members.length && availableProjects.length) {
      const newResources = [];

      members.forEach((member) => {
        const { capacityLow, capacityHigh } = member;

        // capacity for a single day
        const dailyCapacityLow = capacityLow ? capacityLow / 5 : null;
        const dailyCapacityHigh = capacityHigh ? capacityHigh / 5 : null;

        const resourceData = {
          title: member.handle,
          team: member.role.team.name,
          member: member,

          businessHours:
            capacityLow || capacityHigh
              ? {
                  startTime: "00:00",
                  endTime: `${`${dailyCapacityLow ||
                    dailyCapacityHigh}`.padStart(2, "0")}:00`,
                }
              : false,
        };

        // Everyone gets an 'Open Capacity' resource no matter what
        newResources.push({
          ...resourceData,
          id: `${member._id}-0`,
          project: "Open Capacity",
        });

        // Adds each project for the user to a resource
        if (member.projects?.length) {
          member.projects.forEach((project) => {
            const projectName = project.code
              ? `${project.code} - ${project.name}`
              : project.name;

            newResources.push({
              ...resourceData,
              id: `${member._id}-${project._id}`,
              project: projectName,
              projectDueDate: project.endDate,
              projectId: project._id,
            });
          });
        }

        // Only show the add project resources if we are not in the project view
        if (!isProjectView) {
          // If they have the select flag, we add the select
          // otherwise, we give them the add button
          if (member.hasSelect) {
            newResources.push({
              ...resourceData,
              id: `${member._id}-SELECT`,
              project: "SELECT",
            });
          } else {
            newResources.push({
              ...resourceData,
              id: `${member._id}-ADD`,
              project: "Add Project",
            });
          }
        }
      });

      setResources(newResources);
    }
  }, [members, isProjectView]); //eslint-disable-line

  // figure out the open capacity
  useEffect(() => {
    if (timeslots.length) {
      // get start and end of the week
      const timelineStart = getStartOfTimeline(new Date(currentDate));
      const timelineEnd = getEndOfTimeline(new Date(currentDate));

      // get the timeslots that are only for this week
      // (start is after start of week AND before end of week)
      const thisWeekTimeslots = timeslots.filter(
        (timeslot) =>
          timeslot.start > timelineStart && timeslot.start < timelineEnd,
      );

      // group the timeslots by member
      const timeslotsByMember = groupBy(thisWeekTimeslots, "memberId");

      // loop over all the members
      members.forEach((member) => {
        // if this member isn't in the grouped timeslots (since they don't have any timeslots)
        if (
          !Object.prototype.hasOwnProperty.call(timeslotsByMember, member._id)
        ) {
          // add them on, which is an empty array of timeslots
          timeslotsByMember[member._id] = [];
        }
      });

      const newEmptyTimeslots = [];

      // loop over all the grouped timeslots
      Object.entries(timeslotsByMember).forEach(
        ([memberId, memberTimeslots]) => {
          const thisMember =
            members.find((member) => member._id === memberId) || {};

          const { capacityLow, capacityHigh } = thisMember;

          // when the day ends for this user
          const dayEnd = capacityLow
            ? capacityLow / 5
            : capacityHigh
            ? capacityHigh / 5
            : 12;

          // start the day at midnight
          const mondayStart = set(new Date(timelineStart), {
            hours: 0,
          });
          // end the day according to the user's capacity
          const mondayEnd = set(mondayStart, {
            hours: dayEnd,
          });

          // setup fake timeslots in between each day of the week, so the open capacity doesn't stretch into those restricted areas
          const outsideBusinessHours = Array.from({ length: 5 }).map(
            (dayOfWeek, dayIndex) => ({
              // start at 5:30pm
              start: addDays(mondayEnd, dayIndex),
              // end at 8:30am the following day
              end: addDays(mondayStart, dayIndex + 1),
            }),
          );

          // add the outside biz hours timeslots to the member's actual timeslots
          const theseTimeslots = [...outsideBusinessHours, ...memberTimeslots];

          // sort the timeslots by start date
          theseTimeslots.sort((a, b) =>
            a.start < b.start ? -1 : a.start > b.start ? 1 : 0,
          );

          const openCapacityProps = {
            title: "Open Capacity",
            memberId: memberId,
            code: "",
            project: "Open Capacity",
            projectId: "0",
            resourceId: `${memberId}-0`,
            status: "Disabled",
            editable: false, // so it can't be dragged
          };

          // the comparison date will start at the beginning of the week
          let comparisonDate = mondayStart;

          theseTimeslots.forEach((memberTimeslot, index) => {
            // see if theres space in between the comparison date and the start of this task
            const diff = differenceInMilliseconds(
              new Date(memberTimeslot.start),
              new Date(comparisonDate),
            );

            // if theres space
            if (diff > 0) {
              // add it to open capacity
              newEmptyTimeslots.push({
                ...openCapacityProps,
                id: `${memberId}-${index}-open`,
                start: comparisonDate,
                end: memberTimeslot.start,
              });
            }

            // set the comparison date to this timeslot's end date
            comparisonDate = memberTimeslot.end;
          });

          // difference between the end of the week and the end date of the user's last task
          const endDiff = differenceInMilliseconds(
            new Date(timelineEnd),
            new Date(comparisonDate),
          );

          if (endDiff > 0) {
            newEmptyTimeslots.push({
              ...openCapacityProps,
              id: `${memberId}-end-open`,
              start: comparisonDate,
              end: timelineEnd,
            });
          }
        },
      );

      setOpenCapacitySlots(newEmptyTimeslots);
      setLoading(false);
    }
  }, [timeslots, currentView]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get all the members that belong to the user's team
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      // filter out the archived members
      const filteredMembers = membersFromApi.filter(
        (member) => !member.isArchived,
      );

      setMembers(filteredMembers);
      setAllMembers(filteredMembers);

      const memberOptions = filteredMembers.map((member) => ({
        value: member._id,
        label: member.name,
      }));

      setMemberFilters([
        ...memberOptions,
        { value: "reset", label: "View all members" },
      ]);
    } catch (err) {
      console.error("getMembers failed");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the tasks
  |--------------------------------------------------------------------------
  */
  const getTasks = async () => {
    const newTasks = await getAllTasksFromApi({
      start: getStartOfTimeline(new Date(currentDate)),
      end: getEndOfTimeline(new Date(currentDate)),
      projects: true,
      hideArchived: true,
    });

    setTasks(newTasks);
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the projects from the current start date to the current end date
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    const newProjects = await getAllProjectsFromApi({
      start: getStartOfTimeline(new Date(currentDate)),
      end: getEndOfTimeline(new Date(currentDate)),
    });

    setAvailableProjects(newProjects);
  };

  /*
  |--------------------------------------------------------------------------
  | Checks if the task matches our week number
  | Ex: 2021w20
  |--------------------------------------------------------------------------
  */
  const isInCurrentTimeline = (weekString) => {
    if (!weekString || weekString === "") return false;

    const weekArray = weekString.split("w");
    const dateToCompare = new Date(currentDate);

    switch (currentView) {
      case "month": {
        if (parseInt(weekArray[0]) === dateToCompare.getFullYear()) {
          return true;
        } else {
          return false;
        }
      }
      case "week": {
        const startOfMonthNumber = getWeek(startOfMonth(dateToCompare));
        const endOfMonthNumber = getWeek(endOfMonth(dateToCompare));

        if (
          startOfMonthNumber <= weekArray[1] &&
          weekArray[1] <= endOfMonthNumber
        ) {
          return true;
        } else {
          return false;
        }
      }
      default: {
        if (
          parseInt(weekArray[0]) === dateToCompare.getFullYear() &&
          parseInt(weekArray[1]) === getWeek(dateToCompare)
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const getStartOfTimeline = (date) => {
    let realStart = "";

    switch (currentView) {
      case "month":
        realStart = startOfYear(date);
        break;
      case "week":
        realStart = startOfMonth(date);
        break;
      default:
        realStart = startOfWeek(date);
        break;
    }

    return realStart;
  };

  const getEndOfTimeline = (date) => {
    let realEnd = "";

    switch (currentView) {
      case "month":
        realEnd = endOfYear(date);
        break;
      case "week":
        realEnd = endOfMonth(date);
        break;
      default:
        realEnd = endOfWeek(date);
        break;
    }

    return realEnd;
  };

  /*
  |--------------------------------------------------------------------------
  | Updates the schedule in state and updates the task in the backend
  |--------------------------------------------------------------------------
  */
  const updateSchedule = async (taskId, owner, startTime, endTime, newTask) => {
    const isRemoving = !startTime && !endTime;

    const taskParams = {
      memberId: owner,
      week: isRemoving ? null : getDateTimeString(startTime, false),
      date: isRemoving ? null : getDateTimeString(endTime, false),
    };

    if (startTime) {
      const year = getYear(startTime);
      const week = getWeek(startTime);
      taskParams.weekNumber = `${year}w${week}`;
    }

    const taskFromApi = await updateTaskOnApi(taskId, taskParams);

    const updatedTask = taskFromApi.data.data.result;

    let tempAvailableTasks = [...availableTasks];
    let tempTimeslots = [...timeslots];

    const memberId =
      typeof updatedTask.memberId === "string"
        ? updatedTask.memberId
        : updatedTask.memberId?._id || null;

    const taskData = {
      id: updatedTask._id,
      title: updatedTask.title,
      memberId: memberId,
      role: updatedTask.role,
      code: updatedTask.projectId?.code ? updatedTask.projectId.code : "",
      project: updatedTask.projectId?.name || "Open Capacity",
      projectId: updatedTask.projectId?._id || "0",
      resourceId: updatedTask.projectId?._id
        ? `${updatedTask.memberId}-${updatedTask.projectId._id}`
        : `${updatedTask.memberId}-0`,
      status: updatedTask.status,
    };

    if (updatedTask.week && updatedTask.date) {
      taskData.start = updatedTask.week
        ? getStandardizedDateTime(updatedTask.week)
        : getStandardizedDateTime(updatedTask.date);
      taskData.end = getStandardizedDateTime(updatedTask.date);
    }

    // If you're dropping a new task from available tasks, push it to the timeslots array and remove the task from the list of Available Tasks
    if (newTask) {
      tempAvailableTasks = tempAvailableTasks.filter((task) => {
        return task.id !== taskData.id;
      });

      tempTimeslots.push(taskData);
      setAvailableTasks(tempAvailableTasks);
      // If you're removing the task from the scheduler, put it back in the available tasks
    } else if (isRemoving) {
      // Adds timeslot to available
      tempAvailableTasks.push({
        ...taskData,
        hours: updatedTask.hoursToComplete,
      });

      // Removes from regular timeslots
      tempTimeslots = tempTimeslots.filter((task) => {
        return task.id !== taskData.id;
      });

      setAvailableTasks(tempAvailableTasks);
    }
    // Else replace the task with the same id with the updated information
    else {
      tempTimeslots = tempTimeslots.map((timeslot) => {
        if (timeslot.id === taskData.id) {
          return taskData;
        } else {
          return timeslot;
        }
      });
    }

    setTimeslots(tempTimeslots);
  };

  /*
  |--------------------------------------------------------------------------
  | When an event is dropped onto the calendar
  |--------------------------------------------------------------------------
  */
  const handleDrop = async ({ draggedEl, date, resource, jsEvent }) => {
    // make sure its one of the data-event elements
    const isDraggableEvent = draggedEl.hasAttribute("data-event");

    if (isDraggableEvent) {
      // get the event data from the element
      const eventString = draggedEl.getAttribute("data-event");
      const event = JSON.parse(eventString);

      const dragProjectId = event.projectId;
      const spotProjectId = resource.extendedProps.projectId;

      const dragRole = event.role;
      const spotRole = resource.extendedProps.member.role;

      const dragMember = event.member;
      const spotMember = resource.extendedProps.member._id;

      // can only drop event onto the associated project
      if (dragProjectId !== spotProjectId) {
        createErrorMessage("Wrong Project", jsEvent);
        return null;
      }
      // can only drop event onto a member with the same role (if one is specified)
      if (dragRole && dragRole !== spotRole) {
        createErrorMessage("Wrong Role", jsEvent);
        return null;
      }
      // can only drop event onto the appropriate member (if one is specified and no role is specified)
      if (!dragRole && dragMember && dragMember !== spotMember) {
        createErrorMessage("Wrong Team Member", jsEvent);
        return null;
      }

      const taskId = event.id;
      const owner = resource._resource.extendedProps.member._id;
      const duration = event.hours;
      const minutes = duration * 60;
      const endTime = addMinutes(date, minutes);

      updateSchedule(taskId, owner, date, endTime, true);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Create's an error message at the mouse location
  |--------------------------------------------------------------------------
  */
  const createErrorMessage = (msg, event) => {
    setErrorLocation({ x: event.clientX, y: event.clientY });
    setErrorMessage(msg);

    setTimeout(() => {
      setErrorLocation(null);
      setErrorMessage(null);
    }, 1000);
  };

  /*
  |--------------------------------------------------------------------------
  | Removes the task from the schedule
  |--------------------------------------------------------------------------
  */
  const handleEventRemove = (event) => {
    const taskId = event._def.publicId;

    // keep the member on the task
    const memberId = event.extendedProps.memberId;

    // remove the start / end times
    const taskStart = null;
    const taskEnd = null;

    // Updates the schedule
    updateSchedule(taskId, memberId, taskStart, taskEnd);
  };

  /*
  |--------------------------------------------------------------------------
  | When the date on the calendar is changed
  | (week or day is adjusted using the API methods)
  |--------------------------------------------------------------------------
  */
  const handleDatesSet = ({ start }) => {
    // get the start of timeline for the current date
    const startOfCurrentDate = getStartOfTimeline(new Date(currentDate));

    // compare it to the new start date that was set
    const isEqualToCurrentDate = isEqual(
      new Date(startOfCurrentDate),
      new Date(start),
    );

    // only update currentDate if the new date has a different timeline
    if (!isEqualToCurrentDate) {
      setCurrentDate(start);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | When existing event is dropped onto a new spot, check for the project id
  |--------------------------------------------------------------------------
  */
  const handleEventDrop = ({ oldResource, newResource, revert, jsEvent }) => {
    // If the task is dragged onto a new project
    if (newResource) {
      const dragProjectId = oldResource.extendedProps.projectId;
      const spotProjectId = newResource.extendedProps.projectId;

      // Denote that we should not follow through with the new position if it was dragged onto a different project
      if (dragProjectId !== spotProjectId) {
        createErrorMessage("Wrong Project", jsEvent);
        revert();
      }
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Hides the sidebar when you press CMD+K
  |--------------------------------------------------------------------------
  */
  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "k") {
      setBacklogOpen((backlogOpen) => !backlogOpen);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | When an existing event is dragged on the calendar, update the schedule
  |--------------------------------------------------------------------------
  */
  const handleEventChange = ({ event, oldEvent }) => {
    const newStart = new Date(event.start);
    const newEnd = new Date(event.end);
    const taskId = event._def.publicId;

    // Get the projectId from the resourceIds
    const oldTask = oldEvent._def.resourceIds[0].split("-");
    const newTask = event._def.resourceIds[0].split("-");
    const oldProject = oldTask[1];
    const newProject = newTask[1];
    const owner = newTask[0];

    // Only update the schedule if the project is the same (and its not an open capacity event)
    if (oldProject === newProject && oldProject !== "0") {
      updateSchedule(taskId, owner, newStart, newEnd);
    }

    oldEvent.remove();
  };

  // When clicking Add Project, switches button to a select dropdown
  const addProjectSelect = (memberId) => {
    const updatedMembers = members.map((member) => {
      let hasSelect = member.hasSelect || false;

      if (member._id === memberId) {
        // Selected user gets a flag for resource gen function
        hasSelect = true;
      }

      return {
        ...member,
        hasSelect,
      };
    });

    setMembers(updatedMembers);
  };

  // When selecting a project in the dropdown, adds that project to the user
  const onProjectSelectChange = (memberId, projectObj) => {
    const updatedMembers = members.map((member) => {
      const newProjects = [...member.projects];
      let hasSelect = member.hasSelect || false;

      if (member._id === memberId) {
        // Removes the select flag from that user
        hasSelect = false;
        // Adds the new project to their list
        newProjects.push({
          name: projectObj.label,
          _id: projectObj.value,
        });
      }

      return {
        ...member,
        projects: newProjects,
        hasSelect,
      };
    });

    setMembers(updatedMembers);
  };

  return (
    <Container>
      {errorLocation ? (
        <ErrorMsg location={errorLocation}>{errorMessage}</ErrorMsg>
      ) : null}

      <CalCol expanded={!backlogOpen}>
        <Header>
          <HeaderInner>
            <Heading>
              Production {currentView === "day" ? "Capacity" : "Schedule"}
            </Heading>

            <HeaderLinks>
              <DefaultLink onClick={() => setIsProjectView(!isProjectView)}>
                {isProjectView ? "View by User" : "View by Project"}
              </DefaultLink>

              {/* // ? making capacity view the only option for now */}
              {/* <DefaultLink onClick={() => setIsCapacityView(!isCapacityView)}>
                {isCapacityView ? "View by Calendar" : "View by Capacity"}
              </DefaultLink> */}
            </HeaderLinks>

            <TimelineButtons
              currentView={currentView}
              changeView={setCurrentView}
            />
          </HeaderInner>
        </Header>

        <CalContainer>
          <Calendar
            view={currentView}
            // resources={buildResources()}
            resources={resources}
            events={[...timeslots, ...openCapacitySlots]}
            calendarRef={calendarRef}
            handleDrop={handleDrop}
            isProjectView={isProjectView}
            isCapacityView={isCapacityView}
            datesSet={handleDatesSet}
            eventChange={handleEventChange}
            eventDrop={handleEventDrop}
            dragging={dragging}
            eventDragStart={() => {
              setDragging(true);
            }}
            eventDragStop={() => {
              setDragging(false);
            }}
            addProject={addProjectSelect}
            selectProjects={selectProjects}
            onProjectSelectChange={onProjectSelectChange}
            handleEventRemove={handleEventRemove}
            minTime={dayStart}
            maxTime={dayEnd}
          />
        </CalContainer>
      </CalCol>

      <TasksDrawer id="budgets-drag" expanded={backlogOpen}>
        <DrawerActions>
          <DrawerToggle
            onClick={() => {
              setBacklogOpen(!backlogOpen);
            }}
          >
            {backlogOpen ? <CaretRight /> : <CaretLeft />}
          </DrawerToggle>

          <DrawerToggle
            onClick={() => {
              setSidebarView("tasks");
            }}
            isActive={sidebarView === "tasks"}
          >
            <TasksIcon />
          </DrawerToggle>

          <DrawerToggle
            onClick={() => {
              setSidebarView("settings");
            }}
            isActive={sidebarView === "settings"}
          >
            <SettingsIcon color="slate" />
          </DrawerToggle>
        </DrawerActions>

        <TasksInner>
          {projectFilters &&
          projectFilters.length &&
          memberFilters &&
          memberFilters.length ? (
            <TasksFilter>
              <Filter
                placeholder="Select Filter"
                value={
                  filterType ? { label: filterType, value: filterType } : null
                }
                onChange={(e) => {
                  if (filterType === "Member") {
                    setSelectedProject(null);
                  } else if (filterType === "Project") {
                    setSelectedMember(null);
                  }
                  setFilterType(e.value);
                }}
                options={[
                  { label: "Project", value: "Project" },
                  { label: "Member", value: "Member" },
                ]}
              />
            </TasksFilter>
          ) : null}
          {filterType === "Member" && memberFilters && memberFilters.length ? (
            <TasksFilter>
              <Filter
                placeholder="Select Member"
                value={selectedMember}
                onChange={(e) => {
                  setSelectedMember(e);
                }}
                options={memberFilters}
              />
            </TasksFilter>
          ) : null}
          {filterType === "Project" &&
          projectFilters &&
          projectFilters.length ? (
            <TasksFilter>
              <Filter
                placeholder="Select Project"
                value={selectedProject}
                onChange={(e) => {
                  setSelectedProject(e);
                }}
                options={projectFilters}
              />
            </TasksFilter>
          ) : null}

          {sidebarView === "tasks" ? (
            <>
              <TasksHeader>
                <Heading>Available Tasks</Heading>
              </TasksHeader>
              <TasksList>
                {availableTasks ? (
                  <Tasks
                    filterType={filterType}
                    selectedMember={selectedMember}
                    selectedProject={selectedProject}
                    allMembers={allMembers}
                    tasks={availableTasks}
                    groups={[{ id: user._id, title: user.handle }]}
                  />
                ) : null}
              </TasksList>
            </>
          ) : sidebarView === "settings" ? (
            <div style={{ padding: "0 15px" }}>
              <TasksHeader>
                <Heading>Settings</Heading>
              </TasksHeader>
              <TasksList>
                {!isCapacityView ? (
                  <>
                    <Field>
                      <Label htmlFor="day-start">Day Start</Label>
                      <Input
                        as="select"
                        name="day-start"
                        id="day-start"
                        value={dayStart}
                        onChange={(e) => {
                          setDayStart(Number(e.target.value));
                        }}
                      >
                        <option value="6">6am</option>
                        <option value="7">7am</option>
                        <option value="8">8am</option>
                      </Input>
                    </Field>
                    <Field>
                      <Label htmlFor="day-end">Day End</Label>
                      <Input
                        as="select"
                        name="day-end"
                        id="day-end"
                        value={dayEnd}
                        onChange={(e) => {
                          setDayEnd(Number(e.target.value));
                        }}
                      >
                        <option value="18">6pm</option>
                        <option value="19">7pm</option>
                        <option value="20">8pm</option>
                        <option value="21">9pm</option>
                        <option value="22">10pm</option>
                        <option value="23">11pm</option>
                      </Input>
                    </Field>
                  </>
                ) : null}
              </TasksList>
            </div>
          ) : null}
        </TasksInner>
      </TasksDrawer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const ErrorMsg = styled.p`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  color: ${(props) => props.theme.colors.red};
  font-size: 16px;

  ${(props) =>
    props.location
      ? css`
          top: ${props.location.y}px;
          left: ${props.location.x}px;
          animation: popout 1s forwards;
        `
      : css``}

  @keyframes popout {
    from {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      transform: translateY(-60px);
      opacity: 0;
    }
  }
`;

const CalCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  background-color: white;
  padding-top: 40px;
`;

const CalContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;

  table {
    table-layout: fixed;
  }

  .fc-toolbar-chunk {
    display: flex;
  }
`;

const TasksDrawer = styled.div`
  position: relative;
  display: flex;

  flex-basis: ${(props) => (props.expanded ? `250px` : `0%`)};
  max-width: ${(props) => (props.expanded ? `250px` : `0%`)};
  flex-shrink: 0;

  box-shadow: 0px 0px 40px ${rgba("#B9BCC0", 0.3)};
  z-index: 1;
`;

const DrawerActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 30px;
  background-color: ${(props) => props.theme.colors.pureWhite};
`;

const DrawerToggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors.oldLightGray};
  border-right-width: ${(props) => (props.isActive ? 0 : undefined)};
  padding: 3px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: none;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const TasksInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0px 40px 0;
  overflow: hidden;
`;

const TasksList = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Heading = styled.h2`
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
  margin-bottom: 35px;
  padding-right: 35px;

  ${Heading} {
    margin-bottom: 0;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: baseline;
  flex-grow: 1;
`;

const HeaderLinks = styled.div`
  margin-left: 38px;

  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const TasksHeader = styled.div`
  margin-bottom: 40px;
  padding: 0 15px;
`;

const TasksFilter = styled.div`
  margin-bottom: 10px;
  padding: 0px 15px;
`;

const Filter = styled(SelectDropdown)`
  * {
    cursor: pointer !important;
  }
  font-size: 14px;
`;

const TimelineButtons = styled(TimelineButtonGroup)`
  margin-left: auto;
`;

export default Scheduler;
