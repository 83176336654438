import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { getClientHealthFromApi } from "../../utils/api";

import { ClientLink } from "../../utils/helpers";

import ProgressBar from "../dashboard/components/ProgressBar";
import { TableRow, TableCell } from "../../components/Table";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ClientHealth = () => {
  const [highRisk, setHighRisk] = useState(null);
  const [mediumRisk, setMediumRisk] = useState(null); //eslint-disable-line
  const [lowRisk, setLowRisk] = useState(null);
  const [noRisk, setNoRisk] = useState(null);
  const [openedSection, setOpenedSection] = useState("");

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    try {
      const results = await getClientHealthFromApi();

      setHighRisk(results.highRisk);
      setMediumRisk(results.mediumRisk);
      setLowRisk(results.lowRisk);
      setNoRisk(results.noRisk);
    } catch (error) {
      console.error("error getting clients risk", error);
    }
  };

  const toggleSection = (newSection) => {
    if (openedSection === newSection) {
      setOpenedSection("");
    } else {
      setOpenedSection(newSection);
    }
  };

  return (
    <ClientsContainer>
      <ClientsHeader>
        <h1>Client Health Report</h1>
      </ClientsHeader>

      <Section
        title="High Risk"
        color="red"
        desc="Clients with a project that is overdue or over budget."
        noneMsg="No high risk clients."
        handleOpen={() => toggleSection("high")}
        isOpen={openedSection === "high"}
        clients={highRisk}
      />

      {/* // ? projects don't have chunks anymore */}
      {/* <Section
        title="Medium Risk"
        color="yellow"
        desc="Clients with a project that has an overdue chunk."
        noneMsg="No medium risk clients."
        handleOpen={() => toggleSection("medium")}
        isOpen={openedSection === "medium"}
        clients={mediumRisk}
      /> */}

      <Section
        title="Low Risk"
        color="blue"
        desc="Clients with a project that has an overdue task."
        noneMsg="No low risk clients."
        handleOpen={() => toggleSection("low")}
        isOpen={openedSection === "low"}
        clients={lowRisk}
      />

      <Section
        title="No Risk"
        color="green"
        desc="Clients with a nothing overdue or over budget."
        noneMsg="No no-risk clients."
        handleOpen={() => toggleSection("no")}
        isOpen={openedSection === "no"}
        clients={noRisk}
      />
    </ClientsContainer>
  );
};

const Section = ({
  title,
  color,
  desc,
  isOpen,
  handleOpen,
  noneMsg,
  clients,
}) => {
  return (
    <SectionContainer>
      <SectionHeader onClick={handleOpen}>
        <SectionHeaderTitle>{title}</SectionHeaderTitle>{" "}
        <SectionHeaderNum color={color}>
          {clients ? clients.length : "..."} Client
          {clients?.length === 1 ? "" : "s"}
        </SectionHeaderNum>
        <SectionHeaderDesc>{desc}</SectionHeaderDesc>
      </SectionHeader>
      <SectionContent
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { height: "auto" },
          closed: { height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        <SectionInner>
          {clients?.length === 0 ? (
            <NoneMsg>{noneMsg}</NoneMsg>
          ) : (
            <ClientsList>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Revenue Booked</th>
                  <th>{/* placeholder for revenue progress bar */}</th>
                  <th>Revenue Billed</th>
                  {/* <th>Invoiced</th> */}
                  <th>Active Projects</th>
                  <th>Overdue Projects</th>
                  <th>Over Budget Projects</th>
                </tr>
              </thead>
              <tbody>
                {clients
                  ? clients.map((client, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <ClientName>
                            <ClientLink name={client.name} id={client._id} />
                          </ClientName>
                        </TableCell>
                        <TableCell align="left">
                          {currencyFormatter.format(client.revenueBooked) ||
                            "-"}
                        </TableCell>
                        <TableCell align="left">
                          <Progress
                            actual={client.revenueBilled}
                            budgeted={client.revenueBooked}
                            pace={null}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {currencyFormatter.format(client.revenueBilled) ||
                            "-"}
                        </TableCell>
                        <TableCell align="left">
                          {client.activeProjects}
                        </TableCell>
                        <TableCell align="left">
                          {client.overdueProjects}
                        </TableCell>
                        <TableCell align="left">
                          {client.overBudgetProjects}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </tbody>
            </ClientsList>
          )}
        </SectionInner>
      </SectionContent>
    </SectionContainer>
  );
};

const ClientsContainer = styled.div`
  padding-bottom: 100px;
`;

const ClientsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const SectionContainer = styled.div`
  padding: 40px 0;
  margin-left: 90px;
  margin-right: 90px;
  border-top: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const SectionHeader = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const SectionHeaderTitle = styled.h3`
  margin-bottom: 0;
  margin-right: 40px;
`;

const SectionHeaderNum = styled.p`
  margin-bottom: 0;
  margin-right: 40px;
  font-weight: bold;
  color: ${(props) =>
    props.color === "red"
      ? props.theme.colors.red
      : props.color === "yellow"
      ? props.theme.colors.yellow
      : props.color === "blue"
      ? props.theme.colors.blue
      : props.theme.colors.green};
`;

const SectionHeaderDesc = styled.p`
  margin-bottom: 0;
  font-size: 12px;
`;

const SectionContent = styled(motion.div)`
  height: 0;
  overflow: hidden;
`;

const SectionInner = styled.div`
  padding: 50px 0;
`;

const ClientsList = styled.table`
  width: 100%;

  th {
    text-align: left;
    font-size: 12px;
  }
`;

const ClientName = styled.h5`
  font-size: 14px;
  font-weight: normal;
  margin: auto 0;
`;

const Progress = styled(ProgressBar)`
  width: 250px;
`;

const NoneMsg = styled.p`
  padding: 0px 0;
`;

export default ClientHealth;
