import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { startCase } from "lodash";

import { dealStages } from "../../../utils/constants";
import { findItemProperty } from "../../../utils/helpers";

import { Input, MoneyInput, Label } from "../../../components/newForm";
import SelectDropdown from "../../../components/newSelectDropdown";
import Button from "../../../components/newButtons/Button";
import Switch from "../../../components/newSwitch";

import CreateSidebar from "./createSidebar";

const ongoingOptions = [
  // value = number of days
  { label: "Monthly", value: 30 },
  { label: "Yearly", value: 365 },
];

const ModifyDeal = ({
  isOpen,
  close,
  onSubmit,
  currentDeal,
  availOwners,
  availContacts,
  availCompanies,
}) => {
  const [owner, setOwner] = useState(null);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [estimatedBudget, setEstimatedBudget] = useState("");
  const [company, setCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [estimatedCloseDate, setEstimatedCloseDate] = useState("");
  const [doesRenew, setDoesRenew] = useState(false);
  const [renewalPeriod, setRenewalPeriod] = useState("monthly");

  // setup data for an existing deal
  useEffect(() => {
    if (currentDeal) {
      setOwner(currentDeal.owner?._id || null);
      setStatus(currentDeal.status || null);
      setName(currentDeal.name || "");
      setDescription(currentDeal.description || "");
      setEstimatedBudget(currentDeal.estimatedBudget?.toString() || "");
      setCompany(currentDeal.company?._id || null);
      setContacts(
        currentDeal.contacts?.map((contact) => ({
          label: `${contact.firstName} ${contact.lastName}`,
          value: contact._id,
        })) || [],
      );
      setEstimatedCloseDate(
        currentDeal.estimatedCloseDate
          ? currentDeal.estimatedCloseDate.substring(0, 10)
          : "",
      );

      setDoesRenew(currentDeal.doesRenew || false);
      if (currentDeal.renewalPeriod) {
        setRenewalPeriod(currentDeal.renewalPeriod);
      }
    }
  }, [currentDeal]);

  // clear state when closed
  useEffect(() => {
    if (!isOpen) {
      setOwner(null);
      setStatus(null);
      setName("");
      setDescription("");
      setEstimatedBudget("");
      setCompany(null);
      setContacts([]);
      setEstimatedCloseDate("");
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // check that the required fields exist
    if (name && company) {
      const contactIds = contacts?.map((contact) => contact.value) || [];

      const dealData = {
        owner,
        name,
        description,
        estimatedBudget,
        company,
        contacts: contactIds,
        estimatedCloseDate: estimatedCloseDate
          ? new Date(estimatedCloseDate)
          : null,
        doesRenew,
        renewalPeriod,
      };

      // updating an existing deal
      if (currentDeal) {
        dealData.id = currentDeal._id;

        // only set the status if its an existing deal
        if (status) {
          dealData.status = status;
        }
      }
      // creating a new deal
      else {
        if (owner) {
          // add the owner to the deal team
          dealData.team = [owner];
        }
      }

      // if theres only 1 contact, make them the primary contact
      if (contactIds?.length === 1) {
        dealData.primaryContact = contactIds[0];
      }

      onSubmit(dealData);
    }
  };

  return (
    <CreateSidebar
      title={currentDeal ? "Edit Deal" : "New Deal"}
      isOpen={isOpen}
      close={close}
      onSubmit={handleSubmit}
    >
      <Field>
        <SelectDropdown
          placeholder="Owner"
          value={
            owner && availOwners
              ? {
                  label: findItemProperty("name", availOwners, "_id", owner),
                  value: owner,
                }
              : null
          }
          onChange={(e) => setOwner(e.value)}
          options={
            availOwners
              ? availOwners.map((owner) => ({
                  label: owner.name,
                  value: owner._id,
                }))
              : []
          }
        />
      </Field>

      {currentDeal ? (
        <Field>
          <SelectDropdown
            placeholder="Status"
            value={
              status
                ? {
                    label: status,
                    value: status,
                  }
                : null
            }
            onChange={(e) => setStatus(e.value)}
            options={
              dealStages
                ? dealStages.map((status) => ({
                    label: status,
                    value: status,
                  }))
                : []
            }
          />
        </Field>
      ) : null}

      <Field>
        <Input
          placeholder="Deal Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </Field>

      <Field>
        <Input
          placeholder="Description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Field>

      <Field>
        <MoneyInput
          placeholder="Estimated Budget"
          value={estimatedBudget}
          onChange={(e) =>
            setEstimatedBudget(e.target.rawValue.replace("$", ""))
          }
        />
      </Field>

      <Field>
        <Label>Ongoing</Label>
        <Ongoing>
          <span onClick={() => setDoesRenew(false)}>No</span>
          <Switch
            id="deal-ongoing"
            isActive={doesRenew}
            handleSwitch={() => setDoesRenew(!doesRenew)}
          />
          <span onClick={() => setDoesRenew(true)}>Yes</span>
        </Ongoing>
      </Field>

      {doesRenew ? (
        <Field>
          <SelectDropdown
            placeholder="Period"
            value={{
              label: startCase(renewalPeriod),
              value: renewalPeriod,
            }}
            onChange={(e) => setRenewalPeriod(e.value)}
            options={[
              {
                label: "Monthly",
                value: "monthly",
              },
              {
                label: "Yearly",
                value: "yearly",
              },
            ]}
          />
        </Field>
      ) : null}

      <Field>
        <SelectDropdown
          placeholder="Company"
          value={
            company && availCompanies
              ? {
                  label: findItemProperty(
                    "name",
                    availCompanies,
                    "_id",
                    company,
                  ),
                  value: company,
                }
              : null
          }
          onChange={(e) => setCompany(e.value)}
          options={
            availCompanies
              ? availCompanies.map((company) => ({
                  label: company.name,
                  value: company._id,
                }))
              : []
          }
        />
      </Field>

      {/* // ? only the contacts that are associated with the selected company */}
      <Field>
        <SelectDropdown
          isMulti
          placeholder="Contacts"
          value={contacts}
          onChange={(e) => setContacts(e)}
          isDisabled={!company}
          options={
            availContacts
              ? availContacts
                  .filter((contact) => contact.company._id === company)
                  .map((contact) => ({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact._id,
                  }))
              : []
          }
        />
      </Field>

      <Field>
        <Input
          placeholder="Expected Close Date"
          type="text"
          value={estimatedCloseDate}
          onChange={(e) => setEstimatedCloseDate(e.target.value)}
          onFocus={(e) => (e.currentTarget.type = "date")}
          onBlur={(e) => (e.currentTarget.type = "text")}
        />
      </Field>

      <Button disabled={!name || !company}>
        {currentDeal ? "Update" : "Create"}
      </Button>
    </CreateSidebar>
  );
};

const Field = styled.div`
  margin-bottom: 16px;
`;

const Ongoing = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 14px;
    cursor: pointer;
  }
`;

export default ModifyDeal;
