import styled, { css } from "styled-components";
import { darken } from "polished";

const chipThemes = {
  default: {
    color: "coolGray700",
    background: "coolGray200",
  },
  teal: {
    color: "teal600",
    background: "teal100",
  },
  blue: {
    color: "blue600",
    background: "blue100",
  },
  indigo: {
    color: "indigo600",
    background: "indigo100",
  },
  blueGray: {
    color: "gray900",
    background: "blueGray200",
  },
};

const ValueChip = styled.div`
  display: inline-block;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  text-decoration: none;

  padding: 4px 8px;

  border-radius: 4px;

  color: ${(props) => props.theme.colors[chipThemes[props.color].color]};

  background-color: ${(props) =>
    props.theme.colors[chipThemes[props.color].background]};

  transition: color 200ms, background-color 200ms;

  ${(props) =>
    props.hasHover
      ? css`
          &:hover,
          &:focus,
          &:focus-within {
            background-color: ${(props) =>
              darken(
                0.025,
                props.theme.colors[chipThemes[props.color].background],
              )};
          }
        `
      : ``}
`;

ValueChip.defaultProps = {
  color: "default",
};

export const NumberChip = styled(ValueChip)`
  padding: 1px 6px;
`;

export default ValueChip;
