import React from "react";
import { TableHead, TableHeader, TableRow } from "./components";
import SortIcon from "./icons/SortIcon";

/**
 * A reusable table head
 * @param {object[]} headers The header info in an array of objects
 * @param {component|function} [headers.component] Optional: a component to be used in the header cell.
 * @param {component} headers.name The title, to be used in the cell
 * @param {component} headers.accessor The property path to check against when sorting, in dot notation
 * @param {string} sortBy The string to sort by
 * @param {function} changeSort The function that changes the sorting when clicked
 * @param {object[]} entries The items displayed in the table. If a `component` is provided, these items will be passed into the component function as an argument.
 * @returns {component} A table head component
 */
const Head = ({ headers, sortBy, changeSort, entries }) => {
  return (
    <TableHead>
      <TableRow>
        {headers &&
          headers.map((item, index) =>
            item.component ? (
              <TableHeader
                key={index}
                onClick={item.accessor ? () => changeSort(item.accessor) : null}
              >
                {typeof item.component === "function"
                  ? item.component(entries)
                  : item.component}

                {item.accessor ? (
                  <SortIcon
                    isActive={sortBy.includes(item.accessor)}
                    isArrowUp={sortBy === `${item.accessor}-desc`}
                  />
                ) : null}
              </TableHeader>
            ) : (
              <TableHeader
                key={index}
                onClick={item.accessor ? () => changeSort(item.accessor) : null}
                title={item.title}
              >
                {item.name}

                {item.accessor ? (
                  <SortIcon
                    isActive={sortBy.includes(item.accessor)}
                    isArrowUp={sortBy === `${item.accessor}-desc`}
                  />
                ) : null}
              </TableHeader>
            ),
          )}
      </TableRow>
    </TableHead>
  );
};

export default Head;
