import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { convertStageName } from "../../../utils/constants";

const StagesWidget = ({ stages, ...rest }) => {
  const generateSubtext = (stage, stageIndex) => {
    // Gets the last stage (if available)
    const prevCompleted = stageIndex >= 0 ? stages[stageIndex - 1] : null;

    // If the stage is completed
    if (stage.completed && stage.completedDate) {
      // If we also have a previous stage completion date
      if (
        prevCompleted &&
        prevCompleted.completed &&
        prevCompleted.completedDate
      ) {
        // prev stage date - this stage date
        return `${format(
          new Date(prevCompleted.completedDate),
          "MMMM dd, yyyy",
        )} - ${format(new Date(stage.completedDate), "MMMM dd, yyyy")}`;
      } else {
        // this stage date
        return format(new Date(stage.completedDate), "MMMM dd, yyyy");
      }
    }

    // If the stage has a due date, show that
    if (stage.dueDate) {
      return format(new Date(stage.dueDate), "MMMM dd, yyyy");
    }

    // If the stage is active (and no due date), show In Progress
    if (isActive(stage, stageIndex)) {
      return `In Progress`;
    }

    // If nothing else, show that it's waiting on previous stage
    return prevCompleted
      ? `Pending ${convertStageName(prevCompleted.name)}`
      : `Pending`;
  };

  const isActive = (stage, stageIndex) => {
    // Gets previous stage if it's available
    const prevCompleted = stageIndex >= 0 ? stages[stageIndex - 1] : null;

    if (
      // If there is a last stage, and it is completed,
      // but current stage is not
      (prevCompleted && prevCompleted.completed && !stage.completed) ||
      // OR if there is no previous stage, and this one is not completed
      (!prevCompleted && !stage.completed)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container {...rest}>
      <Stages>
        {stages && stages.length
          ? stages.map((stage, index) => (
              <Stage
                title={convertStageName(stage.name)}
                subtext={generateSubtext(stage, index)}
                completed={stage.completed}
                active={isActive(stage, index)}
                key={index}
              />
            ))
          : null}
      </Stages>
    </Container>
  );
};

const Container = styled.div`
  /* background: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px; */
`;

const Stages = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* padding: 0 38px 23px 28px; */
`;

export default StagesWidget;

const Stage = ({ title, subtext, completed, active }) => {
  return (
    <StageWrapper>
      <StageStatus completed={completed} active={active} />
      <StageContainer active={active}>
        <StageName>{title}</StageName>
        <StageSubtext>{subtext}</StageSubtext>
      </StageContainer>
    </StageWrapper>
  );
};

const StageWrapper = styled.div`
  position: relative;
  padding-left: 36px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StageStatus = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;

  position: absolute;
  left: 0;
  top: 50%;

  transform: translateY(-50%);

  background: ${(props) =>
    props.completed
      ? props.theme.colors.indigo600
      : props.theme.colors.pureWhite};

  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.colors.indigo600
        : props.theme.colors.indigo200};
`;

const StageContainer = styled.div`
  min-height: 48px;
  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.colors.indigo600
        : props.theme.colors.indigo200};
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  padding: 8px;
`;

const StageName = styled.h4`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const StageSubtext = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;
`;
