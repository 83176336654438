import React from "react";
import styled from "styled-components";

const FailIcon = (props) => {
  return (
    <Svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.34473 2.34483L11.6551 11.6552"
        stroke="#DF5757"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6551 2.34483L2.34473 11.6552"
        stroke="#DF5757"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default FailIcon;
