import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { subDays } from "date-fns";

import {
  getMembersFromApi,
  getDayCompletionsFromApi,
  getLast30FromApi,
  getTodayCompletionsFromApi,
} from "../../../../utils/api";

import DayCompletionTable from "./DayCompletionTable";

const DayCompletion = () => {
  const [oldestDate, setOldestDate] = useState(subDays(new Date(), 30));
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups();
  }, [oldestDate]); //eslint-disable-line

  const getGroups = async () => {
    const tempGroups = await getMembersFromApi({ grouped: true });
    // All of the past day completions
    const dayCompletions = await getDayCompletionsFromApi({
      start: oldestDate,
      end: new Date(),
    });
    // The stats for the last 30 days
    const last30Data = await getLast30FromApi();
    // Today's completion data
    const todaysCompletionsData = await getTodayCompletionsFromApi();

    // Loops through each group
    const newGroups = tempGroups.map((group) => {
      //Loops through each groups members
      const newMembers = group.members.map((member) => {
        // Gets all the days for that member
        const days = dayCompletions.filter((day) => day.member === member._id);

        // Get that member's last 30 days
        const last30Member = last30Data.find(
          (current30Member) => current30Member._id === member._id,
        );

        let last30 = { pass: 0, fail: 0 };
        if (last30Member.last30) {
          last30 = last30Member.last30;
        }

        // Get that member's today completions
        const todayMember = todaysCompletionsData?.find(
          (currentToday) => currentToday.member === member._id,
        );

        // If today fails for whatever reason, we act as if they had no completions due today
        const todays = {
          member: member._id,
          date: new Date(),
          completions: [],
        };

        // Puts in today's day completions
        if (todayMember.completions) {
          todays.completions = todayMember.completions;
        }

        // Puts the days for that member on that member
        return {
          ...member,
          days: [todays, ...days],
          last30,
        };
      });

      //Puts those updated members on the group
      return {
        ...group,
        members: newMembers,
      };
    });

    setGroups(newGroups);
  };

  function handleTableScroll(e) {
    const mainEl = e.currentTarget;

    if (mainEl.offsetWidth + mainEl.scrollLeft >= mainEl.scrollWidth) {
      setOldestDate(subDays(oldestDate, 5));
    }
  }

  return (
    <Container>
      <Header>Finished My Day</Header>
      <DayCompletionTable
        data={groups}
        oldestDate={oldestDate}
        handleScroll={handleTableScroll}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border: 1px solid #b7bec6;
  border-radius: 4px;
  padding: 36px 40px 0;
`;

const Header = styled.h3`
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.theme.colors.oldBlack2};
  margin-bottom: 30px;
`;

export default DayCompletion;
