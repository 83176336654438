import React from "react";
import styled from "styled-components";

const CommentIcon = ({ completed }) => {
  return (
    <Svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      completed={completed}
    >
      <path
        d="M6 7H6.01M10 7H10.01M14 7H14.01M7 13H3C2.46957 13 1.96086 12.7893 1.58579 12.4142C1.21071 12.0391 1 11.5304 1 11V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V11C19 11.5304 18.7893 12.0391 18.4142 12.4142C18.0391 12.7893 17.5304 13 17 13H12L7 18V13Z"
        stroke="#9CA3AF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    stroke: ${(props) =>
      props.completed
        ? props.theme.colors.green400
        : props.theme.colors.coolGray400};
  }
`;

export default CommentIcon;
