import React from "react";
import styled from "styled-components";

const CaretLeft = () => {
  return (
    <Svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M7.80049 12.9003C7.8006 12.6616 7.70585 12.4326 7.53709 12.2637L2.17309 6.90029L7.53709 1.53689C7.88868 1.1853 7.88868 0.615273 7.53709 0.263688C7.18551 -0.0878961 6.61548 -0.0878961 6.26389 0.263688L0.263894 6.26369C0.094986 6.43249 8.53539e-05 6.66149 8.53539e-05 6.90029C8.53539e-05 7.13908 0.094986 7.36809 0.263894 7.53689L6.26389 13.5369C6.5213 13.7945 6.90855 13.8715 7.24496 13.7322C7.58138 13.5928 7.80066 13.2644 7.80049 12.9003Z"
        fill="#8698AD"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default CaretLeft;
