import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { MenuItem, MenuIcon, MenuText } from "./sidebarView";
import CaretDown from "../icons/CaretDown";

const Popout = ({
  isActive,
  isSidebarOpen,
  to,
  text,
  Icon,
  position,
  children,
}) => {
  return (
    <PopoutContainer position={position} isSidebarOpen={isSidebarOpen}>
      <PopoutItem isactive={isActive} usesArrow>
        <MenuIcon>{Icon ? <Icon /> : null}</MenuIcon>

        <MenuText as={to ? Link : undefined} to={to ? to : undefined}>
          {text} <Caret />
        </MenuText>

        <Caret />
      </PopoutItem>
      <PopoutMenu position={position} isSidebarOpen={isSidebarOpen}>
        <PopoutTitle>{text}</PopoutTitle>
        {children}
      </PopoutMenu>
    </PopoutContainer>
  );
};

const PopoutMenu = styled.div`
  z-index: 2;
  position: absolute;
  right: 0;
  ${(props) =>
    props.position === "bottom"
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `};
  transform: ${(props) =>
    props.isSidebarOpen ? `translate(80%, 5px)` : `translate(100%, 5px)`};
  width: 264px;
  padding: 16px;
  background-color: #fff;

  box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.2);
  border-radius: 4px;

  transition: opacity 0.12s, transform 0.12s ease-out;
  opacity: 0;
  pointer-events: none;
`;

const PopoutTitle = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  padding-left: 16px;
  height: 36px;
  margin-bottom: 8px;
  color: #9ca3af;

  display: flex;
  align-items: center;
`;

const PopoutContainer = styled.div`
  position: relative;

  &:hover {
    ${PopoutMenu} {
      opacity: 1;
      pointer-events: all;

      ${(props) =>
        props.position === "bottom"
          ? props.isSidebarOpen
            ? css`
                transform: translate(80%, 10px);
              `
            : css`
                transform: translate(100%, 10px);
              `
          : props.isSidebarOpen
          ? css`
              transform: translate(80%, -54px);
            `
          : css`
              transform: translate(100%, -54px);
            `}
    }
  }
`;

const PopoutItem = styled((props) => <MenuItem {...props} />)`
  cursor: default;
`;

export const PopoutSubItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 500;
  color: #374151;
  text-decoration: none;
  height: 36px;
  padding-left: 16px;
  transition: color 0.2s, background-color 0.2s;
  cursor: ${(props) => (props.usesArrow ? "default" : "pointer")};
  border-radius: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    transition: transform 0.2s;
  }

  ${(props) =>
    props.isactive
      ? css`
          color: #5048e5;
          font-weight: 500;
          cursor: default;
        `
      : css`
          &:hover {
            color: #5048e5;

            background-color: #f3f4f6;
          }
        `}
`;

const Caret = styled(CaretDown)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(-90deg);
`;

Popout.propTypes = {
  isActive: PropTypes.bool,
  text: PropTypes.string,
  Icon: PropTypes.any,
  position: PropTypes.oneOf(["top", "bottom"]),
};

Popout.defaultProps = {
  isActive: false,
  text: "",
  Icon: null,
  position: "top",
};

export default Popout;
