import { css } from "styled-components";
import { rgba } from "polished";

import { colors, typography } from "./theme";

export const globalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: ${typography.fontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: ${colors.text};
    background-color: ${colors.pureWhite};
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dd,
  p,
  figure,
  pre,
  table,
  hr {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  button {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    outline: none;
    -webkit-appearance: none;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d2d1d2;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${rgba("#e1e0e4", 0.5)};
  }
  ::-webkit-scrollbar-corner {
    background: ${rgba("#e1e0e4", 0.5)};
  }
`;
