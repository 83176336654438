import React from "react";
import styled from "styled-components";
import { darken } from "polished";

import { Field, Label, Input } from "../../../../components/Form";
import SelectDropdown from "../../../../components/SelectDropdown";
import Toggle from "../../../../components/Toggle";

const ClientMeeting = ({
  frequency,
  setFrequency,
  repeatEvery,
  setRepeatEvery,
  weekArray,
  repeatOn,
  setRepeatOn,
  specificWeek,
  setSpecificWeek,
  specificDay,
  setSpecificDay,
  time,
  setTime,
  activeProject,
  setActiveProject,
  toggleWeek,
  type,
  heading,
  monthType,
  setMonthType,
}) => {
  const weekdays = ["M", "TU", "W", "TH", "F", "SA", "SU"];
  const weeks = ["The first", "The second", "The third", "The fourth"];
  const fullWeekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  /*
  |--------------------------------------------------------------------------
  | Set prefix for the month day
  |--------------------------------------------------------------------------
  */
  const ordinal = (date) => {
    if (date > 20 || date < 10) {
      switch (date % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "";
      }
    }
    return "th";
  };

  return (
    <MeetingContainer>
      <Heading>{heading}</Heading>

      <Meeting>
        <FlexRow>
          <StyledField>
            <Label>Frequency</Label>
            <StyledSelectDropdown
              onChange={(e) => setFrequency(e.value)}
              value={
                frequency && {
                  label: frequency,
                  value: frequency,
                }
              }
              placeholder="Select Frequency"
              options={[
                {
                  label: "Week",
                  value: "Week",
                },
                {
                  label: "Month",
                  value: "Month",
                },
              ]}
            ></StyledSelectDropdown>
          </StyledField>
          <StyledField>
            <Label>
              Repeat every {repeatEvery} {frequency && frequency.toLowerCase()}
              {repeatEvery && repeatEvery >= 2 ? "s" : ""}
            </Label>
            <Input
              type="number"
              defaultValue={repeatEvery}
              onChange={(e) => setRepeatEvery(e.target.value)}
            />
          </StyledField>
        </FlexRow>

        {frequency && (
          <Field>
            {frequency === "Week" ? (
              <>
                <Label>Repeat on</Label>
                <Options>
                  {weekdays.map((day) => {
                    return (
                      <Option
                        onClick={() => toggleWeek(type, day)}
                        selected={weekArray && weekArray.includes(day)}
                      >
                        {day}
                      </Option>
                    );
                  })}
                </Options>
              </>
            ) : frequency === "Month" ? (
              <>
                <StyledField>
                  <Label>Month Type</Label>
                  <StyledSelectDropdown
                    onChange={(e) => setMonthType(e.value)}
                    value={
                      monthType && {
                        label: monthType,
                        value: monthType,
                      }
                    }
                    placeholder="Select Month Type"
                    options={[
                      {
                        label: "Day",
                        value: "Day",
                      },
                      {
                        label: "Week",
                        value: "Week",
                      },
                    ]}
                  ></StyledSelectDropdown>
                </StyledField>
                {monthType === "Day" ? (
                  <StyledField>
                    <Label>
                      On the{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {repeatOn && `${repeatOn}${ordinal(repeatOn)}`}
                      </span>{" "}
                      day of the month
                    </Label>
                    <Input
                      type="number"
                      defaultValue={repeatOn}
                      onChange={(e) => setRepeatOn(e.target.value)}
                    />
                  </StyledField>
                ) : (
                  <>
                    <Label>Specific day of a specific week</Label>
                    <FlexRow>
                      <StyledField>
                        <StyledSelectDropdown
                          onChange={(e) => setSpecificWeek(e.value)}
                          value={
                            specificWeek && {
                              label: specificWeek,
                              value: specificWeek,
                            }
                          }
                          placeholder="Select Week"
                          options={
                            weeks &&
                            weeks.map((week) => {
                              return {
                                label: week,
                                value: week,
                              };
                            })
                          }
                        ></StyledSelectDropdown>
                      </StyledField>

                      <StyledField>
                        <StyledSelectDropdown
                          onChange={(e) => setSpecificDay(e.value)}
                          value={
                            specificDay && {
                              label: specificDay,
                              value: specificDay,
                            }
                          }
                          placeholder="Select Day"
                          options={
                            fullWeekdays &&
                            fullWeekdays.map((day) => {
                              return {
                                label: day,
                                value: day,
                              };
                            })
                          }
                        ></StyledSelectDropdown>
                      </StyledField>
                    </FlexRow>
                  </>
                )}
              </>
            ) : null}
          </Field>
        )}

        <StyledField>
          <Label>at the following time</Label>
          <Input
            type="time"
            defaultValue={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </StyledField>

        <Field style={{ marginTop: "10px" }}>
          <Label>Only create tasks while there is an active project?</Label>
          <ToggleContainer>
            <Toggle
              label="Yes"
              type="radio"
              checked={activeProject === "Yes"}
              name={`${type}-activeProject`}
              value="Yes"
              client
              onChange={(e) => setActiveProject(e.target.value)}
            />
            <Toggle
              label="No"
              type="radio"
              checked={activeProject === "No"}
              name={`${type}-activeProject`}
              value="No"
              client
              onChange={(e) => setActiveProject(e.target.value)}
            />
          </ToggleContainer>
        </Field>
      </Meeting>
    </MeetingContainer>
  );
};

const MeetingContainer = styled.div`
  width: 45%;
`;

const Heading = styled.h3`
  font-size: 14px;
  color: #373040;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Meeting = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 45%;
  }
`;

const StyledField = styled(Field)`
  width: 45%;

  input {
    width: 100%;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  * {
    font-size: 14px;
  }
`;

// const TaskRight = styled.div`
//   width: 45%;

//   input,
//   textarea {
//     width: 100%;
//   }
// `;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? darken(0.3, "#7BC1A8") : "#7BC1A8"};
  color: white;
  font-weight: bold;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  height: 35px;
  width: 35px;
  font-size: 12px;
  margin: 5px;

  &:hover {
    opacity: ${(props) => (props.selected ? "1" : ".6")};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-top: 20px;
`;

export default ClientMeeting;
