import React, { useState, useEffect } from "react";
import styled from "styled-components";
import store from "store";

const DesktopLogin = () => {
  const [done, setDone] = useState(false);
  useEffect(() => {
    const token = store.get("sherpaToken");

    if (token) {
      const url = `sherpa://method=login&token=${token}`;
      window.open(url);
      setDone(true);
      //window.open("", "_self").window.close();
    }

    return () => {};
  }, []);

  return done ? (
    <Message>
      Your app should now automatically open, and log in. If it did not, make
      sure the request was not blocked by your popup blocker. You should allow
      all popups from sherpa.big.vision. When logged in, you may close this
      window.
    </Message>
  ) : null;
};

const Message = styled.p`
  max-width: 500px;
  margin: auto;
`;

export default DesktopLogin;
