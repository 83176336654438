import React from "react";

const Bell = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.6666 10.1667V8.83333C13.6666 6.90033 12.0996 5.33333 10.1666 5.33333C8.23359 5.33333 6.66659 6.90033 6.66659 8.83333V10.1667L5.33325 13H14.9999L13.6666 10.1667Z"
        stroke="#0079FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16675 13C8.16675 13 8.16675 14.6667 10.1667 14.6667C12.1667 14.6667 12.1667 13 12.1667 13"
        stroke="#0079FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Bell.propTypes = {};

export default Bell;
