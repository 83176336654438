import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { getDay } from "date-fns";

import Popout, { PopoutSubItem } from "./Popout";
import Inbox from "./Inbox";
import Changelog from "./Changelog";

import mainLogo from "../../images/logo.png";
import ClockIcon from "../icons/ClockIcon";
import ClockStopIcon from "../icons/ClockStopIcon";
import SettingsIcon from "../icons/SettingsIcon";
import ProfileIcon from "../icons/ProfileIcon";

import HomeIcon from "../icons/HomeIcon";
import ScheduleIcon from "../icons/ScheduleIcon";
import FlagIcon from "../icons/FlagIcon";
import ReportsIcon from "../icons/ReportsIcon";
import SalesIcon from "../icons/SalesIcon";
import ClientsIcon from "../icons/ClientsIcon";
import CreateIcon from "../icons/CreateIcon";
import FolderIcon from "../icons/Folder";

import ProgressBar from "../../views/dashboard/components/ProgressBar";

import { respondTo } from "../../styles/styleHelpers";
import { sidebarWidth } from "../../utils/constants";

const currentDate = new Date();
const weekPace = (getDay(currentDate) / 5) * 100;

const getRoundedHours = (totalHours) => {
  if (totalHours > 0) {
    totalHours = Math.ceil(totalHours * 4) / 4;
    totalHours = Math.round(totalHours * 100) / 100;
  }

  return totalHours;
};

const SidebarView = ({
  isOpen,
  route,
  user,
  isAdmin,
  isManagerOrAdmin,
  timerText,
  currentProject,
  currentProjectCode,
  currentProjectBudget,
  currentProjectWeekHoursSpent,
  isTimerRunning,
  isTimerWarning,
  openStopModal,
  goToCurrentProject,
  timerHours,
}) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <Logo as={Link} to="/">
        <img src={mainLogo} alt="logo" />
      </Logo>
      <Menu>
        <MenuItem
          as={Link}
          to="/"
          isactive={route === "" || route === "dashboard" ? 1 : 0}
        >
          <MenuIcon>
            <HomeIcon />
          </MenuIcon>
          <MenuText>Home</MenuText>
        </MenuItem>

        {isManagerOrAdmin ? (
          <Popout
            text="Sales"
            Icon={SalesIcon}
            to="/sales"
            isActive={
              route === "sales" ||
              route === "sales/contacts" ||
              route === "sales/companies" ||
              route === "sales/deals"
            }
            isSidebarOpen={isOpen}
          >
            <PopoutSubItem
              as={Link}
              to="/sales/contacts"
              isactive={route === "sales/contacts" ? 1 : 0}
            >
              Contacts
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/sales/companies"
              isactive={route === "sales/companies" ? 1 : 0}
            >
              Companies
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/sales/deals"
              isactive={route === "sales/deals" ? 1 : 0}
            >
              Deals
            </PopoutSubItem>
          </Popout>
        ) : null}

        {isManagerOrAdmin ? (
          <MenuItem
            as={Link}
            to="/schedule"
            isactive={route === "schedule" ? 1 : 0}
          >
            <MenuIcon>
              <ScheduleIcon />
            </MenuIcon>
            <MenuText>Schedule</MenuText>
          </MenuItem>
        ) : null}

        {isManagerOrAdmin ? (
          <MenuItem
            as={Link}
            to="/manage/time"
            isactive={route === "manage/time" ? 1 : 0}
          >
            <MenuIcon>
              <FlagIcon />
            </MenuIcon>
            <MenuText>Extra Time</MenuText>
          </MenuItem>
        ) : null}

        {isManagerOrAdmin ? (
          <MenuItem
            as={Link}
            to="/manage/clients"
            isactive={route === "manage/clients" ? 1 : 0}
          >
            <MenuIcon>
              <ClientsIcon />
            </MenuIcon>
            <MenuText>Clients</MenuText>
          </MenuItem>
        ) : null}

        {isManagerOrAdmin ? (
          <MenuItem
            as={Link}
            to="/projects"
            isactive={route === "projects" ? 1 : 0}
          >
            <MenuIcon>
              <FolderIcon />
            </MenuIcon>
            <MenuText>Projects</MenuText>
          </MenuItem>
        ) : null}

        {isManagerOrAdmin ? (
          <Popout
            text="Reports"
            Icon={ReportsIcon}
            isActive={
              route === "booking" ||
              route === "targets" ||
              route === "reports/sales" ||
              route === "production" ||
              route === "client-health" ||
              route === "project-health" ||
              route === "booked-vs-capacity" ||
              route === "deliverables" ||
              route == "archivedDeliverables" ||
              route === "tasks"
            }
            isSidebarOpen={isOpen}
          >
            <PopoutSubItem
              as={Link}
              to="/reports/targets"
              isactive={route === "targets" ? 1 : 0}
            >
              Targets
            </PopoutSubItem>

            <PopoutSubItem
              as={Link}
              to="/reports/booking"
              isactive={route === "booking" ? 1 : 0}
            >
              Booking
            </PopoutSubItem>

            <PopoutSubItem
              as={Link}
              to="/reports/sales"
              isactive={route === "reports/sales" ? 1 : 0}
            >
              Sales
            </PopoutSubItem>

            <PopoutSubItem
              as={Link}
              to="/reports/production"
              isactive={route === "production" ? 1 : 0}
            >
              Production Success
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/client-health"
              isactive={route === "client-health" ? 1 : 0}
            >
              Client Health
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/project-health"
              isactive={route === "project-health" ? 1 : 0}
            >
              Project Health
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/deliverables"
              isactive={route === "deliverables" ? 1 : 0}
            >
              Deliverables
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/archivedDeliverables"
              isactive={route === "archivedDeliverables" ? 1 : 0}
            >
              Archived Deliverables
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/tasks"
              isactive={route === "tasks" ? 1 : 0}
            >
              Tasks
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/archivedTasks"
              isactive={route === "archivedTasks" ? 1 : 0}
            >
              Archived Tasks
            </PopoutSubItem>
            <PopoutSubItem
              as={Link}
              to="/reports/booked-vs-capacity"
              isactive={route === "booked-vs-capacity" ? 1 : 0}
            >
              Booked vs. Capacity
            </PopoutSubItem>
          </Popout>
        ) : null}
        {isManagerOrAdmin ? (
          <Popout
            text="Create"
            Icon={CreateIcon}
            isActive={route === "create"}
            isSidebarOpen={isOpen}
          >
            <PopoutSubItem as={Link} to="/manage/clients/new">
              Client
            </PopoutSubItem>
            <PopoutSubItem as={Link} to="/projects/new">
              Project
            </PopoutSubItem>
            <PopoutSubItem as={Link} to="/manage/users/new">
              User
            </PopoutSubItem>
          </Popout>
        ) : null}
      </Menu>

      {user ? (
        <TimerWrapper
          isSidebarOpen={isOpen}
          isRunning={isTimerRunning}
          isWarning={
            isTimerWarning ||
            currentProjectWeekHoursSpent > currentProjectBudget
          }
        >
          <Timer isRunning={isTimerRunning} isSidebarOpen={isOpen}>
            <TimerButton
              disabled={!isTimerRunning}
              onClick={isTimerRunning ? () => openStopModal() : null}
            >
              {isTimerRunning ? <ClockStopIcon /> : <ClockIcon />}
            </TimerButton>
            <Time>{timerText}</Time>
          </Timer>
          {currentProject ? (
            <TimerProject onClick={goToCurrentProject}>
              {currentProjectCode ? (
                <TimerCode>{currentProjectCode}</TimerCode>
              ) : null}
              <TimerProjectName isVisible={isOpen}>
                {currentProjectCode ? `: ` : null}
                {currentProject}
              </TimerProjectName>
            </TimerProject>
          ) : null}

          {currentProjectBudget && isOpen ? (
            <ProgressBarContainer>
              <Progress
                style={{
                  margin: "0px",
                  height: "8px",
                }}
                pace={weekPace}
                actual={getRoundedHours(
                  timerHours + currentProjectWeekHoursSpent,
                )}
                budgeted={currentProjectBudget}
              />

              <ProgressHours>
                {`${getRoundedHours(
                  timerHours + currentProjectWeekHoursSpent,
                )}/${currentProjectBudget}`}
              </ProgressHours>
            </ProgressBarContainer>
          ) : null}
        </TimerWrapper>
      ) : null}

      <Footer>
        <Menu>
          <Inbox isSidebarOpen={isOpen} />

          {isAdmin ? (
            <Popout
              text="Settings"
              Icon={SettingsIcon}
              isActive={
                route === "chunklets" ||
                route === "users" ||
                route === "rates" ||
                route === "accessLevels" ||
                route === "teams" ||
                route === "offices" ||
                route === "kpi" ||
                route === "invoices" ||
                route === "settings"
              }
              position="bottom"
              isSidebarOpen={isOpen}
            >
              <PopoutSubItem
                as={Link}
                to="/manage/users"
                isactive={route === "users" ? 1 : 0}
              >
                Users
              </PopoutSubItem>

              <PopoutSubItem
                as={Link}
                to="/manage/accessLevels"
                isactive={route === "accessLevels" ? 1 : 0}
              >
                Permission Roles
              </PopoutSubItem>
              <PopoutSubItem
                as={Link}
                to="/manage/teams"
                isactive={route === "teams" ? 1 : 0}
              >
                Teams
              </PopoutSubItem>
              <PopoutSubItem
                as={Link}
                to="/manage/roles"
                isactive={route === "roles" ? 1 : 0}
              >
                Roles
              </PopoutSubItem>
              <PopoutSubItem
                as={Link}
                to="/manage/offices"
                isactive={route === "offices" ? 1 : 0}
              >
                Offices
              </PopoutSubItem>
              <PopoutSubItem
                as={Link}
                to="/manage/invoices"
                isactive={route === "invoices" ? 1 : 0}
              >
                Invoices
              </PopoutSubItem>

              {/* <PopoutSubItem
                as={Link}
                to="/projects/chunklets"
                isactive={route === "chunklets" ? 1 : 0}
              >
                Chunklets
              </PopoutSubItem> */}

              <PopoutSubItem
                as={Link}
                to="/manage/kpi"
                isactive={route === "kpi" ? 1 : 0}
              >
                KPIs
              </PopoutSubItem>

              <PopoutSubItem
                as={Link}
                to="/settings"
                isactive={route === "settings" ? 1 : 0}
                usestroke={1}
              >
                Company Settings
              </PopoutSubItem>
            </Popout>
          ) : null}
          <MenuItem
            as={Link}
            to="/profile"
            isactive={route === "profile" ? 1 : 0}
            usestroke={1}
          >
            <MenuIcon>
              <ProfileIcon />
            </MenuIcon>
            <MenuText>Profile</MenuText>
          </MenuItem>
          <Changelog isSidebarOpen={isOpen} />
        </Menu>
      </Footer>
    </SidebarContainer>
  );
};

export const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 500;
  color: #374151;
  text-decoration: none;
  height: 40px;
  padding-left: 48px;
  transition: color 0.2s, background-color 0.2s;
  cursor: ${(props) => (props.usesArrow ? "default" : "pointer")};
  border-radius: 4px;

  ${respondTo("xlarge")} {
    height: 48px;
    padding-left: 48px;
  }

  svg {
    transition: transform 0.2s;
  }

  ${(props) =>
    props.isactive
      ? css`
          color: #5048e5;
          font-weight: 500;
          cursor: default;

          svg path {
            stroke: #5048e5;
          }
        `
      : css`
          &:hover {
            color: #5048e5;

            background-color: #f3f4f6;
          }
        `}
`;

export const MenuText = styled.div`
  /* so it fills the entire space if its a clickable link */
  flex-grow: 1;
  align-self: stretch;

  display: flex;
  align-items: center;

  text-decoration: none;
  color: inherit;
`;

export const MenuIcon = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
`;

const SidebarContainer = styled.div`
  background-color: ${(props) => props.theme.colors.pureWhite};
  border-right: 2px solid #f1f5f8;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  padding: 16px;
  width: ${(props) =>
    props.isOpen ? sidebarWidth.laptop : sidebarWidth.closed};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;

  ${respondTo("xlarge")} {
    width: ${(props) =>
      props.isOpen ? sidebarWidth.full : sidebarWidth.closed};
  }

  ${(props) =>
    props.isOpen
      ? css``
      : css`
          ${MenuItem} {
            position: relative;
            padding-left: 0;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              height: 100%;
              left: -16px;
              right: -16px;
            }
          }

          ${MenuText} {
            opacity: 0;
            pointer-events: none;
          }
        `}
`;

const Logo = styled.div`
  width: 100%;
  min-height: 50px;
  padding-left: 8px;
  padding-bottom: 32px;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;

  ${(props) =>
    props.isSidebarOpen
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100% + 22px);
          margin-left: -11px;
          margin-right: -11px;
        `}

  ${(props) =>
    props.isRunning &&
    css`
      background-color: #f0f3ff;
      //width: calc(100% + 36px);
    `}

  ${(props) =>
    props.isWarning &&
    css`
      background-color: #fee2e2;
    `}
`;

const TimerButton = styled.button`
  cursor: pointer;
  pointer-events: all;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:not(:disabled) {
    &:hover {
      svg {
        animation: shake-bottom 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
          infinite;
      }
    }
  }

  @keyframes shake-bottom {
    0%,
    50%,
    100% {
      transform: scale(1) rotate(0deg);
      transform-origin: 50% 100%;
    }
    5% {
      transform: scale(1.1) rotate(2deg);
    }
    10%,
    20%,
    30% {
      transform: scale(1.1) rotate(-4deg);
    }
    15%,
    25%,
    35% {
      transform: scale(1.1) rotate(4deg);
    }
    40% {
      transform: scale(1.1) rotate(-2deg);
    }
    45% {
      transform: scale(1.1) rotate(2deg);
    }
  }
`;

const Time = styled.h3`
  font-weight: 600;
  color: #374151;
  margin-bottom: 0px;
  font-size: 16px;
`;

const Timer = styled.div`
  position: relative;
  padding-top: 4px;
  padding-bottom: 2px;

  ${(props) =>
    props.isSidebarOpen
      ? css`
          padding-left: 32px;

          ${TimerButton} {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-48%) scale(0.9);
          }
        `
      : css`
          display: flex;
          flex-direction: column;
          align-items: center;

          ${TimerButton} {
            position: static;
            transform: none;
          }

          ${Time} {
            font-size: 12px;
          }
        `}
`;

const TimerProject = styled.h4`
  font-family: Inter;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #6b7280;
  margin-bottom: 0;
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const TimerProjectName = styled.span`
  ${(props) =>
    props.isVisible
      ? ``
      : css`
          display: none;
        `}
`;

const TimerCode = styled.span``;

const Footer = styled.div`
  width: 100%;
  margin-top: auto;
`;

const ProgressBarContainer = styled.div`
  width: 116px;
  margin-top: 10px;
`;

const Progress = styled(ProgressBar)`
  overflow: hidden;
`;

const ProgressHours = styled.p`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: #6b7280;
  margin: 5px 0px 0px 0px;
`;

SidebarView.propTypes = {
  currentProject: PropTypes.string,
  route: PropTypes.string,
  user: PropTypes.object,
  isAdmin: PropTypes.bool,
  isManagerOrAdmin: PropTypes.bool,
  timerText: PropTypes.string,
  isTimerRunning: PropTypes.bool,
};

SidebarView.defaultProps = {
  currentProject: null,
  route: "",
  isAdmin: false,
  isManagerOrAdmin: false,
  timerText: "00:00:00",
  isTimerRunning: false,
};

export default SidebarView;
