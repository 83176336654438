import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getInvoicesFromApi, importInvoiceOnApi } from "../../../utils/api";

import { useNotifications } from "../../../context/notificationsContext";

import CreateLink from "../../../components/links/CreateLink";

import InvoicesTable from "./components/invoicesTable";

const Invoices = () => {
  const { openAlertPopup } = useNotifications();

  const [invoices, setInvoices] = useState(null);
  // const [sortBy, setSortBy] = useState(""); // number, sent, due, total, balance, status

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    try {
      const apiInvoices = await getInvoicesFromApi();

      setInvoices(apiInvoices);
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, something went wrong while fetching the invoices. ${error}`,
      );
    }
  };

  const importInvoice = async () => {
    try {
      // prompt the user for the invoice ID
      const qbInvoiceId = window.prompt(
        "Please provide the ID of the QuickBooks invoice you would like to import.\n" +
          "Note: the ID is the `txnId` in the URL, not the invoice number.",
      );

      // if user provided a value
      if (qbInvoiceId) {
        // attempt to import
        await importInvoiceOnApi(qbInvoiceId);

        // alert if successful
        openAlertPopup("Success", `Invoice successfully imported.`);
      }
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, something went wrong while importing that invoice. ${error}`,
      );
    }
  };

  return (
    <Container>
      <Header>
        <h1>Invoices</h1>

        <CreateLink as={Link} to={`/manage/invoices/new`}>
          Create Invoice
        </CreateLink>

        <CreateLink onClick={importInvoice}>Import Invoice</CreateLink>
      </Header>

      {invoices ? <InvoicesTable invoices={invoices} /> : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  gap: 100px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 70px;
  }
`;

export default Invoices;
