import React from "react";
import styled, { css } from "styled-components";
import { buttonReset } from "../../../styles/styleHelpers";

const TimelineButtonGroup = ({ currentView, changeView, ...rest }) => {
  return (
    <Group {...rest}>
      <Button active={currentView === "day"} onClick={() => changeView("day")}>
        d
      </Button>
      <Button
        active={currentView === "week"}
        onClick={() => changeView("week")}
      >
        w
      </Button>
      <Button
        active={currentView === "month"}
        onClick={() => changeView("month")}
      >
        m
      </Button>
    </Group>
  );
};

const Group = styled.div`
  display: flex;
`;

const Button = styled.button`
  ${buttonReset()}
  border: 1px solid ${(props) => props.theme.colors.oldLightGray};
  width: 54px;
  height: 20px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.oldBlack2};

  ${(props) =>
    props.active
      ? css`
          border-color: ${props.theme.colors.blue};
          font-weight: 600;
        `
      : ``}

  &:first-child {
    border-radius: 2px 0 0 2px;
  }

  &:last-child {
    border-radius: 0 2px 2px 0;
  }
`;

export default TimelineButtonGroup;
