import React from "react";
import styled from "styled-components";

import { useNotifications } from "../../../context/notificationsContext";

import BriefForm from "./BriefForm";

import { editProjectOnApi } from "../../manage/utils/api";

const BriefContainer = ({
  project,
  setProject,
  isImportant,
  projectBriefQuestions,
}) => {
  const { openAlertPopup } = useNotifications();

  const editProject = (values) => {
    try {
      const data = {
        projectId: project._id,
        database: {
          brief: values,
        },
      };

      // update brief on API
      editProjectOnApi(data);

      // update brief in state
      const updatedProject = { ...project };
      updatedProject.brief = values;
      setProject(updatedProject);

      openAlertPopup("Success", "Project brief has been saved", true);
    } catch (err) {
      openAlertPopup(
        "Failure",
        "Sorry, we weren't able to save the project brief",
      );
    }
  };

  return (
    <Container>
      <BriefForm
        projectBriefQuestions={projectBriefQuestions}
        brief={project.brief}
        onSave={isImportant ? editProject : undefined}
        disabled={!isImportant}
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1000px;
  width: 100%;

  input,
  textarea,
  label {
    width: 400px;
  }
`;

export default BriefContainer;
