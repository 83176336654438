import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { buttonReset } from "../../../styles/styleHelpers";

const Button = ({ children, small, disabled, hollow, ...rest }) => {
  return (
    <MainButton
      disabled={disabled}
      small={small ? true : undefined}
      hollow={hollow ? true : undefined}
      {...rest}
    >
      {children}
    </MainButton>
  );
};

const MainButton = styled.button`
  ${buttonReset()}
  position: relative;
  overflow: hidden;
  z-index: 1;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  border-radius: 4px;
  padding: ${(props) => (props.small ? "8px 16px" : "12px 16px")};

  color: ${(props) =>
    props.hollow ? props.theme.colors.black : props.theme.colors.pureWhite};

  background-color: ${(props) =>
    props.hollow ? props.theme.colors.pureWhite : props.theme.colors.indigo600};

  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

  border: 0.5px solid
    ${(props) =>
      props.hollow ? props.theme.colors.black : props.theme.colors.indigo600};

  cursor: pointer;
  transition: transform 0.2s;

  &:disabled {
    cursor: default;
    pointer-events: none;
    color: ${(props) => props.theme.colors.slate};
    background-color: ${(props) => props.theme.colors.lightSlate};
    border: 1px solid rgba(140, 131, 145, 0.4);
  }

  ${(props) =>
    !props.disabled
      ? css`
          &::after {
            content: "";
            position: absolute;
            top: -10%;
            left: -10%;
            height: 120%;
            width: 120%;
            background-color: ${props.hollow
              ? props.theme.colors.coolGray100
              : props.theme.colors.indigo700};
            z-index: -1;
            transform: translate(-120%, 120%);
            transform-origin: center;
            opacity: 0;
            transition: transform 0.4s, opacity 0.4s;
            border-radius: 30px;
          }

          &:hover {
            &::after {
              opacity: 1;
              transform: none;
              transition-delay: 0, 0.1s;
            }
          }

          &:active {
            transform: scale(0.97);
          }
        `
      : ``}
`;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: "Submit",
  small: false,
  disabled: false,
};

export default Button;
