import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TargetCard = ({ title, hours, target, pace }) => {
  let progressHours = (hours / target) * 100;
  const convertedHours = progressHours.toFixed(2);
  progressHours = `${convertedHours}%`;
  const convertedPace = `${pace}%`;
  const minimumPace = 0.8 * pace;
  let cardState;

  if (convertedHours >= 1 && pace) {
    if (parseInt(convertedHours) >= parseInt(pace)) {
      cardState = "safe";
    } else if (parseInt(convertedHours) < parseInt(minimumPace)) {
      cardState = "danger";
    } else if (parseInt(convertedHours) < parseInt(pace)) {
      cardState = "warning";
    }
  }

  return (
    <CardContainer>
      <CardInner>
        <Title>{title}</Title>
        <Progress cardState={cardState}>
          <Pace className="pace" pace={convertedPace} />
          <ProgressBar
            hours={progressHours}
            cardState={cardState}
          ></ProgressBar>
        </Progress>
        <HoursSection>
          <Hours>
            <HoursLabel>Tracked</HoursLabel>
            <HoursQty>{hours ? hours : "0"}</HoursQty>
          </Hours>
          <Hours>
            <HoursLabel>Target</HoursLabel>
            <HoursQty>{target}</HoursQty>
          </Hours>
        </HoursSection>
      </CardInner>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  flex-basis: 300px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 23px;
  margin-right: 23px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  filter: drop-shadow(4px 8px 14px rgba(185, 188, 192, 0.3));
  transition: 0.3s ease-in-out;

  p {
    transition: inherit;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.darkSlate};
    color: ${(props) => props.theme.colors.pureWhite};

    .pace {
      background-color: ${(props) => props.theme.colors.pureWhite};
    }
  }
`;

const Title = styled.p`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 30px;
  font-size: ${(props) => props.theme.fontSize_xxs};
`;

const CardInner = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Progress = styled.div`
  background-color: ${(props) =>
    props.cardState === "safe"
      ? props.theme.colors.lightGreen
      : props.cardState === "warning"
      ? props.theme.colors.lightYellow
      : props.cardState === "danger"
      ? props.theme.colors.lightRed
      : props.theme.colors.oldLightGray};
  height: ${(props) => (props.hours ? props.hours : "")};
  margin: auto;
  display: inline-block;
  width: 130px;
  height: 100%;
  position: relative;
  margin-bottom: 10px;
`;

const Pace = styled.div`
  transition: 0.3s ease-in-out;
  position: absolute;
  width: 180px;
  left: -25px;
  height: 2px;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.darkSlate};
  bottom: ${(props) => (props.pace ? props.pace : "")};
`;

const ProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) =>
    props.cardState === "safe"
      ? props.theme.colors.green
      : props.cardState === "warning"
      ? props.theme.colors.yellow
      : props.theme.colors.red};
  height: ${(props) => (props.hours ? props.hours : "")};
  max-height: 100%;
  transition: 0.5s ease-in-out;
`;

const HoursSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const Hours = styled.div`
  margin-bottom: 0;

  &:last-child {
    p {
      text-align: right;
    }
  }
`;

const HoursLabel = styled.p`
  margin-bottom: 3px;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize_xxxs};
`;

const HoursQty = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize_xs};
  line-height: 1;
`;

TargetCard.propTypes = {
  title: PropTypes.string,
  hours: PropTypes.number,
  pace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  target: PropTypes.number,
};

TargetCard.defaultProps = {
  title: "",
  hours: 0,
  pace: 0,
  target: 0,
};

export default TargetCard;
