import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import { Field, Label, Input } from "../../components/Form";
import Button from "../../components/buttons/Button";
import SimpleLink from "../../components/links/SimpleLink";
import ProfileImage from "../../components/ProfileImage";
import SelectDropdown from "../../components/SelectDropdown";

import { createRoleOnApi, getTeamsFromApi } from "../../utils/api";

const tiers = [
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Director",
    value: "director",
  },
  {
    label: "Partner",
    value: "partner",
  },
];

const RoleCreate = () => {
  const history = useHistory();
  const { openAlertPopup } = useNotifications();

  const [teams, setTeams] = useState([]);
  const [role, setRole] = useState("");
  const [team, setTeam] = useState("");
  const [tier, setTier] = useState("");
  const [harvestId, setHarvestId] = useState("");

  /*
  |--------------------------------------------------------------------------
  | Get all the teams from the API
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    const teamsFromApi = await getTeamsFromApi();
    setTeams(teamsFromApi);
  };

  useEffect(() => {
    getTeams();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Creates the role on the API
  |--------------------------------------------------------------------------
  */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await createRoleOnApi({ role, team, tier, harvestId });

      openAlertPopup("Success", `${role} has been created.`, true);

      // go to roles list view
      history.push(`/manage/roles`);
    } catch (error) {
      console.error("error", error);

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't create the role.`,
      );
    }
  };

  return (
    <Container>
      <Heading>Create Role</Heading>
      <EditSection onSubmit={handleSubmit}>
        <Wrapper>
          <LeftSection>
            <Field>
              <Label>Name</Label>
              <Input
                value={role}
                onChange={(e) => setRole(e.target.value)}
                placeholder="Name"
                required
              />
            </Field>

            <Field>
              <Label>Harvest Id</Label>
              <Input
                value={harvestId}
                onChange={(e) => setHarvestId(e.target.value)}
                placeholder="Harvest Id"
                required
              />
            </Field>

            <Field>
              <Label>Tier</Label>
              <SelectDropdown
                placeholder="Select Tier"
                onChange={(e) => setTier(e.value)}
                options={tiers}
              />
            </Field>

            {teams?.length ? (
              <Field>
                <Label>Team</Label>
                <SelectDropdown
                  placeholder="Select Team"
                  onChange={(e) => setTeam(e.value)}
                  options={teams.map((team) => ({
                    label: team.name,
                    value: team._id,
                  }))}
                />
              </Field>
            ) : null}
          </LeftSection>
        </Wrapper>
        <Button type="submit">Save</Button>
      </EditSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 40px;
`;

const DisplaySection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const DisplayText = styled.div`
  margin-left: 30px;
  color: ${(props) => props.theme.colors.oldBlack2};
  font-weight: 600;
`;

const EditSection = styled.form`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 500px;
  padding-right: 100px;
`;

const RightSection = styled.div`
  flex: 1;
`;

const BookingTable = styled.table`
  ${Input} {
    width: 100%;
  }
`;

const BookingHeader = styled.th`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 400;
  text-align: left;
  color: #545d7a;
  padding-bottom: 15px;

  &:not(:last-child) {
    padding-right: 30px;
  }
`;

const BookingRow = styled.tr``;

const BookingCell = styled.td`
  padding-bottom: 15px;

  &:not(:last-child) {
    padding-right: 30px;
  }
`;

const BookingQuarter = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.colors.oldBlack2};
  margin-bottom: 0;
`;

const DeleteButton = styled(SimpleLink)`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  margin-left: 400px;
  padding-bottom: 2px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

export default RoleCreate;
