import _ from "lodash";
import { isValid } from "date-fns";
import Fuse from "fuse.js";

/**
 * Sorts items by property
 * @param {string} sortBy Path to sort by (can use dot format, ex: company.name)
 * @param {object[]} items Objects that you want sorted
 * @param {object} options Any additional options you want to add
 * @param {boolean} options.isReverse Whether the sorting should be in reverse
 * @param {boolean} options.isDate If the key being sorted is a date string
 * @returns {object[]} Sorted items
 */

export const sortItems = (
  sortBy,
  items,
  { isReverse = false, isDate = false },
) => {
  if (!items || !Array.isArray(items) || items.length < 1) return [];

  let sortedItems = [...items];

  sortedItems = sortedItems.sort((a, b) => {
    let aVal = "";
    let bVal = "";

    // If the sorting property is a date,
    // we convert them into Date objects
    if (isDate) {
      const aRaw = _.get(a, sortBy);
      const bRaw = _.get(b, sortBy);

      // If one of the dates is bad, push it to end
      if (!aRaw || !isValid(aRaw)) return isReverse ? 1 : -1;
      if (!bRaw || !isValid(bRaw)) return isReverse ? -1 : 1;

      aVal = new Date(aVal);
      bVal = new Date(bVal);

      // If the sorting property is anything else
    } else {
      aVal = _.get(a, sortBy);
      bVal = _.get(b, sortBy);

      // If one of the values is bad, push it to the end
      if (!aVal) return isReverse ? 1 : -1;
      if (!bVal) return isReverse ? -1 : 1;
    }

    if (isReverse) {
      return aVal > bVal ? 1 : -1;
    } else {
      return bVal > aVal ? 1 : -1;
    }
  });

  return sortedItems;
};

/**
 * Searches items based on search query
 * @param {string} query The search query
 * @param {object[]} items Objects that you want searched
 * @param {string[]} propsToCheck The dot notation for each property to check against
 * @returns {object[]} Searched items
 */
export const searchItems = (query, items, propsToCheck) => {
  if (!items || !Array.isArray(items) || items.length < 1) return [];
  if (!query || query === "" || propsToCheck.length < 1) return items;

  const fuseObj = new Fuse(items, {
    threshold: 0.4, // Lowered the threshold, as default was too loosey-goosey
    keys: propsToCheck,
  });

  const results = fuseObj.search(query);

  let searchedItems = [];

  if (results.length > 0) {
    searchedItems = results.map((result) => result.item);
  }

  return searchedItems;
};

/**
 * Filters items based on value and route
 * @param {object[]} filters Object of filters to filter by
 * @param {string} filters.path Dot notation for property path
 * @param {string} filters.value The filter value to check against
 * @param {object[]} items The objects that you want filtered
 * @returns {object[]} Filtered items
 */
export const filterItems = (filters, items) => {
  let newItems = [...items];

  newItems = newItems.filter((item) => {
    let shouldInclude = false;

    filters.forEach((filter) => {
      // Gets the value of the path,
      const value = _.get(item, filter.path);

      // if the value exists, and it matches the filter value, or is included in the array of allowed values
      if (value && (value === filter.value || filter.values?.includes(value))) {
        shouldInclude = true;
      }
    });

    return shouldInclude;
  });

  return newItems;
};

/**
 * Paginates and returns the items for the given page
 * @param {object[]} items The objects that you want paginated
 * @param {number} currentPage The current page they're on
 * @param {number} numPerPage The number of items per page
 * @returns {object} Items and num pages
 *  in format: {
 *               items: array,
 *               numPages: number
 *             }
 */
export const paginateItems = (items, currentPage, numPerPage) => {
  if (!items || !Array.isArray(items) || items.length < 1) {
    return { items: [], numPages: 1 };
  }

  let itemsOnPage = [...items];

  const totalPages = Math.ceil(items.length / numPerPage || 1);

  itemsOnPage = itemsOnPage.slice(
    currentPage * numPerPage,
    (currentPage + 1) * numPerPage,
  );

  return {
    items: itemsOnPage,
    numPages: totalPages,
  };
};

/**
 * Gets the possible values for a certain property from an array of objects.
 * @param {string} filter The property to get the unique values for.
 * @param {object[]} items Array of objects to iterate over.
 * @param {object} options Any additional options you want to add.
 * @param {object[]} options.exclude Values that should be excluded. In {label, value} format.
 * @param {object[]} options.propPaths Custom paths to use for certain filters. For instance: {owner: "owner.name"}, so the "owner" filter will grab its values from item.owner.name, rather than item.owner
 * @returns {object[]} Array of objects in {label, value} format.
 */
export const getUniqueOptions = (
  filter,
  items,
  { exclude = [], propPaths = {} },
) => {
  let options = items
    .map((item) => {
      // check if a custom prop path was passed in for this filter
      const propPath =
        filter && propPaths.hasOwnProperty(filter) ? propPaths[filter] : null;

      // if so, use the item's value at that custom prop path
      // otherwise, the filter name itself is the prop path
      return propPath ? _.get(item, propPath) : filter ? item[filter] : null;
    })
    .filter((item) => item !== null && typeof item !== "undefined");

  // remove the duplicates
  options = [...new Set([...options])];

  options = options
    .filter(
      (option) =>
        // remove any options that have already been selected for this filter
        !exclude.some(
          ({ label, value }) => label === filter && value === option,
        ),
    )
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    // convert into label/value pairs for the dropdown
    .map((option) => ({
      label: option,
      value: option,
    }));

  return options;
};
