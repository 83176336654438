import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../context/authContext";
import { useActiveTimer } from "../context/activeTimerContext";

import { capitalize } from "../utils/helpers";
import Avatar from "./Avatar";

const ProfileImage = ({ type, handle, name, showStatus, ...rest }) => {
  const { membersStatuses } = useAuth();
  const { currentProjectId } = useActiveTimer();
  const [status, setStatus] = useState("offline"); // working, available, or offline
  const [currentProject, setCurrentProject] = useState(null); // Project object

  const getInitials = (name) => {
    const wordArray = name?.split(" ");

    // If there's at least 2 words, get the first initial of each word
    // If there's only 1 word, get the first initial,
    // otherwise, just leave it blank
    return wordArray.length > 1
      ? wordArray[0].charAt(0).toUpperCase() +
          wordArray[1].charAt(0).toUpperCase()
      : wordArray.length === 1
      ? name.charAt(0).toUpperCase()
      : "";
  };

  useEffect(() => {
    // Gets this member from the list and updates their status
    // Defaults to offline if anything is missing/wrong
    if (membersStatuses) {
      const thisMember = membersStatuses.find((tempStatus) =>
        handle
          ? tempStatus.handle.toLowerCase() === handle.toLowerCase()
          : false,
      );

      if (thisMember && thisMember.onlineStatus?.status) {
        setStatus(thisMember.onlineStatus.status);

        if (thisMember.onlineStatus.currentProject) {
          let newCurrentProjectTitle = "";
          if (thisMember.onlineStatus.currentProject.code) {
            newCurrentProjectTitle =
              thisMember.onlineStatus.currentProject.code + ": ";
          }
          newCurrentProjectTitle += thisMember.onlineStatus.currentProject.name;

          thisMember.onlineStatus.currentProject.title = newCurrentProjectTitle;
          setCurrentProject(thisMember.onlineStatus.currentProject);
        } else {
          setCurrentProject(null);
        }
      } else {
        setStatus("offline");
        setCurrentProject(null);
      }
    } else {
      setStatus("offline");
      setCurrentProject(null);
    }
  }, [membersStatuses]); //eslint-disable-line

  const determineColor = () => {
    // Same project: green
    // Different project: red
    // Available: blue
    // Offline: gray
    let color = "gray";

    if (
      status === "working" &&
      currentProject &&
      currentProjectId === currentProject._id
    ) {
      color = "green";
    } else if (status === "working") {
      color = "red";
    } else if (status === "available") {
      color = "blue";
    }

    return color;
  };

  return (
    <Avatar
      src={
        handle
          ? `${
              process.env.REACT_APP_API_URL
            }/assets/img/${type}/${handle.toLowerCase()}.jpg`
          : undefined
      }
      alt={name}
      initials={name ? getInitials(name) : undefined}
      tooltip={
        handle ? capitalize(handle) : name ? capitalize(name) : undefined
      }
      statusColor={showStatus ? determineColor() : null}
      statusProject={
        currentProject && currentProject.title ? currentProject.title : ""
      }
      {...rest}
    />
  );
};

ProfileImage.propTypes = {
  handle: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  showStatus: PropTypes.bool,
};

ProfileImage.defaultProps = {
  handle: "",
  name: "",
  type: "profile",
  showStatus: false,
};

export default ProfileImage;
