// import { rgba } from "polished";

// Colors
const colors = {
  // old legacy colors
  oldBlack: "#373040",
  oldYellow: "yellow",
  oldRed: "red",
  oldLightGray: "#e1E0E4",
  oldSilver: "#C4C4C4",

  // ui Colors
  pureWhite: "#FFFFFF",
  oldWhite: "#FAFBF9",
  dark: "#4C4C66",
  dark2: "#6F6C99",
  lightSlate: "#CEC9D4",
  lightGray: "#F9FAFB",
  coolGray: "#D1D5DB",
  coolerGray: "#9CA3AF",
  mediumGray: "#B7BEC6",
  darkGray: "#6B7280",
  darkerGray: "#374151",
  slate: "#8C8391",
  darkSlate: "#554668",
  oldBlack2: "#001940",
  green: "#7BC1A8",
  lightGreen: "#CDF0D6",
  blue: "#0079FF",
  blue2: "#3279CC",
  indigo: "#5048E5",
  lightBlue: "#EFFBFE",
  red: "#DF5757",
  lightRed: "#F7D6E1",
  yellow: "#F1E471",
  lightYellow: "#F5F4CE",

  // Not in design, but used in animations
  purple: "#A96AAB",
  teal: "#2595C2",

  cardBackground: "#F3F9FD",

  /* Color styles */
  black: "#000000",
  white: "#FFFFFF",
  gray50: "#FAFAFA",
  gray100: "#F4F4F5",
  gray200: "#E4E4E7",
  gray300: "#D4D4D8",
  gray400: "#A1A1AA",
  gray500: "#71717A",
  gray600: "#52525B",
  gray700: "#3F3F46",
  gray800: "#27272A",
  gray900: "#18181B",
  red50: "#FEF2F2",
  red100: "#FEE2E2",
  red200: "#FECACA",
  red300: "#FCA5A5",
  red400: "#F87171",
  red500: "#EF4444",
  red600: "#DC2626",
  red700: "#B91C1C",
  red800: "#991B1B",
  red900: "#7F1D1D",
  orange50: "#FFF7ED",
  orange100: "#FFEDD5",
  orange200: "#FED7AA",
  orange300: "#FDBA74",
  orange400: "#FB923C",
  orange500: "#F97316",
  orange600: "#EA580C",
  orange700: "#C2410C",
  orange800: "#9A3412",
  orange900: "#7C2D12",
  yellow50: "#FEFCE8",
  yellow100: "#FEF9C3",
  yellow200: "#FEF08A",
  yellow300: "#FDE047",
  yellow400: "#FACC15",
  yellow500: "#EAB308",
  yellow600: "#CA8A04",
  yellow700: "#A16207",
  yellow800: "#854D0E",
  yellow900: "#713F12",
  green50: "#F0FDF4",
  green100: "#DCFCE7",
  green200: "#BBF7D0",
  green300: "#86EFAC",
  green400: "#4ADE80",
  green500: "#22C55E",
  green600: "#16A34A",
  green700: "#15803D",
  green800: "#166534",
  green900: "#14532D",
  teal50: "#F0FDFA",
  teal100: "#CCFBF1",
  teal200: "#99F6E4",
  teal300: "#5EEAD4",
  teal400: "#2DD4BF",
  teal500: "#14B8A6",
  teal600: "#0D9488",
  teal700: "#0F766E",
  teal800: "#115E59",
  teal900: "#134E4A",
  blue50: "#EFF6FF",
  blue100: "#DBEAFE",
  blue200: "#BFDBFE",
  blue300: "#93C5FD",
  blue400: "#60A5FA",
  blue500: "#3B82F6",
  blue600: "#2563EB",
  blue700: "#1D4ED8",
  blue800: "#1E40AF",
  blue900: "#1E3A8A",
  indigo50: "#F0F3FF",
  indigo100: "#E0E8FF",
  indigo200: "#C8D3FE",
  indigo300: "#A6B4FC",
  indigo400: "#828DF8",
  indigo500: "#6467F2",
  indigo600: "#5048E5",
  indigo700: "#463ACB",
  indigo800: "#372FA2",
  indigo900: "#312E7F",
  purple50: "#FAF5FF",
  purple100: "#F3E8FF",
  purple200: "#E9D5FF",
  purple300: "#D8B4FE",
  purple400: "#C084FC",
  purple500: "#A855F7",
  purple600: "#9333EA",
  purple700: "#7E22CE",
  purple800: "#6B21A8",
  purple900: "#581C87",
  pink50: "#FDF2F8",
  pink100: "#FCE7F3",
  pink200: "#FBCFE8",
  pink300: "#F9A8D4",
  pink400: "#F472B6",
  pink500: "#EC4899",
  pink600: "#DB2777",
  pink700: "#BE185D",
  pink800: "#9D174D",
  pink900: "#831843",
  rose50: "#FFF1F2",
  rose100: "#FFE4E6",
  rose200: "#FECDD3",
  rose300: "#FDA4AF",
  rose400: "#FB7185",
  rose500: "#F43F5E",
  rose600: "#E11D48",
  rose700: "#BE123C",
  rose800: "#9F1239",
  rose900: "#881337",
  fuchsia50: "#FDF4FF",
  fuchsia100: "#FAE8FF",
  fuchsia200: "#F5D0FE",
  fuchsia300: "#F0ABFC",
  fuchsia400: "#E879F9",
  fuchsia500: "#D946EF",
  fuchsia600: "#C026D3",
  fuchsia700: "#A21CAF",
  fuchsia800: "#86198F",
  fuchsia900: "#701A75",
  violet50: "#F5F3FF",
  violet100: "#EDE9FE",
  violet200: "#DDD6FE",
  violet300: "#C4B5FD",
  violet400: "#A78BFA",
  violet500: "#8B5CF6",
  violet600: "#7C3AED",
  violet700: "#6D28D9",
  violet800: "#5B21B6",
  violet900: "#4C1D95",
  lightBlue50: "#F0F9FF",
  lightBlue100: "#E0F2FE",
  lightBlue200: "#BAE6FD",
  lightBlue300: "#7DD3FC",
  lightBlue400: "#38BDF8",
  lightBlue500: "#0EA5E9",
  lightBlue600: "#0284C7",
  lightBlue700: "#0369A1",
  lightBlue800: "#075985",
  lightBlue900: "#0C4A6E",
  cyan50: "#ECFEFF",
  cyan100: "#CFFAFE",
  cyan200: "#A5F3FC",
  cyan300: "#67E8F9",
  cyan400: "#22D3EE",
  cyan500: "#06B6D4",
  cyan600: "#0891B2",
  cyan700: "#0E7490",
  cyan800: "#155E75",
  cyan900: "#164E63",
  emerald50: "#ECFDF5",
  emerald100: "#D1FAE5",
  emerald200: "#A7F3D0",
  emerald300: "#6EE7B7",
  emerald400: "#34D399",
  emerald500: "#10B981",
  emerald600: "#059669",
  emerald700: "#047857",
  emerald800: "#065F46",
  emerald900: "#064E3B",
  lime50: "#F7FEE7",
  lime100: "#ECFCCB",
  lime200: "#D9F99D",
  lime300: "#BEF264",
  lime400: "#A3E635",
  lime500: "#84CC16",
  lime600: "#65A30D",
  lime700: "#4D7C0F",
  lime800: "#3F6212",
  lime900: "#365314",
  amber50: "#FFFBEB",
  amber100: "#FEF3C7",
  amber200: "#FDE68A",
  amber300: "#FCD34D",
  amber400: "#FBBF24",
  amber500: "#F59E0B",
  amber600: "#D97706",
  amber700: "#B45309",
  amber800: "#92400E",
  amber900: "#78350F",
  warmGray50: "#FAFAF9",
  warmGray100: "#F5F5F4",
  warmGray200: "#E7E5E4",
  warmGray300: "#D6D3D1",
  warmGray400: "#A8A29E",
  warmGray500: "#78716C",
  warmGray600: "#57534E",
  warmGray700: "#44403C",
  warmGray800: "#292524",
  warmGray900: "#1C1917",
  trueGray50: "#FAFAFA",
  trueGray100: "#F5F5F5",
  trueGray200: "#E5E5E5",
  trueGray300: "#D4D4D4",
  trueGray400: "#A3A3A3",
  trueGray500: "#737373",
  trueGray600: "#525252",
  trueGray700: "#404040",
  trueGray800: "#262626",
  trueGray900: "#171717",
  coolGray50: "#F9FAFB",
  coolGray100: "#F3F4F6",
  coolGray200: "#E5E7EB",
  coolGray300: "#D1D5DB",
  coolGray400: "#9CA3AF",
  coolGray500: "#6B7280",
  coolGray600: "#4B5563",
  coolGray700: "#374151",
  coolGray800: "#1F2937",
  coolGray900: "#111827",
  blueGray50: "#F8FAFC",
  blueGray100: "#F1F5F9",
  blueGray200: "#E2E8F0",
  blueGray300: "#CBD5E1",
  blueGray400: "#94A3B8",
  blueGray500: "#64748B",
  blueGray600: "#475569",
  blueGray700: "#334155",
  blueGray800: "#1E293B",
  blueGray900: "#0F172A",
};

colors.primary = colors.oldBlack;
colors.text = colors.oldBlack;
colors.positive = colors.green;
colors.warning = colors.oldYellow;
colors.negative = colors.oldRed;

export { colors };

export const typography = {
  fontSize_xxl: "64px",
  fontSize_xl: "36px",
  fontSize_l: "28px",
  fontSize: "24px",
  fontSize_s: "20px",
  fontSize_xs: "18px",
  fontSize_xxs: "16px",
  fontSize_xxxs: "14px",
  fontSize_xxxxs: "12px",
  fontSize_xxxxxs: "10px",
  fontSize_xxxxxxs: "8px",

  fontFamily: `Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
  fontFamily_Roboto: `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
  fontFamily_Inter: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
};

// Borders
export const borders = {
  borderRadius: "10px",

  boxShadow: "0px 1px 2px rgba(31, 41, 55, 0.08)",
  boxShadow_m:
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1)",
  boxShadow_l:
    "0px 10px 15px rgba(31, 41, 55, 0.1), 0px 0px 6px rgba(31, 41, 55, 0.05)",
  boxShadow_xl:
    "0px 20px 25px rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04)",
  boxShadow_xxl: "0px 25px 50px rgba(31, 41, 55, 0.25)",
  boxShadow_inner: "inset 0px 0px 4px rgba(31, 41, 55, 0.15)",
};

// Easings
export const easings = {
  default: "ease-in-out",
};

// Indexes
export const indexes = {
  // something: 20,
  // somethingElse: 10,
};

// Breakpoints
export const breakpoints = {
  xxlarge: "1920",
  xlarge: "1600",
  large: "1024",
  medium: "760",
  small: "480",
};

// Misc. Variables
// export const something = 50;

// The Whole Theme
const theme = {
  colors,
  easings,
  indexes,
  ...borders,
  ...typography,
};

export default theme;
