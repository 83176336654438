import React from "react";
import styled from "styled-components";

const StartIcon = () => {
  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 0C7.16 0 0 7.16 0 16C0 24.84 7.16 32 16 32C24.84 32 32 24.84 32 16C32 7.16 24.84 0 16 0ZM22.08 16.96L14.36 22.2C13.36 22.88 12 22.16 12 20.96V10.92C12 9.76 13.28 9.04 14.28 9.64L22.04 14.44C22.96 15 23 16.32 22.08 16.96Z" />
    </Svg>
  );
};

const Svg = styled.svg`
  transition: transform 0.2s;

  path {
    transition: fill 0.2s;
    fill: ${(props) => props.theme.colors.darkSlate};

    @keyframes startColor {
      0% {
        fill: ${(props) => props.theme.colors.darkSlate};
      }
      16.67% {
        fill: ${(props) => props.theme.colors.purple};
      }
      33.33% {
        fill: ${(props) => props.theme.colors.purple};
      }
      50% {
        fill: ${(props) => props.theme.colors.green};
      }
      66.67% {
        fill: ${(props) => props.theme.colors.yellow};
      }
      83.33% {
        fill: ${(props) => props.theme.colors.teal};
      }
      100% {
        fill: ${(props) => props.theme.colors.darkSlate};
      }
    }
  }
`;

export default StartIcon;
