import React from "react";
import styled from "styled-components";

const Budget = ({
  minimal,
  sales,
  tracked = 0,
  budget = 0,
  low = 0,
  high = 0,
  contracted = 0,
  salesQuota = 0,
  ...rest
}) => {
  let total = 0;
  let lowPosition = 0;

  const originalBudget = budget;

  // used for the percentage text
  const progressRounded = Math.round((tracked / budget) * 100);

  if (salesQuota) {
    high = salesQuota;
  }

  if (tracked >= budget) {
    budget = tracked;
  }

  // used for the bar
  //const progress = (tracked / budget) * 100;

  if (budget < high) {
    total = (budget / high) * 100;
    lowPosition = (low / high) * 100;
  } else if (budget > high && !sales) {
    total = (high / budget) * 100;
    lowPosition = (low / budget) * 100;
  } else if (budget > high && sales) {
    total = 100;
  }

  if (sales && contracted > 0) {
    contracted = (contracted / budget) * 100;
  }

  return (
    <Container {...rest} sales={sales}>
      {!minimal && (
        <TrackedPercentage>
          {sales
            ? `$${tracked.toLocaleString()}`
            : // show "less than 1" if it rounded to 0 but there's tracked time
              `${!progressRounded && tracked ? "<1" : progressRounded}%`}
        </TrackedPercentage>
      )}
      <ProgressContainer minimal={minimal} sales={sales}>
        <Progress
          total={total}
          over={
            ((budget > high || tracked > originalBudget) && !sales) ||
            (tracked < high && sales)
          }
          sales={sales}
          contracted={contracted}
        >
          {tracked && budget ? (
            <Tracked progress={(tracked / budget) * 100}></Tracked>
          ) : null}
          {contracted ? <Contracted contracted={contracted} /> : null}
        </Progress>
        {low ? <Low position={lowPosition} /> : null}
        <High
          over={budget > high || (budget === 0 && high === 0)}
          total={sales && budget > high ? (high / budget) * 100 : total}
        />
      </ProgressContainer>
      {sales && <SalesQuota>${salesQuota.toLocaleString()}</SalesQuota>}
    </Container>
  );
};

// const Container = styled.div`
//   display: flex;
//   align-items: center;
//   transition: ${(props) =>
//     props.sales ? "1s ease-in-out" : ".5s ease-in-out"};

//   * {
//     transition: ${(props) =>
//       props.sales ? "1s ease-in-out" : ".5s ease-in-out"};
//   }
// `;

const Container = styled.div`
  display: flex;
  gap: 20px;
  min-height: 100%;
`;

const Tracked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 2;
  background-color: #7bc1a8;
  width: ${(props) => (props.progress ? `${props.progress}%` : "0")};
`;

const Contracted = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  background: repeating-linear-gradient(
    135deg,
    #cdf0d6,
    #cdf0d6 20px,
    #7bc1a8 20px,
    #7bc1a8 40px
  );
  width: ${(props) => (props.contracted ? `${props.contracted}%` : "0")};
`;

const Progress = styled.div`
  position: relative;
  background-color: ${(props) => (props.over ? "#F7D6E1" : "#cdf0d6")};
  width: ${(props) =>
    (props.total > 0 && !props.over) || (props.sales && props.over)
      ? `${props.total}%`
      : "100%"};
  ${Tracked} {
    background-color: ${(props) => (props.over ? "#DF5757" : "#7bc1a8")};
  }
`;

const TrackedPercentage = styled.p`
  font-weight: 600;
  margin: 0px;
  margin-right: 20px;
  min-width: 70px;
`;

const SalesQuota = styled.p`
  font-weight: 600;
  margin: 0px;
  margin-left: 20px;
  min-width: 70px;
`;

const Low = styled.div`
  background-color: black;
  position: absolute;
  z-index: 3;
  width: 2px;
  transform: translateY(-50%);
  top: 50%;
  left: ${(props) =>
    props.position > 0 ? `calc(${props.position}% - 2px)` : "100%"};
`;

const High = styled.div`
  background-color: black;
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  left: ${(props) => (props.over ? `${props.total}%` : "100%")};
  width: 2px;
`;

const ProgressContainer = styled.div`
  width: ${(props) =>
    props.minimal ? "270px" : props.sales ? "1000px" : "500px"};
  position: relative;

  ${Progress} {
    height: ${(props) => (props.minimal ? "30px" : "40px")};
  }

  ${Low} {
    height: ${(props) => (props.minimal ? "50px" : "65px")};
  }

  ${High} {
    height: ${(props) => (props.minimal ? "50px" : "65px")};
  }
`;

export default Budget;
