import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { format, intervalToDuration } from "date-fns";

import { useActiveTimer } from "../../context/activeTimerContext";
import { useNotifications } from "../../context/notificationsContext";
import { useAuth } from "../../context/authContext";

import {
  createExtraTimeOnApi,
  updateExtraTimeOnApi,
} from "../../views/manage/utils/api";

import StopTimerView from "./stopTimerView";
import ExtraTime from "./extraTime";

const StopTimerModal = () => {
  const { openAlertPopup, setLoading } = useNotifications();
  const locationObj = useLocation();
  const location = locationObj.pathname;

  const { isAdmin, user } = useAuth();

  const {
    isExtraTimeModalOpen,
    isStopModalOpen,
    closeStopModal,
    closeExtraTimeModal,
    openStopModal,
    stopModalText,
    stopActiveTimer,
    currentTimerStart,
    currentProjectId,
    currentProjectBudget,
    currentProjectWeekHoursSpent,
    currentProjectPhases,
    currentProjectManager,
    currentProjectName,
    currentExistingExtraTime,
  } = useActiveTimer();

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [duration, setDuration] = useState("");
  const [timeLeft, setTimeLeft] = useState("");
  const [timeOver, setTimeOver] = useState("");
  const [leftOverMins, setLeftOverMins] = useState("");
  const [over, setOver] = useState(false);

  const [extraProjectId, setExtraProjectId] = useState(null);
  const [extraProjectName, setExtraProjectName] = useState(null);
  const [extraProjectBudget, setExtraProjectBudget] = useState(null);
  const [extraProjectSpent, setExtraProjectSpent] = useState(null);
  const [extraProjectPhases, setExtraProjectPhases] = useState(null);
  const [extraProjectManager, setExtraProjectManager] = useState(null);
  const [extraProjectExistingTime, setExtraProjectExistingTime] = useState(
    null,
  );
  const [extraProjectLeftOverMins, setExtraProjectLeftOverMins] = useState(
    null,
  );
  const [nextProject, setNextProject] = useState(null);

  useEffect(() => {
    const queryString = locationObj.search.replace("?", "");

    if (queryString.includes(`stop-modal`)) {
      const queryArray = queryString.split("&");
      let newNextProject = null;

      queryArray.forEach((param) => {
        const paramArray = param.split("=");

        if (paramArray[0] === "nextProject") {
          newNextProject = paramArray[1];
        }
      });
      setNextProject(newNextProject);
      openStopModal(
        newNextProject ? "Let's wrap up the last project first." : undefined,
      );
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (currentTimerStart && isStopModalOpen) {
      const newStart = new Date(currentTimerStart);
      const newEnd = new Date();

      const interval = {
        start: newStart,
        end: newEnd,
      };

      const newDuration = intervalToDuration(interval);
      const durationInMin = newDuration.hours * 60 + newDuration.minutes;

      const leftOverMin =
        currentProjectBudget * 60 -
        (durationInMin + currentProjectWeekHoursSpent * 60);

      let leftOverDuration;

      // If you are over the weekly budget
      if (leftOverMin < 0) {
        leftOverDuration = {
          hours: Math.ceil(leftOverMin / 60) * -1,
          minutes: Math.ceil(leftOverMin % 60) * -1,
        };
        setOver(true);

        setTimeOver(
          `-${leftOverDuration.hours}:${("0" + leftOverDuration.minutes).slice(
            -2,
          )}`,
        );
      } else {
        leftOverDuration = {
          hours: Math.floor(leftOverMin / 60),
          minutes: Math.floor(leftOverMin % 60),
        };
      }

      let minutes = leftOverMin / 60;

      if (minutes < 0) {
        minutes *= -1;
      }

      setLeftOverMins(minutes.toFixed(2));
      setStartTime(format(new Date(currentTimerStart), "h:mmaaaaa'm'"));
      setEndTime(format(new Date(), "h:mmaaaaa'm'"));
      setDuration(
        `${newDuration.hours}:${("0" + newDuration.minutes).slice(-2)}`,
      );

      if (currentProjectBudget) {
        setTimeLeft(
          `${leftOverMin < 0 ? "-" : ""}${leftOverDuration.hours}:${(
            "0" + leftOverDuration.minutes
          ).slice(-2)}`,
        );
      } else {
        setTimeLeft("N/A");
      }
    }
  }, [currentTimerStart, isStopModalOpen, currentProjectWeekHoursSpent]); //eslint-disable-line

  const submitStop = (notes) => {
    setExtraProjectId(currentProjectId);
    setExtraProjectName(currentProjectName);
    setExtraProjectBudget(currentProjectBudget);
    setExtraProjectSpent(currentProjectWeekHoursSpent);
    setExtraProjectManager(currentProjectManager);
    setExtraProjectExistingTime(currentExistingExtraTime);
    setExtraProjectLeftOverMins(leftOverMins);
    setLoading(true);

    if (currentProjectPhases) {
      const projectPhases = [...currentProjectPhases];
      projectPhases.sort((a, b) => {
        const aName = a.name.toUpperCase();
        const bName = b.name.toUpperCase();

        return aName > bName ? 1 : aName < bName ? -1 : 0;
      });
      setExtraProjectPhases(projectPhases);
    }
    // If a next project was in the query
    if (nextProject) {
      stopActiveTimer(notes, nextProject);
      // If we are on the project page, but not for the current project
      // stop this timer and start the new project timer
    } else if (
      location.includes(`projects`) &&
      !location.includes(currentProjectId)
    ) {
      const pathArray = location.split("/");
      const projectsIndex = pathArray.findIndex(
        (partial) => partial === "projects",
      );
      const newProjectId = pathArray[projectsIndex + 1];

      stopActiveTimer(notes, newProjectId, over);
    } else {
      // If we are anywhere else on the site, just stop the timer
      stopActiveTimer(notes, null, over);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Extra time submission
  |--------------------------------------------------------------------------
  */
  const submitExtraTime = async (notes) => {
    // If there is an existing extra time with the same memberId-projectId pair, then update the existing extra time
    if (extraProjectExistingTime) {
      const tempExistingExtraTime = { ...extraProjectExistingTime };
      tempExistingExtraTime.id = tempExistingExtraTime._id;
      tempExistingExtraTime.spent = extraProjectSpent.toFixed(2);
      tempExistingExtraTime.overage = extraProjectLeftOverMins;
      tempExistingExtraTime.notes = notes;
      tempExistingExtraTime.date = new Date();

      try {
        await await updateExtraTimeOnApi(tempExistingExtraTime);

        openAlertPopup(
          "Success",
          "Your existing extra time has been updated with this entry.",
          true,
        );
      } catch (err) {
        console.error(err);
        openAlertPopup(
          "Error",
          "Something went wrong and your extra time couldn't be updated.",
          true,
        );
      }
    } else {
      // If there is no existing extra time, create a new one

      const data = {
        member: user._id,
        memberName: user.handle,
        project: extraProjectId,
        projectName: extraProjectName,
        budget: extraProjectBudget,
        spent: extraProjectSpent.toFixed(2),
        overage: extraProjectLeftOverMins,
        projectManager: extraProjectManager,
        notes,
      };

      try {
        await createExtraTimeOnApi(data);

        openAlertPopup("Success", "Your extra time has been saved.", true);
      } catch (err) {
        console.error(err);
        openAlertPopup(
          "Error",
          "Something went wrong and your extra time couldn't be saved.",
          true,
        );
      }
    }

    closeExtraTimeModal();
  };

  return (
    <>
      <StopTimerView
        isOpen={isStopModalOpen}
        canOverride={isAdmin}
        overrideClose={() => closeStopModal()}
        closeAndRedirect={() => closeStopModal(true)}
        headerText={stopModalText}
        submit={(notes) => submitStop(notes)}
        startTime={startTime}
        endTime={endTime}
        duration={duration}
        timeLeft={timeLeft}
        over={over}
      />
      <ExtraTime
        isOpen={isExtraTimeModalOpen}
        canOverride={isAdmin}
        overrideClose={() => closeExtraTimeModal()}
        closeAndRedirect={() => closeExtraTimeModal(true)}
        submit={(notes) => submitExtraTime(notes)}
        timeOver={timeOver}
        currentExistingExtraTime={currentExistingExtraTime}
        phases={extraProjectPhases}
      />
    </>
  );
};

export default StopTimerModal;
