import React from "react";
import styled from "styled-components";

const Checkbox = ({ label, checked, onChange, disabled, ...rest }) => {
  return (
    <CheckboxContainer disabled={disabled} {...rest}>
      {label}
      <CheckInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      ></CheckInput>
      <CheckMark className="checkmark"></CheckMark>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.label`
  position: relative;
  display: inline-flex;
  margin-bottom: 30px;
  padding-left: 35px;
  line-height: 1.7;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  opacity: ${(props) => (props.disabled ? ".4" : "1")};

  input:checked ~ .checkmark {
    background-color: #0079ff;

    &::after {
      display: block;
    }
  }

  &:hover {
    .checkmark {
      background-color: #dae1f5;
    }
  }
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid #dae1f5;
  border-radius: 3px;
  transition: 0.2s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default Checkbox;
