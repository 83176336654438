import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getTeamsFromApi } from "../../../../utils/api";

import CreateIcon from "../../../../components/icons/CreateIcon";
import Editable from "../../../../components/Editable";
import Table from "../../../../components/NewTable/index";
import PopoutMenu from "../../../projects/components/PopoutMenu";
import {
  TableContainer,
  CreateInput,
} from "../../../projects/components/ProjectTable";
import {
  CreateLabel,
  CreateValue,
} from "../../../projects/components/ProjectTable";
import {
  TableCell,
  SearchInput,
  Checkbox,
  AddBtn,
} from "../../../../components/NewTable/components";

const tiers = [
  {
    label: "Staff",
    value: "staff",
  },
  {
    label: "Director",
    value: "director",
  },
  {
    label: "Partner",
    value: "partner",
  },
];

const types = [
  {
    label: "Retainer",
    value: "retainer",
  },
  {
    label: "Project",
    value: "project",
  },
  {
    label: "Rush",
    value: "rush",
  },
];

const Rates = ({ rates, createRate, editRate, deleteRate }) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    getTeams();
  }, []);

  /*
  |--------------------------------------------------------------------------
  | Get the list of teams from the api
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    try {
      const teamsFromApi = await getTeamsFromApi();

      if (teamsFromApi?.length) {
        const teamOptions = teamsFromApi.map((team) => {
          return {
            label: team.name,
            value: team._id,
          };
        });
        setTeams(teamOptions);
      }
    } catch (error) {
      console.error("error getting teams", error);
    }
  };

  return (
    <Container>
      <RatesTitle>Rates</RatesTitle>
      <RatesContainer>
        <TableContainer style={{ margin: "0px" }}>
          <Table
            // so the scrolling is handled by the whole viewport, rather than just the table
            scrollable={false}
            noPadding
            headers={[
              {
                name: "Team",
                accessor: "team",
              },
              {
                name: "Tier",
                accessor: "tier",
              },
              {
                name: "Type",
                accessor: "Type",
              },
              {
                name: "Amount",
                accessor: "amount",
              },
              {
                name: "Actions",
              },
            ]}
            entries={
              rates?.length
                ? rates.map((rate) => {
                    return {
                      ...rate,
                      row: () => (
                        <>
                          <TableCell>
                            <Editable
                              id={`${rate._id}-team`}
                              options={teams}
                              defaultValue={
                                teams && rate.team
                                  ? teams.find(
                                      (team) => rate.team === team.value,
                                    )
                                  : null
                              }
                              onSave={(value) =>
                                editRate(rate._id, { ...rate, team: value })
                              }
                            >
                              {rate.team && teams ? (
                                teams.find((team) => rate.team === team.value)
                                  .label
                              ) : (
                                <>&nbsp;</>
                              )}
                            </Editable>
                          </TableCell>

                          <TableCell>
                            <Editable
                              id={`${rate._id}-tier`}
                              options={tiers}
                              defaultValue={
                                tiers && rate.tier
                                  ? tiers.find(
                                      (tier) => rate.tier === tier.value,
                                    )
                                  : null
                              }
                              onSave={(value) =>
                                editRate(rate._id, { ...rate, tier: value })
                              }
                            >
                              {rate.tier || "N/A"}
                            </Editable>
                          </TableCell>

                          <TableCell>
                            <Editable
                              id={`${rate._id}-type`}
                              options={types}
                              defaultValue={
                                types && rate.type
                                  ? types.find(
                                      (type) => rate.type === type.value,
                                    )
                                  : null
                              }
                              onSave={(value) =>
                                editRate(rate._id, { ...rate, type: value })
                              }
                            >
                              {rate.type || "N/A"}
                            </Editable>
                          </TableCell>

                          <TableCell>
                            <Editable
                              id={`${rate._id}-amount`}
                              defaultValue={rate.amount}
                              onSave={(value) =>
                                editRate(rate._id, { ...rate, amount: value })
                              }
                            >
                              ${rate.amount || "0"}
                            </Editable>
                          </TableCell>

                          <TableCell data-sticky-col="right">
                            <PopoutMenu
                              actions={[
                                {
                                  text: "Duplicate",
                                  action: () =>
                                    createRate({
                                      ...rate,
                                    }),
                                },
                                {
                                  text: "Delete",
                                  action: () => deleteRate(rate._id),
                                  danger: true,
                                },
                              ]}
                            />
                          </TableCell>
                        </>
                      ),
                    };
                  })
                : []
            }
            handleCreate={false} // ? so the table doesn't show the add btn
            createRow={
              <CreateRow
                onCreate={createRate}
                teams={teams}
                tiers={tiers}
                types={types}
              />
            }
          />
        </TableContainer>
      </RatesContainer>
    </Container>
  );
};

const CreateRow = ({ teams, tiers, types, onCreate }) => {
  const defaultInputValues = {
    team: null,
    tier: null,
    type: null,
    amount: 0,
  };

  const [createInputs, setCreateInputs] = useState(defaultInputValues);
  const [isLoading, setIsLoading] = useState(false);

  const updateCreateInputs = (newValue, key) => {
    const newInputs = { ...createInputs };
    newInputs[key] = newValue;

    if (key === "rate") {
      newInputs[key] = parseInt(newValue);
    }
    setCreateInputs(newInputs);
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      if (onCreate) {
        await onCreate(createInputs);
      }
    } catch (error) {}

    // reset the input field values
    setCreateInputs(defaultInputValues);

    setIsLoading(false);
  };

  return (
    <>
      <TableCell full style={{ width: "120px", paddingRight: "20px" }}>
        {teams ? (
          <Editable
            onSave={(value) => updateCreateInputs(value, "team")}
            options={teams}
            defaultValue={
              createInputs.team
                ? teams.find((team) => createInputs.team === team.value)
                : null
            }
            dropdownProps={{
              placeholder: "Team",
            }}
          >
            {createInputs.team ? (
              <CreateValue>
                {teams.find((team) => createInputs.team === team.value).label}
              </CreateValue>
            ) : (
              <CreateLabel>Team</CreateLabel>
            )}
          </Editable>
        ) : null}
      </TableCell>

      <TableCell full style={{ width: "120px" }}>
        {tiers ? (
          <Editable
            onSave={(value) => updateCreateInputs(value, "tier")}
            options={tiers}
            defaultValue={
              createInputs.tier
                ? tiers.find((tier) => createInputs.tier === tier.value)
                : null
            }
            dropdownProps={{
              placeholder: "tier",
            }}
          >
            {createInputs.tier ? (
              <CreateValue>
                {tiers.find((tier) => createInputs.tier === tier.value).label}
              </CreateValue>
            ) : (
              <CreateLabel>Tier</CreateLabel>
            )}
          </Editable>
        ) : null}
      </TableCell>

      <TableCell full style={{ width: "120px" }}>
        {types ? (
          <Editable
            onSave={(value) => updateCreateInputs(value, "type")}
            options={types}
            defaultValue={
              createInputs.type
                ? types.find((type) => createInputs.type === type.value)
                : null
            }
            dropdownProps={{
              placeholder: "type",
            }}
          >
            {createInputs.type ? (
              <CreateValue>
                {types.find((type) => createInputs.type === type.value).label}
              </CreateValue>
            ) : (
              <CreateLabel>Type</CreateLabel>
            )}
          </Editable>
        ) : null}
      </TableCell>

      <TableCell style={{ width: "120px" }}>
        <CreateInput
          type="number"
          placeholder="Amount"
          value={createInputs.amount}
          onChange={(e) => updateCreateInputs(e.currentTarget.value, "amount")}
        />
      </TableCell>

      <TableCell>
        <AddBtn
          onClick={handleCreate}
          disabled={
            isLoading ||
            !createInputs.team ||
            !createInputs.tier ||
            !createInputs.type ||
            !createInputs.amount
          }
          style={{ display: "flex", margin: "0 auto" }}
        >
          <CreateIcon />
        </AddBtn>
      </TableCell>
    </>
  );
};

const Container = styled.div``;

const RatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RatesTitle = styled.h3`
  font-size: 24px;
  color: ${(props) => props.theme.colors.oldBlack};
  font-weight: 500;
  margin-bottom: 20px;
`;

export default Rates;
