import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { darken } from "polished";

import { useNotifications } from "../../context/notificationsContext";
import {
  getTaskGeneratorFromApi,
  updateTaskGeneratorOnApi,
  deleteTaskGeneratorOnApi,
} from "./utils/api";
import { getAllProjectsFromApi, getMembersFromApi } from "../manage/utils/api";
import { useHistory, useParams } from "react-router-dom";

import { respondTo } from "../../styles/styleHelpers";

import { Field, Label, Input, Textarea } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";
import Button from "../../components/buttons/Button";
import Checkbox from "./components/Checkbox";
import ProfileImage from "../../components/ProfileImage";

const TaskGenerator = () => {
  const { id } = useParams();
  const history = useHistory();

  const { openAlertPopup } = useNotifications();
  const [projects, setProjects] = useState(null);
  const [members, setMembers] = useState(null);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [repeatEvery, setRepeatEvery] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [permanent, setPermanent] = useState(false);
  const [memberArray, setMemberArray] = useState([]);
  const [weekArray, setWeekArray] = useState([]);
  const [linkText, setLinkText] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);

  const weekdays = ["M", "TU", "W", "TH", "F", "SA", "SU"];

  /*
  |--------------------------------------------------------------------------
  | Get All Tasks
  |--------------------------------------------------------------------------
  */
  async function getTaskGenerators() {
    const result = await getTaskGeneratorFromApi(id);

    if (result) {
      setTitle(result.title);
      setDescription(result.description);
      setProjectId(result.projectId);
      setWeekArray(result.repeatOn);
      setMemberArray(result.members);
      setRepeatEvery(result.repeatEvery);
      setFrequency(result.frequency);
      setStartDate(result.startDate);
      setEndDate(result.endDate);
      setPermanent(result.permanent);
      setLinkText(result?.link?.text);
      setLinkUrl(result?.link?.url);
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  async function getProjects() {
    const result = await getAllProjectsFromApi();
    setProjects(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Members
  |--------------------------------------------------------------------------
  */
  async function getMembers() {
    const result = await getMembersFromApi();
    setMembers(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Check to see if a member is added to the project
  |--------------------------------------------------------------------------
  */
  const toggleMember = (id) => {
    const tempMemberArray = [...memberArray];

    if (tempMemberArray && tempMemberArray.includes(id)) {
      const index = tempMemberArray.indexOf(id);

      if (index !== -1) {
        tempMemberArray.splice(index, 1);
      }
    } else {
      tempMemberArray.push(id);
    }

    setMemberArray(tempMemberArray);
  };

  /*
  |--------------------------------------------------------------------------
  | Check to see if a week is added to the project
  |--------------------------------------------------------------------------
  */
  const toggleWeek = (id) => {
    const tempWeekArray = [...weekArray];

    if (tempWeekArray && tempWeekArray.includes(id)) {
      const index = tempWeekArray.indexOf(id);

      if (index !== -1) {
        tempWeekArray.splice(index, 1);
      }
    } else {
      tempWeekArray.push(id);
    }

    setWeekArray(tempWeekArray);
  };

  /*
  |--------------------------------------------------------------------------
  | Update task generator
  |--------------------------------------------------------------------------
  */
  async function updateTaskGenerator() {
    try {
      const data = {
        title,
        projectId,
        members: memberArray,
        description,
        frequency,
        repeatOn: weekArray,
        repeatEvery,
        weekArray,
        startDate,
        endDate,
        permanent,
        link: {
          text: linkText,
          url: linkUrl,
        },
      };

      const result = await updateTaskGeneratorOnApi(id, data);

      if (result) {
        openAlertPopup("Success", "Task generator updated successfully!", true);
      }
      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task generator update failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Delete task generator on api
  |--------------------------------------------------------------------------
  */
  async function deleteTaskGenerator() {
    try {
      const result = await deleteTaskGeneratorOnApi(id);

      if (result) {
        openAlertPopup("Success", "Task generator deleted successfully!", true);
        history.push(`/tasks/manage`);
      }

      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task generator delete failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get projects and members for the update task form
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getProjects();
    getMembers();
    getTaskGenerators();
  }, []); //eslint-disable-line

  return (
    <TasksContainer>
      <Header>
        <HeaderLeft>
          <MainHeader>Edit Task</MainHeader>
        </HeaderLeft>
        <SimpleLink as={Link} to="/tasks/manage">
          View all tasks
        </SimpleLink>
      </Header>
      <Task>
        <TaskLeft>
          <FlexRow>
            <StyledField>
              <Label>Frequency</Label>
              {frequency && (
                <StyledSelectDropdown
                  onChange={(e) => setFrequency(e.value)}
                  defaultValue={{
                    label: frequency,
                    value: frequency,
                  }}
                  placeholder="Select Frequency"
                  options={[
                    {
                      label: "Day",
                      value: "Day",
                    },
                    {
                      label: "Week",
                      value: "Week",
                    },
                    {
                      label: "Month",
                      value: "Month",
                    },
                    {
                      label: "Year",
                      value: "Year",
                    },
                  ]}
                ></StyledSelectDropdown>
              )}
            </StyledField>
            <StyledField>
              <Label>
                Repeat every {repeatEvery}{" "}
                {frequency && frequency.toLowerCase()}
                {repeatEvery && repeatEvery >= 2 ? "s" : ""}
              </Label>
              <Input
                type="number"
                defaultValue={repeatEvery}
                onChange={(e) => setRepeatEvery(e.target.value)}
              />
            </StyledField>
          </FlexRow>

          <Field>
            <Label>Repeat on</Label>

            <Options>
              {weekdays &&
                weekdays.map((item) => {
                  return (
                    <Option
                      onClick={() => toggleWeek(item)}
                      selected={weekArray && weekArray.includes(item)}
                    >
                      {item}
                    </Option>
                  );
                })}
            </Options>
          </Field>

          <FlexRow>
            <StyledField>
              <Label>Link Text</Label>
              <Input
                type="text"
                defaultValue={linkText}
                onChange={(e) => setLinkText(e.target.value)}
              />
            </StyledField>
            <StyledField>
              <Label>Link Url</Label>
              <Input
                type="text"
                defaultValue={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
              />
            </StyledField>
          </FlexRow>

          <FlexRow>
            <StyledField>
              <Label>Start Date</Label>
              <Input
                type="date"
                defaultValue={startDate && startDate.substring(0, 10)}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </StyledField>
            <StyledField>
              <Label>End Date</Label>
              <Input
                type="date"
                defaultValue={endDate && endDate.substring(0, 10)}
                disabled={permanent}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </StyledField>
          </FlexRow>

          <CheckboxContainer>
            <Checkbox
              label="No End Date"
              checked={permanent}
              onChange={() => setPermanent(!permanent)}
            ></Checkbox>
          </CheckboxContainer>
        </TaskLeft>

        <TaskRight>
          <Field>
            <Label>Name</Label>
            <Input
              type="text"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Field>

          <Field>
            <Label>Project</Label>
            {projects && projectId && (
              <StyledSelectDropdown
                onChange={(e) => setProjectId(e.value)}
                placeholder="Select Project"
                defaultValue={projects
                  .filter((item) => {
                    return item._id === projectId;
                  })
                  .map((item) => {
                    return {
                      label: item.name,
                      value: item._id,
                    };
                  })}
                options={projects.map((item) => {
                  return {
                    label: item.name,
                    value: item._id,
                  };
                })}
              ></StyledSelectDropdown>
            )}
          </Field>

          <Field>
            <Label>Assigned To</Label>
            <Options>
              {members &&
                members.map((item) => {
                  return (
                    <StyledProfileImage
                      title={item.name}
                      onClick={() => toggleMember(item._id)}
                      selected={memberArray.includes(item._id)}
                      handle={item.handle}
                      name={item.name}
                    ></StyledProfileImage>
                  );
                })}
            </Options>
          </Field>

          <Field>
            <Label>Task Details</Label>
            <Textarea
              style={{ height: "130px" }}
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Field>

          <ButtonRow>
            <Delete onClick={() => deleteTaskGenerator()}>Delete</Delete>

            <Save
              disabled={
                !(
                  title &&
                  projectId &&
                  memberArray &&
                  memberArray.length &&
                  description &&
                  frequency &&
                  weekArray &&
                  weekArray.length &&
                  repeatEvery &&
                  startDate &&
                  (endDate || permanent) &&
                  linkText &&
                  linkUrl
                )
              }
              onClick={() => updateTaskGenerator()}
            >
              Save
            </Save>
          </ButtonRow>
        </TaskRight>
      </Task>
    </TasksContainer>
  );
};

const TasksContainer = styled.div`
  padding: 53px 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MainHeader = styled.h2`
  color: ${(props) => props.theme.colors.darkSlate};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0px;
  margin-right: 170px;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const Task = styled.div`
  padding: 40px 0px;
  display: flex;
  justify-content: space-between;
  width: 75%;
`;

const TaskLeft = styled.div`
  width: 45%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 45%;
  }
`;

const StyledField = styled(Field)`
  width: 45%;

  input {
    width: 100%;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 20px;

  * {
    font-size: 14px;
  }
`;

const TaskRight = styled.div`
  width: 45%;

  input,
  textarea {
    width: 100%;
  }
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? darken(0.3, "#7BC1A8") : "#7BC1A8"};
  color: white;
  font-weight: bold;
  line-height: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  height: 35px;
  width: 35px;
  font-size: 12px;
  margin: 5px;

  &:hover {
    opacity: ${(props) => (props.selected ? "1" : ".6")};
  }
`;

const StyledProfileImage = styled(ProfileImage)`
  cursor: pointer;
  width: 37px;
  height: 37px;
  margin: 5px;
  border: ${(props) =>
    props.selected ? "3px solid #0079FF" : "3px solid white"};

  * {
    font-size: 14px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;

const Delete = styled(Button)`
  min-width: 140px;
  width: 140px;
  background-color: #df5757;
  border-color: #df5757;
  margin-right: 20px;

  &::after {
    background-color: ${darken(0.1, "#df5757")};
  }
`;

const Save = styled(Button)`
  min-width: 140px;
  width: 140px;
`;

export default TaskGenerator;
