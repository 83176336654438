import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { darken } from "polished";

import { useNotifications } from "../../context/notificationsContext";
import {
  getTaskTemplateFromApi,
  updateTaskTemplateOnApi,
  deleteTaskTemplateOnApi,
} from "./utils/api";
import { getAllRolesFromApi } from "../manage/utils/api";
import { useHistory, useParams } from "react-router-dom";

import { respondTo } from "../../styles/styleHelpers";

import { Field, Label, Input, Textarea } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";
import Button from "../../components/buttons/Button";

const TaskTemplate = () => {
  const { id } = useParams();
  const history = useHistory();

  const { openAlertPopup } = useNotifications();

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [hoursToComplete, setHoursToComplete] = useState(null);
  const [role, setRole] = useState(null);
  const [roles, setRoles] = useState(null);

  const [linkText, setLinkText] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Get All Task Templates
  |--------------------------------------------------------------------------
  */
  async function getTaskTemplates() {
    const result = await getTaskTemplateFromApi(id);

    if (result) {
      setTitle(result.title);
      setDescription(result.description);
      setRole(result.role);
      setLinkText(result.link.text);
      setLinkUrl(result.link.url);
      setHoursToComplete(result.hoursToComplete);
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Roles
  |--------------------------------------------------------------------------
  */
  async function getRoles() {
    const result = await getAllRolesFromApi();
    setRoles(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Update task template
  |--------------------------------------------------------------------------
  */
  async function updateTaskTemplate() {
    try {
      const data = {
        title,
        description,
        hoursToComplete,
        role,
        link: {
          text: linkText,
          url: linkUrl,
        },
      };

      const result = await updateTaskTemplateOnApi(id, data);

      if (result) {
        openAlertPopup("Success", "Task template updated successfully!", true);
      }
      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task template update failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Delete task template on api
  |--------------------------------------------------------------------------
  */
  async function deleteTaskTemplate() {
    try {
      const result = await deleteTaskTemplateOnApi(id);

      if (result) {
        openAlertPopup("Success", "Task template deleted successfully!", true);
        history.push(`/tasks/manage`);
      }

      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task template delete failed!");
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get projects and members for the update task form
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getRoles();
    getTaskTemplates();
  }, []); //eslint-disable-line

  return (
    <TasksContainer>
      <Header>
        <HeaderLeft>
          <MainHeader>Edit Task</MainHeader>
        </HeaderLeft>
        <SimpleLink as={Link} to="/tasks/manage">
          View all tasks
        </SimpleLink>
      </Header>
      <Task>
        <TaskLeft>
          <FlexRow>
            <StyledField>
              <Label>Role</Label>
              {roles && role && (
                <StyledSelectDropdown
                  onChange={(e) => setRole(e.value)}
                  placeholder="Select Role"
                  defaultValue={roles
                    .filter((item) => {
                      return item._id === role;
                    })
                    .map((item) => {
                      return {
                        label: item.role,
                        value: item._id,
                      };
                    })}
                  options={roles.map((item) => {
                    return {
                      label: item.role,
                      value: item._id,
                    };
                  })}
                ></StyledSelectDropdown>
              )}
            </StyledField>

            <StyledField>
              <Label>Hours To Complete</Label>
              <Input
                type="number"
                defaultValue={hoursToComplete}
                onChange={(e) => setHoursToComplete(e.target.value)}
              />
            </StyledField>
          </FlexRow>

          <FlexRow>
            <StyledField>
              <Label>Link Text</Label>
              <Input
                type="text"
                defaultValue={linkText}
                onChange={(e) => setLinkText(e.target.value)}
              />
            </StyledField>
            <StyledField>
              <Label>Link Url</Label>
              <Input
                type="text"
                defaultValue={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
              />
            </StyledField>
          </FlexRow>
        </TaskLeft>

        <TaskRight>
          <Field>
            <Label>Name</Label>
            <Input
              type="text"
              defaultValue={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Field>

          <Field>
            <Label>Task Details</Label>
            <Textarea
              style={{ height: "150px" }}
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Field>

          <ButtonRow>
            <Delete onClick={() => deleteTaskTemplate()}>Delete</Delete>

            <Save
              disabled={!(title && description && role && linkText && linkUrl)}
              onClick={() => updateTaskTemplate()}
            >
              Save
            </Save>
          </ButtonRow>
        </TaskRight>
      </Task>
    </TasksContainer>
  );
};

const TasksContainer = styled.div`
  padding: 53px 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MainHeader = styled.h2`
  color: ${(props) => props.theme.colors.darkSlate};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0px;
  margin-right: 170px;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const Task = styled.div`
  padding: 40px 0px;
  display: flex;
  justify-content: space-between;
  width: 75%;
`;

const TaskLeft = styled.div`
  width: 45%;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 45%;
  }
`;

const StyledField = styled(Field)`
  width: 45%;

  input {
    width: 100%;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 20px;

  * {
    font-size: 14px;
  }
`;

const TaskRight = styled.div`
  width: 45%;

  input,
  textarea {
    width: 100%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
`;

const Delete = styled(Button)`
  min-width: 140px;
  width: 140px;
  background-color: #df5757;
  border-color: #df5757;
  margin-right: 20px;

  &::after {
    background-color: ${darken(0.1, "#df5757")};
  }
`;

const Save = styled(Button)`
  min-width: 140px;
  width: 140px;
`;

export default TaskTemplate;
