import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { differenceInMinutes, isPast, isToday, format } from "date-fns";

import SelectDropdown from "../../../components/SelectDropdown";
import ProfileImage from "../../../components/ProfileImage";
import CreateLink from "../../../components/links/CreateLink";
import { respondTo } from "../../../styles/styleHelpers";

import Timeslot from "./timeslot";

const Calendar = ({
  view,
  dragging,
  calendarRef,
  isProjectView,
  isCapacityView,
  resources,
  events,
  handleDrop,
  addProject,
  selectProjects,
  onProjectSelectChange,
  handleEventRemove,
  minTime,
  maxTime,
  ...rest
}) => {
  useEffect(() => {
    // if we've just switched to capacity view
    if (isCapacityView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      const dayOfWeekNumber = new Date().getDay();

      // ? wait a moment for it to re-render
      setTimeout(() => {
        // scroll to the current day, but not to an exact minute. That way it will scroll to today at midnight, or 0:00hrs
        calendarApi.scrollToTime({
          days: dayOfWeekNumber > 1 ? dayOfWeekNumber - 1 : 0,
          minutes: 0,
        });
      }, 200);
    }
  }, [isCapacityView]); //eslint-disable-line

  const handleEventContent = ({ event }) => {
    const { projectId, code, status } = event.extendedProps;

    const title = code ? `${code}: ${event.title}` : event.title;

    // duration of this event
    const minutes = differenceInMinutes(event.end, event.start);
    const hours = minutes / 60;

    // check if this event is in the past, before today
    const eventPast = isPast(new Date(event.end));
    const eventToday = isToday(new Date(event.end));

    // check if the task is completed
    const isComplete = status === "Completed";

    // for the open capacity
    const isDisabled = status === "Disabled";

    // if this event is being dropped on the calendar from the sidebar
    const isDropping = event.extendedProps?.isDrop ? true : false;

    // link the title to the project
    const link =
      projectId && projectId !== "0" ? `/projects/${projectId}` : null;

    return (
      <Timeslot
        id={event.id}
        title={title}
        duration={isNaN(hours) ? 0 : hours}
        // completionPct={completionPct}
        isComplete={isComplete}
        isDisabled={isDisabled}
        dragging={dragging || isDropping}
        isPast={eventPast && !eventToday}
        link={link}
        handleRemove={
          handleEventRemove && !isDisabled && !isDropping
            ? () => {
                handleEventRemove(event);
              }
            : undefined
        }
      />
    );
  };

  const findDueDate = (projectName) => {
    const foundResource = resources.find(
      (resource) => resource.project === projectName,
    );

    return foundResource?.projectDueDate;
  };

  // to position the scroll on today's day, at the current time
  const currentDayOfWeekNumber = new Date().getDay();
  const currentHours = new Date().getHours();

  return (
    <Container dragging={dragging}>
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        ref={calendarRef}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineWeek"
        headerToolbar={{
          left: "title",
          center: "",
          right: "today,prev,next",
        }}
        // height of entire calendar
        height="100%"
        buttonText={{
          today: `Current ${
            view === "month" ? "Year" : view === "week" ? "Month" : "Week"
          }`,
        }}
        weekends={false}
        slotDuration="00:15:00"
        slotMinWidth={60} // 60px for each 15min slot
        slotLabelFormat={
          view === "month"
            ? [
                // First row: Month
                {
                  month: "long",
                },
                // Second row: Week day & day #
                {
                  weekday: "short",
                  day: "numeric",
                },
              ]
            : view === "week"
            ? [
                // First row: Week number
                {
                  week: "short",
                },
                // Second row: Week day, month, day
                {
                  month: "numeric",
                  day: "numeric",
                  weekday: "narrow",
                },
              ]
            : [
                // first row: day of week
                {
                  month: "numeric",
                  day: "numeric",
                  weekday: "short",
                  week: "narrow",
                },
                // second row: time of day
                isCapacityView
                  ? // like 0:00hrs, 2:00hrs, 13:00hrs
                    (slotTime) =>
                      slotTime.date.hour +
                      `:${`${slotTime.date.minute}`.padStart(2, "0")}hrs`
                  : {
                      // like 12:00am, 8:00am, 12:00pm, 2:00pm
                      hour: "numeric",
                      minute: "2-digit", // show the minutes at the end
                      meridiem: "short",
                    },
              ]
        }
        businessHours={
          // ? hide biz hours on capacity view
          isCapacityView
            ? false
            : {
                // days of week. an array of zero-based day of week integers (0=Sunday)
                daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
                startTime: "8:30", // 8:30am
                endTime: "17:30", // 5:30pm
              }
        }
        dayHeaders={true}
        dayHeaderFormat={{
          weekday: "short",
          month: "numeric",
          day: "numeric",
          omitCommas: true,
        }}
        // how much time to show for each day.
        // - capacity view: midnight -> noon
        slotMinTime={isCapacityView ? `00:00:00` : `${minTime}:00:00`}
        slotMaxTime={isCapacityView ? `12:00:00` : `${maxTime}:00:00`}
        scrollTime={{
          // initial scroll amount. (can't be changed after it renders)
          days:
            // calendar starts on monday, so subtract 1 to take that into account
            currentDayOfWeekNumber > 0 ? currentDayOfWeekNumber - 1 : 0,

          minutes: currentHours * 60,
        }}
        resourceAreaColumns={[
          // {
          //   group: !isProjectView,
          //   field: "title",
          //   headerContent: "User",
          //   // width: "15%",
          //   width: "20%",
          //   cellContent: (args) => {
          //     // ? A different format is returned depending on if there is 1 or many projects for that user,
          //     // ? so we have to check for that to determine which props of the object to use

          //     return args.resource?.extendedProps ? (
          //       <ProfileImage
          //         name={args.resource._resource.extendedProps.member.name}
          //         handle={args.resource._resource.extendedProps.member.handle}
          //       />
          //     ) : (
          //       <ProfileImage name={args.groupValue} handle={args.groupValue} />
          //     );
          //   },
          // },
          {
            group: !isProjectView,
            field: "member",
            headerContent: "User",
            // width: "15%",
            width: "20%",
            cellContent: (args) => {
              // ? prop has a different name depending on whether the "group" prop above is true
              const userInfo = args.groupValue || args.fieldValue;

              return typeof userInfo === "string" ? (
                <CalProfileImage name={userInfo} handle={userInfo} />
              ) : (
                <>
                  <CalProfileImage
                    name={userInfo.name}
                    handle={userInfo.handle}
                  />

                  {isCapacityView &&
                  !isProjectView &&
                  (userInfo.capacityLow || userInfo.capacityHigh) ? (
                    <UserCapacity isCondensed={isProjectView}>
                      {isProjectView ? null : <strong>Capacity</strong>}

                      <div>
                        {userInfo.capacityLow / 5 || "?"} -{" "}
                        {userInfo.capacityHigh / 5 || "?"}{" "}
                        {isProjectView ? "hrs" : null}
                      </div>
                    </UserCapacity>
                  ) : null}
                </>
              );
            },
          },
          {
            group: isProjectView,
            field: "project",
            headerContent: "Project",
            width: "50%",
            cellContent: ({ fieldValue, resource, groupValue }) => {
              // Generates the resource labels

              return fieldValue === "Add Project" ? (
                // If it's an Add Project, create the button
                <CreateLink
                  onClick={() => addProject(resource.extendedProps.member._id)}
                >
                  {fieldValue}
                </CreateLink>
              ) : fieldValue === "SELECT" ? (
                // If it's SELECT, create the select dropdown
                <DropdownContainer>
                  <ProjectDropdown
                    placeholder="Select Project"
                    onChange={(e) => {
                      onProjectSelectChange(
                        resource.extendedProps.member._id,
                        e,
                      );
                    }}
                    options={selectProjects}
                    short
                  />
                </DropdownContainer>
              ) : resource?.extendedProps ? (
                // Otherwise, just show
                <Project>
                  <ProjectName>
                    {resource._resource.extendedProps.project}
                  </ProjectName>

                  {isProjectView &&
                  resource._resource.extendedProps.projectDueDate ? (
                    <ProjectDueDate>
                      DUE:{" "}
                      {format(
                        new Date(
                          resource._resource.extendedProps.projectDueDate,
                        ),
                        "MMM dd, yyyy",
                      )}
                    </ProjectDueDate>
                  ) : null}
                </Project>
              ) : (
                <Project>
                  <ProjectName>{groupValue}</ProjectName>
                  <ProjectDueDate>
                    {findDueDate(groupValue)
                      ? `DUE: ${format(
                          new Date(findDueDate(groupValue)),
                          "MMM dd, yyyy",
                        )}`
                      : null}
                  </ProjectDueDate>
                </Project>
              );
            },
          },
        ]}
        resources={resources}
        events={events}
        eventContent={handleEventContent}
        // prevent events from being resized
        eventDurationEditable={false}
        // Adds a red line for today
        // - hide it on capacity view
        nowIndicator={isCapacityView && view === "day" ? false : true}
        firstDay={1}
        droppable={true}
        editable={true}
        // only allow the [data-event] elements to be dropped onto the calendar
        dropAccept="[data-event]"
        // events can't overlap when dragging/resizing
        //eventOverlap={false}
        drop={handleDrop}
        resourceLaneClassNames={(args) => {
          const laneClassNames = [];

          const {
            capacityLow,
            capacityHigh,
          } = args.resource._resource.extendedProps.member;

          if (capacityLow && capacityHigh) {
            // capacity for a single day
            const dailyCapacityLow = capacityLow / 5;
            const dailyCapacityHigh = capacityHigh / 5;

            // hours between low and high
            const capacityDiff = dailyCapacityHigh - dailyCapacityLow;

            // amount of hours in the 12hr period after the low capacity point
            const hoursAfterLow = 12 - dailyCapacityLow;

            // class name will tell us the percentage of the non-bix hours block that needs to be lighter colored to represent the time between low and high capacity
            laneClassNames.push(
              `capacity-zone-${capacityDiff}-${hoursAfterLow}`,
            );
          }

          return laneClassNames;
        }}
        {...rest}
      />
    </Container>
  );
};

const Container = styled.div`
  /* border color used throughout the calendar */
  --fc-border-color: ${(props) => props.theme.colors.oldLightGray};

  position: relative;
  flex-grow: 1;

  /* day of week in upper left corner for manager view */
  &::before {
    ${(props) =>
      props.dayOfWeek
        ? // only show the first 3 characters on laptop
          css`
            content: "${props.dayOfWeek.substring(0, 3)}";
          `
        : ``}

    position: absolute;
    top: 0;
    left: 0;

    font-size: 14px;
    font-weight: 700;
    color: #554668;
    line-height: 1;
    text-transform: uppercase;
    pointer-events: none;

    ${respondTo("xlarge")} {
      ${(props) =>
        props.dayOfWeek
          ? css`
              content: "${props.dayOfWeek}";
            `
          : ``}
    }
  }

  .fc {
    width: 100%;
  }

  .fc-resource .fc-icon {
    display: none;
  }

  .fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-sat,
  .fc-timeline-slot.fc-timeline-slot-lane.fc-day.fc-day-sun {
    background-color: ${(props) => props.theme.colors.lightBlue};
  }

  table {
    margin: 0;
  }

  /* remove border around entire calendar */
  .fc-scrollgrid {
    border: 0;
  }

  /* // ? bug fix introduced in chrome 91 */
  .fc-scrollgrid-section-liquid {
    height: 1px;
  }

  /* remove border on bottom and left of calendar */
  .fc-scrollgrid-section-liquid > td {
    border: 0;
  }

  /* entire header at top */
  .fc-col-header {
    border-bottom: 1px solid var(--fc-border-color);
  }

  .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
    height: 100%;
    display: flex;
    align-items: center;
  }

  /* header cells */
  .fc-col-header-cell {
    line-height: 1;
    border: 0;

    /* regular days of week (Mon, Tue, etc.) */
    .header-day-name {
      color: #8698ad;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .header-day-number {
      font-size: 18px;
      font-weight: 600;
    }

    /* resource columns (for manager view) */
    &.fc-resource {
      color: ${(props) => props.theme.colors.slate};
      font-weight: 600;
    }
  }

  /* padding between header cells and body of calendar */
  .fc-col-header-cell-cushion {
    padding: 0;
    padding-bottom: 9px;
  }

  .fc-scrollgrid-section-header {
    /* remove border-right on header */
    > td {
      border: 0;
    }

    /* remove scrollbar on header cells */
    .fc-scroller {
      overflow: hidden !important;
    }
  }

  /* slots on the left */
  .fc-timegrid-slot-label {
    border-right-color: transparent;
    color: #8698ad;
  }

  /* times on the left */
  .fc-timegrid-slot-label-frame {
    text-align: left;
    //transform: translateY(150%);
  }
  .fc-timegrid-slot-label-cushion {
    padding-left: 0;
  }

  /* all the columns except the time col on the left */
  .fc-timegrid-col:not(.fc-timegrid-axis) {
    background-color: transparent;
    border-color: transparent;
    transition: border-color 200ms linear;
  }

  .fc-timeline-event {
    border: 0;
    border-radius: 0;
    background-color: transparent;

    padding-top: 0;
    padding-right: 0;

    cursor: ${(props) => (props.dragging ? "grabbing" : "grab")};

    &:active {
      cursor: grabbing;
    }
    &:focus {
      outline: none;
    }

    &.fc-event-end {
      margin-right: 0;
    }
  }

  .fc-h-event .fc-event-main {
    color: ${(props) => props.theme.colors.darkSlate};
    padding: 0;
    display: flex;
  }

  /* remove the small margin around the timeslots */
  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }

  /* when one of the day columns is being hovered, give it a higher z-index than the others (so the elements on the outside that appear on hover can easily be hovered on without hovering onto the next column and having them disappear) */
  .fc-timegrid-col-events {
    &:hover {
      z-index: 10;
    }
  }

  .fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
    overflow: visible;
  }

  .fc-datagrid-cell-main {
    width: 100%;
  }

  /* top calendar toolbar area */
  .fc-header-toolbar {
    padding: 0 40px;
  }

  /* container of the header wity resource group names and times of day */
  .fc-resource-timeline > table > thead {
    position: relative;
    z-index: 1;
  }

  /* header with resource group names and times of day */
  .fc-scrollgrid-section-header {
    /* bg color so the box-shadow doesn't overlap onto it */
    background-color: white;
  }

  /* container of the resource columns */
  .fc-scrollgrid-section-body > td:first-child {
    box-shadow: 40px 0px 60px 0px ${rgba("#B9BCC0", 0.15)};
  }

  /* header above first column of resources on the left */
  /* first column of resources on the left */
  .fc-datagrid-header .fc-datagrid-cell:first-child .fc-datagrid-cell-cushion,
  .fc-resource-group .fc-datagrid-cell-cushion {
    padding-left: 40px;
  }

  /* header above resources on left */
  .fc-datagrid-header .fc-datagrid-cell {
    border-left: 0;
    border-right: 0;

    .fc-datagrid-cell-cushion {
      padding-bottom: 5px;
    }
  }

  /* each resource on left */
  .fc-datagrid-body .fc-datagrid-cell {
    border-left: 0;
    border-right: 0;

    /* projects (sub resources) */
    &.fc-resource {
      border-style: dotted;
    }

    .fc-datagrid-cell-cushion {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  /* days and time labels at top */
  .fc-timeline-slot-label {
    border-left: 0;
  }
  /* only the days */
  .fc-timeline-header-row:not(.fc-timeline-header-row-chrono)
    .fc-timeline-slot-label {
    font-size: 12px;
    font-weight: 700;
    border-bottom: 0;
  }
  /* only the time labels */
  .fc-timeline-header-row-chrono .fc-timeline-slot-label {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    border-top: 0;

    /* the 15/30/45 min marks */
    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    /* 15/45min */
    &::before {
      width: 50%;
      height: 3px;

      border: 1px solid ${(props) => props.theme.colors.mediumGray};
      border-top: 0;
      border-bottom: 0;
    }
    /* 30min */
    &::after {
      width: 1px;
      height: 3px;
      background-color: ${(props) => props.theme.colors.mediumGray};
    }

    .fc-timeline-slot-frame {
      align-items: flex-end;
    }

    .fc-timeline-slot-cushion {
      padding-left: 0;
    }
  }

  /* vertical divider line between the resources on left and calendar on right  */
  .fc-resource-timeline-divider {
    width: 1px;
  }
  thead .fc-resource-timeline-divider {
    /* hide the one thats in the header */
    opacity: 0;
  }

  /* the lighter vertical lines in between the main hour lines that mark the 15, 30, and 45min marks */
  .fc-timeline-slot-minor {
    border-color: transparent;
  }

  /* horizontal lanes */
  .fc-timeline-lane {
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
  /* "add project" horizontal lane */
  .fc-timeline-lane[data-resource-id$="ADD"] {
    border-bottom-color: ${(props) => props.theme.colors.oldLightGray};
  }

  /* bg color for the non-business hours */
  .fc-non-business {
    /* background: ${(props) => rgba(props.theme.colors.slate, 0.05)}; */
    background: ${(props) => props.theme.colors.oldSilver};
  }
  /* capacity indication (to represent the time between a user's low and high capacity) */
  .capacity-zone-1-4 .fc-non-business {
    background: ${(props) =>
      `linear-gradient(to right, ${rgba(props.theme.colors.slate, 0.15)} 25%, ${
        props.theme.colors.oldSilver
      } 25%)`};
  }
  .capacity-zone-2-4 .fc-non-business,
  .capacity-zone-1-2 .fc-non-business {
    background: ${(props) =>
      `linear-gradient(to right, ${rgba(props.theme.colors.slate, 0.15)} 50%, ${
        props.theme.colors.oldSilver
      } 50%)`};
  }
  .capacity-zone-3-4 .fc-non-business {
    background: ${(props) =>
      `linear-gradient(to right, ${rgba(props.theme.colors.slate, 0.15)} 75%, ${
        props.theme.colors.oldSilver
      } 75%)`};
  }
  .capacity-zone-1-3 .fc-non-business {
    background: ${(props) =>
      `linear-gradient(to right, ${rgba(
        props.theme.colors.slate,
        0.15,
      )} 33.333333%, ${props.theme.colors.oldSilver} 33.333333%)`};
  }
  .capacity-zone-2-3 .fc-non-business {
    background: ${(props) =>
      `linear-gradient(to right, ${rgba(
        props.theme.colors.slate,
        0.15,
      )} 66.666666%, ${props.theme.colors.oldSilver} 66.666666%)`};
  }
  .capacity-zone-1-1 .fc-non-business,
  .capacity-zone-2-2 .fc-non-business,
  .capacity-zone-3-3 .fc-non-business,
  .capacity-zone-4-4 .fc-non-business {
    background: ${(props) => rgba(props.theme.colors.slate, 0.15)};
  }
`;

const DropdownContainer = styled.div`
  //background-color: red;
`;

const ProjectDropdown = styled(SelectDropdown)`
  min-height: 30px;
`;

const Project = styled.div`
  padding: 20px 40px;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  white-space: normal;
`;

const ProjectName = styled.div``;

const ProjectDueDate = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 5px;
`;

const UserCapacity = styled.div`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  text-align: center;
  margin-top: ${(props) => (props.isCondensed ? "5px " : "20px")};

  strong {
    display: block;
    margin-bottom: 0.25em;
  }
`;

const CalProfileImage = styled(ProfileImage)`
  margin: auto;
`;

Calendar.propTypes = {
  events: PropTypes.array,
  dragging: PropTypes.bool,
  handleEventRemove: PropTypes.func,
  resources: PropTypes.array,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  minTime: PropTypes.number,
  maxTime: PropTypes.number,
  isProjectView: PropTypes.bool,
  isCapacityView: PropTypes.bool,
};

Calendar.defaultProps = {
  events: [],
  dragging: false,
  resources: [],
  calendarRef: null,
  minTime: 7,
  maxTime: 19,
  isProjectView: false,
  isCapacityView: false,
};

export default Calendar;
