import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format, isPast, isToday } from "date-fns";
import { startCase } from "lodash";

import {
  getWeek,
  findItemProperty,
  getStandardizedDate,
} from "../../../utils/helpers";
import { buttonReset } from "../../../styles/styleHelpers";
import { taskStatuses } from "../../../utils/constants";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../../utils/sorting";

import useBulkSelect from "../../../hooks/useBulkSelect";

import { NumberChip } from "../../../components/ValueChip";
import ProfileImage from "../../../components/ProfileImage";
import Switch from "../../../components/newSwitch";
import CheckMenu from "../../../components/NewTable/CheckMenu";
import Editable from "../../../components/Editable";
import Table from "../../../components/NewTable/index";
import {
  TableCell,
  SearchInput,
  Checkbox,
  AddBtn,
} from "../../../components/NewTable/components";
import Filters, { FilterGroup } from "../../../components/NewTable/Filters";
import FilterSelect from "../../../components/NewTable/FilterSelect";
import SelectedFilters from "../../../components/NewTable/SelectedFilters";
import SelectDropdown from "../../../components/newSelectDropdown";
import CreateIcon from "../../../components/icons/CreateIcon";
import TaskNotesIcon from "../../../components/icons/TaskNotesIcon";
import { Input, Checkbox as CheckboxInput } from "../../../components/newForm";

import RecurringModal from "./RecurringModal";
import PopoutMenu from "./PopoutMenu";
import {
  ViewContainer,
  TableContainer,
  FiltersContainer,
  SelectedFiltersContainer,
  FiltersLeft,
  FiltersRight,
  CreateSelect,
  CreateInput,
  CreateLabel,
  CreateValue,
  WeekNumberFields,
} from "./ProjectTable";
import Tooltip from "../../../components/Tooltip";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  owner: "memberId.name",
  hours: "hoursToComplete",
  week: "weekNumber",
  date: "dateString",
};

// filter chips
const filters = ["owner", "role", "status", "date", "week", "step", "hours"];

// options for the group by dropdown
const groupByOptions = ["owner", "role", "status", "week", "hours"];

// group by dropdown options
const groupBySelectOptions = [
  // first option is "None"
  { label: "None", value: null },
  // remaining options
  ...groupByOptions.map((option) => {
    // grab the proper path for this filter
    const value = filterPaths.hasOwnProperty(option)
      ? filterPaths[option]
      : option;

    return {
      label: startCase(option),
      value,
    };
  }),
];

const TasksTable = ({
  project,
  projectId,
  allMembers,
  tasks,
  phases,
  availDeliverables,
  availMembers,
  userId,
  isImportant,
  isSuper,
  createTask,
  toggleTaskCompleted,
  taskEditable,
  onDelete,
  getTaskStepDetails,
  setSelectedTask,
  highlightedTask,
  roles,
  recurring,
}) => {
  const [displayTasks, setDisplayTasks] = useState([]);
  const [tableGroupBy, setTableGroupBy] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  // whether to display completed tasks from before this week
  // ? by default, show completed tasks on the Recurring Tasks view
  const [showCompletedTasks, setShowCompletedTasks] = useState(
    recurring ? true : false,
  );

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    clearSelectedItems,
  } = useBulkSelect(displayTasks);

  useEffect(() => {
    // show completed tasks on the Recurring Tasks view
    setShowCompletedTasks(recurring ? true : false);
  }, [recurring]);

  /*
  |--------------------------------------------------------------------------
  | Check to see if a rate combination exists before creating a task
  |--------------------------------------------------------------------------
  */
  const checkExistingRates = (role) => {
    let rateExists = false;
    const rates = project.contract?.rates;

    if (rates && role) {
      // Find the first member with the role that was passed in
      // Since members will generally share the same team and tier if they have the same role
      // Example: all members with a "Developer" role will all have a tier of "staff" and a team of "Tech"
      const selectedMember = allMembers.find(
        (member) => member.role._id === role,
      );

      // If no member is found with the role, return false
      if (!selectedMember) {
        return rateExists;
      }

      const team = selectedMember.role.team._id;
      const tier = selectedMember.role.tier;
      const type = project.designation;

      // Look for a rate that has the combination of matching team, tier, and type
      const foundRate = rates.find(
        (rate) =>
          rate.team._id === team && rate.tier === tier && rate.type === type,
      );

      if (foundRate) {
        console.info("Found rate", foundRate);
        rateExists = true;
      } else {
        console.info("Rate not found");
      }
    }

    return rateExists;
  };

  /*
  |--------------------------------------------------------------------------
  | Return rates error message
  |--------------------------------------------------------------------------
  */
  const getRatesMessage = (role) => {
    const rates = project.contract?.rates;

    if (rates && role) {
      // Find the first member with the role that was passed in
      // Since members will generally share the same team and tier if they have the same role
      // Example: all members with a "Developer" role will all have a tier of "staff" and a team of "Tech"
      const selectedMember = allMembers.find(
        (member) => member.role._id === role,
      );

      // If no member is found with the role, return false
      if (!selectedMember) {
        return "There are currently  no members assigned to this role";
      }

      const team = selectedMember.role.team.name.toLowerCase();
      const tier = selectedMember.role.tier.toLowerCase();
      const type = project.designation;

      return `Rate with Team: [${team}], Tier: [${tier}], Type: [${type}] is missing from the contract`;
    } else {
      return "Rate is missing from contract";
    }
  };

  // update the tasks to display when tasks are updated, or when a search or filter is applied
  useEffect(() => {
    if (tasks) {
      const newDisplayTasks = filterTasks();
      setDisplayTasks(newDisplayTasks);
    }
  }, [tasks, searchText, selectedFilters]);

  useEffect(() => {
    if (highlightedTask && displayTasks?.length) {
      const task = document.getElementById(highlightedTask._id);
      task.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayTasks]);

  const filterTasks = () => {
    let tempTasks = getDateStrings(tasks);

    // Filters the items
    if (selectedFilters?.length) {
      tempTasks = filterItems(
        // Formats filters
        selectedFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempTasks,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempTasks = searchItems(searchText, tempTasks, [
        "title",
        "project.name",
        "hoursToComplete",
        "role",
        "memberId.name",
        "weekNumber",
        "date",
        "status",
      ]);
    }

    // sorts the results by due date / completion status

    const tasksWithoutDueDate = tempTasks.filter((task) => !task.date);
    const tasksWithDueDate = tempTasks.filter((task) => task.date);

    // incomplete tasks, sorted with oldest due dates at the top
    const incompleteTasks = tasksWithDueDate
      .filter((task) => task.status.toLowerCase() !== "completed")
      .sort((a, b) => new Date(a.date) - new Date(b.date));

    // completed tasks, sorted with recent due dates at the top
    const completedTasks = tasksWithDueDate
      .filter((task) => task.status.toLowerCase() === "completed")
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    // sorted tasks:
    // - no due date
    // - incomplete (oldest due dates first)
    // - completed (recent due dates first)
    const sortedTasks = [
      ...tasksWithoutDueDate,
      ...incompleteTasks,
      ...completedTasks,
    ];

    return sortedTasks;
  };

  // add a dateString prop to the items
  const getDateStrings = (items) =>
    items.map((item) => ({
      ...item,
      dateString: item.date
        ? format(getStandardizedDate(item.date), "MM/dd/yyyy")
        : "",
    }));

  const handleDelete = (toDelete) => {
    const itemsToDelete = Array.isArray(toDelete) ? toDelete : [toDelete];

    if (onDelete) {
      onDelete(itemsToDelete, () => {
        // on success, deselect the checked items
        clearSelectedItems();
      });
    }
  };

  return (
    <Container>
      <FiltersContainer>
        <FiltersLeft>
          {isImportant ? (
            <CheckMenu
              disabled={!selectedItems.length}
              numItems={selectedItems.length}
              handleDelete={() => handleDelete(selectedItems)}
            />
          ) : null}

          <Filters
            selectedFilters={selectedFilters}
            updateFilters={setSelectedFilters}
            filters={filters}
            getUniqueOptions={(filter) =>
              getUniqueOptions(filter, getDateStrings(tasks), {
                exclude: selectedFilters,
                propPaths: filterPaths,
              })
            }
          />

          <SelectedFiltersContainer>
            <SelectedFilters
              filters={selectedFilters}
              updateFilters={setSelectedFilters}
              shouldWrap
            />
          </SelectedFiltersContainer>
        </FiltersLeft>

        <FiltersRight>
          <CheckboxInput
            label="Show Completed"
            checked={showCompletedTasks}
            onChange={() => {
              setShowCompletedTasks(!showCompletedTasks);
            }}
          />

          <FilterSelect
            label="Group By"
            handleChange={(label, value) => setTableGroupBy(value)}
            defaultValue={groupBySelectOptions[0]}
            options={groupBySelectOptions}
          />

          <Input
            placeholder="Search items..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FiltersRight>
      </FiltersContainer>

      <TableContainer>
        <Table
          // so the scrolling is handled by the whole viewport, rather than just the table
          scrollable={false}
          noPadding
          groupBy={tableGroupBy}
          headers={[
            {
              component: isImportant
                ? (groupedEntries = displayTasks) => (
                    <Checkbox
                      isChecked={
                        groupedEntries?.length &&
                        // ? in case the items are grouped, reference the adjacent grouped items, rather than the entire array
                        groupedEntries.every((item) =>
                          selectedItems.includes(item._id),
                        )
                      }
                      onClick={() => handleSelectAll(groupedEntries)}
                    />
                  )
                : undefined,
            },
            {
              name: "Completed",
              accessor: "status",
            },
            {
              name: "Tasks",
              accessor: "title",
            },
            // {
            //   name: "Project",
            //   accessor: "projectId.name",
            // },
            {
              name: "Phase",
              accessor: "phase",
            },
            {
              name: "Deliverable",
              accessor: "deliverable",
            },
            {
              name: "Step",
              accessor: "step",
            },
            {
              name: "Hours",
              accessor: "hoursToComplete",
            },
            {
              name: "Role",
              accessor: "role?.role",
            },
            {
              name: "Owner",
              accessor: "memberId.name",
            },
            {
              name: "Week",
              accessor: "weekNumber",
            },
            {
              name: "Due Date",
              accessor: "date",
            },
            {
              name: "Status",
              accessor: "status",
            },
            {
              name: "Recurring",
              accessor: "recurring.active",
            },
            {
              name: "",
            },
          ]}
          // defaultSort="date-asc"
          entries={
            displayTasks
              ? displayTasks
                  .filter((task) => {
                    const isCompleted = task.status === "Completed";

                    const taskYear = new Date(task.date).getFullYear();
                    const taskWeekNumber = getWeek(new Date(task.date));

                    const currentYear = new Date().getFullYear();
                    const currentWeek = getWeek();

                    const isThisWeek =
                      parseInt(taskYear) === parseInt(currentYear) &&
                      parseInt(taskWeekNumber) === parseInt(currentWeek);

                    // show completed tasks from this week
                    // show other completed tasks only if `showCompletedTasks` was checked
                    // show all incomplete tasks
                    const displayTask = isCompleted
                      ? isThisWeek
                        ? true
                        : showCompletedTasks
                        ? true
                        : false
                      : true;

                    if (recurring) {
                      // if we're only showing recurring tasks
                      return task.recurring?.active && displayTask;
                    } else {
                      return displayTask;
                    }
                  })
                  .map((task) => {
                    const taskPhase = phases.find(
                      (phase) => phase._id === task.phase,
                    );

                    const taskDeliverable = availDeliverables?.find(
                      (deliverable) => deliverable._id === task.deliverableId,
                    );

                    const stepDetails = task.step
                      ? getTaskStepDetails(task)
                      : null;

                    const taskWeekNumber = task.weekNumber
                      ? `${task.weekNumber.split("w")[0]} - W${
                          task.weekNumber.split("w")[1]
                        }`
                      : "N/A";

                    const taskDateString = task.date
                      ? format(getStandardizedDate(task.date), "MM/dd/yyyy")
                      : "";

                    // whether the task belongs to this user
                    const belongsToUser =
                      userId &&
                      task.memberId?._id &&
                      userId === task.memberId._id;

                    return {
                      ...task,
                      phase: taskPhase?.name || "",
                      deliverable: taskDeliverable?.name || "",
                      // ? if task doesn't have a due date, give it an old date so its at the top of the table
                      date: task.date || new Date(0).toISOString(),
                      dateString: taskDateString,
                      rowProps: {
                        disabled: stepDetails?.disabled,
                        highlight: highlightedTask?._id === task._id,
                      },
                      row: (sortedEntries) => (
                        <>
                          <TableCell>
                            {isImportant ? (
                              <Checkbox
                                isChecked={selectedItems.includes(task._id)}
                                onClick={(e) => {
                                  // ? include the sorted entries from the table so if the user sorted or grouped the items, the correct ones will be selected if they shift+click to multi-select
                                  handleSelect(
                                    task._id,
                                    e.shiftKey,
                                    sortedEntries,
                                  );
                                }}
                              />
                            ) : null}
                          </TableCell>

                          <TableCell>
                            <div style={{ paddingLeft: "25%" }}>
                              <Switch
                                id={`${task._id}-toggle`}
                                isActive={task.status === "Completed"}
                                handleSwitch={() =>
                                  toggleTaskCompleted(task._id)
                                }
                                disabled={
                                  isSuper || project.projectManager === userId
                                    ? false
                                    : !belongsToUser || stepDetails?.disabled
                                }
                              />
                            </div>
                          </TableCell>

                          <TableCell
                            data-task-name
                            data-sticky-col="left"
                            data-highlight={
                              highlightedTask?._id === task._id
                                ? "highlight"
                                : ""
                            }
                          >
                            <div
                              style={{
                                display: "inline-block",
                                verticalAlign: "middle",
                              }}
                            >
                              <Editable
                                id={`${task._id}-title`}
                                defaultValue={task.title}
                                iconClickOnly
                                {...taskEditable(task, "title")}
                              >
                                <Btn onClick={() => setSelectedTask(task)}>
                                  {task.notes ? <TaskNotesIcon /> : null}{" "}
                                  {task.title || "(Untitled Task)"}
                                </Btn>
                              </Editable>
                            </div>
                          </TableCell>

                          {/* <TableCell >{task.projectId.name || "N/A"}</TableCell> */}

                          <TableCell>
                            <Editable
                              id={`${task._id}-phase`}
                              {...taskEditable(task, "phase", {
                                taskPhase,
                              })}
                            >
                              {taskPhase ? taskPhase.name : <>&nbsp;</>}
                            </Editable>
                          </TableCell>

                          <TableCell>
                            <Editable
                              id={`${task._id}-deliverable`}
                              iconClickOnly={taskDeliverable ? true : false}
                              {...taskEditable(task, "deliverable", {
                                taskDeliverable,
                              })}
                            >
                              {taskDeliverable ? (
                                <Btn
                                  as={Link}
                                  to={`/projects/${projectId}/deliverables?search=${
                                    taskDeliverable.name
                                  }${
                                    taskDeliverable.file?._id
                                      ? `&file=${taskDeliverable.file._id}`
                                      : ""
                                  }`}
                                >
                                  {taskDeliverable.name}
                                </Btn>
                              ) : (
                                <>&nbsp;</>
                              )}
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-step`}
                              {...taskEditable(task, "step")}
                            >
                              {task.step ? (
                                <NumberChip
                                  color={stepDetails.color}
                                  title={
                                    stepDetails.status
                                      ? `${stepDetails.status} step`
                                      : undefined
                                  }
                                >
                                  {task.step}
                                </NumberChip>
                              ) : (
                                <>&nbsp;</>
                              )}
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-hours`}
                              {...taskEditable(task, "hours")}
                            >
                              <span>{task.hoursToComplete || "0"}</span>
                            </Editable>
                          </TableCell>

                          <TableCell data-task-role>
                            <Editable
                              id={`${task._id}-role`}
                              {...taskEditable(task, "role")}
                            >
                              {task.role?.role || "N/A"}
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-owner`}
                              {...taskEditable(task, "owner")}
                            >
                              <ProfileImage
                                handle={task.memberId?.handle || undefined}
                                name={
                                  task.memberId?.name || task.role?.role || ""
                                }
                                // showStatus={task.memberId ? true : undefined}
                                xsmall
                              />
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-weekNumber`}
                              {...taskEditable(task, "weekNumber")}
                            >
                              {taskWeekNumber}
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-due`}
                              {...taskEditable(task, "due")}
                              danger={
                                isPast(getStandardizedDate(taskDateString)) &&
                                !isToday(getStandardizedDate(taskDateString)) &&
                                task.status !== "Completed"
                              }
                            >
                              {taskDateString || "N/A"}
                            </Editable>
                          </TableCell>

                          <TableCell full>
                            <Editable
                              id={`${task._id}-status`}
                              {...taskEditable(task, "status")}
                            >
                              {task.status || "N/A"}
                            </Editable>
                          </TableCell>

                          <TableCell full center>
                            <RecurringModal
                              task={task}
                              isActive={task.recurring?.active ? true : false}
                              canOpen={isImportant}
                            />
                          </TableCell>

                          <TableCell data-sticky-col="right">
                            {isImportant ? (
                              <PopoutMenu
                                actions={[
                                  {
                                    text: "Duplicate",
                                    action: () =>
                                      createTask({
                                        ...task,
                                        projectId: task.projectId?._id || null,
                                        memberId: task.memberId?._id || null,
                                        status: "To do",
                                      }),
                                  },
                                  {
                                    text: "Delete",
                                    action: () => handleDelete([task._id]),
                                    danger: true,
                                  },
                                ]}
                              />
                            ) : null}
                          </TableCell>
                        </>
                      ),
                    };
                  })
              : []
          }
          handleCreate={false} // ? so the table doesn't show the add btn
          createRow={
            isImportant ? (
              <CreateRow
                projectId={projectId}
                phases={phases}
                availDeliverables={availDeliverables}
                availMembers={availMembers}
                roles={roles}
                onCreate={createTask}
                checkExistingRates={checkExistingRates}
                getRatesMessage={getRatesMessage}
              />
            ) : (
              undefined
            )
          }
        />
      </TableContainer>
    </Container>
  );
};

// ? this is its own component so the tasks table doesn't re-render every time the user types in an input field
const CreateRow = ({
  projectId,
  phases,
  availDeliverables,
  availMembers,
  roles,
  onCreate,
  checkExistingRates,
  getRatesMessage,
}) => {
  const defaultInputValues = {
    title: "",
    projectId: projectId,
    memberId: null,
    phase: null,
    step: "",
    hoursToComplete: "",
    role: null,
    weekNumber: `${new Date().getFullYear()}w${getWeek(new Date())}`,
    // date: format(getStandardizedDate(new Date()), "yyyy-MM-dd"),
    date: "",
    status: taskStatuses[0],
  };

  const [createInputs, setCreateInputs] = useState(defaultInputValues);
  const [isLoading, setIsLoading] = useState(false);
  const [rateExists, setRateExists] = useState(false);

  // when filling out fields
  useEffect(() => {
    // if a member is selected but no role is selected
    if (createInputs.memberId && !createInputs.role) {
      const selectedMember = availMembers.find(
        (member) => member._id === createInputs.memberId,
      );

      // set the role as the selected member's role
      if (selectedMember?.role) {
        updateCreateInputs(selectedMember.role._id, "role");
        setRateExists(checkExistingRates(selectedMember.role._id));
      }
    }

    // if theres a due date
    if (createInputs.date) {
      // determine the year/week
      const taskDate = getStandardizedDate(createInputs.date);
      const taskWeekNumber = `${taskDate.getFullYear()}w${getWeek(taskDate)}`;

      // if the current weekNumber string doesn't match that of the current date
      if (createInputs.weekNumber !== taskWeekNumber) {
        updateCreateInputs(taskWeekNumber, "weekNumber");
      }
    }
  }, [createInputs]);

  const updateCreateInputs = (newValue, key) => {
    const newInputs = { ...createInputs };

    // If the role has been changed, reset the member Id
    if (key === "role" && newInputs.memberId && newInputs.role) {
      newInputs.memberId = null;
    }

    newInputs[key] = newValue;

    setCreateInputs(newInputs);
  };

  const handleCreate = async () => {
    setIsLoading(true);

    try {
      if (onCreate) {
        await onCreate(createInputs);
      }
    } catch (error) {
      // error
    }

    // reset the input field values
    setCreateInputs(defaultInputValues);

    setIsLoading(false);
  };

  return (
    <>
      <TableCell>{/* placeholder for bulk select */}</TableCell>
      <TableCell>{/* placeholder for completed toggle */}</TableCell>

      <TableCell full>
        <CreateInput
          placeholder="Task Name"
          value={createInputs.title}
          onChange={(e) => updateCreateInputs(e.currentTarget.value, "title")}
        />
      </TableCell>

      {/* <TableCell full>{projectName}</TableCell> */}

      <TableCell>
        <Editable
          id="create-row-phase"
          onSave={(value) => updateCreateInputs(value, "phase")}
          options={
            phases?.length
              ? phases.map((phase) => ({
                  label: phase.name,
                  value: phase._id,
                }))
              : []
          }
          defaultValue={
            createInputs.phase
              ? {
                  label: findItemProperty(
                    "name",
                    phases,
                    "_id",
                    createInputs.phase,
                  ),
                  value: createInputs.phase,
                }
              : null
          }
          dropdownProps={{ menuPlacement: "top", placeholder: "Phase" }}
        >
          {createInputs.phase ? (
            <CreateValue>
              {findItemProperty("name", phases, "_id", createInputs.phase)}
            </CreateValue>
          ) : (
            <CreateLabel>Phase</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell>
        <Editable
          id="create-row-deliverable"
          onSave={(value) => updateCreateInputs(value, "deliverableId")}
          options={
            availDeliverables?.length
              ? availDeliverables.map((deliverable) => ({
                  label: deliverable.name,
                  value: deliverable._id,
                }))
              : []
          }
          defaultValue={
            createInputs.deliverableId
              ? {
                  label: findItemProperty(
                    "name",
                    availDeliverables,
                    "_id",
                    createInputs.deliverableId,
                  ),
                  value: createInputs.deliverableId,
                }
              : null
          }
          dropdownProps={{
            menuPlacement: "top",
            isClearable: true,
            placeholder: "Deliverable",
          }}
        >
          {createInputs.deliverableId ? (
            <CreateValue>
              {findItemProperty(
                "name",
                availDeliverables,
                "_id",
                createInputs.deliverableId,
              )}
            </CreateValue>
          ) : (
            <CreateLabel>Deliverable</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-step"
          onSave={(value) => updateCreateInputs(value, "step")}
          defaultValue={createInputs.step}
          inputProps={{ type: "number", placeholder: "Step" }}
        >
          {createInputs.step ? (
            <NumberChip>{createInputs.step}</NumberChip>
          ) : (
            <CreateLabel>Step</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-hours"
          onSave={(value) => updateCreateInputs(value, "hoursToComplete")}
          defaultValue={createInputs.hoursToComplete}
          inputProps={{ type: "number", placeholder: "Hours" }}
        >
          {createInputs.hoursToComplete ? (
            <CreateValue>{createInputs.hoursToComplete}</CreateValue>
          ) : (
            <CreateLabel>Hours</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell data-tip={createInputs.role && !rateExists} data-for="role">
        {createInputs.role && !rateExists ? (
          <Tooltip id="role" place="top">
            {getRatesMessage(createInputs.role)}
          </Tooltip>
        ) : null}
        <Editable
          id="create-row-role"
          onSave={(value) => {
            updateCreateInputs(value, "role");
            setRateExists(checkExistingRates(value));
          }}
          options={
            roles?.length
              ? roles.map((role) => ({
                  label: role.role,
                  value: role._id,
                }))
              : []
          }
          defaultValue={
            roles?.length && createInputs.role
              ? {
                  label: roles.find((role) => role._id === createInputs.role)
                    .role,
                  value: roles.find((role) => role._id === createInputs.role)
                    ._id,
                }
              : null
          }
          dropdownProps={{
            menuPlacement: "top",
            placeholder: "Role",
            isClearable: createInputs.memberId ? false : true,
          }}
        >
          {roles?.length && createInputs.role ? (
            <CreateValue error={!rateExists}>
              {roles.find((role) => role._id === createInputs.role).role}
            </CreateValue>
          ) : (
            <CreateLabel>Role</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell
        data-tip={createInputs.memberId && createInputs.role && !rateExists}
        data-for="owner"
      >
        {createInputs.memberId && createInputs.role && !rateExists ? (
          <Tooltip id="owner" place="top">
            {getRatesMessage(createInputs.role)}
          </Tooltip>
        ) : null}

        <Editable
          id="create-row-owner"
          onSave={(value) => updateCreateInputs(value, "memberId")}
          options={
            availMembers?.length
              ? availMembers
                  .filter(
                    // if a role is selected, only show the members that have that role
                    (member) =>
                      !createInputs.role ||
                      member.role._id === createInputs.role,
                  )
                  .map((member) => ({
                    label: member.handle,
                    value: member._id,
                  }))
              : []
          }
          defaultValue={
            createInputs.memberId
              ? {
                  label: findItemProperty(
                    "handle",
                    availMembers,
                    "_id",
                    createInputs.memberId,
                  ),
                  value: createInputs.memberId,
                }
              : null
          }
          dropdownProps={{
            menuPlacement: "top",
            isClearable: true,
            placeholder: "Owner",
          }}
        >
          {createInputs.memberId ? (
            <ProfileImage
              handle={findItemProperty(
                "handle",
                availMembers,
                "_id",
                createInputs.memberId,
              )}
              hasBorder={createInputs.role && !rateExists ? true : false}
              borderColor={createInputs.role && !rateExists ? "red600" : null}
              name={findItemProperty(
                "name",
                availMembers,
                "_id",
                createInputs.memberId,
              )}
              xsmall
            />
          ) : (
            <CreateLabel>Owner</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-week"
          onSave={
            !createInputs.date
              ? (newValues) => {
                  // if both values are provided, update the weekNumber
                  if (newValues.year && newValues.week) {
                    const newWeekNumber = `${newValues.year}w${newValues.week}`;

                    updateCreateInputs(newWeekNumber, "weekNumber");
                  }
                  // if neither are provided, clear the weekNumber
                  else if (!newValues.year && !newValues.week) {
                    updateCreateInputs(null, "weekNumber");
                  }
                  // if only one or the other was provided, do nothing
                }
              : undefined
          }
          edit={(editRef) => (
            <>
              <WeekNumberFields>
                <CreateInput
                  ref={(el) => (editRef["year"] = el)}
                  type="number"
                  placeholder="Year"
                  defaultValue={
                    createInputs.weekNumber
                      ? createInputs.weekNumber.split("w")[0]
                      : ""
                  }
                  small
                />
                w
                <CreateInput
                  ref={(el) => (editRef["week"] = el)}
                  type="number"
                  placeholder="Week"
                  defaultValue={
                    createInputs.weekNumber
                      ? createInputs.weekNumber.split("w")[1]
                      : ""
                  }
                  small
                />
              </WeekNumberFields>
            </>
          )}
        >
          {createInputs.weekNumber ? (
            <CreateValue>
              {`${createInputs.weekNumber.split("w")[0]} - W${
                createInputs.weekNumber.split("w")[1]
              }`}
            </CreateValue>
          ) : (
            <CreateLabel>Week</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <Editable
          id="create-row-due"
          onSave={(value) => updateCreateInputs(value, "date")}
          defaultValue={createInputs.date}
          inputProps={{ type: "date" }}
        >
          {createInputs.date ? (
            <CreateValue>
              {format(getStandardizedDate(createInputs.date), "MM/dd/yyyy")}
            </CreateValue>
          ) : (
            <CreateLabel>Due Date</CreateLabel>
          )}
        </Editable>
      </TableCell>

      <TableCell full>
        <CreateSelect
          menuPlacement="top"
          defaultValue={{
            label: taskStatuses[0],
            value: taskStatuses[0],
          }}
          options={taskStatuses.map((status) => ({
            label: status,
            value: status,
          }))}
          onChange={(e) => {
            updateCreateInputs(e.value, "status");
          }}
        />
      </TableCell>

      <TableCell center full>
        <AddBtn
          onClick={handleCreate}
          disabled={
            isLoading ||
            !createInputs.title ||
            !createInputs.role ||
            !createInputs.hoursToComplete ||
            !rateExists
          }
          style={{ display: "flex", margin: "0 auto" }}
        >
          <CreateIcon />
        </AddBtn>
      </TableCell>

      <TableCell full>{/* placeholder for 3-dot menu */}</TableCell>
    </>
  );
};

const Container = styled(ViewContainer)`
  table {
    th,
    td {
      &[data-task-name] {
        min-width: 150px;
      }

      &[data-task-role] {
        max-width: 120px;
        line-height: 1.25;
      }
    }
  }
`;

const Btn = styled.button`
  ${buttonReset()}
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.indigo};
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  transition: color 200ms;

  svg {
    width: 15px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  &:hover,
  &:focus-within {
    color: ${(props) => props.theme.colors.indigo600};
    text-decoration: underline;
  }
`;

export default TasksTable;
