import React from "react";
import styled from "styled-components";

const DotIcon = ({ ...props }) => {
  return (
    <Svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="5.64844"
        cy="5.40796"
        r="4.25"
        stroke="#F3F4F6"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default DotIcon;
