import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format, isPast, isToday } from "date-fns";

import { getStandardizedDate } from "../../../utils/helpers";

import ProfileImage from "../../../components/ProfileImage";
import Table from "../../../components/NewTable/index";
import { TableCell } from "../../../components/NewTable/components";

const OverdueDeliverables = ({ deliverables = [] }) => {
  const [overdueDeliverables, setOverdueDeliverables] = useState([]);

  useEffect(() => {
    setOverdueDeliverables(
      deliverables
        .filter(
          (deliverable) =>
            deliverable.shipBy?.length &&
            isPast(
              getStandardizedDate(
                deliverable.shipBy[deliverable.shipBy.length - 1].date,
              ),
            ) &&
            !isToday(
              getStandardizedDate(
                deliverable.shipBy[deliverable.shipBy.length - 1].date,
              ),
            ) &&
            deliverable.status !== "Approved",
        )
        // Get the list of approvers that haven't approved the deliverable
        .map((deliverable) => {
          const notApproved = [];

          deliverable.approvers?.forEach((approver) => {
            let deliverableApproved = false;

            // Check to see if an approver is in the approvedBy array
            deliverable.approvedBy?.forEach((approved) => {
              if (approver._id === approved._id) {
                deliverableApproved = true;
              }
            });

            if (!deliverableApproved) {
              notApproved.push(approver);
            }
          });

          return {
            ...deliverable,
            notApproved,
          };
        })
        // sort by date
        .sort(
          (a, b) =>
            new Date(
              a.shipBy?.length ? a.shipBy[a.shipBy.length - 1].date : 0,
            ) -
            new Date(b.shipBy?.length ? b.shipBy[b.shipBy.length - 1].date : 0),
        ),
    );
  }, [deliverables]);

  return (
    <Container>
      <Table
        showAll
        noPadding
        headers={[
          {
            name: "Status",
            accessor: "status",
          },
          {
            name: "Owner",
            accessor: "owner.name",
          },
          {
            name: "Deliverables",
            accessor: "name",
          },
          {
            name: "Waiting On",
          },
          {
            name: "Due Date",
            accessor: "dueDate",
          },
        ]}
        entries={
          overdueDeliverables
            ? overdueDeliverables.map((deliverable) => {
                return {
                  ...deliverable,
                  dueDate: deliverable.shipBy?.length
                    ? new Date(
                        deliverable.shipBy[deliverable.shipBy.length - 1].date,
                      ).toISOString()
                    : new Date(0).toISOString(),
                  row: (
                    <>
                      <Cell full>
                        {/* {deliverable.status || "N/A"} */}
                        🚨
                      </Cell>
                      <Cell>
                        <ProfileImage
                          handle={deliverable.owner?.handle || undefined}
                          name={deliverable.owner?.name || ""}
                          xsmall
                        />
                      </Cell>
                      <Cell>{deliverable.name || "N/A"}</Cell>
                      <Cell full>
                        <Approvers>
                          {deliverable.notApproved?.length
                            ? deliverable.notApproved.map((approver, index) => (
                                <ProfileImage
                                  key={approver._id}
                                  handle={approver.handle}
                                  name={approver.name}
                                  hasBorder
                                  position={index}
                                  xsmall
                                />
                              ))
                            : "N/A"}
                        </Approvers>
                      </Cell>
                      <Cell>
                        {deliverable.shipBy?.length
                          ? format(
                              getStandardizedDate(
                                deliverable.shipBy[
                                  deliverable.shipBy.length - 1
                                ].date,
                              ),
                              "MM/dd/yyyy",
                            )
                          : "N/A"}
                      </Cell>
                    </>
                  ),
                };
              })
            : []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 700px;

  [data-table-inner] {
    height: 240px;
  }

  table {
    th,
    td {
      padding: 8.5px 7.5px;

      &:first-of-type {
        padding: 8.5px;
        padding-right: 0;
      }
    }

    thead {
      position: relative;
      z-index: 5;

      tr {
        &,
        th {
          position: sticky;
          top: 0;
        }
      }
    }

    tbody {
      tr {
        font-size: 14px;
      }
    }
  }
`;

const Cell = styled(TableCell)`
  padding: 10px 20px;
`;

const Approvers = styled.div`
  display: flex;
`;

export default OverdueDeliverables;
