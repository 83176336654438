import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

import CloseIcon from "../icons/CloseIcon";

const CloseButton = ({ color, transparent, ...rest }) => {
  return (
    <Button color={color} transparent={transparent} {...rest}>
      <CloseIcon />
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset()}
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 26px;

  opacity: ${(props) => (props.transparent ? "0.4" : "1")};

  path {
    fill: ${(props) =>
      props.color === "white"
        ? props.theme.colors.pureWhite
        : props.theme.coolGray700};
  }

  &:hover {
    svg {
      //opacity: 0.2;
      animation: wiggle 2s ease-in-out infinite;
    }
  }

  @keyframes wiggle {
    0% {
      transform: rotate(6deg);
    }
    6.25% {
      transform: rotate(-6deg);
    }
    12.5% {
      transform: rotate(10deg);
    }
    18.75% {
      transform: rotate(-3deg);
    }
    25% {
      transform: rotate(0deg);
    }
  }
`;

CloseButton.propTypes = {
  color: PropTypes.oneOf(["white", "gray"]),
  transparent: PropTypes.bool,
};

CloseButton.defaultProps = {
  color: "gray",
  transparent: true,
};

export default CloseButton;
