import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  isSameDay,
  eachDayOfInterval,
  isToday,
  isWeekend,
  format,
} from "date-fns";
import ProfileImage from "../../../../components/ProfileImage";
import Circle from "./Circle";

import Tooltip from "../../../../components/Tooltip";

import { getStandardizedDateTime } from "../../../../utils/helpers";

const DayCompletionTable = ({ data, oldestDate, handleScroll }) => {
  const [arrayOfDays, setArrayOfDays] = useState([new Date()]);

  useEffect(() => {
    const newDayArray = eachDayOfInterval({
      start: oldestDate,
      end: new Date(),
    });

    if (newDayArray.length > 0) {
      setArrayOfDays(newDayArray.reverse());
    }
  }, [oldestDate]);

  return (
    <Table>
      <Sidebar>
        {data.map((group, index) => (
          <Group key={index}>
            <HeaderRow>
              <Header>{group.team}</Header>
              <Header>Name</Header>
              <Header>Manager</Header>
              <Header>Last 30 days</Header>
            </HeaderRow>
            {group.members.map((member, memberIndex) => (
              <Row key={memberIndex}>
                <Item>
                  <ProfileImage
                    handle={member.handle}
                    name={member.name}
                    small
                    showStatus
                  />
                </Item>
                <Item>{member.name}</Item>
                <Item>{member.manager ? member.manager.name : "N/A"}</Item>
                <Item>
                  <Circle
                    status={member.last30.pass > 0 ? "success" : "neutral"}
                    amount={member.last30.pass}
                  />
                  <Circle
                    status={member.last30.fail > 0 ? "fail" : "neutral"}
                    amount={member.last30.fail}
                  />
                </Item>
              </Row>
            ))}
          </Group>
        ))}
      </Sidebar>
      <Main onScroll={handleScroll}>
        <MainInner>
          {data.map((group, groupIndex) => (
            <Group key={groupIndex}>
              <HeaderRow>
                {arrayOfDays.map((day, index) =>
                  !isWeekend(day) ? (
                    <Header key={index}>{format(day, "E M/d")}</Header>
                  ) : null,
                )}
              </HeaderRow>
              {group.members.map((member, memberIndex) => (
                <Row key={memberIndex}>
                  {arrayOfDays.map((day, dayIndex) => (
                    <SingleDayEntry
                      key={dayIndex}
                      day={day}
                      member={member}
                      uniqueId={`${groupIndex}-${memberIndex}-comp-${dayIndex}`}
                    />
                  ))}
                </Row>
              ))}
            </Group>
          ))}
        </MainInner>
      </Main>
    </Table>
  );
};

const SingleDayEntry = ({ day, member, uniqueId }) => {
  if (isWeekend(day)) return null;

  let thisDayInfo = null;
  let canShowPopup = false;

  member.days.forEach((tempDayInfo) => {
    if (
      isSameDay(
        getStandardizedDateTime(tempDayInfo.date),
        getStandardizedDateTime(day),
      )
    ) {
      thisDayInfo = tempDayInfo;
    }
  });

  let status = "neutral";

  if (thisDayInfo) {
    const notCompleted = thisDayInfo.completions.filter(
      (item) => !item.completed,
    );

    if (notCompleted.length === 0) {
      status = "success";
    } else {
      canShowPopup = true;

      if (!isToday(getStandardizedDateTime(day))) {
        status = "fail";
      }
    }
  } else {
    status = "neutral";
  }

  return (
    <Item data-tip data-for={uniqueId}>
      <Circle status={status} />
      {thisDayInfo && canShowPopup ? (
        <Tooltip id={uniqueId} place="right">
          <TooltipList>
            {thisDayInfo.completions.map((completion, completionIndex) => (
              <ListItem key={completionIndex}>
                <Circle
                  status={completion.completed ? "success" : "fail"}
                  small
                />{" "}
                {completion.text}
              </ListItem>
            ))}
          </TooltipList>
        </Tooltip>
      ) : null}
    </Item>
  );
};

const Table = styled.div`
  position: relative;
  display: flex;
`;

const Group = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
`;

const HeaderRow = styled(Row)`
  height: 25px;
  border-bottom: 1px solid #001940;
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: ${(props) => props.theme.colors.oldBlack2};
`;

const Item = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
`;

const TooltipList = styled.ul`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  & > :first-child {
    margin-right: 7px;
  }
`;

const Sidebar = styled.div`
  position: relative;
  height: 100%;
  width: 35%;
  padding-bottom: 70px;

  &::after {
    content: "";
    position: absolute;
    top: 25px;
    right: 0;
    height: calc(100% - 25px);
    width: 1px;
    background-color: rgba(0, 0, 0, 0.25);
  }

  ${Header} {
    flex: 1 25%;

    &:first-child {
      flex: 0 0 80px;
    }

    &:last-child {
      flex: 0 0 90px;
    }
  }

  ${Item} {
    flex: 1 25%;

    &:first-child {
      flex: 0 0 80px;
    }

    &:last-child {
      flex: 0 0 90px;
    }

    &:not(:last-child) {
      padding-right: 10px;
    }
  }
`;

const Main = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const MainInner = styled.div`
  width: max-content;
  padding-bottom: 70px;

  ${Header} {
    width: 82px;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  ${Item} {
    width: 82px;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export default DayCompletionTable;
