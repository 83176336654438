import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getYear, getQuarter } from "date-fns";

import { useNotifications } from "../../context/notificationsContext";

import {
  getMembersFromApi,
  getAllBookingPlansFromApi,
  getTeamsFromApi,
} from "../manage/utils/api";
import { getMemberEntriesFromApi } from "./utils/api";

import { respondTo } from "../../styles/styleHelpers";
import { TableHeader, TableRow, TableCell } from "../../components/Table";
import Budget from "../projects/components/Budget";

const Booking = () => {
  const [sortBy, setSortBy] = useState("newest");
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState(null);
  const [bookingPlans, setBookingPlans] = useState(null);
  const { setLoading } = useNotifications();

  const year = getYear(new Date());

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the teams
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedTeams = [...teams];

    if (sortBy === "name-asc") {
      sortedTeams.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedTeams.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget1-asc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q1.tracked / a.bookingGoals[0].q1.total || 0;
          if (!a.bookingGoals[0].q1.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q1.tracked / b.bookingGoals[0].q1.total || 0;
          if (!b.bookingGoals[0].q1.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget1-desc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q1.tracked / a.bookingGoals[0].q1.total || 0;
          if (!a.bookingGoals[0].q1.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q1.tracked / b.bookingGoals[0].q1.total || 0;
          if (!b.bookingGoals[0].q1.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget2-asc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q2.tracked / a.bookingGoals[0].q2.total || 0;
          if (!a.bookingGoals[0].q2.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q2.tracked / b.bookingGoals[0].q2.total || 0;
          if (!b.bookingGoals[0].q2.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget2-desc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q2.tracked / a.bookingGoals[0].q2.total || 0;
          if (!a.bookingGoals[0].q2.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q2.tracked / b.bookingGoals[0].q2.total || 0;
          if (!b.bookingGoals[0].q2.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget3-asc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q3.tracked / a.bookingGoals[0].q3.total || 0;
          if (!a.bookingGoals[0].q3.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q3.tracked / b.bookingGoals[0].q3.total || 0;
          if (!b.bookingGoals[0].q3.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget3-desc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q3.tracked / a.bookingGoals[0].q3.total || 0;
          if (!a.bookingGoals[0].q3.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q3.tracked / b.bookingGoals[0].q3.total || 0;
          if (!b.bookingGoals[0].q3.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget4-asc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q4.tracked / a.bookingGoals[0].q4.total || 0;
          if (!a.bookingGoals[0].q4.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q4.tracked / b.bookingGoals[0].q4.total || 0;
          if (!b.bookingGoals[0].q4.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget4-desc") {
      sortedTeams.sort(function(a, b) {
        let textA = 1;
        let textB = 1;

        if (a.bookingGoals[0] && b.bookingGoals[0]) {
          textA =
            a.bookingGoals[0].q4.tracked / a.bookingGoals[0].q4.total || 0;
          if (!a.bookingGoals[0].q4.total) {
            textA = -1;
          }
          textB =
            b.bookingGoals[0].q4.tracked / b.bookingGoals[0].q4.total || 0;
          if (!b.bookingGoals[0].q4.total) {
            textB = -1;
          }
        }
        if (!a.bookingGoals[0] || textA === undefined) {
          textA = -1;
        }
        if (!b.bookingGoals[0] || textB === undefined) {
          textB = -1;
        }

        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setTeams(sortedTeams);
  }, [sortBy]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Toggle the sort to asc or desc
  |--------------------------------------------------------------------------
  */
  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get teams, members, and budget plans
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    setLoading(true);
    getTeams();
    getMembers();
    getBookingPlans();
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get All Teams
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    try {
      const results = await getTeamsFromApi();

      setTeams(results);
      return;
    } catch (error) {
      console.error("Get teams error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();
      console.log("Members", results);
      setMembers(results);
      return;
    } catch (error) {
      console.error("Get members error: " + error);
    }
  };

  /*
|--------------------------------------------------------------------------
| Get All Budget Plans
|--------------------------------------------------------------------------
*/
  const getBookingPlans = async () => {
    try {
      const results = await getAllBookingPlansFromApi();
      setBookingPlans(results);
      return;
    } catch (error) {
      console.error("Get budget plans error: " + error);
    }
  };

  /*
|--------------------------------------------------------------------------
| Get All Entries and calculate the tracked hours for a member
|--------------------------------------------------------------------------
*/
  const getEntries = async (id) => {
    try {
      const result = await getMemberEntriesFromApi(id, `${year}-01-01`);
      const timeEntries = result?.data?.data?.result;

      const trackedHours = {
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
      };

      timeEntries.forEach((entry) => {
        const quarter = getQuarter(new Date(entry.spent_date));

        if (quarter === 1) {
          trackedHours.q1 += entry.rounded_hours;
        } else if (quarter === 2) {
          trackedHours.q2 += entry.rounded_hours;
        } else if (quarter === 3) {
          trackedHours.q3 += entry.rounded_hours;
        } else if (quarter === 4) {
          trackedHours.q4 += entry.rounded_hours;
        }
      });

      return trackedHours;
    } catch (error) {
      console.error("Get project error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get tracked hours and total hours for the teams
  |--------------------------------------------------------------------------
  */
  const getHours = async () => {
    try {
      let tempMembers = [...members];
      const tempTeams = [...teams];

      // Get tracked and total hours for each member
      tempMembers = await Promise.all(
        tempMembers.map(async (member) => {
          member.total = getTotalHours(member._id);
          member.tracked = await getEntries(member.harvestId);
          return member;
        }),
      );

      // Loop through each member and add their tracked/total values to their respective teams
      tempMembers.forEach((member) => {
        tempTeams.forEach((team) => {
          if (team.bookingGoals) {
            team.bookingGoals.forEach((goal) => {
              if (!goal.q1.tracked) {
                goal.q1.tracked = 0;
              }
              if (!goal.q1.total) {
                goal.q1.total = 0;
              }
              if (!goal.q2.tracked) {
                goal.q2.tracked = 0;
              }
              if (!goal.q2.total) {
                goal.q2.total = 0;
              }
              if (!goal.q3.tracked) {
                goal.q3.tracked = 0;
              }
              if (!goal.q3.total) {
                goal.q3.total = 0;
              }
              if (!goal.q4.tracked) {
                goal.q4.tracked = 0;
              }
              if (!goal.q4.total) {
                goal.q4.total = 0;
              }

              if (
                goal.year === year.toString() &&
                team._id === member.role.team._id
              ) {
                goal.q1.tracked += member.tracked.q1;
                goal.q1.total += member.total.q1;
                goal.q2.tracked += member.tracked.q2;
                goal.q2.total += member.total.q2;
                goal.q3.tracked += member.tracked.q3;
                goal.q3.total += member.total.q3;
                goal.q4.tracked += member.tracked.q4;
                goal.q4.total += member.total.q4;
              }
            });
          }
        });
      });

      setLoading(false);
      setTeams(tempTeams);

      return tempTeams;
    } catch (error) {
      console.error("Get Hours error: " + error);
    }
  };

  /*
|--------------------------------------------------------------------------
| Get total hours
|--------------------------------------------------------------------------
*/
  const getTotalHours = (id) => {
    const tempBookingPlans = [...bookingPlans];
    const totalHours = {
      q1: 0,
      q2: 0,
      q3: 0,
      q4: 0,
    };

    tempBookingPlans.forEach((plan) => {
      if (plan.memberId === id) {
        plan.weeks.forEach((week) => {
          const quarter = getQuarter(new Date(week.weekDate));
          if (quarter === 1) {
            totalHours.q1 += week.hours;
          } else if (quarter === 2) {
            totalHours.q2 += week.hours;
          } else if (quarter === 3) {
            totalHours.q3 += week.hours;
          } else if (quarter === 4) {
            totalHours.q4 += week.hours;
          }
        });
      }
    });

    return totalHours;
  };

  /*
  |--------------------------------------------------------------------------
  | Get hours
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (
      members &&
      members.length >= 1 &&
      bookingPlans &&
      bookingPlans.length >= 1 &&
      teams &&
      teams.length
    ) {
      getHours();
    }
  }, [members, bookingPlans]); //eslint-disable-line

  return (
    <Container>
      <Name>Booking Report</Name>

      <Table>
        <thead>
          <tr>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("name")}
              isArrowUp={sortBy === "name-desc"}
            >
              Team
            </StyledTableHeader>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("budget1")}
              isArrowUp={sortBy === "budget1-desc"}
            >
              2021 Q1
            </StyledTableHeader>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("budget2")}
              isArrowUp={sortBy === "budget2-desc"}
            >
              2021 Q2
            </StyledTableHeader>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("budget3")}
              isArrowUp={sortBy === "budget3-desc"}
            >
              2021 Q3
            </StyledTableHeader>
            <StyledTableHeader
              align="left"
              onClick={() => changeSort("budget4")}
              isArrowUp={sortBy === "budget4-desc"}
            >
              2021 Q4
            </StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {teams &&
            teams.map((team, teamIndex) => {
              return (
                <TableRow key={teamIndex}>
                  <StyledTableCell align="left">{team.name}</StyledTableCell>
                  {team.bookingGoals &&
                    team.bookingGoals.map((goal, goalIndex) => {
                      if (goal.year === year.toString()) {
                        if (goal.q1) {
                          return (
                            <StyledTableCell align="left" key={goalIndex}>
                              <StyledBudget
                                minimal
                                tracked={Math.round(goal.q1.tracked)}
                                budget={Math.round(goal.q1.total)}
                                low={goal.q1.minimumTarget}
                                high={goal.q1.goalTarget}
                              />
                              <BookingInfo>
                                <p>Tracked: {Math.round(goal.q1.tracked)}</p>
                                <p>
                                  Total planned: {Math.round(goal.q1.total)}
                                </p>
                                <p>Minimum target: {goal.q1.minimumTarget}</p>
                                <p>Goal target: {goal.q1.goalTarget}</p>
                              </BookingInfo>
                            </StyledTableCell>
                          );
                        } else if (goal.q2) {
                          return (
                            <StyledTableCell align="left" key={goalIndex}>
                              <StyledBudget
                                minimal
                                tracked={Math.round(goal.q2.tracked)}
                                budget={Math.round(goal.q2.total)}
                                low={goal.q2.minimumTarget}
                                high={goal.q2.goalTarget}
                              />
                              <BookingInfo>
                                <p>Tracked: {Math.round(goal.q2.tracked)}</p>
                                <p>
                                  Total planned: {Math.round(goal.q2.total)}
                                </p>
                                <p>Minimum target: {goal.q2.minimumTarget}</p>
                                <p>Goal target: {goal.q2.goalTarget}</p>
                              </BookingInfo>
                            </StyledTableCell>
                          );
                        } else if (goal.q3) {
                          return (
                            <StyledTableCell align="left" key={goalIndex}>
                              <StyledBudget
                                minimal
                                tracked={Math.round(goal.q3.tracked)}
                                budget={Math.round(goal.q3.total)}
                                low={goal.q3.minimumTarget}
                                high={goal.q3.goalTarget}
                              />
                              <BookingInfo>
                                <p>Tracked: {Math.round(goal.q3.tracked)}</p>
                                <p>
                                  Total planned: {Math.round(goal.q3.total)}
                                </p>
                                <p>Minimum target: {goal.q3.minimumTarget}</p>
                                <p>Goal target: {goal.q3.goalTarget}</p>
                              </BookingInfo>
                            </StyledTableCell>
                          );
                        } else if (goal.q4) {
                          return (
                            <StyledTableCell align="left" key={goalIndex}>
                              <StyledBudget
                                minimal
                                tracked={Math.round(goal.q4.tracked)}
                                budget={Math.round(goal.q4.total)}
                                low={goal.q4.minimumTarget}
                                high={goal.q4.goalTarget}
                              />
                              <BookingInfo>
                                <p>Tracked: {Math.round(goal.q4.tracked)}</p>
                                <p>
                                  Total planned: {Math.round(goal.q4.total)}
                                </p>
                                <p>Minimum target: {goal.q4.minimumTarget}</p>
                                <p>Goal target: {goal.q4.goalTarget}</p>
                              </BookingInfo>
                            </StyledTableCell>
                          );
                        } else return null;
                      } else return null;
                    })}
                </TableRow>
              );
            })}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  padding: 53px;
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontSize_l};
  color: ${(props) => props.theme.colors.darkSlate};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 80px;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const Table = styled.table``;

const StyledTableHeader = styled(TableHeader)`
  padding: 0px 40px 20px 40px;

  &:first-of-type {
    padding-left: 0px;
    padding-right: 40px;
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 30px 40px;
  position: relative;

  &:first-of-type {
    padding-right: 60px;
  }
`;

const BookingInfo = styled.div`
  position: absolute;
  left: 40px;
  bottom: 0;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(90%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const StyledBudget = styled(Budget)`
  cursor: help;

  &:hover ~ ${BookingInfo} {
    opacity: 1;
    transform: translateY(75%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

export default Booking;
