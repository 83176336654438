import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CaretLeft, CaretRight } from "../../../../components/buttons/Carets";

const WeekFilter = ({
  weekNumber,
  weekDuration,
  handlePrevWeek,
  handleNextWeek,
}) => {
  return (
    <Container>
      <Header>
        <PreviousWeek onClick={handlePrevWeek}></PreviousWeek>
        <WeekNumber>Week {weekNumber}</WeekNumber>
        <NextWeek onClick={handleNextWeek}></NextWeek>
      </Header>
      <Duration>{weekDuration}</Duration>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Duration = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: bold;
  color: ${(props) => props.theme.colors.mediumGray};
  margin: 0px;
`;

const PreviousWeek = styled(CaretLeft)``;

const NextWeek = styled(CaretRight)``;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const WeekNumber = styled.p`
  font-weight: bold;
  margin: 0px 10px;
`;

WeekFilter.propTypes = {
  weekNumber: PropTypes.number,
  weekDuration: PropTypes.string,
  handleSwitch: PropTypes.func,
  handlePrevWeek: PropTypes.func,
  handleNextWeek: PropTypes.func,
};

WeekFilter.defaultProps = {
  weekNumber: 0,
  weekDuration: "0",
  handlePrevWeek: null,
  handleNextWeek: null,
};

export default WeekFilter;
