import React from "react";
import styled from "styled-components";

import Editable from "../../../components/Editable";

const ProgressBar = ({
  numStages,
  current,
  currentText,
  handleEdit,
  editOptions,
}) => {
  return (
    <Container>
      <Bar>
        {Array.from({ length: numStages }).map((item, index) => (
          <Item
            key={index}
            completed={index + 1 < current}
            active={index + 1 === current}
          />
        ))}
      </Bar>

      <Editable
        id="progress-bar-text"
        options={editOptions || undefined}
        onSave={handleEdit ? handleEdit : undefined}
      >
        <Text>{currentText}</Text>
      </Editable>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Bar = styled.div`
  width: 191px;
  height: 8px;
  display: flex;
  margin-right: 8px;
`;

const Item = styled.div`
  background: ${(props) =>
    props.active
      ? props.theme.colors.indigo600
      : props.completed
      ? props.theme.colors.indigo800
      : props.theme.colors.blueGray200};
  border-radius: 2px;
  height: 100%;
  flex: 1;

  &:not(:last-child) {
    margin-right: 2px;
  }
`;

const Text = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.indigo600};
  margin-bottom: 0;
`;

export default ProgressBar;
