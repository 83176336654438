import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Avatar from "./Avatar";
import ProfileImage from "./ProfileImage";

const ProfileImageGroup = ({
  maxToShow,
  members,
  plusColor,
  small,
  xsmall,
}) => {
  // ? maxToShow -1 = show all

  const membersToShow =
    members.length < maxToShow || maxToShow === -1 ? members.length : maxToShow;
  const additionalMembers = maxToShow === -1 ? 0 : members.length - maxToShow;

  return (
    <Container>
      {Array.from({ length: membersToShow }).map((item, index) => (
        <ProfileImage
          key={members[index]._id}
          handle={members[index].handle}
          name={members[index].name}
          hasBorder
          small={small}
          xsmall={xsmall}
        />
      ))}
      {additionalMembers > 0 ? (
        <Avatar
          initials={`+${additionalMembers}`}
          tooltip={`${additionalMembers} other${
            additionalMembers > 1 ? "s" : ""
          }`}
          bgColor={plusColor}
          hasBorder
          small={small}
          xsmall={xsmall}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: -8px;
  }
`;

ProfileImageGroup.propTypes = {
  maxToShow: PropTypes.number, // max number of member avatars to show
  members: PropTypes.arrayOf(
    PropTypes.shape({
      handle: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  plusColor: PropTypes.string,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
};
ProfileImageGroup.defaultProps = {
  maxToShow: 3,
  members: [],
  plusColor: "darkGray",
  small: true,
  xsmall: false,
};

export default ProfileImageGroup;
