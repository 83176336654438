import React, { useEffect, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { debounce } from "lodash";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useAuth } from "../../../context/authContext";
import { useSockets } from "../../../context/socketsContext";
import { buttonReset, respondTo } from "../../../styles/styleHelpers";
import { formatNotesObject } from "../../../utils/helpers";
import { updateDealOnApi } from "../../../utils/api";

const DealNotes = ({ dealId, initialNotes, blockedBy }) => {
  const tabs = ["Notes", "Project Plan"];

  const { user } = useAuth();
  const {
    sendSocketEvent,
    startDealNotesListeners,
    stopDealNotesListeners,
  } = useSockets();
  const [currentTab] = useState("Notes");
  // Instead of tracking the value state, we are tracking the entire
  // editor's state
  const [currentEditorState, setCurrentEditorState] = useState();
  const [currentBlocker, setCurrentBlocker] = useState(null);

  // When getting initialNotes, convert them into a ContentState object,
  // and build the Editor State object
  useEffect(() => {
    if (initialNotes) {
      updateNotes(initialNotes);
    }
  }, [initialNotes]);

  useEffect(() => {
    if (blockedBy) {
      setCurrentBlocker(blockedBy);
    }
  }, [blockedBy]);

  useEffect(() => {
    if (dealId && user) {
      startDealNotesListeners(dealId, {
        blockCallback: blockNotes,
        unblockCallback: unblockNotes,
        updateNotesCallback: liveUpdateNotes,
      });

      return () => {
        stopDealNotesListeners(dealId);
      };
    }
  }, [dealId, user]);

  const blockNotes = (newMember) => {
    // Only block if our current user is not the member
    // that is triggering the blocking action
    if (user._id !== newMember.id) {
      setCurrentBlocker(newMember.name);
    }
  };

  // Unblock the notes
  const unblockNotes = () => {
    setCurrentBlocker(null);
  };

  const liveUpdateNotes = (editor, newNotes) => {
    if (user._id !== editor) {
      updateNotes(newNotes);
    }
  };

  const updateNotes = (newNotes) => {
    // Convert raw object to a ContentState object
    const newContent = convertFromRaw(formatNotesObject(newNotes));
    // Create EditorState with our new ContentState
    const newEditorState = EditorState.createWithContent(newContent);

    setCurrentEditorState(newEditorState);
  };

  // uploads to server every X millisecons
  // Is a callback so it doesn't run again every rerender (breaking debounce)
  // Is debounced so that waits until the user stops typing to save
  const uploadNotesDebounced = useCallback(
    debounce((dealId, newContent) => {
      updateDealOnApi(dealId, { notes: newContent });
    }, 1000),
    [],
  );

  // Updates the state every letter,
  // only uploads data when user stops typing
  const updateState = (newState) => {
    setCurrentEditorState(newState);

    // Gets the ContentState object and converts to raw obj
    const newNotes = convertToRaw(newState.getCurrentContent());
    uploadNotesDebounced(dealId, newNotes);
  };

  const startEditing = () => {
    sendSocketEvent("deal:editing-notes", { user: user._id, deal: dealId });
  };

  const stopEditing = () => {
    sendSocketEvent("deal:stop-editing-notes", {
      user: user._id,
      deal: dealId,
    });
  };

  return (
    <Container>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab active={currentTab === tab} key={index}>
            {tab}
          </Tab>
        ))}
      </Tabs>

      {currentTab === "Notes" ? (
        <Main>
          <EditorWrapper isdisabled={currentBlocker ? true : false}>
            <Editor
              editorState={currentEditorState}
              onEditorStateChange={updateState}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-body"
              readOnly={currentBlocker ? true : false}
              onFocus={startEditing}
              onBlur={stopEditing}
            />
          </EditorWrapper>
          {currentBlocker ? (
            <Blocker>{currentBlocker} is editing right now.</Blocker>
          ) : null}
        </Main>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray100};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Tabs = styled.button`
  ${buttonReset()}
  display: flex;
  margin-top: 16px;
  height: 40px;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
`;

const Tab = styled.div`
  height: 40px;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) =>
    props.active
      ? props.theme.colors.indigo600
      : props.theme.colors.coolGray500};

  ${(props) =>
    props.active
      ? css`
          border-bottom: 3px solid ${props.theme.colors.indigo600};
          cursor: default;
        `
      : ``}
`;

const Main = styled.div`
  position: relative;
  width: 100%;
  min-height: 753px;

  .editor-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .editor-body {
    padding: 10px 30px;
    flex: 1;

    ${respondTo("xlarge")} {
      padding: 38px 64px;
    }
  }
`;

const EditorWrapper = styled.div`
  ${(props) =>
    props.isdisabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
          filter: blur(1px);
        `
      : ``}
`;

const Blocker = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 16px;
  line-height: 24px;

  color: ${(props) => props.theme.colors.indigo600};
  background: ${(props) => props.theme.colors.pureWhite};

  padding: 10px 18px;

  border: 1px solid ${(props) => props.theme.colors.coolGray300};
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
`;

export default DealNotes;
