import React, { Fragment } from "react";
import styled from "styled-components";
import { darken } from "polished";
import { format } from "date-fns";
import { startCase } from "lodash";

import Tooltip from "../../../components/Tooltip";

import { getStandardizedDate } from "../../../utils/helpers";

const StageProgress = ({
  minimal,
  stageNumber,
  currentStage,
  setCurrentStage,
  stages,
  stagesStatus,
  archived,
  id,
}) => {
  return (
    <Container minimal={minimal}>
      {stages.map((stage, index) => (
        <Fragment key={index}>
          <Stage
            filled={
              (stagesStatus?.[stage]?.completed && stageNumber >= index + 1) ||
              (minimal &&
                stagesStatus?.[stage]?.completed &&
                stageNumber >= index + 2) ||
              archived
            }
            active={currentStage === index + 1}
            onClick={
              setCurrentStage ? () => setCurrentStage(index + 1) : undefined
            }
            next={stageNumber === index + 1}
            minimal={minimal}
            data-tip
            data-for={`${id}-stage-${index}`}
          >
            <Title>{startCase(stage)}</Title>

            {stagesStatus?.[stage]?.dueDate ? (
              <DueDate>
                {format(
                  getStandardizedDate(stagesStatus[stage].dueDate),
                  "MM/dd/yy",
                )}
              </DueDate>
            ) : null}

            {stagesStatus?.[stage]?.completedDate ? (
              <CompletedDate>
                {format(
                  getStandardizedDate(stagesStatus[stage].completedDate),
                  "MM/dd/yy",
                )}
              </CompletedDate>
            ) : null}
          </Stage>

          {minimal ? (
            <Tooltip id={`${id}-stage-${index}`} place="top">
              {startCase(stage)}
            </Tooltip>
          ) : null}
        </Fragment>
      ))}
    </Container>
  );
};

const Title = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
`;

const DueDate = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
`;

const CompletedDate = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
`;

const Stage = styled.button`
  position: relative;
  width: 10%;
  margin: 0px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? "#5048E5" : props.filled ? "#372FA2" : "#E2E8F0"};
  color: ${(props) =>
    props.active ? "white" : props.filled ? "#6467F2" : "#6B7280"};
  text-align: center;
  line-height: 1.2;
  cursor: ${(props) => (props.minimal ? "default" : "pointer")};
  transition: 0.2s ease-in-out;

  &:first-of-type {
    margin-left: 0px;
    border-radius: 25px 5px 5px 25px;
  }

  &:last-of-type {
    margin-right: 0px;
    border-radius: 5px 25px 25px 5px;
  }

  &:hover {
    background-color: ${darken(0.2, "#372FA2")};
    color: white;
  }

  ${Title} {
    display: ${(props) =>
      (props.minimal && props.next) || !props.minimal ? "block" : "none"};
    font-size: ${(props) => (props.minimal ? "10px" : "14px")};
  }

  ${DueDate} {
    font-size: ${(props) => (props.minimal ? "10px" : "14px")};
    display: ${(props) => (props.filled ? "none" : "block")};
  }

  ${CompletedDate} {
    font-size: ${(props) => (props.minimal ? "10px" : "14px")};
    display: ${(props) => (props.filled ? "block" : "none")};
  }
`;

const Container = styled.div`
  position: relative;
  width: ${(props) => (props.minimal ? "700px" : "100%")};
  height: ${(props) => (props.minimal ? "40px" : "auto")};
  min-height: ${(props) => (props.minimal ? "40px" : "50px")};

  display: flex;
  /* align-items: center; */

  margin-bottom: ${(props) => (props.minimal ? "0px" : "50px")};
  /* pointer-events: ${(props) => (props.minimal ? "none" : "all")}; */
  z-index: 2;
`;

StageProgress.defaultProps = {
  id: "",
};

export default StageProgress;
