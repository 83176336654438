import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; //eslint-disable-line
import { Draggable } from "@fullcalendar/interaction";
import styled from "styled-components";
import {
  differenceInMinutes,
  subHours,
  format,
  addWeeks,
  subWeeks,
  addHours,
  addMinutes,
} from "date-fns";

import { useAuth } from "../../context/authContext";
import { useActiveTimer } from "../../context/activeTimerContext";
import { useNotifications } from "../../context/notificationsContext";

import Calendar from "./components/calendar";
import Tasks from "./components/tasks";
import {
  CaretLeft as Prev,
  CaretRight as Next,
} from "../../components/buttons/Carets";
import CaretLeft from "../../components/icons/CaretLeft";
import CaretRight from "../../components/icons/CaretRight";

import {
  getWeekBookingPlanFromApi,
  updateWeekBookingPlanOnApi,
} from "./utils/api";
import {
  getProjectsFromApi,
  startTimerOnApi,
  getManagerChildrenFromApi,
} from "../../utils/api";

import { getWeek, getStandardizedDate } from "../../utils/helpers";
import { respondTo } from "../../styles/styleHelpers";

const WorkPlanner = () => {
  const history = useHistory();

  const { user, isAdmin } = useAuth();
  const { refreshSidebarCounter } = useActiveTimer();
  const { openAlertPopup } = useNotifications();

  // so we know which bookingPlans to get from the backend
  const [currentDate, setCurrentDate] = useState(new Date());

  // bookingPlans for the current week
  // start as null so we know when they're grabbed from the API
  const [budgets, setBudgets] = useState(null);

  // the "events" on the calendar
  const [timeslots, setTimeslots] = useState([]);

  // the users on this user's team
  const [team, setTeam] = useState([]);

  const [dragging, setDragging] = useState(false);
  const [backlogOpen, setBacklogOpen] = useState(true);
  const [isManager, setIsManager] = useState(false);

  // date of the current day on the calendar (relevant for the manager view)
  const [calendarDate, setCalendarDate] = useState(null);

  const [eventRevert, setEventRevert] = useState(false);

  const calendarRef = useRef(null);

  const calendarViews = {
    default: "timeGridWeek",
    manager: "resourceTimeGridDay",
  };

  useEffect(() => {
    // set the draggable area for the budgets
    new Draggable(document.getElementById("budgets-drag"), {
      itemSelector: "[data-event]",
    });

    // keydown shortcut for toggling the backlog
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); //eslint-disable-line

  // update budgets when date changes
  useEffect(() => {
    if (user) {
      // if we've already loaded in some budgets, clear them out
      if (budgets?.length) {
        setBudgets(null);
      }

      // if we haven't retrieved the user's team
      if (!team.length) {
        // grab the other users in their team (for the manager view)
        getTeam();
      }

      // get the budgets from the API
      getBudgets();
    }
  }, [currentDate, isManager, user]); //eslint-disable-line

  // ? since the user can't be on any other routes when a timer is running, updating the tracked time here is unnecessary
  // // as the active timer ticks, update the trackedTime for the corresponding budget
  // useEffect(() => {
  //   if (budgets) {
  //     // split the timer into an array
  //     const timerArray = timerText.split(":");

  //     // get the hours, minutes, and seconds
  //     const timerHours = parseInt(timerArray[0]);
  //     const timerMin = parseInt(timerArray[1]);
  //     const timerSec = parseInt(timerArray[2]);

  //     // add the seconds onto the minutes
  //     const timerTotalMinutes = timerMin + timerSec / 60;
  //     // add the minutes onto the hours
  //     const timerTotalHours = timerHours + timerTotalMinutes / 60;

  //     // loop over the budgets
  //     const newBudgets = budgets.map((budget) => {
  //       let { trackedHours, runningHours } = budget;

  //       // if the budget has a running timer
  //       if (runningHours > 0) {
  //         // amount of time that has ellapsed on the timer since page load
  //         const timeEllapsed = timerTotalHours - runningHours;

  //         // if more than 8 minutes have ellapsed (half of 15min, rounded to the nearest minute), round up to the next 15min
  //         // so 45min + 8min would round up to a full hour, but 45min + 7min would still just be 45min.
  //         if (timeEllapsed >= 8 / 60) {
  //           trackedHours += 0.25;
  //           runningHours += 0.25;
  //         }
  //       }

  //       return {
  //         ...budget,
  //         trackedHours,
  //         runningHours,
  //       };
  //     });

  //     setBudgets(newBudgets);
  //   }
  // }, [timerText]);

  // update timeslots when budgets are updated
  useEffect(() => {
    // if there are budgets
    if (budgets?.length) {
      const newTimeslots = [];

      // set the events for the calendar according to the breakdowns of the budgets
      budgets.forEach((budget) => {
        budget?.breakdown.forEach((timeslot) => {
          newTimeslots.push({
            id: budget.id,
            title: budget.name,
            code: budget.code,
            start: new Date(timeslot.start),
            end: new Date(timeslot.end),
            resourceId: budget.member._id,
          });
        });
      });

      setTimeslots(newTimeslots);
    } else if (timeslots.length) {
      // there are no budgets, but there are timeslots. so clear out the old timeslots
      setTimeslots([]);
    }
  }, [budgets]); //eslint-disable-line

  // when manager view is toggled
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      // get the current view of the calendar
      const currentView = calendarApi.view.type;

      // if we're in manager view, make sure the view of the calendar is set accordingly
      if (isManager && currentView !== calendarViews.manager) {
        calendarApi.changeView(calendarViews.manager);
      } else if (!isManager && currentView !== calendarViews.default) {
        // if we're in the regular view, make sure the view of the calendar is set accordingly
        calendarApi.changeView(calendarViews.default);
      }
    }
  }, [isManager]); //eslint-disable-line

  const getTeam = async () => {
    try {
      // get the members that this user manages
      const members = await getManagerChildrenFromApi(user._id);

      setTeam([...members, user]);
    } catch (err) {
      console.error("getTeam failed");
    }
  };

  const getBudgets = async () => {
    try {
      // the users we need to grab the budgets for
      // - if in manager view, we'll need budgets for the whole team
      // - otherwise, we only need budgets for this user
      const members = isManager ? team : [user];

      // array of requests for the budgets
      const budgetRequests = members.map((member) => getBudgetForUser(member));

      // await for them all to finish
      const allBudgets = await Promise.all(budgetRequests);

      // flatten them into one single array (since they are each returned as an array, so otherwise we'd have an array of arrays)
      setBudgets(allBudgets.flat());
    } catch (err) {
      console.error("getBudgets failed");
    }
  };

  const getBudgetForUser = async (member) => {
    try {
      // get the bookingPlans from the API that have time budgetted for this week
      const bookingPlansRequest = getWeekBookingPlanFromApi(
        member.email,
        format(currentDate, "yyyy-MM-dd"),
      );

      // get the projects from the API for this user (which includes the time entries)
      // (only includes projects that are active this week)
      const projectsRequest = getProjectsFromApi(
        member.email,
        format(currentDate, "yyyy-MM-dd"),
      );

      const [bookingPlansFromApi, projectsFromApi] = await Promise.all([
        bookingPlansRequest,
        projectsRequest,
      ]);

      // loop over the data
      return bookingPlansFromApi.data.data.bookingPlans.map((bookingPlan) => {
        // get the week budget that corresponds to this week
        const thisWeek = bookingPlan.weeks.find(
          (week) =>
            getWeek(getStandardizedDate(week.weekDate)) ===
            getWeek(getStandardizedDate(currentDate)),
        );

        // get the corresponding project for this budget plan
        const thisProject = projectsFromApi.data.data.projects.find(
          (project) => project._id === bookingPlan.projectId._id,
        );

        // the amount of hours that have been tracked to this project
        let trackedHours = 0;

        // amount of hours that the currently running timer has
        let runningHours = 0;

        // if theres a corresponding project
        if (thisProject) {
          // get the time entries for the project
          const timeEntries = thisProject.entries.filter((entry) => {
            const spentDate = getStandardizedDate(entry.spent_date);
            const entryWeekNumber = getWeek(spentDate);

            // only get the entries for this week, for this user
            return (
              entryWeekNumber === getWeek(getStandardizedDate(currentDate)) &&
              entry.user.id === member.harvestId
            );
          });

          // loop over the entries and add up the total amount of tracked hours
          timeEntries.forEach((time) => {
            trackedHours += time.rounded_hours;

            // if the timer is running, remember its current amount of hours
            if (time.is_running) {
              runningHours = time.rounded_hours;
            }
          });

          // round the tracked hours
          trackedHours = Math.round(trackedHours * 100) / 100;
        }

        return {
          id: bookingPlan._id,
          code: bookingPlan.projectId.code,
          name: bookingPlan.projectId.name,
          hours: thisWeek?.hours || 0,
          trackedHours,
          runningHours,
          breakdown: thisWeek?.breakdown || [],
          project: {
            id: bookingPlan.projectId.harvestId,
            startDate: bookingPlan.projectId.startDate,
            endDate: bookingPlan.projectId.endDate,
            taskId: projectsFromApi.data.data.task.harvestId,
          },
          member,
        };
      });
    } catch (err) {
      console.error("error fetching the budgets", err);

      openAlertPopup("Something went wrong", "Please refresh and try again.");
    }
  };

  // hide the backlog once all tasks are completed (left this here, but felt it was unnecessary)
  // useEffect(() => {
  //   let scheduled = 0;
  //   let total = 0;

  //   budgets.forEach((budget) => {
  //     total += budget.hours;

  //     budget.breakdown.forEach((timeSlot) => {
  //       // get the difference in minutes
  //       const diff = differenceInMinutes(timeSlot.end, timeSlot.start);
  //       // divide by 60 to convert it to hours
  //       scheduled += diff / 60;
  //     });
  //   });

  //   setBacklogOpen(scheduled === total ? false : true);
  // }, [budgets]);

  // when an event is dropped onto the calendar
  const handleDrop = ({ draggedEl, date, resource }) => {
    // make sure its one of the data-event elements
    const isDraggableEvent = draggedEl.hasAttribute("data-event");

    if (isDraggableEvent) {
      // get the event data from the element
      const eventString = draggedEl.getAttribute("data-event");
      const event = JSON.parse(eventString);

      // get the duration of the event
      const duration = event.duration.split(":");
      const hours = duration[0];
      const mins = duration[1];

      // use the duration to determine when it ends ("date" is the start time)
      let endDate = addHours(date, Number(hours));
      endDate = addMinutes(endDate, Number(mins));

      // if the event should be allowed in this spot (don't allow events to overlap)
      let isAllowed = true;

      // if the event was dropped on a resource column (person) that doesn't correspond with the event's resource (person)
      if (resource && resource.id !== event.resourceId) {
        // don't allow an event for one person to be dropped onto another person's column
        isAllowed = false;
      }

      // start and end of the new event
      const startTime = new Date(date).getTime();
      const endTime = new Date(endDate).getTime();

      // loop over all the existing timeslots
      // (that correspond to that particular person, in case we're in manager view)
      timeslots
        .filter((timeslot) => timeslot.resourceId === event.resourceId)
        .forEach((timeslot) => {
          // start and end of this timeslot
          const timeslotStart = new Date(timeslot.start).getTime();
          const timeslotEnd = new Date(timeslot.end).getTime();

          // if the event's start time overlaps with the existing timeslot
          const startTimeOverlap =
            startTime > timeslotStart && startTime < timeslotEnd;

          // if the event's end time overlaps with the existing timeslot
          const endTimeOverlap =
            endTime > timeslotStart && endTime < timeslotEnd;

          // if the event is dropped exactly on top of the existing timeslot
          const exactOverlap =
            startTime === timeslotStart || endTime === timeslotEnd;

          // if any of the above are true, don't allow the event to be dropped here
          if (startTimeOverlap || endTimeOverlap || exactOverlap) {
            isAllowed = false;
          }
        });

      // if the new event isn't overlapping any existing timeslots
      if (isAllowed) {
        // loop over the budgets
        const newBudgets = budgets.map((budget) => {
          // find the corresponding budget
          if (budget.id === event.id) {
            // add a new item to its breakdown
            const newBreakdown = [
              ...budget.breakdown,
              {
                start: date,
                end: endDate,
              },
            ];

            // update it on the backend
            updateBookingPlan(budget.id, newBreakdown);

            return {
              ...budget,
              breakdown: newBreakdown,
            };
          } else {
            return budget;
          }
        });

        // update the budgets
        setBudgets(newBudgets);
      }
    }
  };

  // when an existing event is dragged around on the calendar
  const handleEventChange = ({ event, oldEvent }) => {
    // if the action is being reverted
    if (eventRevert) {
      // reset the value of eventRevert
      setEventRevert(false);

      // stop execution here
      return;
    }

    const newStart = new Date(event.start);
    let newEnd = new Date(event.end);

    // in case the event's end time needs to be decreased for going over the budget limit
    let timeRestriction;

    // loop over the budgets
    const newBudgets = budgets.map((budget) => {
      // find the corresponding budget
      if (budget.id === event.id) {
        // determine how much time has already been scheduled
        let scheduled = 0;

        // determine the original duration of the timeslot that was adjusted
        let prevDuration;

        budget.breakdown.forEach((timeslot) => {
          // get the difference in minutes
          const diff = differenceInMinutes(
            new Date(timeslot.end),
            new Date(timeslot.start),
          );
          // divide by 60 to convert it to hours
          scheduled += diff / 60;

          // if this is the timeslot that was updated
          if (
            new Date(timeslot.start).getTime() ===
              new Date(oldEvent.start).getTime() &&
            new Date(timeslot.end).getTime() ===
              new Date(oldEvent.end).getTime()
          ) {
            prevDuration = diff / 60;
          }
        });

        // if we know what the prev duration was (this won't apply if the change was triggered by moveEnd)
        if (prevDuration) {
          // the duration of the adjusted timeslot
          const newDuration = differenceInMinutes(newEnd, newStart) / 60;

          const durationDiff = newDuration - prevDuration;

          // newly amount of time scheduled (considering the timeslot that was just adjusted)
          const newScheduled = scheduled + durationDiff;

          // if more time was added to the timeslot AND that added time puts us over the budgetted time for the budget
          if (durationDiff > 0 && newScheduled > budget.hours) {
            // adjust the end time for the timeslot in the budget breakdown
            newEnd = subHours(newEnd, newScheduled - budget.hours);

            // the amount of minutes that we will need to move the event back on the calendar so its not going over the budgetted time
            timeRestriction = { minutes: (newScheduled - budget.hours) * -60 };

            // return the budget as it is (handleEventChange will get triggered again by moveEnd, so the new breakdown values will be set then)
            return budget;
          }
        }

        // loop over the budget's breakdown
        const newBreakdown = budget.breakdown.map((timeslot) => {
          // find the corresponding timeslot (use the start time, since the end time could be different if we had to use moveEnd to restrict it)
          if (
            new Date(timeslot.start).getTime() ===
            new Date(oldEvent.start).getTime()
          ) {
            // give timeslot the new start and end times
            return {
              start: newStart,
              end: newEnd,
            };
          } else {
            // otherwise, return the timeslot as is
            return timeslot;
          }
        });

        // update it on the backend
        updateBookingPlan(budget.id, newBreakdown);

        // update the budget with the new breakdown
        return {
          ...budget,
          breakdown: newBreakdown,
        };
      } else {
        return budget;
      }
    });

    // if this event needs to have its end time decreased to fit within the bounds of the budgetted time
    if (timeRestriction) {
      // move the event back on the calendar so its not going over the budgetted time
      event.moveEnd(timeRestriction);
    } else {
      // update the budgets
      setBudgets(newBudgets);
    }
  };

  const handleEventRemove = (event) => {
    // loop over the budgets
    const newBudgets = budgets.map((budget) => {
      // find the corresponding budget
      if (budget.id === event.id) {
        // remove the corresponding item from its breakdown
        const newBreakdown = budget.breakdown.filter(
          (timeslot) =>
            new Date(timeslot.start).getTime() !==
              new Date(event.start).getTime() &&
            new Date(timeslot.end).getTime() !== new Date(event.end).getTime(),
        );

        // update it on the backend
        updateBookingPlan(budget.id, newBreakdown);

        return {
          ...budget,
          breakdown: newBreakdown,
        };
      } else {
        return budget;
      }
    });

    // update the budgets
    setBudgets(newBudgets);

    // remove the event from the calendar
    event.remove();
  };

  const updateBookingPlan = (id, breakdown) => {
    // use the ID of the updated budget to update its breakdown in the backend

    updateWeekBookingPlanOnApi(
      id,
      format(currentDate, "yyyy-MM-dd"),
      breakdown,
    );
  };

  const startTimer = async (budgetId) => {
    const thisBudget = budgets.find((budget) => budgetId === budget.id);

    const response = await startTimerOnApi({
      entries: [],
      email: user.email,
      projectId: thisBudget.project.id,
      taskId: thisBudget.project.taskId,
      date: format(new Date(), "yyyy-MM-dd"),
      time: format(new Date(), "K:mma"),
      startDate: thisBudget.project.startDate,
      endDate: thisBudget.project.endDate,
    });

    refreshSidebarCounter();
    history.push(`/projects/${response.redirectProject}`);
  };

  // when the date on the calendar is changed
  // (week or day is adjusted using the API methods)
  const handleDatesSet = ({ start }) => {
    setCalendarDate(start);
  };

  // when existing event is dropped onto a new spot
  const handleEventDrop = ({ oldResource, newResource, revert }) => {
    // when in the manager view, don't allow events to be dragged onto a different user
    if (oldResource && newResource && oldResource.id !== newResource.id) {
      // revert the action on the calendar
      revert();
      // denote that we should not follow through with the new position
      setEventRevert(true);
    }
  };

  const goPrevWeek = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      if (isManager) {
        // adjust date on calendar (go back 1 week from the current day)
        calendarApi.gotoDate(subWeeks(calendarDate, 1));
      } else {
        // adjust the week on the calendar (go to prev week)
        calendarApi.prev();
      }

      // adjust the week for our currentDate (which dictates which bookingPlans we grab from the backend)
      setCurrentDate(subWeeks(currentDate, 1));
    }
  };

  const goNextWeek = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      if (isManager) {
        // adjust date on calendar (go forward 1 week from the current day)
        calendarApi.gotoDate(addWeeks(calendarDate, 1));
      } else {
        // adjust the week on the calendar (go to next week)
        calendarApi.next();
      }

      // adjust the week for our currentDate (which dictates which bookingPlans we grab from the backend)
      setCurrentDate(addWeeks(currentDate, 1));
    }
  };

  const goPrevDay = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      // get the current date of the calendar
      const calDate = calendarApi.getDate();

      // determine current day of week
      // (1 = Mon, 2 = Tue, ... 7 = Sun)
      const dayOfWeek = format(new Date(calDate), "i");

      // if we're not at the beginning of the week, go to the prev day
      // (so the day arrows can't take you to the prev week)
      if (dayOfWeek > 1) {
        calendarApi.prev();
      }
    }
  };

  const goNextDay = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      // get the current date of the calendar
      const calDate = calendarApi.getDate();

      // determine current day of week
      // (1 = Mon, 2 = Tue, ... 7 = Sun)
      const dayOfWeek = format(new Date(calDate), "i");

      // if we're not at the end of the week, go to the next day
      // (so the day arrows can't take you to the next week)
      if (dayOfWeek < 7) {
        calendarApi.next();
      }
    }
  };

  // Hides the sidebar when you press CMD+K
  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "k") {
      setBacklogOpen((backlogOpen) => !backlogOpen);
    }
  };

  return (
    <Container>
      <CalCol expanded={!backlogOpen}>
        <Header>
          <HeaderInner>
            <Heading>My {isManager ? "Team" : "Week"}</Heading>
            <HeaderControls>
              <Prev onClick={goPrevWeek} />
              <Week>Week {getWeek(getStandardizedDate(currentDate))}</Week>
              <Next onClick={goNextWeek} />
            </HeaderControls>

            {isManager ? (
              <HeaderControls>
                <Prev onClick={goPrevDay} />
                <Week>
                  {calendarDate
                    ? format(new Date(calendarDate), "MMM d")
                    : null}
                </Week>
                <Next onClick={goNextDay} />
              </HeaderControls>
            ) : null}

            {/* only show team directors (and admins) the manager view  */}
            {user &&
            (user.role.tier.toLowerCase() === "director" || isAdmin) ? (
              <HeaderLink
                onClick={() => {
                  setIsManager(!isManager);
                }}
              >
                View My {isManager ? "Week" : "Team"}
              </HeaderLink>
            ) : null}
          </HeaderInner>
        </Header>

        <CalContainer>
          <Calendar
            budgets={budgets ? budgets : []}
            dragging={dragging}
            showWeekends={!backlogOpen}
            isManager={isManager}
            user={user}
            events={timeslots}
            resources={team.map((member) => {
              // each member is a resource for the manager view
              return { id: member._id, title: member.handle };
            })}
            handleEventRemove={handleEventRemove}
            calendarRef={calendarRef}
            handleTimerStart={startTimer}
            dayOfWeek={
              calendarDate ? format(new Date(calendarDate), "iiii") : null
            }
            // item dropped onto calendar
            drop={handleDrop}
            // existing event moved around on the calendar
            eventChange={handleEventChange}
            datesSet={handleDatesSet}
            eventDrop={handleEventDrop}
            eventDragStart={() => {
              setDragging(true);
            }}
            eventDragStop={() => {
              setDragging(false);
            }}
            eventResizeStart={() => {
              setDragging(true);
            }}
            eventResizeStop={() => {
              setDragging(false);
            }}
          />
        </CalContainer>
      </CalCol>

      <TasksDrawer id="budgets-drag" expanded={backlogOpen}>
        <DrawerActions>
          <DrawerToggle
            onClick={() => {
              setBacklogOpen(!backlogOpen);
            }}
          >
            {backlogOpen ? <CaretRight /> : <CaretLeft />}
          </DrawerToggle>
        </DrawerActions>

        <TasksInner>
          <TasksHeader>
            <Heading>{isManager ? "Team" : "My"} Projects</Heading>
          </TasksHeader>
          <TasksList>
            {budgets ? (
              <Tasks
                tasks={budgets}
                groups={
                  isManager
                    ? team.map((member) => {
                        // to organize the tasks into groups for each member in the manager view
                        return { id: member._id, title: member.handle };
                      })
                    : // when not in the manager view, the user is the only group
                      [{ id: user._id, title: user.handle }]
                }
              />
            ) : null}
          </TasksList>
        </TasksInner>
      </TasksDrawer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* margin: 20px 50px; */
  flex-grow: 1;

  margin-left: 8px;

  ${respondTo("xlarge")} {
    margin-left: 25px;
  }
`;

const CalCol = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: #fcfcfc;
  padding-top: 40px;
  padding-left: 25px;

  flex-basis: ${(props) => (props.expanded ? `100%` : `77.5%`)};
  max-width: ${(props) => (props.expanded ? `100%` : `77.5%`)};

  ${respondTo("xlarge")} {
    flex-basis: ${(props) => (props.expanded ? `100%` : `75%`)};
    max-width: ${(props) => (props.expanded ? `100%` : `75%`)};
  }
`;

const CalContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;

const TasksDrawer = styled.div`
  position: relative;
  display: flex;
  flex-basis: ${(props) => (props.expanded ? `22.5%` : `0%`)};
  max-width: ${(props) => (props.expanded ? `22.5%` : `0%`)};

  ${respondTo("xlarge")} {
    flex-basis: ${(props) => (props.expanded ? `25%` : `0%`)};
    max-width: ${(props) => (props.expanded ? `25%` : `0%`)};
  }
`;

const DrawerActions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 30px;
  background-color: ${(props) => props.theme.colors.pureWhite};
`;

const DrawerToggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors.oldLightGray};
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: none;
  }
`;

const TasksInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 0;
  overflow: hidden;
`;

const TasksList = styled.div`
  position: relative;
  flex-grow: 1;
`;

const Heading = styled.h2`
  /* font-size: 24px; */
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 60px; */
  /* margin-bottom: 40px; */
  margin-bottom: 35px;
  padding-right: 25px;

  ${Heading} {
    margin-right: 38px;
    margin-bottom: 0;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  align-items: baseline;
  flex-grow: 1;
`;

const HeaderControls = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLink = styled.button`
  color: ${(props) => props.theme.colors.slate};
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  margin-left: auto;
  transition: color 200ms;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.oldBlack2};
  }
`;

const Week = styled.span`
  font-size: 18px;
  margin: 0 10px;
`;

const TasksHeader = styled.div`
  /* margin-bottom: 35px; */
  margin-bottom: 75px;
  padding: 0 15px;
`;

export default WorkPlanner;
