import React, { useState, useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import FileModalView from "./fileModalView";
import { useFile } from "../../context/fileContext";
import { useAuth } from "../../context/authContext";

const FileModal = () => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const {
    isFileModalOpen,
    closeFileModal,
    uploadNewVersion,
    fileInfo,
    fileType,
    comments,
    setComments,
    editComment,
    deleteComment,
    completeComment,
    postComment,
    isFileLoading,
    setIsFileLoading,
    feedbackCompleted,
    currentDeliverable,
    downloadUrl,
    latestVersion,
    downloadFile,
    denyDeliverable,
    approveDeliverable,
    optionalApproveDeliverable,
    submitForReview,
  } = useFile();
  const { user } = useAuth();

  const [newComment, setNewComment] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [tool, setTool] = useState("none");
  const [dimensions, setDimensions] = useState({ w: 0, h: 0 });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const isDrawing = useRef(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // eslint-disable-line

  // adjust dimensions when window is resized
  useEffect(() => {
    const imageFile = document.getElementById("file-img");
    const pageFile = document.getElementById("file-page");

    const newDims = {};

    if (fileType === "image" && imageFile) {
      newDims.w = imageFile.offsetWidth;
      newDims.h = imageFile.offsetHeight;
    } else if (fileType === "pdf" && pageFile) {
      newDims.w = pageFile.width;
      newDims.h = pageFile.height;
    }

    setDimensions(newDims);
  }, [windowWidth, windowHeight]); //eslint-disable-line

  useEffect(() => {
    // if a new file is loading, reset the dimensions
    if (isFileLoading) {
      setDimensions({});
    }
  }, [isFileLoading]);

  useEffect(() => {
    const tempComments = [...comments];

    if (sortBy === "Date") {
      tempComments.sort((a, b) => {
        return a.comment.postedDate - b.comment.postedDate;
      });
    } else if (sortBy === "Page") {
      tempComments.sort((a, b) => {
        return a.page - b.page;
      });
    }

    setComments(tempComments);
  }, [sortBy]);

  // Creates the comment object
  const createComment = ({ tool, points, version }) => {
    return {
      comment: {
        author: { _id: user._id, name: user.name, handle: user.handle },
        postedDate: new Date().toISOString(),
        message: "",
      },
      mark: {
        tool,
        points,
      },
      page: pageNumber,
      version: version?._id,
      highlighted: false,
      hovered: false,
    };
  };

  // Sets the dimensions of the canvas to that of the image
  const setImageDimensions = (target) => {
    const newDims = {};

    if (fileType === "image") {
      newDims.w = target.currentTarget.offsetWidth;
      newDims.h = target.currentTarget.offsetHeight;
    } else if (fileType === "pdf") {
      newDims.w = target.width;
      newDims.h = target.height;
    }

    setDimensions(newDims);
  };

  // When drawing, we create a comment on mouse down
  const handleMouseDown = (e, version) => {
    if (tool === "draw") {
      isDrawing.current = true;

      const pos = e.target.getStage().getPointerPosition();

      // set the point as a relative value
      const points = [pos.x / dimensions.w, pos.y / dimensions.h];

      setNewComment(createComment({ tool, points, version }));
    }
  };

  // When drawing, adds points when the mouse moves
  const handleMouseMove = (e) => {
    if (tool === "draw") {
      // no drawing - skipping
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();

      // set the point as a relative value
      const newPoint = [point.x / dimensions.w, point.y / dimensions.h];

      const tempComment = { ...newComment };
      tempComment.mark.points = tempComment.mark.points.concat(newPoint);

      setNewComment(tempComment);
    }
  };

  const handleMouseUp = (e, version) => {
    // If they don't have a tool selected, and click the canvas, we clear any highlighting
    if (tool === "none") {
      highlightComment(null);
      return;
    }

    // If they are done drawing, we stop the drawing flag
    if (tool === "draw") {
      isDrawing.current = false;

      //if they are using the point tool, we create the comment all at once now
    } else if (tool === "point") {
      const pos = e.target.getStage().getPointerPosition();

      // set the point as a relative value
      const points = [pos.x / dimensions.w, pos.y / dimensions.h];

      setNewComment(createComment({ tool, points, version }));
    }

    // No matter which tool they have, we focus on the comment message box so they can type
    document.getElementById("new-comment-message").focus();
  };

  // Saves the new comment to the full list of comments
  const saveNewComment = (message, parent) => {
    // But only if the message is not empty
    if (message !== "") {
      let tempComment;
      if (newComment) {
        tempComment = { ...newComment };
      } else {
        tempComment = createComment({
          tool: null,
          points: null,
          version: latestVersion,
        });
      }
      tempComment.comment.postedDate = new Date().toISOString();
      tempComment.comment.message = message;

      if (parent) {
        tempComment.parent = parent;
      }

      if (newComment) {
        postComment(newComment);
      } else {
        postComment(tempComment);
      }

      clearNewComment();
    }
  };

  // Clears out the new comment stuff
  const clearNewComment = () => {
    setNewComment(null);
    setNewMessage("");
  };

  // Sets which comment should be highlighted on both the sidebar and the canvas
  const highlightComment = (id) => {
    let newPage = null;

    const newComments = comments.map((comment) => {
      if (comment.id === id) {
        newPage = comment.page;
        comment.highlighted = true;
      } else {
        comment.highlighted = false;
      }

      return comment;
    });

    setComments(newComments);

    if (newPage) {
      setPageNumber(newPage);
    }
  };

  const hoverComment = (id) => {
    const newComments = comments.map((comment) => {
      if (comment.id === id) {
        comment.hovered = true;
      } else {
        comment.hovered = false;
      }
      return comment;
    });

    setComments(newComments);
  };

  const handleKeyDown = (e) => {
    // This wierd if updates the state,
    // without this, the state would be stale,
    // and wouldn't update correctly
    // eslint-disable-next-line
    if ((newComment) => newComment) {
      if (e.keyCode === 27 || (e.metaKey && e.keyCode === 90)) {
        // ESC, or Command + Z
        // Clears the new comment
        clearNewComment();
      }
    }
  };

  const decPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      setPageNumber(1);
    }
  };

  const incPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    } else {
      setPageNumber(numPages);
    }
  };

  return (
    <FileModalView
      id={fileInfo?._id}
      isOpen={isFileModalOpen}
      close={closeFileModal}
      fileType={fileType}
      fileInfo={fileInfo}
      fileName={fileInfo?.fileName}
      fileVersions={fileInfo?.versions}
      uploadNewVersion={uploadNewVersion}
      creator={fileInfo?.owner}
      createdDate={fileInfo?.createdAt}
      updatedDate={fileInfo?.updatedAt}
      isLoading={isFileLoading}
      setIsLoading={setIsFileLoading}
      comments={comments}
      tool={tool}
      setTool={setTool}
      dimensions={dimensions}
      setImageDimensions={setImageDimensions}
      handleMouseDown={handleMouseDown}
      handleMouseMove={handleMouseMove}
      handleMouseUp={handleMouseUp}
      newComment={newComment}
      saveNewComment={saveNewComment}
      clearNewComment={clearNewComment}
      newMessage={newMessage}
      setNewMessage={setNewMessage}
      highlightComment={highlightComment}
      hoverComment={hoverComment}
      numPages={numPages}
      setNumPages={setNumPages}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      incPage={incPage}
      decPage={decPage}
      editComment={editComment}
      deleteComment={deleteComment}
      completeComment={completeComment}
      feedbackCompleted={feedbackCompleted}
      currentDeliverable={currentDeliverable}
      setSortBy={setSortBy}
      downloadUrl={downloadUrl}
      downloadFile={downloadFile}
      latestVersion={latestVersion}
      denyDeliverable={denyDeliverable}
      approveDeliverable={approveDeliverable}
      optionalApproveDeliverable={optionalApproveDeliverable}
      submitForReview={submitForReview}
    />
  );
};

export default FileModal;
