import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

const CreateLink = ({ children, isLight, ...rest }) => {
  return (
    <CreateButton {...rest}>
      <CreateIcon isLight={isLight} />
      <CreateText isLight={isLight}>{children}</CreateText>
    </CreateButton>
  );
};

const CreateText = styled.div`
  position: relative;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: ${(props) =>
    props.isLight ? props.theme.colors.oldWhite : props.theme.colors.darkSlate};
  text-decoration: none;
  padding-bottom: 2px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.lightGreen};
    transform: scaleX(0);
    transition: transform 0.4s;
    transform-origin: left;
  }
`;

const CreateIcon = styled.div`
  position: relative;
  width: 28px;
  height: 28px;

  background-color: ${(props) => (props.isLight ? "white" : "transparent")};
  border: 2px solid #eaeaea;
  border-radius: 8px;

  z-index: 1;
  overflow: hidden;
  transition: border-color 0.6s;

  &::before {
    content: "";
    position: absolute;
    background-color: ${(props) => props.theme.colors.lightGreen};
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom center;
    opacity: 0;
    z-index: -1;
    transition: transform 0.4s, opacity 0.4s;
  }

  &::after {
    content: "+";
    line-height: 0.8;
    font-size: 26px;
    position: absolute;
    left: 50%;
    top: 50%;
    font-weight: 400;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.colors.mediumGray};
    transition: color 0.4s, transform 0.6s;
  }
`;

const CreateButton = styled.button`
  ${buttonReset()}
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    ${CreateIcon} {
      border-color: ${(props) => props.theme.colors.green};
      transition-delay: 0.4s;

      &::before {
        opacity: 1;
        transform: scaleY(1);
      }

      &::after {
        color: ${(props) => props.theme.colors.green};
        transform: translate(-50%, -50%) rotate(180deg);
        transition-delay: 0s, 0.4s;
      }
    }

    ${CreateText} {
      &::after {
        transform: scaleX(1);
        transition-delay: 0.4s;
      }
    }
  }
`;

CreateLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  to: PropTypes.string,
  isLight: PropTypes.bool,
};

CreateLink.defaultProps = {
  children: "Create",
  to: "#",
  isLight: false,
};

export default CreateLink;
