import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useNotifications } from "../../../context/notificationsContext";
import { useAuth } from "../../../context/authContext";

import TimeCard from "./TimeCard";

import {
  getPastEntriesFromApi,
  updatePastEntryFromApi,
  deletePastEntryOnApi,
} from "../utils/api";

const CardContainer = ({
  disabled,
  entry,
  startTimer,
  stopTimer,
  deleteTimer,
  isActiveTimer,
  email,
  budgetHours,
  actualHours,
  pace,
  paceLabelTop,
  paceLabelBottom,
  isInProduction,
  isPersonal,
  openStopModal,
}) => {
  const history = useHistory();
  const { openAlertPopup } = useNotifications();
  const { isManagerOrAdmin } = useAuth();

  const [editing, setEditing] = useState(null);
  const [notes, setNotes] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [entryEditing, setEntryEditing] = useState(null);
  const [pastEntries, setPastEntries] = useState(null);

  const [entryId, setEntryId] = useState(null);
  const [entryDate, setEntryDate] = useState(null);
  const [entryTitle, setEntryTitle] = useState(null);
  const [entryStart, setEntryStart] = useState(null);
  const [entryEnd, setEntryEnd] = useState(null);
  const [entryNotes, setEntryNotes] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | If there is no active timer, set editing and notes to false
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (!isActiveTimer) {
      setEditing(false);
      setNotes(null);
    }
  }, [isActiveTimer]);

  /*
  |--------------------------------------------------------------------------
  | If the menu is opened, get all the past entries
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (menuOpen) {
      getPastEntries();
    }
  }, [menuOpen]);

  /*
  |--------------------------------------------------------------------------
  | Get a list of all the past entries for this card
  |--------------------------------------------------------------------------
  */
  const getPastEntries = async () => {
    try {
      const response = await getPastEntriesFromApi({
        projectId: entry.project.harvestId,
        taskId: entry.task.harvestId,
        email,
      });

      const entries = response.data.data.result;

      // Remove the first entry if there is an active timer
      if (isActiveTimer) {
        entries.shift();
      }

      setPastEntries(entries);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update entry
  |--------------------------------------------------------------------------
  */
  const updateEntry = async () => {
    try {
      const data = {
        id: entryId,
        start: entryStart,
        end: entryEnd,
        notes: entryNotes,
      };

      const response = await updatePastEntryFromApi(data);

      if (response && response.result) {
        openAlertPopup(
          "Success",
          "The time entry has been successfully updated.",
          true,
        );

        setEntryEditing(false);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete entry
  |--------------------------------------------------------------------------
  */
  const deleteEntry = async () => {
    try {
      const response = await deletePastEntryOnApi(entryId);

      if (response) {
        openAlertPopup(
          "Success",
          "The time entry has been successfully deleted",
          true,
        );
        setEntryEditing(false);

        // Removes deleted entry to store in state
        const updatedEntries = pastEntries.filter((pastEntry) => {
          return pastEntry.id !== entryId;
        });
        setPastEntries(updatedEntries);
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get logged hours for each task
  |--------------------------------------------------------------------------
  */
  const getHours = (timeEntries) => {
    let totalHours = 0;

    if (timeEntries) {
      timeEntries.forEach((time) => {
        totalHours += time.rounded_hours;
      });
    }

    if (totalHours > 0) {
      totalHours = Math.ceil(totalHours * 4) / 4;
      totalHours = Math.round(totalHours * 100) / 100;
    }

    return totalHours;
  };

  return (
    <TimeCard
      disabled={disabled}
      isActiveTimer={isActiveTimer}
      isInProduction={isInProduction}
      budgetHours={budgetHours}
      trackedHours={getHours(actualHours)}
      pace={pace}
      paceLabelTop={paceLabelTop}
      paceLabelBottom={paceLabelBottom}
      handleProjectRoute={
        isManagerOrAdmin
          ? () => history.push(`/projects/${entry.project._id}`)
          : null
      }
      editing={editing}
      setEditing={setEditing}
      notes={notes}
      setNotes={setNotes}
      startTimer={startTimer}
      stopTimer={stopTimer}
      deleteTimer={deleteTimer}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      entry={entry}
      updateEntry={updateEntry}
      deleteEntry={deleteEntry}
      entryEditing={entryEditing}
      setEntryEditing={setEntryEditing}
      entryStart={entryStart}
      setEntryStart={setEntryStart}
      entryTitle={entryTitle}
      setEntryTitle={setEntryTitle}
      entryDate={entryDate}
      setEntryDate={setEntryDate}
      entryEnd={entryEnd}
      setEntryEnd={setEntryEnd}
      entryNotes={entryNotes}
      setEntryNotes={setEntryNotes}
      pastEntries={pastEntries}
      setEntryId={setEntryId}
      isPersonal={isPersonal}
      openStopModal={openStopModal}
    />
  );
};

export default CardContainer;
