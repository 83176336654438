import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  format,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  addMonths,
} from "date-fns";

import { getStandardizedDate } from "../../utils/helpers";

import { useNotifications } from "../../context/notificationsContext";

import { getEverything } from "./utils/api";
import { respondTo } from "../../styles/styleHelpers";
import { getWeek } from "../../utils/helpers";

import AdminCard from "./components/AdminCard";
import WeekSettings from "./components/WeekSettings";

const AdminDashboard = () => {
  const { setLoading, openAlertPopup } = useNotifications();

  // all members and all projects
  const [members, setMembers] = useState(null);
  const [projects, setProjects] = useState(null);

  const [currentDate, setCurrentDate] = useState(new Date());

  // an entry includes a member, the projects they are assigned to, and the total amount of time that has been scheduled for them for the current week
  const [entries, setEntries] = useState(null);

  // total amount of time booked for this week, across all members
  const [booked, setBooked] = useState(null);
  // minimum amount of time that should be booked for this week
  const [capacity, setCapacity] = useState(null);

  // whether to display the all-time view or the current week view
  const [displayAllTime, setDisplayAllTime] = useState(false);

  const currentYear = new Date(currentDate).getFullYear();

  // get projects and members on mount
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (members && projects) {
      getEntries();
    }
  }, [members, projects, currentDate]);

  useEffect(() => {
    if (entries) {
      let tempCapacity = 0;
      let tempBooked = 0;

      entries.forEach((entry) => {
        if (entry.member.capacityLow) {
          tempCapacity += parseInt(entry.member.capacityLow);
        }
        if (entry.totalWeekBudget) {
          tempBooked += entry.totalWeekBudget;
        }
      });

      setCapacity(tempCapacity);
      setBooked(tempBooked);
    }
  }, [entries]);

  const getData = async () => {
    setLoading(true);

    try {
      const resultsFromApi = await getEverything();

      // Sort members alphabetically
      resultsFromApi.members.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setMembers(resultsFromApi.members);

      const projectsWithBookingPlans = resultsFromApi.projects.map(
        (project) => {
          const bookingPlans = resultsFromApi.bookingPlans.filter(
            (plan) => plan.projectId === project._id,
          );

          return { ...project, bookingPlans };
        },
      );
      setProjects(projectsWithBookingPlans);
    } catch (error) {
      console.error("Get everything error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `There was an issue getting the projects and members. ${errMessage}`,
      );
    }

    setLoading(false);
  };

  const getEntries = () => {
    const allEntries = [];

    // Filter out the members whose start and end dates aren't within the current week
    const activeMembers = members.filter((member) => {
      let shouldShow = false;

      const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
      const endOfCurrentWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

      // show this member if
      // - they don't have a start or end date
      // - their start date is before the current week, and end date is after the current week
      if (
        (!member.startDate || new Date(member.startDate) < endOfCurrentWeek) &&
        (!member.endDate || new Date(member.endDate) > startOfCurrentWeek)
      ) {
        shouldShow = true;
      }

      return shouldShow;
    });

    // Loop over each member
    activeMembers.forEach((member) => {
      const memberProjects = projects
        // only include projects that have time budgeted for the current week
        .filter((project) => {
          let shouldInclude = false;

          // project must have budget plans, and at least one of them must be for the current week
          if (project.bookingPlans) {
            project.bookingPlans.forEach((plan) => {
              plan.weeks.forEach((item) => {
                const planWeekNumber = getWeek(
                  getStandardizedDate(item.weekDate),
                );

                const planYear = new Date(
                  getStandardizedDate(item.weekDate),
                ).getFullYear();

                if (planWeekNumber === weekNumber && planYear === currentYear) {
                  shouldInclude = true;
                }
              });
            });
          }

          return shouldInclude;
        })
        // only include projects that this member has been assigned to
        .filter((project) => {
          // check if the project has a bookingPlan with the same memberId as the current member
          const memberPlan = project.bookingPlans.findIndex(
            (budget) => budget.memberId === member._id,
          );

          return memberPlan > -1;
        })
        // add up the budgeted hours for each of this member's projects
        .map((project) => {
          // hours this member has been scheduled to work on this project:
          // for this week:
          let weekBudget = 0;
          // for all time:
          let allTimeBudget = 0;

          // Find the bookingPlan with the same memberId as the current member
          const memberPlan = project.bookingPlans.find(
            (budget) => budget.memberId === member._id,
          );

          // Loop through all the weeks in the plan and add up the hours
          if (memberPlan?.weeks) {
            memberPlan.weeks.forEach((item) => {
              const planWeekNumber = getWeek(
                getStandardizedDate(item.weekDate),
              );

              const planYear = new Date(
                getStandardizedDate(item.weekDate),
              ).getFullYear();

              if (planWeekNumber === weekNumber && planYear === currentYear) {
                weekBudget += item.hours;
              }

              allTimeBudget += item.hours;
            });
          }

          return {
            ...project,
            weekBudget,
            allTimeBudget,
          };
        });

      // total time budgeted for this member for this week, across all projects
      let totalWeekBudget = 0;
      memberProjects.forEach((project) => {
        totalWeekBudget += project.weekBudget;
      });

      allEntries.push({
        member,
        projects: memberProjects,
        totalWeekBudget,
      });
    });

    setEntries(allEntries);
  };

  const startWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  const startWeek = format(startWeekDate, "d");
  const endWeek = format(endWeekDate, "d");

  const thisMonth = format(startWeekDate, "MMMM");
  const thisMonthShort = format(startWeekDate, "MMM");
  const nextMonthShort = format(addMonths(startWeekDate, 1), "MMM");

  const weekDuration =
    // if the end of the week is a smaller number than the beginning of the week, then the week must end with a different month
    parseInt(endWeek) < parseInt(startWeek)
      ? `${thisMonthShort} ${startWeek} - ${nextMonthShort} ${endWeek}`
      : `${thisMonth} ${startWeek} - ${endWeek}`;

  const weekNumber = getWeek(getStandardizedDate(currentDate));

  return (
    <DashboardContainer>
      <Header>
        <HeaderLeft>
          <MainHeader>Admin Dashboard</MainHeader>
        </HeaderLeft>

        {booked && capacity ? (
          <Hours>
            <BookedContainer>
              <HoursTitle>Booked</HoursTitle>
              <HoursAmount>{booked}</HoursAmount>
            </BookedContainer>
            <PercentageBooked>
              <PercentageBookedTitle>vs</PercentageBookedTitle>
              <PercentageBookedAmount>
                {(booked / capacity).toFixed(2)}
              </PercentageBookedAmount>
            </PercentageBooked>
            <CapacityContainer>
              <HoursTitle>Capacity</HoursTitle>
              <HoursAmount>{capacity}</HoursAmount>
            </CapacityContainer>
          </Hours>
        ) : null}

        <WeekSettings
          id="time-entries-switch"
          onText="All Time"
          offText={`Week ${weekNumber}`}
          isActive={displayAllTime}
          weekDuration={weekDuration}
          handleSwitch={(checked) => {
            setDisplayAllTime(checked);
          }}
          handlePrevWeek={() => setCurrentDate(subWeeks(currentDate, 1))}
          handleNextWeek={() => setCurrentDate(addWeeks(currentDate, 1))}
        />
      </Header>

      <CardsContainer>
        <CardHeaders>
          {entries
            ? entries.map((entry, index) => (
                <CardHeader key={index}>
                  <Member>{entry.member.name}</Member>
                  <WeekBudget
                    budget={entry.totalWeekBudget}
                    low={entry.member.capacityLow}
                    high={entry.member.capacityHigh}
                  >
                    {entry.totalWeekBudget}

                    {entry.member.capacityLow && entry.member.capacityHigh ? (
                      <Capacity>
                        <CapacityLow>{entry.member.capacityLow}</CapacityLow>
                        <CapacityHigh>{entry.member.capacityHigh}</CapacityHigh>
                      </Capacity>
                    ) : null}
                  </WeekBudget>
                </CardHeader>
              ))
            : null}
        </CardHeaders>

        <Cards>
          {entries
            ? entries.map((entry, index) => (
                <CardContainer key={index}>
                  {/* <CardHeader>
                  <Member>{entry.member.name}</Member>
                  <WeekBudget budget={entry.totalWeekBudget}>
                    {entry.totalWeekBudget}
                  </WeekBudget>
                </CardHeader> */}

                  {entry.projects.map((project, projectIndex) => {
                    // hide card if it has 0 hours budgetted for the week, and we aren't in AllTime view
                    const hideCard = !displayAllTime && !project.weekBudget;

                    return !hideCard ? (
                      <AdminCard
                        key={`${entry.member._id}-${project._id}-${projectIndex}`}
                        project={project}
                        budgetHours={
                          displayAllTime
                            ? project.allTimeBudget
                            : project.weekBudget
                        }
                        // admin={true}
                        // actualHours={0}
                        // pace={0}
                        // activeTimer={false}
                        // getHours={() => {}}
                        // getTime={() => {}}
                        // getDate={() => {}}
                        // startTimer={() => {}}
                        // stopTimer={() => {}}
                        // deleteTimer={() => {}}
                        // user={entry.member.email}
                        // username={entry.member.name}
                      />
                    ) : null;
                  })}
                </CardContainer>
              ))
            : null}
        </Cards>
      </CardsContainer>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  padding: 53px 0;
  padding-left: 25px;
`;

const CardsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding: 0 25px;
  padding-bottom: 18px;
  margin-right: 25px;

  overflow: auto;

  /* transition: 0.3s ease-in-out; */
`;

const CardContainer = styled.div`
  position: relative;
  width: 200px;
  flex-shrink: 0;
  margin-right: 30px;

  ${respondTo("xlarge")} {
    width: 250px;
    margin-right: 40px;
  }
`;

const CardHeaders = styled.div`
  position: sticky;
  top: 0;

  display: flex;
  width: 100%;

  padding: 20px 0px;
  background-color: #f3f9fd;
  z-index: 2;

  /* overflow-x: hidden; */

  /* so theres padding on the far right side when scrolled all the way */
  &::after {
    content: "";
    display: block;
    padding-left: 1px;
    margin-left: -1px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  width: 200px;
  margin-right: 30px;
  font-size: 16px;

  p {
    margin-bottom: 0px;
  }

  ${respondTo("xlarge")} {
    width: 250px;
    margin-right: 40px;
    font-size: 20px;
  }
`;

const Member = styled.p``;

const WeekBudget = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 22px;
  color: ${(props) =>
    props.budget < props.low || props.budget > props.high
      ? "#DF5757"
      : "black"};
`;

const Cards = styled.div`
  display: flex;
  /* overflow: auto; */

  &::after {
    content: "";
    flex: auto;
  }
`;

const MainHeader = styled.h2`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 25px;
  line-height: 1;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
    margin-bottom: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 55px 21px 26px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const Capacity = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  * {
    font-size: 10px;
    color: black;
  }
`;

const CapacityLow = styled.div`
  border-bottom: 1px solid black;
`;

const CapacityHigh = styled.div``;

const Hours = styled.div`
  display: flex;
`;

const BookedContainer = styled.div`
  text-align: center;
`;

const CapacityContainer = styled.div`
  text-align: center;
`;

const HoursTitle = styled.p`
  color: ${(props) => props.theme.colors.darkSlate};
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
`;

const HoursAmount = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.colors.oldBlack2};
  font-size: 12px;
`;

const PercentageBooked = styled.div`
  text-align: center;
  margin: 0px 25px;
`;

const PercentageBookedTitle = styled.p`
  color: ${(props) => props.theme.colors.oldBlack2};
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px;
`;

const PercentageBookedAmount = styled.p`
  color: ${(props) => props.theme.colors.oldBlack2};
  font-weight: bold;
  font-size: 18px;
`;

export default AdminDashboard;
