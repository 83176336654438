import React, { useState } from "react";
import styled from "styled-components";
import { darken } from "polished";

import { useNotifications } from "../../../context/notificationsContext";

import { createTaskTemplateOnApi } from "../utils/api";

import { Field, Label, Input, Textarea } from "../../../components/Form";
import SelectDropdown from "../../../components/SelectDropdown";
import Button from "../../../components/buttons/Button";

const CreateTaskTemplate = ({
  templateModalActive,
  setTemplateModalActive,
  roles,
  getTaskTemplates,
}) => {
  const { openAlertPopup } = useNotifications();
  const [role, setRole] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [hoursToComplete, setHoursToComplete] = useState(null);
  const [linkText, setLinkText] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Create task template
  |--------------------------------------------------------------------------
  */
  async function createTaskTemplate() {
    try {
      const data = {
        title,
        description,
        hoursToComplete,
        role,
        link: {
          text: linkText,
          url: linkUrl,
        },
      };

      const result = await createTaskTemplateOnApi(data);

      if (result) {
        openAlertPopup("Success", "Task template created successfully!", true);
        getTaskTemplates();
        setTemplateModalActive(false);
      }
      return result;
    } catch (error) {
      openAlertPopup("Failure", "Task template creation failed!");
    }
  }

  return (
    <>
      {templateModalActive && (
        <CreateTaskContainer>
          <CreateTask>
            <CreateTaskInner>
              <CreateTaskLeft>
                <CreateTaskHeader>Task Template</CreateTaskHeader>
                <FlexRow>
                  <StyledField>
                    <Label>Role</Label>
                    {roles && (
                      <StyledSelectDropdown
                        onChange={(e) => setRole(e.value)}
                        placeholder="Select Role"
                        options={roles.map((item) => {
                          return {
                            label: item.role,
                            value: item._id,
                          };
                        })}
                      ></StyledSelectDropdown>
                    )}
                  </StyledField>
                  <StyledField>
                    <Label>Hours To Complete</Label>
                    <Input
                      type="number"
                      onChange={(e) => setHoursToComplete(e.target.value)}
                    />
                  </StyledField>
                </FlexRow>

                <FlexRow>
                  <StyledField>
                    <Label>Link Text</Label>
                    <Input
                      type="text"
                      onChange={(e) => setLinkText(e.target.value)}
                    />
                  </StyledField>
                  <StyledField>
                    <Label>Link Url</Label>
                    <Input
                      type="text"
                      onChange={(e) => setLinkUrl(e.target.value)}
                    />
                  </StyledField>
                </FlexRow>
              </CreateTaskLeft>

              <CreateTaskRight>
                <Field>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Field>

                <Field>
                  <Label>Task Details</Label>
                  <Textarea
                    style={{ height: "150px" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Field>

                <ButtonRow>
                  <Cancel onClick={() => setTemplateModalActive(false)}>
                    Cancel
                  </Cancel>
                  <Save
                    disabled={!(title && role && description)}
                    onClick={() => createTaskTemplate()}
                  >
                    Save
                  </Save>
                </ButtonRow>
              </CreateTaskRight>
            </CreateTaskInner>
          </CreateTask>
        </CreateTaskContainer>
      )}
    </>
  );
};

const CreateTaskContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
`;

const CreateTask = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.4);
  z-index: 4;
  width: 65%;
`;

const CreateTaskInner = styled.div`
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
`;

const CreateTaskLeft = styled.div`
  width: 40%;
`;

const CreateTaskHeader = styled.h3`
  font-size: 24px;
  margin-bottom: 60px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 40%;
  }
`;

const StyledField = styled(Field)`
  width: 45%;

  input {
    width: 100%;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 20px;

  * {
    font-size: 14px;
  }
`;

const CreateTaskRight = styled.div`
  width: 40%;

  input,
  textarea {
    width: 100%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Cancel = styled(Button)`
  margin-right: 20px;
  background-color: #df5757;
  border-color: #df5757;
  min-width: auto;
  width: 120px;

  &::after {
    background-color: ${darken(0.1, "#df5757")};
  }
`;

const Save = styled(Button)`
  min-width: auto;
  width: 120px;
`;

export default CreateTaskTemplate;
