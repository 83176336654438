import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { rgba } from "polished";
import { formatDuration, intervalToDuration } from "date-fns";
import { cloneDeep } from "lodash";

import { buttonReset, respondTo } from "../../styles/styleHelpers";
import { useNotifications } from "../../context/notificationsContext";

import { MenuItem, MenuIcon, MenuText } from "./sidebarView";
import ProfileImage from "../ProfileImage";
import NotificationsIcon from "../icons/NotificationsIcon";
import InboxIcon from "../icons/InboxIcon";
import TrashIcon from "../icons/TrashIcon";
import StarIcon from "../icons/StarIcon";
import Checkmark from "../icons/CheckmarkIcon";
import CloseButton from "../buttons/CloseButton";

const Inbox = ({ isSidebarOpen }) => {
  const history = useHistory();
  const { notifications, updateNotification } = useNotifications();

  const [displayedNotes, setDisplayedNotes] = useState(null);
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [notesFilter, setNotesFilter] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // number of unread notifications
  useEffect(() => {
    const unreadNotes = notifications.filter(getUnreadNotes);
    setUnreadNumber(unreadNotes.length);
  }, [notifications]);

  useEffect(() => {
    // use the proper filtering function depending on which filter was selected
    const filterFunc =
      notesFilter === "unread"
        ? getUnreadNotes
        : notesFilter === "starred"
        ? getStarredNotes
        : notesFilter === "archived"
        ? getArchivedNotes
        : getUnarchivedNotes;

    // filter the notes
    const filteredNotes = notifications.filter(filterFunc);

    setDisplayedNotes(filteredNotes);
  }, [notifications, notesFilter]);

  const getUnreadNotes = (note) => !note.hasRead && !note.isArchived;

  const getStarredNotes = (note) => note.isStarred && !note.isArchived;

  const getArchivedNotes = (note) => note.isArchived;

  const getUnarchivedNotes = (note) => !note.isArchived;

  const clickNotification = (noteId, link) => {
    // mark it as read
    updateNotification(noteId, { hasRead: true });

    if (link) {
      history.push(link);
    }
  };

  const markAllRead = async () => {
    const notesToChange = displayedNotes.filter((note) => !note.hasRead);

    if (notesToChange && notesToChange.length > 0) {
      for (let i = 0; i < notesToChange.length; i++) {
        await updateNotification(notesToChange[i]._id, {
          hasRead: true,
        });
      }

      // Sets all the notes to false, because for some reason, they don't update correctly when you try to do a bunch with the update function
      const updatedNotes = displayedNotes.map((note) => ({
        ...note,
        hasRead: true,
      }));

      setDisplayedNotes(updatedNotes);
    }
  };

  return (
    <Wrapper>
      <MenuItem onClick={() => setIsOpen(!isOpen)} isactive={isOpen}>
        <MenuIcon>
          <NotificationsIcon />
        </MenuIcon>
        <MenuText>Notifications</MenuText>
        <Badge
          isVisible={unreadNumber}
          isSidebarOpen={isSidebarOpen}
          isAbsolute
        >
          {unreadNumber}
        </Badge>
      </MenuItem>

      <Popout isVisible={isOpen}>
        <PopoutHeader>
          <PopoutTitle>Notifications</PopoutTitle>
          <MarkAll onClick={markAllRead}>Mark all as read</MarkAll>
          <Close onClick={() => setIsOpen(false)} transparent={false} />
        </PopoutHeader>

        <Tabs>
          <Tab onClick={() => setNotesFilter(null)} isActive={!notesFilter}>
            All
          </Tab>
          <Tab
            onClick={() => setNotesFilter("unread")}
            isActive={notesFilter === "unread"}
          >
            Unread
            <Badge isVisible={unreadNumber}>{unreadNumber}</Badge>
          </Tab>
          <Tab
            onClick={() => setNotesFilter("starred")}
            isActive={notesFilter === "starred"}
          >
            Starred
          </Tab>
          <Tab
            onClick={() => setNotesFilter("archived")}
            isActive={notesFilter === "archived"}
          >
            Archive
          </Tab>
        </Tabs>

        <NoteList>
          {displayedNotes ? (
            <>
              {displayedNotes.length ? (
                cloneDeep(displayedNotes) // copy the array and reverse it so the newest ones come first
                  .reverse()
                  .map((notification, index) => {
                    const duration = intervalToDuration({
                      start: new Date(notification.createdAt),
                      end: new Date(),
                    });

                    // Clears out everything except the largest duration
                    // That way you don't get times that say "5 days 6 hours and 57 minutes ago"
                    if (duration?.years > 0) {
                      duration.months = 0;
                      duration.weeks = 0;
                      duration.days = 0;
                      duration.hours = 0;
                      duration.minutes = 0;
                    } else if (duration?.months > 0) {
                      duration.weeks = 0;
                      duration.days = 0;
                      duration.hours = 0;
                      duration.minutes = 0;
                    } else if (duration?.weeks > 0) {
                      duration.days = 0;
                      duration.hours = 0;
                      duration.minutes = 0;
                    } else if (duration?.days > 0) {
                      duration.hours = 0;
                      duration.minutes = 0;
                    } else if (duration?.hours > 0) {
                      duration.minutes = 0;
                    }

                    const formattedDuration = formatDuration(duration, {
                      format: [
                        "years",
                        "months",
                        "weeks",
                        "days",
                        "hours",
                        "minutes",
                      ],
                    });

                    return (
                      <NoteItem
                        key={notification._id || index}
                        isFaded={
                          notification.hasRead && !notification.isStarred
                        }
                        isStarred={notification.isStarred}
                        read={notification.hasRead}
                      >
                        <NoteInner>
                          <NoteIcon>
                            <ProfileWrapper read={notification.hasRead}>
                              <ProfileMain
                                handle={
                                  notification?.author?.handle || "sherpa"
                                }
                                name={notification?.author?.name || "Sherpa P"}
                                xsmall
                              />
                              {notification.hasRead ? <Check /> : null}
                              <ReadButton
                                hasRead={notification.hasRead}
                                isShown={!notification.hasRead}
                                title={
                                  notification.hasRead
                                    ? "Mark as Unread"
                                    : "Mark as Read"
                                }
                                onClick={() =>
                                  updateNotification(notification._id, {
                                    hasRead: !notification.hasRead,
                                  })
                                }
                              >
                                <Checkmark />
                              </ReadButton>
                            </ProfileWrapper>
                          </NoteIcon>

                          <ItemContainer>
                            <ItemRow>
                              {/* since buttons can't be nested, use the author to trigger the click on the notification */}
                              <Author
                                onClick={() =>
                                  clickNotification(
                                    notification._id,
                                    notification.link,
                                  )
                                }
                              >
                                {notification?.author?.handle || "Sherpa"}
                              </Author>
                              <StarButton
                                isActive={notification.isStarred}
                                onClick={() =>
                                  updateNotification(notification._id, {
                                    isStarred: !notification.isStarred,
                                  })
                                }
                                title={
                                  notification.isStarred ? "Unstar" : "Star"
                                }
                              >
                                <StarIcon />
                              </StarButton>
                            </ItemRow>

                            <Message>{notification.message}</Message>

                            <ItemRow>
                              <Time>
                                {formattedDuration
                                  ? `${formattedDuration} ago`
                                  : `Just now`}
                              </Time>
                              <TrashButton
                                onClick={() =>
                                  updateNotification(notification._id, {
                                    isArchived: !notification.isArchived,
                                  })
                                }
                                title={
                                  notification.isArchived
                                    ? "Unarchive"
                                    : "Archive"
                                }
                                hasDanger={!notification.isArchived}
                              >
                                {notesFilter === "archived" ? (
                                  <UnarchiveIcon />
                                ) : (
                                  <TrashIcon />
                                )}
                              </TrashButton>
                            </ItemRow>
                          </ItemContainer>
                        </NoteInner>

                        {/* <NoteFooter>
                          <StarButton
                            isActive={notification.isStarred}
                            onClick={() =>
                              updateNotification(notification._id, {
                                isStarred: !notification.isStarred,
                              })
                            }
                            title={notification.isStarred ? "Unstar" : "Star"}
                          >
                            <StarIcon />
                          </StarButton>

                          <TrashButton
                            onClick={() =>
                              updateNotification(notification._id, {
                                isArchived: !notification.isArchived,
                              })
                            }
                            title={
                              notification.isArchived ? "Unarchive" : "Archive"
                            }
                            hasDanger={!notification.isArchived}
                          >
                            {notesFilter === "archived" ? (
                              <UnarchiveIcon />
                            ) : (
                              <TrashIcon />
                            )}
                          </TrashButton>
                        </NoteFooter> */}
                      </NoteItem>
                    );
                  })
              ) : (
                <NoNew>No {notesFilter || ""} notifications</NoNew>
              )}

              {/* <AllButton
                onClick={(e) => {
                  e.target.blur();
                }}
              >
                {true ? `See Less` : `See All`}
              </AllButton> */}
            </>
          ) : null}
        </NoteList>
      </Popout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Badge = styled.div`
  ${(props) =>
    props.isVisible
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}

  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  border-radius: 4px;
  line-height: 1;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  color: ${(props) => props.theme.colors.indigo600};
  background-color: ${(props) => props.theme.colors.indigo100};

  user-select: none;

  ${(props) =>
    props.isAbsolute
      ? props.isSidebarOpen
        ? css`
            position: absolute;
            top: 50%;
            right: 24px;
            transform: translateY(-50%);
          `
        : css`
            position: absolute;
            bottom: 0;
            right: 0;
            transform: scale(0.6) translate(50%, -50%);
          `
      : ``}
`;

const Popout = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;

  width: 285px;
  height: 600px;

  display: flex;
  flex-direction: column;

  padding-top: 14px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 4px;

  transition: opacity 0.12s, visibility 0.12s, transform 0.12s ease-out;

  transform: translate(101%, 0px); // 15px
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isVisible ? "auto" : "none")};

  /* ${Wrapper}:hover & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translate(101%, 0px);
  } */

  ${respondTo("xlarge")} {
    width: 330px;
    height: 520px;
    padding-top: 17px;
  }
`;

const PopoutHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 16px;

  > *:last-child {
    margin-left: auto;
  }
`;

const PopoutTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 20px;
  font-weight: normal;
  line-height: 32px;
  color: ${(props) => props.theme.colors.black};
  letter-spacing: 0.02em;
  margin-right: 15px;
  margin-bottom: 0;

  ${respondTo("xlarge")} {
    font-size: 20px;
  }
`;

const MarkAll = styled.button`
  ${buttonReset()}
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray500};

  &:hover{
    text-decoration: underline;
  }
`;

const NoteList = styled.div`
  flex: 1;
  overflow: auto;

  display: flex;
  flex-direction: column;
  padding-top: 16px;
`;

const NoteInner = styled.div`
  display: flex;
  align-items: flex-start;

  padding: 8px;
  border-radius: 4px;
`;

const NoteItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 500;
  text-decoration: none;

  padding-left: 20px;
  padding-right: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  opacity: ${(props) => (props.isFaded ? 0.7 : 1)};

  transition: color 0.2s, opacity 0.2s, background-color 0.2s;

  ${NoteInner} {
    background-color: ${(props) =>
      !props.read ? rgba(props.theme.colors.indigo50, 0.5) : "transparent"};
  }

  &:hover {
    ${NoteInner} {
      background-color: ${(props) =>
        !props.read
          ? rgba(props.theme.colors.indigo50, 0.5)
          : rgba(props.theme.colors.coolGray50, 0.8)};
    }
  }
`;

const NoteFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NoteIcon = styled.div`
  margin-right: 16px;
  margin-top: 4px;
`;

const ProfileWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
`;

const ProfileMain = styled(ProfileImage)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`;

const Check = styled.div``;

const ItemContainer = styled.div`
  width: 100%;
  flex: 1;
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 5px;
`;

const Author = styled.button`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;

  /* give it a clickable area that will cover the entire notification. So clicking the notification will trigger a click here */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 5px;
  padding-right: 20px;
`;

const Time = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;
`;

const NoNew = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.slate};
  margin: auto;
  opacity: 0.7;
`;

const Close = styled(CloseButton)`
  position: static;

  svg {
    width: 12px;
    height: 12px;
  }
`;

const NoteButton = styled.button`
  /* give it a higher z-index so clicking the button won't trigger a general click on the notification */
  position: relative;
  z-index: 4;

  opacity: ${(props) => (props.isShown || props.isActive ? 1 : 0)};
  visibility: ${(props) =>
    props.isShown || props.isActive ? "visible" : "hidden"};

  transition: all 200ms;

  ${NoteItem}:hover & {
    opacity: 1;
    visibility: visible;
  }

  ${NoteFooter} & {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ReadButton = styled(NoteButton)`
  width: 30px;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border-radius: 50%;
  border: 2px solid;
  border-color: ${(props) =>
    props.hasRead ? "transparent" : props.theme.colors.indigo500};
  //props.theme.colors.slate : props.theme.colors.red};

  svg {
    opacity: 0;
    transition: opacity 0.2s;
    display: inline-block;
  }

  &:hover,
  &:focus-visible {
    background-color: ${(props) => props.theme.colors.indigo500};

    svg {
      opacity: 1;
    }
  }
`;

const TrashButton = styled(NoteButton)`
  path {
  }

  &:hover {
    animation: wiggle 2s ease-in-out infinite;
  }

  @keyframes wiggle {
    0% {
      transform: rotate(6deg);
    }
    6.25% {
      transform: rotate(-6deg);
    }
    12.5% {
      transform: rotate(10deg);
    }
    18.75% {
      transform: rotate(-3deg);
    }
    25% {
      transform: rotate(0deg);
    }
  }
`;

const UnarchiveIcon = styled(InboxIcon)`
  width: 20px;
  height: auto;
  transform: scale(0.8);

  path {
    fill: ${(props) => props.theme.colors.darkSlate};
  }
`;

const StarButton = styled(NoteButton)`
  path {
    stroke: ${(props) =>
      props.isActive ? props.theme.colors.indigo400 : undefined};
    fill: ${(props) =>
      props.isActive ? props.theme.colors.indigo400 : undefined};
    transition: fill 200ms;
  }

  &:hover {
    path {
      fill: ${(props) =>
        props.isActive ? "transparent" : props.theme.colors.coolGray500};
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;

const Tab = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.indigo600
      : props.theme.colors.coolGray500};
  transition: color 0.2s;

  ${Badge} {
    margin-left: 8px;
  }

  /* underline */
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.indigo600};
    height: 2px;

    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transition: opacity 200ms;
  }

  &:hover,
  &:focus-visible {
    color: ${(props) =>
      props.isActive
        ? props.theme.colors.indigo600
        : props.theme.colors.coolGray400};
  }
`;

export default Inbox;
