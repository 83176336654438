import React from "react";
import styled from "styled-components";

const ElipsesIcon = () => {
  return (
    <Svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16536 2.00008H2.1737M7.9987 2.00008H8.00703M13.832 2.00008H13.8404M2.9987 2.00008C2.9987 2.2211 2.9109 2.43306 2.75462 2.58934C2.59834 2.74562 2.38638 2.83342 2.16536 2.83342C1.94435 2.83342 1.73239 2.74562 1.57611 2.58934C1.41983 2.43306 1.33203 2.2211 1.33203 2.00008C1.33203 1.77907 1.41983 1.56711 1.57611 1.41083C1.73239 1.25455 1.94435 1.16675 2.16536 1.16675C2.38638 1.16675 2.59834 1.25455 2.75462 1.41083C2.9109 1.56711 2.9987 1.77907 2.9987 2.00008ZM8.83203 2.00008C8.83203 2.2211 8.74423 2.43306 8.58795 2.58934C8.43167 2.74562 8.21971 2.83342 7.9987 2.83342C7.77768 2.83342 7.56572 2.74562 7.40944 2.58934C7.25316 2.43306 7.16536 2.2211 7.16536 2.00008C7.16536 1.77907 7.25316 1.56711 7.40944 1.41083C7.56572 1.25455 7.77768 1.16675 7.9987 1.16675C8.21971 1.16675 8.43167 1.25455 8.58795 1.41083C8.74423 1.56711 8.83203 1.77907 8.83203 2.00008ZM14.6654 2.00008C14.6654 2.2211 14.5776 2.43306 14.4213 2.58934C14.265 2.74562 14.053 2.83342 13.832 2.83342C13.611 2.83342 13.3991 2.74562 13.2428 2.58934C13.0865 2.43306 12.9987 2.2211 12.9987 2.00008C12.9987 1.77907 13.0865 1.56711 13.2428 1.41083C13.3991 1.25455 13.611 1.16675 13.832 1.16675C14.053 1.16675 14.265 1.25455 14.4213 1.41083C14.5776 1.56711 14.6654 1.77907 14.6654 2.00008Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  ellipse,
  path {
    transition: fill 0.2s;
  }
`;

export default ElipsesIcon;
