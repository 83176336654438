import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  getCompaniesFromApi,
  getContractsFromApi,
  getContractBudgetDetailsFromApi,
} from "../../utils/api";
import {
  createProjectOnApi,
  getMembersFromApi,
  getAllProjectsFromApi,
} from "../manage/utils/api";

import { getCurrency, capitalize } from "../../utils/helpers";

import { useNotifications } from "../../context/notificationsContext";

import Button from "../../components/buttons/Button";
import {
  Field,
  Label,
  Input,
  MoneyInput,
  FieldGroup,
} from "../../components/Form";
import Toggle, { ToggleContainer } from "../../components/Toggle";
import SelectDropdown from "../../components/SelectDropdown";
import ProjectRates from "./components/ProjectRates";

const projectTypes = [
  { label: "Retainer", value: "retainer" },
  { label: "Project", value: "project" },
  { label: "Rush", value: "rush" },
];

const ProjectRequest = () => {
  const history = useHistory();
  const { openAlertPopup } = useNotifications();
  const locationObj = useLocation();

  const [clients, setClients] = useState(null);
  const [contracts, setContracts] = useState(null);
  const [strategyDirectors, setStrategyDirectors] = useState(null);
  const [projectManagers, setProjectManagers] = useState(null);
  const [projectManager, setProjectManager] = useState(null);
  const [producer, setProducer] = useState(null);

  const [loadingContracts, setLoadingContracts] = useState(false);
  const [contractRemaining, setContractRemaining] = useState(null);

  const [client, setClient] = useState(null);
  const [contract, setContract] = useState(null);
  const [harvestId, setHarvestId] = useState("");
  const [projectName, setProjectName] = useState(null);
  const [projectCode, setProjectCode] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [projectType, setProjectType] = useState("Time & Materials"); // eslint-disable-line
  const [hourlyRates, setHourlyRates] = useState("Person Hourly Rate"); // eslint-disable-line
  const [budget, setBudget] = useState("Fees Per Task"); // eslint-disable-line
  const [lowEstimate, setLowEstimate] = useState(null);
  const [highEstimate, setHighEstimate] = useState(null);
  const [expenseBudget, setExpenseBudget] = useState(null);
  const [budgetReset, setBudgetReset] = useState(false);
  const [emailAlert, setEmailAlert] = useState(true); // eslint-disable-line
  const [projectReport, setProjectReport] = useState(true); // eslint-disable-line
  const [leadDirector, setLeadDirector] = useState(null);
  const [designation, setDesignation] = useState("project");

  const [projectCodeMessage, setProjectCodeMessage] = useState("");

  /*
  |--------------------------------------------------------------------------
  * Set up members and clients
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getMembers();
    getClients();
  }, []);

  // when clients are fetched
  useEffect(() => {
    // check if theres a pre-selected client in the URL
    if (locationObj.search) {
      const urlParams = new URLSearchParams(locationObj.search);

      const preSelectedClient = urlParams.get("client");

      if (preSelectedClient) {
        setClient(preSelectedClient);
      }
    }
  }, [clients]);

  // * when a client is selected
  useEffect(() => {
    if (client && clients) {
      const foundClient = clients.find((item) => item._id === client);

      if (foundClient) {
        setProducer(foundClient.producer?._id || null);
        setProjectManager(foundClient.producer?._id || null);

        // reset the message displayed under the project code
        setProjectCodeMessage("");

        // fill the project code field with the client's acronym
        if (foundClient.acronym) {
          setProjectCode(foundClient.acronym);

          getLatestProjectCode(foundClient);
        }

        // reset the selected contract
        setContract(null);

        // get the contracts for this client
        getContracts();
      }
    }
  }, [client, clients]);

  // * when a contract is selected
  useEffect(() => {
    if (contract) {
      // reset the contract budget and remaining values
      setContractRemaining(null);

      // fetch this contract's budget details
      getContractBudget();
    }
  }, [contract]);

  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      const strategyDirectorMembers = membersFromApi.filter(
        (member) =>
          member.role.tier === "director" || member.role.tier === "partner",
      );
      const managerMembers = membersFromApi.filter(
        (member) =>
          member.role.role === "Production Manager" ||
          member.role.role === "Sr Partnership Manager",
      );

      setStrategyDirectors(strategyDirectorMembers);
      setProjectManagers(managerMembers);
    } catch (error) {
      console.error("error getting members", error);
      throw error;
    }
  };

  const getClients = async () => {
    try {
      // get the companies that have a status of "client"
      const companiesFromApi = await getCompaniesFromApi({ client: true });

      setClients(companiesFromApi);
    } catch (error) {
      console.error("error getting clients", error);
      throw error;
    }
  };

  // get a client's contracts
  const getContracts = async () => {
    try {
      setLoadingContracts(true);

      // get this client's contracts
      const contractsFromApi = await getContractsFromApi({
        company: client,
      });

      // filter out the closed contracts
      const activeContracts = contractsFromApi.filter(
        (thisContract) =>
          thisContract.status.toLowerCase().indexOf("closed") === -1,
      );

      // sort contracts by alpha
      activeContracts.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      setContracts(activeContracts);
    } catch (error) {
      console.error("error getting client contracts", error);
    }

    setLoadingContracts(false);
  };

  const getContractBudget = async () => {
    try {
      const contractBudgetDetails = await getContractBudgetDetailsFromApi(
        contract,
      );

      // remaining = total budget minus the greater of Budgeted or Billed amount

      const { total, budgeted, billed } = contractBudgetDetails;

      const greaterValue = budgeted > billed ? budgeted : billed;

      setContractRemaining(total - greaterValue);
    } catch (error) {
      console.error("error getting contract budget details", error);
    }
  };

  const getLatestProjectCode = async (selectedClient) => {
    try {
      const latestProjects = await getAllProjectsFromApi({
        clientId: client,
        archived: "any",
        latest: 1,
      });

      const acronym = selectedClient.acronym || "";

      // if this client has a previous project
      if (latestProjects?.length) {
        // get its project code
        const latestCode = latestProjects[0].code || "";

        // get just the number at the end
        // ? regex removes all non-digit leading characters
        const codeNumber = latestCode.replace(/^\D+/g, "");

        // increment it
        const nextNumber = Number(codeNumber) + 1;

        // if its not a 3-digit number, make sure it has leading zeros
        const nextNumberString = `${nextNumber}`.padStart(3, "0");

        // combine it with the client's acronym (and convert it back to a string)
        setProjectCode(`${acronym}${nextNumberString}`);

        setProjectCodeMessage(
          `The last project code used for ${
            selectedClient.name
          } was ${latestCode || "(blank)"}`,
        );
      } else {
        // this is the first project, so start its code at "001"
        setProjectCode(`${acronym}001`);

        setProjectCodeMessage(
          `This is the first project for ${selectedClient.name}`,
        );
      }
    } catch (error) {
      console.error("error getting most recent project", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  * Get all values in state and send to the API to save
  |--------------------------------------------------------------------------
  */
  const addProject = async () => {
    try {
      const data = {
        clientId: client,
        name: projectName,
        harvestId,
        producer,
        contract,
        leadDirector,
        projectManager,
        startDate,
        endDate,
        lowEstimate,
        highEstimate,
        designation,
        expenseBudget,
        harvestData: {
          projectCode,
          projectType,
          hourlyRates,
          startDate,
          endDate,
          budget,
          budgetReset,
          emailAlert,
          projectReport,
        },
      };

      console.error("Adding project", data);

      const newProject = await createProjectOnApi(data);

      history.push(`/projects/${newProject._id}`);
    } catch (err) {
      console.error("project creation error", err);

      const errMessage = typeof err === "string" ? err : "";

      openAlertPopup("Failure", `Project submission failed! ${errMessage}`);
    }
  };

  const selectedContract = contracts
    ? contracts.find((thisContract) => thisContract._id === contract)
    : null;

  return (
    <Container>
      <Heading>Project Details</Heading>

      <FlexContainer>
        <div>
          <Field>
            <Label>Client</Label>
            <StyledSelectDropdown
              id="clients"
              onChange={(e) => setClient(e.value)}
              placeholder="Select Client"
              required
              value={
                client &&
                clients &&
                clients
                  .filter((item) => item._id === client)
                  .map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))
              }
              options={
                clients &&
                clients.map((client) => ({
                  label: client.name,
                  value: client._id,
                }))
              }
            />
          </Field>

          <Field>
            <Label>Contract</Label>
            <StyledSelectDropdown
              id="contract"
              onChange={(e) => setContract(e.value)}
              placeholder={
                !client ? "(Select a client first)" : "Select Contract"
              }
              isDisabled={!client}
              isLoading={loadingContracts}
              required
              defaultValue={
                selectedContract
                  ? {
                      label: `${selectedContract.name} — ${capitalize(
                        selectedContract.status,
                      )}`,
                      value: selectedContract._id,
                    }
                  : null
              }
              options={
                contracts
                  ? contracts.map((thisContract) => ({
                      label: `${thisContract.name} — ${capitalize(
                        thisContract.status,
                      )}`,
                      value: thisContract._id,
                    }))
                  : null
              }
            />
          </Field>

          <Field>
            {selectedContract ? (
              <>
                <Label>Contract Budget</Label>

                <div>Total: {getCurrency(selectedContract.budget)}</div>

                <PotentiallyDangerous
                  hasDanger={
                    contractRemaining !== null && contractRemaining <= 0
                  }
                >
                  Remaining:{" "}
                  {contractRemaining !== null
                    ? getCurrency(contractRemaining)
                    : "..."}
                </PotentiallyDangerous>
              </>
            ) : null}
          </Field>

          <Field>
            {selectedContract ? (
              <>
                <Label>Project Type</Label>
                <StyledSelectDropdown
                  onChange={(e) => setDesignation(e.value)}
                  placeholder="Select Project Type"
                  value={
                    designation &&
                    projectTypes.filter((type) => {
                      return type.value === designation;
                    })
                  }
                  options={projectTypes}
                />
              </>
            ) : null}
          </Field>

          <Field>
            <Label>Harvest Id</Label>
            <Input
              name="harvest"
              type="text"
              placeholder="(optional)"
              value={harvestId}
              onChange={(e) => setHarvestId(e.target.value)}
            />
          </Field>
          <Field>
            <Label>Project Name</Label>
            <Input
              name="projectName"
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </Field>
          <Field>
            <Label>Project Code </Label>
            <Input
              name="projectCode"
              type="text"
              value={projectCode}
              onChange={(e) => setProjectCode(e.target.value)}
              required
            />
            {projectCodeMessage ? (
              <FieldMessage>{projectCodeMessage}</FieldMessage>
            ) : null}
          </Field>
          <Field>
            <Label>Start Date</Label>
            <Input
              name="startDate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </Field>
          <Field>
            <Label>End Date</Label>
            <Input
              name="endDate"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </Field>
        </div>
        <div>
          <Field>
            <Label>Project Type</Label>
            <Input
              name="projectType"
              type="text"
              value={projectType}
              disabled
            />
          </Field>
          <Field>
            <Label>Hourly Rates</Label>
            <Input
              name="hourlyRates"
              type="text"
              value={hourlyRates}
              disabled
            />
          </Field>

          <FieldGroup>
            <Field>
              <Label>Low Estimate</Label>
              <MoneyInput
                value={lowEstimate}
                onChange={(e) => {
                  setLowEstimate(e.target.rawValue.replace("$", ""));
                }}
              />
            </Field>

            <Field>
              <Label>High Estimate</Label>
              <MoneyInput
                value={highEstimate}
                onChange={(e) => {
                  setHighEstimate(e.target.rawValue.replace("$", ""));
                }}
              />
            </Field>
          </FieldGroup>

          <Field>
            <Label>Expense Budget</Label>
            <MoneyInput
              value={expenseBudget}
              onChange={(e) => {
                setExpenseBudget(e.target.rawValue.replace("$", ""));
              }}
            />
          </Field>

          <Field>
            <Label>Budget</Label>
            <Input name="budget" type="text" value={budget} disabled />
          </Field>
          <Field>
            <Label>Monthly Budget Reset</Label>

            <ToggleContainer width="400px">
              <Toggle
                label="Budget resets every month?"
                name="budgetReset"
                type="checkbox"
                onChange={(e) => setBudgetReset(e.target.checked)}
                checked={budgetReset}
              />
            </ToggleContainer>
          </Field>
          <Field>
            <Label>Send email alert if project budget exceeds</Label>

            <ToggleContainer width="400px" disabled>
              <Toggle
                label="100%"
                name="emailAlert"
                type="checkbox"
                checked={emailAlert}
                disabled
              />
            </ToggleContainer>
          </Field>
          <Field>
            <Label>Show project report to everyone</Label>

            <ToggleContainer width="400px" disabled>
              <Toggle
                label="Show project report to everyone"
                name="projectReport"
                type="checkbox"
                checked={projectReport}
                disabled
              />
            </ToggleContainer>
          </Field>

          <Field>
            <Label>Strategy Director</Label>
            <StyledSelectDropdown
              id="directors"
              onChange={(e) => setLeadDirector(e.value)}
              placeholder="Select Director"
              defaultValue={
                leadDirector &&
                strategyDirectors
                  ?.filter((item) => item._id === leadDirector)
                  ?.map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))
              }
              options={strategyDirectors?.map((director) => ({
                label: director.name,
                value: director._id,
              }))}
            />
          </Field>

          <Field>
            <Label>Production Manager</Label>
            <StyledSelectDropdown
              id="projectManagers"
              onChange={(e) => setProjectManager(e.value)}
              placeholder="Select Production Manager"
              value={
                projectManager &&
                projectManagers &&
                projectManagers
                  .filter((item) => item._id === projectManager)
                  .map((item) => ({
                    label: item.name,
                    value: item._id,
                  }))
              }
              options={
                projectManagers &&
                projectManagers.map((manager) => ({
                  label: manager.name,
                  value: manager._id,
                }))
              }
            ></StyledSelectDropdown>
          </Field>
        </div>
      </FlexContainer>

      <Actions>
        <Button
          onClick={() => addProject()}
          disabled={
            !(
              client &&
              contract &&
              projectName &&
              projectCode &&
              startDate &&
              endDate &&
              projectType &&
              hourlyRates &&
              budget &&
              emailAlert &&
              projectReport &&
              leadDirector &&
              projectManager
            )
          }
        >
          Save
        </Button>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;
  max-width: 1000px;
  width: 100%;

  input,
  textarea {
    width: 400px;
  }
`;

const Heading = styled.h1`
  font-size: 28px;
  color: #373040;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 50px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 400px;
`;

const PotentiallyDangerous = styled.span`
  font-weight: ${(props) => (props.hasDanger ? 700 : undefined)};
  color: ${(props) => (props.hasDanger ? props.theme.colors.red : undefined)};
`;

const FieldMessage = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.2;
`;

export default ProjectRequest;
