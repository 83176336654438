import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";

import {
  getChunkletFromApi,
  updateChunkletOnApi,
  deleteChunkletOnApi,
  createChunkletOnApi,
  getSettings,
} from "../../utils/api";

import { TableRow, TableCell } from "../../components/Table";
import { Label, Field, Input, Textarea } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";
import CreateLink from "../../components/links/CreateLink";
import Button from "../../components/buttons/Button";

import { roles } from "../../utils/constants";

// * Create / Update chunklet

const Chunklet = () => {
  const history = useHistory();
  const { id } = useParams();
  const { openAlertPopup } = useNotifications();

  // array of filters
  const [chunkletFilters, setChunkletFilters] = useState([]);
  // object of selected filters
  // - key = name of filter
  // - value = selected options for the filter
  const [selectedFilters, setSelectedFilters] = useState({});

  const [title, setTitle] = useState("");
  const [tasks, setTasks] = useState([]);
  const [deliverables, setDeliverables] = useState([]);

  const [isExisting, setIsExisting] = useState(false);

  // Can be:
  // brief, tasks, deliverables, internal-approval, client-approval, or close-out
  const [currentTab, setCurrentTab] = useState("tasks");

  useEffect(() => {
    // get the filter options
    getSettingsData();
  }, []);

  useEffect(() => {
    // if user is creating a new chunklet, the :id in the url will be "new", or something along those lines
    // if they're editing an existing one, the :id will be a long string (the object ID of the chunklet)
    const alreadyExists = id.length > 7;

    setIsExisting(alreadyExists);

    // if it exists, fetch the chunklet's data
    if (alreadyExists) {
      getChunklet();
    }
  }, [id]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get chunklet
  |--------------------------------------------------------------------------
  */
  const getChunklet = async () => {
    try {
      const chunkletData = await getChunkletFromApi(id);

      setTitle(chunkletData.title || "");
      setTasks(chunkletData.tasks || []);
      setDeliverables(chunkletData.deliverables || []);

      // if it has filters
      if (chunkletData?.filters?.length) {
        // convert the filters array into an object
        const filters = {};
        chunkletData.filters.forEach((filter) => {
          filters[filter.name] = filter.options;
        });

        // set the selected filters
        setSelectedFilters(filters);
      }
    } catch (error) {
      console.error("getChunklet failed");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update/Create chunklet on api
  |--------------------------------------------------------------------------
  */
  const handleSave = async () => {
    // Format the selected filters object back into an array
    const filters = Object.entries(selectedFilters).map(([name, options]) => {
      return {
        name,
        options,
      };
    });

    const data = {
      title,
      tasks,
      deliverables,
      filters,
    };

    try {
      // if it exists, update it
      // otherwise, create it
      if (isExisting) {
        await updateChunkletOnApi(id, data);
      } else {
        await createChunkletOnApi(data);
      }

      openAlertPopup(
        "Success",
        `Chunklet ${isExisting ? "updated" : "created"} successfully!`,
        true,
      );

      // if a new one was just created, go back to the chunklets list view
      if (!isExisting) {
        history.push(`/projects/chunklets`);
      }
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Chunklet ${isExisting ? "update" : "create"} failed!`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete chunklet on api
  |--------------------------------------------------------------------------
  */
  const deleteChunklet = async () => {
    try {
      await deleteChunkletOnApi(id);
      openAlertPopup("Success", "Chunklet deleted successfully!", true);
      history.push(`/projects/chunklets`);
    } catch (error) {
      openAlertPopup("Failure", "Chunklet delete failed!");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get saved settings data to populate the filter dropdowns
  |--------------------------------------------------------------------------
  */
  const getSettingsData = async () => {
    try {
      const response = await getSettings();

      if (response?.chunkletFilters) {
        setChunkletFilters(response.chunkletFilters);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Header>
        <h1>{isExisting ? "Edit" : "New"} Chunklet</h1>

        <SimpleLink as={Link} to="/projects/chunklets">
          View all chunklets
        </SimpleLink>
      </Header>

      <div>
        <Field>
          <Label>Name</Label>
          <Input
            type="text"
            value={title}
            placeholder="Chunklet name"
            onChange={(e) => setTitle(e.target.value)}
          />
        </Field>

        <Filters>
          {chunkletFilters.map((filter, index) =>
            filter.options ? (
              <Field key={index}>
                <Label>{filter.name}</Label>
                <Filter
                  isMulti
                  value={
                    Object.prototype.hasOwnProperty.call(
                      selectedFilters,
                      filter.name,
                    )
                      ? selectedFilters[filter.name].map((option) => {
                          return {
                            label: option,
                            value: option,
                          };
                        })
                      : null
                  }
                  onChange={(e) => {
                    const newFilters = { ...selectedFilters };
                    newFilters[filter.name] = e
                      ? e.map((option) => option.value)
                      : [];
                    setSelectedFilters(newFilters);
                  }}
                  placeholder={`Select ${filter.name} Tags`}
                  options={filter.options.map((option) => {
                    return {
                      label: option,
                      value: option,
                    };
                  })}
                />
              </Field>
            ) : null,
          )}
        </Filters>

        <Tabs>
          <Tab
            onClick={() => {
              setCurrentTab("tasks");
            }}
            isActive={currentTab === "tasks"}
          >
            Tasks
          </Tab>

          <Tab
            onClick={() => {
              setCurrentTab("deliverables");
            }}
            isActive={currentTab === "deliverables"}
          >
            Deliverables
          </Tab>
        </Tabs>

        {currentTab === "tasks" ? (
          <>
            {tasks.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Hours</th>
                    <th>Role</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task, index) => {
                    return (
                      <TableRow key={index}>
                        <Cell align="left">
                          <Input
                            type="text"
                            value={task.title}
                            placeholder="Task title"
                            onChange={(e) => {
                              const tempTasks = [...tasks];
                              tempTasks[index].title = e.target.value;
                              setTasks(tempTasks);
                            }}
                          />
                        </Cell>

                        <Cell align="left" small>
                          <Input
                            type="number"
                            value={task.hoursToComplete}
                            placeholder="Hours"
                            onChange={(e) => {
                              const tempTasks = [...tasks];
                              tempTasks[index].hoursToComplete = parseInt(
                                e.target.value,
                              );
                              setTasks(tempTasks);
                            }}
                          />
                        </Cell>

                        <Cell align="left" small>
                          <Role
                            value={
                              task.role
                                ? { label: task.role, value: task.role }
                                : null
                            }
                            onChange={(e) => {
                              const tempTasks = [...tasks];
                              tempTasks[index].role = e.value;
                              setTasks(tempTasks);
                            }}
                            placeholder={`Select Role`}
                            options={roles.map((option) => {
                              return {
                                label: option,
                                value: option,
                              };
                            })}
                          />
                        </Cell>

                        <Cell align="left">
                          <Textarea
                            value={task.description}
                            placeholder="Description"
                            onChange={(e) => {
                              const tempTasks = [...tasks];
                              tempTasks[index].description = e.target.value;
                              setTasks(tempTasks);
                            }}
                          />
                        </Cell>

                        <Cell>
                          <SimpleLink
                            onClick={() => {
                              const tempTasks = [...tasks];
                              tempTasks.splice(index, 1);
                              setTasks(tempTasks);
                            }}
                          >
                            Delete
                          </SimpleLink>
                        </Cell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p>(this chunklet has no tasks)</p>
            )}

            <CreateLink
              onClick={() => {
                const tempTasks = [...tasks];
                tempTasks.push({
                  title: "",
                  hoursToComplete: "",
                  description: "",
                });
                setTasks(tempTasks);
              }}
            >
              Add Task
            </CreateLink>
          </>
        ) : currentTab === "deliverables" ? (
          <>
            {deliverables.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {deliverables.map((deliverable, index) => {
                    return (
                      <TableRow key={index}>
                        <Cell align="left">
                          <Input
                            type="text"
                            value={deliverable.name}
                            placeholder="Deliverable name"
                            onChange={(e) => {
                              const tempDeliverables = [...deliverables];
                              tempDeliverables[index].name = e.target.value;
                              setDeliverables(tempDeliverables);
                            }}
                          />
                        </Cell>

                        <Cell>
                          <SimpleLink
                            onClick={() => {
                              const tempDeliverables = [...deliverables];
                              tempDeliverables.splice(index, 1);
                              setDeliverables(tempDeliverables);
                            }}
                          >
                            Delete
                          </SimpleLink>
                        </Cell>
                      </TableRow>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p>(this chunklet has no deliverables)</p>
            )}

            <CreateLink
              onClick={() => {
                const tempDeliverables = [...deliverables];
                tempDeliverables.push({
                  name: "",
                });
                setDeliverables(tempDeliverables);
              }}
            >
              Add Deliverable
            </CreateLink>
          </>
        ) : null}

        <Actions>
          <Button disabled={!title} onClick={handleSave}>
            Save
          </Button>

          {isExisting ? (
            <SimpleLink danger onClick={deleteChunklet}>
              Delete Chunklet
            </SimpleLink>
          ) : null}
        </Actions>
      </div>
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.oldBlack};
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 660px;
  margin-bottom: 30px;
`;

const Filter = styled(SelectDropdown)`
  width: 300px;
  font-size: 14px;
`;

const Role = styled(SelectDropdown)`
  width: 300px;
  font-size: 14px;
`;

const Table = styled.table`
  margin-bottom: 20px;
  width: 100%;

  th {
    font-size: ${(props) => props.theme.fontSize_xxxs};
    text-align: left;
    padding: 0 25px;

    &:first-child {
      padding-left: 0;
    }
  }
`;

const Cell = styled(TableCell)`
  padding: 25px 10px;
  width: ${(props) => (props.small ? "150px" : "auto")};

  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-right: 0;
  }

  input,
  textarea {
    width: 100%;
  }
`;

const Actions = styled.div`
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Tab = styled.button`
  padding: 0px 10px 5px;
  border-bottom: 6px solid;
  border-color: ${(props) =>
    props.isActive ? props.theme.colors.blue : "transparent"};
`;

export default Chunklet;
