import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import {
  updateClientOnApi,
  getClientFromApi,
  getIndustriesFromApi,
} from "../../utils/api";

import { respondTo } from "../../styles/styleHelpers";
import { timezones, companyStages, clientStages } from "../../utils/constants";

import { useNotifications } from "../../context/notificationsContext";
import { useAuth } from "../../context/authContext";

import Button from "../../components/newButtons/Button";

import DealHeader from "./components/singleDealHeader";
import CompanyDetails from "./components/singleCompanyDetails";
import CompanyNotes from "./components/singleCompanyNotes";

const SingleCompany = () => {
  const { companyId } = useParams();

  const { setLoading, openAlertPopup } = useNotifications();
  const { isImportant } = useAuth();

  const [company, setCompany] = useState(null);
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    if (companyId) {
      getCompany();
    }
  }, [companyId]);

  const getCompany = async () => {
    setLoading(true);

    try {
      const companyFromApi = await getClientFromApi(companyId);
      setCompany(companyFromApi);
    } catch (error) {
      console.error("error getting company", error);
    }

    setLoading(false);
  };

  const getIndustries = async () => {
    try {
      const industriesFromApi = await getIndustriesFromApi();
      setIndustries(industriesFromApi || []);
    } catch (error) {
      console.error("error getting industries", error);
    }
  };

  const createIndustry = (newIndustry) => {
    setIndustries([...new Set([...industries, newIndustry])]);
  };

  const handleDetailsEdit = async (
    updatedProps,
    context = "company",
    secondaryContext = null,
  ) => {
    if (!company) return;
    try {
      const tempCompany = { ...company };

      // get the keys for the new props
      let keys = Object.keys(updatedProps);
      let formattedProps = { ...updatedProps };

      switch (context) {
        case "company":
          // Update the company

          // If these are supposed to be in info, bring in the original info obj
          // ? If you don't do this, it will wipe all the info fields
          if (secondaryContext === "info") {
            formattedProps = {
              info: { ...tempCompany.info, ...updatedProps },
            };
            keys = ["info"];
          }

          // Has to add database parent obj to prop because that's what server is expecting
          await updateClientOnApi(company._id, {
            database: formattedProps,
          });

          // Attaches any props to the deal's company and updates
          if (keys) {
            keys.forEach((key) => {
              tempCompany[key] = formattedProps[key];
            });
          }

          break;
      }

      setCompany(tempCompany);
    } catch (err) {
      console.error("Something went wrong with editing company", err);

      const errorMessage = typeof err === "string" ? err : "";

      openAlertPopup(
        "Update company failed",
        `Something went wrong while editing the company. ${errorMessage}`,
      );
    }
  };

  // const handleDelete = async () => {
  //   openPromptPopup({
  //     header: "Delete deal",
  //     body: "Are you sure you want to delete the selected deals?",
  //     confirmCallback: async () => {
  //       try {
  //         await deleteCompaniesOnApi({ ids: [dealId] });
  //         openAlertPopup("Deals deleted successfully!", null, true);
  //         closePromptPopup();
  //         history.push("/sales/deals");
  //       } catch (err) {
  //         openAlertPopup("Delete deal failed", "Please try again");
  //       }
  //     },
  //     cancelText: "Cancel",
  //     confirmText: "Delete",
  //   });
  // };

  const menuItems = [
    {
      //handleClick: () => handleDelete(),
      text: "Delete",
      danger: true,
    },
  ];

  return (
    <Container>
      <DealHeader
        title={company ? company.name : ""}
        breadcrumbs={[
          { name: "Sales", link: "/sales" },
          { link: "/sales/companies", name: "Companies" },
          {
            name: company ? company.name : "",
          },
        ]}
        statusText={company?.archived ? "Archived" : company?.status}
        created={company ? company.createdAt : ""}
        updated={company ? company.updatedAt : ""}
        menuItems={menuItems}
        statuses={companyStages}
        handleTitleEdit={
          isImportant
            ? (value) => {
                handleDetailsEdit({ name: value });
              }
            : undefined
        }
        handleStatusEdit={
          isImportant && !company?.archived
            ? (value) => {
                handleDetailsEdit({ status: value });
              }
            : undefined
        }
      />
      <Main>
        <LeftCol>
          {/* link to the client view if this company is a client */}
          {clientStages.includes(company?.status) ? (
            <Cta>
              <Button as={Link} to={`/manage/clients/${companyId}`} small>
                Go To Client
              </Button>
            </Cta>
          ) : null}

          <CompanyDetails
            id={companyId}
            handleEdit={handleDetailsEdit}
            name={company ? company.name : ""}
            code={company ? company.acronym : ""}
            tier={company ? company.tier : ""}
            t1type={company ? company.t1type : ""}
            industry={company?.industry ? company.industry : ""}
            industries={industries}
            createIndustry={createIndustry}
            products={company?.info?.products ? company.info.products : null}
            social={company?.info?.social ? company.info.social : null}
            // contactName={
            //   deal && deal.contacts?.length > 0
            //     ? `${deal.contacts[0].firstName} ${deal.contacts[0].lastName}`
            //     : ""
            // }
            // officeNumber={
            //   deal?.company?.info?.phone ? deal.company.info.phone : "N/A"
            // }
            // team={deal ? deal.team : []}
            // directNumber={
            //   deal && deal.contacts?.length > 0
            //     ? deal.contacts[0].contactInfo.phone
            //     : ""
            // }
            // email={
            //   deal && deal.contacts?.length > 0
            //     ? deal.contacts[0].contactInfo.email
            //     : null
            // }
            // amount={deal ? deal.amount : ""}
            // amountWeighted={deal ? deal.amountWeighted : ""}
            // chanceClosing={deal ? deal.chanceClosing : null}
            website={company?.info?.website ? company.info.website : null}
            address={
              company?.info?.address1 ? (
                <>
                  {company.info.address1}
                  <br />
                  {company.info.address2 ? (
                    <>
                      {company.info.address2}
                      <br />
                    </>
                  ) : null}
                  {company.info.city}, {company.info.state}{" "}
                  {company.info.zipcode}
                </>
              ) : (
                "N/A"
              )
            }
            parentCompany={
              company?.info?.parentCompany ? company.info.parentCompany : "N/A"
            }
            stockTicker={
              company?.info?.stockTicker ? company.info.stockTicker : "N/A"
            }
            marketCap={
              company?.info?.marketCap ? company.info.marketCap : "N/A"
            }
            annualRevenue={
              company?.info?.annualRevenue ? company.info.annualRevenue : "N/A"
            }
            description={
              company?.info?.description ? company.info.description : "N/A"
            }
            timezone={company?.info?.timezone ? company.info.timezone : "N/A"}
            availTimezones={timezones}
          />
        </LeftCol>
        <RightCol>
          <CompanyNotes
            companyId={company ? company._id : ""}
            initialNotes={company ? company.notes : ""}
            blockedBy={company?.notesEditing ? company.notesEditing : null}
          />
        </RightCol>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  padding: 27px 40px 75px;
  gap: 20px;
`;

const Col = styled.div`
  position: relative;
  height: 100%;
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const LeftCol = styled(Col)`
  flex: 0 1 40%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
  }
`;

const RightCol = styled(Col)`
  flex: 0 1 60%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
  }
`;

const Cta = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  transform: translateY(-100%);
`;

export default SingleCompany;
