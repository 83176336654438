import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import { Field, Label, Input, MoneyInput } from "../../components/Form";
import Button from "../../components/buttons/Button";
import CreateLink from "../../components/links/CreateLink";
import SimpleLink from "../../components/links/SimpleLink";
import ProfileImage from "../../components/ProfileImage";
import { createTeamOnApi } from "../../utils/api";

const TeamCreate = () => {
  const history = useHistory();
  const { openAlertPopup } = useNotifications();

  const [name, setName] = useState("New Team");
  const [bookingGoals, setBookingGoals] = useState([]);

  const generateBookingObj = () => {
    let newYear = new Date().getUTCFullYear();

    if (bookingGoals.length > 0) {
      const oldYear = bookingGoals[bookingGoals.length - 1].year;
      newYear = parseInt(oldYear) + 1;
    }

    return {
      year: newYear,
      q1: {
        minimumTarget: "",
        goalTarget: "",
      },
      q2: {
        minimumTarget: "",
        goalTarget: "",
      },
      q3: {
        minimumTarget: "",
        goalTarget: "",
      },
      q4: {
        minimumTarget: "",
        goalTarget: "",
      },
    };
  };

  const addYear = () => {
    const newBooking = [...bookingGoals, generateBookingObj()];
    setBookingGoals(newBooking);
  };

  const removeYear = () => {
    const newBooking = [...bookingGoals];
    newBooking.pop();
    setBookingGoals(newBooking);
  };

  const updateBooking = (i, quarter, type, value) => {
    const newBooking = [...bookingGoals];

    newBooking[i][quarter][type] = value;
    setBookingGoals(newBooking);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await createTeamOnApi({ name, bookingGoals });

      openAlertPopup("Success", `${name} has been created.`, true);

      // go to teams list view
      history.push(`/manage/teams`);
    } catch (error) {
      console.error("error", error);

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't create the team.`,
      );
    }
  };

  return (
    <Container>
      <Heading>Create Team</Heading>
      <DisplaySection>
        <ProfileImage name={name} handle={name} showStatus />
        <DisplayText>{name}</DisplayText>
      </DisplaySection>
      <EditSection onSubmit={handleSubmit}>
        <Wrapper>
          <LeftSection>
            <Field>
              <Label>Team Name</Label>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </Field>
          </LeftSection>
          <RightSection>
            {bookingGoals && (
              <BookingTable>
                <thead>
                  <tr>
                    <BookingHeader>Team Booking Goals</BookingHeader>
                    <BookingHeader>Minimum Target</BookingHeader>
                    <BookingHeader>Goal Target</BookingHeader>
                  </tr>
                </thead>
                <tbody>
                  {bookingGoals.map((bookingItem, index) => (
                    <React.Fragment key={index}>
                      <BookingRow>
                        <BookingCell>
                          <BookingQuarter>{bookingItem.year} Q1</BookingQuarter>
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Min Target"
                            value={bookingItem.q1.minimumTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q1",
                                "minimumTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Goal Target"
                            value={bookingItem.q1.goalTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q1",
                                "goalTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                      </BookingRow>
                      <BookingRow>
                        <BookingCell>
                          <BookingQuarter>{bookingItem.year} Q2</BookingQuarter>
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Min Target"
                            value={bookingItem.q2.minimumTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q2",
                                "minimumTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Goal Target"
                            value={bookingItem.q2.goalTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q2",
                                "goalTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                      </BookingRow>
                      <BookingRow>
                        <BookingCell>
                          <BookingQuarter>{bookingItem.year} Q3</BookingQuarter>
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Min Target"
                            value={bookingItem.q3.minimumTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q3",
                                "minimumTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Goal Target"
                            value={bookingItem.q3.goalTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q3",
                                "goalTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                      </BookingRow>
                      <BookingRow>
                        <BookingCell>
                          <BookingQuarter>{bookingItem.year} Q4</BookingQuarter>
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Min Target"
                            value={bookingItem.q4.minimumTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q4",
                                "minimumTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                        <BookingCell>
                          <MoneyInput
                            placeholder="Goal Target"
                            value={bookingItem.q4.goalTarget}
                            onChange={(e) =>
                              updateBooking(
                                index,
                                "q4",
                                "goalTarget",
                                e.target.value,
                              )
                            }
                          />
                        </BookingCell>
                      </BookingRow>
                    </React.Fragment>
                  ))}
                </tbody>
              </BookingTable>
            )}
            <ButtonRow>
              <CreateLink type="button" onClick={() => addYear()}>
                Add Year
              </CreateLink>
              {bookingGoals.length > 0 ? (
                <DeleteButton type="button" onClick={() => removeYear()} danger>
                  Remove Year
                </DeleteButton>
              ) : null}
            </ButtonRow>
          </RightSection>
        </Wrapper>
        <Button type="submit">Save</Button>
      </EditSection>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px;

  ${Input} {
    width: 100%;
  }
`;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-bottom: 40px;
`;

const DisplaySection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const DisplayText = styled.div`
  margin-left: 30px;
  color: ${(props) => props.theme.colors.oldBlack2};
  font-weight: 600;
`;

const EditSection = styled.form`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const LeftSection = styled.div`
  flex: 1;
  max-width: 500px;
  padding-right: 100px;
`;

const RightSection = styled.div`
  flex: 1;
`;

const BookingTable = styled.table`
  ${Input} {
    width: 100%;
  }
`;

const BookingHeader = styled.th`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-weight: 400;
  text-align: left;
  color: #545d7a;
  padding-bottom: 15px;

  &:not(:last-child) {
    padding-right: 30px;
  }
`;

const BookingRow = styled.tr``;

const BookingCell = styled.td`
  padding-bottom: 15px;

  &:not(:last-child) {
    padding-right: 30px;
  }
`;

const BookingQuarter = styled.p`
  font-weight: 600;
  color: ${(props) => props.theme.colors.oldBlack2};
  margin-bottom: 0;
`;

const DeleteButton = styled(SimpleLink)`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  margin-left: 400px;
  padding-bottom: 2px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

export default TeamCreate;
