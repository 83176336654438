import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format, differenceInDays } from "date-fns";

import { getStandardizedDate } from "../../../utils/helpers";

import ScheduleIcon from "../../../components/icons/ScheduleIconSmall";
import Breadcrumbs from "../../../components/breadcrumbs";
import ValueChip from "../../../components/ValueChip";
import Editable from "../../../components/Editable";

import ProgressBar from "./progressBarSmall";
import MenuButton from "../../../components/newButtons/MenuButton";

const DealHeader = ({
  title,
  statusText,
  breadcrumbs,
  created,
  updated,
  stages, // array of stages
  numStages, // in case the amount of stages to display differs from the length of the stages array
  currentStage,
  stage,
  statuses,
  handleTitleEdit,
  handleProgressEdit,
  handleStatusEdit,
  menuItems,
  statusHistory,
}) => {
  const today = getStandardizedDate(new Date());
  const [latestStatus, setLatestStatus] = useState(null);
  const [daysSince, setDaysSince] = useState(0);

  // Get the latest status in the status history array if it exists
  useEffect(() => {
    const tempLatestStatus = statusHistory?.length
      ? statusHistory[statusHistory.length - 1]
      : null;

    setLatestStatus(tempLatestStatus);

    if (tempLatestStatus) {
      setDaysSince(
        differenceInDays(today, getStandardizedDate(tempLatestStatus.date)),
      );
    }
  }, [statusHistory]);

  return (
    <Header>
      <CrumbContainer>
        <Breadcrumbs crumbs={breadcrumbs} />
      </CrumbContainer>

      <HeaderRow>
        <div>
          <TitleContainer>
            <Editable id="deal-header-title" onSave={handleTitleEdit}>
              <Title>{title}</Title>
            </Editable>{" "}
            {statusText ? (
              <Editable
                id="deal-header-status"
                onSave={handleStatusEdit}
                options={
                  statuses
                    ? statuses.map((status) => ({
                        label: status,
                        value: status,
                      }))
                    : undefined
                }
              >
                <ValueChip color="indigo">{statusText}</ValueChip>
              </Editable>
            ) : null}
          </TitleContainer>

          <Created>
            <ScheduleIcon />
            {created
              ? `Created: ${format(new Date(created), "MMM dd, yyyy")}`
              : "Created: N/A"}
          </Created>

          <ProgressContainer>
            <ProgressBar
              numStages={numStages || stages?.length || 0}
              current={currentStage}
              currentText={stage}
              editOptions={
                stages
                  ? stages.map((stage) => ({
                      label: stage,
                      value: stage,
                    }))
                  : undefined
              }
              handleEdit={handleProgressEdit}
            />

            {latestStatus && stages.includes(latestStatus.status) ? (
              <Days>
                {daysSince} day{daysSince > 0 || daysSince === 0 ? "s" : ""} ago
              </Days>
            ) : null}
          </ProgressContainer>
        </div>

        <div>
          <BtnGroup>
            <StyledMenuButton items={menuItems} />

            {/* {handleEdit ? (
              <Button onClick={handleEdit}>{editText || "Edit"}</Button>
            ) : null} */}
          </BtnGroup>

          <Updated>
            {updated
              ? `Last updated: ${format(
                  new Date(updated),
                  "MMMM dd, yyyy 'at' h:mma",
                )}`
              : "Last updated: N/A"}
          </Updated>
        </div>
      </HeaderRow>
    </Header>
  );
};

const Header = styled.div`
  width: 100%;
  padding: 24px 40px 16px;
`;

const CrumbContainer = styled.div`
  margin-bottom: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 6px;
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 24px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0;
`;

const Created = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};

  display: flex;
  align-items: center;

  margin-bottom: 9px;

  svg {
    margin-right: 4px;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 9px;
`;

const StyledMenuButton = styled(MenuButton)`
  display: inline;
  margin-right: 16px;
`;

// const MenuButton = styled.button`
//   ${buttonReset()}
//   height: 36px;
//   width: 36px;
//   background: ${(props) => props.theme.colors.pureWhite};
//   border: 1px solid ${(props) => props.theme.colors.coolGray200};
//   box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
//   border-radius: 4px;
//   margin-right: 16px;
// `;

const Updated = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray400};

  display: flex;
  align-items: center;

  margin-bottom: 0;
`;

const Days = styled.span`
  font-size: 12px;
  margin-left: 15px;
`;

const ProgressContainer = styled.div`
  display: flex;
`;

export default DealHeader;
