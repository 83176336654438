import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";

import useClickOutside from "../../hooks/useClickOutside";
import { buttonReset } from "../../styles/styleHelpers";

import Icon from "../icons/ThreeDotsIcon";
import SimpleLink from "../links/SimpleLink";

const MenuButton = ({ disabled, items, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapper = useRef(null);

  useClickOutside(wrapper, isOpen, () => {
    setIsOpen(false);
  });

  return (
    <Wrapper ref={wrapper} {...rest}>
      <Button
        disabled={disabled}
        onClick={() => (!disabled ? setIsOpen(!isOpen) : null)}
      >
        <Icon />
      </Button>
      <Menu isOpen={isOpen}>
        {items.map((item, index) => (
          <MenuItem
            onClick={item.handleClick}
            small
            nounderline
            danger={item.danger}
            key={index}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  ${buttonReset()}

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;

  background-color: white;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.coolGray200};
  box-shadow: ${(props) => props.theme.boxShadow};

  cursor: pointer;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      border-color: ${(props) => props.theme.colors.blue};
    }
  }

  &:disabled {
    cursor: default;

    svg {
      opacity: 0.3;
      pointer-events: none;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: max-content;
  padding: 20px 20px;

  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray200};
  border-radius: 4px;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);

  z-index: 2;
  transition: transform 0.2s, opacity 0.2s;

  ${(props) =>
    props.isOpen
      ? css`
          opacity: 1;
          transform: translateY(100%);
        `
      : css`
          opacity: 0;
          transform: translateY(120%);
          pointer-events: none;
        `}
`;

const MenuItem = styled(SimpleLink)`
  ${buttonReset()}

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default MenuButton;
