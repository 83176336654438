import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  getAllTasksFromApi,
  getProjectDeliverablesFromApi,
  getProjectPaceFromApi,
} from "../../../utils/api";
import { getPastEntriesFromApi } from "../../dashboard/utils/api";
import { isNumber } from "../../../utils/helpers";

import { useSockets } from "../../../context/socketsContext";
import ActivityLog from "../../../components/ActivityLog";

import Widget from "./Widget";
import BudgetWheel from "./BudgetWheel";
import OverdueTasks from "./OverdueTasks";
import OverdueDeliverables from "./OverdueDeliverables";
import StagesWidget from "./StagesWidget";

import { useAuth } from "../../../context/authContext";

const Dashboard = ({ projectId, project, phases }) => {
  const {
    startProjectTaskListeners,
    stopProjectTaskListeners,
    startProjectDeliverableListeners,
    stopProjectDeliverableListeners,
  } = useSockets();

  const { user } = useAuth();

  const [tasks, setTasks] = useState([]);
  const [deliverables, setDeliverables] = useState([]);

  const [trackedDollars, setTrackedDollars] = useState(null);
  const [budgetedDollars, setBudgetedDollars] = useState(0);
  const [paceDollars, setPaceDollars] = useState(null);

  useEffect(() => {
    // for budget wheel
    // - pace bar
    getProjectPace();

    // - dollars tracked
    if (project?.harvestId) {
      getTrackedDollars(project.harvestId);
    }

    // - total dollars budgeted

    let totalRate = 0;

    // roles assigned to the project
    project?.tasks?.forEach((roleBudget) => {
      // total dollars budgeted for this role
      totalRate += roleBudget.totalRate;
    });

    setBudgetedDollars(totalRate);
  }, [project]);

  useEffect(() => {
    if (projectId) {
      getTasks();
      getDeliverables();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectId && tasks && deliverables) {
      startProjectTaskListeners(projectId, {
        taskCreateCallback: (newTask) => {
          setTasks([...tasks, newTask]);
        },
        taskEditCallback: (updatedTask) => {
          setTasks(
            tasks.map((task) =>
              task._id === updatedTask._id ? { ...task, ...updatedTask } : task,
            ),
          );
        },
        taskDeleteCallback: (deletedTaskId) => {
          setTasks(tasks.filter((task) => task._id !== deletedTaskId));
        },
      });

      startProjectDeliverableListeners(projectId, {
        deliverableCreateCallback: (newDeliverable) => {
          setDeliverables([...deliverables, newDeliverable]);
        },
        deliverableEditCallback: (updatedDeliverable) => {
          setDeliverables(
            deliverables.map((deliverable) =>
              deliverable._id === updatedDeliverable._id
                ? { ...deliverable, ...updatedDeliverable }
                : deliverable,
            ),
          );
        },
        deliverableDeleteCallback: (deletedDeliverableId) => {
          setDeliverables(
            deliverables.filter(
              (deliverable) => deliverable._id !== deletedDeliverableId,
            ),
          );
        },
      });

      return () => {
        stopProjectTaskListeners(projectId);
        stopProjectDeliverableListeners(projectId);
      };
    }
  }, [projectId, tasks, deliverables]);

  const getTasks = async () => {
    try {
      const tasksFromApi = await getAllTasksFromApi({ projectId });
      setTasks(tasksFromApi);
    } catch (error) {
      console.error("error getting tasks", error);
    }
  };

  const getDeliverables = async () => {
    try {
      const deliverablesFromApi = await getProjectDeliverablesFromApi(
        projectId,
      );
      setDeliverables(deliverablesFromApi);
    } catch (error) {
      console.error("error getting deliverables", error);
    }
  };

  const getTrackedDollars = async (harvestId) => {
    try {
      // get the tracked time in dollars
      const timeEntries = await getPastEntriesFromApi({
        projectId: harvestId,
        calculate: true,
      });

      setTrackedDollars(timeEntries.data.data.result);
    } catch (error) {
      console.error("error getting tracked dollars", error);
    }
  };

  const getProjectPace = async () => {
    try {
      const { dollars } = await getProjectPaceFromApi(project._id);
      setPaceDollars(dollars || 0);
    } catch (error) {
      console.error("get project pace failed", error);
    }
  };

  return (
    <Container>
      <StagesCol>
        <StagesWidgetContainer title="Project Stages">
          <StagesWidget
            stages={
              // Converts the stages from an object to array
              project.stages
                ? Object.keys(project.stages).map((key) => ({
                    name: key,
                    ...project.stages[key],
                  }))
                : null
            }
          />
        </StagesWidgetContainer>
      </StagesCol>

      <MiddleCol>
        <Widget title="Overdue Deliverables">
          <OverdueDeliverables
            projectId={project._id}
            deliverables={deliverables}
          />
        </Widget>

        <Widget title="Overdue Tasks">
          <OverdueTasks
            projectId={project._id}
            phases={phases || []}
            tasks={tasks}
            deliverables={deliverables}
          />
        </Widget>
        <Widget title="Recent Activity">
          <ActivityLog reference={project._id} naked />
        </Widget>
      </MiddleCol>

      {user.accessLvl === "admin" || user.accessLvl === "super" ? (
        <RightCol>
          <Widget title="Budget">
            <WheelContainer>
              <BudgetWheel
                label="Tracked Time"
                current={trackedDollars}
                total={budgetedDollars}
                pace={paceDollars}
              />
            </WheelContainer>
          </Widget>
        </RightCol>
      ) : null}

      {/* placeholder to ensure we have padding on the right side */}
      <LastCol />

      {/* {openTeamDetails ? (
        <TeamDetails
          isOpen={openTeamDetails}
          handleClose={() => setOpenTeamDetails(false)}
          members={project.teamMembersInfo}
          project={project}
        />
      ) : null}  */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  min-height: 100%;
`;

// const StagesCol = styled(StagesWidget)`
//   flex: 0 0 324px;
// `;
const StagesCol = styled.div`
  flex: 0 0 324px;
`;

const MiddleCol = styled.div`
  flex: 0 0 44%;
`;

const RightCol = styled.div`
  flex: 0 0 31%;
`;

// placeholder at the end so we always have padding on the right edge
const LastCol = styled.div`
  flex: 0 0 20px;
`;

const StagesWidgetContainer = styled(Widget)`
  padding-right: 38px;
`;

const WheelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Dashboard.propTypes = {
  project: PropTypes.object,
};
Dashboard.defaultProps = {
  project: {},
};

export default Dashboard;
