import React from "react";
import styled from "styled-components";

const ClockStopIcon = ({ ...props }) => {
  return (
    <Svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9301 4.86316V2.85895H14.2436C15.0316 2.85895 15.6446 2.21053 15.6446 1.44421C15.6446 0.677895 15.0316 0 14.2728 0H8.78548C7.99741 0 7.38447 0.648421 7.38447 1.41474C7.38447 2.18105 8.0266 2.82947 8.78548 2.82947H10.0989V4.86316C4.40733 5.57053 0 10.4632 0 16.3874C0 22.7832 5.16621 28 11.4999 28C17.8336 28 22.9999 22.7832 22.9999 16.3874C23.029 10.4632 18.6217 5.57053 12.9301 4.86316ZM11.5291 25.1705C6.74234 25.1705 2.8312 21.2211 2.8312 16.3874C2.8312 11.5537 6.74234 7.60421 11.5291 7.60421C16.3159 7.60421 20.227 11.5537 20.227 16.3874C20.227 21.2211 16.3159 25.1705 11.5291 25.1705Z"
        fill="#DC2626"
      />
      <rect
        x="7.34082"
        y="11.8184"
        width="8.31848"
        height="8.4"
        rx="2"
        fill="#DC2626"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default ClockStopIcon;
