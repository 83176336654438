import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  format,
  getYear,
  getMonth,
  startOfWeek,
  startOfQuarter,
  endOfQuarter,
  differenceInDays,
  getDay,
  getDaysInMonth,
  getDate,
  getDayOfYear,
} from "date-fns";

import { useAuth } from "../../context/authContext";
import { respondTo } from "../../styles/styleHelpers";

import { getMemberEntriesFromApi } from "./utils/api";

import { getMembersFromApi } from "../../utils/api";
import TargetCard from "./components/TargetCard";
import SelectDropdown from "../../components/SelectDropdown";

const Targets = () => {
  const { user, isManagerOrAdmin } = useAuth();

  const [loading, setLoading] = useState("false");
  const [members, setMembers] = useState([]);
  const [todayHours, setTodayHours] = useState(null);
  const [weekHours, setWeekHours] = useState(null);
  const [monthHours, setMonthHours] = useState(null);
  const [quarterHours, setQuarterHours] = useState(null);
  const [yearHours, setYearHours] = useState(null);

  const [roleHasAuthority, setRoleHasAuthority] = useState(false);

  const year = getYear(new Date());
  const yearPace = ((getDayOfYear(new Date()) / 365) * 100).toFixed(2);

  let quarter = startOfQuarter(new Date());
  quarter = format(quarter, "yyyy-MM-dd");
  const quarterDays =
    differenceInDays(endOfQuarter(new Date()), startOfQuarter(new Date())) + 1;
  const dayOfQuarter =
    differenceInDays(new Date(), startOfQuarter(new Date())) + 1;
  const quarterPace = ((dayOfQuarter / quarterDays) * 100).toFixed(2);

  let month = getMonth(new Date());
  month += 1;
  month = `${year}-${month}-01`;
  const monthPace = (
    (getDate(new Date()) / getDaysInMonth(new Date())) *
    100
  ).toFixed(2);

  const today = format(new Date(), "yyyy-MM-dd");
  const todayPace = 100;

  let startWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const week = format(startWeek, "yyyy-MM-dd");
  const weekPace = (getDay(new Date()) / 5) * 100;

  startWeek = format(startWeek, "d");

  /*
  |--------------------------------------------------------------------------
  | Get all members on mount
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (user) {
      // check if user has authoritative role
      const isCoord =
        user.role && user.role.role.toLowerCase().includes("project");
      const isProducer =
        user.role && user.role.role.toLowerCase().includes("producer");
      const isPartner =
        user.role && user.role.role.toLowerCase().includes("partner");
      const isDirector =
        user.role && user.role.role.toLowerCase().includes("director");

      const thisRoleHasAuthority =
        isCoord || isProducer || isPartner || isDirector;
      setRoleHasAuthority(thisRoleHasAuthority);

      // if user doesn't have any authority, auto load their own entries
      if (!thisRoleHasAuthority && !isManagerOrAdmin) {
        getEntries(user.harvestId);
      }

      getMembers();
    }
  }, [user]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get All Members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();

      setMembers(results);
      return;
    } catch (error) {
      console.error("Get project error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Entries
  |--------------------------------------------------------------------------
  */
  const getEntries = async (id) => {
    setLoading("true");

    try {
      const result = await getMemberEntriesFromApi(id, `${year}-01-01`);
      const timeEntries = result?.data?.data?.result;
      let tempTodayHours = 0;
      let tempWeekHours = 0;
      let tempMonthHours = 0;
      let tempQuarterHours = 0;
      let tempYearHours = 0;

      if (timeEntries) {
        timeEntries
          .filter((entry) => {
            return entry.spent_date >= today;
          })
          .forEach((item) => {
            tempTodayHours += item.rounded_hours;
          });

        timeEntries
          .filter((entry) => {
            return entry.spent_date >= week;
          })
          .forEach((item) => {
            tempWeekHours += item.rounded_hours;
          });

        timeEntries
          .filter((entry) => {
            return entry.spent_date >= month;
          })
          .forEach((item) => {
            tempMonthHours += item.rounded_hours;
          });

        timeEntries
          .filter((entry) => {
            return entry.spent_date >= quarter;
          })
          .forEach((item) => {
            tempQuarterHours += item.rounded_hours;
          });

        timeEntries.forEach((entry) => {
          tempYearHours += entry.rounded_hours;
        });

        setTodayHours(tempTodayHours.toFixed(2));
        setWeekHours(tempWeekHours.toFixed(2));
        setMonthHours(tempMonthHours.toFixed(2));
        setQuarterHours(tempQuarterHours.toFixed(2));
        setYearHours(tempYearHours.toFixed(2));
        setLoading("false");

        return timeEntries;
      }
    } catch (error) {
      console.error("Get project error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Change state when a member is selected from the dropdown
  |--------------------------------------------------------------------------
  */
  const changeMember = (e) => {
    const selectedMember = members.filter((item) => {
      return item._id === e.value;
    });

    getEntries(selectedMember[0].harvestId);
  };

  return (
    <TargetsContainer>
      <Header>
        <HeaderLeft>
          <Name>Billed vs Target</Name>

          {user.role &&
          members.length &&
          (roleHasAuthority || isManagerOrAdmin) ? (
            <StyledSelectDropdown
              name="members"
              onChange={(e) => changeMember(e)}
              placeholder="Select Member"
              options={members
                .filter((member) => {
                  return roleHasAuthority ||
                    isManagerOrAdmin ||
                    member.name === user.name
                    ? true
                    : false;
                })
                .map((member) => {
                  return {
                    label: member.name,
                    value: member._id,
                  };
                })}
            />
          ) : null}
        </HeaderLeft>
      </Header>

      <Cards loading={loading}>
        <TargetCard
          title="Today"
          hours={todayHours}
          pace={todayPace}
          target={8}
        />
        <TargetCard
          title="Week"
          hours={weekHours}
          pace={weekPace}
          target={32}
        />
        <TargetCard
          title="Month"
          hours={monthHours}
          pace={monthPace}
          target={128}
        />
        <TargetCard
          title="Quarter"
          hours={quarterHours}
          pace={quarterPace}
          target={384}
        />
        <TargetCard
          title="Year"
          hours={yearHours}
          pace={yearPace}
          target={1536}
        />
      </Cards>
    </TargetsContainer>
  );
};

const TargetsContainer = styled.div`
  padding: 53px 0 53px 25px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 23px 0;
  margin-right: -38px;
  opacity: ${(props) => (props.loading === "true" ? "30%" : "1")};
  pointer-events: ${(props) => (props.loading === "true" ? "none" : "all")};
  transition: 0.3s ease-in-out;
  background-color: ${(props) => props.theme.colors.cardBackground};

  &::after {
    content: "";
    flex: auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 55px 21px 26px;
  align-items: flex-start;

  .choices {
    width: 400px;
    z-index: 4;

    * {
      font-size: 20px;
    }
  }

  .choices__inner {
    border-radius: 20px;
    background: #e1e0e4;
    min-height: 55px;
    padding: 0px !important;
    border: 0px;
    display: flex;
    align-items: center;
  }

  .choices__list {
    padding: 0px;
  }

  .choices__item {
    padding-left: 20px;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 0px;
  margin-right: 60px;
  line-height: 1;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 300px;
`;

export default Targets;
