import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useAuth } from "../../context/authContext";

import { getSettings, createWeeklyExperienceSurvey } from "../../utils/api";

import Button from "../../components/buttons/Button";

const Survey = () => {
  const { user } = useAuth();

  const [settings, setSettings] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [rating, setRating] = useState(null);
  const [tags, setTags] = useState([]);
  const [text, setText] = useState("");
  const [step, setStep] = useState(0);
  const [response, setResponse] = useState(null);
  const [endMessages, setEndMessages] = useState(null);
  const date = new Date();

  /*
  |--------------------------------------------------------------------------
  | Set up
  |--------------------------------------------------------------------------
  */

  const getSettingsData = async () => {
    try {
      const response = await getSettings();

      if (response) {
        const tempQuestions = response.weeklyExperienceSurvey.questions.map(
          (question) => {
            return {
              title: question,
              rating: null,
              tags: null,
              feedback: null,
            };
          },
        );
        setQuestions(tempQuestions);
        setSettings(response.weeklyExperienceSurvey);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  // Set up ending messages
  const setupEnding = async () => {
    const endMessage = [
      {
        title: `Thanks for sharing ${user.handle}, and sorry about that.`,
        body: `We'll let ${user.directorName} know and you guys can chat next week. We want to help you have more green weeks :)`,
      },
      {
        title: `Thanks ${user.handle}.`,
        body: `Sounds like you had an OK week. We'll let ${user.directorName} know, and thanks for the feedback :)`,
      },
      {
        title: `Thanks ${user.handle} :)`,
        body: `Glad to hear you had a great week! We'll let ${user.directorName} know.`,
      },
    ];

    setEndMessages([...endMessage]);
  };

  // Decide which thank you message to display
  useEffect(() => {
    if (questions) {
      let response;
      let bad = 0;
      let good = 0;

      questions.forEach((question) => {
        if (question.rating === 0) {
          bad++;
        } else if (question.rating === 2) {
          good++;
        }
      });

      if (good === questions.length) {
        response = 2;
      } else if (bad >= 1) {
        response = 0;
      } else {
        response = 1;
      }

      setResponse(response);
    }
  }, [questions]); //eslint-disable-line

  // Submit survey data to backend when finished
  useEffect(() => {
    if (questions && step === questions.length) {
      const data = {
        memberId: user._id,
        date,
        questions,
      };

      createSurvey(data);
    }
  }, [step]); //eslint-disable-line

  // Create Survey
  const createSurvey = async (data) => {
    try {
      const response = await createWeeklyExperienceSurvey(data);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  // Submit question and proceed to next step
  const submitQuestion = () => {
    const tempQuestions = [...questions];

    tempQuestions[step].rating = rating;
    tempQuestions[step].feedback = text;
    tempQuestions[step].tags = tags;

    setQuestions(tempQuestions);
    setRating(null);
    setText("");
    setTags([]);
    setStep(step + 1);

    // scroll to top of page
    window.scrollTo(0, 0);
  };

  const handleTag = (tag) => {
    const tempTags = [...tags];

    const index = tempTags.indexOf(tag);

    if (index > -1) {
      tempTags.splice(index, 1);
    } else {
      tempTags.push(tag);
    }

    setTags(tempTags);
  };

  // Check if user is logged in
  useEffect(() => {
    setupEnding();
    getSettingsData();
  }, []); //eslint-disable-line

  return (
    <SurveyContainer>
      <SurveyInner>
        {questions && step < questions.length && (
          <SurveyStart>
            <Title>{questions[step].title}</Title>
            <RatingContainer>
              <Rating
                number={0}
                rating={rating}
                onClick={() => {
                  setRating(0);
                }}
              />
              <Rating
                number={1}
                rating={rating}
                onClick={() => {
                  setRating(1);
                }}
              />
              <Rating
                number={2}
                rating={rating}
                onClick={() => {
                  setRating(2);
                }}
              />
            </RatingContainer>
            <FeedbackContainer>
              <FeedbackTrigger>
                <FeedbackTitle>Feedback</FeedbackTitle>
                <FeedbackSubtitle>
                  (Please select all that apply)
                </FeedbackSubtitle>
              </FeedbackTrigger>
              <FeedbackDropdown className="dropdown">
                <Tags>
                  {settings &&
                    settings.tags.map((tag, index) => {
                      return (
                        <Tag
                          key={index}
                          rating={rating}
                          className={
                            tags && tags.includes(tag) ? "-active" : ""
                          }
                          onClick={() => {
                            handleTag(tag);
                          }}
                        >
                          {tag}
                        </Tag>
                      );
                    })}
                </Tags>
                <FeedbackText
                  value={text}
                  placeholder="Can you provide more information as to your rating? (optional)"
                  onChange={(e) => setText(e.target.value)}
                ></FeedbackText>
              </FeedbackDropdown>
            </FeedbackContainer>

            {rating !== null && (
              <Button
                onClick={() => {
                  submitQuestion();
                }}
              >
                Submit
              </Button>
            )}
          </SurveyStart>
        )}

        {questions &&
          step === questions.length &&
          response !== null &&
          endMessages !== null && (
            <SurveyEnd>
              <Title>{endMessages[response].title}</Title>
              <SurveyEndText>{endMessages[response].body}</SurveyEndText>
              <Button href="/">Return to Dashboard</Button>
            </SurveyEnd>
          )}
      </SurveyInner>
    </SurveyContainer>
  );
};

const SurveyContainer = styled.div`
  padding: 100px 0px;
  background-color: #191919;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurveyInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

const SurveyStart = styled.div`
  text-align: center;
  width: 100%;
`;

const Title = styled.h2`
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 44px;
  line-height: 1.17;
  max-width: 640px;
  margin: auto;
  margin-bottom: 60px;
`;

const RatingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
`;

const Rating = styled.div`
  outline: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 0px 20px;
  border-radius: 50%;
  border: 3px solid black;
  border-color: ${(props) =>
    props.number === 0
      ? "#F37B7B"
      : props.number === 1
      ? "#FFF599"
      : "#D4F3D1"};
  background-color: ${(props) =>
    props.rating === 0 && props.number === 0
      ? "#F37B7B"
      : props.rating === 1 && props.number === 1
      ? "#FFF599"
      : props.rating === 2 && props.number === 2
      ? "#D4F3D1"
      : "transparent"};
`;

const FeedbackContainer = styled.div`
  background-color: #252525;
  padding: 50px 0 1px 0;
  border-radius: 10px;
  margin-bottom: 50px;
`;

const FeedbackTrigger = styled.div`
  display: flex;
  align-items: flex-end;
  padding-left: 80px;
  padding-right: 80px;
  margin-bottom: 49px;
`;

const FeedbackTitle = styled.p`
  color: white;
  text-align: left;
  font-size: 22px;
  margin-right: 8px;
  margin-bottom: 0px;
`;

const FeedbackSubtitle = styled.p`
  color: white;
  text-align: left;
  font-size: 12px;
  margin-bottom: 4px;
`;

const FeedbackDropdown = styled.div`
  //display: none;
  padding-bottom: 49px;
  padding-top: 60px;
  border-top: 1px solid #373535;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
`;

const Tag = styled.div`
  display: inline-block;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  outline: none;
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50px;
  font-size: 16px;
  padding: 10px 30px;
  margin: 0px 10px 20px 10px;

  &:hover,
  &:focus {
    color: ${(props) =>
      props.rating
        ? props.theme.colors.oldBlack2
        : props.theme.colors.pureWhite};
    background-color: #204aee;
    background-color: ${(props) =>
      props.rating === 0
        ? "#F37B7B"
        : props.rating === 1
        ? "#FFF599"
        : props.rating === 2
        ? "#D4F3D1"
        : "#204aee"};
    border: 1px solid
      ${(props) =>
        props.rating === 0
          ? "#F37B7B"
          : props.rating === 1
          ? "#FFF599"
          : props.rating === 2
          ? "#D4F3D1"
          : "#204aee"};
  }

  &.-active {
    color: ${(props) =>
      props.rating
        ? props.theme.colors.oldBlack2
        : props.theme.colors.pureWhite};
    background-color: ${(props) =>
      props.rating === 0
        ? "#F37B7B"
        : props.rating === 1
        ? "#FFF599"
        : props.rating === 2
        ? "#D4F3D1"
        : "#204aee"};
    border: 1px solid
      ${(props) =>
        props.rating === 0
          ? "#F37B7B"
          : props.rating === 1
          ? "#FFF599"
          : props.rating === 2
          ? "#D4F3D1"
          : "#204aee"};
  }
`;

const FeedbackText = styled.textarea`
  width: calc(100% - 160px);
  height: 200px;
  border-radius: 10px;
  padding: 20px;
  margin-left: 80px;
  margin-right: 80px;
  font-size: 14px;
  outline: none;
  resize: none;
`;

const SurveyEnd = styled.div`
  text-align: center;
`;

const SurveyEndText = styled.div`
  color: white;
  font-size: 20px;
  margin-bottom: 50px;
`;

export default Survey;
