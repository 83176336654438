import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { eachDayOfInterval, isSameDay, subDays } from "date-fns";

import { getStandardizedDate } from "../../../utils/helpers";

import LineGraph from "./LineGraph";

const Burndown = ({ budget, timeEntries }) => {
  const [graphData, setGraphData] = useState([]);
  const [annotations, setAnnotations] = useState({});

  useEffect(() => {
    if (budget && timeEntries) {
      calculateData();
    }
  }, [budget, timeEntries]);

  /*
  |--------------------------------------------------------------------------
  | Calculate the graph data for the time entries
  |--------------------------------------------------------------------------
  */
  const calculateData = async () => {
    // Sort the entries by the spent_date
    const sortedEntries = timeEntries.sort((a, b) => {
      return new Date(a.spent_date) - new Date(b.spent_date);
    });

    // Get the array of dates ranging from the first entry's spent_date, to today
    const start = getStandardizedDate(sortedEntries[0].spent_date);
    const end = getStandardizedDate(new Date());
    const dates = eachDayOfInterval({ start, end });
    const tempGraphData = [];

    // Add the first item with the original budget the day before the first time entry is tracked
    tempGraphData.push({ x: subDays(start, 1), y: budget });

    let totalBudget = budget;

    // Loop over each date from the earliest spent_date of the entries to today
    dates.forEach((oldDate) => {
      const date = getStandardizedDate(oldDate);

      // Loop over each entry to subtract from the total budget if the entry's spent_date lands on the looped date
      sortedEntries.forEach((entry) => {
        const spentDate = getStandardizedDate(entry.spent_date);

        // Check to see if the current looped date is on the entry spent_date
        // If the entry's spent_date matches the looped date, subtract the spent amount from the budget
        if (isSameDay(date, spentDate)) {
          if (
            entry.rounded_hours &&
            !isNaN(entry.rounded_hours) &&
            entry.billable_rate
          ) {
            totalBudget -= entry.rounded_hours * entry.billable_rate;
          }
        }
      });

      // For each date, log the amount of active and overdue tasks
      tempGraphData.push({
        x: date,
        y: Math.floor(totalBudget),
      });
    });

    // If there are negative values, add a danger area
    if (tempGraphData[tempGraphData.length - 1].y < 0) {
      const tempAnnotations = {
        yaxis: [
          {
            y: -10000000,
            y2: 0,
            borderColor: "#000",
            fillColor: "#EF4444",
            opacity: 0.2,
          },
        ],
      };

      setAnnotations(tempAnnotations);
    }

    setGraphData(tempGraphData);
  };

  return (
    <Container>
      <Title>Project Budget Burndown</Title>
      <GraphContainer>
        <LineGraph
          useDollars
          yLabel="Budget"
          entryLabel="Budget Remaining"
          lineColor="#22C55E"
          annotations={annotations}
          data={graphData}
        />
      </GraphContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 20;
`;

const GraphContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 24px 24px 10px 24px;
  width: 50%;

  text {
    fill: ${(props) => props.theme.colors.coolGray400};
    font-weight: 500;
    font-size: 12px;
  }

  .apexcharts-yaxis-title {
    transform: translateX(-5px);
  }
`;

export default Burndown;
