import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { motion, AnimatePresence } from "framer-motion";

import { useNotifications } from "../../context/notificationsContext";
import useClickOutside from "../../hooks/useClickOutside";

const Modal = () => {
  const { isModalOpen, modalContent, closeModal } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);

  const wrapper = useRef(null);

  // close it when click is outside the modal wrapper
  useClickOutside(wrapper, isOpen, () => {
    closeModal();
  });

  useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <AnimatePresence>
      {isOpen ? (
        <ScreenBlocker
          initial={{ opacity: 0.0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.0 }}
          transition={{ duration: 0.1 }}
        >
          <ModalWrapper
            ref={wrapper}
            initial={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            exit={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            transition={{ type: "spring", duration: 0.6 }}
          >
            {modalContent}
          </ModalWrapper>
        </ScreenBlocker>
      ) : null}
    </AnimatePresence>
  );
};

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${(props) => rgba(props.theme.colors.oldBlack2, 0.15)};
  transition: opacity 0.2s;
`;

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 50px 50px 100px #bbbbbb, -50px -50px 100px #e9e9e9;
`;

export default Modal;
