import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";
import { motion, AnimatePresence } from "framer-motion";

import Button from "../buttons/Button";
import SimpleLink from "../links/SimpleLink";
import { Textarea } from "../Form";
import SelectDropdown from "../SelectDropdown";

const ExtraTime = ({
  isOpen,
  canOverride,
  overrideClose,
  submit,
  timeOver,
  phases,
  currentExistingExtraTime,
}) => {
  const [notes, setNotes] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");

  useEffect(() => {
    if (currentExistingExtraTime) {
      setNotes(currentExistingExtraTime.notes);
      setSelectedPhase(currentExistingExtraTime.phase);
    }
  }, [currentExistingExtraTime]);

  const getPhaseName = (id) => {
    if (id && phases?.length) {
      const thisPhase = phases.find((phase) => phase._id === id);

      return thisPhase?.name || "";
    } else {
      return "";
    }
  };

  return (
    <AnimatePresence>
      {isOpen ? (
        <ScreenBlocker
          initial={{ opacity: 0.0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.0 }}
          transition={{ duration: 0.1 }}
        >
          <Modal
            initial={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            exit={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            transition={{ type: "spring", duration: 0.6 }}
          >
            {canOverride && (
              <OverrideButton danger small onClick={overrideClose}>
                Override
              </OverrideButton>
            )}
            <Header>Way to go on the extra effort!</Header>

            <Info>
              With this most recent time entry, you’ve exceeded your time booked
              on this project for the week by <b>{timeOver}&nbsp;hours</b>.
              Please leave some notes so your Project Manager knows what to do
              with the extra time.
            </Info>

            {phases?.length ? (
              <PhaseSelect
                name="phases"
                onChange={(e) => {
                  setSelectedPhase(e.value);
                }}
                value={
                  selectedPhase
                    ? {
                        label: getPhaseName(selectedPhase),
                        value: selectedPhase,
                      }
                    : null
                }
                placeholder="Select relevant project phase"
                options={phases.map((phase) => ({
                  label: phase.name,
                  value: phase._id,
                }))}
              />
            ) : null}

            <Notes
              placeholder="Notes go here"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <Submit
              small
              onClick={() => {
                submit(notes, selectedPhase);
                setNotes("");
              }}
            >
              Submit
            </Submit>
          </Modal>
        </ScreenBlocker>
      ) : null}
    </AnimatePresence>
  );
};

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${(props) => rgba(props.theme.colors.oldBlack2, 0.15)};
  transition: opacity 0.2s;
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 50px 50px 100px #bbbbbb, -50px -50px 100px #e9e9e9;
`;

const OverrideButton = styled(SimpleLink)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const Header = styled.h2`
  max-width: 400px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Info = styled.p`
  margin-bottom: 50px;
`;

const PhaseSelect = styled(SelectDropdown)`
  margin-bottom: 20px;
  cursor: pointer;
`;

const Notes = styled(Textarea)`
  height: 140px;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
`;

const Submit = styled(Button)`
  width: 130px;
  margin-left: auto;
`;

ExtraTime.propTypes = {
  canOverride: PropTypes.bool,
  overrideClose: PropTypes.func,
  closeAndRedirect: PropTypes.func,
  submit: PropTypes.func,
};

ExtraTime.defaultProps = {
  canOverride: false,
  overrideClose: null,
  closeAndRedirect: null,
  submit: null,
};

export default ExtraTime;
