import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Link } from "react-router-dom";

import {
  createContactOnApi,
  getContactsFromApi,
  updateContactOnApi,
  deleteContactsOnApi,
} from "../../utils/api";
import { getStandardizedDate } from "../../utils/helpers";

import useBulkSelect from "../../hooks/useBulkSelect";
import {
  searchItems,
  getUniqueOptions,
  filterItems,
} from "../../utils/sorting";

import { useNotifications } from "../../context/notificationsContext";

import Filters, { FilterGroup } from "../../components/NewTable/Filters";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import { TableCell, Checkbox } from "../../components/NewTable/components";
import Table from "../../components/NewTable/index";
import { Input } from "../../components/newForm";
import CheckMenu from "./components/CheckMenu";
import Tabs, { TabContent } from "../../components/Tabs";
import PageLayout from "./components/pageLayout";
import CreateContact from "./components/createContact";
import UpdateContact from "./components/updateContact";
import ProfileImage from "../../components/ProfileImage";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  company: "company.name",
  owner: "owner.name",
  tier: "company.tier",
};

// filter chips
const filters = ["company", "owner", "status", "tier", "title"];

const tabs = [
  // {
  //   name: "Overview",
  //   id: "overview",
  // },
  // {
  //   name: "Tasks",
  //   id: "tasks",
  //   number: 8,
  // },
  {
    name: "Table",
    id: "table",
  },
  // {
  //   name: "Kanban",
  //   id: "kanban",
  // },
  // {
  //   name: "Reports",
  //   id: "reports",
  // },
];

const Contacts = () => {
  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const [originalContacts, setOriginalContacts] = useState([]);
  const [contacts, setContacts] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterActive, setFilterActive] = useState(false);

  const [currentContact, setCurrentContact] = useState(null);
  const [currentTab, setCurrentTab] = useState("table");
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const {
    selectedItems,
    handleSelect,
    handleSelectAll,
    clearSelectedItems,
  } = useBulkSelect(contacts);

  /*
  |--------------------------------------------------------------------------
  | Get all contacts
  |--------------------------------------------------------------------------
  */
  const getContacts = async (shouldRefresh = false) => {
    setLoading(true);

    let tempContacts;

    if (originalContacts && originalContacts.length && !shouldRefresh) {
      tempContacts = [...originalContacts];
    } else {
      tempContacts = await getContactsFromApi();
    }

    setOriginalContacts(tempContacts);

    // If a filter was selected or a sort is active, filter the entries
    if ((selectedFilters && selectedFilters.length) || searchText) {
      filterContacts(selectedFilters);
    } else {
      setContacts(tempContacts);
    }

    setLoading(false);
  };

  /*
  |--------------------------------------------------------------------------
  | Get the appropriate contacts based on the page number
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getContacts();
  }, [selectedFilters, searchText]);

  /*
  |--------------------------------------------------------------------------
  | Filter the contacts
  |--------------------------------------------------------------------------
  */
  const filterContacts = (tempFilters) => {
    // Filters out the contacts based on the filter value
    let tempContacts = [...originalContacts];

    // Filters the contacts
    if (tempFilters && tempFilters.length) {
      tempContacts = filterItems(
        // Formats filters
        tempFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempContacts,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempContacts = searchItems(searchText, tempContacts, [
        "owner.name",
        "status",
        "firstName",
        "lastName",
        "contactInfo.email",
        "contactInfo.phone",
        "contactInfo.address1",
        "contactInfo.address2",
        "contactInfo.city",
        "contactInfo.state",
        "contactInfo.zipcode",
        "title",
        "company.name",
        "company.tier",
      ]);
    }

    setContacts(tempContacts);
  };

  /*
  |--------------------------------------------------------------------------
  | Create a new contact
  |--------------------------------------------------------------------------
  */
  const createNewContact = async (data) => {
    try {
      await createContactOnApi(data);

      setCreateOpen(false);
      openAlertPopup("Client created successfully!", null, true);
      getContacts(true);
    } catch (err) {
      const errorMessage = typeof err === "string" ? err : "";

      openAlertPopup(
        "Create contact failed",
        `Please check the entered data and try again. ${errorMessage}`,
      );

      throw new Error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update an existing contact
  |--------------------------------------------------------------------------
  */
  const updateContact = async (id, data) => {
    try {
      let updatedContact = await updateContactOnApi(id, data);
      updatedContact = updatedContact.data.result;

      const tempContacts = [...contacts];
      const tempContactsIndex = tempContacts.findIndex(
        (contact) => contact._id === id,
      );
      tempContacts[tempContactsIndex] = updatedContact;

      setContacts(tempContacts);

      const tempOriginalContacts = [...originalContacts];
      const tempOriginalContactsIndex = tempContacts.findIndex(
        (contact) => contact._id === id,
      );
      tempOriginalContacts[tempOriginalContactsIndex] = updatedContact;
      setOriginalContacts(tempOriginalContacts);

      setUpdateOpen(false);
      openAlertPopup("Contact updated successfully!", null, true);
    } catch (err) {
      openAlertPopup(
        "Update contact failed",
        "Please check the entered data and try again",
      );
      throw new Error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete contacts
  |--------------------------------------------------------------------------
  */
  const handleDelete = async () => {
    openPromptPopup({
      header: "Delete selected contacts",
      body: `Are you sure you want to delete the ${
        selectedItems.length
      } selected contact${selectedItems.length === 1 ? "" : "s"}?`,
      confirmCallback: async () => {
        try {
          await deleteContactsOnApi({ contacts: selectedItems });
          openAlertPopup("Contacts deleted successfully!", null, true);
          clearSelectedItems();
          getContacts(true);
          closePromptPopup();
        } catch (err) {
          openAlertPopup("Delete contacts failed", "Please try again");
          closePromptPopup();
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const handleEdit = () => {
    const newContactId = selectedItems[0];
    const newCurrentContact = contacts.find(
      (contact) => contact._id === newContactId,
    );
    setCurrentContact(newCurrentContact);
    setUpdateOpen(true);
  };

  return (
    <PageLayout
      title="Contacts"
      createButton="Create Contact"
      number={contacts ? contacts.length : 0}
      handleCreate={() => setCreateOpen(true)}
    >
      <CreateContact
        isOpen={createOpen}
        close={() => setCreateOpen(false)}
        handleCreate={createNewContact}
      />
      <UpdateContact
        isOpen={updateOpen}
        close={() => setUpdateOpen(false)}
        handleUpdate={updateContact}
        currentContact={currentContact}
      />

      <TabContentSection>
        <TabContent isactive={true}>
          {contacts ? (
            <>
              <StyledFilterGroup>
                <CheckMenu
                  disabled={selectedItems.length > 0 ? false : true}
                  numItems={selectedItems.length}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />

                <Filters
                  selectedFilters={selectedFilters}
                  updateFilters={setSelectedFilters}
                  filters={filters}
                  getUniqueOptions={(filter) =>
                    getUniqueOptions(filter, originalContacts, {
                      exclude: selectedFilters,
                      propPaths: filterPaths,
                    })
                  }
                />

                <SearchInput
                  placeholder="Search items..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </StyledFilterGroup>

              {selectedFilters?.length ? (
                <SelectedFilters
                  filters={selectedFilters}
                  updateFilters={setSelectedFilters}
                />
              ) : null}

              <TableContainer>
                <Table
                  // so the scrolling is handled by the whole viewport, rather than just the table
                  scrollable={false}
                  noPadding
                  headers={[
                    {
                      component: (groupedEntries = contacts) => (
                        <Checkbox
                          isChecked={
                            groupedEntries?.length &&
                            // ? in case the items are grouped, reference the adjacent grouped items, rather than the entire array
                            groupedEntries.every((item) =>
                              selectedItems.includes(item._id),
                            )
                          }
                          onClick={() => handleSelectAll(groupedEntries)}
                        />
                      ),
                    },
                    {
                      name: "Owner",
                      accessor: "owner.name",
                    },
                    {
                      name: "Status",
                      accessor: "status",
                    },
                    {
                      name: "Tier",
                      accessor: "tier",
                    },
                    {
                      name: "First Name",
                      accessor: "firstName",
                    },
                    {
                      name: "Last Name",
                      accessor: "lastName",
                    },
                    {
                      name: "Email",
                      accessor: "contactInfo.email",
                    },
                    {
                      name: "Title",
                      accessor: "title",
                    },
                    {
                      name: "Company",
                      accessor: "company.name",
                    },
                    {
                      name: "Phone",
                      accessor: "contactInfo.phone",
                    },
                    {
                      name: "Mailing Address",
                      accessor: "contactInfo.address1",
                    },
                    {
                      name: "Created",
                      accessor: "createdAt",
                    },
                    {
                      name: "Updated",
                      accessor: "updatedAt",
                    },
                  ]}
                  entries={
                    contacts
                      ? contacts.map((contact) => {
                          return {
                            ...contact,
                            row: (sortedEntries) => (
                              <>
                                <TableCell>
                                  <Checkbox
                                    isChecked={selectedItems.includes(
                                      contact._id,
                                    )}
                                    onClick={(e) => {
                                      // ? include the sorted entries from the table so if the user sorted or grouped the items, the correct ones will be selected if they shift+click to multi-select
                                      handleSelect(
                                        contact._id,
                                        e.shiftKey,
                                        sortedEntries,
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell full>
                                  <ProfileImage
                                    name={contact.owner?.name || ""}
                                    handle={contact.owner?.handle || ""}
                                    small
                                  />
                                </TableCell>
                                <TableCell full>{contact.status}</TableCell>
                                <TableCell full>
                                  {contact.company.tier
                                    ? contact.company.tier
                                    : "N/A"}
                                </TableCell>
                                <TableCell full>
                                  <Link to={`/sales/contacts/${contact._id}`}>
                                    {contact.firstName}
                                  </Link>
                                </TableCell>
                                <TableCell full>
                                  <Link to={`/sales/contacts/${contact._id}`}>
                                    {contact.lastName}
                                  </Link>
                                </TableCell>
                                <TableCell full>
                                  {contact.contactInfo.email}
                                </TableCell>
                                <TableCell full>{contact.title}</TableCell>
                                <TableCell full>
                                  {contact.company.name}
                                </TableCell>
                                <TableCell full>
                                  {contact.contactInfo.phone}
                                </TableCell>
                                <TableCell>
                                  {contact.contactInfo.address1}
                                  <br />
                                  {contact.contactInfo.address2 ? (
                                    <>
                                      {contact.contactInfo.address2}
                                      <br />
                                    </>
                                  ) : null}
                                  {contact.contactInfo.city},{" "}
                                  {contact.contactInfo.state}{" "}
                                  {contact.contactInfo.zipcode}
                                </TableCell>
                                <TableCell full>
                                  {format(
                                    getStandardizedDate(
                                      contact.createdAt,
                                      false,
                                    ),
                                    "MMM dd, yyyy",
                                  )}
                                </TableCell>
                                <TableCell full>
                                  {format(
                                    getStandardizedDate(
                                      contact.updatedAt,
                                      false,
                                    ),
                                    "MMM dd, yyyy",
                                  )}
                                </TableCell>
                              </>
                            ),
                          };
                        })
                      : []
                  }
                />
              </TableContainer>
            </>
          ) : null}
        </TabContent>
      </TabContentSection>
    </PageLayout>
  );
};

const Header = styled.div`
  padding: 0 40px;
`;

const TabContentSection = styled.div`
  width: 100%;
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  z-index: 6;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const SearchInput = styled(Input)`
  margin-left: auto;
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

export default Contacts;
