import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { startCase } from "lodash";

import { respondTo } from "../../../styles/styleHelpers";
import { getCurrency, isBoolean } from "../../../utils/helpers";

import ValueChip from "../../../components/ValueChip";
import Editable from "../../../components/Editable";
import SelectDropdown from "../../../components/newSelectDropdown";
import { checkAndAppendHttps } from "../../../utils/helpers";
import ProfileImageGroup from "../../../components/ProfileImageGroup";
import RangeSlider from "../../../components/RangeSlider";

const DealDetails = ({
  id,
  handleEdit,
  company,
  contacts,
  primaryContact,
  team,
  estimatedBudget,
  description,
  address,
  chanceClosing,
  availMembers,
  availCompanies,
  industries,
  createIndustry,
  renewalPeriod,
  doesRenew,
}) => {
  return (
    <Container>
      <HeaderRow>
        <Header>Deal Details</Header>
      </HeaderRow>

      <StatsMain>
        <StatsCol>
          <Stat>
            <Label>Company Name</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-company-name`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit({ company: value }, "deal", "company");
                        }
                      : undefined
                  }
                  options={availCompanies.map((availCompany) => ({
                    label: availCompany.name,
                    value: availCompany._id,
                  }))}
                  iconClickOnly
                >
                  <ExLink as={Link} to={`/sales/companies/${company._id}`}>
                    {company.name}
                  </ExLink>
                </Editable>
              </Value>
            ) : null}
          </Stat>

          <Stat>
            <Label>Company Code</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-company-code`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit({ acronym: value }, "company");
                        }
                      : undefined
                  }
                >
                  {company.acronym || "N/A"}
                </Editable>
              </Value>
            ) : null}
          </Stat>

          <Stat>
            <Label>Tier</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-tier`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit({ tier: value }, "company");
                        }
                      : undefined
                  }
                  options={[
                    { label: "Tier 1", value: "Tier 1" },
                    { label: "Tier 2", value: "Tier 2" },
                    { label: "Tier 3", value: "Tier 3" },
                  ]}
                  defaultValue={
                    company.tier
                      ? {
                          label: company.tier,
                          value: company.tier,
                        }
                      : undefined
                  }
                >
                  {company.tier ? (
                    <ValueChip color="teal">{company.tier}</ValueChip>
                  ) : (
                    "N/A"
                  )}
                </Editable>
              </Value>
            ) : null}
          </Stat>

          <Stat>
            <Label>Industry</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-industry`}
                  creatable
                  handleDropdownCreate={(value) => {
                    createIndustry(value);
                    handleEdit({ industry: value }, "company");
                  }}
                  options={
                    industries?.length
                      ? industries.map((industry) => ({
                          label: industry,
                          value: industry,
                        }))
                      : null
                  }
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit({ industry: value }, "company");
                        }
                      : undefined
                  }
                >
                  {company.industry ? (
                    <ValueChip>{company.industry}</ValueChip>
                  ) : (
                    "N/A"
                  )}
                </Editable>
              </Value>
            ) : null}
          </Stat>

          <Break />

          {contacts ? (
            <Stat>
              <Label>Primary Contact</Label>
              <Value>
                <Editable
                  id={`${id}-primary-contact`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit(
                            { primaryContact: value },
                            "deal",
                            "contact",
                          );
                        }
                      : undefined
                  }
                  options={contacts.map((contact) => ({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact._id,
                  }))}
                  iconClickOnly
                >
                  {primaryContact ? (
                    <ExLink
                      as={Link}
                      to={`/sales/contacts/${primaryContact._id}`}
                    >
                      {`${primaryContact.firstName} ${primaryContact.lastName}`}
                    </ExLink>
                  ) : (
                    "N/A"
                  )}
                </Editable>
              </Value>
            </Stat>
          ) : null}

          <Stat>
            <Label>Office Number</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-office-number`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit({ phone: value }, "company", "info");
                        }
                      : undefined
                  }
                >
                  {company?.info?.phone || "N/A"}
                </Editable>
              </Value>
            ) : null}
          </Stat>

          {primaryContact ? (
            <Stat>
              <Label>Direct Number</Label>
              <Value>
                <Editable
                  id={`${id}-direct-number`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit(
                            { phone: value },
                            "contact",
                            "contactInfo",
                          );
                        }
                      : undefined
                  }
                >
                  {primaryContact?.contactInfo?.phone || "N/A"}
                </Editable>
              </Value>
            </Stat>
          ) : null}

          {primaryContact ? (
            <Stat>
              <Label>Email</Label>
              <Value>
                <Editable
                  id={`${id}-contact-email`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          handleEdit(
                            { email: value },
                            "contact",
                            "contactInfo",
                          );
                        }
                      : undefined
                  }
                  defaultValue={primaryContact?.contactInfo?.email}
                  iconClickOnly
                >
                  {primaryContact?.contactInfo?.email ? (
                    <ExLink
                      href={`mailto:${primaryContact.contactInfo.email}`}
                      target="_blank"
                    >
                      {primaryContact.contactInfo.email} <MailIcon />
                    </ExLink>
                  ) : (
                    "N/A"
                  )}
                </Editable>
              </Value>
            </Stat>
          ) : null}

          <Break />

          <Stat>
            <Label>Website</Label>
            {company ? (
              <Value>
                <Editable
                  id={`${id}-company-website`}
                  onSave={
                    handleEdit
                      ? (value) => {
                          const updatedValue = checkAndAppendHttps(value);
                          handleEdit(
                            { website: updatedValue },
                            "company",
                            "info",
                          );
                        }
                      : undefined
                  }
                  defaultValue={company?.info?.website}
                  iconClickOnly
                >
                  {company?.info?.website ? (
                    <ExLink href={company.info.website} target="_blank">
                      {company.info.website} <LinkIcon />
                    </ExLink>
                  ) : (
                    "N/A"
                  )}
                </Editable>
              </Value>
            ) : null}
          </Stat>

          <Stat>
            <Label>Address</Label>
            <Value>{address}</Value>
          </Stat>
        </StatsCol>

        <StatsCol>
          <Stat>
            <Label>Ongoing</Label>
            <Editable
              id={`${id}-ongoing`}
              onSave={
                handleEdit
                  ? (value) => {
                      handleEdit({ doesRenew: value === "yes" ? true : false });
                    }
                  : undefined
              }
              options={[
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              defaultValue={{
                label: doesRenew ? "Yes" : "No",
                value: doesRenew ? "yes" : "no",
              }}
            >
              <Value>
                {isBoolean(doesRenew) ? (doesRenew ? "Yes" : "No") : "N/A"}
              </Value>
            </Editable>
          </Stat>

          {doesRenew && renewalPeriod ? (
            <Stat>
              <Label>Period</Label>
              <Editable
                id={`${id}-renewalPeriod`}
                onSave={
                  handleEdit
                    ? (value) => {
                        handleEdit({ renewalPeriod: value });
                      }
                    : undefined
                }
                options={[
                  {
                    label: "Monthly",
                    value: "monthly",
                  },
                  {
                    label: "Yearly",
                    value: "yearly",
                  },
                ]}
                defaultValue={{
                  label: startCase(renewalPeriod),
                  value: renewalPeriod,
                }}
              >
                <Value>{startCase(renewalPeriod)}</Value>
              </Editable>
            </Stat>
          ) : null}

          <Stat>
            <Label>Deal Value</Label>
            <Value>
              <Editable
                id={`${id}-estimatedBudget`}
                onSave={
                  handleEdit
                    ? (value) => {
                        handleEdit({ estimatedBudget: value });
                      }
                    : undefined
                }
                defaultValue={estimatedBudget}
              >
                {estimatedBudget ? getCurrency(estimatedBudget) : "N/A"}
              </Editable>
            </Value>
          </Stat>

          <Stat>
            <Label>Probability of Closing</Label>
            <Value>
              <Editable
                id={`${id}-chanceClosing`}
                onSave={
                  handleEdit
                    ? (values) => {
                        handleEdit({ chanceClosing: values.chanceClosing });
                      }
                    : undefined
                }
                edit={(editRef) => (
                  <RangeSlider
                    min="0"
                    max="100"
                    step="1"
                    defaultValue={chanceClosing}
                    ref={(el) => (editRef["chanceClosing"] = el)}
                  />
                )}
              >
                <ValueEm>{chanceClosing ? `${chanceClosing}%` : "N/A"}</ValueEm>
              </Editable>
            </Value>
          </Stat>

          <Stat>
            <Label>Weighted Deal Value</Label>
            <Value>
              {estimatedBudget && chanceClosing
                ? getCurrency(estimatedBudget * (chanceClosing * 0.01))
                : "N/A"}
            </Value>
          </Stat>

          <Break />

          <Stat>
            <Label>Deal Team</Label>
            <Value>
              <Editable
                id={`${id}-team`}
                onSave={
                  handleEdit
                    ? (value) => {
                        if (Array.isArray(value)) {
                          const newTeam = [];

                          value.forEach((member) => {
                            newTeam.push(member.value);
                          });

                          handleEdit({ team: newTeam });
                        }
                      }
                    : undefined
                }
                edit={
                  <SelectDropdown
                    options={
                      availMembers
                        ? availMembers.map((avail) => {
                            return { label: avail.name, value: avail._id };
                          })
                        : []
                    }
                    defaultValue={
                      team
                        ? availMembers
                            .filter((avail) => team.includes(avail._id))
                            .map((avail) => {
                              return { label: avail.name, value: avail._id };
                            })
                        : []
                    }
                    isMulti
                  />
                }
              >
                {team?.length ? (
                  <ProfileImageGroup
                    maxToShow={-1}
                    members={availMembers.filter((member) =>
                      team.includes(member._id),
                    )}
                    xsmall
                  />
                ) : (
                  "N/A"
                )}
              </Editable>
            </Value>
          </Stat>

          <Stat>
            <Label>Description</Label>
            <Value>
              <Editable
                id={`${id}-description`}
                onSave={
                  handleEdit
                    ? (value) => {
                        handleEdit({ description: value });
                      }
                    : undefined
                }
                defaultValue={description}
              >
                <Value>{description || "N/A"}</Value>
              </Editable>
            </Value>
          </Stat>
        </StatsCol>
      </StatsMain>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.coolGray100};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Header = styled.h4`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Label = styled.p`
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray400};
  width: 75px;

  ${respondTo("xlarge")} {
    width: 146px;
  }
`;

const StatsMain = styled.div`
  display: flex;
  gap: 40px;
`;

const StatsCol = styled.div`
  flex: 1;
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Value = styled.div`
  text-align: right;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const ValueEm = styled.span`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.indigo600};
`;

const ExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover,
  &:focus-visible {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

const Break = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.coolGray100};
  margin: 12px 0;
  border: 0;
`;

export default DealDetails;

const MailIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.60156 4.70715L7.99916 7.90555L14.3968 4.70715C14.3731 4.29951 14.1944 3.91634 13.8974 3.63613C13.6004 3.35592 13.2075 3.19988 12.7992 3.19995H3.19916C2.79083 3.19988 2.39792 3.35592 2.1009 3.63613C1.80389 3.91634 1.62525 4.29951 1.60156 4.70715V4.70715Z"
      fill="#5048E5"
    />
    <path
      d="M14.4016 6.49438L8.00156 9.69438L1.60156 6.49438V11.2C1.60156 11.6243 1.77013 12.0313 2.07019 12.3314C2.37025 12.6314 2.77722 12.8 3.20156 12.8H12.8016C13.2259 12.8 13.6329 12.6314 13.9329 12.3314C14.233 12.0313 14.4016 11.6243 14.4016 11.2V6.49438Z"
      fill="#5048E5"
    />
  </svg>
);

const LinkIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66797 4.00008H4.0013C3.64768 4.00008 3.30854 4.14056 3.05849 4.39061C2.80844 4.64065 2.66797 4.97979 2.66797 5.33341V12.0001C2.66797 12.3537 2.80844 12.6928 3.05849 12.9429C3.30854 13.1929 3.64768 13.3334 4.0013 13.3334H10.668C11.0216 13.3334 11.3607 13.1929 11.6108 12.9429C11.8608 12.6928 12.0013 12.3537 12.0013 12.0001V9.33341M9.33464 2.66675H13.3346M13.3346 2.66675V6.66675M13.3346 2.66675L6.66797 9.33341"
      stroke="#5048E5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
