import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";

import {
  getProjectDeliverablesFromApi,
  getAllTasksFromApi,
  createPhaseOnApi,
  updatePhaseOnApi,
  deletePhaseOnApi,
} from "../../../utils/api";
import { respondTo } from "../../../styles/styleHelpers";
import { getStandardizedDate } from "../../../utils/helpers";

import { useNotifications } from "../../../context/notificationsContext";
import { useAuth } from "../../../context/authContext";

import Dots from "../../../components/icons/DotsVertical";
import Trash from "../../../components/icons/Trash";

import ToggleButton from "../../../components/newButtons/ToggleButton";
import Button from "../../../components/newButtons/Button";
import ProfileImage from "../../../components/ProfileImage";
import ValueChip from "../../../components/ValueChip";
import Editable from "../../../components/Editable";
import Table from "../../../components/NewTable/";
import { TableCell } from "../../../components/NewTable/components";

const Phases = ({ projectId, phases }) => {
  const { openAlertPopup } = useNotifications();
  const { isImportant } = useAuth();

  const [projectPhases, setProjectPhases] = useState(phases);
  const [formattedPhases, setFormattedPhases] = useState([]);
  const [deliverables, setDeliverables] = useState(null);
  const [tasks, setTasks] = useState(null);

  const [displayNew, setDisplayNew] = useState(false);
  const [newPhaseName, setNewPhaseName] = useState("");

  useEffect(() => {
    if (projectId) {
      getProjectData();
    }
  }, [projectId]);

  useEffect(() => {
    setProjectPhases(phases);
  }, [phases]);

  useEffect(() => {
    // make sure deliverables/tasks have been loaded first
    if (deliverables && tasks) {
      const phasesToDisplay = projectPhases
        .map((phase) => {
          // deliverables associated with this phase
          const phaseDeliverables = deliverables
            ? deliverables
                .filter((deliverable) => deliverable.phase === phase._id)
                .sort((a, b) =>
                  (a.shipBy?.length ? a.shipBy[a.shipBy.length - 1].date : 0) >
                  (b.shipBy?.length ? b.shipBy[b.shipBy.length - 1].date : 0)
                    ? 1
                    : (a.shipBy?.length
                        ? a.shipBy[a.shipBy.length - 1].date
                        : 0) <
                      (b.shipBy?.length
                        ? b.shipBy[b.shipBy.length - 1].date
                        : 0)
                    ? -1
                    : 0,
                )
            : null;

          // tasks associated with this phase
          const phaseTasks = tasks.filter((task) => task.phase === phase._id);

          // tasks for this phase that haven't been completed yet
          const tasksRemaining = phaseTasks.filter(
            (task) => task.status.toLowerCase() !== "completed",
          );

          let startDate = null;
          let endDate = null;

          // start date is the earliest task start date
          // end date is the latest task end date
          phaseTasks.forEach((task) => {
            const taskStart = task.week ? getStandardizedDate(task.week) : null;
            const taskEnd = task.date ? getStandardizedDate(task.date) : null;

            // if this task has a start date AND
            // - there is no earliest start date yet, OR this one is earlier
            if (taskStart && (!startDate || taskStart < startDate)) {
              startDate = taskStart;
            }
            // if this task has an end date AND
            // - there is no latest end date yet, OR this one is later
            if (taskEnd && (!endDate || taskEnd > endDate)) {
              endDate = taskEnd;
            }
          });

          return {
            ...phase,
            deliverables: phaseDeliverables,
            tasks: phaseTasks,
            tasksRemaining: tasksRemaining.length,
            startDate,
            endDate,
          };
        })
        .sort(sortByEarliestDeliverable);

      setFormattedPhases(phasesToDisplay);

      setDisplayNew(phasesToDisplay.length ? false : true);
    }
  }, [projectPhases, tasks, deliverables]);

  const getProjectData = async () => {
    try {
      // get all the deliverables and tasks for this project
      const [deliverablesFromApi, tasksFromApi] = await Promise.all([
        getProjectDeliverablesFromApi(projectId),
        getAllTasksFromApi({ projectId }),
      ]);

      setDeliverables(deliverablesFromApi || []);
      setTasks(tasksFromApi || []);
    } catch (error) {
      console.error("error getting project deliverables", error);

      openAlertPopup(
        "Error",
        "Sorry, we weren't able to get the tasks/deliverables for the phases.",
      );
    }
  };

  const handleCreate = async () => {
    try {
      if (newPhaseName.trim()) {
        const newPhase = { name: newPhaseName.trim() };

        const updatedProject = await createPhaseOnApi(projectId, newPhase);

        // update the phases in state
        // ? add it to state after the API call (not before, since it needs an _id)
        setProjectPhases(updatedProject.phases);

        // reset the phase name input
        setNewPhaseName("");

        // hide the Create New input
        setDisplayNew(false);

        // scroll to the bottom of the container, so we see the newest phase
        const containerEl = document.getElementById("phase-container");
        containerEl.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    } catch (error) {
      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to create that new phase. ${errMessage}`,
      );
    }
  };

  const handleEdit = async (phaseId, toUpdate) => {
    const originalPhases = projectPhases;

    try {
      // optimistically update state
      setProjectPhases(
        projectPhases.map((phase) =>
          // apply updates only to the phase that matches the ID
          phase._id === phaseId
            ? {
                ...phase,
                ...toUpdate,
              }
            : phase,
        ),
      );

      await updatePhaseOnApi(projectId, phaseId, toUpdate);
    } catch (error) {
      // revert to original phases before the edit
      setProjectPhases(originalPhases);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to update that phase. ${errMessage}`,
      );
    }
  };

  const handleDelete = async (phaseId) => {
    const originalPhases = projectPhases;

    try {
      const phaseToDelete = phases.find((phase) => phase._id === phaseId);

      const confirmation = window.confirm(
        `Are you sure you want to delete the phase ${phaseToDelete.name}? Any tasks or deliverables associated with this phase will still exist, but will no longer be associated with a phase.`,
      );

      if (confirmation) {
        // optimistically update state
        setProjectPhases(
          projectPhases.filter((phase) => phase._id !== phaseId),
        );

        await deletePhaseOnApi(projectId, phaseId);
      }
    } catch (error) {
      setProjectPhases(originalPhases);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Error",
        `Sorry, we weren't able to delete that phase. ${errMessage}`,
      );
    }
  };

  // sort array of phases by the due dates of their deliverables
  // the phases with the earliest due deliverable would come first
  const sortByEarliestDeliverable = (a, b) => {
    let aDate = null;
    let bDate = null;

    // find the deliverable with the earliest due date for A and B

    if (a.deliverables) {
      a.deliverables.forEach((deliverable) => {
        if (
          !aDate ||
          new Date(
            deliverable.shipBy?.length
              ? deliverable.shipBy[deliverable.shipBy.length - 1].date
              : 0,
          ) < aDate
        ) {
          aDate = new Date(
            deliverable.shipBy?.length
              ? deliverable.shipBy[deliverable.shipBy.length - 1].date
              : 0,
          );
        }
      });
    }
    if (b.deliverables) {
      b.deliverables.forEach((deliverable) => {
        if (
          !bDate ||
          new Date(
            deliverable.shipBy?.length
              ? deliverable.shipBy[deliverable.shipBy.length - 1].date
              : 0,
          ) < bDate
        ) {
          bDate = new Date(
            deliverable.shipBy?.length
              ? deliverable.shipBy[deliverable.shipBy.length - 1].date
              : 0,
          );
        }
      });
    }

    // if A and B both have deliverables
    return aDate && bDate
      ? // sort them based on which one is has the earliest due date
        aDate > bDate
        ? 1
        : aDate < bDate
        ? -1
        : 0
      : // if A has deliverables but B doesn't
      aDate
      ? -1
      : // if B has deliverables but A doesn't
      bDate
      ? 1
      : // if neither have deliverables
        0;
  };

  return (
    <Container>
      {formattedPhases.length ? (
        <div id="phase-container">
          {formattedPhases.map((phase) => (
            <Phase
              key={phase._id}
              phase={phase}
              projectId={projectId}
              handleEdit={isImportant ? handleEdit : undefined}
              handleDelete={isImportant ? handleDelete : undefined}
            />
          ))}
        </div>
      ) : null}

      {isImportant ? (
        <StickyCol>
          <StickyColInner>
            {displayNew ? (
              <CreateForm
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCreate();
                }}
              >
                <Input
                  placeholder="New Phase"
                  value={newPhaseName}
                  onChange={(e) => setNewPhaseName(e.target.value)}
                  required
                />
                <Button type="submit" disabled={!newPhaseName.trim()}>
                  Save
                </Button>
              </CreateForm>
            ) : (
              <Button onClick={() => setDisplayNew(true)}>Create New</Button>
            )}
          </StickyColInner>
        </StickyCol>
      ) : null}
    </Container>
  );
};

const Phase = ({ phase, projectId, handleEdit, handleDelete }) => {
  return (
    <PhaseContainer key={phase._id}>
      <PhaseTop>
        <PhaseHeader>
          <Editable
            id={`${phase._id}-name`}
            onSave={
              handleEdit
                ? (newValue) => {
                    if (newValue.trim()) {
                      handleEdit(phase._id, { name: newValue.trim() });
                    }
                  }
                : undefined
            }
          >
            <PhaseTitle>{phase.name || "Untitled Phase"}</PhaseTitle>
          </Editable>

          {phase.startDate && phase.endDate ? (
            <PhaseDates>
              {format(new Date(phase.startDate), "MMMM d, y")} &ndash;{" "}
              {format(new Date(phase.endDate), "MMMM d, y")}
            </PhaseDates>
          ) : null}
        </PhaseHeader>

        <PhaseTopRight>
          <ValueChip color="indigo">
            {phase.tasksRemaining ? phase.tasksRemaining : 0}
          </ValueChip>

          <PhaseTasks>Tasks Remaining</PhaseTasks>

          {handleDelete ? (
            <PhaseToggle
              id={`${phase._id}-menu`}
              menuPosition="right"
              actions={[
                {
                  text: "Delete",
                  onClick: () => handleDelete(phase._id),
                  hasDanger: true,
                  icon: <Trash width="13" height="15" color="rose500" />,
                },
              ]}
              toggle={
                <MenuButton
                  title="Phase options"
                  aria-label="Toggle phase options"
                >
                  <Dots />
                </MenuButton>
              }
            />
          ) : null}
        </PhaseTopRight>
      </PhaseTop>

      <div>
        {phase.deliverables ? (
          phase.deliverables?.length ? (
            <StyledTable
              noPadding
              noShadow
              hideFooter={phase.deliverables.length <= 10}
              perPageDefault={10}
              headers={[
                {
                  name: "Deliverable",
                  accessor: "name",
                },
                {
                  name: "Owner",
                  accessor: "owner.name",
                },
                {
                  name: "Due Date",
                  accessor: "dueDate",
                },
                {
                  name: "Status",
                  accessor: "status",
                },
              ]}
              entries={
                phase.deliverables?.length
                  ? phase.deliverables.map((deliverable) => {
                      return {
                        ...deliverable,
                        dueDate: deliverable.shipBy?.length
                          ? new Date(
                              deliverable.shipBy[
                                deliverable.shipBy.length - 1
                              ].date,
                            ).toISOString()
                          : new Date(0).toISOString(),
                        row: (
                          <>
                            <TableCell>
                              <TableLink
                                to={`/projects/${projectId}/deliverables?search=${deliverable.name}`}
                              >
                                {deliverable.name}
                              </TableLink>
                            </TableCell>
                            <TableCell>
                              <ProfileImage
                                handle={deliverable.owner.handle}
                                name={deliverable.owner.name}
                                xsmall
                              />
                            </TableCell>
                            <TableCell>
                              {deliverable.shipBy?.length
                                ? format(
                                    getStandardizedDate(
                                      deliverable.shipBy[
                                        deliverable.shipBy.length - 1
                                      ].date,
                                    ),
                                    "MM/dd/yyyy",
                                  )
                                : "N/A"}
                            </TableCell>
                            <TableCell full>{deliverable.status}</TableCell>
                          </>
                        ),
                      };
                    })
                  : []
              }
            />
          ) : (
            "No deliverables yet for this phase"
          )
        ) : null}
      </div>
    </PhaseContainer>
  );
};

const Container = styled.div`
  display: flex;
  gap: 100px;
`;

const StickyCol = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-grow: 1;

  &:first-child {
    justify-content: flex-start;
  }
`;

const PhaseContainer = styled.div`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  margin-bottom: 80px;
  min-width: 650px;
`;

const PhaseTop = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const PhaseHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PhaseTitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PhaseDates = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.darkGray};
`;

const PhaseTopRight = styled.div`
  display: flex;
  align-items: center;

  margin-left: auto;

  > * {
    margin-left: 7px;
  }
`;

const MenuButton = styled.button`
  padding: 0 8px;
  line-height: 0;

  svg {
    path {
      stroke: ${(props) => props.theme.colors.darkerGray};
      transition: stroke 200ms;
    }
  }

  &:hover,
  &:focus-visible {
    svg {
      path {
        stroke: ${(props) => props.theme.colors.indigo};
      }
    }
  }
`;

const PhaseTasks = styled.div`
  font-weight: 500;
`;

const PhaseToggle = styled(ToggleButton)`
  display: flex;
`;

const StyledTable = styled(Table)`
  table {
    margin-bottom: 0;
  }
  thead {
    background-color: transparent;
  }
  tbody {
    tr {
      font-size: 14px;
    }
    td {
      font-weight: 400;
      color: ${(props) => props.theme.colors.darkerGray};
    }
  }
`;

const TableLink = styled(Link)`
  color: ${(props) => props.theme.colors.indigo};
  font-weight: 500;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
`;

const StickyColInner = styled.div`
  position: sticky;
  top: 100px;
  right: 0;
`;

const CreateForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;

  > * {
    margin-bottom: 10px;
  }

  ${respondTo("xlarge")} {
    flex-direction: row;
    align-items: stretch;

    > * {
      margin-bottom: 0;
    }
  }
`;

const Input = styled.input`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 20px;

  min-width: 300px;

  background-color: transparent;
  color: ${(props) => props.theme.colors.darkerGray};

  padding: 0;
  padding-bottom: 5px;
  margin-right: 15px;

  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumGray};

  transition: border-color 200ms;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.indigo};
  }
`;

Phases.propTypes = {
  projectId: PropTypes.string,
  phases: PropTypes.array,
};
Phases.defaultProps = {
  phases: [],
};

export default Phases;
