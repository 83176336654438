import React from "react";
import styled from "styled-components";

const ProjectProgress = ({ current, total }) => {
  return (
    <Container>
      <ProgressBarContainer>
        <ProgressBar percentage={current / total} />
      </ProgressBarContainer>
      <ProgressNumber>
        {current && total ? `${Math.round((current / total) * 100)}%` : `0%`}
      </ProgressNumber>
      <Info>
        <p>Completed Items: {current}</p>
        <p>Total Items: {total}</p>
      </Info>
    </Container>
  );
};

const Info = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(50%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  cursor: help;

  &:hover ${Info} {
    opacity: 1;
    transform: translateY(12%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

const ProgressBarContainer = styled.div`
  background: #e5e7eb;
  border-radius: 2px;
  width: 190px;
  height: 8px;
  margin-right: 8px;
  position: relative;
`;

const ProgressBar = styled.div`
  position: absolute;
  height: 8px;
  border-radius: 2px;
  left: 0;
  top: 0;
  background-color: #5048e5;
  width: ${(props) => (props.percentage ? `${props.percentage * 100}%` : 0)};
  transition: 0.3s ease-in-out;
`;

const ProgressNumber = styled.p`
  margin: 0px;
  color: black;
  font-size: 12px;
`;

export default ProjectProgress;
