import React from "react";
import styled from "styled-components";

const NoteIcon = ({ pastEntry }) => {
  return (
    <Svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      pastEntry={pastEntry}
    >
      <path d="M31.6979 2.79177L29.2806 0.306414C29.0763 0.102138 28.804 0 28.5316 0C28.2592 0 27.9869 0.102138 27.8166 0.306414L13.5514 14.776C13.4492 14.8781 13.3811 14.9802 13.313 15.1164L11.7129 19.1679C11.5767 19.5424 11.6448 19.985 11.9512 20.2574C12.1555 20.4616 12.3938 20.5638 12.6662 20.5638C12.8023 20.5638 12.9045 20.5297 13.0407 20.4957L17.0581 18.8955C17.1943 18.8274 17.2964 18.7593 17.3986 18.6572L31.6638 4.18766C32.1064 3.81315 32.1064 3.16628 31.6979 2.79177Z" />
      <path d="M25.194 14.1624C24.2407 14.1624 23.4917 14.9114 23.4917 15.8647V27.236H3.4046V5.44659H19.9509C20.9042 5.44659 21.6532 4.69758 21.6532 3.74429C21.6532 2.791 20.9042 2.04199 19.9509 2.04199H1.7023C0.749012 2.04199 0 2.75696 0 3.6762V28.9383C0 29.8916 0.749012 30.6406 1.7023 30.6406H25.1259C26.0792 30.6406 26.8963 29.8576 26.8963 28.9383V15.8647C26.8963 14.9114 26.1473 14.1624 25.194 14.1624Z" />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: ${(props) => (props.pastEntry ? "scale(.5)" : "scale(0.8)")};
  transition: all 0.2s;

  path {
    fill: ${(props) => props.theme.colors.darkSlate};
  }
`;

export default NoteIcon;
