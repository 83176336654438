import axios from "axios";
import { headers } from "../../../utils/api";

export const getMemberEntriesFromApi = async (id, from) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/time/entries?harvestId=${id}&from=${from}`,
      headers,
    };
    const results = await axios(config);
    return results;
  } catch (err) {
    console.error(err);
  }
};
