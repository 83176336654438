import React from "react";

import { useNotifications } from "../../context/notificationsContext";

import PromptView from "./PromptView";

const Prompt = () => {
  const { isPromptOpen, promptData, closePromptPopup } = useNotifications();

  return (
    <PromptView
      title={promptData.header}
      message={promptData.body}
      isOpen={isPromptOpen}
      handleClose={promptData.cancelCallback || closePromptPopup}
      handleCancel={promptData.cancelCallback || closePromptPopup}
      handleConfirm={promptData.confirmCallback}
      cancelText={promptData.cancelText || "Cancel"}
      confirmText={promptData.confirmText || "Confirm"}
    />
  );
};

export default Prompt;
