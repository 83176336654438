import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { respondTo } from "../../../styles/styleHelpers";

const ProjectPopout = ({ children, linkUrl, linkText }) => {
  return (
    <PopoutContainer>
      <ProjectName to={linkUrl}>{linkText}</ProjectName>
      <PopoutMenu>{children}</PopoutMenu>
    </PopoutContainer>
  );
};

const PopoutMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transform: translate(80%, 5px);
  width: 270px;
  padding: 20px;

  background: linear-gradient(
    190.57deg,
    rgba(255, 255, 255, 0.56) 19.25%,
    rgba(248, 248, 255, 0.71) 54.39%,
    rgba(255, 255, 255, 0.59) 90.02%
  );
  backdrop-filter: blur(19px);
  box-shadow: -10px 48.5967px 140px rgba(126, 123, 160, 0.3);
  border-radius: 10px;

  transition: opacity 0.12s, transform 0.12s ease-out;
  opacity: 0;
  pointer-events: none;

  ${respondTo("xlarge")} {
    width: 305px;
    padding: 25px;
  }
`;

const PopoutContainer = styled.div`
  position: relative;

  &:hover {
    ${PopoutMenu} {
      opacity: 1;
      pointer-events: all;
      transform: translate(80%, -18px);
    }
  }
`;

const ProjectName = styled(Link)`
  color: ${(props) => props.theme.colors.oldBlack};
  transition: 0.2s;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.colors.slate};
    text-decoration: underline;
  }
`;

export default ProjectPopout;
