const selectStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    border: "1px solid #dae1f5",
    borderColor: "#dae1f5",
    backgroundColor: isDisabled ? "#fafbf9" : "white",
    borderRadius: "4px",
    cursor: isDisabled ? "not-allowed" : "pointer",
    width: "100%",
    padding: "0px 10px",
    transition: ".2s ease-in-out",
    fontFamily: "Inter",
    fontSize: "14px",
    minHeight: `36px`,
    boxShadow: "none",

    ":hover": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },

    ":focus": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },

    ":active": {
      border: "1px solid #0079FF",
      boxShadow: "none",
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#9ca3af",
  }),

  // Down arrow on the right
  dropdownIndicator: (styles /*, { isDisabled } */) => ({
    ...styles,
    padding: "0",
  }),
  // 'X' icon that appears to the left of the down arrow after you start selecting items
  clearIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  // Vertical line that separates the down arrow from the 'X'
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),

  // Dropdown menu container
  menu: (styles) => ({
    ...styles,
    borderRadius: "3px",
    zIndex: "2",
    boxShadow: "0px 15px 25px 5px rgba(185,188,192,0.3)",
    marginTop: "-1px",

    left: 0,
    right: 0,
  }),

  // List inside the dropdown container
  menuList: (styles) => ({
    ...styles,
    borderRadius: "3px",

    padding: "0",
  }),

  // Option in the dropdown menu
  option: (styles) => ({
    ...styles,
    cursor: "pointer",
    padding: "12px 20px",
  }),

  // Option in the dropdown menu
  noOptionsMessage: (styles) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: "14px",
  }),
};

export default selectStyles;
