import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../../../context/authContext";

import { editProjectOnApi, getMembersFromApi } from "../../manage/utils/api";

import Checkbox from "../../tasks/components/Checkbox";

const StageApprovals = ({
  approverTypes,
  project,
  setProject,
  changeStage,
  stageName,
  user,
  setIsApproved,
}) => {
  const { isAdmin } = useAuth();

  const [members, setMembers] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [approvedBy, setApprovedBy] = useState([]);

  // on initial load, and whenever the stage changes
  useEffect(() => {
    // reset the members
    setMembers(null);

    // get all the members who are potential approvers
    getMembers();
  }, [stageName]); //eslint-disable-line

  // when an approval is updated
  useEffect(() => {
    // check that we have approvers
    if (approvers.length) {
      // set whether the stage has been approved
      setIsApproved(approvers.length === approvedBy.length);
    }
  }, [approvers, approvedBy]); //eslint-disable-line

  useEffect(() => {
    // once we've retrieved the members from the API and the project has team members assigned to it
    if (members && project?.teamMembers) {
      // get all the potential approvers for this project
      const projectApprovers = [];
      // get all those who have already approved this stage
      const projectApprovedBy = [];

      // grab all the team members IDs
      const projectTeam = project.teamMembers.map(
        (teamMember) => teamMember.teamMember,
      );

      // and grab the client's partnership manager and producer
      const { partner, producer } = project.client;
      if (partner && !projectTeam.includes(partner)) {
        projectTeam.push(partner);
      }
      if (producer && !projectTeam.includes(producer)) {
        projectTeam.push(producer);
      }

      // loop over the team members assigned to the project
      projectTeam.forEach((teamMember) => {
        // check if this team member is one of the members who has the authority to give approvals
        const correspondingMember = members.find(
          (member) => member._id === teamMember,
        );

        // if this team member can give approvals
        if (correspondingMember) {
          // if we haven't already added them to the approvers array
          if (!projectApprovers.includes(teamMember)) {
            projectApprovers.push(teamMember);
          }

          // if the member has given their approval already
          if (
            project?.stages?.[stageName]?.approvedBy &&
            project.stages[stageName].approvedBy.includes(teamMember) &&
            !projectApprovedBy.includes(teamMember)
          ) {
            projectApprovedBy.push(teamMember);
          }
        }
      });

      setApprovers(projectApprovers);
      setApprovedBy(projectApprovedBy);
    }
  }, [project, members]); //eslint-disable-line

  const getMembers = async () => {
    // get everyone from the API
    const membersFromApi = await getMembersFromApi();

    // if the approver types includes partner or producer
    const needsPartner = approverTypes.indexOf("Partnership Manager") > -1;
    const needsProducer = approverTypes.indexOf("Production Manager") > -1;

    // partner and producer for the client
    const { partner, producer } = project.client;

    // filter out only the ones who are the approvers we need
    const approverMembers = membersFromApi.filter(
      (member) =>
        approverTypes.indexOf(member.role.tier) > -1 ||
        approverTypes.indexOf(member.role) > -1 ||
        (needsPartner && member._id === partner) ||
        (needsProducer && member._id === producer),
    );

    setMembers(approverMembers);
  };

  const updateApprover = (id, hasApproved) => {
    // if they approved, add them to the array.
    // if they unapproved, remove them from the array
    const updatedApprovedBy = hasApproved
      ? [...approvedBy, id]
      : approvedBy.filter((personId) => personId !== id);

    // stage is approved if all the approvers have given their approval
    const isApproved = updatedApprovedBy.length === approvers.length;

    // update the project in state accordingly
    const updatedProject = { ...project };

    updatedProject.stages[stageName].approvedBy = updatedApprovedBy;
    updatedProject.stages[stageName].completed = isApproved;

    setApprovedBy(updatedApprovedBy);
    setProject(updatedProject);

    // if stage is now approved, move on to next stage
    changeStage(isApproved);

    // data to send to API
    const data = {
      projectId: project._id,
      database: {},
    };

    // database query to set approvedBy for this stage
    const databaseQuery = `stages.${stageName}.approvedBy`;
    data.database = {
      [databaseQuery]: updatedApprovedBy,
    };

    // send request to API
    editProjectOnApi(data);
  };

  return (
    <div>
      <h2>Approvers</h2>
      {members &&
        approvers.map((approver, index) => {
          const member = members.find((item) => item._id === approver);

          return (
            <div
              key={index}
              style={{ marginRight: "20px", display: "inline-block" }}
            >
              <Checkbox
                label={member ? member.name : null}
                disabled={approver !== user._id && !isAdmin}
                checked={approvedBy.includes(approver)}
                onChange={(e) => {
                  updateApprover(approver, e.target.checked);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

StageApprovals.propTypes = {
  approverTypes: PropTypes.array,
  setProject: PropTypes.func,
  changeStage: PropTypes.func,
  stageName: PropTypes.string,
  project: PropTypes.object,
  user: PropTypes.object,
  setIsApproved: PropTypes.func,
};
StageApprovals.defaultProps = {
  approverTypes: [],
  setProject: null,
  changeStage: null,
  stageName: "",
  project: {},
  user: {},
  setIsApproved: null,
};

export default StageApprovals;
