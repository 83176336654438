import React from "react";
import styled from "styled-components";

import Circle from "../../reports/components/productionReport/Circle";
import Button from "../../../components/buttons/Button";

const FinishSection = ({
  completions,
  handleFinishClick,
  hasFinished,
  day,
}) => {
  return (
    <>
      <CompletionList>
        {completions
          ? completions.map((completion, completionIndex) => (
              <CompletionItem key={completionIndex}>
                <Circle
                  status={completion.completed ? "success" : "fail"}
                  small
                />{" "}
                {completion.text}
                {completion.unfinished?.length ? (
                  <CompletionPopup>
                    {completion.unfinished.map(
                      (unfinished, unfinishedIndex) => (
                        <UnfinishedItem key={unfinishedIndex}>
                          {unfinished.title
                            ? unfinished.title
                            : unfinished.name}
                        </UnfinishedItem>
                      ),
                    )}
                  </CompletionPopup>
                ) : null}
              </CompletionItem>
            ))
          : null}
      </CompletionList>
      <Finish
        /*disabled={overdue > 0 || (hoursBehind > 0 && day >= 5) || hasFinished}*/
        onClick={handleFinishClick}
      >
        {hasFinished
          ? `${day >= 5 ? "Week" : "Day"} Finished`
          : `Finish My ${day >= 5 ? "Week" : "Day"}`}
      </Finish>
    </>
  );
};

const CompletionList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CompletionPopup = styled.div`
  position: absolute;
  top: 0;
  left: -15px;
  transform: translate(-100%, -10px);
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.mediumGray};
  border-radius: 5px;
  z-index: 2;
  padding: 15px 15px;
  box-shadow: 0px 2px 14px rgba(233, 233, 233, 0.5);
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.2s;
`;

const CompletionItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 0;
  position: relative;

  & > :first-child {
    margin-right: 8px;
  }

  &:hover {
    ${CompletionPopup} {
      opacity: 1;
    }
  }
`;

const UnfinishedItem = styled.p`
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Finish = styled(Button)`
  margin-left: auto;
  margin-right: 0px;
  display: block;
`;

export default FinishSection;
