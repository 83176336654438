import React, { useState } from "react";
import styled, { css } from "styled-components";
import { buttonReset } from "../../../styles/styleHelpers";

import SimpleLink from "../../../components/links/SimpleLink";
import Dots from "../../../components/icons/Dots";

const ActionMenu = ({ disabled, actions, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper>
      <Button
        {...props}
        type="button"
        disabled={disabled}
        onClick={() => (!disabled ? setIsOpen(!isOpen) : null)}
      >
        <Dots direction="horizontal" />
      </Button>
      <Menu isOpen={isOpen}>
        {actions && actions.length > 0
          ? actions.map((action, index) => (
              <ActionMenuItem
                type="button"
                onClick={() => {
                  setIsOpen(false);
                  action.action();
                }}
                small
                nounderline
                danger={action.danger ? true : false}
                key={index}
              >
                {action.text}
              </ActionMenuItem>
            ))
          : null}
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const Button = styled.button`
  ${buttonReset()}

  cursor: pointer;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: default;

    svg {
      opacity: 0.3;
      pointer-events: none;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid #e5e7eb;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.05);
  z-index: 5;
  padding: 20px 20px;
  transition: transform 0.2s, opacity 0.2s;

  ${(props) =>
    props.isOpen
      ? css`
          opacity: 1;
          transform: translateY(100%);
        `
      : css`
          opacity: 0;
          transform: translateY(120%);
          pointer-events: none;
        `}
`;

const ActionMenuItem = styled(SimpleLink)`
  ${buttonReset()}

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export default ActionMenu;
