import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";

import ProfileImage from "./ProfileImage";
import DefaultLink from "./links/DefaultLink";
import { Input } from "./Form";

// ! *****************************
// !------------------------------
// ! DEPRECATED
// ! This component is in the process of being phased out in favor of the Chat components (ChatMessage)
// !------------------------------
// ! *****************************

const Comment = ({
  author,
  postedDate,
  message,
  editable,
  editComment,
  deletable,
  deleteComment,
  editedMessage,
  setEditedMessage,
  completed,
  completable,
  completeComment,
  ...rest
}) => {
  const [editingMessage, setEditingMessage] = useState(false);

  return (
    <Container {...rest}>
      <ProfileImage
        small={true}
        handle={author.handle}
        name={author.name}
        showStatus
      />
      <Main>
        <Header>
          <Name>{author.handle}</Name>
          <PostedDate>
            {format(new Date(postedDate), "MMM d - h:mma")}
          </PostedDate>
        </Header>

        {editingMessage ? (
          <MessageInput
            value={editedMessage}
            onChange={(e) => setEditedMessage(e.target.value)}
          />
        ) : (
          <Message
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></Message>
        )}

        {deletable || editable || completable ? (
          <Controls>
            {editingMessage ? (
              <Edit onClick={() => setEditingMessage(false)}>Cancel</Edit>
            ) : completable && !completed ? (
              <Edit onClick={() => completeComment()}>Complete</Edit>
            ) : completable && completed ? (
              <Save
                onClick={() => {
                  completeComment();
                }}
              >
                Uncomplete
              </Save>
            ) : null}

            {editable && !editingMessage ? (
              <Edit
                onClick={() => {
                  setEditedMessage(message);
                  setEditingMessage(true);
                }}
              >
                Edit
              </Edit>
            ) : editable && editingMessage ? (
              <Save
                onClick={() => {
                  setEditingMessage(false);
                  editComment();
                }}
              >
                Save
              </Save>
            ) : null}

            {deletable ? (
              <Delete danger onClick={deleteComment}>
                Delete
              </Delete>
            ) : null}
          </Controls>
        ) : null}
      </Main>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 30px;
  max-width: 300px;
`;

const Main = styled.div`
  margin-left: 17px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const Name = styled.p`
  color: ${(props) => props.theme.colors.dark};
  margin-bottom: 0px;
  font-weight: bold;
  line-height: 1;
  font-size: ${(props) => props.theme.fontSize_xxs};
  line-height: 1;
`;

const PostedDate = styled.p`
  color: #9796b7;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 1;
`;

const Message = styled.p`
  color: #6f6c99;
  margin-bottom: 0px;
  font-size: 12px;

  // * Only applies word break to links (because they are urls)
  a {
    word-break: break-all;
  }

  // * Tags that are highlighted
  .highlighted {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
    margin-right: 3px;
    color: ${(props) => props.theme.colors.blue2};
    background-color: ${(props) => props.theme.colors.lightBlue};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Edit = styled(DefaultLink)`
  margin-left: 10px;
  font-size: 10px;
`;

const Save = styled(DefaultLink)`
  margin-left: 10px;
  font-size: 10px;
`;

const Delete = styled(DefaultLink)`
  margin-left: 10px;
  font-size: 10px;
`;

const MessageInput = styled(Input)`
  width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 12px;
`;

Comment.propTypes = {
  author: PropTypes.object,
  postedDate: PropTypes.string,
  message: PropTypes.string,
};

export default Comment;
