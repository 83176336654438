import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { format } from "date-fns";

import { buttonReset } from "../../../styles/styleHelpers";
import { getStandardizedDate } from "../../../utils/helpers";
import {
  updateTaskRecurringOnApi,
  deleteRecurringOnApi,
} from "../../../utils/api";

import { useNotifications } from "../../../context/notificationsContext";
import useClickOutside from "../../../hooks/useClickOutside";

import RecurringIcon from "../../../components/icons/RecurringIcon";
import Button from "../../../components/newButtons/Button";
import SelectDropdown from "../../../components/newSelectDropdown";
import {
  Label,
  Field,
  Input,
  Checkbox as CheckboxForm,
} from "../../../components/newForm";

const availFrequencies = [
  {
    label: "Weekly",
    value: "Week",
  },
  {
    label: "Monthly",
    value: "Month",
  },
  {
    label: "Yearly",
    value: "Year",
  },
];

const weeks = [
  {
    label: "Sunday",
    value: "Sunday",
  },
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
];

const weekIterations = [
  {
    label: "The first",
    value: "The first",
  },
  {
    label: "The second",
    value: "The second",
  },
  {
    label: "The third",
    value: "The third",
  },
  {
    label: "The fourth",
    value: "The fourth",
  },
];

const months = [
  {
    label: "January",
    value: "January",
  },
  {
    label: "February",
    value: "February",
  },
  {
    label: "March",
    value: "March",
  },
  {
    label: "April",
    value: "April",
  },
  {
    label: "May",
    value: "May",
  },
  {
    label: "June",
    value: "June",
  },
  {
    label: "July",
    value: "July",
  },
  {
    label: "August",
    value: "August",
  },
  {
    label: "September",
    value: "September",
  },
  {
    label: "October",
    value: "October",
  },
  {
    label: "November",
    value: "November",
  },
  {
    label: "December",
    value: "December",
  },
];

const RecurringModal = ({ isVisible = true, isActive, task, canOpen }) => {
  const { openPromptPopup, closePromptPopup } = useNotifications();

  const [isOpen, setIsOpen] = useState(false);
  const [frequency, setFrequency] = useState(availFrequencies[0]);

  const [repeatEvery, setRepeatEvery] = useState("1");
  const [repeatOn, setRepeatOn] = useState([]);
  const [specificDay, setSpecificDay] = useState(null);
  const [specificWeek, setSpecificWeek] = useState(null);
  const [specificMonth, setSpecificMonth] = useState(null);
  const [skipWeekend, setSkipWeekend] = useState(false);

  const [endDate, setEndDate] = useState("");
  const [occurrencesChecked, setOccurrencesChecked] = useState(false);
  const [occurrences, setOccurrences] = useState(0);
  const [upfrontChecked, setUpfrontChecked] = useState(false);
  const [upfront, setUpfront] = useState(0);

  const wrapper = useRef(null);

  // close it when click is outside the modal wrapper
  useClickOutside(wrapper, isOpen, () => {
    setIsOpen(false);
  });

  const close = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    openPromptPopup({
      header: "Delete Recurring of Task?",
      body:
        "Tasks will no longer recur. You will have to set up the task recurrance again.",
      confirmCallback: () => {
        deleteRecurringOnApi(task._id);
        setIsOpen(false);
        closePromptPopup();
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        frequency: frequency.value,
        repeatEvery,
        repeatOn,
        endDate: new Date(endDate),
        specificDay: specificDay ? specificDay.value : undefined,
        specificWeek: specificWeek ? specificWeek.value : undefined,
        specificMonth: specificMonth ? specificMonth.value : undefined,
        active: true,
        createUpfront: upfrontChecked ? upfront : undefined,
        skipWeekend,
        limit: occurrencesChecked ? occurrences : undefined,
      };

      await updateTaskRecurringOnApi(task._id, data);
      setIsOpen(false);
    } catch (err) {
      console.error("Submit failed");
    }
  };

  // When the frequency changes, resets the state that needs to be
  // cleaned up between frequency types
  useEffect(() => {
    setRepeatEvery("1");
    setSpecificDay(null);
    setSpecificWeek(null);
    setSkipWeekend(false);

    if (frequency.value === "Month") {
      setRepeatOn(["1"]);
    } else if (frequency.value === "Year") {
      setRepeatOn(["1"]);
    } else {
      setRepeatOn([]);
    }

    if (frequency.value === "Year") {
      setSpecificMonth(months[0]);
    } else {
      setSpecificMonth(null);
    }
  }, [frequency]);

  useEffect(() => {
    if (task.recurring?.active) {
      setFrequency(
        task.recurring.frequency
          ? availFrequencies.find(
              (freq) => freq.value === task.recurring.frequency,
            )
          : availFrequencies[0],
      );
      setRepeatEvery(task.recurring.repeatEvery || "1");
      setSpecificDay(
        task.recurring.specificDay
          ? weeks.find((week) => week.value === task.recurring.specificDay)
          : null,
      );
      setSpecificWeek(
        task.recurring.specificWeek
          ? weekIterations.find(
              (week) => week.value === task.recurring.specificWeek,
            )
          : null,
      );
      setSpecificMonth(
        task.recurring.specificMonth
          ? months.find((month) => month.value === task.recurring.specificMonth)
          : null,
      );
      setSkipWeekend(task.recurring.skipWeekend || false);
      setRepeatOn(task.recurring.repeatOn || []);

      setEndDate(
        task.recurring.endDate
          ? format(getStandardizedDate(task.recurring.endDate), "yyyy-MM-dd")
          : "",
      );

      if (task.recurring.createUpfront > 0) {
        setUpfrontChecked(true);
        setUpfront(task.recurring.createUpfront);
      }

      if (task.recurring.limit > 0) {
        setOccurrencesChecked(true);
        setOccurrences(task.recurring.limit);
      }
    }
  }, [task]);

  // On the weekly view, handles the toggle of adding week days
  const addWeekDay = (newDay) => {
    let tempRepeatOn = [...repeatOn];
    // If it's already on the list, remove it
    if (repeatOn.includes(newDay)) {
      tempRepeatOn = tempRepeatOn.filter((day) => day !== newDay);
    } else {
      // Otherwise add it to the list
      tempRepeatOn.push(newDay);
    }

    setRepeatOn(tempRepeatOn);
  };

  // On the monthly view, handles switching radio buttons
  const changeMonthRadio = async (which) => {
    if (which == "day") {
      setRepeatOn(["1"]);
      setSpecificDay(null);
      setSpecificWeek(null);
    } else if (which === "specific") {
      await setRepeatOn([]);
      setSpecificDay(weeks[0]);
      setSpecificWeek(weekIterations[0]);
      setSkipWeekend(false);
    }
  };

  // On the yearly view, handles switching radio buttons
  const changeYearRadio = async (which) => {
    if (which == "day") {
      setRepeatOn(["1"]);
      setSpecificDay(null);
      setSpecificWeek(null);
      setSpecificMonth(months[0]);
    } else if (which === "week") {
      await setRepeatOn([]);
      setSpecificDay(weeks[0]);
      setSpecificWeek(weekIterations[0]);
      setSpecificMonth(months[0]);
    }
  };

  return isVisible ? (
    <div ref={wrapper}>
      <RecurringButton onClick={canOpen ? () => setIsOpen(!isOpen) : undefined}>
        <RecurringIcon isActive={isActive} />
      </RecurringButton>

      <Modal isOpen={isOpen}>
        <TopSection>
          <Header>Recurrence</Header>
        </TopSection>
        <MainSection>
          {frequency.value === "Week" ? (
            <>
              <FormRow>
                <Field>
                  <Label>Frequency</Label>
                  <ShortDropdown
                    value={frequency}
                    onChange={(e) => setFrequency(e)}
                    options={availFrequencies}
                  />
                </Field>
                <Field>
                  <Label>Repeat Every</Label>
                  <Row>
                    <MediumInput
                      placeholder="0"
                      type="Number"
                      value={repeatEvery}
                      onChange={(e) => setRepeatEvery(e.target.value)}
                    />
                    <SecondaryLabel>Week on:</SecondaryLabel>
                  </Row>
                </Field>
              </FormRow>
              <Weekdays>
                <Checkbox
                  label="Sunday"
                  checked={repeatOn.includes("Sunday")}
                  onChange={() => addWeekDay("Sunday")}
                />
                <Checkbox
                  label="Thursday"
                  checked={repeatOn.includes("Thursday")}
                  onChange={() => addWeekDay("Thursday")}
                />
                <Checkbox
                  label="Monday"
                  checked={repeatOn.includes("Monday")}
                  onChange={() => addWeekDay("Monday")}
                />
                <Checkbox
                  label="Friday"
                  checked={repeatOn.includes("Friday")}
                  onChange={() => addWeekDay("Friday")}
                />
                <Checkbox
                  label="Tuesday"
                  checked={repeatOn.includes("Tuesday")}
                  onChange={() => addWeekDay("Tuesday")}
                />
                <Checkbox
                  label="Saturday"
                  checked={repeatOn.includes("Saturday")}
                  onChange={() => addWeekDay("Saturday")}
                />
                <Checkbox
                  label="Wednesday"
                  checked={repeatOn.includes("Wednesday")}
                  onChange={() => addWeekDay("Wednesday")}
                />
              </Weekdays>
            </>
          ) : frequency.value === "Month" ? (
            <>
              <FormRow>
                <Field>
                  <Label>Frequency</Label>
                  <ShortDropdown
                    value={frequency}
                    onChange={(e) => setFrequency(e)}
                    options={availFrequencies}
                  />
                </Field>
                <Field>
                  <Label>Repeat Every</Label>
                  <Row>
                    <MediumInput
                      placeholder="0"
                      type="Number"
                      value={repeatEvery}
                      onChange={(e) => setRepeatEvery(e.target.value)}
                    />
                    <SecondaryLabel>Month by:</SecondaryLabel>
                  </Row>
                </Field>
              </FormRow>
              <Options>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="monthOption"
                    value="day"
                    checked={repeatOn.length > 0 ? true : false}
                    onChange={() => changeMonthRadio("day")}
                  />
                  Day of the month
                  <MonthDayInput
                    type="Number"
                    value={repeatOn[0] || ""}
                    onChange={(e) => setRepeatOn([e.target.value])}
                  />
                </RadioLabel>
                <Checkbox
                  label={`Move to next working day if ${repeatOn[0] ||
                    ""} is on a weekend`}
                  checked={skipWeekend}
                  onChange={(e) => setSkipWeekend(e.target.checked)}
                />
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="monthOption"
                    value="specific"
                    checked={specificWeek || specificDay ? true : false}
                    onChange={() => changeMonthRadio("specific")}
                  />
                  Specific day of the month
                </RadioLabel>
                <Row>
                  <OptionsDropdown
                    borderless
                    value={specificWeek}
                    onChange={(e) => {
                      setRepeatOn([]);
                      setSpecificWeek(e);
                    }}
                    options={weekIterations}
                  />
                  <OptionsDropdown
                    borderless
                    value={specificDay}
                    onChange={(e) => {
                      setRepeatOn([]);
                      setSpecificDay(e);
                    }}
                    options={weeks}
                  />
                </Row>
              </Options>
            </>
          ) : frequency.value === "Year" ? (
            <>
              <FormRow>
                <Field>
                  <Label>Frequency</Label>
                  <ShortDropdown
                    value={frequency}
                    onChange={(e) => setFrequency(e)}
                    options={availFrequencies}
                  />
                </Field>
                <Field>
                  <RadioLabel style={{ marginBottom: "14px" }}>
                    <RadioInput
                      type="radio"
                      name="yearOption"
                      value="day"
                      onChange={() => changeYearRadio("day")}
                      checked={repeatOn.length > 0 ? true : false}
                    />
                    Day of the month
                  </RadioLabel>
                  <Row>
                    <MonthDayInput
                      style={{ width: "57px" }}
                      type="Number"
                      value={repeatOn.length > 0 ? repeatOn[0] : ""}
                      onChange={(e) => setRepeatOn([e.target.value])}
                    />

                    <OptionsDropdown
                      borderless
                      value={repeatOn.length > 0 ? specificMonth : null}
                      onChange={(e) => setSpecificMonth(e)}
                      options={months}
                    />
                  </Row>
                </Field>
              </FormRow>
              <Options>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="yearOption"
                    value="week"
                    checked={specificWeek || specificDay ? true : false}
                    onChange={() => changeYearRadio("week")}
                  />
                  Day of the week of the month
                </RadioLabel>
                <Row>
                  <OptionsDropdown
                    borderless
                    value={specificWeek}
                    onChange={(e) => setSpecificWeek(e)}
                    options={weekIterations}
                  />
                  <OptionsDropdown
                    borderless
                    value={specificDay}
                    onChange={(e) => setSpecificDay(e)}
                    options={weeks}
                  />
                  <OptionsDropdown
                    borderless
                    value={repeatOn.length < 1 ? specificMonth : null}
                    onChange={(e) => setSpecificMonth(e)}
                    options={months}
                  />
                </Row>
              </Options>
            </>
          ) : null}
          <DateRow>
            <Field>
              <Label>End date</Label>
              <DateInput
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Field>
          </DateRow>
          <FormRow>
            <Col>
              <Row>
                <Checkbox
                  label="Limit to"
                  checked={occurrencesChecked}
                  onChange={(e) => setOccurrencesChecked(e.target.checked)}
                />
              </Row>
              <Row>
                <ShortInput
                  placeholder="0"
                  type="number"
                  value={occurrences}
                  onChange={(e) => setOccurrences(e.target.value)}
                />
                <Label>Occurrences</Label>
              </Row>
            </Col>
            <Col>
              <Row>
                <Checkbox
                  label="Create upfront"
                  checked={upfrontChecked}
                  onChange={(e) => setUpfrontChecked(e.target.checked)}
                />
              </Row>
              <Row>
                <ShortInput
                  placeholder="0"
                  type="number"
                  value={upfront}
                  onChange={(e) => setUpfront(e.target.value)}
                />
                <Label>Tasks</Label>
              </Row>
            </Col>
          </FormRow>

          <ButtonRow>
            <Button
              onClick={handleSubmit}
              disabled={
                frequency.label === "Weekly" && (!repeatOn || !repeatOn.length)
              }
            >
              Save
            </Button>
            <Button hollow onClick={close}>
              Cancel
            </Button>
            <Link onClick={(e) => handleDelete(e)}>Delete</Link>
          </ButtonRow>
        </MainSection>
      </Modal>
    </div>
  ) : null;
};

const RecurringButton = styled.button`
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

const Modal = styled.div`
  width: 475px;
  min-height: 598px;
  position: fixed;
  left: 50%;
  top: 50%;
  transition: opacity 0.2s, transform 0.2s;
  background-color: #fff;
  box-shadow: 0px 2px 14px rgba(233, 233, 233, 1);
  z-index: 300;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isOpen
      ? css`
          opacity: 1;
          pointer-events: all;
          transform: translate(-50%, -50%);
        `
      : css`
          opacity: 0;
          pointer-events: none;
          transform: translate(-50%, -40%);
        `}
`;

const TopSection = styled.div`
  position: relative;
  padding: 20px 46px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray300};
`;

const Header = styled.h4`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const MainSection = styled.div`
  padding: 33px 46px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: auto;
  padding-top: 35px;

  & > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

const Link = styled.button`
  ${buttonReset()}
  font-family: Inter;
  font-size: 14px;
  color: ${(props) => props.theme.colors.indigo600};

  &:hover {
    text-decoration: underline;
  }
`;

const ShortDropdown = styled(SelectDropdown)`
  width: 113px;
`;

const FormRow = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 56px;
  }
`;

const DateRow = styled(FormRow)`
  display: flex;
  margin-bottom: 37px;

  ${Field} {
    margin-bottom: 0;
  }

  ${Input} {
    width: 138px;
  }

  & > *:not(:last-child) {
    margin-right: 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

const DateInput = styled(Input)`
  font-size: 12px;
`;

const Col = styled.div`
  flex: 1;
`;

const Checkbox = styled(CheckboxForm)``;

const ShortInput = styled(Input)`
  width: 65px !important;
  margin-right: 11px;
`;

const MediumInput = styled(Input)`
  width: 91px !important;
  margin-right: 14px;
`;

const SecondaryLabel = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const Weekdays = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 9px;

  & > * {
    width: 113px;
    margin-bottom: 18px;

    &:nth-child(2n + 1) {
      margin-right: 55px;
    }
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;

  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const RadioInput = styled(Input)`
  width: 20px !important;
  height: 20px;
  margin-right: 16px;
`;

const MonthDayInput = styled(Input)``;

const RadioLabel = styled(Label)`
  display: flex;
  align-items: center;
  font-weight: normal;

  & > ${MonthDayInput} {
    margin-left: 23px;
    width: 57px;
  }
`;

const OptionsDropdown = styled(ShortDropdown)``;

export default RecurringModal;
