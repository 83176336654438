import React, { useState } from "react";

import { postExpenseToProject } from "../../../utils/api";
import { useAuth } from "../../../context/authContext";
import Expenses from "./Expenses";

const ProjectExpenses = ({
  projectId,
  expenseBudget,
  expenses,
  activeMembers,
}) => {
  const { user, isImportant } = useAuth();
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("");

  const postExpense = async () => {
    try {
      if (message !== "") {
        const data = {
          userId: user._id,
          amount,
          message,
        };

        await postExpenseToProject(projectId, data);

        setAmount("");
        setMessage("");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Expenses
      expenseBudget={expenseBudget}
      expenses={expenses}
      activeMembers={activeMembers}
      message={message}
      setMessage={setMessage}
      amount={amount}
      setAmount={setAmount}
      postExpense={isImportant ? postExpense : undefined}
    />
  );
};

export default ProjectExpenses;
