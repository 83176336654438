import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useActiveTimer } from "../context/activeTimerContext";

// This checks if we are on the correct view for the active timer
// If we aren't in the project, it shows a different state in sidebar
const ProjectViewChecker = () => {
  const {
    currentProjectId,
    isTimerRunning,
    setIsOnProjectDashboard,
  } = useActiveTimer();

  let location = useLocation();
  location = location.pathname;

  useEffect(() => {
    if (
      currentProjectId &&
      isTimerRunning &&
      !location.includes(`projects/${currentProjectId}`)
    ) {
      setIsOnProjectDashboard(false);
    } else {
      setIsOnProjectDashboard(true);
    }
  }, [currentProjectId, isTimerRunning, location]); //eslint-disable-line

  return null;
};

export default ProjectViewChecker;
