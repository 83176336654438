import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  format,
  startOfWeek,
  endOfWeek,
  addWeeks,
  subWeeks,
  addMonths,
  endOfDay,
} from "date-fns";

import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";
import { useActiveTimer } from "../../context/activeTimerContext";
import { useSockets } from "../../context/socketsContext";

import { getWeek, getStandardizedDate } from "../../utils/helpers";

import { stopTimerOnApi, editTimerOnApi, deleteTimerOnApi } from "./utils/api";
import {
  getProjectsFromApi,
  getMembersFromApi,
  getTeamsFromApi,
} from "../../utils/api";

import CardContainer from "./components/CardContainer";
import WeekSettings from "./components/WeekSettings";
import SimpleLink from "../../components/links/SimpleLink";
import SelectDropdown from "../../components/SelectDropdown";

const Dashboard = () => {
  const { user, authenticating, isManagerOrAdmin, isImportant } = useAuth();
  const { openAlertPopup } = useNotifications();
  const { startEntriesListeners, stopEntriesListeners } = useSockets();

  const {
    currentProjectId,
    startTimer,
    clearSidebarCounter,
    openStopModal,
  } = useActiveTimer();

  const [entries, setEntries] = useState([]);
  const [teamEntries, setTeamEntries] = useState([]);
  const [allTimeRetrieved, setAllTimeRetrieved] = useState(false);
  const [allTime, setAllTime] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [team, setTeam] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const { setLoading } = useNotifications();

  const startWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  const startWeek = format(startWeekDate, "d");
  const endWeek = format(endWeekDate, "d");

  const thisMonth = format(startWeekDate, "MMMM");
  const thisMonthShort = format(startWeekDate, "MMM");
  const nextMonthShort = format(addMonths(startWeekDate, 1), "MMM");

  const weekDuration =
    // if the end of the week is a smaller number than the beginning of the week, then the week must end with a different month
    parseInt(endWeek) < parseInt(startWeek)
      ? `${thisMonthShort} ${startWeek} - ${nextMonthShort} ${endWeek}`
      : `${thisMonth} ${startWeek} - ${endWeek}`;

  const weekNumber = getWeek(getStandardizedDate(currentDate));

  const currentYear = new Date(currentDate).getFullYear();

  /*
  |--------------------------------------------------------------------------
  | Get projects every time the current date changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (!authenticating && user) {
      if (currentDate) {
        setup();

        startEntriesListeners({ callback: setup });
      }

      return () => {
        stopEntriesListeners();
      };
    }
  }, [currentDate, user, authenticating]);

  /*
  |--------------------------------------------------------------------------
  | Get the team members if the user is a manager or admin
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (user && isImportant && !teams.length) {
      getTeams();
    }
  }, [user, isImportant]);

  /*
  |--------------------------------------------------------------------------
  | Get the All Time values for the projects
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (allTime && !allTimeRetrieved) {
      getAllTimeProjects();
    }
  }, [allTime]);

  /*
  |--------------------------------------------------------------------------
  | Get the members from the selected team
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (selectedTeam) {
      getTeam();
    } else {
      setTeam([]);
    }
  }, [selectedTeam]);

  /*
  |--------------------------------------------------------------------------
  | Get team member projects if a team has been selected
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (team) {
      setLoading(true);
      getTeamProjects();
    }
  }, [team, currentDate]);

  const setup = () => {
    setLoading(true);

    if (!selectedTeam) {
      getProjects();
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get Projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      let results = await getProjectsFromApi(
        user.email,
        format(currentDate, "yyyy-MM-dd"),
      );
      results = results.data.data;

      const tempProjects = results.projects.map((project) => {
        let isActive = false;

        if (currentProjectId === project.harvestId) {
          isActive = true;
        }

        let weekBudget = 0;
        let allTimeBudget = 0;

        project.bookingPlan.forEach((plan) => {
          plan.weeks.forEach((item) => {
            const planWeekNumber = getWeek(getStandardizedDate(item.weekDate));

            const planYear = new Date(
              getStandardizedDate(item.weekDate),
            ).getFullYear();

            if (planWeekNumber === weekNumber && planYear === currentYear) {
              weekBudget += item.hours;
            }

            allTimeBudget += item.hours;
          });
        });

        // Rounds the number if it has more than 2 decimal places
        weekBudget = Math.round((weekBudget + Number.EPSILON) * 100) / 100;
        allTimeBudget =
          Math.round((allTimeBudget + Number.EPSILON) * 100) / 100;

        return {
          weekBudget,
          allTimeBudget,
          project,
          task: results.task,
          recentTask:
            project.tasks && project.tasks[0] ? project.tasks[0] : null,
          isActiveTimer: isActive,
        };
      });

      const filteredProjects = tempProjects.filter((project) => {
        let show = false;

        if (project.project.bookingPlan) {
          project.project.bookingPlan.forEach((plan) => {
            plan.weeks.forEach((item) => {
              const planWeekNumber = getWeek(
                getStandardizedDate(item.weekDate),
              );

              const planYear = new Date(
                getStandardizedDate(item.weekDate),
              ).getFullYear();

              // only show this project if it has tasks on the current week number for this year
              if (planWeekNumber === weekNumber && planYear === currentYear) {
                show = true;
              }
            });
          });
        }

        return show;
      });

      // Order time cards based on which has the next most recent task that due
      filteredProjects.sort((a, b) => {
        const task1 = a.recentTask ? new Date(a.recentTask.date) : null;
        const task2 = b.recentTask ? new Date(b.recentTask.date) : null;

        return task1 && task2 ? task1 - task2 : task1 ? -1 : task2 ? 1 : 0;
      });

      setEntries(filteredProjects);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openAlertPopup("Error", "Something went wrong, please try again later.");
      console.error("Get project error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get All Time Projects
  |--------------------------------------------------------------------------
  */
  const getAllTimeProjects = async () => {
    try {
      setLoading(true);

      let results = await getProjectsFromApi(
        user.email,
        format(currentDate, "yyyy-MM-dd"),
        true,
      );

      results = results.data.data;

      const tempProjects = [];

      results.projects.forEach((project) => {
        let isActive = false;

        if (currentProjectId === project.harvestId) {
          isActive = true;
        }

        let weekBudget = 0;
        let allTimeBudget = 0;

        project.bookingPlan.forEach((plan) => {
          plan.weeks.forEach((item) => {
            const planWeekNumber = getWeek(getStandardizedDate(item.weekDate));

            const planYear = new Date(
              getStandardizedDate(item.weekDate),
            ).getFullYear();

            if (planWeekNumber === weekNumber && planYear === currentYear) {
              weekBudget += item.hours;
            }

            allTimeBudget += item.hours;
          });
        });

        // Rounds the number if it has more than 2 decimal places
        weekBudget = Math.round((weekBudget + Number.EPSILON) * 100) / 100;
        allTimeBudget =
          Math.round((allTimeBudget + Number.EPSILON) * 100) / 100;

        tempProjects.push({
          weekBudget,
          allTimeBudget,
          project,
          task: results.task,
          isActiveTimer: isActive,
        });
      });

      const filteredProjects = tempProjects.filter((project) => {
        let show = false;
        if (project.project.bookingPlan) {
          project.project.bookingPlan.forEach((plan) => {
            plan.weeks.forEach((item) => {
              const planWeekNumber = getWeek(
                getStandardizedDate(item.weekDate),
              );

              const planYear = new Date(
                getStandardizedDate(item.weekDate),
              ).getFullYear();

              if (planWeekNumber === weekNumber && planYear === currentYear) {
                show = true;
              }
            });
          });
        }

        return show ? project : false;
      });

      setEntries(filteredProjects);
      setAllTimeRetrieved(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openAlertPopup("Error", "Something went wrong, please try again later.");
      console.error("Get project error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get Team Projects
  |--------------------------------------------------------------------------
  */
  const getTeamProjects = async () => {
    try {
      const tempTeamEntries = await Promise.all(
        team.map(async (member) => {
          const memberProjects = await getProjectsFromApi(
            member.email,
            format(currentDate, "yyyy-MM-dd"),
          );
          member.task = memberProjects.data.data.task;
          member.projects = memberProjects.data.data;
          return member;
        }),
      );

      tempTeamEntries.map((teamEntry) => {
        const tempProjects = [];

        teamEntry.projects.projects.forEach((project) => {
          let isActive = false;

          if (currentProjectId === project.harvestId) {
            isActive = true;
          }

          let weekBudget = 0;
          let allTimeBudget = 0;

          project.bookingPlan.forEach((plan) => {
            plan.weeks.forEach((item) => {
              const planWeekNumber = getWeek(
                getStandardizedDate(item.weekDate),
              );

              const planYear = new Date(
                getStandardizedDate(item.weekDate),
              ).getFullYear();

              if (planWeekNumber === weekNumber && planYear === currentYear) {
                weekBudget += item.hours;
              }

              allTimeBudget += item.hours;
            });
          });

          // Rounds the number if it has more than 2 decimal places
          weekBudget = Math.round((weekBudget + Number.EPSILON) * 100) / 100;
          allTimeBudget =
            Math.round((allTimeBudget + Number.EPSILON) * 100) / 100;

          tempProjects.push({
            weekBudget,
            allTimeBudget,
            project,
            task: teamEntry.task,
            isActiveTimer: isActive,
          });
        });

        const filteredProjects = tempProjects.filter((project) => {
          let show = false;
          if (project.project.bookingPlan) {
            project.project.bookingPlan.forEach((plan) => {
              plan.weeks.forEach((item) => {
                const planWeekNumber = getWeek(
                  getStandardizedDate(item.weekDate),
                );

                const planYear = new Date(
                  getStandardizedDate(item.weekDate),
                ).getFullYear();

                if (planWeekNumber === weekNumber && planYear === currentYear) {
                  show = true;
                }
              });
            });
          }

          return show ? project : false;
        });

        teamEntry.projects = filteredProjects;
        return teamEntry;
      });

      // Grab the current logged in user's entries
      // let userEntries = tempTeamEntries.find((entry) => {
      //   return entry.harvestId === user.harvestId;
      // });

      // setEntries(userEntries.projects);

      setTeamEntries(tempTeamEntries);
      setLoading(false);
    } catch (error) {
      console.error("Get project error: " + error);
      openAlertPopup("Error", "Something went wrong, please try again later.");
      setLoading(false);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all available teams
  |--------------------------------------------------------------------------
  */
  const getTeams = async () => {
    try {
      const results = await getTeamsFromApi();
      setTeams(results);
    } catch (err) {
      console.error("getTeams failed");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get team members
  |--------------------------------------------------------------------------
  */
  const getTeam = async () => {
    try {
      const results = await getMembersFromApi({ team: selectedTeam });
      setTeam(results);
    } catch (err) {
      console.error("getTeam failed");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Stop timer on a task
  |--------------------------------------------------------------------------
  */
  const stopTimer = async (
    entryId,
    projectId,
    clientId,
    taskId,
    startDate,
    endDate,
    notes,
  ) => {
    try {
      await editTimerOnApi({
        entryId,
        notes,
      });

      const response = await stopTimerOnApi({
        entries,
        entryId,
        projectId,
        clientId,
        taskId,
        startDate,
        endDate,
        notes,
      });

      // Clear any existing timers, set the timer in state and store to null
      clearSidebarCounter();

      // For each project, replace the time entries with the new ones generated from the API call
      const tempEntries = [...entries];

      tempEntries.forEach((entry) => {
        const filteredProject = response.projects.filter((project) => {
          return project.project.harvestId === entry.project.harvestId;
        });

        entry.project.entries = [...filteredProject[0].project.entries];

        // Set all project activeTimers to false
        entry.isActiveTimer = false;
      });

      setEntries(tempEntries);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Delete timer on a task
  |--------------------------------------------------------------------------
  */
  const deleteTimer = async (
    entryId,
    projectId,
    clientId,
    taskId,
    startDate,
    endDate,
  ) => {
    try {
      const response = await deleteTimerOnApi({
        entries,
        entryId,
        projectId,
        clientId,
        taskId,
        startDate,
        endDate,
      });

      // Clear any existing timers, set the timer in state and store to null
      clearSidebarCounter();

      // For each project, replace the time entries with the new ones generated from the API call
      const tempEntries = [...entries];

      tempEntries.forEach((entry) => {
        const filteredProject = response.projects.filter((project) => {
          return project.project.harvestId === entry.project.harvestId;
        });

        entry.project.entries = [...filteredProject[0].project.entries];

        // Set all project activeTimers to false
        entry.isActiveTimer = false;
      });

      setEntries(tempEntries);

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Calculates the pace percentage for the progress bar
  |--------------------------------------------------------------------------
  */
  const calculatePace = ({ tasks }) => {
    const thisWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
    const thisWeekEnd = endOfWeek(currentDate, { weekStartsOn: 1 });
    const todayEnd = endOfDay(new Date());

    let taskHoursByToday = 0;
    let taskHoursThisWeek = 0;

    tasks.forEach((task) => {
      // task is due this week
      const dueThisWeek =
        new Date(task.date) >= thisWeekStart &&
        new Date(task.date) <= thisWeekEnd;

      // task is due this week, but prior to the end of today
      const dueByToday =
        new Date(task.date) >= thisWeekStart && new Date(task.date) <= todayEnd;

      // sum hours just for the tasks due this week
      if (dueThisWeek) {
        taskHoursThisWeek += task.hoursToComplete;
      }

      // sum hours just for the tasks due by today
      if (dueByToday) {
        taskHoursByToday += task.hoursToComplete;
      }
    });

    const percentage = (taskHoursByToday / taskHoursThisWeek) * 100;

    // If the percentage is way over or under, we want to put a hard limit so we don't have overflow issues
    const pacePct = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;

    return [pacePct, taskHoursByToday];

    // // If we are in full mode, we get the number of business days for the project,
    // // and how many we've had so far, then get the percentage of whole project
    // const total =
    //   differenceInBusinessDays(parseISO(endDate), parseISO(startDate)) + 1; // Adds an extra day, because the end date is the start of the last day
    // const current = differenceInBusinessDays(new Date(), parseISO(startDate));

    // const percentage = (current / total) * 100;

    // // If the percentage is way over or under, we want to put a hard limit so we don't have overflow issues
    // return percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;
  };

  return (
    <DashboardContainer>
      <Header>
        <HeaderLeft>
          <SubHeader>
            <Name>
              Team: <em>{user.role.team.name}</em>
            </Name>

            {isImportant ? (
              <SelectTeam
                placeholder="Select Team"
                onChange={(e) => {
                  setSelectedTeam(e?.label || null);
                }}
                options={teams.map((team) => ({
                  label: team.name,
                  value: team._id,
                }))}
                isClearable
              />
            ) : null}
          </SubHeader>
        </HeaderLeft>

        <HeaderRight>
          <WeekSettings
            id="time-entries-switch"
            onText="All Time"
            offText={`Week ${weekNumber}`}
            isActive={allTime}
            weekDuration={weekDuration}
            handleSwitch={(checked) => {
              setAllTime(checked);
            }}
            handlePrevWeek={() => setCurrentDate(subWeeks(currentDate, 1))}
            handleNextWeek={() => setCurrentDate(addWeeks(currentDate, 1))}
          />

          {isManagerOrAdmin ? (
            <AdminDashboard>
              <SimpleLink as={Link} to="/dashboard">
                Admin Dashboard
              </SimpleLink>
            </AdminDashboard>
          ) : null}
        </HeaderRight>
      </Header>

      <Cards>
        <CardTitle>{user.name}</CardTitle>

        <CardsInner>
          <CardsList>
            {user.harvestId &&
              entries &&
              entries.map((entry, index) => {
                const stages = [];
                if (entry.project.stages) {
                  //eslint-disable-next-line
                  for (const [key, value] of Object.entries(
                    entry.project.stages,
                  )) {
                    stages.push(key);
                  }
                }

                const productionStageIndex = stages.findIndex(
                  (stage) => stage === "production",
                );

                // get the name of the stage that comes right before production
                const stageBeforeProduction = stages[productionStageIndex - 1];

                // Checks if this project is in production
                let isInProduction = false;
                if (
                  entry?.project?.stages &&
                  entry.project.stages[stageBeforeProduction] &&
                  entry.project.stages[stageBeforeProduction].completed
                ) {
                  isInProduction = true;
                }

                const [pacePct, hoursByToday] = calculatePace(entry.project);

                // hide card if it has 0 hours budgetted for the week, and we aren't in AllTime view
                const hideCard = !allTime && !entry.weekBudget;

                return !hideCard ? (
                  <CardContainer
                    key={index}
                    entry={entry}
                    budgetHours={
                      allTime ? entry.allTimeBudget : entry.weekBudget
                    }
                    actualHours={
                      allTime
                        ? entry.project.entries
                        : entry.project.entries.filter((item) => {
                            // convert spent_date string into date
                            const spentDate = getStandardizedDate(
                              item.spent_date,
                            );
                            // get the week for this entry
                            const entryWeekNumber = getWeek(
                              getStandardizedDate(spentDate),
                            );
                            // get the year for this entry
                            const entryYear = new Date(spentDate).getFullYear();

                            // only show it if its the same week number and correct user
                            return (
                              entryWeekNumber === weekNumber &&
                              entryYear === currentYear &&
                              item.user.id === user.harvestId
                            );
                          })
                    }
                    pace={pacePct}
                    paceLabelTop={"EOD"}
                    paceLabelBottom={hoursByToday}
                    isActiveTimer={currentProjectId === entry.project._id}
                    startTimer={startTimer}
                    stopTimer={stopTimer}
                    deleteTimer={deleteTimer}
                    email={user.email}
                    isInProduction={isInProduction}
                    openStopModal={openStopModal}
                  />
                ) : null;
              })}
          </CardsList>
        </CardsInner>
      </Cards>

      {teamEntries && selectedTeam
        ? teamEntries.map((member, index) =>
            member._id !== user._id ? (
              <Cards key={index}>
                <CardTitle>{member.name}</CardTitle>
                <CardsList>
                  {member.harvestId && member.projects
                    ? member.projects.map((entry, index) => {
                        const stages = [];
                        if (entry.project.stages) {
                          //eslint-disable-next-line
                          for (const [key, value] of Object.entries(
                            entry.project.stages,
                          )) {
                            stages.push(key);
                          }
                        }
                        const productionStageIndex = stages.findIndex(
                          (stage) => stage === "production",
                        );

                        // get the name of the stage that comes right before production
                        const stageBeforeProduction =
                          stages[productionStageIndex - 1];

                        // Checks if this project is in production
                        let isInProduction = false;
                        if (
                          entry?.project?.stages &&
                          entry.project.stages[stageBeforeProduction] &&
                          entry.project.stages[stageBeforeProduction].completed
                        ) {
                          isInProduction = true;
                        }

                        const [pacePct, hoursByToday] = calculatePace(
                          entry.project,
                        );

                        // hide card if it has 0 hours budgetted for the week, and we aren't in AllTime view
                        const hideCard = !allTime && !entry.weekBudget;

                        return !hideCard ? (
                          <CardContainer
                            key={index}
                            disabled
                            entry={entry}
                            budgetHours={
                              allTime ? entry.allTimeBudget : entry.weekBudget
                            }
                            actualHours={
                              allTime
                                ? entry.project.entries
                                : entry.project.entries?.filter((item) => {
                                    // convert spent_date string into date
                                    const spentDate = getStandardizedDate(
                                      item.spent_date,
                                    );
                                    // get the week for this entry
                                    const entryWeekNumber = getWeek(
                                      getStandardizedDate(spentDate),
                                    );

                                    // only show it if its the same week number and correct user
                                    return (
                                      entryWeekNumber === weekNumber &&
                                      item.user.id === member.harvestId
                                    );
                                  })
                            }
                            pace={pacePct}
                            paceLabelTop={"EOD"}
                            paceLabelBottom={hoursByToday}
                            isActiveTimer={entry.isActiveTimer}
                            isInProduction={isInProduction}
                            email={member.email}
                          />
                        ) : null;
                      })
                    : null}
                </CardsList>
              </Cards>
            ) : null,
          )
        : null}

      {/* <SurveyLink href="/survey">Survey</SurveyLink> */}
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  padding: 20px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 20px 30px;
`;

const HeaderLeft = styled.div``;

const Name = styled.h3`
  font-size: ${(props) => props.theme.fontSize_s};
  font-weight: 300;
  color: ${(props) => props.theme.colors.darkSlate};
  margin: 0px 70px 0px 0px;

  em {
    font-weight: 600;
    font-style: normal;
  }
`;

const Cards = styled.div`
  padding-top: 18px;
  padding-left: 23px;
  transition: 0.3s ease-in-out;
  margin-bottom: 30px;

  &::after {
    content: "";
    flex: auto;
  }
`;

const CardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 23px;
`;

const CardTitle = styled.h3`
  font-weight: 600;
  margin: 10px 0px 20px 0px;
`;

const SubHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdminDashboard = styled.div`
  align-self: flex-end;
  margin-top: 37px;
  margin-right: 30px;
`;

const SelectTeam = styled(SelectDropdown)`
  width: 200px;
`;

//eslint-disable-next-line
const SurveyLink = styled.a`
  display: block;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: 4px solid #554668;
  color: #554668;
  background: transparent;
  border-radius: 10px;
  padding: 10px 20px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  align-self: center;

  &:hover,
  &:focus {
    background-color: #554668;
    color: white;
  }
`;

const CardsInner = styled.div`
  display: flex;
`;

const PermanentCards = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.cardBackground};
  padding-left: 23px;
  padding-top: 23px;
  margin-right: 23px;
`;

export default Dashboard;
