import React from "react";
import styled from "styled-components";
import SelectDropdown from "../newSelectDropdown";
import selectStyles from "../../vendor/react-select/newStyles";

const FilterSelect = ({
  label,
  handleChange,
  options,
  defaultValue,
  ...rest
}) => {
  return (
    <Filter {...rest}>
      <FilterLabel>{label}</FilterLabel>
      <FilterDropdown>
        <SelectDropdown
          styles={{
            ...selectStyles,
            control: (styles) => ({
              ...styles,
              border: "none !important",
              boxShadow: "none !important",
            }),
          }}
          defaultValue={defaultValue}
          onChange={(e) => {
            handleChange(label, e.value);
          }}
          options={options}
        />
      </FilterDropdown>
    </Filter>
  );
};

export const FilterLabel = styled.p`
  display: flex;
  align-items: center;

  font-size: 14px;
  text-transform: capitalize;

  margin: 0px;
  padding: 0px 10px;

  background-color: white;
  border-radius: 4px 0px 0px 4px;
  color: ${(props) => props.theme.colors.coolerGray};

  z-index: 1;
  pointer-events: none;
  transition: 0.2s ease-in-out;
`;

export const FilterDropdown = styled.div`
  width: 150px;
  cursor: pointer;

  * {
    font-size: 14px;
    cursor: pointer;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  border: 1px solid #e5e7eb;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  background-color: white;

  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    border-right: 1px solid white;
    border-color: #0079ff;
  }
`;

export default FilterSelect;
