import React from "react"
import styled from "styled-components"
import { useAuth } from "../../context/authContext"
import { useNotifications } from "../../context/notificationsContext"
import DefaultLink from "../../components/links/DefaultLink"

const Profile = () => {
  const { logout } = useAuth()
  const { openPromptPopup, closePromptPopup } = useNotifications()

  const openDownloadLink = () => {
    window.open("https://sherpa-api.big.vision/desktop/download/mac", "_blank")
  }

  const handleLogout = () => {
    openPromptPopup({
      header: "Log out of Sherpa",
      body:
        "Are you sure you want to log out of Sherpa? You will have to log back in to receive alerts, manage timers, or complete tasks and deliverables.",
      confirmCallback: () => {
        logout()
        closePromptPopup()
      },
      cancelText: "Cancel",
      confirmText: "Log out",
    })
  }

  return (
    <Container>
      <Title>Profile</Title>

      <Link onClick={() => openDownloadLink()}>Download Desktop App</Link>
      <Link onClick={handleLogout} danger>
        Log out of Sherpa
      </Link>
    </Container>
  )
}

const Container = styled.div`
  padding: 40px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 40px;
`

const Link = styled(DefaultLink)`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

export default Profile
