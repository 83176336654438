import React from "react";
import styled from "styled-components";

import { respondTo } from "../styles/styleHelpers";
import { sidebarWidth } from "../utils/constants";

import { useAuth } from "../context/authContext";

const MainContainer = ({ children }) => {
  const { user, isOpen } = useAuth();

  return (
    <Container isOpen={isOpen} loggedIn={user ? true : false}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: ${(props) =>
    props.loggedIn && props.isOpen ? sidebarWidth.laptop : sidebarWidth.closed};

  ${respondTo("xlarge")} {
    margin-left: ${(props) =>
      props.loggedIn && props.isOpen ? sidebarWidth.full : sidebarWidth.closed};
  }
`;

export default MainContainer;
