import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Dots = ({ color, direction }) => {
  return (
    <Container direction={direction}>
      <Dot color={color} />
      <Dot color={color} />
      <Dot color={color} />
    </Container>
  );
};

const Dot = styled.span`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.darkerGray};
`;

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "vertical" ? "column" : "row"};

  ${Dot} {
    margin-bottom: ${(props) =>
      props.direction === "vertical" ? "3px" : undefined};
    margin-right: ${(props) =>
      props.direction === "horizontal" ? "3px" : undefined};

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

Dots.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(["vertical", "horizontal"]),
};
Dots.defaultProps = {
  color: "darkerGray",
  direction: "vertical",
};

export default Dots;
