import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SimpleLink from "../../../../components/links/SimpleLink";
import { Range } from "../../../../components/Form";

import { editProjectOnApi } from "../../../manage/utils/api";

const BudgetPlan = ({ project, setProject, isManagerOrAdmin, isImportant }) => {
  const [chance, setChance] = useState(
    Object.prototype.hasOwnProperty.call(project, "chanceOfClosing")
      ? project.chanceOfClosing
      : 1,
  );

  const handleRange = (e) => {
    setChance(e.target.value);
  };

  const handleSave = async () => {
    try {
      const chanceOfClosing = parseFloat(chance);

      const hasChanged = project.chanceOfClosing !== chanceOfClosing;

      // if the chance has changed
      if (hasChanged) {
        //  update the project in state
        const updatedProject = {
          ...project,
          chanceOfClosing,
        };

        setProject(updatedProject);

        // update the project on the backend
        const data = {
          projectId: project._id,
          database: {
            chanceOfClosing,
          },
        };
        await editProjectOnApi(data);
      }
    } catch (error) {
      console.error("handleSave failed");
    }
  };

  return (
    <Container>
      <div>
        <h2>Chance of Closing</h2>

        <Range
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={chance}
          onChange={isManagerOrAdmin ? handleRange : undefined}
          onInput={isManagerOrAdmin ? handleRange : undefined}
          onBlur={isManagerOrAdmin ? handleSave : undefined}
          onMouseUp={isManagerOrAdmin ? handleSave : undefined}
          disabled={!isManagerOrAdmin}
        />

        <p>{Math.floor(chance * 100)}%</p>
      </div>

      {isImportant ? (
        <LinkContainer>
          <SimpleLink as={Link} to={`/projects/${project._id}/budget`}>
            Review Plan
          </SimpleLink>
        </LinkContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

const LinkContainer = styled.div`
  margin-top: 50px;
`;

BudgetPlan.propTypes = {
  project: PropTypes.object,
  setProject: PropTypes.func,
  isManagerOrAdmin: PropTypes.bool,
  isImportant: PropTypes.bool,
};
BudgetPlan.defaultProps = {
  project: null,
  setProject: null,
  isManagerOrAdmin: false,
  isImportant: false,
};

export default BudgetPlan;
