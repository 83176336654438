import React from "react";
import { Link } from "react-router-dom";
import {
  getWeek as getWeekDateFns,
  getDay as getDayFns,
  setDay as setDayFns,
  startOfWeek as startOfWeekFns,
  endOfWeek as endOfWeekFns,
  isSameWeek as isSameWeekFns,
  getHours,
  getMinutes,
  getSeconds,
  isBefore,
} from "date-fns";

import { useAuth } from "../context/authContext";
import SimpleLink from "../components/links/SimpleLink";

export const getWeek = (date = new Date(), options = {}) =>
  getWeekDateFns(date, {
    weekStartsOn: 1, // week starts on Monday
    firstWeekContainsDate: 7, // first week of the year must be the first full week, which would include Jan 7th
    ...options,
  });

export const getDay = (date = new Date(), options = {}) =>
  getDayFns(date, {
    weekStartsOn: 1, // week starts on Monday
    ...options,
  });

export const setDay = (date = new Date(), day = 0, options = {}) =>
  setDayFns(date, day, {
    weekStartsOn: 1, // week starts on Monday
    ...options,
  });

export const startOfWeek = (date = new Date(), options = {}) =>
  startOfWeekFns(date, {
    weekStartsOn: 1, // week starts on Monday
    ...options,
  });

export const endOfWeek = (date = new Date(), options = {}) =>
  endOfWeekFns(date, {
    weekStartsOn: 1, // week starts on Monday
    ...options,
  });

export const isSameWeek = (
  dateLeft = new Date(),
  dateRight = new Date(),
  options = {},
) =>
  isSameWeekFns(dateLeft, dateRight, {
    weekStartsOn: 1, // week starts on Monday
    ...options,
  });

/**
 * Take timezone into account for a date
 * @param {string | Date} date - Date object or date string like: "2021-01-17" or "2021-01-17T00:00:00.000+00:00"
 * @return {Date} - Returns the standardized date in a Date object
 */
export const getStandardizedDate = (date, hasTimezone = true) => {
  const dateString = getDateString(date, hasTimezone);

  return new Date(dateString);
};

export const getDateString = (date, hasTimezone = true) => {
  // If a Date object is passed in, we use that,
  // otherwise, convert the string to a date
  const dateObj = date instanceof Date ? date : new Date(date);

  const timezone = hasTimezone ? "-04:00" : "";

  const yearMonthDay = getBasicDateString(dateObj);

  return new Date(`${yearMonthDay}T04:00:00.000${timezone}`);
};

export const getStandardizedDateTime = (date, hasTimezone = true) => {
  const dateString = getDateTimeString(date, hasTimezone);

  return new Date(dateString);
};

export const getDateTimeString = (date, hasTimezone = true) => {
  // If a Date object is passed in, we use that,
  // otherwise, convert the string to a date
  const dateObj = date instanceof Date ? date : new Date(date);

  let hours = getHours(dateObj);
  let minutes = getMinutes(dateObj);
  let seconds = getSeconds(dateObj);

  // these must be 2 digits, so put a zero in front if needed
  hours = `${hours}`.padStart(2, "0");
  minutes = `${minutes}`.padStart(2, "0");
  seconds = `${seconds}`.padStart(2, "0");

  const milliSec = "000";

  const timezone = hasTimezone ? "-04:00" : "";

  const yearMonthDay = getBasicDateString(dateObj);

  return `${yearMonthDay}T${hours}:${minutes}:${seconds}.${milliSec}${timezone}`;
};

export const getBasicDateString = (date) => {
  // If a Date object is passed in, we use that,
  // otherwise, convert the string to a date
  const dateObj = date instanceof Date ? date : new Date(date);

  // get the year, month, and day from the provided date
  let year = dateObj.getUTCFullYear();
  let month = dateObj.getUTCMonth();
  let day = dateObj.getUTCDate();

  // add 1 to month, since it is 0 indexed
  month = month + 1;

  // these must be 2 digits, so put a zero in front if needed
  month = `${month}`.padStart(2, "0");
  day = `${day}`.padStart(2, "0");

  // make sure year has 4 digits
  year = `${year}`.padStart(4, "0");

  return `${year}-${month}-${day}`;
};

export const getKpiDate = (date) => {
  // If a Date object is passed in, we use that,
  // otherwise, convert the string to a date
  const dateObj = date instanceof Date ? date : new Date(date);
  dateObj.setHours(dateObj.getHours() - 5);

  return dateObj;
};

export const getSafariDate = (date) => {
  let safariDate = date.replace(/-/g, "/");
  safariDate = new Date(safariDate);

  // get the year, month, and day from the provided date
  const year = safariDate.getUTCFullYear();
  let month = safariDate.getUTCMonth();
  let day = safariDate.getUTCDate();

  // add 1 to month, since it is 0 indexed
  month = month + 1;
  // month must be 2 digits, so add a leading 0 if its less than 10
  month = month < 10 ? `0${month}` : month;

  // day must be 2 digits, so add a leading 0 if its less than 10
  day = day < 10 ? `0${day}` : day;

  return new Date(`${year}-${month}-${day}T00:00:00.000-10:00`);
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getCurrency = (number, showCents = undefined) => {
  // if we don't specify whether or not to show cents, it will default to whether or not the number has decimals. If it does, then show cents. Otherwise, don't.
  const shouldShowCents =
    typeof showCents === "undefined" ? !Number.isInteger(number) : showCents;

  return Number(number).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    // whether to show cents ".00" at the end
    minimumFractionDigits: shouldShowCents ? 2 : 0,
    maximumFractionDigits: shouldShowCents ? 2 : 0,
  });
};

export const isValidDate = (date) => date instanceof Date && !isNaN(date);

// Searches through the string to highlight any users that have been tagged & urls to convert to links
export const highlightTags = (message = "") => {
  //Regex finds any tags that are in the @handle format
  // const tagRegex = /(?:^|\W)@[a-zA-Z]+(?=$|\W)/g;
  // ? modified it to be a positive lookbehind, so the character right before the "@" doesn't get included
  const tagRegex = /(?<=^|\W)@[a-zA-Z]+(?=$|\W)/g;
  const formattedString = message.replace(
    tagRegex,
    '<span class="highlighted">$&</span>',
  );

  const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  const formattedHTML = formattedString.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`,
  );

  return formattedHTML;
};

// If a link doesn't have http or https in front of it, we add them
export const checkAndAppendHttps = (string) => {
  let result = string;
  const regex = /^http(s)?/;

  if (!result.match(regex)) {
    result = "https://" + result;
  }

  return result;
};

// replace new lines in text with "<br />"
export const replaceNewLineWithBr = (text) =>
  text.replace(/\r\n|\r|\n/g, "<br />");

// replace "<br />" with new lines
export const replaceBrWithNewLine = (text) => text.replace(/<br\s?\/?>/g, "\n");

// replace encoded "<br />" with decoded "<br />"
export const decodeBr = (text) => text.replace(/&lt;br\s?\/?&gt;/g, "<br />");

/*
|--------------------------------------------------------------------------
| Set prefix for the month day
|--------------------------------------------------------------------------
*/
export const ordinal = (day) => {
  if (day > 20 || day < 10) {
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }
  return "th";
};

/*
|--------------------------------------------------------------------------
| Set all client names to links to the client view if you're an admin
|--------------------------------------------------------------------------
*/
export const ClientLink = ({ name, id }) => {
  const { isAdmin } = useAuth();

  return isAdmin ? (
    <SimpleLink as={Link} to={`/manage/clients/${id}`}>
      {name}
    </SimpleLink>
  ) : (
    <>{name}</>
  );
};

/*
|--------------------------------------------------------------------------
| Set all project names to links to the project view if you're an admin
|--------------------------------------------------------------------------
*/
export const ProjectLink = ({ name, id }) => {
  const { isAdmin } = useAuth();

  return isAdmin ? (
    <SimpleLink as={Link} to={`/projects/${id}`}>
      {name}
    </SimpleLink>
  ) : (
    <>{name}</>
  );
};

export const getSortFunction = ({
  type = "string",
  direction = "asc",
  field,
}) => {
  const isAsc = direction === "asc";

  const sortFunctions = {
    number: (a, b) => {
      const prev = isAsc ? a : b;
      const next = isAsc ? b : a;

      const prevNumber = Number(prev[field]);
      const nextNumber = Number(next[field]);

      return prevNumber < nextNumber ? -1 : prevNumber > nextNumber ? 1 : 0;
    },
    string: (a, b) => {
      const prev = isAsc ? a : b;
      const next = isAsc ? b : a;

      const prevText = String(prev[field]).toUpperCase();
      const nextText = String(next[field]).toUpperCase();

      return prevText < nextText ? -1 : prevText > nextText ? 1 : 0;
    },
    date: (a, b) => {
      const prev = isAsc ? a : b;
      const next = isAsc ? b : a;

      const prevDate = new Date(prev[field]);
      const nextDate = new Date(next[field]);

      return isBefore(prevDate, nextDate)
        ? -1
        : isBefore(nextDate, prevDate)
        ? 1
        : 0;
    },
  };

  // fallback in case "text" is passed in for the type instead of "string"
  sortFunctions.text = sortFunctions.string;

  return sortFunctions[type];
};

export const findItemProperty = (
  property,
  array = [],
  findByProp,
  findByValue,
) => {
  const found = array.find((item) => item[findByProp] === findByValue);
  return found ? found[property] : "";
};

// remove duplicate objects from an array
export const removeArrayObjectDuplicates = (items, identifier) => {
  return Array.from(
    new Set(
      // create flat array of just the identifier property value
      // Set will remove any duplicates
      items.map((item) => item[identifier]),
    ),
    // Array.from will convert the Set into an Array
    // loop over the array of identifiers
  ).map((thisIdentifier) => {
    // return the object of the corresponding item from the original untampered array
    return items.find((item) => item[identifier] === thisIdentifier);
  });
};

export const roundUpTo = (number, roundTo) => {
  const rounder = 1 / roundTo;
  return Math.ceil(number * rounder) / rounder;
};

export const roundDownTo = (number, roundTo) => {
  const rounder = 1 / roundTo;
  return Math.floor(number * rounder) / rounder;
};

export const isNumber = (value) => typeof value === "number" && isFinite(value);

export const isBoolean = (value) => typeof value === "boolean";

// ensure that the notes object always has a value for `blocks` and `entityMap`
export const formatNotesObject = (notes) => ({
  ...notes,
  blocks: notes.blocks || [],
  entityMap: notes.entityMap || {},
});
