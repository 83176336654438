import React from "react";

export default () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.89401 0.553001C8.81087 0.38712 8.68322 0.24764 8.52533 0.150167C8.36745 0.0526925 8.18556 0.00106812 8.00001 0.00106812C7.81446 0.00106812 7.63257 0.0526925 7.47468 0.150167C7.3168 0.24764 7.18915 0.38712 7.10601 0.553001L0.106007 14.553C0.0191509 14.7266 -0.0153083 14.9217 0.00683302 15.1145C0.0289744 15.3074 0.106759 15.4896 0.230699 15.639C0.354638 15.7884 0.519374 15.8985 0.704811 15.9558C0.890248 16.0132 1.08837 16.0153 1.27501 15.962L6.27501 14.533C6.48398 14.4732 6.66779 14.347 6.79861 14.1734C6.92943 13.9998 7.00013 13.7884 7.00001 13.571V9C7.00001 8.73478 7.10536 8.48043 7.2929 8.29289C7.48044 8.10536 7.73479 8 8.00001 8C8.26522 8 8.51958 8.10536 8.70711 8.29289C8.89465 8.48043 9.00001 8.73478 9.00001 9V13.571C8.99989 13.7884 9.07059 13.9998 9.2014 14.1734C9.33222 14.347 9.51603 14.4732 9.72501 14.533L14.725 15.961C14.9116 16.0144 15.1097 16.0124 15.2951 15.9552C15.4806 15.898 15.6454 15.788 15.7694 15.6387C15.8934 15.4895 15.9714 15.3073 15.9937 15.1146C16.016 14.9218 15.9817 14.7266 15.895 14.553L8.89501 0.553001H8.89401Z"
      fill="#374151"
    />
  </svg>
);
