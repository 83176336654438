import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { getTeamsFromApi } from "../../utils/api";

import CreateLink from "../../components/links/CreateLink";
import SimpleLink from "../../components/links/SimpleLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../components/Table";
import ProfileImage from "../../components/ProfileImage";

const Teams = () => {
  const [teams, setTeams] = useState([]);
  const [sortBy, setSortBy] = useState("newest");

  useEffect(() => {
    getTeams();
  }, []);

  async function getTeams() {
    const results = await getTeamsFromApi();

    setTeams(results);
  }

  // Handles the actual sorting of the teams
  useEffect(() => {
    const sortedTeams = [...teams];

    if (sortBy === "name-asc") {
      sortedTeams.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedTeams.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setTeams(sortedTeams);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  return (
    <TeamsContainer>
      <TeamsHeader>
        <h1>Teams</h1>
        <CreateLink as={Link} to={`/manage/teams/new`}>
          Create Team
        </CreateLink>
      </TeamsHeader>
      {teams.length ? (
        <TeamsList>
          <thead>
            <tr>
              <th></th>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
              >
                Team Name
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <TableRow key={index}>
                <TeamsCell>
                  <ProfileImage
                    type="team"
                    handle={team.name}
                    name={team.name}
                    small
                  />
                </TeamsCell>
                <TableCell align="left">
                  <TableCellTitle style={{ display: "block" }}>
                    {team.name}
                  </TableCellTitle>
                  <SimpleLink as={Link} to={`/manage/teams/${team._id}`}>
                    edit
                  </SimpleLink>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </TeamsList>
      ) : null}
    </TeamsContainer>
  );
};

const TeamsContainer = styled.div`
  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const TeamsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const TeamsList = styled.table`
  padding: 50px;
  width: 100%;
  max-width: 1400px;
`;

const TeamsCell = styled(TableCell)`
  &:first-child {
    width: 30px;
  }
`;

export default Teams;
