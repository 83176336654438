import styled, { css } from "styled-components";

import { Input } from "../newForm";
import { respondTo, buttonReset } from "../../styles/styleHelpers";

export const TableOuter = styled.div`
  background-color: white;
  padding: ${(props) => (props.noPadding ? "0px" : "24px")};
  border-radius: 8px;
  box-shadow: ${(props) => (props.noShadow ? "none" : props.theme.boxShadow)};
  overflow: hidden;
  overflow: ${(props) => (props.scrollable ? "hidden" : "visible")};
`;

export const TableInner = styled.div`
  ${(props) =>
    props.scrollable
      ? css`
          max-height: 674px;
          overflow: auto;
        `
      : ``}
`;

export const Table = styled.table`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
`;

export const TableHead = styled.thead``;

export const TableHeader = styled.th`
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  transition: 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  color: ${(props) => props.theme.colors.darkGray};
  border: none;
  text-align: left;

  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.colors.lightGray};
  z-index: 5;

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.darkSlate};
  }

  &:first-of-type {
    padding: 10px 0px 10px 10px;
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  font-size: 12px;
  position: relative;

  ${(props) =>
    props.highlight
      ? css`
          background-color: ${(props) => props.theme.colors.lightSlate};
          scroll-margin: 100px;
        `
      : ``}


  ${(props) =>
    props.disabled
      ? css`
          /* don't fade it when hovered/focused since it will prevent the Editable tooltip from being used */
          td:not(:hover):not(:focus):not(:focus-within) {
            opacity: 0.5;
          }
        `
      : ``}

  ${(props) =>
    props.sticky
      ? css`
          background-color: ${(props) =>
            props.dark ? props.theme.colors.lightGray : "white"};

          z-index: 4;

          /* so it can overlap the top of the project view while its hovered if one of its cells has a dropdown open */
          &:hover,
          &:focus-within {
            z-index: 8;
          }

          &,
          td {
            position: sticky;
            bottom: 0;
          }

          /* so the contents of a hovered cell can appear on top of adjacent cells, like if it contains a dropdown */
          td {
            &:hover,
            &:focus-within {
              z-index: 1;
            }
          }
        `
      : ``}
`;

export const TableCell = styled.td`
  text-align: ${(props) => (props.center ? "center" : "left")};
  padding: 10px 20px;
  flex-shrink: 0;
  font-weight: ${(props) => (props.danger ? "700" : "500")};
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
  color: ${(props) => props.theme.colors.darkerGray};
  transition: opacity 200ms;

  ${(props) =>
    props.full
      ? css`
          white-space: nowrap;
        `
      : ""};

  &:first-of-type {
    padding: 10px 0px 10px 10px;
  }
`;

export const Checkbox = styled.button`
  display: block;
  position: relative;
  background: white;
  border: 1px solid ${(props) => props.theme.colors.coolGray};
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: 0.2s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: ${(props) => props.theme.colors.blue};
    border-radius: 3px;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.1s, transform 0.1s, background-color 0.1s;
  }

  ${(props) =>
    props.isChecked
      ? css`
          &::after {
            opacity: 1;
            transform: scale(1);
          }

          &:hover {
            background-color: ${props.theme.colors.lightGray};
          }
        `
      : css`
          &:hover {
            background-color: ${props.theme.colors.lightGray};
          }
        `}
`;

export const SearchInput = styled(Input)`
  margin-left: auto;
`;

export const StatsMain = styled.div`
  display: flex;
  gap: 40px;
`;

export const StatsCol = styled.div`
  flex: 1;
`;

export const Stat = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StatLabel = styled.p`
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray400};
  width: 75px;

  ${respondTo("xlarge")} {
    width: 146px;
  }
`;

export const StatValue = styled.div`
  text-align: right;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

export const StatValueRow = styled.span`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  gap: 10px;
`;

export const StatValueColor = styled.span`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal600
      : props.theme.colors.blue600};
  padding: 4px 8px;

  background: ${(props) =>
    props.color === "teal"
      ? props.theme.colors.teal100
      : props.theme.colors.blue100};
  border-radius: 4px;
`;

export const StatValueEm = styled.span`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.indigo600};
`;

export const StatExLink = styled.a`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;

  color: ${(props) => props.theme.colors.indigo600};
  transition: color 0.2s;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
    display: inline-block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.indigo800};
  }
`;

export const AddBtn = styled.button`
  ${buttonReset()}

  svg {
    display: inline-block;
    width: 100%;

    path {
      fill: transparent;
      transition: stroke 200ms, fill 200ms;
    }
  }

  &:disabled {
    cursor: not-allowed;

    path {
      stroke: ${(props) => props.theme.colors.coolGray};
    }
  }

  &:not(:disabled) {
    &:hover,
    &:focus-within {
      path {
        fill: ${(props) => props.theme.colors.indigo100};
      }
    }
  }
`;
