import React from "react";
import styled from "styled-components";

const PlusIcon = () => {
  return (
    <Svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5625 0.5C5.82772 0.5 6.08207 0.605357 6.26961 0.792893C6.45714 0.98043 6.5625 1.23478 6.5625 1.5V4.5H9.5625C9.82772 4.5 10.0821 4.60536 10.2696 4.79289C10.4571 4.98043 10.5625 5.23478 10.5625 5.5C10.5625 5.76522 10.4571 6.01957 10.2696 6.20711C10.0821 6.39464 9.82772 6.5 9.5625 6.5H6.5625V9.5C6.5625 9.76522 6.45714 10.0196 6.26961 10.2071C6.08207 10.3946 5.82772 10.5 5.5625 10.5C5.29728 10.5 5.04293 10.3946 4.85539 10.2071C4.66786 10.0196 4.5625 9.76522 4.5625 9.5V6.5H1.5625C1.29728 6.5 1.04293 6.39464 0.855393 6.20711C0.667857 6.01957 0.5625 5.76522 0.5625 5.5C0.5625 5.23478 0.667857 4.98043 0.855393 4.79289C1.04293 4.60536 1.29728 4.5 1.5625 4.5H4.5625V1.5C4.5625 1.23478 4.66786 0.98043 4.85539 0.792893C5.04293 0.605357 5.29728 0.5 5.5625 0.5Z"
        fill="#9CA3AF"
      />
    </Svg>
  );
};

const Svg = styled.svg`
  display: inline-block;
`;

export default PlusIcon;
