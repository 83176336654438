import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { downloadFileFromApi, updateMemberOnApi } from "../../../utils/api";

import { editProjectOnApi } from "../../manage/utils/api";

import { useAuth } from "../../../context/authContext";
import { useNotifications } from "../../../context/notificationsContext";

import ProfileImage from "../../../components/ProfileImage";
import ChatMessageList from "../../../components/Chat/ChatMessageList";
import ChatCompose from "../../../components/Chat/ChatCompose";
import ChatWrapper from "../../../components/Chat/ChatWrapper";

const Recaps = ({ project, initialRecaps }) => {
  const { user } = useAuth();
  const { loading, setLoading } = useNotifications();

  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentRecapIndex, setCurrentRecapIndex] = useState(null);
  const [recaps, setRecaps] = useState(initialRecaps);
  const [speed, setSpeed] = useState(0);
  const [message, setMessage] = useState("");
  const [listView, setListView] = useState(true);

  useEffect(() => {
    getFiles();
  }, []);

  // user's playback speed stored in DB
  useEffect(() => {
    setSpeed(user?.playbackSpeed || 1);
  }, [user]);

  useEffect(() => {
    if (!listView) {
      const video = document.getElementById("recap-video");

      if (video) {
        video.playbackRate = speed;
      }
    }
  }, [listView]);

  /*
  |--------------------------------------------------------------------------
  | Play the video that was clicked on
  |--------------------------------------------------------------------------
  */
  const viewRecap = async (index, type, fileId) => {
    try {
      let tempRecaps = [...recaps];
      setCurrentRecapIndex(index);
      setCurrentVideo(null);

      if (type === "video") {
        setListView(false);
      }

      // Converts old values of true/false to an array of users who have viewed the recap
      tempRecaps = tempRecaps.map((recap) => {
        // Set the recap's viewed property to be an empty array if it's not already an array
        if (!Array.isArray(recap.viewed)) {
          recap.viewed = [];
        }

        return recap;
      });

      // If the viewed array does not include the user's id, add the user id to the viewed array
      if (!tempRecaps[index].viewed.includes(user._id)) {
        tempRecaps[index].viewed.push(user._id);

        const projectData = {
          projectId: project,
          database: {
            recaps: tempRecaps,
          },
        };

        await editProjectOnApi(projectData);
        setRecaps(tempRecaps);
      }

      // Download the file from the api and get the source of the video

      if (type === "video") {
        setLoading(true);
        let downloadedVideo = await downloadFileFromApi(fileId);
        downloadedVideo = window.URL.createObjectURL(downloadedVideo);
        setCurrentVideo(downloadedVideo);
        setLoading(false);
      }
    } catch (err) {
      console.error("Error playing video", err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Add comments to a recap video
  |--------------------------------------------------------------------------
  */
  const addComment = async () => {
    try {
      const tempRecaps = [...recaps];
      let tempComments = [];

      if (tempRecaps[currentRecapIndex]) {
        if (tempRecaps[currentRecapIndex].comments) {
          tempComments = tempRecaps[currentRecapIndex].comments;
        }

        tempComments.push({
          author: user._id,
          date: Date.now(),
          message,
        });
      }

      if (tempComments && tempComments.length) {
        tempRecaps[currentRecapIndex].comments = tempComments;

        const projectData = {
          projectId: project,
          database: {
            recaps: tempRecaps,
          },
        };

        const result = await editProjectOnApi(projectData);

        setRecaps(result.data.result.database.recaps);
      }
    } catch (err) {
      console.error("Error adding comment", err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the files
  |--------------------------------------------------------------------------
  */
  const getFiles = async () => {
    // try {
    //   setLoading(true);
    //   // get all the recap files for this project, that aren't archived
    //   const files = await getFilesFromApi({
    //     project: project,
    //     category: "recaps",
    //     isArchived: false,
    //     isHidden: false,
    //   });
    //   setRecaps(files);
    //   setLoading(false);
    // } catch (err) {
    //   console.error("Error getting recaps", err);
    // }
  };

  /*
  |--------------------------------------------------------------------------
  | Set playback speed
  |--------------------------------------------------------------------------
  */
  const updatePlaybackSpeed = async (speed) => {
    // TODO: maybe clean this up
    const video = document.getElementById("recap-video");
    video.playbackRate = speed;
    await updateMemberOnApi(user._id, { playbackSpeed: speed });
    setSpeed(speed);
  };

  const playbackSpeeds = [0.5, 1, 1.5, 2];

  const isViewingRecap =
    currentRecapIndex !== null && !listView && recaps?.length;

  // max characters to show as a preview of the note
  const noteMaxLength = 50;

  return (
    <OuterContainer>
      <Container>
        {isViewingRecap ? (
          <PlayerContainer>
            <PlayerInner loading={loading} currentVideo={currentVideo}>
              <Player id="recap-video" src={currentVideo} controls />
            </PlayerInner>

            <PlayerSpeed>
              {playbackSpeeds.map((playbackSpeed) => (
                <PlaybackSpeed
                  active={speed === playbackSpeed}
                  onClick={() => {
                    updatePlaybackSpeed(playbackSpeed);
                  }}
                >
                  {playbackSpeed}x
                </PlaybackSpeed>
              ))}
            </PlayerSpeed>

            <PlayerFooter>
              <RecapInfo>
                <RecapOwner>
                  {recaps[currentRecapIndex].owner ? (
                    <ProfileImage
                      handle={recaps[currentRecapIndex].owner.handle}
                      name={recaps[currentRecapIndex].owner.name}
                      small
                      showStatus
                    />
                  ) : null}

                  <OwnerName>{recaps[currentRecapIndex].owner.name}</OwnerName>
                </RecapOwner>

                <VideoTitle>
                  {format(
                    new Date(recaps[currentRecapIndex].date),
                    "MM-dd-yyyy",
                  )}{" "}
                  -{" "}
                  {recaps[currentRecapIndex].file
                    ? recaps[currentRecapIndex].file.fileName
                    : null}
                </VideoTitle>
              </RecapInfo>
              <ViewAll onClick={() => setListView(true)}>More Videos</ViewAll>
            </PlayerFooter>
          </PlayerContainer>
        ) : (
          <RecapsContainer>
            {/* go back to the recap that was previously open */}
            {currentRecapIndex !== null ? (
              <Back
                onClick={() => {
                  setListView(false);
                }}
              >
                Back to Video
              </Back>
            ) : null}

            <RecapsList>
              {recaps?.length
                ? recaps.map((recap, index) => (
                    <Recap
                      key={index}
                      onClick={() => {
                        if (recap.file) {
                          viewRecap(index, "video", recap.file._id);
                        }
                      }}
                    >
                      <RecapOwner>
                        {recap.owner ? (
                          <ProfileImage
                            handle={recap.owner.handle}
                            name={recap.owner.name}
                            small
                            showStatus
                          />
                        ) : null}

                        <OwnerName>
                          {recap.owner.name.split(" ").map((word) => {
                            return <span>{word}</span>;
                          })}
                        </OwnerName>
                      </RecapOwner>

                      <RecapTitle align="left">
                        {format(new Date(recap.date), "MM-dd-yyyy")} -{" "}
                        {recap.file ? recap.file.fileName : null}
                      </RecapTitle>

                      <RecapViewed>
                        {recap.file ? (
                          <PlayVideo
                            viewed={
                              recap.viewed?.length &&
                              recap.viewed.includes(user._id)
                            }
                          >
                            {recap.viewed?.length &&
                            recap.viewed.includes(user._id)
                              ? "Viewed"
                              : "View"}
                          </PlayVideo>
                        ) : (
                          <Notes onMouseEnter={() => viewRecap(index, "notes")}>
                            {recap.notes ? (
                              <NotesTrigger>
                                <NotesText
                                  viewed={
                                    recap.viewed?.length &&
                                    recap.viewed.includes(user._id)
                                  }
                                >
                                  {recap.notes.substring(0, noteMaxLength)}
                                  {recap.notes.length > noteMaxLength
                                    ? "..."
                                    : null}
                                </NotesText>

                                <NotesFull>
                                  <NotesText viewed={recap.viewed}>
                                    {recap.notes}
                                  </NotesText>
                                </NotesFull>
                              </NotesTrigger>
                            ) : null}
                          </Notes>
                        )}
                      </RecapViewed>
                    </Recap>
                  ))
                : "No recaps"}
            </RecapsList>
          </RecapsContainer>
        )}

        {isViewingRecap ? (
          <Comments>
            <CommentsInner>
              <ChatWrapper
                footer={
                  <ChatCompose
                    message={message}
                    onMessageChange={(value) => setMessage(value)}
                    onSend={() => {
                      addComment();
                      setMessage("");
                    }}
                  />
                }
              >
                <ChatMessageList
                  items={recaps[currentRecapIndex].comments}
                  userId={user._id}
                />
              </ChatWrapper>
            </CommentsInner>
          </Comments>
        ) : null}
      </Container>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  position: relative;
  display: flex;

  box-shadow: 0px 5px 15px 5px rgb(0 0 0 / 5%);
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;

  /* overflow: hidden; */

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const Comments = styled.div`
  width: 400px;
  flex-shrink: 0;
  position: relative;
`;

const CommentsInner = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const RecapsContainer = styled.div`
  background-color: white;
  height: 100%;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const Back = styled.p`
  color: #4b5563;
  margin-bottom: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  line-height: 1;

  &::before {
    display: block;
    content: "";
    height: 10px;
    width: 10px;
    border-top: 2px solid #4b5563;
    border-left: 2px solid #4b5563;
    transform: rotate(-45deg);
    margin-right: 5px;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    color: #5048e5;

    &::before {
      border-color: #5048e5;
      transform: translateX(-5px) rotate(-45deg);
    }
  }
`;

const ViewAll = styled.p`
  color: #4b5563;
  margin-bottom: 0px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  line-height: 1;

  &::after {
    display: block;
    content: "";
    height: 10px;
    width: 10px;
    border-top: 2px solid #4b5563;
    border-left: 2px solid #4b5563;
    transform: rotate(135deg);
    margin-left: 5px;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    color: #5048e5;

    &::after {
      border-color: #5048e5;
      transform: translateX(5px) rotate(135deg);
    }
  }
`;

const RecapsList = styled.table`
  width: 100%;
`;

const PlayerContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-right: 40px;
`;

const PlayerInner = styled.div`
  width: 100%;
  padding-top: 62.5%;
  height: 0px;
  position: relative;

  &::after {
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s ease-in-out;
    z-index: 2;
    background-color: ${(props) =>
      props.loading || !props.currentVideo ? "black" : "transparent"};
    pointer-events: ${(props) =>
      props.loading || !props.currentVideo ? "all" : "none"};
  }
`;

const Player = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
`;

const PlayVideo = styled.div`
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background-color: ${(props) => (props.viewed ? "#5048E5" : "white")};
  color: ${(props) => (props.viewed ? "white" : "#374151")};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  cursor: pointer;
`;

const Notes = styled.div`
  position: relative;
  z-index: 3;
`;

const NotesText = styled.p`
  text-align: left;
  font-size: ${(props) => props.theme.fontSize_xxxs};

  margin-bottom: 0;
  padding: 10px 0;

  color: ${(props) =>
    props.viewed
      ? props.theme.colors.oldBlack2
      : props.theme.colors.mediumGray};
`;

const NotesTrigger = styled.div``;

const NotesFull = styled.div`
  position: absolute;
  /* bottom: 100%; */
  top: 100%;
  left: 0;
  padding: 15px;
  white-space: normal;
  width: 300px;
  text-align: left;

  background-color: #fff;
  box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  opacity: 0;
  visibility: hidden;
  z-index: 1;

  transition: opacity 200ms, visibility 200ms;

  ${NotesTrigger}:hover & {
    opacity: 1;
    visibility: visible;
  }

  ${NotesText} {
    padding: 0;
  }
`;

const PlayerSpeed = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px auto;
`;

const PlaybackSpeed = styled.button`
  color: ${(props) => (props.active ? "white" : props.theme.colors.oldBlack2)};
  background-color: ${(props) =>
    props.active ? props.theme.colors.blue : "transparent"};
  height: 30px;
  width: 35px;
  margin: 0px 5px;
  font-weight: 600;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  font-size: 14px;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.blue};
    color: white;
  }
`;

const PlayerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  border-top: 1px solid #e5e7eb;
  padding-top: 18px;
`;

const RecapInfo = styled.div``;

const VideoTitle = styled.h3`
  font-weight: normal;
  color: #374151;
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
`;

const Recap = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  border-bottom: 1px solid #e5e7eb;
  padding: 10px 0px;

  transition: 0.2s ease-in-out;
  cursor: pointer;

  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 2;
  }
`;

const RecapTitle = styled.div`
  margin-right: 25px;
  color: #374151;
  font-size: 14px;
  border-left: 2px solid #4b5563;
  padding-left: 25px;
  min-width: 300px;
`;

const RecapOwner = styled.div`
  display: flex;
  align-items: center;
  margin-right: 25px;
`;

const OwnerName = styled.p`
  margin-bottom: 0px;
  margin-left: 5px;
  color: #6b7280;
  font-size: 12px;
  line-height: 1;
  width: 60px;

  span {
    display: block;
    line-height: 1;
  }
`;

const RecapViewed = styled.div``;

export default Recaps;
