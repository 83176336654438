import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ProgressBar = ({
  actual,
  budgeted,
  paceLow,
  paceHigh,
  tall,
  ...rest
}) => {
  const calculateProgress = (actual, budgeted) => {
    if (budgeted === 0 && actual !== 0) {
      return "over";
    } else if (budgeted === 0 && actual === 0) {
      return 0;
    } else if (actual <= budgeted) {
      return (actual / budgeted) * 100;
    } else {
      return "over";
    }
  };

  // Figures out if we are on track, compared to the pace
  // Returns over, good, under
  const calculateOnTrack = (actual, budgeted) => {
    const currentProgress = calculateProgress(actual, budgeted);

    if (currentProgress === "over") {
      return "over";
    } else {
      if (paceLow < currentProgress && currentProgress < paceHigh) {
        return "good";
      } else {
        return "under";
      }
    }
  };

  return (
    <Container
      whereOnTrack={calculateOnTrack(actual, budgeted)}
      tall={tall ? true : false}
      {...rest}
    >
      <Progress
        percentage={calculateProgress(actual, budgeted)}
        whereOnTrack={calculateOnTrack(actual, budgeted)}
      />
      {paceLow && paceLow >= 0 ? (
        <PaceLine tall={tall ? true : false} percentage={paceLow} />
      ) : null}
      {paceHigh && paceHigh >= 0 ? (
        <PaceLine tall={tall ? true : false} percentage={paceHigh} />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) =>
    props.whereOnTrack === "good"
      ? props.theme.colors.lightGreen
      : props.whereOnTrack === "under"
      ? props.theme.colors.lightYellow
      : props.theme.colors.lightRed};
  height: ${(props) => (props.tall ? `27px` : `12px`)};
  position: relative;
`;

const Progress = styled.div`
  background-color: ${(props) =>
    props.whereOnTrack === "good"
      ? props.theme.colors.green
      : props.whereOnTrack === "under"
      ? props.theme.colors.yellow
      : props.theme.colors.red};
  height: 100%;
  width: ${(props) =>
    props.percentage === "over" ? "100" : props.percentage}%;
`;

const PaceLine = styled.div`
  position: absolute;
  top: 50%;
  left: ${(props) => props.percentage}%;
  transform: translateY(-50%);
  width: 1px;
  height: ${(props) => (props.tall ? `32px` : `18px`)};
  background-color: ${(props) => props.theme.colors.darkSlate};
`;

ProgressBar.propTypes = {
  actual: PropTypes.number,
  budgeted: PropTypes.number,
  paceLow: PropTypes.number,
  paceHigh: PropTypes.number,
};

ProgressBar.defaultProps = {
  actual: 0,
  budgeted: 0,
  paceLow: 0,
  paceHigh: 0,
};

export default ProgressBar;
