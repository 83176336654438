import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import { respondTo } from "../../styles/styleHelpers";
import { useNotifications } from "../../context/notificationsContext";
import {
  getContactFromApi,
  updateContactOnApi,
  updateClientOnApi,
  getClientsFromApi,
  deleteContactsOnApi,
} from "../../utils/api";

import DealHeader from "./components/singleDealHeader";
import ContactDetails from "./components/singleContactDetails";
import ContactNotes from "./components/singleContactNotes";

const SingleContact = () => {
  const history = useHistory();
  const {
    setLoading,
    openAlertPopup,
    openPromptPopup,
    closePromptPopup,
  } = useNotifications();

  const { contactId } = useParams();
  const [contact, setContact] = useState(null);
  const [availCompanies, setAvailCompanies] = useState([]);

  useEffect(() => {
    if (contactId) {
      getContact();
    }
  }, [contactId]);

  const getContact = async () => {
    setLoading(true);

    const newCompanies = await getClientsFromApi();
    setAvailCompanies(newCompanies);

    const tempContact = await getContactFromApi(contactId);

    setContact(tempContact);
    setLoading(false);
  };

  const handleDetailsEdit = async (
    updatedProps,
    context = "contact",
    secondaryContext = null,
  ) => {
    if (!contact) return;

    try {
      const tempContact = { ...contact };

      // get the keys for the new props
      let keys = Object.keys(updatedProps);
      let formattedProps = { ...updatedProps };

      switch (context) {
        case "contact":
          // Update the deal

          if (keys) {
            keys.forEach((key) => {
              if (secondaryContext === "contactInfo") {
                formattedProps = {
                  contactInfo: { ...tempContact.contactInfo, ...updatedProps },
                };
                keys = ["contactInfo"];
              } else if (secondaryContext === "company") {
                const newCompany = availCompanies.find(
                  (company) => company._id === formattedProps[key],
                );

                if (newCompany) {
                  tempContact["company"] = newCompany;
                }
              }
            });

            if (secondaryContext !== "company") {
              keys.forEach((key) => {
                tempContact[key] = formattedProps[key];
              });
            }
          }

          await updateContactOnApi(contactId, formattedProps);
          break;

        case "company":
          // Update the company

          // If these are supposed to be in info, bring in the original info obj
          // ? If you don't do this, it will wipe all the info fields
          if (secondaryContext === "info") {
            formattedProps = {
              info: { ...tempContact.company.info, ...updatedProps },
            };
            keys = ["info"];
          }

          // Has to add database parent obj to prop because that's what server is expecting
          await updateClientOnApi(contact.company._id, {
            database: formattedProps,
          });

          // Attaches any props to the deal's company and updates
          if (keys) {
            keys.forEach((key) => {
              tempContact.company[key] = formattedProps[key];
            });
          }

          break;
      }

      setContact(tempContact);
    } catch (err) {
      console.error(`Something went wrong with editing the ${context}`, err);

      const errorMessage = typeof err === "string" ? err : "";

      openAlertPopup(
        `Update ${context} failed`,
        `Something went wrong while editing the ${context}. ${errorMessage}`,
      );
    }
  };

  const handleDelete = async () => {
    openPromptPopup({
      header: "Delete contact",
      body: "Are you sure you want to delete the selected contact?",
      confirmCallback: async () => {
        try {
          await deleteContactsOnApi({ contacts: [contactId] });
          openAlertPopup("Contact deleted successfully!", null, true);
          closePromptPopup();
          history.push("/sales/contacts");
        } catch (err) {
          openAlertPopup("Delete contact failed", "Please try again");
        }
      },
      cancelText: "Cancel",
      confirmText: "Delete",
    });
  };

  const menuItems = [
    {
      handleClick: () => handleDelete(),
      text: "Delete",
      danger: true,
    },
  ];

  return (
    <Container>
      {contact ? (
        <>
          <DealHeader
            title={`${contact.firstName} ${contact.lastName}`}
            breadcrumbs={[
              { name: "Sales", link: "/sales" },
              { link: "/sales/contacts", name: "Contacts" },
              {
                name: `${contact.firstName} ${contact.lastName}`,
              },
            ]}
            statusText={contact?.archived ? "Archived" : null}
            created={contact.createdAt}
            updated={contact.updatedAt}
            menuItems={menuItems}
          />
          <Main>
            <LeftCol>
              <ContactDetails
                id={contactId}
                handleEdit={handleDetailsEdit}
                name={contact.company.name}
                code={contact.company.acronym}
                tier={contact.company.tier}
                title={contact.title}
                division={contact.division}
                roleDescription={contact.roleDescription}
                officeAddress={
                  contact.company?.info?.address1 ? (
                    <>
                      {contact.company.info.address1}
                      <br />
                      {contact.company.info.address2 ? (
                        <>
                          {contact.company.info.address2}
                          <br />
                        </>
                      ) : null}
                      {contact.company.info.city}, {contact.company.info.state}{" "}
                      {contact.company.info.zipcode}
                    </>
                  ) : (
                    "N/A"
                  )
                }
                tenure={contact.tenure}
                preferredContact={contact.preferredContact}
                linkedin={contact.linkedin}
                kpiResponsible={contact.kpiResponsible}
                painsGains={contact.painsGains}
                success={contact.success}
                reportingStructure={contact.reportingStructure}
                roleWithinPartnership={contact.roleWithinPartnership}
                reviewerApprover={contact.reviewerApprover}
                personalContactInfo={contact.personalContactInfo}
                additionalNotes={contact.additionalNotes}
                amNotes={contact.amNotes}
                industry={
                  contact.company?.industry ? contact.company.industry : ""
                }
                contactName={`${contact.firstName} ${contact.lastName}`}
                phone={
                  contact.contactInfo?.phone ? contact.contactInfo.phone : "N/A"
                }
                email={
                  contact.contactInfo?.email ? contact.contactInfo.email : "N/A"
                }
                address={
                  contact.contactInfo?.address1 ? (
                    <>
                      {contact.contactInfo.address1}
                      <br />
                      {contact.contactInfo.address2 ? (
                        <>
                          {contact.contactInfo.address2}
                          <br />
                        </>
                      ) : null}
                      {contact.contactInfo.city}, {contact.contactInfo.state}{" "}
                      {contact.contactInfo.zipcode}
                    </>
                  ) : (
                    "N/A"
                  )
                }
                availCompanies={
                  availCompanies && availCompanies.length > 0
                    ? availCompanies
                    : []
                }
              />
            </LeftCol>
            <RightCol>
              <ContactNotes
                contactId={contact._id}
                initialNotes={contact.notes}
                blockedBy={contact?.blockedBy ? contact.blockedBy : null}
              />
            </RightCol>
          </Main>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  padding: 27px 40px 75px;
  gap: 20px;
`;

const Col = styled.div`
  height: 100%;
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const LeftCol = styled(Col)`
  flex: 0 1 40%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
  }
`;

const RightCol = styled(Col)`
  flex: 0 1 60%;

  ${respondTo("xlarge")} {
    flex: 0 1 50%;
  }
`;
export default SingleContact;
