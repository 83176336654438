import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { buttonReset } from "../../styles/styleHelpers";
import ChatBubbles from "../icons/ChatBubbles";

import ProfileImageGroup from "../ProfileImageGroup";

const ChatButton = ({ members, ...rest }) => {
  // active state for when there are unread messages
  const isActive = members.length;

  return (
    <Button isActive={isActive} {...rest}>
      <Inner isActive={isActive}>
        <Content isActive={isActive}>
          <ButtonText>Chat</ButtonText>
          <ChatBubbles />
        </Content>

        <Avatars isActive={isActive}>
          <ProfileImageGroup members={members} plusColor="indigo800" xsmall />
        </Avatars>
      </Inner>
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset()}
  position: relative;

  display: flex;
  flex-direction: column;

  color: ${(props) =>
    props.isActive ? "white" : props.theme.colors.darkerGray};

  transition: 200ms;

  /* background */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    background-color: ${(props) =>
      props.isActive ? props.theme.colors.indigo : "white"};
    box-shadow: ${(props) => props.theme.boxShadow_m};
    border-radius: 4px;

    transform: ${(props) => (props.isActive ? "scaleX(1.15)" : "scaleX(1)")};

    transition: 200ms;
  }

  &:hover,
  &:focus-visible {
    &::before {
      background-color: ${(props) =>
        props.isActive
          ? props.theme.colors.indigo700
          : props.theme.colors.indigo50};
    }
  }

  svg {
    path {
      stroke: ${(props) =>
        props.isActive ? "white" : props.theme.colors.darkerGray};
      transition: stroke 200ms;
    }
  }
`;

const Inner = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding: 10px;

  min-height: ${(props) => (props.isActive ? "85px" : "40px")};

  transition: min-height 200ms;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: ${(props) => (props.isActive ? 0 : 1)};
  transition: flex 200ms;
`;

const ButtonText = styled.span`
  font-size: 20px;
  font-weight: 500;

  &:not(:last-child) {
    margin-right: 12px;
  }
`;

const Avatars = styled.div`
  display: flex;

  position: absolute;
  left: 50%;
  bottom: 0;

  transform: ${(props) =>
    props.isActive
      ? "translateX(-50%) translateY(-15px)"
      : "translateX(-50%) translateY(100%)"};

  transition: transform 200ms;
`;

ChatButton.propTypes = {
  members: PropTypes.array,
};
ChatButton.defaultProps = {
  members: [],
};

export default ChatButton;
