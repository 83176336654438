import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { visuallyHidden } from "../../../styles/styleHelpers";
import { capitalize, getCurrency } from "../../../utils/helpers";

const ProjectRates = ({ rates, selectedRate, handleSelection }) => {
  return (
    <Rates>
      {Object.entries(rates).map(([rateType, rateTiers]) => (
        <RateColumn key={rateType} isActive={selectedRate === rateType}>
          <RateInput
            name="projectDesignation"
            type="radio"
            onChange={() => {
              handleSelection(rateType);
            }}
            checked={selectedRate === rateType}
          />

          <RateColumnLabel>{capitalize(rateType)}</RateColumnLabel>

          {Object.entries(rateTiers).map(([rateTier, rateAmount]) => (
            <Rate key={`${rateType}-${rateTier}`}>
              <div>{capitalize(rateTier)}</div>

              <strong>{getCurrency(rateAmount)}</strong>
            </Rate>
          ))}
        </RateColumn>
      ))}
    </Rates>
  );
};

const Rates = styled.div`
  display: flex;
  gap: 30px;
  padding-top: 10px;
`;

const RateColumn = styled.label`
  position: relative;
  flex-grow: 1;
  flex-basis: 0%;
  cursor: pointer;

  opacity: ${(props) => (props.isActive ? 1 : 0.5)};

  transition: opacity 200ms;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -15px;
    right: -15px;
    bottom: -10px;

    background-color: ${(props) =>
      props.isActive ? props.theme.colors.cardBackground : "transparent"};
    border: 1px solid transparent;

    z-index: -1;
    pointer-events: none;
    transition: background-color 200ms, border-color 200ms;
  }

  &:hover {
    opacity: 1;

    &::before {
      border-color: ${(props) =>
        props.isActive ? "transparent" : props.theme.colors.blue};
    }
  }
`;

const RateColumnLabel = styled.div`
  border-bottom: 1px solid;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
`;

const RateInput = styled.input`
  ${visuallyHidden()};
`;

const Rate = styled.div`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  text-align: center;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    transition: color 200ms;
  }

  ${RateInput}:checked ~ & {
    strong {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

ProjectRates.propTypes = {
  rates: PropTypes.object,
  selectedRate: PropTypes.string,
  handleSelection: PropTypes.func,
};

export default ProjectRates;
