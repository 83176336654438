import React from "react";
import styled from "styled-components";

import HappyFaceIcon from "../../../components/icons/HappyFaceIcon";

const MessageSection = ({ name }) => {
  return (
    <>
      <MessageHeader>
        <HappyFaceIcon />
        Good morning{name ? ` ${name}` : ``}!
      </MessageHeader>
      <MessageBody>
        It’s great to have you here today. Keep an eye on this area for new
        messages and features.{" "}
        <span role="img" aria-label="A present emoji">
          🎁
        </span>
      </MessageBody>
    </>
  );
};

const MessageHeader = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;

  color: ${(props) => props.theme.colors.darkSlate};

  svg {
    padding-bottom: 2px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const MessageBody = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;

  color: ${(props) => props.theme.colors.darkSlate};
`;

export default MessageSection;
