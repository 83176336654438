import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Switch from "../../../components/Switch";
import { CaretLeft, CaretRight } from "../../../components/buttons/Carets";

const WeekSettings = ({
  id,
  onText,
  offText,
  isActive,
  weekDuration,
  handleSwitch,
  handlePrevWeek,
  handleNextWeek,
  disableSwitch,
}) => {
  return (
    <Container>
      <Duration>{weekDuration}</Duration>
      <PreviousWeek onClick={handlePrevWeek}></PreviousWeek>
      <NextWeek onClick={handleNextWeek}></NextWeek>

      {!disableSwitch ? (
        <Switch
          id={id}
          onText={onText}
          offText={offText}
          isActive={isActive}
          handleSwitch={handleSwitch}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Duration = styled.p`
  font-size: ${(props) => props.theme.fontSize_s};
  font-weight: 300;
  color: ${(props) => props.theme.colors.darkSlate};
  margin: 0px 24px 0px 0px;
  //width: 170px;
`;

const PreviousWeek = styled(CaretLeft)``;

const NextWeek = styled(CaretRight)``;

// const Arrow = styled.button`
//   background-color: transparent;
//   border-top: 1px solid black;
//   border-left: 1px solid black;
//   border-right: 0px;
//   border-bottom: 0px;
//   cursor: pointer;
//   outline: none;
//   height: 10px;
//   width: 10px;
//   transition: 0.3s ease-in-out;
//   position: relative;

//   &::after {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -50%;
//     left: -50%;
//     height: 150%;
//     width: 150%;
//   }
// `;

// const PreviousWeek = styled(Arrow)`
//   transform: rotate(-45deg);
//   margin-right: 5px;

//   &:hover,
//   &:focus {
//     transform: scale(0.8) rotate(-45deg);
//   }

//   &::after {
//     transform: rotate(45deg);
//   }
// `;

// const NextWeek = styled(Arrow)`
//   transform: rotate(135deg);
//   margin-right: 25px;

//   &:hover,
//   &:focus {
//     transform: scale(0.8) rotate(135deg);
//   }

//   &::after {
//     transform: rotate(-135deg);
//   }
// `;

WeekSettings.propTypes = {
  id: PropTypes.string.isRequired,
  onText: PropTypes.string,
  offText: PropTypes.string,
  isActive: PropTypes.bool,
  weekDuration: PropTypes.string,
  handleSwitch: PropTypes.func,
  handlePrevWeek: PropTypes.func,
  handleNextWeek: PropTypes.func,
};

WeekSettings.defaultProps = {
  onText: "On",
  offText: "Off",
  isActive: false,
  weekDuration: "0",
  handleSwitch: null,
  handlePrevWeek: null,
  handleNextWeek: null,
};

export default WeekSettings;
