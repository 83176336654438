import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";
import ProfileImage from "./ProfileImage";

const Expense = ({ author, postedDate, amount, message, ...rest }) => {
  return (
    <Container {...rest}>
      <ProfileImage
        xsmall
        handle={author.handle}
        name={author.name}
        showStatus
      />
      <Main>
        <Row>
          <Col>
            <Name>{author.handle}</Name>
            <PostedDate>
              {format(new Date(postedDate), "MMM d - h:mma")}
            </PostedDate>
          </Col>

          <Amount>${amount.toLocaleString()}</Amount>
        </Row>
        <Message
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></Message>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 30px;
  max-width: 320px;
`;

const Main = styled.div`
  margin-left: 17px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Col = styled.div``;

const Name = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 2px;
`;

const PostedDate = styled.p`
  color: #9796b7;
  margin-bottom: 0px;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  line-height: 16px;

  color: ${(props) => props.theme.colors.coolGray400};
`;

const Amount = styled.p`
  margin-bottom: 0px;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.colors.coolGray700};
`;

const Message = styled.p`
  margin-bottom: 0px;
  word-break: break-all;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};

  .highlighted {
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 3px;
    margin-right: 3px;
    color: ${(props) => props.theme.colors.blue2};
    background-color: ${(props) => props.theme.colors.lightBlue};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-left: 0;
    }
  }
`;

Expense.propTypes = {
  author: PropTypes.object,
  postedDate: PropTypes.string,
  amount: PropTypes.number,
  message: PropTypes.string,
};

export default Expense;
