import React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import Tabs, { TabContent } from "../../components/Tabs";
import PageLayout from "./components/pageLayout";
import { buttonReset } from "../../styles/styleHelpers";

import { FilterGroup } from "../../components/NewTable/Filters";

import SalesDashboard from "./dashboard";
import Deals from "./deals";
import SingleDeal from "./singleDeal";
import Contacts from "./contacts";
import SingleContact from "./singleContact";
import Companies from "./companies";
import SingleCompany from "./singleCompany";

const Sales = () => {
  const match = useRouteMatch();

  const tabs = [
    {
      name: "Dashboard",
      id: "dashboard",
      to: "/sales",
      exact: true,
    },
    {
      name: "Contacts",
      id: "contacts",
      to: "/sales/contacts",
      exact: true,
    },
    {
      name: "Companies",
      id: "companies",
      to: "/sales/companies",
      exact: true,
    },
    {
      name: "Deals",
      id: "deals",
      to: "/sales/deals",
      exact: true,
    },
  ];

  return (
    <PageLayout title="Sales">
      <Header>
        <Tabs tabs={tabs} />
      </Header>

      <Switch>
        <Route
          exact
          path={`${match.path}/contacts`}
          render={(props) => <Contacts {...props} />}
        />
        <Route
          exact
          path={`${match.path}/contacts/:contactId`}
          render={(props) => <SingleContact {...props} />}
        />

        <Route
          exact
          path={`${match.path}/companies`}
          render={(props) => <Companies {...props} />}
        />
        <Route
          exact
          path={`${match.path}/companies/:companyId`}
          render={(props) => <SingleCompany {...props} />}
        />

        <Route
          exact
          path={`${match.path}/deals`}
          render={(props) => <Deals {...props} />}
        />
        <Route
          exact
          path={`${match.path}/deals/:dealId`}
          render={(props) => <SingleDeal {...props} />}
        />
        <Route
          exact
          path={match.path}
          render={(props) => <SalesDashboard {...props} />}
        />
      </Switch>
    </PageLayout>
  );
};

const Header = styled.div`
  padding: 0 40px;
`;

const TabContentSection = styled.div`
  width: 100%;
`;

const Dashboard = styled.div`
  display: flex;
  gap: 15px;
  min-height: 500px;
  width: 100%;
`;

const Col = styled.div`
  flex: 0 0 50%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  background-color: #fff;
  padding: 30px;
  overflow: auto;
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  z-index: 6;
  margin-bottom: 16px;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const TableContainer = styled.div`
  margin-top: 24px;

  table {
    [data-name-col] {
      min-width: 150px;
    }
  }
`;

const PipeHeader = styled.div`
  width: 100%;
  display: flex;
`;

const PipeHeaderText = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.theme.colors.coolGray800};

  margin-right: 24px;
  margin-bottom: 0;
`;

const PipeHeaderButton = styled.button`
  ${buttonReset()}
  padding: 4px 8px;
  background: ${(props) =>
    props.active
      ? props.theme.colors.indigo100
      : props.theme.colors.coolGray200};
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.indigo600
      : props.theme.colors.coolGray700};

  &:not(:last-child) {
    margin-right: 15px;
  }
`;

const PipeList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 26px;
`;

const PipeGroup = styled.div`
  width: 100%;
  height: 103px;
  display: flex;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${(props) =>
    props.split ? props.theme.colors.red100 : "transparent"};

  &:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const PipeBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: ${(props) =>
    props.width > 100
      ? "100"
      : props.width < 1 || !props.width
      ? ".3"
      : props.width}%;

  background-color: ${(props) =>
    props.shade === 0
      ? props.theme.colors.green50
      : props.shade === 1
      ? props.theme.colors.green100
      : props.shade === 2
      ? props.theme.colors.green200
      : props.shade === 3
      ? props.theme.colors.green300
      : props.shade === 4
      ? props.theme.colors.green400
      : props.theme.colors.green500};
  z-index: -1;
`;

const PipeText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
`;

const PipeLostText = styled(PipeText)`
  margin-left: auto;
  align-items: flex-end;
`;

const PipeStatus = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;

  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

const PipeValue = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

const PipeTotal = styled.p`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.coolGray800};
  margin-bottom: 0;
`;

export default Sales;
