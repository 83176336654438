import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import styled from "styled-components";
import { rem } from "polished";

import mainLogo from "../../images/logo-login.png";

import { refreshTokenSetup } from "../../utils/refreshTokenSetup";
import { useAuth } from "../../context/authContext";

const Login = () => {
  const { login, user } = useAuth();

  const [hasFailed, setHasFailed] = useState(false);

  const history = useHistory();

  // * HOTFIX:
  // * When google auth redirects, it keeps redirecting back
  // * to login (for some reason), so if user is logged in,
  // * we just push them to dashboard
  useEffect(() => {
    if (user) {
      history.push(`/`);
    }
  }, [user, history]);

  const onSuccess = async (res) => {
    try {
      console.info("[Login Success]");
      const google_token = res.getAuthResponse().id_token;

      await login(res.profileObj.email, google_token);
      setHasFailed(false);
      refreshTokenSetup(res);
      history.push(`/`);
    } catch (err) {
      setHasFailed(true);
      console.error(err);
    }
  };

  const onFailure = (res) => {
    console.error("[LOGIN FAILED]");
    console.error(res);
    setHasFailed(true);
  };

  return (
    <LoginContainer>
      <img src={mainLogo} alt="logo" />
      <LoginHeader>Project Sherpa</LoginHeader>
      <StyledGoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        style={{ marginTop: "50px" }}
      />
      {hasFailed ? (
        <LoginError>
          Please use the email associated with your Big Vision account
        </LoginError>
      ) : null}
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    width: 120px;
    margin-bottom: 30px;
  }
`;

const LoginHeader = styled.h1`
  margin-bottom: 50px;
  font-size: 40px;
  color: ${(props) => props.theme.colors.slate};
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 60px;
`;

const LoginError = styled.div`
  width: 100%;
  //max-width: ${rem("700px")};
  font-size: 40px;
  padding: 30px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  color: #ff0033;
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  border: 1px solid ${(props) => props.theme.colors.slate} !important;
  border-radius: 12px !important;
  opacity: 1 !important;
  transition: 0.3s ease-in-out !important;
  font-size: 16px !important;
  height: 50px !important;
  outline: none !important;
  line-height: 0 !important;
  box-shadow: none !important;
  min-width: 150px;
  display: flex;
  justify-content: center;

  & > div {
    display: none;
  }

  span {
    transition: 0.3s ease-in-out !important;
    padding: 5px 10px !important;
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.blue} !important;
    border-color: ${(props) => props.theme.colors.blue};

    span {
      color: white !important;
    }
  }
`;

export default Login;
