import React from "react";
import styled from "styled-components";

const PaginationLeftIcon = ({ ...props }) => {
  return (
    <Svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.78247 11.908L6.28247 8.40796L9.78247 4.90796"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="9.02344"
        cy="8.40796"
        r="7.625"
        stroke="white"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default PaginationLeftIcon;
