import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { eachDayOfInterval, isSameDay } from "date-fns";
import { setDay, getWeek, getStandardizedDate } from "../../../utils/helpers";
import { useAuth } from "../../../context/authContext";
import {
  getMemberEntriesFromApi,
  getWeekProjectsFromApi,
  createEntryOnApi,
  updatePastEntryFromApi,
} from "../../../utils/api";
import FinishEdit from "./FinishEdit";
import EditIcon from "../../../components/icons/EditIcon";
import Button from "../../../components/newButtons/Button";
import CloseButton from "../../../components/buttons/CloseButton";
import format from "date-fns/format";
import PlusIcon from "../../../components/icons/PlusIcon";
import { buttonReset, respondTo } from "../../../styles/styleHelpers";

const FinishWeekModal = ({ finishWeek, close }) => {
  const { user } = useAuth();
  const [daysData, setDaysData] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [availProjects, setAvailProjects] = useState([]);

  // Sets up needed date objects
  const today = new Date();
  const startOfWeek = setDay(today, 1);
  const endOfWeek = setDay(today, 0);
  const weekNumber = getWeek(today);
  const currentYear = new Date(today).getFullYear();

  useEffect(() => {
    if (!selectedEntry) {
      setupDays();
      setupAvail();
    }
  }, [selectedEntry]);

  const setupDays = async () => {
    let newDaysData = [];

    const daysOfWeek = eachDayOfInterval({
      start: startOfWeek,
      end: endOfWeek,
    });

    newDaysData = daysOfWeek.map((date) => {
      return {
        date,
        entries: [],
      };
    });

    newDaysData = await getEntries(newDaysData);
    setDaysData(newDaysData);
  };

  const setupAvail = async () => {
    const tempProjects = await getWeekProjectsFromApi();

    const filteredProjects = tempProjects.filter((project) => {
      let show = false;

      if (project.stages?.brief?.completed && !project.archived) {
        if (project.bookingPlan) {
          project.bookingPlan.forEach((plan) => {
            plan.weeks.forEach((item) => {
              const planWeekNumber = getWeek(new Date(item.weekDate));

              const planYear = new Date(
                getStandardizedDate(item.weekDate),
              ).getFullYear();

              if (planWeekNumber === weekNumber && planYear === currentYear) {
                show = true;
              }
            });
          });
        }
      }

      return show;
    });

    setAvailProjects(filteredProjects);
  };

  const getEntries = async (oldData) => {
    const result = await getMemberEntriesFromApi(
      user.harvestId,
      format(startOfWeek, "yyyy-MM-dd"),
      format(endOfWeek, "yyyy-MM-dd"),
    );

    const newEntries = result.data.data.result;

    const newDayData = oldData.map((day) => {
      const newDayEntries = [];

      if (newEntries && newEntries.length > 0) {
        newEntries.forEach((entry) => {
          if (
            isSameDay(getStandardizedDate(new Date(entry.spent_date)), day.date)
          ) {
            newDayEntries.push(entry);
          }
        });
      }

      let lastEntryEnd = "";
      let totalHours = 0;
      const emptyEntries = [];

      newDayEntries.sort((a, b) => {
        const aTime = convertAMPMTo24Hr(a.started_time);
        const bTime = convertAMPMTo24Hr(b.started_time);

        return aTime > bTime ? 1 : -1;
      });

      for (let i = 0; i < newDayEntries.length; i++) {
        totalHours += newDayEntries[i].rounded_hours;

        if (
          lastEntryEnd !== "" &&
          lastEntryEnd !== newDayEntries[i].started_time
        ) {
          emptyEntries.push({
            empty: true,
            started_time: lastEntryEnd,
            ended_time: newDayEntries[i].started_time,
          });
        }

        lastEntryEnd = newDayEntries[i].ended_time;
      }

      const sortedEntries = [...newDayEntries, ...emptyEntries].sort((a, b) => {
        const aTime = convertAMPMTo24Hr(a.started_time);
        const bTime = convertAMPMTo24Hr(b.started_time);

        return aTime > bTime ? 1 : -1;
      });

      return {
        ...day,
        entries: sortedEntries,
        totalHours,
        missingHours: totalHours >= 8 ? 0 : 8 - totalHours,
      };
    });

    return newDayData;
  };

  const convertAMPMTo24Hr = (oldString) => {
    let hours = Number(oldString.match(/^(\d+)/)[1]);
    const minutes = Number(oldString.match(/:(\d+)/)[1]);
    const isAm = oldString.match(/am$/);
    if (!isAm && hours < 12) hours = hours + 12;
    if (isAm && hours == 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
  };

  const convertNumToHoursAndMinutes = (val) => {
    let hours = Math.floor(val);
    let minutes = (val - hours) * 60;

    hours = hours.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    minutes = minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return hours + ":" + minutes;
  };

  const generateColor = (text) => {
    const colors = ["green", "blue", "teal", "purple", "violet"];

    const lengthOfName = text.length;

    let pickedColor = colors[0];

    // Sees what the length of their full name is divisible by to decide what color they get
    colors.forEach((color, index) => {
      if (lengthOfName % (index + 1) === 0) {
        pickedColor = colors[index];
      }
    });

    return pickedColor;
  };

  const updateEntry = async (data) => {
    try {
      const response = await updatePastEntryFromApi(data);

      if (response && response.result) {
        setSelectedEntry(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createEntry = async (data) => {
    try {
      const response = await createEntryOnApi(data);

      if (response && response.result) {
        setSelectedEntry(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Close onClick={close} />
      <Header>Finish My Week</Header>
      <Main>
        {daysData.map((day, index) => (
          <Day key={index}>
            <DayHeader>
              <DayDate>{format(day.date, "EEEE - MM/dd")} </DayDate>
              <DayHours>{convertNumToHoursAndMinutes(day.totalHours)}</DayHours>
            </DayHeader>
            <EntriesCol>
              {day.entries.length > 0
                ? day.entries.map((entry, index) =>
                    entry.empty ? (
                      <EmptyEntry key={index}>
                        No Time Submitted{" "}
                        <EditButton
                          onClick={() =>
                            setSelectedEntry({
                              ...entry,
                              spent_date: day.date,
                              new: true,
                            })
                          }
                        >
                          <EditIcon />
                        </EditButton>
                      </EmptyEntry>
                    ) : (
                      <Entry
                        key={index}
                        color={
                          entry.project.name === "Personal Time"
                            ? "grey"
                            : generateColor(
                                `${entry.project.code}: ${entry.project.name}`,
                              )
                        }
                      >
                        <EditButton onClick={() => setSelectedEntry(entry)}>
                          <EditIcon />
                        </EditButton>
                        <EntryTitle>
                          {entry.project.code}: {entry.project.name}
                        </EntryTitle>
                        <EntryRow>
                          <EntryTime>
                            {convertNumToHoursAndMinutes(entry.rounded_hours)}
                          </EntryTime>
                          <EntryStamps>
                            {entry.started_time}-{entry.ended_time}
                          </EntryStamps>
                        </EntryRow>
                      </Entry>
                    ),
                  )
                : null}
              {day.missingHours > 0 ? (
                <EmptyEntry>
                  No Time Submitted{" "}
                  <EditButton
                    onClick={() =>
                      setSelectedEntry({ spent_date: day.date, new: true })
                    }
                  >
                    <EditIcon />
                  </EditButton>
                </EmptyEntry>
              ) : null}
            </EntriesCol>
          </Day>
        ))}
      </Main>
      <ButtonRow>
        <Button
          icon={<PlusIcon />}
          onClick={() => setSelectedEntry({ new: true })}
          light
        >
          New Entry
        </Button>
        <Button onClick={() => finishWeek()}>Finish My Week</Button>
      </ButtonRow>
      {selectedEntry ? (
        <FinishEdit
          entry={selectedEntry}
          weekNumber={weekNumber}
          availProjects={availProjects}
          handleSubmit={selectedEntry.new ? createEntry : updateEntry}
          close={() => setSelectedEntry(null)}
        />
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  min-width: 500px;
  padding: 28px 27px;
  position: relative;
  z-index: 1;
  transform: scale(0.75);

  ${respondTo("xlarge")} {
    transform: none;
  }
`;

const Header = styled.h3`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 24px;
`;

const Close = styled(CloseButton)`
  position: absolute;
  top: 30px;
  right: 27px;
`;

const Main = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
`;

const Day = styled.div`
  flex: 1;
`;

const DayHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 11px;
  border-bottom: 1px solid ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 11px;
`;

const DayDate = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray500};
  margin-bottom: 0;
`;

const DayHours = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.coolGray700};
  margin-bottom: 0;
`;

const EntriesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;
  min-height: 570px;
  overflow-y: auto;
`;

const EditButton = styled.button`
  ${buttonReset()}
  position: absolute;
  top: 11px;
  right: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
`;

const Entry = styled.div`
  position: relative;
  padding: 15px 13px;
  border-radius: 4px;
  background: ${(props) =>
    props.color === "green"
      ? props.theme.colors.green50
      : props.color === "teal"
      ? props.theme.colors.teal50
      : props.color === "purple"
      ? props.theme.colors.purple50
      : props.color === "violet"
      ? props.theme.colors.violet50
      : props.color === "grey"
      ? props.theme.colors.coolGray50
      : props.theme.colors.blue50};
  border: 1px solid ${(props) => props.theme.colors.coolGray200};
  flex: 1;
  min-height: 60px;

  &:hover {
    ${EditButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const EntryText = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: ${(props) => props.theme.colors.coolGray700};
`;

const EntryTitle = styled(EntryText)`
  margin-bottom: 7px;
  padding-right: 44px;
`;

const EntryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EntryTime = styled(EntryText)`
  margin-bottom: 0;
`;

const EntryStamps = styled(EntryText)`
  margin-bottom: 0;
`;

const EmptyEntry = styled(Entry)`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: ${(props) => props.theme.colors.red50};
  border: 1px dashed ${(props) => props.theme.colors.red400};
  color: ${(props) => props.theme.colors.red400};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding-right: 13px;

  &:hover {
    ${EditButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export default FinishWeekModal;
