import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";
import { differenceInMinutes, format } from "date-fns";

const Tasks = ({ tasks, groups }) => {
  return (
    <List>
      {groups.map((group) => (
        <>
          <Accordion
            key={group.id}
            as={groups.length === 1 ? "div" : undefined}
          >
            {/* only show the summary if there's more than 1 group */}
            {groups.length > 1 ? <summary>{group.title}</summary> : null}

            {/* show the tasks that correspond to this group (each group is a member) */}
            {tasks
              .filter((task) => {
                return task?.member?._id === group.id;
              })
              .map((task, index) => {
                // duration as a number
                const durationHours = task.hours < 1 ? task.hours : 1;

                // duration as a string
                // if the task has less than 1hr budgetted, use that value. Otherwise, use 1hr
                const durationFormatted =
                  task.hours < 1 ? `00:${task.hours * 60}` : "01:00";

                let scheduled = 0;

                task.breakdown.forEach((timeSlot) => {
                  // get the difference in minutes
                  const diff = differenceInMinutes(
                    new Date(timeSlot.end),
                    new Date(timeSlot.start),
                  );
                  // divide by 60 to convert it to hours
                  scheduled += diff / 60;
                });

                // remaining hours as a number
                const remainingHours = task.hours - scheduled;

                // remaining hours as a string
                const remainingFormatted =
                  remainingHours < 1 ? `00:${remainingHours * 60}` : "01:00";

                // round down to the nearest hour (and pad with a 0 in case the number isn't 2 digits)
                const remainingHoursRounded = `${Math.floor(remainingHours)}`;
                const remainingHoursRoundedString = remainingHoursRounded.padStart(
                  2,
                  "0",
                );

                // get the decimal of hours remaining and multiply by 60 to get the minutes (and pad with a 0 in case the number isn't 2 digits)
                const remainingMin = `${(remainingHours % 1) * 60}`;
                const remainingMinString = remainingMin.padStart(2, "0");

                const hasError = remainingHours < 0;

                // data expected by the calendar to create an event
                const eventData = {
                  // don't let the calendar automatically create the event. We'll handle it ourselves so all the events just exist in our state
                  create: false,
                  id: task.id,
                  title: task.name,
                  code: task.code,
                  // if the default duration is less than the remaining budgetted hours, use the default duration. Otherwise, use the remaining amount of budgetted time
                  duration:
                    durationHours <= remainingHours
                      ? durationFormatted
                      : remainingFormatted,
                  resourceId: task.member._id,
                };
                const eventDataAttr = JSON.stringify(eventData);

                return remainingHours !== 0 ? (
                  <Item
                    key={index}
                    hasError={hasError}
                    // attribute must be "data-event" so the calendar can grab the data
                    data-event={hasError ? undefined : eventDataAttr}
                    title={
                      hasError
                        ? `Too much time scheduled. Please remove ${Math.abs(
                            remainingHours,
                          )} hour${Math.abs(remainingHours) > 1 ? "s" : ""}`
                        : undefined
                    }
                  >
                    <Details>
                      <Title>{task.code}</Title>
                      <Subtitle>{task.name}</Subtitle>
                      {task.project.endDate ? (
                        <Time>
                          <strong>Due</strong>{" "}
                          {format(
                            new Date(task.project.endDate),
                            "MMMM do, yyyy",
                          )}
                        </Time>
                      ) : null}
                    </Details>

                    <Time>
                      {remainingHoursRoundedString}:{remainingMinString}
                    </Time>
                  </Item>
                ) : null;
              })}

            <AllDone>
              Looks like {groups.length === 1 ? "your" : `${group.title}'s`}{" "}
              schedule is all set!
            </AllDone>
          </Accordion>
        </>
      ))}
    </List>
  );
};

const List = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  padding: 0 15px;
  margin: 0;
  list-style-type: none;
  overflow: auto;
`;

const Item = styled.li`
  display: flex;
  justify-content: space-between;

  border-radius: 8px;
  border: 3px solid;
  border-color: ${(props) => props.theme.colors.oldLightGray};
  background-color: #fff;
  box-shadow: 4px 8px 14px ${rgba("#b9bcc0", 0.3)};

  padding: 7px 10px;
  padding-left: 13px;
  margin-bottom: 5px;

  cursor: ${(props) => (props.hasError ? "default" : "grab")};

  &:active {
    cursor: ${(props) => (props.hasError ? "default" : "grabbing")};
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const Time = styled.div`
  font-size: 12px;

  strong {
    font-weight: 600;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.25;

  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AllDone = styled.li`
  display: none;

  &:first-of-type {
    display: block;
  }
`;

const Accordion = styled.details`
  margin-bottom: 10px;

  summary {
    color: ${(props) => props.theme.colors.slate};
    font-weight: 500;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
    transition: color 200ms;

    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.oldBlack2};
      outline: none;
    }
  }
`;

Tasks.propTypes = {
  tasks: PropTypes.array,
  groups: PropTypes.array,
};

Tasks.defaultProps = {
  events: [],
  groups: [],
};

export default Tasks;
