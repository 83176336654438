import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";
import { checkOffDeliverableOnApi } from "../../utils/api";

const Checklist = ({ delivId, items }) => {
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    if (items) {
      setDisplayItems(items);
    } else {
      setDisplayItems([]);
    }
  }, [items]);

  const handleCheck = async (itemIndex) => {
    const tempItems = [...displayItems];
    tempItems[itemIndex].done = !tempItems[itemIndex].done;

    await checkOffDeliverableOnApi(delivId, tempItems[itemIndex]._id);

    setDisplayItems(tempItems);
  };

  const countLeft = () => {
    const unfinishedItems = displayItems.filter((item) => !item.done);
    return unfinishedItems.length;
  };

  return (
    <ChecklistContainer>
      <ChecklistHeader>
        <ChecklistBadge>{displayItems ? countLeft() : "0"}</ChecklistBadge>
        Quality Checklist
      </ChecklistHeader>
      <ChecklistWrapper>
        <ChecklistMain>
          {displayItems && displayItems.length > 0 ? (
            displayItems.map((item, index) => (
              <ChecklistItem key={index}>
                <Checkbox
                  checked={item.done}
                  onClick={() => handleCheck(index)}
                />{" "}
                <CheckListTitle>{item.text}</CheckListTitle>
              </ChecklistItem>
            ))
          ) : (
            <NoMessage>No checks needed</NoMessage>
          )}
        </ChecklistMain>
      </ChecklistWrapper>
    </ChecklistContainer>
  );
};

const ChecklistContainer = styled.div`
  flex: 0 0 50%;
  max-height: 50%;
  background: ${(props) => props.theme.colors.coolGray700};
  box-shadow: 0px 2px 4px rgba(31, 41, 55, 0.06),
    0px 4px 6px rgba(31, 41, 55, 0.1);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
`;

const ChecklistHeader = styled.div`
  background: ${(props) => props.theme.colors.coolGray600};
  height: 64px;
  padding: 17px 23px 19px;
  display: flex;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => props.theme.colors.coolGray200};
`;

const ChecklistBadge = styled.div`
  height: 28px;
  background: ${(props) => props.theme.colors.indigo100};
  border-radius: 4px;
  padding: 4px 8px;
  color: ${(props) => props.theme.colors.indigo600};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
`;

const ChecklistWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const ChecklistMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 19px;
  padding-bottom: 19px;
`;

const ChecklistItem = styled.div`
  min-height: 32px;
  padding-left: 24px;
  padding-right: 18px;
  display: flex;
`;

const Checkbox = styled.button`
  ${buttonReset()}
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  border: 2px solid #c8d3fe;
  border-radius: 50%;
  margin-right: 20px;

  background-color: ${(props) =>
    props.checked ? props.theme.colors.indigo600 : "transparent"};
`;

const CheckListTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.coolGray100};
  margin-bottom: 0;
`;

const NoMessage = styled.p`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.pureWhite};
  text-align: center;
  font-size: 12px;
  opacity: 0.7;
`;

export default Checklist;
