import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { buttonReset } from "../../styles/styleHelpers";

const Button = ({
  children,
  small,
  disabled,
  noBorderRadius,
  hollow,
  light,
  icon,
  state,
  ...rest
}) => {
  return (
    <MainButton
      disabled={disabled}
      $isSmall={small ? true : undefined}
      $noBorderRadius={noBorderRadius}
      $isHollow={hollow ? true : false}
      $isLight={light ? true : false}
      $state={state}
      {...rest}
    >
      {icon ? icon : null}
      {children}
    </MainButton>
  );
};

const MainButton = styled.button`
  ${buttonReset()}
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  font-weight: ${(props) => (props.$isLight ? "400" : "500")};
  text-align: center;
  text-decoration: none;

  border-radius: ${(props) => (props.$noBorderRadius ? "0px" : "4px")};
  padding: ${(props) => (props.$isSmall ? "8px 16px" : "12px 16px")};

  color: ${(props) =>
    props.$isLight
      ? props.theme.colors.coolGray700
      : props.$isHollow
      ? props.theme.colors.black
      : props.theme.colors.pureWhite};

  background-color: ${(props) =>
    props.$isHollow || props.$isLight
      ? props.theme.colors.pureWhite
      : props.$state === "success"
      ? props.theme.colors.green500
      : props.$state === "error"
      ? props.theme.colors.red500
      : props.theme.colors.indigo600};

  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

  border: 0.5px solid
    ${(props) =>
      props.$isLight
        ? props.theme.colors.coolGray200
        : props.$isHollow
        ? props.theme.colors.black
        : props.$state === "success"
        ? props.theme.colors.green500
        : props.$state === "error"
        ? props.theme.colors.red500
        : props.theme.colors.indigo600};

  cursor: pointer;
  transition: transform 0.2s;

  &:disabled {
    cursor: default;
    pointer-events: none;
    color: ${(props) => props.theme.colors.slate};
    background-color: ${(props) => props.theme.colors.lightSlate};
    border: 1px solid rgba(140, 131, 145, 0.4);
  }

  ${(props) =>
    !props.disabled
      ? css`
          &::after {
            content: "";
            position: absolute;
            top: -10%;
            left: -10%;
            height: 120%;
            width: 120%;
            background-color: ${props.$isHollow || props.$isLight
              ? props.theme.colors.coolGray100
              : props.$state === "success"
              ? props.theme.colors.green600
              : props.$state === "error"
              ? props.theme.colors.red600
              : props.theme.colors.indigo700};
            z-index: -1;
            transform: translate(-120%, 120%);
            transform-origin: center;
            opacity: 0;
            transition: transform 0.4s, opacity 0.4s;
            border-radius: 30px;
          }

          &:hover,
          &:focus-visible {
            &::after {
              opacity: 1;
              transform: none;
              transition-delay: 0, 0.1s;
            }
          }

          &:active {
            transform: scale(0.97);
          }
        `
      : ``}

  svg {
    margin-right: 13px;
  }
`;

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  children: "Submit",
  small: false,
  disabled: false,
};

export default Button;
