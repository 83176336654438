import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Switch = ({ id, isActive, offText, onText, handleSwitch }) => {
  return (
    <Container>
      <Input
        type="checkbox"
        id={id}
        checked={isActive}
        onChange={(e) => {
          handleSwitch(e.target.checked);
        }}
      />

      <OffText
        isSelected={!isActive}
        onClick={() => {
          handleSwitch(false);
        }}
      >
        {offText}
      </OffText>

      <Toggle htmlFor={id}>
        <ToggleSlide />
        {/* <OffToggle />
        <OnToggle /> */}
      </Toggle>

      <OnText
        isSelected={isActive}
        onClick={() => {
          handleSwitch(true);
        }}
      >
        {onText}
      </OnText>
    </Container>
  );
};

const Text = styled.div`
  color: ${(props) => props.theme.colors.darkSlate};
  font-size: ${(props) => props.theme.fontSize_s};
  font-weight: 500;
  cursor: default;
  user-select: none;
  transition: color 200ms ease;
  width: 130px;
`;

const OnText = styled(Text)`
  padding-left: 20px;
`;
const OffText = styled(Text)`
  text-align: right;
  padding-right: 20px;
`;

const Toggle = styled.label`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 75px;
  height: 28px;
  border-radius: 60px;
  padding: 3px;
  background-color: ${(props) => props.theme.colors.darkSlate};
  cursor: pointer;
  filter: drop-shadow(4px 8px 14px rgba(185, 188, 192, 0.3));
`;

const ToggleSlide = styled.div`
  position: absolute;
  left: 3px;
  background-color: ${(props) => props.theme.colors.oldLightGray};
  border-radius: 50%;
  height: 22px;
  width: 22px;
  z-index: 2;
  transition: left 0.2s, background-color 0.2s;
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    ${ToggleSlide} {
      background-color: ${(props) => props.theme.colors.lightGreen};
    }
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Toggle} > ${ToggleSlide} {
    left: calc(100% - 22px - 3px);
  }

  &:checked ~ ${OffText} {
    color: ${(props) => props.theme.colors.oldLightGray};
    cursor: pointer;
  }

  &:not(:checked) ~ ${OnText} {
    color: ${(props) => props.theme.colors.oldLightGray};
    cursor: pointer;
  }
`;

Switch.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  offText: PropTypes.string,
  onText: PropTypes.string,
  handleSwitch: PropTypes.func,
};

Switch.defaultProps = {
  id: "switch",
  isActive: false,
  offText: "Week",
  onText: "All Time",
  handleSwitch: null,
};

export default Switch;
