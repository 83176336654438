import React from "react";
import styled from "styled-components";
import LinkIcon from "../../sales/components/LinkIcon";

const Client = ({ client, clientBriefQuestions }) => {
  const { pointOfContact, status, tier, questions } = client;
  const tiers = ["#CCFBF1", "#FEF3C7", "#FFE4E6"];
  const newTier = tier[tier.length - 1];

  const currentQuestions = [];

  // Set the questions if they have been filled out
  if (questions) {
    // Loop through each of the brief questions that were set in the company settings
    clientBriefQuestions.forEach((brief) => {
      const tempItem = { q: brief._id };

      // Check to see if there's a question in the results that has the same id as the brief
      questions.forEach((result) => {
        if (brief._id === result.q) {
          tempItem.a = result.a;
          currentQuestions.push(tempItem);
        }
      });
    });
  }

  return (
    <Container>
      <QuestionsList>
        {tier ? (
          <Item>
            <Question>Tier:</Question>
            <Tag style={{ background: tiers[parseInt(newTier) - 1] }}>
              {tier}
            </Tag>
          </Item>
        ) : null}
        {status ? (
          <Item>
            <Question>Sale Status:</Question>
            <Answer>{status}</Answer>
          </Item>
        ) : null}
        {pointOfContact && pointOfContact.name ? (
          <Item>
            <Question>Primary Contact:</Question>
            <Answer>{pointOfContact.name}</Answer>
          </Item>
        ) : null}
        {currentQuestions && currentQuestions.length
          ? currentQuestions.map((question, index) => (
              <Item key={index}>
                <Question>
                  {
                    clientBriefQuestions.find((item) => item._id === question.q)
                      .question
                  }
                </Question>
                {clientBriefQuestions.find((item) => item._id === question.q)
                  .question === "Industry" ? (
                  <Tag>{question.a}</Tag>
                ) : clientBriefQuestions.find((item) => item._id === question.q)
                    .question === "Website" ||
                  clientBriefQuestions.find((item) => item._id === question.q)
                    .question === "LinkedIn Profile" ? (
                  <Hyperlink href={question.a} target="_blank">
                    <span>
                      {question.q === "LinkedIn Profile"
                        ? "LinkedIn"
                        : question.a}
                    </span>{" "}
                    <LinkIcon />
                  </Hyperlink>
                ) : (
                  <Answer>{question.a}</Answer>
                )}
              </Item>
            ))
          : null}
      </QuestionsList>
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 300px;
  width: 1100px;
  flex-grow: 0;

  * {
    font-size: 12px;
    line-height: 1.25;
  }
`;

const QuestionsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px 10px 20px 10px;
  height: 100%;
`;

const Item = styled.div`
  margin: 0px 30px 20px 30px;
  max-width: 250px;
`;

const Question = styled.p`
  color: #9ca3af;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Answer = styled.p`
  color: #374151;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 1.42;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  background: #e5e7eb;
  border-radius: 4px;
  font-weight: 400;
  width: max-content;
  line-height: 1.42;
  margin-top: -4px;
`;

const Hyperlink = styled.a`
  color: #5048e5 !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 1.42;
  transition: 0.2s ease-in-out;

  span {
    margin-right: 4px;
  }

  &:hover {
    color: #0d9488 !important;

    path {
      fill: #0d9488;
    }
  }
`;

Client.propTypes = {};

export default Client;
