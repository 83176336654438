import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { darken } from "polished";

import { getAllTasksFromApi, getCompaniesFromApi } from "../../utils/api";
import {
  getAllTaskGeneratorsFromApi,
  getAllTaskTemplatesFromApi,
} from "./utils/api";
import {
  getAllProjectsFromApi,
  getMembersFromApi,
  getAllRolesFromApi,
} from "../manage/utils/api";

import { format } from "date-fns";
import { getStandardizedDate } from "../../utils/helpers";
import { respondTo } from "../../styles/styleHelpers";

import SimpleLink from "../../components/links/SimpleLink";
import CreateLink from "../../components/links/CreateLink";
import ProfileImage from "../../components/ProfileImage";
import SelectDropdown from "../../components/SelectDropdown";
import CreateTaskGenerator from "./components/CreateTaskGenerator";
import CreateTaskTemplate from "./components/CreateTaskTemplate";

const ManageTasks = () => {
  const [taskGenerators, setTaskGenerators] = useState(null);
  const [taskTemplates, setTaskTemplates] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [projects, setProjects] = useState(null);
  const [clients, setClients] = useState(null);
  const [members, setMembers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [generatorModalActive, setGeneratorModalActive] = useState(false);
  const [templateModalActive, setTemplateModalActive] = useState(false);
  const [view, setView] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Get All Task Generators
  |--------------------------------------------------------------------------
  */
  async function getTaskGenerators() {
    const result = await getAllTaskGeneratorsFromApi();
    setTaskGenerators(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Task Templates
  |--------------------------------------------------------------------------
  */
  async function getTaskTemplates() {
    const result = await getAllTaskTemplatesFromApi();
    setTaskTemplates(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Tasks
  |--------------------------------------------------------------------------
  */
  async function getTasks() {
    const result = await getAllTasksFromApi({
      projects: true,
      hideArchived: true,
    });
    setTasks(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Projects
  |--------------------------------------------------------------------------
  */
  async function getProjects() {
    const result = await getAllProjectsFromApi();
    setProjects(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Clients
  |--------------------------------------------------------------------------
  */
  async function getClients() {
    const result = await getCompaniesFromApi();
    setClients(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Members
  |--------------------------------------------------------------------------
  */
  async function getMembers() {
    const result = await getMembersFromApi();
    setMembers(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get All Roles
  |--------------------------------------------------------------------------
  */
  async function getRoles() {
    const result = await getAllRolesFromApi();
    setRoles(result);
  }

  /*
  |--------------------------------------------------------------------------
  | Get projects and membes for the create task form
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getProjects();
    getMembers();
    getTaskGenerators();
    getTaskTemplates();
    getTasks();
    getRoles();
    getClients();
  }, []);

  return (
    <TasksContainer>
      <Header>
        <HeaderLeft>
          <MainHeader>Manage Tasks {templateModalActive}</MainHeader>
          {view && view === "Recurring Tasks" ? (
            <CreateLink onClick={() => setGeneratorModalActive(true)}>
              Create Recurring Task
            </CreateLink>
          ) : view && view === "Task Templates" ? (
            <CreateLink onClick={() => setTemplateModalActive(true)}>
              Create Task Template
            </CreateLink>
          ) : (
            ""
          )}
        </HeaderLeft>
        <SimpleLink as={Link} to="/tasks">
          Back to your tasks
        </SimpleLink>
      </Header>

      <StyledSelectDropdown
        onChange={(e) => setView(e.value)}
        placeholder="Select view"
        options={[
          {
            label: "Tasks",
            value: "Tasks",
          },
          {
            label: "Task Templates",
            value: "Task Templates",
          },
          {
            label: "Recurring Tasks",
            value: "Recurring Tasks",
          },
        ]}
      ></StyledSelectDropdown>

      {view && view === "Recurring Tasks" && (
        <>
          <h3>Recurring Tasks</h3>
          <TasksList className="tasks">
            {taskGenerators &&
              taskGenerators.map((task, taskIndex) => {
                return (
                  <Task key={taskIndex}>
                    <TaskBody>
                      <TaskTitle>{task.title}</TaskTitle>
                      <TaskDescription>{task.description}</TaskDescription>

                      {task.projectId ? (
                        <>
                          <p
                            style={{
                              fontWeight: "500",
                              textDecoration: "underline",
                              marginBottom: "0px",
                            }}
                          >
                            Project:
                          </p>
                          <p>
                            {projects &&
                              projects.map((item) => {
                                if (item._id === task.projectId) {
                                  return item.name;
                                } else {
                                  return null;
                                }
                              })}
                          </p>
                        </>
                      ) : task.clientId ? (
                        <>
                          <p
                            style={{
                              fontWeight: "500",
                              textDecoration: "underline",
                              marginBottom: "0px",
                            }}
                          >
                            Client:
                          </p>
                          <p>
                            {clients &&
                              clients.map((item) => {
                                if (item._id === task.clientId) {
                                  return item.name;
                                } else {
                                  return null;
                                }
                              })}
                          </p>
                        </>
                      ) : null}
                      <p>
                        Start Date:{" "}
                        {format(
                          getStandardizedDate(task.startDate),
                          "MM-dd-yyyy",
                        )}
                      </p>
                      {task.endDate ? (
                        <p>
                          End Date:{" "}
                          {format(
                            getStandardizedDate(task.endDate),
                            "MM-dd-yyyy",
                          )}
                        </p>
                      ) : (
                        <p>No end date</p>
                      )}

                      <p>Frequency: {task.frequency}</p>
                      <p>
                        Repeat every {task.repeatEvery}{" "}
                        {task.frequency && task.frequency.toLowerCase()}
                        {task.repeatEvery && task.repeatEvery >= 2 ? "s" : ""}
                      </p>

                      <p style={{ marginBottom: "0px" }}>Repeat On:</p>
                      {task.repeatOn ? (
                        <Options>
                          {task.repeatOn &&
                            task.repeatOn.map((item, index) => (
                              <Option key={index}>{item}</Option>
                            ))}
                        </Options>
                      ) : task.specificWeek && task.specificDay ? (
                        <p>
                          {task.specificWeek} {task.specificDay}
                        </p>
                      ) : null}

                      <p style={{ marginTop: "15px", marginBottom: "0px" }}>
                        Assigned to:
                      </p>
                      <Options>
                        {members &&
                          task.members &&
                          task.members.map((member, memberIndex) => {
                            const selectedMember = members.filter((item) => {
                              return item._id === member;
                            });

                            return (
                              <StyledProfileImage
                                key={memberIndex}
                                handle={selectedMember[0].handle}
                                name={selectedMember[0].name}
                                showStatus
                              ></StyledProfileImage>
                            );
                          })}
                      </Options>
                    </TaskBody>

                    <TaskFooter>
                      {task.link && (
                        <SimpleLink as={Link} to={task.link.url}>
                          {task.link.text}
                        </SimpleLink>
                      )}

                      <SimpleLink
                        as={Link}
                        to={`/tasks/generators/${task._id}`}
                      >
                        Edit
                      </SimpleLink>
                    </TaskFooter>
                  </Task>
                );
              })}
          </TasksList>

          {generatorModalActive && (
            <CreateTaskGenerator
              projects={projects}
              members={members}
              generatorModalActive={generatorModalActive}
              setGeneratorModalActive={setGeneratorModalActive}
              getTaskGenerators={getTaskGenerators}
            ></CreateTaskGenerator>
          )}
        </>
      )}

      {view && view === "Task Templates" && (
        <>
          <h3>Task Templates</h3>
          <TasksList className="tasks">
            {taskTemplates &&
              taskTemplates.map((task, taskIndex) => {
                return (
                  <Task key={taskIndex}>
                    <TaskBody>
                      <TaskTitle>{task.title}</TaskTitle>
                      <TaskDescription>{task.description}</TaskDescription>
                      <p>
                        Role:{" "}
                        {roles &&
                          roles.map((item) => {
                            if (task.role === item._id) {
                              return item.role;
                            } else return null;
                          })}
                      </p>
                    </TaskBody>

                    <TaskFooter>
                      {task.link && (
                        <SimpleLink as={Link} to={task.link.url}>
                          {task.link.text}
                        </SimpleLink>
                      )}

                      <SimpleLink as={Link} to={`/tasks/templates/${task._id}`}>
                        Edit
                      </SimpleLink>
                    </TaskFooter>
                  </Task>
                );
              })}
          </TasksList>

          {templateModalActive && (
            <CreateTaskTemplate
              roles={roles}
              templateModalActive={templateModalActive}
              setTemplateModalActive={setTemplateModalActive}
              getTaskTemplates={getTaskTemplates}
            ></CreateTaskTemplate>
          )}
        </>
      )}

      {view && view === "Tasks" && (
        <>
          <h3>Tasks</h3>
          <TasksList className="tasks">
            {tasks &&
              tasks.map((task, taskIndex) => {
                return (
                  <Task key={taskIndex}>
                    <TaskBody>
                      <TaskTitle>{task.title}</TaskTitle>
                      <TaskDescription>{task.description}</TaskDescription>
                      <p
                        style={{
                          fontWeight: "500",
                          textDecoration: "underline",
                          marginBottom: "0px",
                        }}
                      >
                        Project:
                      </p>

                      <p>
                        {projects &&
                          task.projectId &&
                          projects.map((item) => {
                            if (item._id === task.projectId) {
                              return item.name;
                            } else return null;
                          })}
                      </p>
                      <p>
                        Date:{" "}
                        {format(getStandardizedDate(task.date), "MM-dd-yyyy")}
                      </p>
                      <p>
                        Week:{" "}
                        {format(getStandardizedDate(task.week), "MM-dd-yyyy")}
                      </p>

                      {task.position && <p>Position: {task.position}</p>}

                      <p>Status: {task.status}</p>

                      <p style={{ marginTop: "15px", marginBottom: "0px" }}>
                        Assigned to:
                      </p>
                      <Options>
                        {members &&
                          task.memberId &&
                          members.map((item, index) => {
                            if (item._id === task.memberId) {
                              return (
                                <StyledProfileImage
                                  handle={item.handle}
                                  name={item.name}
                                  key={index}
                                  showStatus
                                ></StyledProfileImage>
                              );
                            } else return null;
                          })}
                      </Options>
                    </TaskBody>

                    <TaskFooter>
                      {task.link && (
                        <SimpleLink as={Link} to={task.link.url}>
                          {task.link.text}
                        </SimpleLink>
                      )}

                      <SimpleLink as={Link} to={`/tasks/${task._id}`}>
                        Edit
                      </SimpleLink>
                    </TaskFooter>
                  </Task>
                );
              })}
          </TasksList>
        </>
      )}
    </TasksContainer>
  );
};

const TasksContainer = styled.div`
  padding: 53px 40px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 60px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const MainHeader = styled.h2`
  color: ${(props) => props.theme.colors.darkSlate};
  font-size: ${(props) => props.theme.fontSize_l};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0px;
  margin-right: 170px;

  ${respondTo("xlarge")} {
    font-size: ${(props) => props.theme.fontSize_xl};
  }
`;

const TasksList = styled.div`
  width: 500px;
`;

const Task = styled.div`
  border: 1px solid #cec9d4;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const TaskBody = styled.div`
  margin-bottom: 30px;
`;

const TaskFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TaskTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
`;

const TaskDescription = styled.p`
  font-size: 14px;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? darken(0.3, "#7BC1A8") : "#7BC1A8"};
  color: white;
  font-weight: bold;
  line-height: 0;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  height: 35px;
  width: 35px;
  font-size: 12px;
  margin: 5px;
  ${(props) =>
    props.clickable
      ? css`
          cursor: pointer;

          &:hover {
            opacity: ${(props) => (props.selected ? "1" : ".6")};
          }
        `
      : `cursor: default;`}
`;

const StyledProfileImage = styled(ProfileImage)`
  ${(props) => (props.clickable ? `cursor: pointer;` : `cursor: default;`)}
  width: 37px;
  height: 37px;
  margin: 5px;
  border: ${(props) =>
    props.selected ? "3px solid #0079FF" : "3px solid white"};

  * {
    font-size: 14px;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 300px;
  margin-bottom: 50px;
`;

export default ManageTasks;
