import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { eachWeekOfInterval, lastDayOfWeek, format } from "date-fns";

import { useNotifications } from "../../context/notificationsContext";

import {
  getProjectFromApi,
  getMembersFromApi,
  getRatesFromApi,
  planProjectOnApi,
  updateProjectPlanOnApi,
  editProjectOnApi,
} from "../manage/utils/api";

import { getWeek, getStandardizedDate } from "../../utils/helpers";

import CreateLink from "../../components/links/CreateLink";
import DefaultLink from "../../components/links/DefaultLink";
import FancyLink from "../../components/links/FancyLink";
import Button from "../../components/buttons/Button";
import { Input } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import ProfileImage from "../../components/ProfileImage";
import CloseButton from "../../components/buttons/CloseButton";

// ! -----------------------------------------------------
// ! -------------- NOTICE -------------------------------
// ! -----------------------------------------------------
// ! This view is no longer the primary view for setting up the budget plan.
// ! That is now handled by project chunks in the project's Dashboard view.
// ! A similar read-only version of this view can be found in PlanReview.js (/projects/:id/review)
// !
// ! HOWEVER:
// !
// ! This view will still be used for the time being as a fallback for legacy projects that were created and planned before chunks were implemented.
// ! -----------------------------------------------------

const ProjectPlan = () => {
  const { id } = useParams();
  const { openAlertPopup } = useNotifications();
  const history = useHistory();

  const [rates, setRates] = useState(null);
  const [members, setMembers] = useState(null);
  const [producer, setProducer] = useState(null);

  const [project, setProject] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);
  //const [projectCode, setProjectCode] = useState(null);
  const [teams, setTeams] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [alreadyInHarvest, setAlreadyInHarvest] = useState(false);
  //const [slackUsers, setSlackusers] = useState(null);
  const [weeksArray, setWeeksArray] = useState([]);

  const [isExisting, setIsExisting] = useState(false);

  /*
  |--------------------------------------------------------------------------
  * Set up members and set up an empty teams array with 1 item
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getProject();

    getMembers();

    getSlackMembers();
  }, []); //eslint-disable-line

  const createDefaultBudgetObj = () => {
    return {
      projectId: null,
      mainMember: {
        memberId: null,
        weeks: [],
      },
      supportStaff: {
        memberId: null,
        weeks: [],
      },
      coordinator: {
        memberId: null,
        weeks: [],
      },
      producer: {
        memberId: null,
        weeks: [],
      },
    };
  };

  const generateWeeksForMember = () => {
    return weeksArray.map((week) => {
      return {
        weekDate: week.start,
        hours: 0,
        string: "0",
      };
    });
  };

  /*
  |--------------------------------------------------------------------------
  * Setup data for existing Project
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (isExisting && members && rates && members.length && rates.length) {
      // setup the teams
      project.teamMembers.forEach((teamMember) => {
        if (teamMember.isMain) {
          const { bookingType, bookingQty } = teamMember;
          // teamMember.teamMember = ID
          changeMember(teamMember.teamMember, teamMember.index, {
            bookingType,
            bookingQty,
          });
        }
      });

      // setup the budget plan
      project.bookingPlan.forEach((plan) => {
        let memberType;

        if (teams && teams[plan.index]) {
          Object.entries(teams[plan.index]).forEach(([type, id]) => {
            if (id === plan.memberId) {
              memberType = type;
            }
          });
        }

        plan.weeks.forEach((week, weekIndex) => {
          updateBudget(
            { weekIndex, teamIndex: plan.index, memberType },
            week.hours.toString(),
          );
        });
      });
    }
  }, [isExisting, members, rates]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  * Create staff dropdown with list of members for the first instance
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (members && members.length >= 2) {
      // Get the rates
      getRatesFromApi().then((result) => {
        setRates(result);
      });
    }
  }, [members]);

  /*
  |--------------------------------------------------------------------------
  * Set slack users in state based on the team configuration
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const filteredMembers = [];

    if (teams && members) {
      teams.forEach((item) => {
        if (
          item.coordinator &&
          filteredMembers.indexOf(item.coordinator) === -1
        ) {
          filteredMembers.push(item.coordinator);
        }
        if (
          item.mainMember &&
          filteredMembers.indexOf(item.mainMember) === -1
        ) {
          filteredMembers.push(item.mainMember);
        }
        if (item.producer && filteredMembers.indexOf(item.producer) === -1) {
          filteredMembers.push(item.producer);
        }
        if (
          item.supportStaff &&
          filteredMembers.indexOf(item.supportStaff) === -1
        ) {
          filteredMembers.push(item.supportStaff);
        }
      });
    }

    let slackMembers = [];

    filteredMembers.forEach((filtered) => {
      members.forEach((item) => {
        if (
          item._id === filtered &&
          slackMembers.indexOf(item.slackId) === -1
        ) {
          slackMembers.push(item.slackId);
        }
      });
    });

    slackMembers = slackMembers.join(", ");

    //setSlackusers(slackMembers);
  }, [teams]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  * Get project information
  |--------------------------------------------------------------------------
  */
  async function getProject() {
    const result = await getProjectFromApi(id);

    // setProject(result.databaseProject);
    // setHarvestProject(result.harvestProject);
    // setProjectClient(result.databaseProject.client.name);

    const startTime = result.databaseProject.startDate;
    const startYear = new Date(startTime).getUTCFullYear();
    const startMonth = new Date(startTime).getUTCMonth();
    const startDay = new Date(startTime).getUTCDate();

    const endTime = result.databaseProject.endDate;
    const endYear = new Date(endTime).getUTCFullYear();
    const endMonth = new Date(endTime).getUTCMonth();
    const endDay = new Date(endTime).getUTCDate();

    const projectDateRange = {
      start: new Date(`${startYear}-${startMonth + 1}-${startDay}`),
      end: new Date(`${endYear}-${endMonth + 1}-${endDay}`),
    };

    const weekStarts = eachWeekOfInterval(projectDateRange, {
      weekStartsOn: 1,
    });

    const weeks = [];

    weekStarts.forEach((weekStart) => {
      weeks.push({
        week: getWeek(getStandardizedDate(weekStart)),
        start: weekStart,
        end: lastDayOfWeek(weekStart, { weekStartsOn: 1 }),
      });
    });

    setWeeksArray(weeks);
    setProducer(result.databaseProject.client.producer);
    setProjectId(result.databaseProject._id);
    setProjectName(result.databaseProject.name);
    //setProjectCode(result.harvestProject.code);

    setProject(result.databaseProject);

    const projectExists = result.databaseProject.teamMembers.length
      ? true
      : false;

    let teamsToAdd = 1;

    // if the project already exists, add blank teams for each main team member
    if (projectExists) {
      const mainMembers = result.databaseProject.teamMembers.filter(
        (teamMember) => teamMember.isMain,
      );
      teamsToAdd = mainMembers.length;
    }

    addTeam(teamsToAdd);

    // if there are already team members, then this project has already been planned
    setIsExisting(projectExists);
  }

  /*
  |--------------------------------------------------------------------------
  * Get all values in state and sends to the API to save
  |--------------------------------------------------------------------------
  */
  const planProject = async () => {
    try {
      const data = {
        projectId: id,
        teams,
        bookingPlans: restructureBookingPlans(),
        alreadyInHarvest,
      };

      const timestamp = new Date();

      const budgetData = {
        projectId: id,
        database: {
          "stages.budgetPlan.completedDate": timestamp,
        },
      };

      // if the project has already been planned, update it. OTherwise, create the new plan
      if (isExisting) {
        await updateProjectPlanOnApi(data);
      } else {
        await planProjectOnApi(data);
        await editProjectOnApi(budgetData);
      }

      openAlertPopup("Success", "Project planned successfully!", true);

      try {
        // ? would be nice to not create the channel again if project was already planned, but might not be a big deal right now.
        // (we can't just not run this if the project has already been planned, because a new user could have been added to the plan, so we would want to add them to the slack channel as well)

        if (process.env.REACT_APP_ENVIRONMENT !== "development") {
          // const channelId = await createChannel();
          // // eslint-disable-next-line
          // const inviteUsers = await axios.post(
          //   `https://slack.com/api/conversations.invite?token=${process.env.REACT_APP_SLACK_ACCESS_TOKEN}&channel=${channelId}&users=${slackUsers}`,
          // );
        }
      } catch (err) {
        console.error("Failed to create slack channel");
        console.error(err);
      }

      history.push(`/projects/${id}/stages`);
    } catch (err) {
      console.error("err", err);
      openAlertPopup("Failure", "Project planning failed!");
    }
  };

  const restructureBookingPlans = () => {
    // const newPlans = budgets.flatMap((budget, index) => [
    //   { ...budget.mainMember, projectId: budget.projectId, index },
    //   { ...budget.supportStaff, projectId: budget.projectId, index },
    //   { ...budget.coordinator, projectId: budget.projectId, index },
    //   { ...budget.producer, projectId: budget.projectId, index },
    // ]);

    const newPlans = [];

    let tempTeams = [...teams];
    const tempBudgets = [...budgets];

    tempBudgets.forEach((budget) => {
      let supportHours = 0;
      let producerHours = 0;
      let coordinatorHours = 0;

      if (budget.supportStaff) {
        budget.supportStaff.weeks.forEach((item) => {
          supportHours += item.hours;
        });
      }

      if (budget.producer) {
        budget.producer.weeks.forEach((item) => {
          producerHours += item.hours;
        });
      }

      if (budget.coordinator) {
        budget.coordinator.weeks.forEach((item) => {
          coordinatorHours += item.hours;
        });
      }

      if (supportHours <= 0) {
        tempTeams = tempTeams.map((item) => {
          if (item.mainMember === budget.mainMember.memberId) {
            delete item.supportStaff;
          }

          return item;
        });

        delete budget.supportStaff;
      }
      if (producerHours <= 0) {
        tempTeams = tempTeams.map((item) => {
          if (item.mainMember === budget.mainMember.memberId) {
            delete item.producer;
          }

          return item;
        });

        delete budget.producer;
      }
      if (coordinatorHours <= 0) {
        tempTeams = tempTeams.map((item) => {
          if (item.mainMember === budget.mainMember.memberId) {
            delete item.coordinator;
          }

          return item;
        });

        delete budget.coordinator;
      }
    });

    tempBudgets.forEach((budget, index) => {
      newPlans.push({
        ...budget.mainMember,
        projectId: budget.projectId,
        index,
      });

      if (budget.supportStaff) {
        newPlans.push({
          ...budget.supportStaff,
          projectId: budget.projectId,
          index,
        });
      }
      if (budget.producer) {
        newPlans.push({
          ...budget.producer,
          projectId: budget.projectId,
          index,
        });
      }
      if (budget.coordinator) {
        newPlans.push({
          ...budget.coordinator,
          projectId: budget.projectId,
          index,
        });
      }
    });

    setTeams(tempTeams);
    setBudgets(tempBudgets);

    return newPlans;
  };

  /*
  |--------------------------------------------------------------------------
  * Add teams member to a project
  |--------------------------------------------------------------------------
  */
  const addTeam = (length = 1) => {
    //Adds another team
    const tempTeams = [...teams];
    Array.from({ length }).forEach(() => {
      tempTeams.push({});
    });
    setTeams(tempTeams);

    //Adds another budget for that team
    const tempBudgets = [...budgets];
    Array.from({ length }).forEach(() => {
      tempBudgets.push(createDefaultBudgetObj());
    });
    setBudgets(tempBudgets);
  };

  /*
  |--------------------------------------------------------------------------
  * Get selected "Booked By" value
  |--------------------------------------------------------------------------
  */
  const changeBooked = (selection, index) => {
    const tempTeams = [...teams];
    tempTeams[index].bookingType = selection;

    if (!tempTeams[index].bookingQty) {
      tempTeams[index].bookingQty = 1;
    }

    if (rates) {
      const tempTeams = [...teams];
      const tempRates = [...rates];

      tempTeams.forEach((team) => {
        if (team.budgetTier && team.bookingType) {
          const rate = tempRates.filter((item) => {
            return (
              item.budgetTier === team.budgetTier &&
              item.bookingType === team.bookingType
            );
          });
          team.rates = rate;
        }
      });
    }

    setTeams(tempTeams);
  };

  const changeBookedQty = (selection, index) => {
    const tempTeams = [...teams];
    tempTeams[index].bookingQty = selection !== "" ? parseInt(selection) : 0;

    setTeams(tempTeams);
  };

  /*
  |--------------------------------------------------------------------------
  * Change project teams when a member is selected from the dropdown
  |--------------------------------------------------------------------------
  */
  const changeMember = (member, index, additional = {}) => {
    const selectedMember = members.filter((item) => {
      return item._id === member;
    });

    const tempTeams = [...teams];

    tempTeams[index].mainMember = selectedMember[0]._id;
    tempTeams[index].supportStaff = selectedMember[0].supportStaff;
    tempTeams[index].coordinator = selectedMember[0].coordinator;
    tempTeams[index].producer = producer;
    tempTeams[index].budgetTier = selectedMember[0].budgetTier;
    tempTeams[index].rates = null;

    // optional additional properties to apply
    Object.entries(additional).forEach(([key, value]) => {
      tempTeams[index][key] = value;
    });

    if (rates) {
      const tempTeams = [...teams];
      const tempRates = [...rates];

      tempTeams.forEach((team) => {
        if (team.budgetTier && team.bookingType) {
          const rate = tempRates.filter((item) => {
            return (
              item.budgetTier === team.budgetTier &&
              item.bookingType === team.bookingType
            );
          });
          team.rates = rate;
        }
      });
    }

    setTeams(tempTeams);

    // Adds all the ids and weeks for this team's budget
    const tempBudgets = [...budgets];
    tempBudgets[index].mainMember.memberId = selectedMember[0]._id;
    if (tempBudgets[index].supportStaff) {
      tempBudgets[index].supportStaff.memberId = selectedMember[0].supportStaff;
    }
    if (tempBudgets[index].coordinator) {
      tempBudgets[index].coordinator.memberId = selectedMember[0].coordinator;
    }
    if (tempBudgets[index].producer) {
      tempBudgets[index].producer.memberId = producer;
    }

    // only set the weeks if this budget hasn't been created already
    if (!tempBudgets[index].projectId) {
      tempBudgets[index].projectId = projectId;
      tempBudgets[index].mainMember.weeks = generateWeeksForMember();
      if (tempBudgets[index].supportStaff) {
        tempBudgets[index].supportStaff.weeks = generateWeeksForMember();
      }
      if (tempBudgets[index].coordinator) {
        tempBudgets[index].coordinator.weeks = generateWeeksForMember();
      }
      if (tempBudgets[index].producer) {
        tempBudgets[index].producer.weeks = generateWeeksForMember();
      }
    }

    setBudgets(tempBudgets);
  };

  /*
  |--------------------------------------------------------------------------
  * Returns initials of a string
  |--------------------------------------------------------------------------
  */

  const formatName = (id) => {
    if (members && id) {
      const selectedMember = members.filter((member) => {
        return member._id === id;
      });

      const name = selectedMember[0].name;

      // Get first name and last initial
      const nameArray = name.split(" ");
      const finalName = `${nameArray[0]} ${nameArray[1][0]}`;

      return finalName;
    }
  };

  const getMember = (id) => {
    if (members && id) {
      const selectedMember = members.filter((member) => {
        return member._id === id;
      });

      return selectedMember;
    }
  };

  /*
  |--------------------------------------------------------------------------
  * Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();
      setMembers(results);

      return results;
    } catch (error) {
      console.error(error);
    }
  };

  const removeMember = (memIndex) => {
    let updatedTeams = [...teams];
    updatedTeams = updatedTeams.filter((team, index) => index !== memIndex);
    setTeams(updatedTeams);

    let updatedBudgets = [...budgets];
    updatedBudgets = updatedBudgets.filter(
      (budget, index) => index !== memIndex,
    );
    setBudgets(updatedBudgets);
  };

  // Format budget
  const formatBudget = (budget, qty) => {
    const result = budget * qty;
    return Math.round((result + Number.EPSILON) * 100) / 100; //Rounds to 2 decimal places
  };

  // Format budget
  const formatRate = (rate, qty) => {
    const result = rate * qty;
    return Math.round((result + Number.EPSILON) * 100) / 100; //Rounds to 2 decimal places
  };

  const updateBudget = ({ weekIndex, teamIndex, memberType }, value) => {
    const tempBudgets = [...budgets];

    const isValueGood = value.match(/^([0-9]|\.)+$/);

    let newHours = 0;

    if (isValueGood === null || isValueGood.length < 1) {
      console.error("Budget value was not a number");
    } else {
      // Converts the value to a number
      newHours = parseFloat(value);
    }

    // Puts the value in the correct week for the correct team member
    switch (memberType) {
      case "mainMember":
        tempBudgets[teamIndex].mainMember.weeks[weekIndex].hours = newHours;
        tempBudgets[teamIndex].mainMember.weeks[weekIndex].string = value;
        break;
      case "supportStaff":
        tempBudgets[teamIndex].supportStaff.weeks[weekIndex].hours = newHours;
        tempBudgets[teamIndex].supportStaff.weeks[weekIndex].string = value;
        break;
      case "coordinator":
        tempBudgets[teamIndex].coordinator.weeks[weekIndex].hours = newHours;
        tempBudgets[teamIndex].coordinator.weeks[weekIndex].string = value;
        break;
      case "producer":
        tempBudgets[teamIndex].producer.weeks[weekIndex].hours = newHours;
        tempBudgets[teamIndex].producer.weeks[weekIndex].string = value;
        break;

      default:
        console.error("Invalid memberType in updateBudget");
        break;
    }

    setBudgets(tempBudgets);
  };

  /*
  |--------------------------------------------------------------------------
  * Get all slack members
  |--------------------------------------------------------------------------
  */
  const getSlackMembers = async () => {
    try {
      const response = await axios.get(
        `https://slack.com/api/users.list?token=${process.env.REACT_APP_SLACK_ACCESS_TOKEN}`,
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // /*
  // |--------------------------------------------------------------------------
  // * Create a channel in slack
  // |--------------------------------------------------------------------------
  // */
  // const createChannel = async () => {
  //   let channelName = `_${projectCode}_${projectName}_`;

  //   channelName = channelName.replace(/\s+/g, "-").toLowerCase();

  //   try {
  //     const response = await axios.post(
  //       `https://slack.com/api/conversations.create?token=${process.env.REACT_APP_SLACK_ACCESS_TOKEN}&name=${channelName}`,
  //     );

  //     return response.data.channel.id;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  /*
  |--------------------------------------------------------------------------
  * Remove row from a member
  |--------------------------------------------------------------------------
  */

  const removeRow = (mainMember, memberToRemove) => {
    if (teams && budgets) {
      let tempTeams = [...teams];
      let tempBudgets = [...budgets];

      tempTeams = tempTeams.map((item) => {
        if (item.mainMember === mainMember) {
          delete item[memberToRemove];
        }

        return item;
      });

      tempBudgets = tempBudgets.map((item) => {
        if (item.mainMember.memberId === mainMember) {
          delete item[memberToRemove];
        }

        return item;
      });

      setTeams(tempTeams);
      setBudgets(tempBudgets);
    }
  };

  /*
  |--------------------------------------------------------------------------
  * Add row to a member
  |--------------------------------------------------------------------------
  */

  const addRow = (mainMember, memberToAddRole) => {
    if (teams && budgets) {
      let tempTeams = [...teams];
      let tempBudgets = [...budgets];
      let tempMembers = [...members];
      let memberToAddId;

      if (memberToAddRole === "producer") {
        memberToAddId = producer;
      } else {
        tempMembers = tempMembers.filter((item) => {
          if (item._id === mainMember) {
            return item;
          } else return false;
        });

        memberToAddId = tempMembers[0][memberToAddRole];
      }

      tempTeams = tempTeams.map((item) => {
        if (item.mainMember === mainMember) {
          item[memberToAddRole] = memberToAddId;
        }

        return item;
      });

      tempBudgets = tempBudgets.map((item) => {
        if (item.mainMember.memberId === mainMember) {
          item[memberToAddRole] = {
            memberId: null,
            weeks: [],
          };
          item[memberToAddRole].memberId = memberToAddId;
          item[memberToAddRole].weeks = generateWeeksForMember();
          item[memberToAddRole].active = true;
        }

        return item;
      });

      setTeams(tempTeams);
      setBudgets(tempBudgets);
    }
  };

  const checkHours = (weeks) => {
    let hours = 0;

    if (weeks) {
      weeks.forEach((week) => {
        hours += week.hours;
      });

      if (hours > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <ManageHeader>
        <h1>
          Plan Project Budget {projectName ? ` — [${projectName}]` : null}
        </h1>
        <p>Fill out this one form to plan a project budget.</p>
      </ManageHeader>

      <ProjectSetup>
        <ProjectTeam>
          <div style={{ marginBottom: "40px", marginTop: "30px" }}>
            <input
              style={{ marginRight: "10px" }}
              type="checkbox"
              checked={alreadyInHarvest}
              onChange={() => setAlreadyInHarvest(!alreadyInHarvest)}
            />
            <span>Project already in harvest?</span>
          </div>
          <Heading>Project Team</Heading>

          {teams &&
            teams.map((member, index) => {
              return (
                <TeamMember key={index}>
                  <TableContainer>
                    <MemberTable>
                      <thead>
                        <tr>
                          <th>Staff</th>
                          <th>Booked By</th>
                          <th>Quantity</th>
                          <th>Hours Budget</th>
                          <th>$ Budget</th>
                          {weeksArray &&
                            weeksArray.map((weekObj, weekIndex) => (
                              <th key={weekIndex}>
                                <WeekNumber>Week {weekObj.week}</WeekNumber>
                                <WeekDates>
                                  {format(weekObj.start, "MMM d")} -{" "}
                                  {format(weekObj.end, "MMM d")}
                                </WeekDates>
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                        {/* Main Member */}
                        <tr>
                          <td>
                            <StaffMemberContainer>
                              {members && member.mainMember && (
                                <MemberImage
                                  handle={
                                    getMember(member.mainMember)[0].handle
                                  }
                                  name={getMember(member.mainMember)[0].name}
                                />
                              )}
                              <MainMemberDropdown
                                name="staff"
                                id="staff"
                                options={
                                  members
                                    ? members.map((option) => {
                                        return {
                                          label: formatName(option._id),
                                          value: option._id,
                                        };
                                      })
                                    : []
                                }
                                placeholder="Select Staff"
                                isSearchable
                                onChange={({ value }) =>
                                  changeMember(value, index)
                                }
                                value={
                                  teams[index].mainMember
                                    ? {
                                        label: formatName(
                                          teams[index].mainMember,
                                        ),
                                        value: teams[index].mainMember,
                                      }
                                    : undefined
                                }
                              />
                            </StaffMemberContainer>
                          </td>

                          <td>
                            <BookedBy
                              name="bookedBy"
                              id="bookedBy"
                              options={[
                                {
                                  label: "Rush",
                                  value: "Rush",
                                },
                                {
                                  label: "Hour",
                                  value: "Hour",
                                },
                                {
                                  label: "Week",
                                  value: "Week",
                                },
                                {
                                  label: "Month",
                                  value: "Month",
                                },
                              ]}
                              placeholder="Select Type"
                              onChange={({ value }) =>
                                changeBooked(value, index)
                              }
                              value={
                                teams[index].bookingType
                                  ? {
                                      label: teams[index].bookingType,
                                      value: teams[index].bookingType,
                                    }
                                  : undefined
                              }
                            />
                          </td>

                          <td>
                            <Quantity
                              type="text"
                              name="quantity"
                              alternate
                              // defaultValue="1"
                              value={teams[index].bookingQty || ""}
                              onChange={(e) => {
                                changeBookedQty(e.target.value, index);
                              }}
                            />
                          </td>

                          <td>
                            {member.rates &&
                              member.rates.map((item, ratesIndex) => {
                                if (item.bookingType === member.bookingType) {
                                  return (
                                    <div key={ratesIndex}>
                                      {formatBudget(
                                        item.mainBudget,
                                        member.bookingQty,
                                      )}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </td>

                          <td>
                            {member.rates &&
                              member.rates.map((item, ratesIndex) => {
                                return (
                                  <div key={ratesIndex}>
                                    $
                                    {formatRate(
                                      item.mainRate,
                                      formatBudget(
                                        item.mainBudget,
                                        member.bookingQty,
                                      ),
                                    )}
                                  </div>
                                );
                              })}
                          </td>
                          {weeksArray &&
                            weeksArray.map((weekObj, weekIndex) => (
                              <td>
                                <BudgetInput
                                  key={weekIndex}
                                  type="text"
                                  //defaultValue="0"
                                  value={
                                    budgets[index]?.mainMember?.weeks[weekIndex]
                                      ?.string
                                  }
                                  onChange={(e) =>
                                    updateBudget(
                                      {
                                        weekIndex,
                                        teamIndex: index,
                                        memberType: "mainMember",
                                      },
                                      e.target.value,
                                    )
                                  }
                                />
                              </td>
                            ))}
                        </tr>
                        {/* Director / Support */}
                        {members &&
                        member.supportStaff &&
                        budgets &&
                        budgets[index].supportStaff &&
                        (checkHours(budgets[index].supportStaff.weeks) ||
                          budgets[index].supportStaff.active) ? (
                          <tr>
                            <td>
                              <StaffMemberContainer>
                                <StyledCloseButton
                                  onClick={() =>
                                    removeRow(member.mainMember, "supportStaff")
                                  }
                                />
                                <MemberImage
                                  handle={
                                    getMember(member.supportStaff)[0].handle
                                  }
                                  name={getMember(member.supportStaff)[0].name}
                                />
                                <span>
                                  {member.budgetTier === "Director" ||
                                  member.budgetTier === "Partner"
                                    ? "Support"
                                    : "Director"}
                                </span>
                              </StaffMemberContainer>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  return (
                                    <div key={ratesIndex}>
                                      {formatBudget(
                                        item.supportBudget,
                                        member.bookingQty,
                                      )}
                                    </div>
                                  );
                                })}
                            </td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  return (
                                    <div key={ratesIndex}>
                                      $
                                      {formatRate(
                                        item.supportRate,
                                        formatBudget(
                                          item.supportBudget,
                                          member.bookingQty,
                                        ),
                                      )}
                                    </div>
                                  );
                                })}
                            </td>
                            {weeksArray &&
                              weeksArray.map((weekObj, weekIndex) => (
                                <td>
                                  <BudgetInput
                                    key={weekIndex}
                                    type="text"
                                    //defaultValue="0"
                                    value={
                                      budgets[index]?.supportStaff?.weeks[
                                        weekIndex
                                      ]?.string
                                    }
                                    onChange={(e) =>
                                      updateBudget(
                                        {
                                          weekIndex,
                                          teamIndex: index,
                                          memberType: "supportStaff",
                                        },
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              ))}
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              <CreateLink
                                onClick={() =>
                                  addRow(member.mainMember, "supportStaff")
                                }
                              >
                                Add Support/Director
                              </CreateLink>
                            </td>
                          </tr>
                        )}

                        {/* Producer */}
                        {members &&
                        member.producer &&
                        budgets &&
                        budgets[index].producer &&
                        (checkHours(budgets[index].producer.weeks) ||
                          budgets[index].producer.active) ? (
                          <tr>
                            <td>
                              <StaffMemberContainer>
                                <StyledCloseButton
                                  onClick={() =>
                                    removeRow(member.mainMember, "producer")
                                  }
                                />

                                <MemberImage
                                  handle={getMember(member.producer)[0].handle}
                                  name={getMember(member.producer)[0].name}
                                />
                                <span>Producer</span>
                              </StaffMemberContainer>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  if (item.bookingType === member.bookingType) {
                                    return (
                                      <div key={ratesIndex}>
                                        {formatBudget(
                                          item.producerBudget,
                                          member.bookingQty,
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  if (item.bookingType === member.bookingType) {
                                    return (
                                      <div key={ratesIndex}>
                                        $
                                        {formatRate(
                                          item.producerRate,
                                          formatBudget(
                                            item.producerBudget,
                                            member.bookingQty,
                                          ),
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </td>
                            {weeksArray &&
                              weeksArray.map((weekObj, weekIndex) => (
                                <td>
                                  <BudgetInput
                                    key={weekIndex}
                                    type="text"
                                    //defaultValue="0"
                                    value={
                                      budgets[index]?.producer?.weeks[weekIndex]
                                        ?.string
                                    }
                                    onChange={(e) =>
                                      updateBudget(
                                        {
                                          weekIndex,
                                          teamIndex: index,
                                          memberType: "producer",
                                        },
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              ))}
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              <CreateLink
                                onClick={() =>
                                  addRow(member.mainMember, "producer")
                                }
                              >
                                Add Producer
                              </CreateLink>
                            </td>
                          </tr>
                        )}

                        {/* Coordinator */}
                        {members &&
                        member.coordinator &&
                        budgets &&
                        budgets[index].coordinator &&
                        (checkHours(budgets[index].coordinator.weeks) ||
                          budgets[index].coordinator.active) ? (
                          <tr>
                            <td>
                              <StaffMemberContainer>
                                <StyledCloseButton
                                  onClick={() =>
                                    removeRow(member.mainMember, "coordinator")
                                  }
                                />

                                <MemberImage
                                  handle={
                                    getMember(member.coordinator)[0].handle
                                  }
                                  name={getMember(member.coordinator)[0].name}
                                />
                                <span>Coordinator</span>
                              </StaffMemberContainer>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  if (item.bookingType === member.bookingType) {
                                    return (
                                      <div key={ratesIndex}>
                                        {formatBudget(
                                          item.coordinatorBudget,
                                          member.bookingQty,
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </td>
                            <td>
                              {member.rates &&
                                member.rates.map((item, ratesIndex) => {
                                  if (item.bookingType === member.bookingType) {
                                    return (
                                      <div key={ratesIndex}>
                                        $
                                        {formatRate(
                                          item.coordinatorRate,
                                          formatBudget(
                                            item.coordinatorBudget,
                                            member.bookingQty,
                                          ),
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                            </td>

                            {weeksArray &&
                              weeksArray.map((weekObj, weekIndex) => (
                                <td>
                                  <BudgetInput
                                    key={weekIndex}
                                    type="text"
                                    //defaultValue="0"
                                    value={
                                      budgets[index]?.coordinator?.weeks[
                                        weekIndex
                                      ]?.string
                                    }
                                    onChange={(e) =>
                                      updateBudget(
                                        {
                                          weekIndex,
                                          teamIndex: index,
                                          memberType: "coordinator",
                                        },
                                        e.target.value,
                                      )
                                    }
                                  />
                                </td>
                              ))}
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              <CreateLink
                                onClick={() =>
                                  addRow(member.mainMember, "coordinator")
                                }
                              >
                                Add Coordinator
                              </CreateLink>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </MemberTable>
                  </TableContainer>
                  <DeleteButton onClick={() => removeMember(index)} danger>
                    Delete
                  </DeleteButton>
                </TeamMember>
              );
            })}
        </ProjectTeam>
      </ProjectSetup>

      <ButtonRow>
        <FancyLink onClick={() => addTeam()}>Add</FancyLink>

        <Button onClick={() => planProject()} disabled={!(teams.length > 0)}>
          Save
        </Button>
      </ButtonRow>
    </>
  );
};

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 600;
  margin-bottom: 0px;
`;

const ManageHeader = styled.div`
  padding: 40px 50px;
  border-bottom: 1px solid #cec9d4;
  margin-bottom: 30px;

  h1 {
    font-size: 28px;
    color: #373040;
    margin-bottom: 10px;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    color: #373040;
    margin-bottom: 0px;
  }
`;

const ProjectSetup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  padding: 0px 50px;
`;

const WeekNumber = styled.div`
  font-size: 14px;
  font-weight: 800;
  text-align: center;
`;

const WeekDates = styled.div`
  font-size: 14px;
  text-align: center;
`;

const BudgetInput = styled(Input)`
  display: inline-block;
  height: 48px;
  width: 120px;
  text-align: center;
  margin: 0px 10px;
`;

const ProjectTeam = styled.div`
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 50px 50px 50px;

  button:first-of-type {
    margin-left: 0;
    margin-right: auto;
  }
`;

const TeamMember = styled.div`
  position: relative;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled(DefaultLink)`
  margin-left: auto;
  margin-top: 15px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
`;

const MemberTable = styled.table`
  width: 100%;
  margin-bottom: 30px;

  thead {
    margin-bottom: 15px;
  }

  th,
  td {
    font-size: 14px;
  }

  th {
    padding-bottom: 15px;
    width: 20%;
    border-bottom: 1px solid #f0f1f2;
    vertical-align: bottom;
  }

  td {
    text-align: center;
    border-bottom: 1px solid #f0f1f2;
  }

  th:first-of-type {
    text-align: left;
  }

  td {
    padding: 15px 0px;

    &:first-of-type {
      text-align: left;
    }
  }

  tr td:last-of-type input {
    margin-right: 0px;
  }
`;

const MemberImage = styled(ProfileImage)``;

const StaffMemberContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
  }

  ${MemberImage} {
    margin: 0;
    margin-right: 25px;
  }
`;

const MainMemberDropdown = styled(SelectDropdown)`
  margin: auto;
  width: 180px;
`;

const BookedBy = styled(SelectDropdown)`
  width: 150px;
  margin: auto;
`;

const Quantity = styled(Input)`
  width: 80px;
  margin: auto;
  text-align: center;
`;

const StyledCloseButton = styled(CloseButton)`
  position: static;
  margin-right: 20px;
`;

export default ProjectPlan;
