import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { respondTo } from "../../../styles/styleHelpers";
import { highlightTags, getCurrency } from "../../../utils/helpers";

import Expense from "../../../components/Expense";
import Button from "../../../components/newButtons/Button";
import { Input, Textarea } from "../../../components/newForm";

const Expenses = ({
  expenseBudget,
  expenses,
  amount,
  message,
  setMessage,
  setAmount,
  postExpense,
}) => {
  let spent = 0;

  if (expenses) {
    expenses.forEach((expense) => {
      spent += parseFloat(expense.amount);
    });
  }

  return (
    <Container>
      <BudgetTable>
        <TableRow>
          <BudgetAmount>{getCurrency(expenseBudget || 0)}</BudgetAmount>
          <BudgetText>Expense Budget</BudgetText>
        </TableRow>
        <TableRow>
          <BudgetAmount>{getCurrency(spent || 0)}</BudgetAmount>
          <BudgetText>Spent to Date</BudgetText>
        </TableRow>
        <Line />
        <TableRow>
          <BudgetAmount
            danger={expenseBudget && spent && spent > expenseBudget}
          >
            {expenseBudget ? getCurrency(expenseBudget - spent) : "$0"}
          </BudgetAmount>
          <BudgetText>Remaining</BudgetText>
        </TableRow>
      </BudgetTable>

      <ExpenseList>
        {expenses ? (
          expenses.length ? (
            expenses.map((item, index) => (
              <Expense
                className="expense"
                key={index}
                author={item.author}
                postedDate={item.postedDate}
                amount={item.amount}
                message={highlightTags(item.message)}
              />
            ))
          ) : (
            <NoExpenses>There are no expenses yet</NoExpenses>
          )
        ) : null}
      </ExpenseList>

      {postExpense ? (
        <MessageContainer
          onSubmit={(e) => {
            e.preventDefault();
            postExpense();
          }}
        >
          <Amount
            type="number"
            value={amount}
            placeholder="Expense Amount"
            onChange={(e) => setAmount(e.target.value)}
          />
          <MessageInput
            value={message}
            placeholder="What was this expense for?"
            onChange={(e) => setMessage(e.target.value)}
          />
          <Send>Send</Send>
        </MessageContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ExpenseList = styled.div`
  position: relative;
  padding-right: 8px;
  margin-bottom: 30px;
  overflow-y: auto;
  height: 231px;

  display: flex;
  flex-direction: column-reverse;

  .expense:first-of-type {
    margin-bottom: auto;
  }
`;

const NoExpenses = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.slate};
  margin-top: auto;
  text-align: center;
  margin-bottom: auto;
  opacity: 0.7;
`;

const MessageContainer = styled.form`
  flex-shrink: 0;
  margin-top: auto;
`;

const Amount = styled(Input)`
  width: 100%;
  margin-bottom: 17px;
`;

const MessageInput = styled(Textarea)`
  height: 100px;
  width: 100%;
  margin-bottom: 20px;

  ${respondTo("xlarge")} {
    height: 150px;
  }
`;

const Send = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0px;
`;

const BudgetTable = styled.div`
  width: 100%;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Line = styled.hr`
  color: ${(props) => props.theme.colors.coolGray700};
`;

const BudgetAmount = styled.div`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) =>
    props.danger ? props.theme.colors.red : props.theme.colors.coolGray700};
`;

const BudgetText = styled.div`
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.coolGray400};
`;

Expenses.propTypes = {
  expenses: PropTypes.array,
};

export default Expenses;
