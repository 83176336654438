import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import { getMembersFromApi } from "../../utils/api";
import { getKpisFromApi } from "./utils/api";

import CreateLink from "../../components/links/CreateLink";
import SimpleLink from "../../components/links/SimpleLink";
import { TableHeader, TableRow, TableCell } from "../../components/Table";
import ProfileImage from "../../components/ProfileImage";

const Kpis = () => {
  const [kpis, setKpis] = useState([]);
  const [members, setMembers] = useState([]);
  const [sortBy, setSortBy] = useState("newest");

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const results = await getMembersFromApi();
      setMembers(results);
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all Kpis
  |--------------------------------------------------------------------------
  */
  const getKpis = async () => {
    try {
      const results = await getKpisFromApi();
      setKpis(results.reverse());
    } catch (error) {
      console.error("Error getting Kpis");
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the kpis
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedKpis = [...kpis];

    if (sortBy === "member-asc") {
      sortedKpis.sort(function(a, b) {
        var textA = a.member.toUpperCase();
        var textB = b.member.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "member-desc") {
      sortedKpis.sort(function(a, b) {
        var textA = a.member.toUpperCase();
        var textB = b.member.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "name-asc") {
      sortedKpis.sort(function(a, b) {
        let textA, textB;

        textA = a.type || a.name;
        textB = b.type || b.name;
        textA = textA.toUpperCase();
        textB = textB.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedKpis.sort(function(a, b) {
        let textA, textB;

        textA = a.type || a.name;
        textB = b.type || b.name;
        textA = textA.toUpperCase();
        textB = textB.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "target-asc") {
      sortedKpis.sort(function(a, b) {
        var textA = parseInt(a.target);
        var textB = parseInt(b.target);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "target-desc") {
      sortedKpis.sort(function(a, b) {
        var textA = parseInt(a.target);
        var textB = parseInt(b.target);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "frequency-asc") {
      sortedKpis.sort(function(a, b) {
        var textA = a.frequency.toUpperCase();
        var textB = b.frequency.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "frequency-desc") {
      sortedKpis.sort(function(a, b) {
        var textA = a.frequency.toUpperCase();
        var textB = b.frequency.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setKpis(sortedKpis);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get members and kpis on start
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getMembers();
    getKpis();
  }, []);

  return (
    <KpisContainer>
      <KpisHeader>
        <h1>Scoreboard KPIs</h1>
        <CreateLink as={Link} to={`/manage/kpi/new`}>
          Create Kpi
        </CreateLink>
      </KpisHeader>
      {kpis && kpis.length ? (
        <KpisList>
          <thead>
            <tr>
              <TableHeader
                onClick={() => changeSort("member")}
                isArrowUp={sortBy === "member-desc"}
                align="left"
              >
                Owner
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
              >
                KPI Name
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("target")}
                isArrowUp={sortBy === "target-desc"}
                align="left"
              >
                Target
              </TableHeader>
              <TableHeader
                onClick={() => changeSort("frequency")}
                isArrowUp={sortBy === "frequency-desc"}
                align="left"
              >
                Frequency
              </TableHeader>
              <TableHeader noArrow align="left">
                Results
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {members &&
              kpis &&
              kpis.map((kpi, index) => (
                <TableRow key={index}>
                  <KpisCell>
                    <ProfileImage
                      handle={
                        members.find((member) => member._id === kpi.member)
                          ?.handle
                      }
                      name={
                        members.find((member) => member._id === kpi.member)
                          ?.name
                      }
                      small
                    />
                  </KpisCell>
                  <TableCell align="left">
                    <SimpleLink as={Link} to={`/manage/kpi/${kpi._id}`}>
                      {kpi.type ? kpi.type : kpi.name}
                    </SimpleLink>
                  </TableCell>
                  <TableCell align="left">{kpi.target}</TableCell>
                  <TableCell align="left">{kpi.frequency}</TableCell>
                  <TableCell>
                    <ResultsContainer>
                      {kpi.results
                        .slice(0)
                        .reverse()
                        .map((kpi) => {
                          return (
                            <Result>
                              <p>{format(new Date(kpi.date), "MM-dd-yy")}</p>
                              <p>{kpi.value}</p>
                            </Result>
                          );
                        })}
                    </ResultsContainer>
                  </TableCell>
                </TableRow>
              ))}
          </tbody>
        </KpisList>
      ) : null}
    </KpisContainer>
  );
};

const KpisContainer = styled.div`
  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const KpisHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const KpisList = styled.table`
  padding: 50px;

  td,
  th {
    padding-left: 0px;
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 0px;
  }
`;

const KpisCell = styled(TableCell)`
  &:first-child {
    width: 30px;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  overflow: auto;
`;

const Result = styled.div`
  margin-right: 25px;
  flex-shrink: 0;

  p {
    margin-bottom: 0px;
  }

  &:last-of-type {
    margin-right: 0px;
  }
`;

export default Kpis;
