import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import TextareaAutosize from "react-textarea-autosize";
import { encode } from "html-entities";

import {
  highlightTags,
  replaceNewLineWithBr,
  replaceBrWithNewLine,
  decodeBr,
} from "../../utils/helpers";
import PencilEditFill from "../icons/PencilEditFill";
import Trash from "../icons/Trash";

import Tooltip from "../Tooltip";
import ProfileImage from "../ProfileImage";
import Menu from "../Menu";
import Dots from "../icons/DotsVertical";

const ChatMessage = ({
  author,
  postedDate,
  message,
  isMe,
  hideMeta,
  onEdit,
  onDelete,
}) => {
  const menuToggle = useRef(null);
  const textarea = useRef(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);

  useEffect(() => {
    // ? don't run this on the inital load, otherwise if there are many of these in the document at the same time the page will get bogged down and load very slowly
    if (hasLoaded && menuToggle.current) {
      if (isMenuOpen) {
        ReactTooltip.show(menuToggle.current);
        // ? only apply the listener when user is in edit mode (that way it isn't bogged down with a bunch of listeners)
        document.addEventListener("mousedown", clickListener);
      } else {
        ReactTooltip.hide(menuToggle.current);
      }
    }

    // denote that the initial load has happened
    setHasLoaded(true);

    return () => {
      document.removeEventListener("mousedown", clickListener);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    // when user goes into edit mode
    if (isEditing) {
      // hide the tooltip
      ReactTooltip.hide(menuToggle.current);

      // reset the edited text
      setEditedMessage(message);

      if (textarea.current) {
        // put focus on textarea
        textarea.current.focus();

        // put the cursor at the end of the textarea
        textarea.current.setSelectionRange(
          textarea.current.value.length,
          textarea.current.value.length,
        );
      }
    }
  }, [isEditing]);

  const clickListener = (e) => {
    // close the tooltip if user clicks outside
    if (menuToggle.current) {
      if (!menuToggle.current.contains(e.target) && isMenuOpen) {
        setIsMenuOpen(false);
      }
    }
  };

  const handleEditSave = () => {
    const trimmedMessage = editedMessage.trim();

    if (trimmedMessage) {
      // add in <br>s wherever the user added new lines
      const taggedMessage = replaceNewLineWithBr(editedMessage).trim();

      onEdit(taggedMessage, { author, postedDate, message });

      setIsEditing(false);
    }
  };

  // convert the <br> html tags into new lines
  const textareaValue = () => replaceBrWithNewLine(editedMessage);

  const messageValue = () => {
    // encode html elements into entities
    const encodedMessage = encode(message);

    // decode <br>s so the message still has line breaks
    const encodedMessageWithBr = decodeBr(encodedMessage);

    // add our own tags to the message where needed
    const taggedMessage = highlightTags(encodedMessageWithBr);

    return taggedMessage;
  };

  const formattedDate = postedDate
    ? format(new Date(postedDate), "h:mm a")
    : null;

  // don't show the name if the user is the author
  const name = !isMe ? author.name : null;

  const messageId = `${author._id}${
    postedDate ? new Date(postedDate).getTime() : ``
  }`;

  const actions = [];

  if (onEdit) {
    actions.push({
      text: "Edit",
      onClick: () => {
        setIsEditing(!isEditing);
      },
      icon: <PencilEditFill width="14" height="14" />,
    });
  }

  if (onDelete) {
    actions.push({
      text: "Delete",
      onClick: () => onDelete({ author, postedDate, message }),
      hasDanger: true,
      icon: <Trash width="13" height="15" color="rose500" />,
    });
  }

  return (
    <Container isReversed={isMe}>
      {!isMe ? (
        <ProfileImage
          handle={author.handle}
          name={author.name}
          xsmall
          showStatus
        />
      ) : null}

      <Body isReversed={isMe}>
        {!hideMeta && (name || formattedDate) ? (
          <Meta>
            {name ? <b>{name}</b> : null} {formattedDate ? formattedDate : null}
          </Meta>
        ) : null}

        <Message isReversed={isMe}>
          {/* <Bubble
            isHighlighted={isMe}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          /> */}

          <Bubble isHighlighted={isMe} isEditing={isEditing}>
            {isEditing ? (
              <>
                <EditInput
                  ref={textarea}
                  maxRows={20}
                  value={textareaValue()}
                  onChange={(e) => {
                    setEditedMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    // If they press cmd+enter in the textarea, we submit
                    if (e.metaKey && e.key === "Enter") {
                      handleEditSave();
                    }
                  }}
                />
                <BtnGroup>
                  <Submit
                    onClick={handleEditSave}
                    disabled={editedMessage.trim() ? false : true}
                  >
                    Save
                  </Submit>
                  <EditButton onClick={() => setIsEditing(false)}>
                    Cancel
                  </EditButton>
                </BtnGroup>
              </>
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: messageValue(),
                }}
              />
            )}
          </Bubble>

          {actions.length ? (
            <MenuButton
              ref={menuToggle}
              title="Message options"
              aria-label="Message options"
              data-tip
              data-for={messageId}
              data-event="none"
              data-event-off="none"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
              disabled={isEditing}
            >
              <Dots />
            </MenuButton>
          ) : null}

          <Tooltip id={messageId} place="bottom">
            <Menu items={actions} />
          </Tooltip>
        </Message>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: ${(props) => (props.isReversed ? "row-reverse" : "row")};

  font-family: ${(props) => props.theme.fontFamily_Inter};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* use padding instead of margin so it doesn't mess with the max-width */
  padding-left: ${(props) => (props.isReversed ? undefined : "16px")};
  padding-right: ${(props) => (props.isReversed ? "16px" : undefined)};

  max-width: 100%;
`;

const Meta = styled.div`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 12px;
  color: ${(props) => props.theme.colors.coolerGray};
  margin-bottom: 4px;

  b {
    font-weight: 500;
  }
`;

const Bubble = styled.div`
  font-size: 14px;
  line-height: 1.45;

  padding: 8px;

  color: ${(props) =>
    props.isEditing
      ? props.theme.colors.darkerGray
      : props.isHighlighted
      ? "white"
      : props.theme.colors.darkerGray};

  background-color: ${(props) =>
    props.isEditing
      ? props.theme.colors.indigo50
      : props.isHighlighted
      ? props.theme.colors.indigo
      : props.theme.colors.coolGray100};

  border-radius: 8px;
  border-bottom-left-radius: ${(props) =>
    props.isHighlighted ? undefined : 0};
  border-bottom-right-radius: ${(props) =>
    props.isHighlighted ? 0 : undefined};

  transition: color 200ms, background-color 200ms, box-shadow 200ms;

  &:focus-within {
    box-shadow: 0px 0px 0px 1px ${(props) => props.theme.colors.indigo};
  }

  p {
    margin-bottom: 0;
  }

  a {
    font-weight: 500;
    color: ${(props) =>
      props.isHighlighted ? "white" : props.theme.colors.darkerGray};

    // apply word break to links because they are urls
    word-break: break-all;
  }

  // Tags that are highlighted
  .highlighted {
    font-weight: 700;
  }
`;

const MenuButton = styled.button`
  position: absolute;
  bottom: 8px;
  right: 0;
  transform: translateX(100%);

  line-height: 0;
  padding: 2px 13px;
  cursor: pointer;

  opacity: 0;

  transition: opacity 200ms 200ms;

  /* reveal it when the Body is being hovered or when the button has focus */
  ${Body}:hover &:not(:disabled),
  &:focus:not(:disabled) {
    opacity: 1;
  }

  svg {
    path {
      stroke: ${(props) => props.theme.colors.coolerGray};
      transition: stroke 200ms;
    }
  }

  &:hover,
  &:focus {
    svg {
      path {
        stroke: ${(props) => props.theme.colors.darkerGray};
      }
    }
  }
`;

const Message = styled.div`
  position: relative;
  margin-left: ${(props) => (props.isReversed ? "auto" : undefined)};
  max-width: 100%;
`;

const EditInput = styled(TextareaAutosize)`
  display: block;

  /* force it to be as wide as possible, but keep it within its container */
  width: 100vw;
  max-width: 100%;

  color: inherit;
  background-color: inherit;
  border: 0;
  padding: 0;

  line-height: inherit;
  outline: none;
  resize: none;
`;

const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  margin-left: auto;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const EditButton = styled.button`
  font-size: 14px;
  padding: 4px 7px;
  color: ${(props) => props.theme.colors.darkerGray};
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.coolGray200};
  border-radius: 4px;

  transition: background-color 200ms, color 200ms;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.coolGray100};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.colors.coolGray500};
  }
`;

const Submit = styled(EditButton)`
  background-color: ${(props) => props.theme.colors.indigo};
  border-color: ${(props) => props.theme.colors.indigo};
  color: white;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo700};
  }

  &:disabled {
    border-color: ${(props) => props.theme.colors.coolGray500};
    background-color: ${(props) => props.theme.colors.coolGray500};
  }
`;

ChatMessage.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string,
    handle: PropTypes.string,
  }),
  message: PropTypes.string,
  isMe: PropTypes.bool,
  hideMeta: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
ChatMessage.defaultProps = {
  message: "",
  isMe: false,
  hideMeta: false,
};

export default ChatMessage;
