import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { format, eachDayOfInterval } from "date-fns";
import { startCase } from "lodash";

import { useFile } from "../../context/fileContext";

import { getAllDeliverablesFromApi, getMembersFromApi } from "../../utils/api";
import {
  filterItems,
  searchItems,
  getUniqueOptions,
} from "../../utils/sorting";

import ProfileImage from "../../components/ProfileImage";
import Filters, { FilterGroup } from "../../components/NewTable/Filters";
import FilterSelect from "../../components/NewTable/FilterSelect";
import SelectedFilters from "../../components/NewTable/SelectedFilters";
import Table from "../../components/NewTable/index";
import { TableCell, SearchInput } from "../../components/NewTable/components";
import PageLayout from "../sales/components/pageLayout";
import LineGraph from "../projects/components/LineGraph";
import { getStandardizedDate } from "../../utils/helpers";

// match filter labels with the actual path to fetch the property on the object
const filterPaths = {
  project: "project.name",
  client: "client.name",
  version: "file.versions.length",
  tier: "client.tier",
  owner: "owner.name",
  // TODO: find a way to get this logic to work for the dates
  // ? - format( new Date(item.shipBy[item.shipBy.length - 1].date), "MM/dd/yyyy" )
  // dueDate: "item.shipBy[item.shipBy.length - 1].date",
};

// filter chips
const filters = [
  "name",
  "project",
  "client",
  "tier",
  "owner",
  // TODO: setup submitBy, reviewBy, and shipBy as filterable options
  // "dueDate",
  "risk",
  "status",
  "version",
];

// options for the group by dropdown
const groupByOptions = [
  "status",
  "owner",
  "project",
  "client",
  "tier",
  "risk",
  "version",
];

// group by dropdown options
const groupBySelectOptions = [
  // first option is "None"
  { label: "None", value: null },
  // remaining options
  ...groupByOptions.map((option) => {
    // grab the proper path for this filter
    const value = filterPaths.hasOwnProperty(option)
      ? filterPaths[option]
      : option;

    return {
      label: startCase(option),
      value,
    };
  }),
];

const Deliverables = () => {
  const { openFile } = useFile();

  const [deliverables, setDeliverables] = useState([]);
  const [displayDeliverables, setDisplayDeliverables] = useState([]);
  const [activeDeliverables, setActiveDeliverables] = useState([]);
  const [overdueDeliverables, setOverdueDeliverables] = useState([]);
  const [members, setMembers] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [groupBy, setGroupBy] = useState(null);

  useEffect(() => {
    getMembers();
    getDeliverables();
  }, []);

  useEffect(() => {
    if (deliverables) {
      const newDisplay = filterDeliverables();
      setDisplayDeliverables(newDisplay);
    }
  }, [deliverables, searchText, selectedFilters]);

  /*
  |--------------------------------------------------------------------------
  | Filters the deliverables
  |--------------------------------------------------------------------------
  */
  const filterDeliverables = () => {
    let tempDelivs = [...deliverables];

    // Filters the delivs
    if (selectedFilters && selectedFilters.length > 0) {
      tempDelivs = filterItems(
        // Formats filters
        selectedFilters.map((filter) => {
          // Gets proper paths for each filter
          const path = filterPaths.hasOwnProperty(filter.label)
            ? filterPaths[filter.label]
            : filter.label;

          return {
            path,
            value: filter.value,
          };
        }),

        tempDelivs,
      );
    }

    // Filters out for search results
    if (searchText !== "") {
      tempDelivs = searchItems(searchText, tempDelivs, [
        "name",
        "status",
        "owner.name",
        "project.name",
        "client.name",
        "tier",
        "dueDate",
        "risk",
        "file.versions.length",
        "internalReview",
        "clientReview",
      ]);
    }

    return tempDelivs;
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    let tempMembers = await getMembersFromApi();

    tempMembers = tempMembers.map((member) => {
      return {
        _id: member._id,
        name: member.name,
        handle: member.handle,
      };
    });

    setMembers(tempMembers);
  };

  /*
  |--------------------------------------------------------------------------
  | Get all the deliverables
  |--------------------------------------------------------------------------
  */
  const getDeliverables = async () => {
    const tempDeliverables = await getAllDeliverablesFromApi();

    const tempActiveDeliverables = [];
    const tempOverdueDeliverables = [];

    // Only return deliverables that have a createdAt date set
    let sortedDeliverables = tempDeliverables.filter((deliverable) => {
      return deliverable.createdAt;
    });

    // Sort the deliverables by createdAt date
    sortedDeliverables = sortedDeliverables.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    });

    const start = getStandardizedDate(sortedDeliverables[0].createdAt);
    const end = getStandardizedDate(new Date());
    const dates = eachDayOfInterval({ start, end });

    // Loop over each date from the earliest createdAt date of the deliverables to today
    dates.forEach((oldDate) => {
      const date = getStandardizedDate(oldDate);
      let overdue = 0;
      let active = 0;

      // Loop over each deliverable to see which one will be marked as overdue or active
      sortedDeliverables.forEach((deliverable) => {
        const created = getStandardizedDate(deliverable.createdAt);
        const dueDate = deliverable.shipBy?.length
          ? getStandardizedDate(
              deliverable.shipBy[deliverable.shipBy.length - 1].date,
            )
          : null;
        const completedDate = deliverable.completedDate
          ? getStandardizedDate(deliverable.completedDate)
          : null;

        // Check to see if the date is after the deliverable createdAt date
        // AND the approved date doesn't exist, OR it does exist and it's after the current date
        if (
          date >= created &&
          (!completedDate || (completedDate && completedDate >= date))
        ) {
          // If the due date is past the current date OR the due date doesn't exist, mark it as active
          if ((dueDate && dueDate >= date) || !dueDate) {
            active++;
          }
          // If the dueDate is before the current date, mark it as overdue
          else if (dueDate && dueDate < date) {
            overdue++;
          }
        }
      });

      // For each date, log the amount of active and overdue tasks
      tempActiveDeliverables.push({
        x: date,
        y: active,
      });
      tempOverdueDeliverables.push({
        x: date,
        y: overdue,
      });
    });

    setDeliverables(tempDeliverables);
    setActiveDeliverables(tempActiveDeliverables);
    setOverdueDeliverables(tempOverdueDeliverables);
  };

  /*
  |--------------------------------------------------------------------------
  | Get unique options for a filter
  |--------------------------------------------------------------------------
  */

  // const getUniqueOptions = (filter) => {
  //   let options = deliverables
  //     .map((item) => {
  //       if (filter === "dueDate") {
  //         if (item.shipBy?.length) {
  //           return {
  //             label: format(
  //               new Date(item.shipBy[item.shipBy.length - 1].date),
  //               "MM/dd/yyyy",
  //             ),
  //             value: item.shipBy[item.shipBy.length - 1].date,
  //           };
  //         } else {
  //           return null;
  //         }
  //       } else if (filter) {
  //         return {
  //           label: item[filter],
  //           value: item[filter],
  //         };
  //       }
  //     })
  //     .filter((item) => item !== null);

  //   options = [...new Set(options.map((item) => item.label))];

  //   options = options.map((option) => {
  //     return {
  //       label: option,
  //       value: option,
  //     };
  //   });

  //   return options;
  // };

  // const activeDate = new Date();
  // const overdueDate = new Date("01-02-2021");
  // const active = [];
  // const overdue = [];

  // for (let i = 0; i < 200; i++) {
  //   active.push({
  //     x: addDays(activeDate, i),
  //     y: Math.floor(Math.random() * 100) + 10,
  //   });

  //   overdue.push({
  //     x: addDays(overdueDate, i),
  //     y: Math.floor(Math.random() * 50),
  //   });
  // }

  return (
    <PageLayout
      title="Deliverables"
      breadcrumbs={[{ name: "Reports" }, { name: "Deliverables" }]}
    >
      <Container>
        <Graphs>
          <GraphContainer>
            <LineGraph
              yLabel="Deliverables"
              entryLabel="Active Deliverables"
              lineColor="#22C55E"
              data={activeDeliverables}
            />
          </GraphContainer>
          <GraphContainer>
            <LineGraph
              yLabel="Overdue Deliverables"
              entryLabel="Overdue Deliverables"
              lineColor="#EF4444"
              data={overdueDeliverables}
            />
          </GraphContainer>
        </Graphs>

        <StyledFilterGroup>
          <Filters
            selectedFilters={selectedFilters}
            updateFilters={setSelectedFilters}
            filters={filters}
            getUniqueOptions={(filter) =>
              getUniqueOptions(filter, deliverables, {
                exclude: selectedFilters,
                propPaths: filterPaths,
              })
            }
          />
          <FilterSelect
            label="Group By"
            handleChange={(label, value) => setGroupBy(value)}
            defaultValue={groupBySelectOptions[0]}
            options={groupBySelectOptions}
          />

          <SearchInput
            placeholder="Search items..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </StyledFilterGroup>

        <StyledSelectedFilters
          filters={selectedFilters}
          updateFilters={setSelectedFilters}
        />

        <Table
          noPadding
          noShadow
          showAll={groupBy ? true : false}
          groupBy={groupBy}
          headers={[
            {
              name: "Deliverable",
              accessor: "name",
            },
            { name: "Project", accessor: "project.name" },
            { name: "Client", accessor: "client.name" },
            { name: "Tier", accessor: "client.tier" },
            {
              name: "Owner",
              accessor: "owner.name",
            },
            {
              name: "Due Date",
              accessor: "dueDate",
            },
            {
              name: "Risk",
              accessor: "risk",
            },
            {
              name: "Status",
              accessor: "status",
            },
            {
              name: "Version",
              accessor: "file.versions.length",
            },
            {
              name: "Internal Review",
              accessor: "openEdits",
            },
            {
              name: "Client Review",
              accessor: "clientReview",
            },
          ]}
          entries={
            displayDeliverables &&
            deliverables.length &&
            members &&
            members.length
              ? displayDeliverables.map((deliverable) => {
                  return {
                    ...deliverable,
                    dueDate: deliverable.shipBy?.length
                      ? new Date(
                          deliverable.shipBy[
                            deliverable.shipBy.length - 1
                          ].date,
                        ).toISOString()
                      : new Date(0).toISOString(),
                    row: (
                      <>
                        <TableCell full>
                          <StyledLink
                            disabled={!deliverable.file && !deliverable.link}
                            onClick={() => {
                              deliverable.file && deliverable.file._id
                                ? openFile(deliverable.file._id)
                                : null;
                            }}
                          >
                            {deliverable.name}
                          </StyledLink>
                        </TableCell>
                        <TableCell full>
                          {deliverable.project ? (
                            <StyledLink
                              as={Link}
                              to={`/projects/${deliverable.project._id}`}
                            >
                              {deliverable.project.name}
                            </StyledLink>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell full>
                          {deliverable.client && deliverable.client.acronym
                            ? deliverable.client.acronym
                            : deliverable.client && deliverable.client.name
                            ? deliverable.client.name
                            : "N/A"}
                        </TableCell>
                        <TableCell full>
                          {deliverable.client && deliverable.client.tier ? (
                            <Tier tier={deliverable.client.tier}>
                              {deliverable.client.tier}
                            </Tier>
                          ) : (
                            "N/A"
                          )}
                        </TableCell>
                        <TableCell full>
                          <ProfileImage
                            handle={deliverable.owner.handle}
                            name={deliverable.owner.name}
                            xsmall
                          />
                        </TableCell>

                        <TableCell full>
                          {deliverable.shipBy?.length
                            ? format(
                                getStandardizedDate(
                                  deliverable.shipBy[
                                    deliverable.shipBy.length - 1
                                  ].date,
                                ),
                                "MM/dd/yyyy",
                              )
                            : "N/A"}
                        </TableCell>

                        <TableCell full>
                          <Risk danger={deliverable.risk === "Off Track"}>
                            {deliverable.risk}
                          </Risk>
                        </TableCell>

                        <TableCell full>{deliverable.status}</TableCell>

                        <TableCell full>
                          {deliverable.file && deliverable.file.versions
                            ? `Version ${deliverable.file.versions.length}`
                            : "N/A"}
                        </TableCell>

                        <TableCell full>
                          {deliverable.openEdits} Open Edits
                        </TableCell>

                        <TableCell full>
                          {deliverable.clientReview || "N/A"}
                        </TableCell>
                      </>
                    ),
                  };
                })
              : []
          }
        />
      </Container>
    </PageLayout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;
  background-color: white;
`;

const StyledLink = styled.p`
  margin: 0px;
  cursor: pointer;
  color: ${(props) => (props.disabled ? "#6B7280" : "#5048e5")};
  text-decoration: none;
`;

const Tier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 50px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${(props) =>
    props.tier === "Tier 1"
      ? "#CCFBF1"
      : props.tier === "Tier 2"
      ? "#FFEDD5"
      : "#DBEAFE"};
  color: ${(props) =>
    props.tier === "Tier 1"
      ? "#0D9488"
      : props.tier === "Tier 2"
      ? "#EA580C"
      : "#2563EB"};
`;

const Risk = styled.span`
  color: ${(props) => (props.danger ? "#EF4444" : "#6B7280")};
`;

const Graphs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GraphContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  padding: 24px 24px 10px 24px;
  width: 49%;
  margin-bottom: 50px;

  text {
    fill: ${(props) => props.theme.colors.coolGray400};
    font-weight: 500;
    font-size: 12px;
  }

  .apexcharts-yaxis-title {
    transform: translateX(-5px);
  }
`;

const StyledFilterGroup = styled(FilterGroup)`
  position: relative;
  z-index: 6;

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledSelectedFilters = styled(SelectedFilters)`
  margin-bottom: 24px;
`;

export default Deliverables;
