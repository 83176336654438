import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import "cleave.js/dist/addons/cleave-phone.us";
import Cleave from "cleave.js/react";

export const Label = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 3px;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.coolGray700};
`;

export const Field = styled.div`
  margin-bottom: 24px;

  ${(props) =>
    props.row
      ? css`
          display: flex;
          justify-content: flex-end;
          align-items: center;

          ${Label} {
            margin-right: 30px;
            margin-bottom: 0;
            text-align: right;
          }
        `
      : ``}

  .choices {
    width: 300px;

    * {
      font-size: 14px;
    }
  }

  .choices__inner {
    border-radius: 15px;
    background: #e1e0e4;
    min-height: 50px;
    padding: 0px !important;
    border: 0px;
    display: flex;
    align-items: center;
  }

  .choices__list {
    padding: 0px;
  }

  .choices__item {
    padding-left: 15px;
  }
`;

export const Input = styled.input`
  box-sizing: border-box;
  outline: none;
  border: 1px solid #dae1f5;
  border-radius: 3px;
  background: white;
  color: #10182f;
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  padding: 0px 8px;
  height: 36px;
  width: 300px;
  max-width: 100%;

  transition-property: border, border-color, border-radius, background-color,
    color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  background: #ffffff;
  /* coolGray/200 */

  border: 1px solid #e5e7eb;
  /* shadow-base */

  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 4px;

  &::placeholder {
    color: #9ca3af;
  }

  &:focus,
  &:active {
    border: 1px solid #0079ff;
  }

  &:disabled {
    background-color: #fafbf9;
    border: 1px solid #dae1f5;
    cursor: no-drop;
  }

  ${(props) =>
    props.isInvalid
      ? css`
          border-color: ${props.theme.colors.red};
        `
      : ``}

  &[type='radio'] {
    border: none !important;
    box-shadow: none !important;
    color: ${(props) => props.theme.colors.indigo600};
  }
`;

export const FieldGroup = styled(Field)`
  display: flex;

  ${Field} {
    margin-right: 20px;
    margin-bottom: 0;
  }

  ${Input} {
    width: auto;
  }
`;

// range slider
export const Range = styled.input`
  width: 300px;
`;

const StyledTextarea = styled(Input)`
  resize: none;
  height: 100px;
  max-height: 75vh;
  padding: 10px;
`;

export const Textarea = ({ children, ...rest }) => (
  <StyledTextarea as="textarea" {...rest}>
    {children}
  </StyledTextarea>
);

export const MoneyInput = ({ ...props }) => {
  return (
    <Input
      as={Cleave}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        prefix: "$",
      }}
      {...props}
    />
  );
};

export const PhoneInput = ({ ...props }) => {
  return (
    <Input
      as={Cleave}
      options={{
        phone: true,
        phoneRegionCode: "US",
        delimiter: "-",
      }}
      {...props}
    />
  );
};

export const Checkbox = ({
  label,
  checked,
  onChange,
  disabled,
  type,
  small,
  name,
  value,
  ...rest
}) => {
  return (
    <CheckboxContainer disabled={disabled} {...rest}>
      <CheckInput
        type={type}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        value={value}
      />
      <CheckMark small={small} />
      {label ? <span>{label}</span> : null}
    </CheckboxContainer>
  );
};

Checkbox.propTypes = {
  type: PropTypes.string,
};
Checkbox.defaultProps = {
  type: "checkbox",
};

const CheckboxContainer = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;

  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-size: 14px;
  line-height: 1.7;
  color: ${(props) => props.theme.colors.coolGray700};

  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
  cursor: pointer;
  user-select: none;
`;

const CheckInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const CheckMark = styled.span`
  /* bg behind where the checkmark icon would be */
  position: relative;
  width: ${(props) => (props.small ? "16px" : "20px")};
  height: ${(props) => (props.small ? "16px" : "20px")};
  flex-shrink: 0;

  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.coolGray300};
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  box-shadow: ${(props) => props.theme.boxShadow};

  &:not(:last-child) {
    margin-right: ${(props) => (props.small ? "8px" : "16px")};
  }

  /* check icon */
  &::after {
    content: "";
    position: absolute;

    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms;
  }

  /* checkbox */
  input[type="checkbox"] ~ & {
    &::after {
      /* top: 2px;
      left: 6px; */
      top: 15%;
      left: 36%;

      /* width: 5px;
      height: 12px; */
      width: 25%;
      height: 65%;

      border: 2px solid white;
      border-top-width: 0;
      border-left-width: 0;

      transform: rotate(45deg);
    }
  }

  /* radio */
  input[type="radio"] ~ & {
    border-radius: 50%;

    &::after {
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      transform: translate(-50%, -50%);

      background-color: ${(props) => props.theme.colors.indigo600};
      border-radius: 50%;
    }
  }

  /* hovered while unchecked */
  ${CheckboxContainer}:hover input:not(:checked) ~ & {
    background-color: ${(props) => props.theme.colors.coolGray100};
  }

  /* checked */
  input:checked ~ & {
    border-color: ${(props) => props.theme.colors.indigo600};

    &::after {
      opacity: 1;
    }
  }
  input:checked[type="checkbox"] ~ & {
    background-color: ${(props) => props.theme.colors.indigo600};
  }
`;
