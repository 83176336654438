import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useAuth } from "../../../context/authContext";
import { useNotifications } from "../../../context/notificationsContext";

import { editProjectOnApi } from "../../manage/utils/api";

import ProjectBrief from "./stages/ProjectBrief";
import BudgetPlan from "./stages/BudgetPlan";

import StageProgress from "./StageProgress";
import Stage from "./Stage";

const Stages = ({
  stages,
  project,
  setProject,
  stageNumber,
  setStageNumber,
  projectBriefQuestions,
}) => {
  const { isManagerOrAdmin, isImportant, user } = useAuth();
  const { openAlertPopup } = useNotifications();
  const [currentStage, setCurrentStage] = useState(stageNumber);

  // our initial stage status object
  const initialStatus = {};

  for (const name of stages) {
    // each stage will be a property of the stagesStatus object, with their own properties of completed and dueDate
    initialStatus[name] = {
      completed: false,
      dueDate: null,
      completedDate: null,
    };
  }

  const [stagesStatus, setStagesStatus] = useState(initialStatus);

  useEffect(() => {
    // set the current stage number if the number passed in changes
    setCurrentStage(stageNumber);
  }, [stageNumber]);

  /*
  |--------------------------------------------------------------------------
  | Check for the current stage number to display
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (stageNumber) {
      // new stages status to apply updates to
      const updatedStatus = { ...stagesStatus };

      // loop over the stages
      stages.forEach((stageName, stageIndex) => {
        // all of the stages prior to the stageNumber are complete
        if (stageIndex < stageNumber - 1 && updatedStatus?.[stageName]) {
          updatedStatus[stageName].completed = true;
        }
      });

      // update the stagesStatus with our newly updated status
      setStagesStatus(updatedStatus);
    }
  }, [stageNumber]);

  /*
  |--------------------------------------------------------------------------
  | Set stage due dates if they exist
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (project && project.stages) {
      // new stages status to apply updates to
      const updatedStatus = { ...stagesStatus };

      // loop over the stages
      stages.forEach((stageName) => {
        if (project.stages[stageName]) {
          // set the due date for this stage
          updatedStatus[stageName].dueDate = project.stages[stageName]?.dueDate;

          // set whether it's been completed
          updatedStatus[stageName].completed =
            project.stages[stageName].completed;

          updatedStatus[stageName].completedDate =
            project.stages[stageName].completedDate;
        }
      });

      // update the stagesStatus with our newly updated status
      setStagesStatus(updatedStatus);
    }
  }, [project]);

  /*
  |--------------------------------------------------------------------------
  | Update a stage's due date on the backend
  |--------------------------------------------------------------------------
  */
  const updateDueDate = async (num, dueDate) => {
    try {
      const data = {
        projectId: project._id,
        database: {},
      };

      // new stages status to apply updates to
      const updatedStatus = { ...stagesStatus };

      // loop over the stages
      stages.forEach((stageName, stageIndex) => {
        // if this stage index matches the stage number that was modified
        if (stageIndex + 1 === num) {
          // update its due date
          updatedStatus[stageName].dueDate = dueDate;

          // set the query to update this particular stage in the database
          const databaseQuery = `stages.${stageName}.dueDate`;
          data.database = {
            [databaseQuery]: dueDate,
          };

          // update the project in state
          const tempProject = { ...project };
          tempProject.stages[stageName].dueDate = dueDate;
          setProject(tempProject);
        }
      });

      // update the stagesStatus with our newly updated status
      setStagesStatus(updatedStatus);

      await editProjectOnApi(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update the stages and stage number if a completed field has been changed
  |--------------------------------------------------------------------------
  */
  const updateStage = async (num, status) => {
    const data = {
      projectId: project._id,
      database: {},
    };

    // new stages status to apply updates to
    const updatedStatus = { ...stagesStatus };

    // loop over the stages
    stages.forEach((stageName, stageIndex) => {
      // if this stage index matches the stage number that was modified
      if (stageIndex + 1 === num) {
        updatedStatus[stageName].completed = status;

        // set the query to update this particular stage in the database
        const completedQuery = `stages.${stageName}.completed`;
        const completedDateQuery = `stages.${stageName}.completedDate`;

        let timestamp = null;

        // If the stage has been checked completed, get the current timestamp to pass to the backend
        if (status) {
          timestamp = new Date();
        }

        updatedStatus[stageName].completedDate = timestamp;

        // Add the timestamp and status to the stage on the backend
        data.database = {
          [completedQuery]: status,
          [completedDateQuery]: timestamp,
        };

        // update the project in state accordingly
        const updatedProject = { ...project };

        if (updatedProject.stages[stageName]) {
          updatedProject.stages[stageName].completed = status;
        } else {
          updatedProject.stages.stageName = {
            completed: status,
            dueDate: null,
            completedDate: null,
          };
        }

        setProject(updatedProject);
      }
    });

    // stage number we should jump to
    let jumpTo = null;

    // If the update status is true, set the stage number accordingly based on which stages are complete
    if (status) {
      // loop over the stages and find the earliest stage that isn't completed yet
      stages.forEach((stageName, stageIndex) => {
        // if jumpTo hasn't been set yet and this stage isn't completed
        if (!jumpTo && !updatedStatus[stageName].completed) {
          // then jump to this stage
          jumpTo = stageIndex + 1;
        }
      });

      // If all stages have been completed
      if (!jumpTo) {
        // Get all the time entries and calculate the total tracked hours to set the finalTrackedDollars in the backend
        try {
          // Set is_active to false for the project in harvest in order to archive it
          data.harvestId = project.harvestId;
          data.harvest = {
            is_active: false,
          };
          data.database.archived = true;

          const result = await editProjectOnApi(data);

          if (result) {
            openAlertPopup(
              "Success",
              "Project completed and archived on harvest!",
              true,
            );
            return result;
          }
        } catch (err) {
          if (err.database) {
            if (err.message) {
              openAlertPopup(
                "Success",
                `Project completed! ${err.message}`,
                true,
              );
            } else {
              openAlertPopup("Success", `Project completed!`, true);
            }
          } else {
            if (err.message) {
              openAlertPopup(
                "Failure",
                `Project completion failed! ${err.message}`,
              );
            } else {
              openAlertPopup(
                "Failure",
                "Project completion failed, please check with a dev for further details.",
              );
            }
          }
        }

        jumpTo = stages.length + 1;
      } else {
        await editProjectOnApi(data);
      }

      setStageNumber(jumpTo);
      setCurrentStage(jumpTo);
    } else {
      await editProjectOnApi(data);
      setStageNumber(num);
      setCurrentStage(num);
    }

    // update the stagesStatus with our newly updated status
    setStagesStatus(updatedStatus);
  };

  // components for each of the stages
  // (order doesn't matter)
  const stageComponents = stages.map((stageName, stageIndex) => {
    // default props shared by all the stages
    const stageProps = {
      name: stageName,
      isManagerOrAdmin: isManagerOrAdmin,
      completed: stagesStatus?.[stageName]?.completed ?? false,
      completedDate: stagesStatus?.[stageName]?.completedDate,
      dueDate: stagesStatus?.[stageName]?.dueDate,
      changeStage: (status) => updateStage(stageIndex + 1, status),
      updateDueDate: (dueDate) => updateDueDate(stageIndex + 1, dueDate),
      unlocked: stageIndex + 1 <= stageNumber && !project.archived,
    };

    return stageName === "budgetPlan" ? (
      <Stage
        {...stageProps}
        user={user}
        setProject={setProject}
        project={project}
        approvals={["Partnership Manager", "Production Manager"]}
        render={
          <BudgetPlan
            project={project}
            setProject={setProject}
            isManagerOrAdmin={isManagerOrAdmin}
            isImportant={isImportant}
          />
        }
      />
    ) : stageName === "brief" ? (
      <Stage
        {...stageProps}
        user={user}
        setProject={setProject}
        project={project}
        approvals={[
          "Director",
          "Partnership Manager",
          "Production Manager",
          // "Project Manager",
        ]}
        render={
          <ProjectBrief
            isImportant={isImportant}
            project={project}
            setProject={setProject}
            projectBriefQuestions={projectBriefQuestions}
          />
        }
      />
    ) : stageName === "salesBrief" ? (
      <Stage
        {...stageProps}
        user={user}
        setProject={setProject}
        project={project}
        approvals={["Production Manager"]}
        // render={
        //   <SalesBrief
        //     isImportant={isImportant}
        //     project={project}
        //     setProject={setProject}
        //   />
        // }
      />
    ) : (
      // default stage component
      // (checkbox for completed and a due date field)
      <Stage {...stageProps} checkToComplete />
    );
  });

  return (
    <div>
      <StageProgress
        stageNumber={stageNumber}
        currentStage={currentStage}
        archived={project.archived}
        setCurrentStage={setCurrentStage}
        stages={stages}
        stagesStatus={stagesStatus}
      />

      {stageNumber > stages.length && <p>All the stages have been completed</p>}

      <div>
        {/* use the stage component that corresponds with the current stage */}
        {stageComponents[currentStage - 1]}
      </div>
    </div>
  );
};

Stages.propTypes = {
  stages: PropTypes.array,
  stageNumber: PropTypes.number,
};

Stages.defaultProps = {
  stages: [],
  stageNumber: 0,
};

export default Stages;
