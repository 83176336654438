import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { getProjectHealthFromApi } from "../../utils/api";

import { ProjectLink } from "../../utils/helpers";

import ProgressBar from "../dashboard/components/ProgressBar";
import { TableRow, TableCell } from "../../components/Table";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const ProjectHealth = () => {
  const [highRisk, setHighRisk] = useState(null);
  const [mediumRisk, setMediumRisk] = useState(null); //eslint-disable-line
  const [lowRisk, setLowRisk] = useState(null);
  const [noRisk, setNoRisk] = useState(null);
  const [openedSection, setOpenedSection] = useState("");

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      const results = await getProjectHealthFromApi();
      setHighRisk(results.highRisk);
      setMediumRisk(results.mediumRisk);
      setLowRisk(results.lowRisk);
      setNoRisk(results.noRisk);
    } catch (error) {
      console.error("project health error", error);
    }
  };

  const toggleSection = (newSection) => {
    if (openedSection === newSection) {
      setOpenedSection("");
    } else {
      setOpenedSection(newSection);
    }
  };

  return (
    <ProjectsContainer>
      <ProjectsHeader>
        <h1>Project Health Report</h1>
      </ProjectsHeader>

      <Section
        title="High Risk"
        color="red"
        desc="Projects with an overdue deliverable or are over budget."
        noneMsg="No high risk projects."
        handleOpen={() => toggleSection("high")}
        isOpen={openedSection === "high"}
        projects={highRisk}
      />

      {/* // ? projects don't have chunks anymore */}
      {/* <Section
        title="Medium Risk"
        color="yellow"
        desc="Project that has an overdue chunk."
        noneMsg="No medium risk projects."
        handleOpen={() => toggleSection("medium")}
        isOpen={openedSection === "medium"}
        projects={mediumRisk}
      /> */}

      <Section
        title="Low Risk"
        color="blue"
        desc="Projects that have an overdue task."
        noneMsg="No low risk projects."
        handleOpen={() => toggleSection("low")}
        isOpen={openedSection === "low"}
        projects={lowRisk}
      />

      <Section
        title="No Risk"
        color="green"
        desc="Projects with a nothing overdue or over budget."
        noneMsg="No no-risk projects."
        handleOpen={() => toggleSection("no")}
        isOpen={openedSection === "no"}
        projects={noRisk}
      />
    </ProjectsContainer>
  );
};

const Section = ({
  title,
  color,
  desc,
  isOpen,
  handleOpen,
  noneMsg,
  projects,
}) => {
  return (
    <SectionContainer>
      <SectionHeader onClick={handleOpen}>
        <SectionHeaderTitle>{title}</SectionHeaderTitle>{" "}
        <SectionHeaderNum color={color}>
          {projects ? projects.length : "..."} Project
          {projects?.length === 1 ? "" : "s"}
        </SectionHeaderNum>
        <SectionHeaderDesc>{desc}</SectionHeaderDesc>
      </SectionHeader>
      <SectionContent
        animate={isOpen ? "open" : "closed"}
        variants={{
          open: { height: "auto" },
          closed: { height: 0 },
        }}
        transition={{ duration: 0.4 }}
      >
        <SectionInner>
          {projects?.length === 0 ? (
            <NoneMsg>{noneMsg}</NoneMsg>
          ) : (
            <ProjectsList>
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Revenue Booked</th>
                  <th>{/* placeholder for revenue progress bar */}</th>
                  <th>Revenue Billed</th>
                  {/* <th>Invoiced</th> */}
                  <th>Active Deliverables</th>
                  <th>Overdue Tasks</th>
                </tr>
              </thead>
              <tbody>
                {projects
                  ? projects.map((project, index) => {
                      const projectName = `${
                        project.code ? `${project.code} - ` : ``
                      }${project.name}`;

                      return (
                        <TableRow key={index}>
                          <Cell align="left">
                            <ProjectName>
                              <ProjectLink
                                name={projectName}
                                id={project._id}
                              />
                            </ProjectName>
                          </Cell>
                          <Cell align="left">
                            {currencyFormatter.format(project.revenueBooked) ||
                              "-"}
                          </Cell>
                          <TableCell>
                            <Progress
                              actual={project.revenueBilled}
                              budgeted={project.revenueBooked}
                              pace={null}
                            />
                          </TableCell>
                          <Cell align="left">
                            {currencyFormatter.format(project.revenueBilled) ||
                              "-"}
                          </Cell>
                          <Cell align="left">
                            {project.overdueDeliverables}
                          </Cell>
                          <Cell align="left">{project.overdueTasks}</Cell>
                        </TableRow>
                      );
                    })
                  : null}
              </tbody>
            </ProjectsList>
          )}
        </SectionInner>
      </SectionContent>
    </SectionContainer>
  );
};

const Cell = styled(TableCell)`
  padding-left: 0;
`;

const ProjectsContainer = styled.div`
  padding-bottom: 100px;
`;

const ProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const SectionContainer = styled.div`
  padding: 40px 0;
  margin-left: 50px;
  margin-right: 90px;
  border-top: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const SectionHeader = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const SectionHeaderTitle = styled.h3`
  margin-bottom: 0;
  margin-right: 40px;
`;

const SectionHeaderNum = styled.p`
  margin-bottom: 0;
  margin-right: 40px;
  font-weight: bold;
  color: ${(props) =>
    props.color === "red"
      ? props.theme.colors.red
      : props.color === "yellow"
      ? props.theme.colors.yellow
      : props.color === "blue"
      ? props.theme.colors.blue
      : props.theme.colors.green};
`;

const SectionHeaderDesc = styled.p`
  margin-bottom: 0;
  font-size: 12px;
`;

const SectionContent = styled(motion.div)`
  height: 0;
  overflow: hidden;
`;

const SectionInner = styled.div`
  padding: 50px 0;
`;

const ProjectsList = styled.table`
  width: 100%;

  th {
    text-align: left;
    font-size: 12px;
  }
`;

const ProjectName = styled.h5`
  font-size: 14px;
  font-weight: normal;
  margin: auto 0;
`;

const Progress = styled(ProgressBar)`
  width: 250px;
`;

const NoneMsg = styled.p`
  padding: 0px 0;
`;

export default ProjectHealth;
