import React, { useState, useRef, useContext, useEffect } from "react";
import { useNotifications } from "./notificationsContext";
import { useSockets } from "./socketsContext";
import { useAuth } from "./authContext";

import {
  postFileCommentOnApi,
  getFileFromApi,
  updateFileOnApi,
  editFileCommentsOnApi,
  getProjectDeliverablesFromApi,
  editDeliverableOnApi,
  getDeliverableFromApi,
} from "../utils/api";

const FileContext = React.createContext();

// * This is the context used for both the alert popup
// * and the notifications in the sidebar

const FileProvider = (props) => {
  const { startFileListeners } = useSockets();
  const { openAlertPopup } = useNotifications();
  const { user } = useAuth();

  const stateRef = useRef();
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [fileInfo, setFileInfo] = useState(null); // full file object
  const [fileType, setFileType] = useState("image"); // image or pdf
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [feedbackCompleted, setFeedbackCompleted] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentDeliverables, setCurrentDeliverables] = useState(null);
  const [currentDeliverable, setCurrentDeliverable] = useState(null);

  const [latestVersion, setLatestVersion] = useState(null);

  const [comments, setComments] = useState([]);
  stateRef.current = isFileModalOpen; // Sets the open as a ref so it always updates like it should for callback functions

  /*
  |--------------------------------------------------------------------------
  | Get file data any time it changes
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (fileInfo) {
      // handle the file type
      const fileExtension = getFileType(fileInfo.filePath);
      setFileType(fileExtension);

      // set the version to the most recent one (which will download the appropriate file)
      const tempLatestVersion = fileInfo.versions[fileInfo.versions.length - 1];

      if (firstLoad || isFileLoading) {
        setLatestVersion(tempLatestVersion);
      }

      if (!currentDeliverables) {
        getDeliverables(fileInfo.project, fileInfo._id);
      } else {
        currentDeliverables.forEach((deliverable) => {
          if (deliverable.file?._id === fileInfo._id) {
            setCurrentDeliverable(deliverable);
          }
        });
      }

      // handle the comments
      setComments(parseComments(fileInfo.comments));

      startFileListeners(fileInfo._id, {
        fileCommentCallback: liveUpdateComments,
        fileEditCommentCallback: liveUpdateEditComments,
      });

      setFirstLoad(false);
    }
  }, [fileInfo]); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Live update comments
  |--------------------------------------------------------------------------
  */
  const liveUpdateComments = (newComment) => {
    const tempComments = [...fileInfo.comments, newComment];
    const tempFileInfo = { ...fileInfo, comments: tempComments };

    setFileInfo(tempFileInfo);
  };

  const liveUpdateEditComments = (newComments) => {
    let totalCompleted = 0;
    const totalFeedback = newComments.length;

    newComments.forEach((comment) => {
      if (comment.completed) {
        totalCompleted += 1;
      }
    });

    const tempComments = [...newComments];
    const tempFileInfo = { ...fileInfo, comments: [...tempComments] };

    if (totalCompleted === totalFeedback) {
      setFeedbackCompleted(true);
    }

    setFileInfo(tempFileInfo);
  };

  /*
  |--------------------------------------------------------------------------
  | Gets the file type by checking the end of the file path
  |--------------------------------------------------------------------------
  */
  const getFileType = (path) => {
    // handle the file type
    if (path) {
      const filePathParts = path.split(".");
      const fileExtension = filePathParts[filePathParts.length - 1];

      if (fileExtension === "pdf") {
        return "pdf";
      } else {
        return "image";
      }
    } else {
      return "link";
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Parse comments
  |--------------------------------------------------------------------------
  */
  const parseComments = (parsingComments) => {
    return parsingComments.map((comment, index) => {
      let replies = 0;
      const tempComments = [...parsingComments];

      tempComments.forEach((tempComment) => {
        if (tempComment.parent === comment._id) {
          replies++;
        }
      });

      return {
        id: comment._id,
        parent: comment.parent,
        replies,
        comment: {
          author: comment.author,
          postedDate: comment.postedDate,
          message: comment.message,
          completed: comment.completed,
        },
        mark: comment.mark,
        page: comment.pageNumber,
        version: comment.version,
        highlighted: false,
        hovered: false,
        commentNum: index + 1,
      };
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Toggle modal
  |--------------------------------------------------------------------------
  */
  const toggleFileModal = () => setIsFileModalOpen(!stateRef.current);

  const openFile = async (fileId, deliverableId = null) => {
    const file = await getFileFromApi(fileId);

    if (deliverableId) {
      const deliverable = await getDeliverableFromApi(deliverableId);

      file.deliverable = deliverable;
    }

    setFileInfo(file);
    setIsFileModalOpen(true);
  };

  const closeFileModal = () => {
    setIsFileModalOpen(false);
    setFirstLoad(true);
    setFileInfo(null);
  };

  /*
  |--------------------------------------------------------------------------
  | Upload new version
  |--------------------------------------------------------------------------
  */
  const uploadNewVersion = async (fileUpload) => {
    try {
      setIsFileLoading(true);
      const newExtension = getFileType(fileUpload.name);

      if (newExtension !== fileType) {
        openAlertPopup(
          "Could not upload new version",
          `The new version of the file (${newExtension}) is a different file type than the original (${fileType}).`,
        );
        return;
      }

      // update the file with the newly uploaded version
      const updatedFile = await updateFileOnApi(fileInfo._id, {
        file: fileUpload,
      });

      // update the file info with the new version
      const newFileInfo = {
        ...updatedFile,
      };

      clearApprovals();

      // set the new file info (which will set the version number and download the newest file)
      setFileInfo(newFileInfo);
    } catch (err) {
      console.error(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Post comment
  |--------------------------------------------------------------------------
  */
  const postComment = async (comment) => {
    await postFileCommentOnApi(fileInfo._id, comment);
  };

  /*
  |--------------------------------------------------------------------------
  | Format comments back in order to update the file on the api
  |--------------------------------------------------------------------------
  */
  const reformatComments = (newComments) => {
    const formattedComments = newComments.map((comment) => {
      const tempComment = { ...comment };
      tempComment._id = tempComment.id;
      tempComment.pageNumber = tempComment.page;
      tempComment.author = tempComment.comment.author._id;
      tempComment.message = tempComment.comment.message;
      tempComment.postedDate = tempComment.comment.postedDate;
      tempComment.completed = tempComment.comment.completed;

      delete tempComment.comment;
      delete tempComment.page;
      delete tempComment.highlighted;
      delete tempComment.hovered;
      return tempComment;
    });

    return formattedComments;
  };

  /*
  |--------------------------------------------------------------------------
  | Edit comment
  |--------------------------------------------------------------------------
  */
  const editComment = async (id, message) => {
    let updatedComments = [...comments];

    updatedComments = updatedComments.map((comment) => {
      if (comment.id === id) {
        comment.comment.message = message;
      }
      return comment;
    });

    updatedComments = reformatComments(updatedComments);

    await editFileCommentsOnApi(fileInfo._id, {
      comments: updatedComments,
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Complete comment
  |--------------------------------------------------------------------------
  */
  const completeComment = async (id, status) => {
    let updatedComments = [...comments];

    updatedComments = updatedComments.map((comment) => {
      if (comment.id === id) {
        comment.comment.completed = status;
      }
      return comment;
    });

    updatedComments = reformatComments(updatedComments);

    await editFileCommentsOnApi(fileInfo._id, {
      comments: updatedComments,
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Delete comment
  |--------------------------------------------------------------------------
  */
  const deleteComment = async (id) => {
    let updatedComments = [...comments];

    // Remove comments with the id and anything with that has the id as its parent
    updatedComments = updatedComments.filter((comment) => {
      return comment.parent !== id && comment.id !== id;
    });

    updatedComments = reformatComments(updatedComments);

    await editFileCommentsOnApi(fileInfo._id, {
      comments: updatedComments,
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Get deliverables
  |--------------------------------------------------------------------------
  */
  const getDeliverables = async (projectId, fileId) => {
    const deliverables = await getProjectDeliverablesFromApi(projectId);

    deliverables.forEach((deliverable) => {
      if (deliverable.file?._id === fileId) {
        setCurrentDeliverable(deliverable);
      }
    });

    setCurrentDeliverables(deliverables);
  };

  /*
  |--------------------------------------------------------------------------
  | Update the deliverable on the backend and in state
  |--------------------------------------------------------------------------
  */
  const updateDeliverable = async (data) => {
    const tempDeliverables = [...currentDeliverables];

    // Edit deliverable on api and update the current deliverable/deliverables in state
    const updatedDeliverable = await editDeliverableOnApi(
      currentDeliverable._id,
      { data },
    );
    setCurrentDeliverable(updatedDeliverable);

    // Update the current deliverables in state with the updated deliverable
    const newDeliverables = tempDeliverables.map((deliverable) => {
      if (deliverable._id === currentDeliverable._id) {
        return {
          ...updatedDeliverable,
        };
      } else {
        return deliverable;
      }
    });

    setCurrentDeliverables(newDeliverables);
  };

  /*
  |--------------------------------------------------------------------------
  | Optional Approve deliverable
  |--------------------------------------------------------------------------
  */
  const optionalApproveDeliverable = () => {
    const optionalApprovedBy = currentDeliverable.optionalApprovedBy?.length
      ? [...currentDeliverable.optionalApprovedBy]
      : [];

    // Get the latest iteration
    const currentIteration =
      currentDeliverable.iterations[currentDeliverable.iterations.length - 1];

    // Add the current user to the optionalApprovedBy array
    optionalApprovedBy.push({
      approver: user._id,
      iteration: currentIteration._id,
      status: "approve",
    });

    const data = {
      optionalApprovedBy,
      action: "approve",
    };

    updateDeliverable(data);
  };
  /*
  |--------------------------------------------------------------------------
  | Approve deliverable
  |--------------------------------------------------------------------------
  */
  const approveDeliverable = () => {
    let status = currentDeliverable.status;
    let completelyApproved = true;
    const approvedBy = [...currentDeliverable.approvedBy];

    // Get the latest iteration
    const currentIteration =
      currentDeliverable.iterations[currentDeliverable.iterations.length - 1];

    // Add the current user to the approvedBy array with a status of approve
    approvedBy.push({
      approver: user._id,
      iteration: currentIteration._id,
      status: "approve",
    });

    // get the approvals for this iteration and filter out approvals that include this iteration with a status of approve
    const approvals = approvedBy.filter(
      (approval) =>
        approval.iteration === currentIteration._id &&
        approval.status === "approve",
    );

    // Loop through each approver for the deliverable to see if the deliverable is completely approved
    currentDeliverable.approvers.forEach((approver) => {
      // If the approvals for this iteration include the approver in the current loop
      if (!approvals.some((approval) => approval.approver === approver._id)) {
        completelyApproved = false;
      }
    });

    // if its completely approved
    if (completelyApproved) {
      // update its status
      status = "Approved";
    }
    // if its not completely approved, but its status says "Approved"
    else if (status === "Approved") {
      // move its status back into review
      status = "In review";
    }

    const data = {
      approvedBy,
      status,
      action: "approve",
    };

    updateDeliverable(data);
  };

  /*
  |--------------------------------------------------------------------------
  | Deny deliverable
  |--------------------------------------------------------------------------
  */
  const denyDeliverable = () => {
    const approvedBy = [...currentDeliverable.approvedBy];

    // Get the latest iteration
    const currentIteration =
      currentDeliverable.iterations[currentDeliverable.iterations.length - 1];

    // Add the current user to the approvedBy array with a status of deny
    approvedBy.push({
      approver: user._id,
      iteration: currentIteration._id,
      status: "deny",
    });

    const data = {
      approvedBy,
      status: "To revise",
      action: "deny",
    };

    updateDeliverable(data);
  };

  /*
  |--------------------------------------------------------------------------
  | Submit deliverable for review
  |--------------------------------------------------------------------------
  */
  const submitForReview = async () => {
    const iterations = currentDeliverable.iterations
      ? [...currentDeliverable.iterations]
      : [];
    iterations.push({});

    const data = {
      status: "In review",
      action: "review",
      iterations,
    };

    updateDeliverable(data);
  };

  /*
  |--------------------------------------------------------------------------
  | Clear approvals
  |--------------------------------------------------------------------------
  */
  const clearApprovals = () => {
    const data = {
      approvedBy: [],
      status: "In progress",
    };

    updateDeliverable(data);
  };

  return (
    <FileContext.Provider
      value={{
        isFileModalOpen,
        closeFileModal,
        toggleFileModal,

        isFileLoading,
        setIsFileLoading,
        setFileInfo,
        openFile,
        fileInfo,
        fileType,
        uploadNewVersion,
        comments,
        setComments,

        editComment,
        deleteComment,
        postComment,
        completeComment,
        feedbackCompleted,
        currentDeliverable,
        currentDeliverables,
        setCurrentDeliverables,
        denyDeliverable,
        approveDeliverable,
        optionalApproveDeliverable,
        submitForReview,
        latestVersion,
      }}
      {...props}
    >
      {props.children}
    </FileContext.Provider>
  );
};

const useFile = () => useContext(FileContext);

export { FileProvider, useFile, FileContext };
