import React, { useState } from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";

import { useNotifications } from "../../../context/notificationsContext";

import { createTaskGeneratorOnApi } from "../utils/api";

import { Field, Label, Input, Textarea } from "../../../components/Form";
import SelectDropdown from "../../../components/SelectDropdown";
import Button from "../../../components/buttons/Button";
import Checkbox from "./Checkbox";
import ProfileImage from "../../../components/ProfileImage";

const CreateTaskGenerator = ({
  generatorModalActive,
  setGeneratorModalActive,
  getTaskGenerators,
  members,
  projects,
}) => {
  const { openAlertPopup } = useNotifications();
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [repeatEvery, setRepeatEvery] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [permanent, setPermanent] = useState(false);
  const [memberArray, setMemberArray] = useState([]);
  const [weekArray, setWeekArray] = useState([]);
  const [linkText, setLinkText] = useState(null);
  const [linkUrl, setLinkUrl] = useState(null);

  const weekdays = ["M", "TU", "W", "TH", "F", "SA", "SU"];

  /*
  |--------------------------------------------------------------------------
  | Check to see if a member is added to the project
  |--------------------------------------------------------------------------
  */
  const toggleMember = (id) => {
    const tempMemberArray = [...memberArray];

    if (tempMemberArray && tempMemberArray.includes(id)) {
      const index = tempMemberArray.indexOf(id);

      if (index !== -1) {
        tempMemberArray.splice(index, 1);
      }
    } else {
      tempMemberArray.push(id);
    }

    setMemberArray(tempMemberArray);
  };

  /*
|--------------------------------------------------------------------------
| Check to see if a week is added to the project
|--------------------------------------------------------------------------
*/
  const toggleWeek = (id) => {
    const tempWeekArray = [...weekArray];

    if (tempWeekArray && tempWeekArray.includes(id)) {
      const index = tempWeekArray.indexOf(id);

      if (index !== -1) {
        tempWeekArray.splice(index, 1);
      }
    } else {
      tempWeekArray.push(id);
    }

    setWeekArray(tempWeekArray);
  };

  /*
  |--------------------------------------------------------------------------
  | Create task generator
  |--------------------------------------------------------------------------
  */
  async function createTaskGenerator() {
    try {
      const data = {
        title,
        projectId,
        members: memberArray,
        description,
        frequency,
        repeatOn: weekArray,
        repeatEvery,
        weekArray,
        startDate,
        endDate,
        permanent,
        link: {
          text: linkText,
          url: linkUrl,
        },
      };

      const result = await createTaskGeneratorOnApi(data);

      if (result) {
        openAlertPopup("Success", "Recurring task created successfully!", true);
        getTaskGenerators();
        setGeneratorModalActive(false);
      }
      return result;
    } catch (error) {
      openAlertPopup("Failure", "Recurring task creation failed!");
    }
  }

  return (
    <>
      {generatorModalActive && (
        <CreateTaskContainer>
          <CreateTask>
            <CreateTaskInner>
              <CreateTaskLeft>
                <CreateTaskHeader>Recurring Task</CreateTaskHeader>
                <FlexRow>
                  <StyledField>
                    <Label>Frequency</Label>
                    <StyledSelectDropdown
                      onChange={(e) => setFrequency(e.value)}
                      placeholder="Select Frequency"
                      options={[
                        {
                          label: "Day",
                          value: "Day",
                        },
                        {
                          label: "Week",
                          value: "Week",
                        },
                        {
                          label: "Month",
                          value: "Month",
                        },
                        {
                          label: "Year",
                          value: "Year",
                        },
                      ]}
                    ></StyledSelectDropdown>
                  </StyledField>
                  <StyledField>
                    <Label>
                      Repeat every {repeatEvery}{" "}
                      {frequency && frequency.toLowerCase()}
                      {repeatEvery && repeatEvery >= 2 ? "s" : ""}
                    </Label>
                    <Input
                      type="number"
                      onChange={(e) => setRepeatEvery(e.target.value)}
                    />
                  </StyledField>
                </FlexRow>

                <Field>
                  <Label>Repeat on</Label>

                  <Options>
                    {weekdays &&
                      weekdays.map((item) => {
                        return (
                          <Option
                            onClick={() => toggleWeek(item)}
                            selected={weekArray.includes(item)}
                            clickable
                          >
                            {item}
                          </Option>
                        );
                      })}
                  </Options>
                </Field>

                <FlexRow>
                  <StyledField>
                    <Label>Link Text</Label>
                    <Input
                      type="text"
                      onChange={(e) => setLinkText(e.target.value)}
                    />
                  </StyledField>
                  <StyledField>
                    <Label>Link Url</Label>
                    <Input
                      type="text"
                      onChange={(e) => setLinkUrl(e.target.value)}
                    />
                  </StyledField>
                </FlexRow>

                <FlexRow>
                  <StyledField>
                    <Label>Start Date</Label>
                    <Input
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </StyledField>
                  <StyledField>
                    <Label>End Date</Label>
                    <Input
                      type="date"
                      disabled={permanent}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </StyledField>
                </FlexRow>

                <CheckboxContainer>
                  <Checkbox
                    label="No End Date"
                    onChange={() => setPermanent(!permanent)}
                  ></Checkbox>
                </CheckboxContainer>
              </CreateTaskLeft>

              <CreateTaskRight>
                <Field>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Field>

                <Field>
                  <Label>Project</Label>
                  {projects && (
                    <StyledSelectDropdown
                      onChange={(e) => setProjectId(e.value)}
                      placeholder="Select Project"
                      options={projects.map((item) => {
                        return {
                          label: item.name,
                          value: item._id,
                        };
                      })}
                    ></StyledSelectDropdown>
                  )}
                </Field>

                <Field>
                  <Label>Assigned To</Label>
                  <Options>
                    {members &&
                      members.map((item) => {
                        return (
                          <StyledProfileImage
                            title={item.name}
                            onClick={() => toggleMember(item._id)}
                            selected={memberArray.includes(item._id)}
                            handle={item.handle}
                            name={item.name}
                            clickable
                          ></StyledProfileImage>
                        );
                      })}
                  </Options>
                </Field>

                <Field>
                  <Label>Task Details</Label>
                  <Textarea
                    style={{ height: "130px" }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Field>

                <ButtonRow>
                  <Cancel onClick={() => setGeneratorModalActive(false)}>
                    Cancel
                  </Cancel>
                  <Save
                    disabled={
                      !(
                        title &&
                        projectId &&
                        memberArray &&
                        memberArray.length &&
                        description &&
                        frequency &&
                        weekArray &&
                        weekArray.length &&
                        repeatEvery &&
                        startDate &&
                        (endDate || permanent) &&
                        linkText &&
                        linkUrl
                      )
                    }
                    onClick={() => createTaskGenerator()}
                  >
                    Save
                  </Save>
                </ButtonRow>
              </CreateTaskRight>
            </CreateTaskInner>
          </CreateTask>
        </CreateTaskContainer>
      )}
    </>
  );
};

const CreateTaskContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
`;

const CreateTask = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.4);
  z-index: 4;
  width: 65%;
`;

const CreateTaskInner = styled.div`
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
`;

const CreateTaskLeft = styled.div`
  width: 40%;
`;

const CreateTaskHeader = styled.h3`
  font-size: 24px;
  margin-bottom: 60px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    width: 40%;
  }
`;

const StyledField = styled(Field)`
  width: 45%;

  input {
    width: 100%;
  }
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 20px;

  * {
    font-size: 14px;
  }
`;

const CreateTaskRight = styled.div`
  width: 40%;

  input,
  textarea {
    width: 100%;
  }
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px -5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.selected ? darken(0.3, "#7BC1A8") : "#7BC1A8"};
  color: white;
  font-weight: bold;
  line-height: 0;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  height: 35px;
  width: 35px;
  font-size: 12px;
  margin: 5px;
  ${(props) =>
    props.clickable
      ? css`
          cursor: pointer;

          &:hover {
            opacity: ${(props) => (props.selected ? "1" : ".6")};
          }
        `
      : `cursor: default;`}
`;

const StyledProfileImage = styled(ProfileImage)`
  ${(props) => (props.clickable ? `cursor: pointer;` : `cursor: default;`)}
  width: 37px;
  height: 37px;
  margin: 5px;
  border: ${(props) =>
    props.selected ? "3px solid #0079FF" : "3px solid white"};

  * {
    font-size: 14px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Cancel = styled(Button)`
  margin-right: 20px;
  background-color: #df5757;
  border-color: #df5757;
  min-width: auto;
  width: 120px;

  &::after {
    background-color: ${darken(0.1, "#df5757")};
  }
`;

const Save = styled(Button)`
  min-width: auto;
  width: 120px;
`;

export default CreateTaskGenerator;
