import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _ from "lodash";

import CreateIcon from "../icons/CreateIcon";
import {
  TableOuter,
  TableInner,
  Table,
  TableBody,
  TableRow,
  TableCell,
  AddBtn,
} from "./components";
import TableHead from "./TableHead";
import TableFooter from "./TableFooter";
import { sortItems, paginateItems } from "../../utils/sorting";

/**
 * A reusable table component
 * @param {object[]} headers The header info in an array of objects
 * @param {component} [headers.component] Optional: a component to be used in the header cell, replacing the regular component
 * @param {component} headers.name The title, to be used in the cell
 * @param {component} headers.accessor The property path to check against when sorting, in dot notation
 * @param {object[]} entries The data of the entries you are passing in. Includes full object properties (needed for sorting/filtering)
 * @param {component|function} entries.row The actual row of TableCells for each entry. It should be a <></> with the right amount of TableCell components within it. A TableRow is added to each automatically.
 * @param {component} entries.rowProps Any props that should be applied to the TableRow that contains all the TableCells for that entry
 * @param {number} [perPageDefault] If you want the table to start with a certain number of items per page by default. (Defaults to 10)
 * @param {number[]} [perPageOptions] The available options for the number of entries per page (Defaults to [10, 25, 50, 100])
 * @param {string} [groupBy] Optional: Passes in the prop in dot notation, to group them by
 * @param {function} [handleCreate] Optional: If you want a create row, this is what sends that data back up to the parent
 * @param {component} [createRow] Optional: The row that is displayed for the create new (at the end of the table)
 * @param {string} [defaultSort] Optional: The default sorting option
 * @param {boolean} [showAll] Optional: Shows all items without any pagination
 * @param {boolean} [hideHead] Optional: Hides the table head
 * @param {boolean} [hideFooter] Optional: Hides the table footer
 * @param {boolean} [noPadding] Optional: Removes the padding on the table
 * @param {boolean} [noShadow] Optional: Removes the box-shadow on the table
 * @returns {component} The full table with pagination
 */

const NewTable = ({
  headers,
  entries,
  perPageDefault = 10,
  perPageOptions = [10, 25, 50, 100],
  groupBy = null,
  handleCreate,
  createRow,
  defaultSort = null,
  showAll = true,
  scrollable = undefined,
  hideHead = false,
  hideFooter = false,
  noPadding = false,
  noShadow = false,
  ...rest
}) => {
  const [sortBy, setSortBy] = useState(defaultSort ? defaultSort : "");

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(perPageDefault);
  const [numOfPages, setNumOfPages] = useState(1);
  const [displayItems, setDisplayItems] = useState([]);

  // if scrollable prop is provided, use its value. Otherwise, use showAll's value
  const canScroll = typeof scrollable !== "undefined" ? scrollable : showAll;

  useEffect(() => {
    if (entries) {
      let { items: filteredEntries, numPages } = filterEntries(entries); //eslint-disable-line

      let finalItems = [];

      if (groupBy) {
        numPages = 1;
        finalItems = groupEntries(filteredEntries, groupBy);
      } else {
        finalItems = filteredEntries;
      }

      setNumOfPages(numPages);
      setDisplayItems(finalItems);
    }
  }, [entries, sortBy, currentPage, perPage, groupBy]);

  const filterEntries = (newData) => {
    let tempData = [...newData];
    let numPages = 1;

    if (sortBy) {
      const sort = sortBy.split("-")[0];
      const sortDirection = sortBy.split("-")[1];

      tempData = sortItems(sort, tempData, {
        isReverse: sortDirection === "asc",
      });
    }

    if (!showAll) {
      ({ items: tempData, numPages } = paginateItems(
        tempData,
        currentPage,
        perPage,
      ));
    }

    return { items: tempData, numPages: numPages || 1 };
  };

  const groupEntries = (newEntries, groupBy) => {
    const newGroupedEntries = _(newEntries)
      .groupBy(groupBy)
      .map((items, key) => ({
        label: key,
        entries: items,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))
      .value();

    return newGroupedEntries;
  };

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  return (
    <TableWrapper {...rest}>
      {// there is a groupBy, and the items have been given a label property
      groupBy &&
      displayItems?.length &&
      displayItems[0].hasOwnProperty("label") ? (
        // Grouped tables
        <TableOuter
          noPadding={noPadding}
          noShadow={noShadow}
          scrollable={canScroll}
        >
          <TableInner scrollable={canScroll} data-table-inner>
            {displayItems.map((group, groupIndex) => (
              <Group key={groupIndex}>
                {/* flex so the header can be made sticky */}
                <div style={{ display: "flex" }}>
                  <GroupHeader className="table__group-header">
                    {group.label &&
                    group.label !== "undefined" &&
                    group.label !== "null"
                      ? group.label
                      : "N/A"}
                  </GroupHeader>
                </div>
                <Table>
                  {hideHead ? null : (
                    <TableHead
                      sortBy={sortBy}
                      changeSort={changeSort}
                      headers={headers}
                      entries={group.entries}
                    />
                  )}
                  <TableBody>
                    {group.entries &&
                      group.entries.map((item, index) =>
                        item.row ? (
                          <TableRow
                            key={`${groupIndex}-${index}`}
                            {...item.rowProps}
                          >
                            {/* if the row is a function, provide the items in this group as an argument */}
                            {typeof item.row === "function"
                              ? item.row(group.entries)
                              : item.row}
                          </TableRow>
                        ) : null,
                      )}
                  </TableBody>
                </Table>
              </Group>
            ))}

            {createRow ? (
              <TableRow key={`new`} sticky dark style={{ zIndex: 5 }}>
                {createRow}

                {handleCreate !== false ? (
                  <TableCell style={{ padding: 0 }}>
                    <StyledAddBtn
                      onClick={handleCreate ? handleCreate : undefined}
                      disabled={!handleCreate}
                    >
                      <CreateIcon />
                    </StyledAddBtn>
                  </TableCell>
                ) : null}
              </TableRow>
            ) : null}
          </TableInner>
        </TableOuter>
      ) : (
        // Single Table
        <>
          <TableOuter
            noPadding={noPadding}
            noShadow={noShadow}
            scrollable={canScroll}
          >
            <TableInner scrollable={canScroll} data-table-inner>
              <Table>
                {hideHead ? null : (
                  <TableHead
                    sortBy={sortBy}
                    changeSort={changeSort}
                    headers={headers}
                  />
                )}
                <TableBody>
                  {displayItems?.length
                    ? displayItems.map((item, index) =>
                        item.row ? (
                          <TableRow
                            id={item._id}
                            key={index}
                            {...item.rowProps}
                          >
                            {/* if the row is a function, provide the current items as an argument */}
                            {typeof item.row === "function"
                              ? item.row(displayItems)
                              : item.row}
                          </TableRow>
                        ) : null,
                      )
                    : null}

                  {createRow && numOfPages === currentPage + 1 ? (
                    <TableRow key={`new`} sticky dark>
                      {createRow}

                      {handleCreate !== false ? (
                        <TableCell style={{ padding: 0 }}>
                          <StyledAddBtn
                            onClick={handleCreate ? handleCreate : undefined}
                            disabled={!handleCreate}
                          >
                            <CreateIcon />
                          </StyledAddBtn>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableInner>
          </TableOuter>

          {hideFooter || showAll ? null : (
            <TableFooter
              numEntries={entries?.length || 0}
              perPage={perPage}
              perPageDefault={{
                label: `${perPageDefault} rows`,
                value: perPageDefault,
              }}
              perPageOptions={perPageOptions}
              changePerPage={setPerPage}
              currentPage={currentPage}
              changePage={setCurrentPage}
            />
          )}
        </>
      )}
    </TableWrapper>
  );
};

const TableWrapper = styled.div``;

const Group = styled.div`
  margin-bottom: 24px;
`;

const GroupHeader = styled.h3`
  font-family: ${(props) => props.theme.fontFamily_Inter};
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;

  color: ${(props) => props.theme.colors.black};

  margin-bottom: 24px;
`;

const StyledAddBtn = styled(AddBtn)`
  position: absolute;
  top: 50%;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translate(100%, -50%);
`;

export default NewTable;
