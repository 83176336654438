import React from "react";
import styled from "styled-components";

export default ({ ...rest }) => {
  return (
    <Svg
      width="30"
      height="38"
      viewBox="0 0 30 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M.455 0C.217 0 0 .201 0 .422v37.156A.442.442 0 00.455 38h29.09c.238 0 .455-.201.455-.422V6.333a.408.408 0 00-.128-.29L23.51.132A.474.474 0 0023.181 0H.455zm.454.844h21.818v5.49a.438.438 0 00.455.422h5.909v30.4H.909V.844zm22.727.607l4.802 4.46h-4.802v-4.46zM6.364 8.867c-1 0-1.819.76-1.819 1.689 0 .927.82 1.688 1.819 1.688.998 0 1.818-.76 1.818-1.688 0-.928-.82-1.69-1.818-1.69zm0 .844c.507 0 .909.373.909.845 0 .471-.402.844-.91.844-.507 0-.908-.373-.908-.844 0-.472.401-.845.909-.845zm6.363.422c-.25 0-.454.19-.454.423 0 .233.203.422.454.422H25c.251 0 .454-.19.454-.422 0-.234-.203-.423-.454-.423H12.727zm-6.363 5.49c-1 0-1.819.76-1.819 1.688 0 .928.82 1.689 1.819 1.689.998 0 1.818-.761 1.818-1.689s-.82-1.689-1.818-1.689zm0 .844c.507 0 .909.373.909.844s-.402.845-.91.845c-.507 0-.908-.373-.908-.845 0-.471.401-.844.909-.844zm6.363.422c-.25 0-.454.189-.454.422 0 .233.203.422.454.422H25c.251 0 .454-.189.454-.422 0-.233-.203-.422-.454-.422H12.727zm-6.363 5.489c-1 0-1.819.76-1.819 1.689 0 .927.82 1.689 1.819 1.689.998 0 1.818-.762 1.818-1.69 0-.927-.82-1.688-1.818-1.688zm0 .844c.507 0 .909.373.909.845 0 .471-.402.844-.91.844-.507 0-.908-.373-.908-.844 0-.472.401-.845.909-.845zm6.363.422c-.25 0-.454.19-.454.423 0 .233.203.422.454.422H25c.251 0 .454-.19.454-.422 0-.233-.203-.423-.454-.423H12.727zm-6.363 5.49c-1 0-1.819.76-1.819 1.688 0 .928.82 1.69 1.819 1.69.998 0 1.818-.762 1.818-1.69 0-.927-.82-1.689-1.818-1.689zm0 .844c.507 0 .909.373.909.844 0 .472-.402.845-.91.845-.507 0-.908-.373-.908-.845 0-.471.401-.844.909-.844zm6.363.422c-.25 0-.454.189-.454.422 0 .233.203.422.454.422H25c.251 0 .454-.189.454-.422 0-.233-.203-.422-.454-.422H12.727z" />
    </Svg>
  );
};

const Svg = styled.svg`
  path {
    fill: ${(props) => props.theme.colors.mediumGray};
  }
`;
