import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format, isPast, isToday } from "date-fns";

import { getStandardizedDate } from "../../../utils/helpers";

import ProfileImage from "../../../components/ProfileImage";
import Table from "../../../components/NewTable/index";
import { TableCell } from "../../../components/NewTable/components";

const OverdueTasks = ({ phases, tasks = [], deliverables = [] }) => {
  const [overdueTasks, setOverdueTasks] = useState([]);

  useEffect(() => {
    setOverdueTasks(
      tasks
        // Only get tasks with due dates in the past that are not yet marked as completed
        .filter(
          (task) =>
            task.date &&
            isPast(getStandardizedDate(task.date)) &&
            !isToday(getStandardizedDate(task.date)) &&
            task.status !== "Completed",
        )
        // sort by date
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
    );
  }, [tasks]);

  return (
    <Container>
      <Table
        noPadding
        headers={[
          {
            name: "Status",
            accessor: "status",
          },
          {
            name: "Owner",
            accessor: "memberId.handle",
          },
          {
            name: "Tasks",
            accessor: "title",
          },
          {
            name: "Deliverable",
            accessor: "deliverable",
          },
          {
            name: "Phase",
            accessor: "phase",
          },
          {
            name: "Due Date",
            accessor: "date",
          },
        ]}
        entries={
          overdueTasks
            ? overdueTasks.map((task) => {
                const taskPhase = phases.find(
                  (phase) => phase._id === task.phase,
                );

                const taskDeliverable = deliverables?.find(
                  (deliverable) => deliverable._id === task.deliverableId,
                );

                return {
                  ...task,
                  phase: taskPhase?.name || "",
                  deliverable: taskDeliverable?.name || "",
                  row: (
                    <>
                      <Cell full>
                        {/* {task.status || "N/A"} */}
                        🚨
                      </Cell>
                      <Cell>
                        <ProfileImage
                          handle={task.memberId?.handle || undefined}
                          name={task.memberId?.name || task.role?.role || ""}
                          xsmall
                        />
                      </Cell>
                      <Cell>{task.title || "N/A"}</Cell>
                      <Cell>
                        {taskDeliverable ? taskDeliverable.name : null}
                      </Cell>
                      <Cell>{taskPhase ? taskPhase.name : null}</Cell>
                      <Cell>
                        {task.date
                          ? format(getStandardizedDate(task.date), "MM/dd/yyyy")
                          : "N/A"}
                      </Cell>
                    </>
                  ),
                };
              })
            : []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  max-width: 700px;

  [data-table-inner] {
    height: 240px;
  }

  table {
    th,
    td {
      padding: 8.5px 7.5px;

      &:first-of-type {
        padding: 8.5px;
        padding-right: 0;
      }
    }

    thead {
      position: relative;
      z-index: 5;

      tr {
        &,
        th {
          position: sticky;
          top: 0;
        }
      }
    }

    tbody {
      tr {
        font-size: 14px;
      }
    }
  }
`;

const Cell = styled(TableCell)`
  padding: 10px 20px;
`;

export default OverdueTasks;
