import React from "react";
import styled, { css } from "styled-components";
import Cleave from "cleave.js/react";

export const Label = styled.label`
  display: block;
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #545d7a;
`;

export const Field = styled.div`
  margin-bottom: 30px;

  ${(props) =>
    props.row
      ? css`
          display: flex;
          justify-content: flex-end;
          align-items: center;

          ${Label} {
            margin-right: 30px;
            margin-bottom: 0;
            text-align: right;
          }
        `
      : ``}

  .choices {
    width: 300px;

    * {
      font-size: 14px;
    }
  }

  .choices__inner {
    border-radius: 15px;
    background: #e1e0e4;
    min-height: 50px;
    padding: 0px !important;
    border: 0px;
    display: flex;
    align-items: center;
  }

  .choices__list {
    padding: 0px;
  }

  .choices__item {
    padding-left: 15px;
  }
`;

export const Input = styled.input`
  width: 300px;
  height: 55px;
  max-width: 100%;

  font-size: 14px;
  border: 1px solid #dae1f5;
  border-radius: 3px;
  background: white;
  color: #10182f;
  padding: 0px 20px;

  outline: none;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;

  &:focus,
  &:active {
    border: 1px solid #0079ff;
  }

  &:disabled {
    background-color: #fafbf9;
    border: 1px solid #dae1f5;
    cursor: no-drop;
  }

  ${(props) =>
    props.isInvalid
      ? css`
          border-color: ${props.theme.colors.red};
        `
      : ``}
`;

export const FieldGroup = styled(Field)`
  display: flex;

  ${Field} {
    margin-right: 20px;
    margin-bottom: 0;
  }

  ${Input} {
    width: auto;
  }
`;

// range slider
export const Range = styled.input`
  width: 300px;
`;

const StyledTextarea = styled(Input)`
  resize: none;
  height: 100px;
  max-height: 75vh;
  padding: 10px;
`;

export const Textarea = ({ children, ...rest }) => (
  <StyledTextarea as="textarea" {...rest}>
    {children}
  </StyledTextarea>
);

export const MoneyInput = ({ ...props }) => {
  return (
    <Input
      as={Cleave}
      options={{
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        prefix: "$",
      }}
      {...props}
    />
  );
};
