import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { format, isPast, isToday } from "date-fns";

import { useAuth } from "../../context/authContext";
import { useNotifications } from "../../context/notificationsContext";

import { getStandardizedDate, ClientLink } from "../../utils/helpers";

import { getAllProjectsFromApi, getCompaniesFromApi } from "../../utils/api";
import { getPastEntriesFromApi } from "../dashboard/utils/api";

import { stages } from "../../utils/constants";

import { TableHeader, TableRow, TableCell } from "../../components/Table";
import { Field, Label } from "../../components/Form";
import CreateLink from "../../components/links/CreateLink";
import ProjectPopout from "./components/ProjectPopout";
import ProfileImage from "../../components/ProfileImage";
import SelectDropdown from "../../components/SelectDropdown";

import StageProgress from "./components/StageProgress";
import Budget from "./components/Budget";

const Projects = () => {
  const { setLoading } = useNotifications();
  const { isManagerOrAdmin } = useAuth();

  const [client, setClient] = useState(null);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [sortBy, setSortBy] = useState("newest");
  const [group, setGroup] = useState(null);

  const groups = [
    {
      label: "Overdue Projects",
      value: "overdue projects",
    },
    {
      label: "Projects with Overdue Tasks",
      value: "overdue tasks",
    },
    {
      label: "Projects on Track",
      value: "",
    },
  ];

  // on load
  useEffect(() => {
    getClients();
  }, []);

  // loading is done once we have the clients
  useEffect(() => {
    setLoading(clients.length ? false : true);
  }, [clients]);

  // Get all clients
  const getClients = async () => {
    try {
      let clientsFromApi = await getCompaniesFromApi();

      clientsFromApi = clientsFromApi.filter((client) => {
        return client.status === "Client";
      });

      // Add additional options at the end of the list of clients
      clientsFromApi.push({
        _id: 0,
        name: "View all projects",
      });
      clientsFromApi.push({
        _id: -1,
        name: "View all archived projects",
      });

      setClients(clientsFromApi);
    } catch (error) {
      console.error("Error getting clients", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Change state when a Client is selected from the dropdown
  |--------------------------------------------------------------------------
  */
  const changeClient = (e) => {
    if (e.value === 0) {
      // get all projects
      getProjects("default");
    } else if (e.value === -1) {
      // get archived projects
      getProjects("default", "archived");
    } else {
      // get projects for this client
      getProjects("default", e.value);
    }

    setClient(e.value);
    setLoading(true);
  };

  /*
  |--------------------------------------------------------------------------
  | Change state when a Group is selected from the dropdown
  |--------------------------------------------------------------------------
  */
  const changeGroup = (e) => {
    getProjects("group", e.value);

    setGroup(e.label);
    setLoading(true);
  };

  /*
  |--------------------------------------------------------------------------
  | Get the desired projects
  |--------------------------------------------------------------------------
  */
  const getProjects = async (type, selection) => {
    let results;

    // If a client is selected from the client dropdown
    if (type === "default") {
      const projectQuery = { bookingPlan: true, tasks: true };

      if (selection) {
        if (selection === "archived") {
          projectQuery.archived = true;
        } else {
          projectQuery.clientId = selection;
        }
      }

      results = await getAllProjectsFromApi(projectQuery);
    }
    // If a group is selected from the group dropdown
    else if (type === "group") {
      results = await getAllProjectsFromApi({
        clientId: client !== 0 && client !== -1 ? client : undefined,
        group: true,
        tasks: true,
      });

      // Return projects that have an end date that's in the past
      if (selection === "overdue projects") {
        results = results.filter(isProjectOverdue);
      }
      // Return projects that have at least 1 task with a due date that's in the past
      else if (selection === "overdue tasks") {
        results = results.filter(areProjectTasksOverdue);
      }
      // Return projects that are not overdue and don't have overdue tasks
      else {
        results = results.filter(
          (project) =>
            !isProjectOverdue(project) && !areProjectTasksOverdue(project),
        );
      }
    }

    if (results) {
      results = await Promise.all(
        results.map(async (project) => {
          let tracked = 0;
          // Get tracked hours for the project (if it has a harvestId)
          if (type === "default" && project.harvestId && project.stages) {
            // If there are tracked dollar amount saved, use that value. If not, get the time entries.
            if (project.finalTrackedDollars) {
              tracked = project.finalTrackedDollars;
            } else {
              tracked = await getProjectEntries(project.harvestId);
            }
          }

          // Getting rid of duplicate team members
          project.uniqueMembers = project.teamMembers
            ? project.teamMembers
                .filter(
                  (item, itemIndex, memberArray) =>
                    memberArray.findIndex(
                      (innerItem) =>
                        innerItem.teamMember._id === item.teamMember._id,
                    ) === itemIndex,
                )
                .map((item) => item.teamMember)
            : null;

          const stageInfo = getStageInfo(project);

          if (stageInfo) {
            project.stageNumber = stageInfo.stageNumber;
            project.stagesStatus = stageInfo.stagesStatus;
          }

          project.tracked = tracked;
          return project;
        }),
      );

      results = results.slice(0).reverse();
      setLoading(false);
      setProjects(results);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Set the stage number and stageStatus for a project
  |--------------------------------------------------------------------------
  */
  const getStageInfo = (project) => {
    let stageNumber = 0;
    const stagesStatus = {};

    for (const name of stages) {
      // each stage will be a property of the stagesStatus object, with their own properties of completed and dueDate
      stagesStatus[name] = {
        completed: false,
        dueDate: null,
        completedDate: null,
      };
    }

    // stage number that we should jump to
    if (project && project.stages) {
      // loop over all the stages
      stages.forEach((stageName, stageIndex) => {
        if (project.stages[stageName]) {
          // set the due date for this stage
          stagesStatus[stageName].dueDate = project.stages[stageName]?.dueDate;

          // set whether it's been completed
          stagesStatus[stageName].completed =
            project.stages[stageName]?.completed;

          stagesStatus[stageName].completedDate =
            project.stages[stageName]?.completedDate;
        }

        const stageCompleted = project?.stages?.[stageName]?.completed;

        // if this stage has been completed (and previous stages are complete)
        // ? maybe don't check the prev stages are complete, since some old projects don't have the same stages
        // if (stageCompleted && !prevIncomplete) {
        if (stageCompleted) {
          // jump to the stage after this one (plus 2 since the indexes start at 0 but our stage numbers start at 1)
          stageNumber = stageIndex + 2;
        }
      });
    }

    return {
      stageNumber,
      stagesStatus,
    };
  };

  /*
  |--------------------------------------------------------------------------
  | Get all harvest time entries for the project
  |--------------------------------------------------------------------------
  */
  const getProjectEntries = async (id) => {
    try {
      const result = await getPastEntriesFromApi({
        projectId: id,
        calculate: true,
      });

      return result?.data?.data?.result;
    } catch (error) {
      console.error("Get project entries error: " + error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the projects
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedProjects = [...projects];

    if (sortBy === "name-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "stage-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.stageNumber;
        var textB = b.stageNumber;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "stage-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.stageNumber;
        var textB = b.stageNumber;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "budget-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.tracked / a.budget || 0;
        if (!a.budget) {
          textA = -1;
        }
        var textB = b.tracked / b.budget || 0;
        if (!b.budget) {
          textB = -1;
        }
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "budget-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.tracked / a.budget || 0;
        if (!a.budget) {
          textA = -1;
        }
        var textB = b.tracked / b.budget || 0;
        if (!b.budget) {
          textB = -1;
        }
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "date-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.endDate;
        var textB = b.endDate;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "date-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = a.endDate;
        var textB = b.endDate;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "totalTasks-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = getTotalTasks(a);
        var textB = getTotalTasks(b);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "totalTasks-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = getTotalTasks(a);
        var textB = getTotalTasks(b);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "overdueTasks-asc") {
      sortedProjects.sort(function(a, b) {
        var textA = getOverdueTasks(a);
        var textB = getOverdueTasks(b);
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "overdueTasks-desc") {
      sortedProjects.sort(function(a, b) {
        var textA = getOverdueTasks(a);
        var textB = getOverdueTasks(b);
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setProjects(sortedProjects);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  const isProjectOverdue = (project) =>
    project &&
    isPast(getStandardizedDate(project.endDate)) &&
    !isToday(getStandardizedDate(project.endDate));

  const areProjectTasksOverdue = (project) => {
    let tasksOverdue = false;

    project?.projectTasks?.forEach((task) => {
      if (
        isPast(getStandardizedDate(task.date)) &&
        !isToday(getStandardizedDate(task.date)) &&
        task.status.toLowerCase() !== "completed"
      ) {
        tasksOverdue = true;
      }
    });

    return tasksOverdue;
  };

  /*
  |--------------------------------------------------------------------------
  | Get the total amount of tasks for a project
  |--------------------------------------------------------------------------
  */
  const getTotalTasks = (project) => project?.projectTasks?.length || 0;

  /*
  |--------------------------------------------------------------------------
  | Get the amount of overdue tasks for a project
  |--------------------------------------------------------------------------
  */
  const getOverdueTasks = (project) => {
    // task is overdue if its due in past, not due today, and not complete
    const overdueTasks = project?.projectTasks?.filter(
      (task) =>
        isPast(getStandardizedDate(task.date)) &&
        !isToday(getStandardizedDate(task.date)) &&
        task.status.toLowerCase() !== "completed",
    );

    return overdueTasks?.length || 0;
  };

  return (
    <ProjectsContainer>
      <ProjectsHeader>
        <h1>Projects</h1>
        {isManagerOrAdmin && (
          <CreateLink as={Link} to={`/projects/new`}>
            Create Project
          </CreateLink>
        )}
      </ProjectsHeader>

      <Dropdowns>
        <StyledField>
          <Label>Client</Label>
          <StyledSelectDropdown
            id="clients"
            onChange={(e) => changeClient(e)}
            placeholder="Select Client"
            required
            options={
              clients &&
              clients.map((client) => {
                return {
                  label: client.name,
                  value: client._id,
                };
              })
            }
          ></StyledSelectDropdown>
        </StyledField>

        <StyledField>
          <Label>Group</Label>
          <StyledSelectDropdown
            id="groups"
            onChange={(e) => changeGroup(e)}
            placeholder="Select Group"
            required
            options={
              groups &&
              groups.map((group) => {
                return {
                  label: group.label,
                  value: group.value,
                };
              })
            }
          ></StyledSelectDropdown>
        </StyledField>
      </Dropdowns>

      {projects && projects.length >= 1 && (
        <ProjectsList id="projectsTable">
          <thead>
            <tr>
              <StyledTableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                isActive={sortBy.includes("name")}
                align="left"
              >
                Project Name
              </StyledTableHeader>
              <StyledTableHeader
                onClick={() => changeSort("stage")}
                isArrowUp={sortBy === "stage-desc"}
                isActive={sortBy.includes("stage")}
                // stages
              >
                {/* <HeaderStages>
                  {stages.map((stageName, index) => (
                    <div key={index}>{startCase(stageName)}</div>
                  ))}
                </HeaderStages> */}
                Stages
              </StyledTableHeader>
              {group ? (
                <StyledTableHeader
                  onClick={() => changeSort("date")}
                  isArrowUp={sortBy === "date-desc"}
                  isActive={sortBy.includes("date")}
                >
                  Due Date
                </StyledTableHeader>
              ) : (
                <StyledTableHeader
                  onClick={() => changeSort("budget")}
                  isArrowUp={sortBy === "budget-desc"}
                  isActive={sortBy.includes("budget")}
                >
                  Budget
                </StyledTableHeader>
              )}

              {group && (
                <StyledTableHeader
                  onClick={() => changeSort("totalTasks")}
                  isArrowUp={sortBy === "totalTasks-desc"}
                  isActive={sortBy.includes("totalTasks")}
                >
                  Total Tasks
                </StyledTableHeader>
              )}

              {group && (
                <StyledTableHeader
                  onClick={() => changeSort("overdueTasks")}
                  isArrowUp={sortBy === "overdueTasks-desc"}
                  isActive={sortBy.includes("overdueTasks")}
                >
                  Overdue Tasks
                </StyledTableHeader>
              )}
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {
              return (
                <TableRow key={index} to={`/projects/${project._id}`}>
                  <TableCell align="left" style={{ paddingRight: "60px" }}>
                    <ProjectPopout
                      linkUrl={`/projects/${project._id}`}
                      linkText={
                        project.code && project.name
                          ? `${project.code.toUpperCase()} - ${project.name}`
                          : project.name
                          ? project.name
                          : ""
                      }
                    >
                      <PopoutTable>
                        <tbody>
                          <tr>
                            <TableLabel>Client</TableLabel>
                            <TableValue>
                              <ClientLink
                                name={project.client.name}
                                id={project.client._id}
                              />
                            </TableValue>
                          </tr>
                          <tr>
                            <TableLabel>Lead Dir</TableLabel>
                            <TableValue>{project.leadDirector.name}</TableValue>
                          </tr>
                          {project.client.partner && (
                            <tr>
                              <TableLabel>Part Mgr</TableLabel>
                              <TableValue>
                                {project.client.partner.name}
                              </TableValue>
                            </tr>
                          )}
                          {project.uniqueMembers && (
                            <tr>
                              <TableLabel>Team</TableLabel>
                              <TableValue>
                                <TeamMembers>
                                  {project.uniqueMembers.map(
                                    (member, memberIndex) => (
                                      <ProfileImage
                                        small
                                        name={member.name}
                                        handle={member.handle}
                                        style={{ marginBottom: "10px" }}
                                        key={memberIndex}
                                        showStatus
                                      />
                                    ),
                                  )}
                                </TeamMembers>
                              </TableValue>
                            </tr>
                          )}
                        </tbody>
                      </PopoutTable>
                    </ProjectPopout>
                  </TableCell>

                  <StyledTableCell>
                    {project.stages && (
                      <StageProgress
                        minimal
                        stageNumber={project.stageNumber}
                        stages={stages}
                        stagesStatus={project.stagesStatus}
                        id={project._id}
                      />
                    )}
                  </StyledTableCell>

                  {!group && project.budget ? (
                    <StyledTableCell style={{ paddingRight: "0px" }}>
                      <StyledBudget
                        harvestId={project.harvestId}
                        budget={project.budget}
                        low={project.lowEstimate}
                        high={project.highEstimate}
                        tracked={project.tracked}
                      />
                      <BudgetInfo>
                        <p>
                          Tracked: $
                          {Math.round(project.tracked).toLocaleString()}
                        </p>
                        <p>
                          Budget: ${Math.round(project.budget).toLocaleString()}
                        </p>
                        <p>
                          Low estimate:{" "}
                          {project.lowEstimate
                            ? `$${project.lowEstimate.toLocaleString()}`
                            : "Not set"}
                        </p>
                        <p>
                          High estimate:{" "}
                          {project.highEstimate
                            ? `$${project.highEstimate.toLocaleString()}`
                            : "Not set"}
                        </p>
                      </BudgetInfo>
                    </StyledTableCell>
                  ) : group ? (
                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                      {format(
                        getStandardizedDate(project.endDate),
                        "MM-dd-yyyy",
                      )}
                    </StyledTableCell>
                  ) : null}

                  {group ? (
                    <StyledTableCell>{getTotalTasks(project)}</StyledTableCell>
                  ) : null}

                  {group ? (
                    <StyledTableCell>
                      {getOverdueTasks(project)}
                    </StyledTableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </tbody>
        </ProjectsList>
      )}
    </ProjectsContainer>
  );
};

const ProjectsContainer = styled.div`
  h2 {
    font-size: 28px;
    color: ${(props) => props.theme.colors.oldBlack};
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }
`;

const ProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 30px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.oldBlack};
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const ProjectsList = styled.table`
  padding: 50px;
`;

const PopoutTable = styled.table`
  margin: 0px;
  margin-bottom: -10px;

  td,
  tr {
    border: 0px !important;
    vertical-align: top;
  }

  td {
    padding-bottom: 15px;
  }
`;

const TableLabel = styled.td`
  font-weight: 700;
  padding-right: 20px;
`;

const TableValue = styled.td``;

const TeamMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 150px;
  justify-content: space-between;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 40px;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  position: sticky;
  top: 0;

  padding-top: 10px;
  padding-left: 0px;
  background-color: #fff;
  text-align: left;

  z-index: 10;

  &::after {
    ${(props) =>
      props.stages
        ? css`
            position: absolute;
            top: 10px;
          `
        : ``}
  }
`;

const StyledTableCell = styled(TableCell)`
  padding-left: 0px;
  position: relative;
`;

const BudgetInfo = styled.div`
  position: absolute;
  left: 90px;
  bottom: 0;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(90%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const StyledBudget = styled(Budget)`
  cursor: help;

  &:hover ~ ${BudgetInfo} {
    opacity: 1;
    transform: translateY(80%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

const StyledField = styled(Field)`
  padding: 0px 50px;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 300px;
  z-index: 100;
`;

const Dropdowns = styled.div`
  display: flex;
`;

export default Projects;
