import React from "react";
import styled from "styled-components";

const ZoomOutIcon = ({ ...props }) => {
  return (
    <Svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6484 12.408H9.64844M21.6484 12.408C21.6484 13.5899 21.4156 14.7602 20.9634 15.8521C20.5111 16.944 19.8481 17.9362 19.0124 18.7719C18.1767 19.6076 17.1845 20.2706 16.0926 20.7229C15.0007 21.1752 13.8303 21.408 12.6484 21.408C11.4665 21.408 10.2962 21.1752 9.20429 20.7229C8.11236 20.2706 7.1202 19.6076 6.28448 18.7719C5.44875 17.9362 4.78581 16.944 4.33352 15.8521C3.88123 14.7602 3.64844 13.5899 3.64844 12.408C3.64844 10.021 4.59665 7.73183 6.28448 6.044C7.9723 4.35617 10.2615 3.40796 12.6484 3.40796C15.0354 3.40796 17.3246 4.35617 19.0124 6.044C20.7002 7.73183 21.6484 10.021 21.6484 12.408Z"
        stroke="#F3F4F6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default ZoomOutIcon;
