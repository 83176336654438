import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { rgba } from "polished";
import { motion, AnimatePresence } from "framer-motion";
import { respondTo } from "../../styles/styleHelpers";

import Button from "../buttons/Button";
import DefaultLink from "../links/DefaultLink";
import SimpleLink from "../links/SimpleLink";
import { Textarea } from "../Form";

const StopTimerView = ({
  isOpen,
  canOverride,
  overrideClose,
  closeAndRedirect,
  headerText,
  submit,
  startTime,
  endTime,
  duration,
  timeLeft,
  over,
}) => {
  const [notes, setNotes] = useState("");
  return (
    <AnimatePresence>
      {isOpen ? (
        <ScreenBlocker
          initial={{ opacity: 0.0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0.0 }}
          transition={{ duration: 0.1 }}
        >
          <Modal
            initial={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            animate={{ opacity: 1, x: "-50%", y: "-50%" }}
            exit={{ opacity: 0.0, x: "-50%", y: "-30%" }}
            transition={{ type: "spring", duration: 0.6 }}
          >
            {canOverride && (
              <OverrideButton danger small onClick={overrideClose}>
                Override
              </OverrideButton>
            )}
            <HeaderRow>
              <Header>{headerText}</Header>
            </HeaderRow>
            <Body>
              <InfoCol>
                <InfoItem>
                  <InfoLabel>Start time</InfoLabel>
                  <InfoStat>{startTime}</InfoStat>
                </InfoItem>
                <InfoItem>
                  <InfoLabel>End time</InfoLabel>
                  <InfoStat>{endTime}</InfoStat>
                </InfoItem>
                <InfoItem>
                  <InfoLabel>Total spent</InfoLabel>
                  <InfoStat>{duration}</InfoStat>
                </InfoItem>
                <InfoItem>
                  <InfoLabel>Hours left</InfoLabel>
                  <InfoStat over={over}>{timeLeft}</InfoStat>
                </InfoItem>
              </InfoCol>

              <NotesCol>
                <ColHeader>Additional Notes</ColHeader>
                <NotesText
                  placeholder="Notes go here"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <NotesButtonRow>
                  <DefaultLink onClick={closeAndRedirect}>
                    Cancel and continue working
                  </DefaultLink>
                  <NotesButton
                    small
                    onClick={() => {
                      submit(notes);
                      setNotes("");
                    }}
                  >
                    Submit
                  </NotesButton>
                </NotesButtonRow>
              </NotesCol>
            </Body>
          </Modal>
        </ScreenBlocker>
      ) : null}
    </AnimatePresence>
  );
};

const ScreenBlocker = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: ${(props) => rgba(props.theme.colors.oldBlack2, 0.15)};
  transition: opacity 0.2s;
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60vw;
  min-height: 556px;

  display: flex;
  flex-direction: column;

  padding: 50px;

  background-color: ${(props) => props.theme.colors.pureWhite};
  box-shadow: 50px 50px 100px #bbbbbb, -50px -50px 100px #e9e9e9;

  ${respondTo("xlarge")} {
    min-width: 50vw;
  }
`;

const OverrideButton = styled(SimpleLink)`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const HeaderRow = styled.div`
  margin-bottom: 70px;
`;

const Header = styled.h2`
  max-width: 400px;
  font-weight: 600;
  margin-bottom: 0;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 60px;
  }
`;

const ColHeader = styled.h2`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
`;

const InfoCol = styled(Col)``;

const InfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: 35px;
  }
`;

const InfoLabel = styled.p`
  flex: 0 1 84px;
  text-align: right;
  line-height: 1;
  margin-bottom: 1px;
  margin-right: 20px;

  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
`;

const InfoStat = styled.p`
  flex: 0 1 84px;
  line-height: 1;
  margin-bottom: 0;

  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize_xs};
  color: ${(props) =>
    props.over ? props.theme.colors.red : props.theme.colors.oldBlack};
`;

const NotesCol = styled(Col)``;

const NotesText = styled(Textarea)`
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
`;

const NotesButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotesButton = styled(Button)`
  min-width: 130px;
`;

StopTimerView.propTypes = {
  canOverride: PropTypes.bool,
  overrideClose: PropTypes.func,
  closeAndRedirect: PropTypes.func,
  submit: PropTypes.func,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  duration: PropTypes.string,
};

StopTimerView.defaultProps = {
  canOverride: false,
  overrideClose: null,
  closeAndRedirect: null,
  submit: null,
  startTime: "",
  endTime: "",
  duration: "",
};

export default StopTimerView;
