import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { lighten, darken } from "polished";

import { ClientLink } from "../../../utils/helpers";
import { respondTo } from "../../../styles/styleHelpers";
import { format } from "date-fns";

import StartIcon from "../../../components/icons/StartIcon";
import StopIcon from "../../../components/icons/StopIcon";
import NoteIcon from "../../../components/icons/NoteIcon";
import CloseButton from "../../../components/buttons/CloseButton";
import ProgressBar from "./ProgressBar";

const TimeCard = ({
  disabled,
  isActiveTimer,
  isInProduction,
  handleProjectRoute,
  budgetHours,
  trackedHours,
  pace,
  paceLabelTop,
  paceLabelBottom,
  editing,
  setEditing,
  notes,
  setNotes,
  startTimer,
  stopTimer,
  deleteTimer,
  menuOpen,
  setMenuOpen,
  entry,
  updateEntry,
  deleteEntry,
  entryEditing,
  setEntryEditing,
  entryStart,
  setEntryStart,
  entryDate,
  setEntryDate,
  entryTitle,
  setEntryTitle,
  entryEnd,
  setEntryEnd,
  entryNotes,
  setEntryNotes,
  pastEntries,
  setEntryId,
  isPersonal,
  openStopModal,
}) => {
  return (
    <CardContainer>
      {(() => {
        if (editing && isActiveTimer) {
          return (
            <SubmitView>
              <NotesContainer>
                <NotesDate>{format(new Date(), "E, LLL d")}</NotesDate>
                <NotesTitle>What did you do?</NotesTitle>
                <NotesCancel color="white" onClick={() => setEditing(false)}>
                  &larr;
                </NotesCancel>

                <Notes
                  placeholder="Enter what you did with this time"
                  onChange={(e) => setNotes(e.target.value)}
                />
              </NotesContainer>
              <EditButtons>
                <NotesDelete
                  onClick={() =>
                    deleteTimer(
                      entry.id,
                      entry.project.id,
                      entry.client.id,
                      entry.task.id,
                      entry.project.startDate,
                      entry.project.endDate,
                    )
                  }
                >
                  Delete
                </NotesDelete>
                <NotesSave
                  onClick={() =>
                    stopTimer(
                      entry.id,
                      entry.project.id,
                      entry.client.id,
                      entry.task.id,
                      entry.project.startDate,
                      entry.project.endDate,
                      notes,
                    )
                  }
                >
                  Save
                </NotesSave>
              </EditButtons>
            </SubmitView>
          );
        } else if (menuOpen) {
          return (
            <MenuView>
              {entryEditing ? (
                <EditEntry>
                  <EditHeader>
                    <EditDate>{entryDate}</EditDate>
                    <EntryDelete onClick={() => deleteEntry()}>
                      Delete
                    </EntryDelete>
                  </EditHeader>

                  <EditTitle>Project</EditTitle>
                  <EditProject>{entryTitle}</EditProject>

                  <EditTitle>Time</EditTitle>
                  <EntryTime>
                    <input
                      type="text"
                      value={entryStart}
                      onChange={(e) => setEntryStart(e.target.value)}
                    />
                    <p>to</p>
                    <input
                      type="text"
                      value={entryEnd}
                      onChange={(e) => setEntryEnd(e.target.value)}
                    />
                  </EntryTime>

                  <EditTitle>Notes</EditTitle>
                  <EditNotes
                    value={entryNotes}
                    onChange={(e) => setEntryNotes(e.target.value)}
                  />

                  <EntryFooter>
                    <EntryCancel onClick={() => setEntryEditing(false)}>
                      Cancel
                    </EntryCancel>
                    <EntrySave onClick={() => updateEntry()}>Update</EntrySave>
                  </EntryFooter>
                </EditEntry>
              ) : pastEntries ? (
                <>
                  <PastCancel
                    color="gray"
                    onClick={() => setMenuOpen(!menuOpen)}
                  />

                  <PastEntries>
                    {pastEntries.length ? (
                      pastEntries.map((item, index) => {
                        const spentDateArray = item.spent_date.split("-");
                        // year - month - day
                        const spentDate = format(
                          new Date(
                            spentDateArray[0],
                            spentDateArray[1] - 1,
                            spentDateArray[2],
                          ),
                          "iii, MMM d",
                        );

                        return (
                          <Entry
                            key={index}
                            onClick={() => {
                              if (!item.is_locked) {
                                setEntryEditing(true);
                                setEntryId(item.id);
                                setEntryNotes(item.notes);
                                setEntryDate(spentDate);
                                setEntryTitle(
                                  `${entry.project.client.name}/${entry.project.name}`,
                                );
                                setEntryStart(
                                  item.started_time
                                    ? item.started_time
                                    : format(
                                        new Date(item.created_at),
                                        "h:mma",
                                      ).toLowerCase(),
                                );
                                setEntryEnd(
                                  item.ended_time
                                    ? item.ended_time
                                    : format(
                                        new Date(item.updated_at),
                                        "h:mma",
                                      ).toLowerCase(),
                                );
                              }
                            }}
                          >
                            <MenuIconContainer pastEntry={true}>
                              <NoteIcon pastEntry={true} />
                            </MenuIconContainer>
                            <EntryDate>{spentDate}</EntryDate>
                            <EntryRow>
                              <EntryColumn>
                                <EntryHeading>Time</EntryHeading>
                                <EntryDuration>
                                  {item.started_time
                                    ? item.started_time
                                    : format(
                                        new Date(item.created_at),
                                        "h:mma",
                                      )}{" "}
                                  <span>to</span>{" "}
                                  {item.ended_time
                                    ? item.ended_time
                                    : format(
                                        new Date(item.updated_at),
                                        "h:mma",
                                      )}
                                </EntryDuration>
                              </EntryColumn>
                              <EntryColumn>
                                <EntryHeading>Hours</EntryHeading>
                                <EntryHours>{item.rounded_hours}</EntryHours>
                              </EntryColumn>
                            </EntryRow>
                            {item.notes ? (
                              <>
                                <EntryHeading>Notes</EntryHeading>
                                <EntryNotes>
                                  {item.notes.substring(0, 40)}
                                </EntryNotes>
                              </>
                            ) : null}
                          </Entry>
                        );
                      })
                    ) : (
                      <NoEntries>
                        Your past entries would appear here. Currently, there
                        are none.
                      </NoEntries>
                    )}
                  </PastEntries>
                </>
              ) : null}
            </MenuView>
          );
        } else {
          return (
            <DefaultView>
              {!disabled && isInProduction && (
                <MenuIconContainer onClick={() => setMenuOpen(!menuOpen)}>
                  <NoteIcon />
                </MenuIconContainer>
              )}

              <ClientHeader>
                <ClientLink
                  name={entry.project.client.name}
                  id={entry.project.client._id}
                />
              </ClientHeader>
              <ProjectHeader
                onClick={!isPersonal ? handleProjectRoute : null}
                isLink={!isPersonal && handleProjectRoute ? true : false}
              >
                {entry.project.code
                  ? `${entry.project.code.toUpperCase()} - `
                  : ""}
                {entry.project.name}
              </ProjectHeader>

              {isInProduction && !disabled ? (
                <TimerIcon
                  isStop={false}
                  onClick={() => {
                    if (isActiveTimer) {
                      // stopTimer(
                      //   entry.id,
                      //   entry.project.id,
                      //   entry.client.id,
                      //   entry.task.id,
                      //   entry.project.startDate,
                      //   entry.project.endDate,
                      //   notes,
                      // )
                      openStopModal();
                    } else {
                      startTimer(
                        !isPersonal
                          ? entry.project._id
                          : entry.project.harvestId,
                        isPersonal ? "personal" : null,
                      );
                    }
                  }}
                >
                  {isActiveTimer ? <StopIcon /> : <StartIcon />}
                </TimerIcon>
              ) : disabled ? null : (
                <NotProductionMsg>
                  This project is not yet in production.
                </NotProductionMsg>
              )}

              <TimeBar
                pace={pace}
                paceLabelTop={paceLabelTop}
                paceLabelBottom={paceLabelBottom}
                actual={trackedHours}
                budgeted={budgetHours}
              />

              <HoursSection>
                <Hours>
                  <HoursLabel>Tracked</HoursLabel>
                  <HoursQty>{trackedHours}</HoursQty>
                </Hours>
                <Hours>
                  <HoursLabel>Minimum</HoursLabel>
                  <HoursQty>
                    {budgetHours === Infinity ? <>&infin;</> : budgetHours}
                  </HoursQty>
                </Hours>
              </HoursSection>
            </DefaultView>
          );
        }
      })()}
    </CardContainer>
  );
};

const CardContainer = styled.div`
  flex-basis: 300px;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 15px;
  margin-bottom: 23px;
  margin-right: 23px;
  background-color: ${(props) => props.theme.colors.pureWhite};
  filter: drop-shadow(4px 8px 14px rgba(185, 188, 192, 0.3));
  transition: 0.3s ease-in-out;

  .task-name {
    font-size: 16px;
  }

  .edit-timer {
    margin-right: 20px;
    margin-bottom: 0px;
  }
`;

const ClientHeader = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  margin-bottom: 12px;
`;

const ProjectHeader = styled.p`
  font-weight: 300;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  margin-bottom: 0px;
  color: ${(props) => props.theme.colors.oldBlack2};

  ${(props) =>
    props.isLink
      ? css`
          cursor: pointer;
          transition: color 0.2s;

          &:hover {
            //text-decoration: underline;
            color: ${lighten(0.3, props.theme.colors.oldBlack)};
          }
        `
      : css`
          cursor: default;

          &:hover {
            color: ${props.theme.colors.oldBlack};
          }
        `}
`;

const TimeBar = styled(ProgressBar)`
  margin-top: auto;
`;

const HoursSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Hours = styled.div`
  margin-bottom: 0;

  &:last-child {
    p {
      text-align: right;
    }
  }
`;

const HoursLabel = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs} !important;
  font-weight: 300;
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 0px;
`;

const HoursQty = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_s} !important;
  margin-bottom: 0;
`;

const TimerIcon = styled.div`
  padding-top: 34px;
  padding-bottom: 34px;
  align-self: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &:hover,
  &:focus {
    cursor: pointer;

    svg {
      transform: scale(1.15);
    }

    path {
      ${(props) =>
        props.isStop
          ? css`
              fill: ${darken(0.1, props.theme.colors.red)};
            `
          : css`
              animation: startColor 10s infinite linear;
            `}
    }
  }
`;

const NotProductionMsg = styled.p`
  font-weight: 300;
  color: ${(props) => props.theme.colors.slate};
  font-size: ${(props) => props.theme.fontSize_xxxs};
  font-style: italic;
  margin: auto;
  max-width: 150px;
`;

const NotesDate = styled.h4`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  font-weight: 300;
  color: ${(props) => props.theme.colors.pureWhite};

  margin-bottom: 3px;
`;

const NotesTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize_xxs};
  font-weight: 600;
  color: ${(props) => props.theme.colors.pureWhite};
  margin-top: 0;
  margin-bottom: 15px;
`;

const SubmitView = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.darkSlate};
`;

const NotesContainer = styled.div`
  padding: 30px;
`;

const Notes = styled.textarea`
  -webkit-appearance: none;
  resize: none;
  border: none;

  width: 100%;
  height: 126px;
  margin-bottom: 0;

  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.pureWhite};
  background-color: transparent;
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.colors.lightSlate};
  }

  &:focus {
    border: none;
    outline: none;
  }
`;

const EditButtons = styled.div`
  display: flex;
  margin-top: auto;
`;

const NotesButton = styled.button`
  flex-basis: 50%;
  height: 45px;
  transition: 0.3s ease-in-out;

  border: 0px;
  outline: none;
  cursor: pointer;
  display: block;
  color: ${(props) => props.theme.colors.pureWhite};
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  font-weight: 600;
  line-height: 1;
`;

const NotesSave = styled(NotesButton)`
  background-color: ${(props) => props.theme.colors.green};

  color: ${(props) => props.theme.colors.pureWhite};
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  font-weight: 600;
  line-height: 1;

  &:hover,
  &:focus {
    color: ${(props) => darken(0.1, props.theme.colors.pureWhite)};
    background-color: ${(props) => darken(0.1, props.theme.colors.green)};
  }
`;

const NotesDelete = styled(NotesButton)`
  background-color: ${(props) => props.theme.colors.red};

  &:hover,
  &:focus {
    color: ${(props) => darken(0.1, props.theme.colors.pureWhite)};
    background-color: ${(props) => darken(0.1, props.theme.colors.red)};
  }
`;

const DefaultView = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 25px;
`;

const MenuView = styled.div``;

const MenuIconContainer = styled.div`
  position: absolute;
  right: ${(props) => (props.pastEntry ? "7px" : "25px")};
  top: ${(props) => (props.pastEntry ? "7px" : "25px")};
  z-index: 3;
  cursor: pointer;

  &:hover {
    svg {
      transform: ${(props) => (props.pastEntry ? "scale(.5)" : "scale(.7)")};
    }
  }
`;

const NotesCancel = styled(CloseButton)``;

const PastCancel = styled(CloseButton)`
  transform: scale(1.2);
`;

const PastEntries = styled.div`
  padding: 0px 25px 0px 25px;
  margin-top: 60px;
  overflow-y: auto;
  height: 206px;
  font-size: ${(props) => props.theme.fontSize_xxxs};

  ${respondTo("xlarge")} {
    height: 230px;
  }
`;

const NoEntries = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
`;

const Entry = styled.div`
  padding: 15px;
  filter: drop-shadow(4px 8px 14px rgba(185, 188, 192, 0.3));
  border: 1px solid #e1e0e4;
  border-radius: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0.5;

  transition: opacity 0.2s, background-color 0.2s;

  & > * {
    transition: color 0.2s;
  }

  &:hover,
  &:focus {
    opacity: 1;
    background-color: #554668;

    & > * {
      color: white;
    }

    path {
      fill: white;
    }
  }
`;

const EntryDate = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxs};
  margin-bottom: 10px;
`;

const EntryRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const EntryColumn = styled.div`
  &:first-of-type {
    flex-grow: 1;
  }

  &:last-of-type {
    flex-shrink: 0;
  }
`;

const EntryHeading = styled.h4`
  font-weight: 600;
  margin-bottom: 0px;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  line-height: 1;
  opacity: 0.7;
`;

const EntryDuration = styled.p`
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  text-transform: uppercase;
  margin-bottom: 5px;

  span {
    font-weight: normal;
    text-transform: lowercase;
    margin: 0px 2px;
  }
`;

const EntryNotes = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  margin-bottom: 0px;
`;

const EntryHours = styled.p`
  margin-bottom: 0px;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  text-align: center;
`;

const EditEntry = styled.div`
  padding: 20px 25px 0px 25px;
  height: 202px;

  ${respondTo("xlarge")} {
    height: auto;
  }
`;

const EditHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const EditDate = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxs};
  margin-bottom: 0px;
`;

const EntryDelete = styled.a`
  color: ${(props) => props.theme.colors.negative};
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  transition: 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const EditProject = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxxxs};
  color: ${(props) => props.theme.colors.slate};
  line-height: 1;
  margin-bottom: 12px;
  font-weight: 600;
`;

const EditTitle = styled.h4`
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 4px;
  font-weight: 500;
`;

const EntryTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  width: 80%;

  input {
    background-color: white;
    border-radius: 3px;
    text-align: center;
    overflow: hidden;
    border: 1px solid ${(props) => props.theme.colors.lightSlate};
    color: ${(props) => props.theme.colors.slate};
    font-size: ${(props) => props.theme.fontSize_xxxxs};
    font-weight: 600;
    padding: 5px;
    text-transform: uppercase;

    ${respondTo("xlarge")} {
      width: 80px;
    }
  }

  p {
    margin: 0px 10px;
    text-transform: lowercase;
    font-size: ${(props) => props.theme.fontSize_xxxs};
    color: ${(props) => props.theme.colors.lightSlate};
  }
`;

const EditNotes = styled.textarea`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lightSlate};
  color: ${(props) => props.theme.colors.darkSlate};
  padding: 5px;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  width: 100%;
  height: 55px;
  resize: none;
`;

const EntryFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const EntryCancel = styled.div`
  background-color: ${(props) => props.theme.colors.red};
  padding: 12px 0px;
  font-weight: 600;
  color: white;
  transition: 0.3s ease-in-out;
  width: 50%;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(props) => darken(0.1, props.theme.colors.red)};
  }
`;

const EntrySave = styled.div`
  background-color: ${(props) => props.theme.colors.green};
  padding: 12px 0px;
  font-weight: 600;
  color: white;
  transition: 0.3s ease-in-out;
  width: 50%;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize_xxxxs};
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${(props) => darken(0.1, props.theme.colors.green)};
  }
`;

TimeCard.propTypes = {
  isActiveTimer: PropTypes.bool,
  budgetHours: PropTypes.number,
  trackedHours: PropTypes.number,
  pace: PropTypes.number,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  startTimer: PropTypes.func,
  stopTimer: PropTypes.func,
  deleteTimer: PropTypes.func,
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  entry: PropTypes.object,
  updateEntry: PropTypes.func,
  deleteEntry: PropTypes.func,
  entryEditing: PropTypes.bool,
  setEntryEditing: PropTypes.func,
  entryStart: PropTypes.string,
  setEntryStart: PropTypes.func,
  entryEnd: PropTypes.string,
  setEntryEnd: PropTypes.func,
  entryNotes: PropTypes.string,
  setEntryNotes: PropTypes.func,
  pastEntries: PropTypes.array,
  setEntryId: PropTypes.func,
};

TimeCard.defaultProps = {
  isActiveTimer: false,
  budgetHours: 0,
  trackedHours: 0,
  pace: 0,
  editing: false,
  setEditing: null,
  notes: "",
  setNotes: null,
  startTimer: null,
  stopTimer: null,
  deleteTimer: null,
  menuOpen: false,
  setMenuOpen: false,
  entry: null,
  updateEntry: null,
  deleteEntry: null,
  entryEditing: false,
  setEntryEditing: null,
  entryStart: "",
  setEntryStart: null,
  entryEnd: "",
  setEntryEnd: null,
  entryNotes: "",
  setEntryNotes: null,
  pastEntries: [],
  setEntryId: null,
};

export default TimeCard;
