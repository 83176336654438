import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  postCommentToProject,
  editCommentOnProject,
  deleteCommentOnProject,
} from "../../../utils/api";
import { useAuth } from "../../../context/authContext";
import { useNotifications } from "../../../context/notificationsContext";

import { respondTo } from "../../../styles/styleHelpers";
import {
  removeArrayObjectDuplicates,
  replaceNewLineWithBr,
} from "../../../utils/helpers";

import ChatWrapper, { ChatModal } from "../../../components/Chat/ChatWrapper";
import ChatCompose from "../../../components/Chat/ChatCompose";
import ChatMessageList from "../../../components/Chat/ChatMessageList";
import ChatButton from "../../../components/Chat/ChatButton";

const ProjectChat = ({
  isOpen,
  projectId,
  messages,
  activeMembers,
  chatMembers,
  onClose,
  onToggle,
  onSend,
}) => {
  const { user, isAdmin } = useAuth();
  const { openAlertPopup } = useNotifications();

  const [message, setMessage] = useState("");

  const handleSend = async () => {
    try {
      const trimmedMessage = message.trim();

      if (trimmedMessage) {
        const taggedMessage = replaceNewLineWithBr(message).trim();

        await postCommentToProject(projectId, {
          userId: user._id,
          message: taggedMessage,
        });

        // reset the input text
        setMessage("");

        if (onSend) {
          onSend();
        }
      }
    } catch (error) {
      console.error("project chat send error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't send that message. ${errMessage}`,
      );
    }
  };

  const handleEdit = async (newValue, originalMessage) => {
    try {
      await editCommentOnProject(projectId, {
        originalMessage,
        newMessageValue: newValue,
      });
    } catch (error) {
      console.error("project chat edit error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't edit that message. ${errMessage}`,
      );
    }
  };

  const handleDelete = async (message) => {
    try {
      await deleteCommentOnProject(projectId, {
        message,
      });
    } catch (error) {
      console.error("project chat delete error", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Something went wrong and we couldn't delete that message. ${errMessage}`,
      );
    }
  };

  // find this user in the list of chat members
  const thisChatMember = chatMembers
    ? chatMembers.find((chatMember) => chatMember.member === user._id)
    : null;

  // the last time this user viewed the chat
  // - if chat members doesn't exist yet, use current date
  // - if user hasn't viewed the chat before, it will be falsy
  const lastViewed = chatMembers ? thisChatMember?.lastViewed : new Date();

  // get the unread messages
  // - if user hasn't viewed chat before, then all are unread
  // - if they've viewed the chat, find the messages whose postedDate is sooner than lastViewed
  const unreadMessages = lastViewed
    ? messages.filter(
        (message) =>
          new Date(message.postedDate).getTime() >
          new Date(lastViewed).getTime(),
      )
    : messages;

  // the members who made the unread comments (remove duplicates) (remove the current user)
  const unreadMembers = removeArrayObjectDuplicates(
    unreadMessages
      .map((message) => message.author)
      .filter((author) => author._id !== user._id),
    "_id",
  );

  // only show the active members on the project aside from the current user
  const otherActiveMembers = activeMembers.filter(
    (member) => member._id !== user._id,
  );

  return (
    <StickyArea>
      <ChatButton onClick={onToggle} members={unreadMembers} />

      <ProjectChatModal isOpen={isOpen}>
        <ChatWrapper
          title="Chat"
          members={otherActiveMembers}
          onClose={onClose}
          footer={
            <ChatCompose
              message={message}
              onMessageChange={(value) => setMessage(value)}
              onSend={handleSend}
            />
          }
        >
          <ChatMessageList
            items={messages}
            userId={user._id}
            // if user hasn't viewed chat before, make it a very old date
            lastViewed={lastViewed ? lastViewed : new Date(0)}
            isVisible={isOpen}
            onEdit={handleEdit}
            onDelete={handleDelete}
            canModifyOthers={isAdmin}
          />
        </ChatWrapper>
      </ProjectChatModal>
    </StickyArea>
  );
};

const StickyArea = styled.div`
  z-index: 8;
  margin-left: 30px;
`;

const ProjectChatModal = styled(ChatModal)`
  position: absolute;
  right: 0;
  top: 100%;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  /* pointer-events: "all" to counteract the pointer-events: none of the parent */
  pointer-events: ${(props) => (props.isOpen ? "all" : "none")};

  transform: ${(props) =>
    props.isOpen ? "translateY(0px)" : "translateY(40px)"};

  transition: opacity 200ms, visibility 200ms, transform 200ms;
`;

ProjectChat.propTypes = {
  messages: PropTypes.array,
};
ProjectChat.defaultProps = {
  messages: [],
};

export default ProjectChat;
