import React from "react";

const Checkmark = ({ ...rest }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {/* <circle cx="9" cy="9" r="9" fill="#7BC1A8" /> */}
      <path
        d="M4.79999 9.6L7.19999 12.6L13.8 6"
        stroke="#FEFFFF"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Checkmark;
