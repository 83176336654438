import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { respondTo } from "../styles/styleHelpers";

const Avatar = ({
  src,
  alt,
  tooltip,
  initials,
  small,
  xsmall,
  hasBorder,
  borderColor,
  bgColor,
  statusColor,
  statusProject,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const generateColor = () => {
    const colors = ["green", "red", "blue", "dark2", "teal"];

    const lengthOfName = alt.length;

    let pickedColor = colors[0];

    // Sees what the length of their full name is divisible by to decide what color they get
    colors.forEach((color, index) => {
      if (lengthOfName % (index + 1) === 0) {
        pickedColor = colors[index];
      }
    });

    return pickedColor;
  };

  return (
    <Container
      small={small}
      xsmall={xsmall}
      color={isValid ? "transparent" : bgColor || generateColor()}
      hasLoaded={hasLoaded}
      hasBorder={hasBorder}
      borderColor={borderColor}
      {...rest}
    >
      {src ? (
        <Image
          src={src}
          alt={alt}
          onLoad={() => {
            setIsValid(true);
            setHasLoaded(true);
          }}
          onError={() => {
            // need to be able to set it back to false in case the component re-renders, like as a result of list items being re-sorted
            setIsValid(false);
            setHasLoaded(true);
          }}
          isValid={isValid}
          title={tooltip}
        />
      ) : (
        !hasLoaded && setHasLoaded(true) // If there's no src, we load the empty circle (used for placeholder profile images, like clients list)
      )}

      {initials ? <Initials title={tooltip}>{initials}</Initials> : null}
      {statusColor ? (
        <ActiveIcon color={statusColor} title={statusProject} />
      ) : null}
    </Container>
  );
};

const Image = styled.img`
  border-radius: 50%;

  display: ${(props) => (props.isValid ? "inline" : "none")};
  visibility: ${(props) => (props.isValid ? "visible" : "hidden")};
  z-index: 2;
  transition: filter 0.4s 0s;

  &:hover {
    filter: hue-rotate(190deg) saturate(8);
    transition-delay: 2s;
  }
`;

const Initials = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  font-size: 20px;
  font-weight: 600;
  line-height: 0;
  color: ${(props) => props.theme.colors.pureWhite};
  margin: 0px;

  user-select: none;
`;

const ActiveIcon = styled.div`
  height: 14px;
  width: 14px;
  background-color: ${(props) =>
    props.color === "green"
      ? props.theme.colors.green500
      : props.color === "red"
      ? props.theme.colors.red500
      : props.color === "blue"
      ? props.theme.colors.blue500
      : props.theme.colors.coolGray300};
  border: 2px solid #ffffff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  z-index: 5;
`;

const Container = styled.div`
  position: relative;
  z-index: 1;

  width: ${(props) => (props.xsmall ? "15px" : props.small ? "30px" : "50px")};
  height: ${(props) => (props.xsmall ? "15px" : props.small ? "30px" : "50px")};
  min-width: 30px;
  min-height: 30px;

  border-radius: 50%;

  background-color: ${(props) => props.theme.colors[props.color]};

  box-shadow: ${(props) =>
    props.hasBorder
      ? `0px 0px 0px 2px ${props.theme.colors[props.borderColor]}`
      : undefined};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  opacity: ${(props) => (props.hasLoaded ? 1 : 0)};
  transition: opacity 0.2s, visibility 0.2s;

  ${Initials} {
    font-size: ${(props) =>
      props.xsmall ? "12px" : props.small ? "14px" : `20px`};
  }

  ${ActiveIcon} {
    height: ${(props) =>
      props.xsmall ? "10px" : props.small ? "12px" : `14px`};
    width: ${(props) =>
      props.xsmall ? "10px" : props.small ? "12px" : `14px`};
    border-width: ${(props) =>
      props.xsmall ? "1px" : props.small ? "2px" : `2px`};
  }

  ${respondTo("xlarge")} {
    height: ${(props) =>
      props.xsmall ? "30px" : props.small ? "40px" : "55px"};
    width: ${(props) =>
      props.xsmall ? "30px" : props.small ? "40px" : "55px"};
  }
`;

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  initials: PropTypes.string,
  small: PropTypes.bool,
  xsmall: PropTypes.bool,
  hasBorder: PropTypes.bool,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  statusColor: PropTypes.string,
  statusProject: PropTypes.string,
};

Avatar.defaultProps = {
  src: "",
  alt: "",
  initials: "",
  small: false,
  xsmall: false,
  hasBorder: false,
  borderColor: "pureWhite",
};

export default Avatar;
