import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  format,
  intervalToDuration,
  formatDuration,
  isBefore,
  isSameDay,
  setHours,
} from "date-fns";

import {
  getStandardizedDate,
  getCurrency,
  capitalize,
  getSortFunction,
  getBasicDateString,
} from "../../../../utils/helpers";

import { TableHeader, TableRow, TableCell } from "../../../../components/Table";
import SimpleLink from "../../../../components/links/SimpleLink";

const InvoicesTable = ({ invoices }) => {
  const [preppedInvoices, setPreppedInvoices] = useState(invoices);
  const [sortedInvoices, setSortedInvoices] = useState([]);
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    if (invoices) {
      const updatedInvoices = invoices.map((invoice) => {
        const displayStatus =
          invoice.status === "published" || invoice.status === "sent"
            ? !invoice.balance
              ? "Paid"
              : getDueDuration(invoice.dueDate)
            : invoice.status;

        return {
          ...invoice,
          displayStatus,
        };
      });

      setPreppedInvoices(updatedInvoices);
      // default sort
      setSortBy("displayStatus-desc");
    }
  }, [invoices]);

  // Handles the sorting
  useEffect(() => {
    if (preppedInvoices) {
      const newSortedInvoices = [...preppedInvoices];

      if (sortBy) {
        let sortFunc;

        // if we have a custom sort function, use that
        if (Object.prototype.hasOwnProperty.call(customSortFunctions, sortBy)) {
          sortFunc = customSortFunctions[sortBy];
        } else {
          // otherwise, generate a generic sort function
          const sortParts = sortBy.split("-");
          const sortField = sortParts[0];
          const sortDirection = sortParts[1];
          const sortFieldInfo = tableHeadings.find(
            (heading) => heading.value === sortField,
          );
          sortFunc = getSortFunction({
            type: sortFieldInfo.type,
            direction: sortDirection,
            field: sortField,
          });
        }

        if (sortFunc) {
          newSortedInvoices.sort(sortFunc);
        }
      }

      setSortedInvoices(newSortedInvoices);
    }
  }, [sortBy, preppedInvoices]);

  const getDueDuration = (dueDate) => {
    const basicDueDate = new Date(getBasicDateString(new Date(dueDate)));

    // reset today's hours back to 0
    // ? to ensure no unexpected results due to timezones
    const today = setHours(new Date(), 0);
    const basicCurrentDate = new Date(getBasicDateString(new Date(today)));

    const interval = {
      start: basicCurrentDate,
      end: basicDueDate,
    };

    const duration = intervalToDuration(interval);

    const formatted = formatDuration(duration, {
      format: ["months", "weeks", "days"],
      delimiter: ", ",
    });

    // check if the due date is in the past
    const isPast = isBefore(basicDueDate, basicCurrentDate);

    const isToday = isSameDay(basicCurrentDate, basicDueDate);

    // if there are no days remaining, but there are a matter of hours/minutes/seconds left
    const isTomorrow =
      !duration.days &&
      (duration.hours || duration.minutes || duration.seconds);

    return isPast && !isToday
      ? `Overdue ${formatted}`
      : isTomorrow
      ? "Due tomorrow"
      : formatted
      ? `Due in ${formatted}`
      : "Due today";
  };

  // ordering for the invoices based on their status
  const getStatusOrder = (invoice) =>
    invoice.status === "published" || invoice.status === "sent"
      ? !invoice.balance
        ? // ? tack on the invoice number as a decimal so the more recent invoices are displayed higher
          Number(`1.${invoice.accountingNumber}`) // paid
        : invoice.displayStatus.toLowerCase().indexOf("overdue") > -1
        ? Number(`4.${999999 - Number(invoice.accountingNumber)}`) // overdue
        : // ? tack on the decimal so the earlier invoices are displayed higher than the more recent ones
          Number(`3.${999999 - Number(invoice.accountingNumber)}`) // open
      : invoice.status === "void"
      ? Number(`0.${invoice.accountingNumber}`)
      : invoice.status === "review"
      ? 2.2
      : invoice.status === "revise"
      ? 2.1
      : 2.0; // draft

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  const customSortFunctions = {
    "displayStatus-asc": (a, b) => {
      const statusA = getStatusOrder(a);
      const statusB = getStatusOrder(b);
      return statusA < statusB ? -1 : statusA > statusB ? 1 : 0;
    },
    "displayStatus-desc": (a, b) => {
      const statusA = getStatusOrder(a);
      const statusB = getStatusOrder(b);
      return statusA > statusB ? -1 : statusA < statusB ? 1 : 0;
    },
    "accountingNumber-asc": (a, b) => {
      const textA = a.accountingNumber;
      const textB = b.accountingNumber;
      return textA < textB || (textA && !textB)
        ? -1
        : textA > textB || (!textA && textB)
        ? 1
        : 0;
    },
    "accountingNumber-desc": (a, b) => {
      const textA = a.accountingNumber;
      const textB = b.accountingNumber;
      return textA > textB || (!textA && textB)
        ? -1
        : textA < textB || (textA && !textB)
        ? 1
        : 0;
    },
    "dueDate-asc": (a, b) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return isBefore(dateA, dateB) || (a.dueDate && !b.dueDate)
        ? -1
        : isBefore(dateB, dateA) || (!a.dueDate && b.dueDate)
        ? 1
        : 0;
    },
    "dueDate-desc": (a, b) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return isBefore(dateB, dateA) || (!a.dueDate && b.dueDate)
        ? -1
        : isBefore(dateA, dateB) || (a.dueDate && !b.dueDate)
        ? 1
        : 0;
    },
    "sentDate-asc": (a, b) => {
      const dateA = new Date(a.sentDate);
      const dateB = new Date(b.sentDate);
      return isBefore(dateA, dateB) || (a.sentDate && !b.sentDate)
        ? -1
        : isBefore(dateB, dateA) || (!a.sentDate && b.sentDate)
        ? 1
        : 0;
    },
    "sentDate-desc": (a, b) => {
      const dateA = new Date(a.sentDate);
      const dateB = new Date(b.sentDate);
      return isBefore(dateB, dateA) || (!a.sentDate && b.sentDate)
        ? -1
        : isBefore(dateA, dateB) || (a.sentDate && !b.sentDate)
        ? 1
        : 0;
    },
  };

  const tableHeadings = [
    {
      label: "Invoice #",
      value: "accountingNumber",
      type: "number",
    },
    {
      label: "Sent Date",
      value: "sentDate",
      type: "date",
    },
    {
      label: "Due Date",
      value: "dueDate",
      type: "date",
    },
    {
      label: "Total",
      value: "total",
      type: "number",
    },
    {
      label: "Balance",
      value: "balance",
      type: "number",
    },

    {
      label: "Status",
      value: "displayStatus",
      type: "string",
    },
  ];

  return (
    <Table>
      <thead>
        <tr>
          {tableHeadings.map((heading) => (
            <TableHeader
              key={heading.value}
              onClick={() => changeSort(heading.value)}
              isArrowUp={sortBy === `${heading.value}-desc`}
              data-align={
                heading.value === "total" || heading.value === "balance"
                  ? "right"
                  : undefined
              }
            >
              {heading.label}
            </TableHeader>
          ))}
          <th>{/* View */}</th>
        </tr>
      </thead>
      <tbody>
        {sortedInvoices.length ? (
          sortedInvoices.map((invoice) => {
            const status = invoice.displayStatus || invoice.status;

            const hasDanger = status.toLowerCase().indexOf("overdue") > -1;

            const createdAt = invoice.createdAt
              ? format(getStandardizedDate(invoice.createdAt), "MM/yyyy")
              : "";

            const mainTitle = invoice.accountingNumber
              ? `${invoice.accountingNumber}${
                  invoice.client?.acronym ? ` - ${invoice.client.acronym}` : ""
                }`
              : null;

            const clientName = invoice.client?.acronym || invoice.client.name;

            const altTitle = `${clientName}${
              createdAt ? ` - ${createdAt}` : ""
            }`;

            const title = mainTitle || altTitle;

            return (
              <TableRow key={invoice._id}>
                <TableCell>
                  <SimpleLink as={Link} to={`/manage/invoices/${invoice._id}`}>
                    {title}
                  </SimpleLink>
                </TableCell>
                <TableCell>
                  {invoice.sentDate ? (
                    format(getStandardizedDate(invoice.sentDate), "E, MMM do")
                  ) : invoice.status === "published" ? (
                    <strong>Not sent</strong>
                  ) : null}
                </TableCell>
                <TableCell>
                  {invoice.dueDate
                    ? format(getStandardizedDate(invoice.dueDate), "E, MMM do")
                    : null}
                </TableCell>
                <TableCell data-align="right">
                  {getCurrency(invoice.total, true)}
                </TableCell>
                <TableCell data-align="right">
                  {getCurrency(invoice.balance, true)}
                </TableCell>
                <TableCell>
                  <span style={{ fontWeight: hasDanger ? 700 : 400 }}>
                    {capitalize(status)}
                  </span>
                </TableCell>
                <TableCell>
                  <SimpleLink as={Link} to={`/manage/invoices/${invoice._id}`}>
                    View
                  </SimpleLink>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <tr>
            <td colSpan={tableHeadings.length + 1}>No invoices.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  th,
  td {
    padding-left: 2.3vw;
    padding-right: 2.3vw;
    text-align: left;

    &[data-align="right"] {
      text-align: right;
    }

    &:first-child {
      padding-left: 0;

      a {
        font-size: 16px;
      }
    }
  }

  td {
    font-weight: 400;
  }
`;

InvoicesTable.propTypes = {
  invoices: PropTypes.array,
};
InvoicesTable.defaultProps = {
  invoices: [],
};

export default InvoicesTable;
