import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getCapacityReportsFromApi } from "../../utils/api";
import { getWeek } from "../../utils/helpers";

import ProgressBar from "./components/bookedVsCapacity/ProgressBar";
import { TableRow, TableCell } from "../../components/Table";

const BookedVsCapacity = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    setupReports();
  }, []); //eslint-disable-line

  // Sets up the reports and preps for display
  const setupReports = async () => {
    let results = await getCapacityReportsFromApi();

    if (results) {
      // Sorts the reports from newest to oldest
      results.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Formats and preps data for display
      results = results.map((result) => {
        let status = "-";
        const weekText = generateWeekText(result.date);

        // Determines the week's status
        // * This is in JSX format so we can get the linebreaks
        if (result.booked > result.targetHigh) {
          status = (
            <>
              Under Staffed / <br /> Over Utilized
            </>
          );
        } else if (result.booked < result.targetLow) {
          status = (
            <>
              Over Staffed / <br /> Under Utilized
            </>
          );
        } else {
          status = "On Track";
        }

        return {
          status,
          weekText,
          ...result,
        };
      });

      setReports(results);
    }
  };

  // Format: '2021 week 16'
  const generateWeekText = (date) => {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const weekNum = getWeek(dateObj);

    return `${year} week ${weekNum}`;
  };

  return (
    <Container>
      <Header>
        <h1>Booked vs Capacity Report</h1>
      </Header>
      <Section>
        <Table>
          <TableHead>
            <tr>
              <th>Week</th>
              <th>Capacity</th>
              <th></th>
              <th>Booked</th>
              <th>Status</th>
            </tr>
          </TableHead>
          {reports.length > 0 ? (
            <tbody>
              {reports.map((report, index) => (
                <TableRow key={index}>
                  <Cell align="left">{report.weekText}</Cell>
                  <Cell>{report.targetLow} hours</Cell>
                  <Cell>
                    <Progress
                      actual={report.booked}
                      budgeted={report.targetHigh}
                      paceLow={(report.targetLow / report.targetHigh) * 100}
                      paceHigh={(report.targetHigh / report.targetHigh) * 100}
                    />
                  </Cell>
                  <Cell>{report.booked} hours</Cell>
                  <Cell>{report.status}</Cell>
                </TableRow>
              ))}
            </tbody>
          ) : null}
        </Table>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 100px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  margin-top: 60px;
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const TableHead = styled.thead`
  position: relative;

  th {
    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    &:last-child {
      padding-left: 20px;
    }
  }

  &::after {
    content: "";
    background-color: #000000;
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const Cell = styled(TableCell)`
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  text-align: left;

  &:not(:first-child):not(:last-child) {
    text-align: center;
  }
`;

const Section = styled.div`
  padding: 40px 0;
  margin-left: 50px;
  margin-right: 90px;
  max-width: 700px;
`;

const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
    font-size: 12px;
  }
`;

const Progress = styled(ProgressBar)`
  width: 187px;
`;

export default BookedVsCapacity;
