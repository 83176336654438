import React from "react";

import { getCurrency, isNumber } from "../../../utils/helpers";

import DonutChart from "../../../components/DonutChart";

const BudgetWheel = ({
  current,
  total,
  pace,
  small = false,
  label,
  isLoading,
  ...rest
}) => {
  return (
    <DonutChart
      fill={current || 0}
      total={total}
      pace={pace}
      hasDanger={current ? current > total : false}
      meta={label}
      title={isNumber(current) ? getCurrency(current) : "-"}
      subtitle={`/${getCurrency(total)}`}
      small={small}
      style={{ opacity: isLoading ? 0.5 : 1 }}
      {...rest}
    />
  );
};

export default BudgetWheel;
