import axios from "axios";
import { headers } from "../../../utils/api";

export const getAllProjectsFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAllBookingPlansFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/plans`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getProjectFromApi = async (id, params = {}) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/projects/${id}`,
      headers,
      params,
    };

    const response = await axios(config);

    return {
      databaseProject: response.data.data.databaseProject,
      harvestProject: response.data.data.harvestProject,
    };
  } catch (error) {
    console.error(error);
  }
};

export const createProjectOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/`,
      headers,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (err) {
    const errMessage = getErrorMessage(err);
    throw errMessage;
  }
};

export const editProjectOnApi = async (data) => {
  const config = {
    method: "patch",
    url: `${process.env.REACT_APP_API_URL}/projects/${data.projectId}`,
    headers,
    data,
  };

  const result = await axios(config);

  if (result.data.status === "success") {
    return result;
  } else {
    throw result.data.result;
  }
};

export const planProjectOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projects/${data.projectId}/plan`,
      headers,
      data,
    };

    const result = await axios(config);

    if (result.data.status === "success") {
      return true;
    } else {
      throw new Error(result.data.message);
    }
  } catch (err) {
    console.error(err);
    throw new Error("Project plan failed");
  }
};

export const updateProjectPlanOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/projects/${data.projectId}/plan`,
      headers,
      data,
    };

    const result = await axios(config);

    if (result.data.status === "success") {
      return true;
    } else {
      throw new Error(result.data.message);
    }
  } catch (err) {
    console.error(err);
    throw new Error("Project plan failed");
  }
};

export const createClientOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/companies`,
      headers,
      data,
    };

    const result = await axios(config);

    return result.data;
  } catch (err) {
    console.error(err);
    const errMessage = getErrorMessage(err);
    throw errMessage;
  }
};

export const getClientFromApi = async (id, params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/companies/${id}`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getClientEntriesFromApi = async (clientId, calculate) => {
  try {
    let url = "";
    if (calculate) {
      url = `${
        process.env.REACT_APP_API_URL
      }/time/past/entries/companies?clientId=${clientId}&calculate=${true}`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/time/past/entries/companies?clientId=${clientId}`;
    }

    const config = {
      method: "get",
      url,
      headers,
    };

    const response = await axios(config);

    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const updateClientOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/companies/${data.clientId}`,
      headers,
      data,
    };

    const response = await axios(config);

    return response.data;
  } catch (err) {
    console.error(err);
    const errMessage = getErrorMessage(err);
    throw errMessage;
  }
};

export const getAllRolesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/roles`,
      headers,
      params,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getMembersFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/members`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getProducersFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/team/roles/Producer`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getRatesFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/rates`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getTeamsFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/teams`,
      headers,
    };

    const response = await axios(config);

    return response.data.data.results;
  } catch (error) {
    console.error(error);
  }
};

/*
|--------------------------------------------------------------------------
| Access Levels
|--------------------------------------------------------------------------
*/
export const getAccessLevelsFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/accessLevels`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getAccessLevelFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/accessLevels/${id}`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createAccessLevelOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/accessLevels/`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAccessLevelOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/accessLevels/${data.accessId}`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| KPI
|--------------------------------------------------------------------------
*/
export const getKpisFromApi = async (member, custom) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/kpi`,
      headers,
      params: {
        member,
        custom,
      },
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getKpiFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/kpi/${id}`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createKpiOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/kpi/`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateKpiOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/kpi/${data.id}`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Videos
|--------------------------------------------------------------------------
*/
export const getVideosFromApi = async () => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/videos`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getVideoFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/videos/${id}`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createVideoOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/videos/`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateVideoOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/videos/${data.videoId}`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

/*
|--------------------------------------------------------------------------
| Extra Time
|--------------------------------------------------------------------------
*/
export const getExtraTimesFromApi = async (params) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/time`,
      headers,
      params,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const getExtraTimeFromApi = async (id) => {
  try {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/manage/time/${id}`,
      headers,
    };

    const response = await axios(config);
    return response.data.data.result;
  } catch (error) {
    console.error(error);
  }
};

export const createExtraTimeOnApi = async (data) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/manage/time/`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateExtraTimeOnApi = async (data) => {
  try {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/manage/time/${data.id}`,
      headers,
      data,
    };

    const result = await axios(config);
    return result;
  } catch (err) {
    throw new Error(err);
  }
};

// * helpers
const getErrorMessage = (err) => err?.response?.data?.message || err;
