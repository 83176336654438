import React from "react";

const TaskNotesIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.40462 4.66671H12.738M4.40462 8.00004H7.73796M8.57129 14.6667L5.23796 11.3334H2.73796C2.29593 11.3334 1.872 11.1578 1.55944 10.8452C1.24688 10.5327 1.07129 10.1087 1.07129 9.66671V3.00004C1.07129 2.55801 1.24688 2.13409 1.55944 1.82153C1.872 1.50897 2.29593 1.33337 2.73796 1.33337H14.4046C14.8466 1.33337 15.2706 1.50897 15.5831 1.82153C15.8957 2.13409 16.0713 2.55801 16.0713 3.00004V9.66671C16.0713 10.1087 15.8957 10.5327 15.5831 10.8452C15.2706 11.1578 14.8466 11.3334 14.4046 11.3334H11.9046L8.57129 14.6667Z"
        stroke="#374151"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TaskNotesIcon;
