import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../context/authContext";

const PrivateRoute = ({
  render: Component,
  managerOrAdmin,
  adminOnly,
  ...rest
}) => {
  const { user, isAdmin, isManagerOrAdmin, authenticating } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        // logged in
        if (user) {
          let canAccess = false;

          // If the route is admin only
          if (adminOnly && isAdmin) {
            canAccess = true;

            // If the route can be either manager or admin
          } else if (managerOrAdmin && isManagerOrAdmin) {
            canAccess = true;

            // If this is a route any logged in user can access
          } else if (!adminOnly && !managerOrAdmin) {
            canAccess = true;
          }

          if (canAccess) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            );
          }
        } else if (!authenticating) {
          // authentication is complete and there is no logged in user, so redirect to login view
          // (must wait until authentication is complete, otherwise everyone would be redirected to login whenever they refresh the page)
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  managerOrAdmin: PropTypes.bool,
  adminOnly: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  managerOrAdmin: false,
  adminOnly: false,
};

export default PrivateRoute;
