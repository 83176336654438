import React from "react";
import styled from "styled-components";

const StartIcon = () => {
  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.00693261 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0V0ZM22.8571 20.5714C22.8571 21.1776 22.6163 21.759 22.1877 22.1877C21.759 22.6163 21.1776 22.8571 20.5714 22.8571H11.4286C10.8224 22.8571 10.241 22.6163 9.81233 22.1877C9.38368 21.759 9.14286 21.1776 9.14286 20.5714V11.4286C9.14286 10.8224 9.38368 10.241 9.81233 9.81233C10.241 9.38367 10.8224 9.14286 11.4286 9.14286H20.5714C21.1776 9.14286 21.759 9.38367 22.1877 9.81233C22.6163 10.241 22.8571 10.8224 22.8571 11.4286V20.5714Z" />
    </Svg>
  );
};

const Svg = styled.svg`
  transition: transform 0.2s;

  path {
    transition: fill 0.2s;
    fill: ${(props) => props.theme.colors.red};
  }
`;

export default StartIcon;
