import React, { useEffect, useState } from "react";

import { useActiveTimer } from "../context/activeTimerContext";
import { useAuth } from "../context/authContext";

import { toggleFavicon, setTabTitle } from "../utils/tabEffects";

// * Handle the favicon here so that its state is isolated and doesn't cause the rest of the app to re-render

const Favicon = () => {
  const {
    isTimerRunning,
    currentProjectName,
    faviconEnabled,
  } = useActiveTimer();
  const { isDev } = useAuth();

  const [favicon, setFavicon] = useState("mountain");
  const [warning, setWarning] = useState(true);
  const [countdown, setCountdown] = useState(null);

  /*
  |--------------------------------------------------------------------------
  | Handles favicon and page title changes
  | (including flashing if a timer is not active)
  |--------------------------------------------------------------------------
  */
  // Toggle favicon and page title to default if timer is inactive
  useEffect(() => {
    if (!isTimerRunning && faviconEnabled) {
      setTabTitle("Sherpa");
      toggleFavicon("mountain");

      // Countdown to 60 seconds before starting the epilepsy
      const newCountdown = setTimeout(function () {
        if (faviconEnabled) {
          setWarning(true);
          setFavicon("white");
          toggleFavicon("white");

          // Checks if we're in any dev modes
          // if we're in production, opens a new window every time it triggers
          if (!isDev && process.env.REACT_APP_ENVIRONMENT !== "development") {
            window.open("/");
          }

          setTabTitle("START YOUR TIMER - Sherpa");
        }
      }, 60000);

      setCountdown(newCountdown);
    } else if (isTimerRunning) {
      // If there is a timer running, clear all countdowns
      clearTimeout(countdown);
      setWarning(false);

      setFavicon("green");
      toggleFavicon("green");
      setTabTitle(
        currentProjectName ? currentProjectName + " - Sherpa" : "Sherpa",
      );
    } else {
      clearTimeout(countdown);
      setWarning(false);
      setTabTitle("Sherpa");
      toggleFavicon("mountain");
      setFavicon("mountain");
    }
  }, [isTimerRunning, faviconEnabled]); //eslint-disable-line

  useEffect(() => {
    // If warning is set to true, and the favicon is not set to green or mountain, start the epilepsy
    if (
      warning &&
      favicon !== "mountain" &&
      favicon !== "green" &&
      process.env.REACT_APP_ENVIRONMENT !== "development"
    ) {
      setTimeout(function () {
        if (favicon === "red") {
          setFavicon("white");
        } else {
          setFavicon("red");
        }
        toggleFavicon(favicon);
      }, 500);
      // If warning is set to false, and the favicon is set to green, change the favicon to green
    } else if (!warning && favicon === "green") {
      setTimeout(function () {
        toggleFavicon("green");
      }, 500);
    }
  }, [favicon, warning]);
  return <></>;
};

export default Favicon;
