import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { getInvoicesFromApi, getCompaniesFromApi } from "../../../utils/api";
import { getCurrency } from "../../../utils/helpers";
import CreateLink from "../../../components/links/CreateLink";
import SimpleLink from "../../../components/links/SimpleLink";
import {
  TableHeader,
  TableRow,
  TableCell,
  TableCellTitle,
} from "../../../components/Table";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [sortBy, setSortBy] = useState("name");
  const [openedSection, setOpenedSection] = useState("active");

  // array to store the clients with any extra info that is fetched from the API after inital load.
  // (properties that aren't present in the original array of clients)
  // used for sorting purposes
  const updatedClients = useRef([]);

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    try {
      const clientsFromApi = await getCompaniesFromApi({ client: true });
      clientsFromApi.sort((a, b) => {
        const aName = a.name.toUpperCase();
        const bName = b.name.toUpperCase();

        return aName > bName ? 1 : aName < bName ? -1 : 0;
      });
      setClients(clientsFromApi);
    } catch (error) {
      console.log("error getting clients", error);
    }
  };

  // Handles the actual sorting of the clients
  useEffect(() => {
    const sortedClients = [...clients];

    if (sortBy === "name-asc") {
      sortedClients.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedClients.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "producer-asc") {
      sortedClients.sort(function(a, b) {
        var textA = a.producer.name.toUpperCase();
        var textB = b.producer.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "producer-desc") {
      sortedClients.sort(function(a, b) {
        var textA = a.producer.name.toUpperCase();
        var textB = b.producer.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else if (sortBy === "partner-asc") {
      sortedClients.sort(function(a, b) {
        var textA = a?.partner?.name.toUpperCase();
        var textB = b?.partner?.name.toUpperCase();
        return !textA || !textB
          ? -1
          : textA < textB
          ? -1
          : textA > textB
          ? 1
          : 0;
      });
    } else if (sortBy === "partner-desc") {
      sortedClients.sort(function(a, b) {
        var textA = a?.partner?.name.toUpperCase();
        var textB = b?.partner?.name.toUpperCase();
        return !textA || !textB
          ? 1
          : textA > textB
          ? -1
          : textA < textB
          ? 1
          : 0;
      });
    } else if (sortBy === "invoices-desc") {
      sortedClients.sort(function(a, b) {
        const clientA = updatedClients.current.find(
          (client) => client._id === a._id,
        );
        const clientB = updatedClients.current.find(
          (client) => client._id === b._id,
        );

        const numberA = clientA?.openInvoices || 0;
        const numberB = clientB?.openInvoices || 0;
        return numberA < numberB ? -1 : numberA > numberB ? 1 : 0;
      });
    } else if (sortBy === "invoices-asc") {
      sortedClients.sort(function(a, b) {
        const clientA = updatedClients.current.find(
          (client) => client._id === a._id,
        );
        const clientB = updatedClients.current.find(
          (client) => client._id === b._id,
        );

        const numberA = clientA?.openInvoices || 0;
        const numberB = clientB?.openInvoices || 0;
        return numberA > numberB ? -1 : numberA < numberB ? 1 : 0;
      });
    }
    if (sortBy === "status-asc") {
      sortedClients.sort(function(a, b) {
        var textA = a.status;
        var textB = b.status;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "status-desc") {
      sortedClients.sort(function(a, b) {
        var textA = a.status;
        var textB = b.status;
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setClients(sortedClients);
  }, [sortBy]); //eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  const toggleSection = (newSection) => {
    if (openedSection === newSection) {
      setOpenedSection("");
    } else {
      setOpenedSection(newSection);
    }
  };

  const activeClients = clients
    ? clients.filter((client) => client.status === "Client")
    : [];

  const closedClients = clients
    ? clients.filter((client) => client.status !== "Client")
    : [];

  return (
    <ClientsContainer>
      <ClientsHeader>
        <h1>Clients</h1>
        <CreateLink as={Link} to={`/manage/clients/new`}>
          Create Client
        </CreateLink>
      </ClientsHeader>

      {clients.length ? (
        <>
          <SectionContainer>
            <SectionHeader onClick={() => toggleSection("active")}>
              <SectionHeaderTitle>Active Clients</SectionHeaderTitle>{" "}
            </SectionHeader>
            <SectionContent
              animate={openedSection === "active" ? "open" : "closed"}
              variants={{
                open: { height: "auto" },
                closed: { height: 0 },
              }}
              transition={{ duration: 0.4 }}
            >
              <ClientsList id="clientsTable">
                <thead>
                  <tr>
                    <TableHeader
                      onClick={() => changeSort("name")}
                      isArrowUp={sortBy === "name-desc"}
                      isActive={sortBy.includes("name")}
                      align="left"
                    >
                      Client Name
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("producer")}
                      isArrowUp={sortBy === "producer-desc"}
                      isActive={sortBy.includes("producer")}
                    >
                      Relationship Manager
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("partner")}
                      isArrowUp={sortBy === "partner-desc"}
                      isActive={sortBy.includes("partner")}
                    >
                      Partnership Manager
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("invoices")}
                      isArrowUp={sortBy === "invoices-desc"}
                      isActive={sortBy.includes("invoices")}
                    >
                      Open Invoices
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("status")}
                      isArrowUp={sortBy === "status-desc"}
                      isActive={sortBy.includes("status")}
                    >
                      Status
                    </TableHeader>
                    <th>{/* Edit */}</th>
                  </tr>
                </thead>
                <tbody>
                  {activeClients.length ? (
                    activeClients.map((client) => (
                      <ClientRow
                        key={client._id}
                        client={client}
                        updateClient={(toUpdate) => {
                          // so we have the billed dollars and tasks info
                          updatedClients.current.push({
                            ...client,
                            ...toUpdate,
                          });
                        }}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>No active clients.</td>
                    </tr>
                  )}
                </tbody>
              </ClientsList>
            </SectionContent>
          </SectionContainer>

          <SectionContainer>
            <SectionHeader onClick={() => toggleSection("closed")}>
              <SectionHeaderTitle>Past Clients</SectionHeaderTitle>{" "}
            </SectionHeader>
            <SectionContent
              animate={openedSection === "closed" ? "open" : "closed"}
              variants={{
                open: { height: "auto" },
                closed: { height: 0 },
              }}
              transition={{ duration: 0.4 }}
            >
              <ClientsList id="clientsTable">
                <thead>
                  <tr>
                    <TableHeader
                      onClick={() => changeSort("name")}
                      isArrowUp={sortBy === "name-desc"}
                      isActive={sortBy.includes("name")}
                      align="left"
                    >
                      Client Name
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("producer")}
                      isArrowUp={sortBy === "producer-desc"}
                      isActive={sortBy.includes("producer")}
                    >
                      Relationship Manager
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("partner")}
                      isArrowUp={sortBy === "partner-desc"}
                      isActive={sortBy.includes("partner")}
                    >
                      Partnership Manager
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("invoices")}
                      isArrowUp={sortBy === "invoices-desc"}
                      isActive={sortBy.includes("invoices")}
                    >
                      Open Invoices
                    </TableHeader>
                    <TableHeader
                      onClick={() => changeSort("status")}
                      isArrowUp={sortBy === "status-desc"}
                      isActive={sortBy.includes("status")}
                    >
                      Status
                    </TableHeader>
                    <th>{/* Edit */}</th>
                  </tr>
                </thead>
                <tbody>
                  {closedClients.length ? (
                    closedClients.map((client) => (
                      <ClientRow
                        key={client._id}
                        client={client}
                        updateClient={(toUpdate) => {
                          // so we have the billed dollars and tasks info
                          updatedClients.current.push({
                            ...client,
                            ...toUpdate,
                          });
                        }}
                      />
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>No past clients.</td>
                    </tr>
                  )}
                </tbody>
              </ClientsList>
            </SectionContent>
          </SectionContainer>
        </>
      ) : null}
    </ClientsContainer>
  );
};

const ClientRow = ({ client, updateClient }) => {
  const [openInvoices, setOpenInvoices] = useState(null);

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
    try {
      // get all of this client's sent invoices
      const invoices = await getInvoicesFromApi({
        client: client._id,
        status: "sent",
      });

      let openValue = 0;

      //  sum up the balance
      invoices.forEach((invoice) => {
        openValue += invoice.balance;
      });

      setOpenInvoices(openValue);

      updateClient({ openInvoices: openValue });
    } catch (error) {
      console.error("error getting invoices", error);
    }
  };

  return (
    <TableRow>
      <TableCell align="left">
        <TableCellTitle>
          <ClientLink to={`/manage/clients/${client._id}`}>
            {client.acronym ? `${client.acronym} -` : ""} {client.name}
          </ClientLink>
        </TableCellTitle>
      </TableCell>
      <TableCell>{client?.producer?.name || "N/A"}</TableCell>
      <TableCell>{client?.partner?.name || "N/A"}</TableCell>
      <TableCell>
        {openInvoices !== null ? getCurrency(openInvoices) : "..."}
      </TableCell>
      <TableCell>{client?.status || "N/A"}</TableCell>
      <TableCell>
        <SimpleLink as={Link} to={`/manage/clients/${client._id}/edit`}>
          Edit
        </SimpleLink>
      </TableCell>
    </TableRow>
  );
};

const ClientsContainer = styled.div`
  padding: 0px 50px 50px 50px;

  h2 {
    font-size: 28px;
    color: #373040;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  thead {
    th:first-child {
      padding-left: 0;
    }
  }
`;

const ClientsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 40px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #373040;
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const ClientsList = styled.table`
  margin: 0px;
  width: 100%;
  padding-top: 50px;
`;

const ClientLink = styled(Link)`
  font-weight: 700;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.oldBlack};
  text-transform: none;
  transition: color 200ms;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.slate};
  }
`;

const SectionContainer = styled.div`
  padding: 40px 0px;
  border-top: 1px solid #000;

  &:last-child {
    border-bottom: 1px solid #000;
  }
`;

const SectionHeader = styled.div`
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

const SectionHeaderTitle = styled.h3`
  margin-bottom: 0;
`;

const SectionContent = styled(motion.div)`
  height: 0;
  overflow: auto;
`;

export default Clients;
