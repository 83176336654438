import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  format,
  startOfWeek,
  endOfWeek,
  getDay,
  addWeeks,
  subWeeks,
  addMonths,
} from "date-fns";

import { useNotifications } from "../../context/notificationsContext";

import { getWeek, getStandardizedDate, getCurrency } from "../../utils/helpers";

import { getProductionProjectsFromApi } from "../../utils/api";

import ProgressBar from "../dashboard/components/ProgressBar";
import WeekSettings from "../dashboard/components/WeekSettings";

import { TableHeader, TableRow, TableCell } from "../../components/Table";

import DayCompletion from "./components/productionReport/productionDayCompletion";

const Production = () => {
  const { setLoading } = useNotifications();

  const [clientProjects, setClientProjects] = useState([]);
  const [clientTasks, setClientTasks] = useState([]);
  const [clientTasksCompleted, setClientTasksCompleted] = useState(0);
  const [clientTasksOverdue, setClientTasksOverdue] = useState(0);
  const [internalProjects, setInternalProjects] = useState([]);
  const [internalTasks, setInternalTasks] = useState([]);
  const [internalTasksCompleted, setInternalTasksCompleted] = useState(0);
  const [internalTasksOverdue, setInternalTasksOverdue] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  const startWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  const startWeek = format(startWeekDate, "d");
  const endWeek = format(endWeekDate, "d");

  const thisMonth = format(startWeekDate, "MMMM");
  const thisMonthShort = format(startWeekDate, "MMM");
  const nextMonthShort = format(addMonths(startWeekDate, 1), "MMM");

  const weekDuration =
    // if the end of the week is a smaller number than the beginning of the week, then the week must end with a different month
    parseInt(endWeek) < parseInt(startWeek)
      ? `${thisMonthShort} ${startWeek} - ${nextMonthShort} ${endWeek}`
      : `${thisMonth} ${startWeek} - ${endWeek}`;

  const weekNumber = getWeek(getStandardizedDate(currentDate));

  /*
  |--------------------------------------------------------------------------
  | Get all projects from the API
  |--------------------------------------------------------------------------
  */
  const getProjects = async () => {
    try {
      setLoading(true);
      const results = await getProductionProjectsFromApi(
        format(currentDate, "yyyy-MM-dd"),
        weekNumber,
      );

      if (results && results.data.data) {
        setClientProjects(results.data.data.clientProjects);
        setClientTasks(results.data.data.clientTasks);
        setClientTasksCompleted(results.data.data.clientTasksCompleted);
        setClientTasksOverdue(results.data.data.clientTasksOverdue);
        setInternalProjects(results.data.data.internalProjects);
        setInternalTasks(results.data.data.internalTasks);
        setInternalTasksCompleted(results.data.data.internalTasksCompleted);
        setInternalTasksOverdue(results.data.data.internalTasksOverdue);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error getting projects", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all projects based on the week number
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getProjects();
  }, [weekNumber]); //eslint-disable-line

  const generateDollarsMessage = (used, total) => {
    let value = Math.round((total - used) * 100) / 100;

    value = value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      // whether to show cents ".00" at the end
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (value < 0) {
      value *= -1;
      return `-${value}`;
    } else {
      return `${value}`;
    }
  };

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Name>Production Success Report</Name>
        </HeaderLeft>

        <HeaderRight>
          <WeekSettings
            id="time-entries-switch"
            onText="All Time"
            offText={`Week ${weekNumber}`}
            weekDuration={weekDuration}
            handlePrevWeek={() => setCurrentDate(subWeeks(currentDate, 1))}
            handleNextWeek={() => setCurrentDate(addWeeks(currentDate, 1))}
            disableSwitch={true}
          />
        </HeaderRight>
      </Header>

      <Projects>
        <thead>
          <tr>
            <TableHeader align="left" noArrow>
              Project
            </TableHeader>

            <TableHeader align="left" noArrow>
              Booked
              <br />
              Revenue
            </TableHeader>

            <TableHeader align="left" noArrow>
              Booked
              <br />
              Hours
            </TableHeader>

            <TableHeader align="left" noArrow>
              Billed Hours vs Unbilled Hours
            </TableHeader>

            <TableHeader align="left" noArrow>
              Unbilled
              <br />
              Revenue
            </TableHeader>

            <TableHeader align="left" noArrow>
              Billed
              <br />
              Revenue
            </TableHeader>

            <TableHeader align="left" noArrow>
              Scheduled
              <br />
              Tasks
            </TableHeader>

            <TableHeader align="left" noArrow>
              Completed
              <br />
              Tasks
            </TableHeader>

            <TableHeader align="left" noArrow>
              Open
              <br />
              Tasks
            </TableHeader>

            <TableHeader align="left" noArrow>
              Overdue
              <br />
              Tasks
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <TableRow>
            <TableCell align="left">Client Projects</TableCell>
            <TableCell align="left">
              {clientProjects.totalBudgetDollars
                ? getCurrency(clientProjects.totalBudgetDollars, true)
                : 0}
            </TableCell>
            <TableCell align="left">
              {clientProjects.totalBudgetHours} hours
            </TableCell>
            <TableCell align="left">
              <Progress
                actual={clientProjects.totalBilledHours}
                budgeted={clientProjects.totalBudgetHours}
                tall
              />
              <Info>
                <p>Billed Hours: {clientProjects.totalBilledHours}</p>
                <p>
                  Unbilled Hours:{" "}
                  {clientProjects.totalBudgetHours -
                    clientProjects.totalBilledHours >
                  0
                    ? clientProjects.totalBudgetHours -
                      clientProjects.totalBilledHours
                    : 0}
                </p>
              </Info>
            </TableCell>
            <TableCell align="left">
              <NumItem
                isOver={
                  clientProjects.totalBilledHours >
                  clientProjects.totalBudgetHours
                }
              >
                {generateDollarsMessage(
                  clientProjects.totalBilledDollars,
                  clientProjects.totalBudgetDollars,
                )}
              </NumItem>
            </TableCell>
            <TableCell align="left">
              {clientProjects.totalBilledDollars
                ? getCurrency(clientProjects.totalBilledDollars, true)
                : `$0`}
            </TableCell>
            <TableCell align="left">{clientTasks?.length || 0}</TableCell>
            <TableCell align="left">{clientTasksCompleted || 0}</TableCell>
            <TableCell align="left">
              <NumItem>
                {clientTasks ? clientTasks.length - clientTasksCompleted : 0}
              </NumItem>
            </TableCell>
            <TableCell
              align="left"
              style={
                clientTasksOverdue && clientTasksOverdue > 0
                  ? { color: "#DF5757", fontWeight: "700" }
                  : null
              }
            >
              {clientTasksOverdue}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell align="left">Big Vision Projects</TableCell>
            <TableCell align="left">
              {internalProjects.totalBudgetDollars
                ? getCurrency(internalProjects.totalBudgetDollars, true)
                : 0}
            </TableCell>
            <TableCell align="left">
              {internalProjects.totalBudgetHours} hours
            </TableCell>
            <TableCell align="left">
              <Progress
                actual={internalProjects.totalBilledHours}
                budgeted={internalProjects.totalBudgetHours}
                tall
              />
              <Info>
                <p>Billed Hours: {internalProjects.totalBilledHours}</p>
                <p>
                  Unbilled Hours:{" "}
                  {internalProjects.totalBudgetHours -
                    internalProjects.totalBilledHours >
                  0
                    ? internalProjects.totalBudgetHours -
                      internalProjects.totalBilledHours
                    : 0}
                </p>
              </Info>
            </TableCell>
            <TableCell align="left">
              <NumItem
                isOver={
                  internalProjects.totalBilledHours >
                  internalProjects.totalBudgetHours
                }
              >
                {generateDollarsMessage(
                  internalProjects.totalBilledDollars,
                  internalProjects.totalBudgetDollars,
                )}
              </NumItem>
            </TableCell>
            <TableCell align="left">
              {internalProjects.totalBilledDollars
                ? getCurrency(internalProjects.totalBilledDollars, true)
                : "$0"}
            </TableCell>
            <TableCell align="left">{internalTasks?.length || 0}</TableCell>
            <TableCell align="left">{internalTasksCompleted || 0}</TableCell>
            <TableCell align="left">
              <NumItem>
                {internalTasks
                  ? internalTasks.length - internalTasksCompleted
                  : 0}
              </NumItem>
            </TableCell>
            <TableCell
              align="left"
              style={
                internalTasksOverdue && internalTasksOverdue > 0
                  ? { color: "#DF5757", fontWeight: "700" }
                  : null
              }
            >
              {internalTasksOverdue}
            </TableCell>
          </TableRow>
        </tbody>
      </Projects>
      <DayCompletion />
    </Container>
  );
};

const Container = styled.div`
  padding: 53px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
`;

const HeaderLeft = styled.div``;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h3`
  font-size: ${(props) => props.theme.fontSize_xl};
  color: ${(props) => props.theme.colors.darkSlate};
  font-weight: 600;
  line-height: 1;
  margin-bottom: 80px;
`;

const Projects = styled.table`
  width: 100%;
  margin-bottom: 50px;

  ${TableHeader} {
    padding: 0px 30px 10px 30px;
    vertical-align: bottom;

    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0px;
    }
  }

  ${TableCell} {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;

    &:first-of-type {
      padding-left: 0px;
    }

    &:last-of-type {
      padding-right: 0px;
    }
  }
`;

const NumItem = styled.p`
  color: ${(props) => (props.isOver ? props.theme.colors.red : `#532828`)};
  margin-bottom: 0;
  font-weight: ${(props) => (props.isOver ? `bold` : `500`)};
`;

const Info = styled.div`
  position: absolute;
  left: 30px;
  top: 0px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
  transform: translateY(90%);
  background-color: ${(props) => props.theme.colors.pureWhite};
  border: 1px solid ${(props) => props.theme.colors.lightBlue};
  z-index: 4;
  font-size: 12px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 0.2s, 0.2s;

  p {
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const Progress = styled(ProgressBar)`
  margin-bottom: 0;
  position: relative;
  cursor: help;

  &:hover ~ ${Info} {
    opacity: 1;
    transform: translateY(83%);
    pointer-events: all;
    transition-delay: 0s, 0s;
  }
`;

export default Production;
