import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { highlightTags } from "../../../../utils/helpers";
import { respondTo } from "../../../../styles/styleHelpers";

import CaretLeft from "../../../../components/icons/CaretLeft";
import CaretRight from "../../../../components/icons/CaretRight";
import Comment from "../../../../components/Comment";
import Button from "../../../../components/buttons/Button";

const InvoiceSidebar = ({
  comments,
  commentMessage,
  onComment,
  handleCommentChange,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const commentsEnd = useRef(null);

  // when new comments are added
  useEffect(() => {
    if (commentsEnd.current) {
      // scroll to the bottom of the container
      commentsEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments]);

  useEffect(() => {
    // keydown shortcut for toggling the backlog
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "k") {
      setIsOpen((isOpen) => !isOpen);
    }
  };

  return (
    <Container isOpen={isOpen}>
      <Actions>
        <Toggle
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? <CaretRight /> : <CaretLeft />}
        </Toggle>
      </Actions>

      <Inner>
        <Content>
          <Pad>
            <Heading>Invoice History</Heading>
          </Pad>

          <Comments>
            {comments ? (
              comments.length ? (
                comments.map((comment) => (
                  <Comment
                    key={comment._id}
                    author={comment.author}
                    postedDate={comment.postedDate}
                    message={highlightTags(comment.message)}
                  />
                ))
              ) : (
                <NoHistory>No history yet</NoHistory>
              )
            ) : null}
            <div ref={commentsEnd}></div>
          </Comments>

          {onComment ? (
            <Footer>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onComment();
                }}
              >
                <MessageInput
                  value={commentMessage}
                  onChange={handleCommentChange}
                  onKeyDown={(e) => {
                    // If they press cmd+enter in the textarea, we submit
                    if (e.metaKey && e.key === "Enter") {
                      onComment();
                    }
                  }}
                />

                <Submit type="submit">Send</Submit>
              </form>
            </Footer>
          ) : null}
        </Content>
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;

  flex-basis: ${(props) => (props.isOpen ? "300px" : "0px")};
  max-width: ${(props) => (props.isOpen ? "300px" : "0px")};
  flex-shrink: 0;

  ${respondTo("xlarge")} {
    flex-basis: ${(props) => (props.isOpen ? "350px" : "0px")};
    max-width: ${(props) => (props.isOpen ? "350px" : "0px")};
  }
`;

const Actions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);

  width: 30px;
  background-color: ${(props) => props.theme.colors.pureWhite};
`;

const Toggle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors.oldLightGray};
  border-right-width: ${(props) => (props.isActive ? 0 : undefined)};
  padding: 3px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: none;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 50px 0;
`;

const Heading = styled.h2`
  font-size: ${(props) => props.theme.fontSize_xxs};
  color: ${(props) => props.theme.colors.darkSlate};
  margin-bottom: 40px;
`;

const Pad = styled.div`
  padding-left: 40px;
  padding-right: 40px;
`;

const Comments = styled(Pad)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const NoHistory = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.slate};
  margin-top: auto;
  text-align: center;
  margin-bottom: auto;
  opacity: 0.7;
`;

const Footer = styled(Pad)`
  flex-shrink: 0;
  margin-top: auto;
  padding-top: 40px;

  form {
    display: flex;
    flex-direction: column;
  }
`;

const MessageInput = styled.textarea`
  width: 100%;
  height: 100px;

  font-size: 14px;
  padding: 10px;
  margin-bottom: 15px;

  background: white;
  border: 1px solid #dae1f5;
  border-radius: 3px;

  outline: none;
  resize: none;
  transition: 0.2s ease-in-out;

  ${respondTo("xlarge")} {
    height: 120px;
  }

  &:hover,
  &:focus {
    border-color: #0079ff;
  }
`;

const Submit = styled(Button)`
  min-width: 150px;
  margin-left: auto;
`;

InvoiceSidebar.propTypes = {
  comments: PropTypes.array,
  commentMessage: PropTypes.string,
  onComment: PropTypes.func,
  handleCommentChange: PropTypes.func,
};
InvoiceSidebar.defaultProps = {
  comments: [],
  commentMessage: "",
};

export default InvoiceSidebar;
