import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useNotifications } from "../../context/notificationsContext";
import { useSockets } from "../../context/socketsContext";
import {
  getQuickbooksAuthUri,
  refreshQuickbooksAuthToken,
  revokeQuickbooksAuthToken,
  getSettings,
  editSettings,
} from "../../utils/api";

import { capitalize } from "../../utils/helpers";

import Button from "../../components/buttons/Button";
import FancyLink from "../../components/links/FancyLink";
import DefaultLink from "../../components/links/DefaultLink";
import Checkmark from "../../components/icons/Checkmark";
import { Field, Label, Input, MoneyInput } from "../../components/Form";

const Settings = () => {
  const { openAlertPopup } = useNotifications();
  const {
    socket,
    startQuickbooksListener,
    stopQuickbooksListener,
  } = useSockets();

  const [settings, setSettings] = useState(null);
  const [newSurveyTag, setNewSurveyTag] = useState("");
  const [newChunkletTitle, setNewChunkletTitle] = useState("");
  const [newDelivTitle, setNewDelivTitle] = useState("");
  const [newDelivItem, setNewDelivItem] = useState("");
  const [newChunkletTag, setNewChunkletTag] = useState("");

  const [invoiceNeedsAuth, setInvoiceNeedsAuth] = useState(false);

  const tabs = [
    { label: "General", value: "general" },
    { label: "Client Brief", value: "client-brief" },
    { label: "Project Brief", value: "project-brief" },
    { label: "Client Review", value: "client-review" },
    // { label: "Chunklet", value: "chunklet" },
    { label: "Survey", value: "survey" },
    { label: "Rates", value: "rates" },
    { label: "Deliverable Types", value: "deliverable-types" },
    { label: "Third Party", value: "third-party" },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const defaultRates = {
    retainer: {
      staff: null,
      director: null,
      partner: null,
    },
    project: {
      staff: null,
      director: null,
      partner: null,
    },
    rush: {
      staff: null,
      director: null,
      partner: null,
    },
  };

  useEffect(() => {
    // get settings
    getSettingsData();

    // check if the quickbooks token is still valid
    checkQuickbooksToken();
  }, []);

  useEffect(() => {
    if (socket) {
      // listen to the socket for when the quickbooks auth callback succeeds, so we can show the user if it was successful
      startQuickbooksListener(() => {
        checkQuickbooksToken();
      });

      return () => {
        stopQuickbooksListener();
      };
    }
  }, [socket]);

  const getSettingsData = async () => {
    try {
      const settingsFromApi = await getSettings();

      if (settingsFromApi) {
        if (!settingsFromApi.rates) {
          settingsFromApi.rates = defaultRates;
        }

        setSettings(settingsFromApi);
      }
    } catch (error) {
      console.error("error getting settings", error);

      openAlertPopup(
        "Something went wrong",
        "We could not retrieve your settings.",
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Survey tags
  |--------------------------------------------------------------------------
  */
  const addSurveyTag = () => {
    const tempSettings = { ...settings };

    if (newSurveyTag && newSurveyTag !== "") {
      tempSettings.weeklyExperienceSurvey.tags.push(newSurveyTag);
    }

    setNewSurveyTag("");
    setSettings(tempSettings);
  };

  const deleteSurveyTag = (tag) => {
    const tempSettings = { ...settings };

    const index = tempSettings.weeklyExperienceSurvey.tags.indexOf(tag);

    if (index > -1) {
      tempSettings.weeklyExperienceSurvey.tags.splice(index, 1);
    }

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Add new chunklet filter
  |--------------------------------------------------------------------------
  */
  const addNewChunkletFilter = () => {
    const tempSettings = { ...settings };

    if (!tempSettings.chunkletFilters) {
      tempSettings.chunkletFilters = [];
    }

    if (newChunkletTitle && newChunkletTitle !== "") {
      tempSettings.chunkletFilters.push({
        name: newChunkletTitle,
        options: [],
      });
    }

    setNewChunkletTitle("");
    setSettings(tempSettings);
  };

  const addNewDeliverableType = () => {
    const tempSettings = { ...settings };

    if (!tempSettings.deliverableCheckListTypes) {
      tempSettings.deliverableCheckListTypes = [];
    }

    if (newDelivTitle && newDelivTitle !== "") {
      tempSettings.deliverableCheckListTypes.push({
        name: newDelivTitle,
        items: [],
      });
    }

    setNewDelivTitle("");
    setSettings(tempSettings);
  };

  const addDelivItem = (index) => {
    const tempSettings = { ...settings };

    if (newDelivItem && newDelivItem !== "") {
      tempSettings.deliverableCheckListTypes[index].items.push(newDelivItem);
    }

    setNewDelivItem("");
    setSettings(tempSettings);
  };

  const editDeliv = (value, index) => {
    const tempSettings = { ...settings };

    tempSettings.deliverableCheckListTypes[index].name = value;

    setSettings(tempSettings);
  };

  const deleteDelivItem = (index, item) => {
    const tempSettings = { ...settings };

    const itemIndex = tempSettings.deliverableCheckListTypes[
      index
    ].items.indexOf(item);

    if (itemIndex > -1) {
      tempSettings.deliverableCheckListTypes[index].items.splice(itemIndex, 1);
    }

    setSettings(tempSettings);
  };

  const editDelivItem = (value, delivIndex, itemIndex) => {
    const tempSettings = { ...settings };
    tempSettings.deliverableCheckListTypes[delivIndex].items[itemIndex] = value;

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Add new chunklet tag options
  |--------------------------------------------------------------------------
  */
  const addChunkletTag = (index) => {
    const tempSettings = { ...settings };

    if (newChunkletTag && newChunkletTag !== "") {
      tempSettings.chunkletFilters[index].options.push(newChunkletTag);
    }

    setNewChunkletTag("");
    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Delete chunklet tag
  |--------------------------------------------------------------------------
  */
  const deleteChunkletTag = (index, tag) => {
    const tempSettings = { ...settings };

    const tagIndex = tempSettings.chunkletFilters[index].options.indexOf(tag);

    if (tagIndex > -1) {
      tempSettings.chunkletFilters[index].options.splice(tagIndex, 1);
    }

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Edit filter names
  |--------------------------------------------------------------------------
  */
  const editFilters = (value, index) => {
    const tempSettings = { ...settings };
    tempSettings.chunkletFilters[index].name = value;

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Edit a question
  |--------------------------------------------------------------------------
  */
  const editQuestions = (value, index) => {
    const tempSettings = { ...settings };

    tempSettings.weeklyExperienceSurvey.questions[index] = value;

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Edit company info
  |--------------------------------------------------------------------------
  */
  // Edit company name
  const editCompanyName = (value) => {
    setSettings({
      ...settings,
      companyName: value,
    });
  };

  // Edit company website
  const editCompanyWebsite = (value) => {
    setSettings({
      ...settings,
      companyWebsite: value,
    });
  };

  const handleRateChange = (rateType, rateTier, value) => {
    const updatedRates = {
      ...settings.rates,
    };
    updatedRates[rateType][rateTier] = value;

    setSettings({
      ...settings,
      rates: updatedRates,
    });
  };

  /*
  |--------------------------------------------------------------------------
  | Client Brief questions
  |--------------------------------------------------------------------------
  */
  const addClientBriefQuestion = () => {
    const tempSettings = { ...settings };

    if (!tempSettings.clientBrief || !tempSettings.clientBrief.length) {
      tempSettings.clientBrief = [];
    }

    tempSettings.clientBrief.push({ created: new Date().getTime() });

    setSettings(tempSettings);
  };

  const deleteClientBriefQuestion = (index) => {
    const tempSettings = { ...settings };

    if (index > -1) {
      tempSettings.clientBrief.splice(index, 1);
    }

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Project Brief questions
  |--------------------------------------------------------------------------
  */
  const addProjectBriefQuestion = () => {
    const tempSettings = { ...settings };

    if (!tempSettings.projectBrief || !tempSettings.projectBrief.length) {
      tempSettings.projectBrief = [];
    }

    tempSettings.projectBrief.push({ created: new Date().getTime() });

    setSettings(tempSettings);
  };

  const deleteProjectBriefQuestion = (index) => {
    const tempSettings = { ...settings };

    if (index > -1) {
      tempSettings.projectBrief.splice(index, 1);
    }

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Client review options
  |--------------------------------------------------------------------------
  */
  const addClientReviewOption = () => {
    const tempSettings = { ...settings };

    if (!tempSettings.clientReview || !tempSettings.clientReview.length) {
      tempSettings.clientReview = [];
    }

    tempSettings.clientReview.push({ created: new Date().getTime() });

    setSettings(tempSettings);
  };

  const deleteClientReviewOption = (index) => {
    const tempSettings = { ...settings };

    if (index > -1) {
      tempSettings.clientReview.splice(index, 1);
    }

    setSettings(tempSettings);
  };

  /*
  |--------------------------------------------------------------------------
  | Save all current settings to the database
  |--------------------------------------------------------------------------
  */
  const saveSettings = async () => {
    try {
      const response = await editSettings(settings);

      if (response.status === 200) {
        openAlertPopup("Success", "Settings successfully saved.", true);
      } else {
        openAlertPopup(
          "Something went wrong",
          "We could not save your settings.",
        );
      }
    } catch (error) {
      openAlertPopup(
        "Something went wrong",
        "We could not save your settings.",
      );
    }
  };

  const openQuickbooksAuthWindow = async () => {
    try {
      const authUri = await getQuickbooksAuthUri();

      const width = 500;
      const height = 825;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      // open a new window for the quickbooks auth
      window.open(
        authUri,
        "Intuit Auth",
        `menubar=1,resizable=1,width=${width},height=${height},left=${left},top=${top}`,
      );
    } catch (error) {
      openAlertPopup(
        "Something went wrong",
        "We couldn't open the QuickBooks authentication window. Please refresh and try again.",
      );
    }
  };

  const checkQuickbooksToken = async () => {
    try {
      // try refreshing the token
      await refreshQuickbooksAuthToken();

      // token is good to go
      setInvoiceNeedsAuth(false);
    } catch (error) {
      // if the token couldn't be refreshed, we need to re-authenticate
      setInvoiceNeedsAuth(true);
    }
  };

  const disconnectQuickbooks = async () => {
    try {
      const confirmationResult = window.confirm(
        "Are you sure you want to disconnect from QuickBooks?\n\nThe invoicing integration requires that Sherpa be connected to QuickBooks. You will have the opportunity to reconnect afterwards.",
      );

      if (confirmationResult) {
        // revoke the token
        await revokeQuickbooksAuthToken();

        // check for its existance
        checkQuickbooksToken();
      }
    } catch (error) {
      openAlertPopup(
        "Something went wrong",
        "We couldn't sign out of QuickBooks. Please refresh and try again.",
      );
    }
  };

  return (
    <Container>
      <Header>
        <Title>Company Settings</Title>
      </Header>

      <Tabs>
        {tabs.map((tab) => (
          <Tab
            onClick={() => {
              setCurrentTab(tab.value);
            }}
            isActive={currentTab === tab.value}
            key={tab.value}
          >
            {tab.label}
          </Tab>
        ))}
      </Tabs>

      {settings ? (
        <Body>
          <BodyInner>
            {currentTab === "general" ? (
              <>
                <SettingItem>
                  <Label>Company Name</Label>
                  <Input
                    type="text"
                    defaultValue={settings.companyName}
                    onChange={(e) => {
                      editCompanyName(e.target.value);
                    }}
                  />
                </SettingItem>
                <SettingItem>
                  <Label>Website</Label>
                  <Input
                    type="text"
                    defaultValue={settings.companyWebsite}
                    onChange={(e) => {
                      editCompanyWebsite(e.target.value);
                    }}
                  />
                </SettingItem>

                <Button onClick={() => saveSettings()}>Save</Button>
              </>
            ) : currentTab === "client-brief" ? (
              <>
                {settings &&
                  settings.clientBrief.map((item, index) => {
                    return (
                      <Question key={item._id || item.created}>
                        <Input
                          type="text"
                          defaultValue={item.question}
                          style={{ width: "600px" }}
                          onChange={(e) => {
                            const tempSettings = { ...settings };
                            tempSettings.clientBrief[index].question =
                              e.target.value;
                            setSettings(tempSettings);
                          }}
                        />

                        <Delete
                          onClick={() => deleteClientBriefQuestion(index)}
                        />
                      </Question>
                    );
                  })}

                <div style={{ marginTop: "40px" }}>
                  <Button
                    onClick={() => addClientBriefQuestion()}
                    style={{ marginRight: "20px" }}
                  >
                    Add Question
                  </Button>
                  <Button onClick={() => saveSettings()}>Save Questions</Button>
                </div>
              </>
            ) : currentTab === "project-brief" ? (
              <>
                {settings &&
                  settings.projectBrief.map((item, index) => {
                    return (
                      <Question key={item._id || item.created}>
                        <Input
                          type="text"
                          defaultValue={item.question}
                          style={{ width: "600px" }}
                          onChange={(e) => {
                            const tempSettings = { ...settings };
                            tempSettings.projectBrief[index].question =
                              e.target.value;
                            setSettings(tempSettings);
                          }}
                        />

                        <Delete
                          onClick={() => deleteProjectBriefQuestion(index)}
                        />
                      </Question>
                    );
                  })}

                <div style={{ marginTop: "40px" }}>
                  <Button
                    onClick={() => addProjectBriefQuestion()}
                    style={{ marginRight: "20px" }}
                  >
                    Add Question
                  </Button>
                  <Button onClick={() => saveSettings()}>Save Questions</Button>
                </div>
              </>
            ) : currentTab === "client-review" ? (
              <>
                {settings &&
                  settings.clientReview.map((item, index) => {
                    return (
                      <Question key={item._id || item.created}>
                        <Input
                          type="text"
                          defaultValue={item.option}
                          style={{ width: "600px" }}
                          onChange={(e) => {
                            const tempSettings = { ...settings };
                            tempSettings.clientReview[index].option =
                              e.target.value;
                            setSettings(tempSettings);
                          }}
                        />

                        <Delete
                          onClick={() => deleteClientReviewOption(index)}
                        />
                      </Question>
                    );
                  })}

                <div style={{ marginTop: "40px" }}>
                  <Button
                    onClick={() => addClientReviewOption()}
                    style={{ marginRight: "20px" }}
                  >
                    Add Option
                  </Button>
                  <Button onClick={() => saveSettings()}>Save Options</Button>
                </div>
              </>
            ) : currentTab === "chunklet" ? (
              <Setting>
                {/* // ? these chunklet settings might get used in future for blueprints  */}

                <SettingTitle>Chunklet</SettingTitle>

                {settings &&
                  settings.chunkletFilters &&
                  settings.chunkletFilters.map((filter, index) => {
                    return (
                      <React.Fragment key={index}>
                        <SettingItem key={index}>
                          <Label>Filter #{index + 1}</Label>
                          <Input
                            type="text"
                            defaultValue={filter.name}
                            onChange={(e) => {
                              editFilters(e.target.value, index);
                            }}
                          />
                        </SettingItem>

                        <SettingItem>
                          <Label>{filter.name} Tags</Label>
                          <Tags>
                            {settings &&
                              settings.chunkletFilters[index].options &&
                              settings.chunkletFilters[index].options.map(
                                (tag, tagIndex) => {
                                  return (
                                    <Tag key={tagIndex}>
                                      <TagIcon
                                        onClick={() => {
                                          deleteChunkletTag(index, tag);
                                        }}
                                      >
                                        &times;
                                      </TagIcon>
                                      {tag}
                                    </Tag>
                                  );
                                },
                              )}
                            <Tag>
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  addChunkletTag(index);
                                }}
                              >
                                <TagIcon
                                  type="submit"
                                  onClick={() => {
                                    addChunkletTag(index);
                                  }}
                                >
                                  +
                                </TagIcon>
                                <TagInput
                                  type="text"
                                  placeholder="Add Tag"
                                  onChange={(e) => {
                                    setNewChunkletTag(e.target.value);
                                  }}
                                />
                              </Form>
                            </Tag>
                          </Tags>
                        </SettingItem>
                      </React.Fragment>
                    );
                  })}

                <Input
                  type="text"
                  placeholder="Chunklet filter name"
                  value={newChunkletTitle}
                  onChange={(e) => {
                    setNewChunkletTitle(e.target.value);
                  }}
                />

                <Links>
                  <DefaultLink onClick={() => addNewChunkletFilter()}>
                    Add new chunklet filter
                  </DefaultLink>
                  <FancyLink
                    onClick={() => {
                      saveSettings();
                    }}
                  >
                    Save
                  </FancyLink>
                </Links>
              </Setting>
            ) : currentTab === "survey" ? (
              <Setting>
                <SettingTitle>Weekly Team Experience Survey</SettingTitle>

                {settings &&
                  settings.weeklyExperienceSurvey &&
                  settings.weeklyExperienceSurvey.questions &&
                  settings.weeklyExperienceSurvey.questions.map(
                    (question, index) => {
                      return (
                        <SettingItem key={index}>
                          <Label>Question #{index + 1}</Label>
                          <Input
                            type="text"
                            defaultValue={question}
                            onChange={(e) => {
                              editQuestions(e.target.value, index);
                            }}
                          />
                        </SettingItem>
                      );
                    },
                  )}

                <SettingItem>
                  <Label>Tags</Label>
                  <Tags>
                    {settings &&
                      settings.weeklyExperienceSurvey &&
                      settings.weeklyExperienceSurvey.tags &&
                      settings.weeklyExperienceSurvey.tags.map((tag, index) => {
                        return (
                          <Tag key={index}>
                            <TagIcon
                              onClick={() => {
                                deleteSurveyTag(tag);
                              }}
                            >
                              &times;
                            </TagIcon>
                            {tag}
                          </Tag>
                        );
                      })}
                    <Tag>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          addSurveyTag();
                        }}
                      >
                        <TagIcon
                          type="submit"
                          onClick={() => {
                            addSurveyTag();
                          }}
                        >
                          +
                        </TagIcon>
                        <TagInput
                          type="text"
                          placeholder="Add Tag"
                          value={newSurveyTag}
                          onChange={(e) => {
                            setNewSurveyTag(e.target.value);
                          }}
                        />
                      </Form>
                    </Tag>
                  </Tags>
                </SettingItem>

                <FancyLink
                  onClick={() => {
                    saveSettings();
                  }}
                >
                  Save
                </FancyLink>
              </Setting>
            ) : currentTab === "rates" ? (
              <>
                <SettingItem>
                  <Rates>
                    {/* rateType = retainer / project / rush */}
                    {/* rateTier = staff / director / partner */}
                    {Object.entries(settings.rates).map(
                      ([rateType, rateTiers]) => (
                        <RateColumn key={rateType}>
                          {Object.entries(rateTiers).map(
                            ([rateTier, rateAmount]) => (
                              <Field key={`${rateType}-${rateTier}`}>
                                <Label htmlFor={`rate-${rateType}-${rateTier}`}>
                                  {capitalize(rateType)} Rate -{" "}
                                  {capitalize(rateTier)}
                                </Label>
                                <MoneyInput
                                  id={`rate-${rateType}-${rateTier}`}
                                  value={rateAmount}
                                  onChange={(e) => {
                                    handleRateChange(
                                      rateType,
                                      rateTier,
                                      e.target.rawValue.replace("$", ""),
                                    );
                                  }}
                                />
                              </Field>
                            ),
                          )}
                        </RateColumn>
                      ),
                    )}
                  </Rates>
                </SettingItem>

                <Button onClick={() => saveSettings()}>Save</Button>
              </>
            ) : currentTab === "deliverable-types" ? (
              <Setting>
                <SettingTitle>Deliverable Types</SettingTitle>

                {settings &&
                  settings.deliverableCheckListTypes &&
                  settings.deliverableCheckListTypes.map(
                    (checklist, checklistIndex) => {
                      return (
                        <React.Fragment key={checklistIndex}>
                          <SettingItem key={checklistIndex}>
                            <Label>Name</Label>
                            <Input
                              type="text"
                              defaultValue={checklist.name}
                              onChange={(e) => {
                                editDeliv(e.target.value, checklistIndex);
                              }}
                            />
                          </SettingItem>

                          <SettingItem>
                            <Label>{checklist.name} Checklist Items</Label>
                            <DelivItems>
                              {checklist.items &&
                                checklist.items.map((item, itemIndex) => {
                                  return (
                                    <DelivItem>
                                      <TagIcon
                                        onClick={() => {
                                          deleteDelivItem(checklistIndex, item);
                                        }}
                                      >
                                        &times;
                                      </TagIcon>
                                      <Input
                                        type="text"
                                        value={item}
                                        onChange={(e) => {
                                          editDelivItem(
                                            e.target.value,
                                            checklistIndex,
                                            itemIndex,
                                          );
                                        }}
                                      />
                                    </DelivItem>
                                  );
                                })}
                              <DelivItem>
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    addDelivItem(checklistIndex);
                                  }}
                                >
                                  <TagIcon
                                    type="submit"
                                    onClick={() => {
                                      addDelivItem(checklistIndex);
                                    }}
                                  >
                                    +
                                  </TagIcon>
                                  <Input
                                    type="text"
                                    placeholder="Add Checklist Item"
                                    value={newDelivItem}
                                    onChange={(e) => {
                                      setNewDelivItem(e.target.value);
                                    }}
                                  />
                                </Form>
                              </DelivItem>
                            </DelivItems>
                          </SettingItem>
                        </React.Fragment>
                      );
                    },
                  )}

                <Input
                  type="text"
                  placeholder="Deliverable Type Name"
                  value={newDelivTitle}
                  onChange={(e) => {
                    setNewDelivTitle(e.target.value);
                  }}
                />

                <Links>
                  <DefaultLink onClick={() => addNewDeliverableType()}>
                    Add new deliverable type
                  </DefaultLink>
                  <FancyLink
                    onClick={() => {
                      saveSettings();
                    }}
                  >
                    Save
                  </FancyLink>
                </Links>
              </Setting>
            ) : currentTab === "third-party" ? (
              <Setting>
                <SettingTitle>Third Party Integrations</SettingTitle>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <strong style={{ marginRight: "20px" }}>QuickBooks</strong>

                  {invoiceNeedsAuth ? (
                    <Button onClick={() => openQuickbooksAuthWindow()} small>
                      Authenticate
                    </Button>
                  ) : (
                    <Checkmark width={20} height={20} />
                  )}
                </div>
                <AuthMessage>
                  <small>
                    {invoiceNeedsAuth ? (
                      "We need to authenticate with QuickBooks in order to handle invoicing."
                    ) : (
                      <>
                        <DefaultLink onClick={disconnectQuickbooks} danger>
                          Disconnect
                        </DefaultLink>
                      </>
                    )}
                  </small>
                </AuthMessage>
              </Setting>
            ) : null}
          </BodyInner>
        </Body>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 50px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin: 0px;
  font-weight: 500;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

const Tab = styled.button`
  padding: 0px 10px 5px;
  border-bottom: 6px solid;
  border-color: ${(props) =>
    props.isActive ? props.theme.colors.blue : "transparent"};
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BodyInner = styled.div`
  width: 50%;
`;

const Setting = styled.div`
  background-color: #f3f9fd;
  padding: 50px 30px;
  margin-bottom: 30px;
`;

const SettingTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 50px;
`;

const SettingItem = styled.div`
  margin-bottom: 60px;
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  display: flex;
  background-color: white;
  color: #858995;
  border: 2px solid #e1e7f7;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const TagIcon = styled.button`
  margin-right: 10px;
  color: #c6cad0;
  font-size: 30px;
  line-height: 0;
  transition: 0.3s ease-in-out;
  padding: 0px;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #666b7b;
  }
`;

const Form = styled.form`
  display: flex;
  align-items: center;
`;

const TagInput = styled.input`
  outline: none;
  background-color: transparent;
  padding: 0px;
  border: none;
  width: 80px;
`;

const Links = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const AuthMessage = styled.div`
  line-height: 1;
  margin-top: 10px;
`;

const Rates = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const RateColumn = styled.div`
  width: 30%;

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Question = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Delete = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc2626;
  position: relative;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 3px;
    background-color: white;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background-color: #ffe4e6 !important;

    &::before {
      background-color: #dc2626;
    }
  }
`;

const DelivItems = styled.div``;

const DelivItem = styled.div``;

export default Settings;
