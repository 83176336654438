import React from "react";
import styled from "styled-components";

import { useNotifications } from "../../../../context/notificationsContext";
import { editProjectOnApi } from "../../../manage/utils/api";

import BriefForm from "../BriefForm";

const ProjectBrief = ({
  project,
  setProject,
  isImportant,
  projectBriefQuestions = { projectBriefQuestions },
}) => {
  const { openAlertPopup } = useNotifications();

  const updateProjectBrief = async (values) => {
    try {
      const data = {
        projectId: project._id,
        database: {},
      };

      data.database = {
        brief: values,
      };

      let hasChanged = false;

      if (project.brief) {
        Object.entries(project.brief).forEach((name) => {
          if (values[name] !== project.brief[name]) {
            hasChanged = true;
          }
        });
      }

      if (hasChanged) {
        const tempProject = { ...project };
        tempProject.stages.brief.approvedBy = [];
        tempProject.stages.brief.completed = false;
        setProject(tempProject);

        data.database = {
          brief: values,
          "stages.brief.approvedBy": [],
          "stages.brief.completed": false,
        };
      }

      await editProjectOnApi(data);

      openAlertPopup("Success", "Brief updated successfully!", true);
    } catch (err) {
      openAlertPopup("Failure", "Brief update failed!", true);
    }
  };

  return (
    <BriefContainer>
      <BriefForm
        brief={project.brief}
        onSave={isImportant ? updateProjectBrief : undefined}
        disabled={!isImportant}
        projectBriefQuestions={projectBriefQuestions}
        type="project"
      />
    </BriefContainer>
  );
};

const BriefContainer = styled.div`
  margin-top: 40px;

  input,
  label,
  textarea {
    width: 400px;
  }
`;

export default ProjectBrief;
