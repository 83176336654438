import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getChunkletsFromApi, getSettings } from "../../utils/api";

import { TableHeader, TableRow, TableCell } from "../../components/Table";
import { Label, Field } from "../../components/Form";
import SelectDropdown from "../../components/SelectDropdown";
import SimpleLink from "../../components/links/SimpleLink";
import CreateLink from "../../components/links/CreateLink";

const Chunklets = () => {
  const [sortBy, setSortBy] = useState("name-asc");
  const [chunklets, setChunklets] = useState([]);
  const [chunkletFilters, setChunkletFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  /*
  |--------------------------------------------------------------------------
  | Get all chunklets
  |--------------------------------------------------------------------------
  */
  const getChunklets = async () => {
    let results = await getChunkletsFromApi();

    if (results) {
      results = results.slice(0).reverse();
      setChunklets(results);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Handles the actual sorting of the chunklets
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    const sortedChunklets = [...chunklets];

    if (sortBy === "name-asc") {
      sortedChunklets.sort(function(a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (sortBy === "name-desc") {
      sortedChunklets.sort(function(a, b) {
        var textA = a.title.toUpperCase();
        var textB = b.title.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }

    setChunklets(sortedChunklets);
  }, [sortBy]); // eslint-disable-line

  const changeSort = (sortType) => {
    if (sortBy === `${sortType}-asc`) {
      setSortBy(`${sortType}-desc`);
    } else {
      setSortBy(`${sortType}-asc`);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get saved settings data to populate the filter dropdowns
  |--------------------------------------------------------------------------
  */
  const getSettingsData = async () => {
    try {
      const response = await getSettings();

      if (response?.chunkletFilters) {
        const tempFilters = response.chunkletFilters.map((filter) => {
          if (filter.options.length) {
            filter.options = filter.options.map((option) => {
              return {
                label: option,
                value: option,
              };
            });
          }
          return filter;
        });

        const tempSelectedFilters = response.chunkletFilters.map((filter) => {
          return {
            name: filter.name,
            options: [],
          };
        });

        setChunkletFilters(tempFilters);
        setSelectedFilters(tempSelectedFilters);
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update filters
  |--------------------------------------------------------------------------
  */
  const updateFilters = (data, index) => {
    const tempFilters = [...selectedFilters];

    if (data) {
      const values = data.map((item) => {
        return item.value;
      });
      tempFilters[index].options = values;
    } else {
      tempFilters[index].options = [];
    }

    setSelectedFilters(tempFilters);
  };

  /*
  |--------------------------------------------------------------------------
  | Get chunklets and filters
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getChunklets();
    getSettingsData();
  }, []);

  return (
    <Container>
      <Header>
        <h1>Chunklets</h1>
        <CreateLink as={Link} to={`/projects/chunklets/new`}>
          Create Chunklet
        </CreateLink>
      </Header>

      <Filters>
        {chunkletFilters
          ? chunkletFilters.map((filter, index) => {
              if (filter.options) {
                return (
                  <Field key={index}>
                    <Label>{filter.name}</Label>
                    <Filter
                      isMulti
                      onChange={(e) => {
                        updateFilters(e, index);
                      }}
                      placeholder={`Select ${filter.name} Tags`}
                      options={filter.options}
                    />
                  </Field>
                );
              } else {
                return null;
              }
            })
          : null}
      </Filters>

      {chunklets && chunklets.length ? (
        <ChunkletsList id="chunkletsTable">
          <thead>
            <tr>
              <TableHeader
                onClick={() => changeSort("name")}
                isArrowUp={sortBy === "name-desc"}
                align="left"
                style={{ paddingLeft: "0px" }}
              >
                Name
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {chunklets.map((chunklet, index) => {
              // Filter the chunklets based on the selected filters
              if (selectedFilters) {
                const tempFilters = [...selectedFilters];
                let optionsCount = 0;
                let filtersCount = 0;

                // Check each selected filter
                tempFilters.forEach((item) => {
                  // If there's at least 1 item selected in the filter
                  if (item.options.length) {
                    optionsCount++;

                    // Find the chunklet's filter where the selected filter's name matches
                    const foundFilter = chunklet.filters.find(
                      (filter) => item.name === filter.name,
                    );

                    // Loop through the options of the filter to see if the found filters includes all of the selected tags
                    if (foundFilter) {
                      const showFilter = item.options.every((r) =>
                        foundFilter.options.includes(r),
                      );

                      if (showFilter) {
                        filtersCount++;
                      }
                    }
                  } else {
                    return;
                  }
                });

                // Only show the chunklet if its filters matches the filters that are selected
                if (optionsCount === filtersCount) {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        <SimpleLink
                          as={Link}
                          to={`/projects/chunklets/${chunklet._id}`}
                        >
                          {chunklet.title}
                        </SimpleLink>
                      </TableCell>
                    </TableRow>
                  );
                } else return null;
              } else {
                return (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <SimpleLink
                        as={Link}
                        to={`/projects/chunklets/${chunklet._id}`}
                      >
                        {chunklet.title}
                      </SimpleLink>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </tbody>
        </ChunkletsList>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 60px 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;

  h1 {
    font-size: 32px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.oldBlack};
    margin-bottom: 0;
    margin-right: 170px;
  }
`;

const ChunkletsList = styled.table``;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 660px;
  margin-bottom: 50px;
`;

const Filter = styled(SelectDropdown)`
  width: 300px;
  font-size: 14px;
`;

export default Chunklets;
