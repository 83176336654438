import React from "react";
import styled, { css } from "styled-components";
import ElipsesIcon from "./icons/ElipsesIcon";

const Pagination = ({ totalEntries, currentPage, setCurrentPage, perPage }) => {
  const totalPages = Math.ceil(totalEntries / perPage);

  return totalEntries > 0 ? (
    <Container>
      {totalEntries > perPage ? (
        <>
          <Previous
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}
          />
          <Pages>
            {[...Array(totalPages)].map((page, index) => {
              if (
                index === 0 ||
                index === totalPages - 1 ||
                (index < 7 && currentPage < 5) ||
                index === currentPage ||
                (index >= currentPage - 3 && index < currentPage) ||
                (index <= currentPage + 3 && index > currentPage)
              ) {
                return (
                  <Page
                    key={index}
                    active={currentPage === index}
                    onClick={() => setCurrentPage(index)}
                  >
                    {index + 1}
                  </Page>
                );
              } else if (
                index === currentPage - 4 ||
                index === totalPages - 2
              ) {
                return (
                  <ElipsesContainer key={index}>
                    <ElipsesIcon />
                  </ElipsesContainer>
                );
              }
            })}
          </Pages>
          <Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          />
        </>
      ) : null}
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Pages = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0px 8px;
`;

const Page = styled.div`
  color: ${(props) => props.theme.colors.darkerGray};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-weight: 600;
  font-size: 14px;
  width: 24px;
  height: 24px;
  margin: 0px 8px;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo};
    color: white;
  }

  ${(props) =>
    props.active
      ? css`
          background-color: ${(props) => props.theme.colors.indigo};
          color: white;
        `
      : ""};
`;

const Previous = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  transition: 0.2s ease-in-out;
  opacity: ${(props) => (props.disabled ? ".5" : 1)};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo};

    &::after {
      border-color: ${(props) => props.theme.colors.oldWhite};
    }
  }

  &::after {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-bottom: 2px solid ${(props) => props.theme.colors.coolerGray};
    border-left: 2px solid ${(props) => props.theme.colors.coolerGray};
    transform-origin: center;
    transform: rotate(45deg);
    margin-left: 2px;
  }
`;

const Next = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  width: 36px;
  height: 36px;
  position: relative;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  transition: 0.2s ease-in-out;
  opacity: ${(props) => (props.disabled ? ".5" : 1)};

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.colors.indigo};

    &::after {
      border-color: ${(props) => props.theme.colors.oldWhite};
    }
  }

  &::after {
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    border-top: 2px solid ${(props) => props.theme.colors.coolerGray};
    border-right: 2px solid ${(props) => props.theme.colors.coolerGray};
    transform-origin: center;
    transform: rotate(45deg);
    margin-left: -2px;
  }
`;

const ElipsesContainer = styled.div`
  margin: 0px 8px -1px 8px;
`;

export default Pagination;
