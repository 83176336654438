import React from "react";
import styled from "styled-components";

const HappyFace = () => {
  return (
    <Svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1H11.5C13.7091 1 15.5 2.79086 15.5 5V11.5C15.5 13.7091 13.7091 15.5 11.5 15.5H5C2.79086 15.5 1 13.7091 1 11.5V5C1 2.79086 2.79086 1 5 1Z"
        stroke="#001940"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9C4 9 5.25 11.5 8.25 11.5C11.25 11.5 12.5 9 12.5 9"
        stroke="#001940"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 7.25C10.8023 7.25 11.25 6.80228 11.25 6.25C11.25 5.69772 10.8023 5.25 10.25 5.25C9.69772 5.25 9.25 5.69772 9.25 6.25C9.25 6.80228 9.69772 7.25 10.25 7.25Z"
        fill="#001940"
      />
      <path
        d="M6.25 7.25C6.80228 7.25 7.25 6.80228 7.25 6.25C7.25 5.69772 6.80228 5.25 6.25 5.25C5.69772 5.25 5.25 5.69772 5.25 6.25C5.25 6.80228 5.69772 7.25 6.25 7.25Z"
        fill="#001940"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default HappyFace;
