import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const FancyLink = ({ color, children, ...rest }) => {
  return (
    <Link color={color} {...rest}>
      {children}
    </Link>
  );
};

const Link = styled.button`
  position: relative;
  display: block;
  margin: 0px;
  margin-left: auto;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.blue};
  font-weight: bold;
  padding: 0px;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 120%;
    height: 2px;
    opacity: 0;
    background-color: ${(props) =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.blue};
    transition: transform 0.4s, opacity 0.4s;
  }

  &::before {
    top: -4px;
    transform: translate(-10%, 5px);
  }

  &::after {
    bottom: -4px;
    transform: translate(-10%, -5px);
  }

  &:hover,
  &:focus {
    &::before,
    &::after {
      transform: translate(-10%, 0);
      opacity: 1;
    }
  }
`;

FancyLink.propTypes = {
  color: PropTypes.string,
};
FancyLink.defaultProps = {
  color: "blue",
};

export default FancyLink;
