import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { format, subMonths, intervalToDuration } from "date-fns";
import Tooltip from "../../../components/Tooltip";

import {
  createInvoiceOnApi,
  getUninvoicedFromApi,
  getCompaniesFromApi,
} from "../../../utils/api";
import {
  getStandardizedDate,
  isValidDate,
  getCurrency,
} from "../../../utils/helpers";

import { useNotifications } from "../../../context/notificationsContext";

import SelectDropdown from "../../../components/SelectDropdown";
import { Field, Input, FieldGroup } from "../../../components/Form";
import CheckCircle from "../../../components/CheckCircle";
import Button from "../../../components/buttons/Button";

const InvoiceCreate = () => {
  const history = useHistory();
  const locationObj = useLocation();

  const { openAlertPopup } = useNotifications();

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateVisual, setStartDateVisual] = useState(null);
  const [endDateVisual, setEndDateVisual] = useState(null);

  const [projects, setProjects] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    //  get all the clients
    getClients();
  }, []);

  // when an end date is selected
  useEffect(() => {
    // if start and end dates are legit
    if (
      selectedClient &&
      endDate &&
      isValidDate(new Date(endDate)) &&
      startDate &&
      isValidDate(new Date(startDate))
    ) {
      // reset the projects
      setProjects(null);
      setSelectedProjects([]);

      // get the uninvoiced projects for that time range
      getUninvoiced();
    }
  }, [selectedClient, endDate, startDate]);

  // when clients are fetched
  useEffect(() => {
    // check if theres a pre-selected client in the URL
    if (locationObj.search) {
      const urlParams = new URLSearchParams(locationObj.search);

      const preSelectedClient = urlParams.get("client");

      if (preSelectedClient) {
        selectClient(preSelectedClient);
      }
    }
  }, [clients]);

  useEffect(() => {
    // if the end date was set
    if (endDate) {
      // if start date hasn't been set yet, or if the timeframe is invalid
      if (!startDate || !isValidTimeframe()) {
        // set the start date to be 12 months before the end date
        // ? they can't be more than 1 year apart
        setStartDate(subMonths(new Date(endDate), 12));
      }
    }
  }, [endDate, startDate]);

  const getClients = async () => {
    try {
      const apiClients = await getCompaniesFromApi();

      const clientsWithAccounting = apiClients.filter(
        (client) => client.accountingId,
      );

      setClients(clientsWithAccounting);

      if (!clientsWithAccounting.length) {
        throw "None of your clients have their Accounting ID set.";
      }
    } catch (error) {
      console.error("getClients failed:", error);

      const errMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to find any clients. ${errMessage}`,
      );
    }
  };

  const getUninvoiced = async () => {
    try {
      // check the timeframe of the start and end date
      if (!isValidTimeframe()) {
        throw "Timeframe may not be greater than 1 year";
      }

      // format them for the API request
      const startDateFormatted = format(new Date(startDate), "yyyyMMdd");
      const endDateFormatted = format(new Date(endDate), "yyyyMMdd");

      // get the uninvoiced projects from harvest
      const unInvoiced = await getUninvoicedFromApi({
        from: startDateFormatted,
        to: endDateFormatted,
      });

      // get the uninvoiced projects for the selected client whose uninvoiced amount is greater than 0
      const clientUninvoiced = unInvoiced.filter(
        (project) =>
          project.client_id === selectedClient.harvestId &&
          project.uninvoiced_amount > 0,
      );

      const clientUninvoicedIds = clientUninvoiced.map(
        (project) => project.project_id,
      );

      setProjects(clientUninvoiced);

      // all projects selected by default
      setSelectedProjects(clientUninvoicedIds);
    } catch (error) {
      console.error("getUninvoiced failed", error);
    }
  };

  const isValidTimeframe = (start = startDate, end = endDate) => {
    const duration = intervalToDuration({
      start: new Date(start),
      end: new Date(end),
    });

    // invalid timeframe if its more than 1 year
    const isInvalid =
      duration.years > 1 ||
      (duration.years === 1 && (duration.months || duration.days));

    return !isInvalid;
  };

  const createInvoice = async () => {
    try {
      // check the timeframe of the start and end date
      if (!isValidTimeframe()) {
        throw "Timeframe may not be greater than 1 year";
      }

      // format the dates how harvest expects it for an invoice
      const billFromDate = format(new Date(startDate), "yyyy-MM-dd");
      const billThroughDate = format(new Date(endDate), "yyyy-MM-dd");

      // presentational dates, if provided
      const visualFromDate = startDateVisual
        ? format(new Date(startDateVisual), "MM/dd/yyyy")
        : undefined;
      const visualThroughDate = endDateVisual
        ? format(new Date(endDateVisual), "MM/dd/yyyy")
        : undefined;

      const newInvoice = await createInvoiceOnApi({
        billFromDate,
        billThroughDate,
        visualFromDate,
        visualThroughDate,
        clientHarvestId: selectedClient.harvestId,
        projectHarvestIds: selectedProjects,
      });

      openAlertPopup("Success", "Draft invoice created!", true);

      // go to the invoice
      history.push(`/manage/invoices/${newInvoice._id}`);
    } catch (error) {
      openAlertPopup(
        "Failure",
        `Sorry, we weren't able to create that invoice. ${error}`,
      );
    }
  };

  const selectClient = (clientId) => {
    const clientObject = clientId
      ? clients.find((client) => client._id === clientId)
      : null;

    setSelectedClient(clientObject);
  };

  const toggleProject = (projectId) => {
    // check if this project is already selected
    const isSelected =
      selectedProjects.findIndex(
        (selectedProjectId) => selectedProjectId === projectId,
      ) > -1;

    const updatedSelectedProjects = isSelected
      ? // if selected, filter it out
        selectedProjects.filter(
          (selectedProjectId) => selectedProjectId !== projectId,
        )
      : // if not selected, add it
        [...selectedProjects, projectId];

    setSelectedProjects(updatedSelectedProjects);
  };

  // determine the total of the invoice based on the selected projects
  let total = 0;
  if (projects) {
    // loop over the projects
    projects.forEach((project) => {
      // check if user has selected it
      const isSelected = selectedProjects.find(
        (projectId) => projectId === project.project_id,
      );

      // if so, add its amount to the total
      if (isSelected) {
        total += project.uninvoiced_amount;
      }
    });
  }

  return (
    <Container>
      <OptionsCol>
        {clients.length ? (
          <>
            <Field>
              <Heading>{selectedClient ? "" : "Select"} Client</Heading>

              <div style={{ display: "flex", alignItems: "center" }}>
                <Dropdown
                  onChange={(e) => selectClient(e.value)}
                  placeholder="Client"
                  options={clients.map((client) => ({
                    label: client.name,
                    value: client._id,
                  }))}
                  value={
                    selectedClient
                      ? {
                          label: selectedClient.name,
                          value: selectedClient._id,
                        }
                      : null
                  }
                />

                <Question data-tip data-for="invoice-client-info" />

                <Tooltip id="invoice-client-info" place="right">
                  Can't find a client? Make sure we have their QuickBooks ID.
                </Tooltip>
              </div>
            </Field>

            {selectedClient ? (
              <>
                <Heading style={{ display: "flex", alignItems: "center" }}>
                  Bill Through
                  <Question
                    data-tip
                    data-for="invoice-date-info"
                    style={{ visibility: endDate ? "visible" : "hidden" }}
                  />
                </Heading>

                <Tooltip id="invoice-date-info" place="right">
                  Timeframe may not be greater than 1 year
                </Tooltip>

                <FieldGroup>
                  {endDate ? (
                    <>
                      <div>
                        <Input
                          type="date"
                          value={
                            startDate && isValidDate(new Date(startDate))
                              ? format(
                                  getStandardizedDate(startDate),
                                  "yyyy-MM-dd",
                                )
                              : undefined
                          }
                          onChange={(e) =>
                            setStartDate(
                              isValidDate(new Date(e.target.value))
                                ? getStandardizedDate(e.target.value)
                                : null,
                            )
                          }
                        />
                      </div>
                      <div style={{ alignSelf: "center", margin: "0 10px" }}>
                        &rarr;
                      </div>
                    </>
                  ) : null}

                  <div>
                    <Input
                      type="date"
                      value={
                        endDate && isValidDate(new Date(endDate))
                          ? format(getStandardizedDate(endDate), "yyyy-MM-dd")
                          : undefined
                      }
                      onChange={(e) =>
                        setEndDate(
                          isValidDate(new Date(e.target.value))
                            ? getStandardizedDate(e.target.value)
                            : null,
                        )
                      }
                    />
                  </div>
                </FieldGroup>

                {endDate && startDate ? (
                  <>
                    <Separator />

                    <Heading style={{ display: "flex", alignItems: "center" }}>
                      Presentational Dates
                      <Question data-tip data-for="invoice-line-info" />
                    </Heading>

                    <Tooltip id="invoice-line-info" place="right">
                      Dates to display on the invoice's line items. For
                      presentation purposes only. Optional.
                    </Tooltip>

                    <FieldGroup>
                      <div>
                        <Input
                          type="date"
                          value={
                            startDateVisual &&
                            isValidDate(new Date(startDateVisual))
                              ? format(
                                  getStandardizedDate(startDateVisual),
                                  "yyyy-MM-dd",
                                )
                              : undefined
                          }
                          onChange={(e) =>
                            setStartDateVisual(
                              isValidDate(new Date(e.target.value))
                                ? getStandardizedDate(e.target.value)
                                : null,
                            )
                          }
                        />
                      </div>
                      <div style={{ alignSelf: "center", margin: "0 10px" }}>
                        &rarr;
                      </div>

                      <div>
                        <Input
                          type="date"
                          value={
                            endDateVisual &&
                            isValidDate(new Date(endDateVisual))
                              ? format(
                                  getStandardizedDate(endDateVisual),
                                  "yyyy-MM-dd",
                                )
                              : undefined
                          }
                          onChange={(e) =>
                            setEndDateVisual(
                              isValidDate(new Date(e.target.value))
                                ? getStandardizedDate(e.target.value)
                                : null,
                            )
                          }
                        />
                      </div>
                    </FieldGroup>

                    <div>Example Line Item:</div>
                    <div style={{ fontSize: "14px" }}>
                      [ABC123] Project Name: Billable Role (
                      {startDateVisual
                        ? format(new Date(startDateVisual), "MM/dd/yyyy")
                        : format(new Date(startDate), "MM/dd/yyyy")}{" "}
                      -{" "}
                      {endDateVisual
                        ? format(new Date(endDateVisual), "MM/dd/yyyy")
                        : format(new Date(endDate), "MM/dd/yyyy")}
                      )
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
      </OptionsCol>

      <InvoiceCol>
        {endDate &&
        isValidDate(new Date(endDate)) &&
        startDate &&
        isValidDate(new Date(startDate)) ? (
          <>
            <Heading>Select Projects</Heading>

            {projects ? (
              <>
                <Field>
                  <SmallText>
                    Projects with uninvoiced billable hours from{" "}
                    {format(new Date(startDate), "MM/dd/yyyy")} to{" "}
                    {format(new Date(endDate), "MM/dd/yyyy")}{" "}
                  </SmallText>

                  {projects.length ? (
                    <Table>
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>Project</th>
                          <th>Uninvoiced</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projects.map((project) => {
                          const isSelected =
                            selectedProjects.findIndex(
                              (projectId) => projectId === project.project_id,
                            ) > -1;

                          return (
                            <tr key={project.project_id}>
                              <td>
                                <CheckCircle
                                  onClick={() =>
                                    toggleProject(project.project_id)
                                  }
                                  isChecked={isSelected}
                                />
                              </td>

                              <td>{project.project_name}</td>

                              <td
                                style={{
                                  textDecoration: isSelected
                                    ? "none"
                                    : "line-through",
                                }}
                              >
                                {getCurrency(project.uninvoiced_amount, true)}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td colSpan="3">
                            <Sum>
                              <span>Total</span>
                              <b>{getCurrency(total, true)}</b>
                            </Sum>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <p>Nothing to invoice here!</p>
                  )}
                </Field>

                {selectedProjects.length ? (
                  <Button onClick={createInvoice}>Draft Invoice</Button>
                ) : null}
              </>
            ) : (
              <SmallText>Loading...</SmallText>
            )}
          </>
        ) : null}
      </InvoiceCol>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Column = styled.div`
  padding: 50px 40px;
  overflow: auto;
`;

const Heading = styled.h2`
  font-size: ${(props) => props.theme.fontSize_xxs};
  color: ${(props) => props.theme.colors.darkSlate};
`;

const OptionsCol = styled(Column)`
  flex-basis: 40%;
  max-width: 40%;

  ${FieldGroup} ${Input} {
    width: 190px;
  }
`;

const InvoiceCol = styled(Column)`
  flex-basis: 60%;
  max-width: 60%;

  background-color: ${(props) => props.theme.colors.cardBackground};
`;

const Dropdown = styled(SelectDropdown)`
  flex-grow: 1;
`;

const Separator = styled.hr`
  margin: 30px 0;
  background-color: ${(props) => props.theme.colors.slate};

  border: 0;
  border-radius: 0;
  height: 1px;
`;

const Question = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid;
  width: 30px;
  height: 30px;
  font-weight: 500;

  margin-left: 10px;

  &::before {
    content: "?";
  }
`;

const Table = styled.table`
  /* margin-bottom: 0; */

  thead {
    th {
      text-align: left;
    }
  }

  th,
  td {
    padding-right: 40px;
    padding-bottom: 15px;

    &:last-child {
      text-align: right;
      padding-right: 0;
    }
  }

  tbody {
    tr {
      &:last-child {
        td,
        th {
          padding-bottom: 0;
        }
      }
    }
  }
`;

const SmallText = styled.p`
  font-size: ${(props) => props.theme.fontSize_xxxs};
`;

const Sum = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  span {
    padding-right: 30px;
    text-transform: uppercase;
  }
`;

export default InvoiceCreate;
