import React from "react";
import styled from "styled-components";

const PaginationRightIcon = ({ ...props }) => {
  return (
    <Svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.86597 4.90796L11.366 8.40796L7.86597 11.908"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="8.625"
        cy="8.40796"
        r="7.625"
        transform="rotate(-180 8.625 8.40796)"
        stroke="white"
        strokeWidth="1.5"
      />
    </Svg>
  );
};

const Svg = styled.svg``;

export default PaginationRightIcon;
