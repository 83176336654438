import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useParams, useHistory } from "react-router-dom";

import { useNotifications } from "../../../context/notificationsContext";

import {
  getClientFromApi,
  updateClientOnApi,
  getMembersFromApi,
} from "../utils/api";

import {
  createTaskGeneratorOnApi,
  updateTaskGeneratorOnApi,
  getAllTaskGeneratorsFromApi,
} from "../../tasks/utils/api";

import { getSettings } from "../../../utils/api";

import Button from "../../../components/buttons/Button";
import { Field, Label, Input } from "../../../components/Form";
import SelectDropdown from "../../../components/SelectDropdown";

import ClientMeeting from "./components/clientMeeting";

const ClientEdit = () => {
  const { id } = useParams();
  const { openAlertPopup } = useNotifications();
  const history = useHistory();

  const [client, setClient] = useState();
  const [name, setName] = useState(null);
  const [acronym, setAcronym] = useState(null);
  const [tier, setTier] = useState(null);
  const [status, setStatus] = useState(null);
  const [producer, setProducer] = useState(null);
  const [partner, setPartner] = useState(null);
  const [accountOwner, setAccountOwner] = useState(null);
  const [members, setMembers] = useState(null);
  const [availProducers, setAvailProducers] = useState(null);
  const [availPartners, setAvailPartners] = useState(null);
  const [accountingId, setAccountingId] = useState(null);
  const [invoiceApprovers, setInvoiceApprovers] = useState([]);

  const [contactName, setContactName] = useState(null);
  const [contactEmail, setContactEmail] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [googleSheet, setGoogleSheet] = useState(null);
  const [googleFolder, setGoogleFolder] = useState(null);
  const [productionGoogleFolder, setProductionGoogleFolder] = useState(null);

  const [page, setPage] = useState(1);

  // ? retainers now handled by contracts
  // const [retainers, setRetainers] = useState([]);

  const [producerGeneratorId, setProducerGeneratorId] = useState(null);
  const [producerFrequency, setProducerFrequency] = useState(null);
  const [producerRepeatEvery, setProducerRepeatEvery] = useState(null);
  const [producerWeekArray, setProducerWeekArray] = useState([]);
  const [producerMonthType, setProducerMonthType] = useState("Day");
  const [producerRepeatOn, setProducerRepeatOn] = useState(null);
  const [producerSpecificWeek, setProducerSpecificWeek] = useState(null);
  const [producerSpecificDay, setProducerSpecificDay] = useState(null);
  const [producerTime, setProducerTime] = useState(null);
  const [producerActiveProject, setProducerActiveProject] = useState("No");

  const [partnerGeneratorId, setPartnerGeneratorId] = useState(null);
  const [partnerFrequency, setPartnerFrequency] = useState(null);
  const [partnerRepeatEvery, setPartnerRepeatEvery] = useState(null);
  const [partnerWeekArray, setPartnerWeekArray] = useState([]);
  const [partnerMonthType, setPartnerMonthType] = useState("Day");
  const [partnerRepeatOn, setPartnerRepeatOn] = useState(null);
  const [partnerSpecificWeek, setPartnerSpecificWeek] = useState(null);
  const [partnerSpecificDay, setPartnerSpecificDay] = useState(null);
  const [partnerTime, setPartnerTime] = useState(null);
  const [partnerActiveProject, setPartnerActiveProject] = useState("No");

  const tiers = [
    {
      label: "Tier 1",
      value: "Tier 1",
    },
    {
      label: "Tier 2",
      value: "Tier 2",
    },
    {
      label: "Tier 3",
      value: "Tier 3",
    },
  ];

  const statuses = [
    {
      label: "New",
      value: "New",
    },
    {
      label: "Target",
      value: "Target",
    },
    {
      label: "Contacted",
      value: "Contacted",
    },
    {
      label: "Prospect",
      value: "Prospect",
    },
    {
      label: "Open Deal",
      value: "Open Deal",
    },
    {
      label: "Client",
      value: "Client",
    },
    {
      label: "Abandoned",
      value: "Abandoned",
    },
    {
      label: "Referral Partner",
      value: "Referral Partner",
    },
    {
      label: "Past Client",
      value: "Past Client",
    },
    {
      label: "Vendor",
      value: "Vendor",
    },
  ];

  const [questions, setQuestions] = useState([]);
  const [briefQuestions, setBriefQuestions] = useState([]);

  /*
  |--------------------------------------------------------------------------
  | Check to see if a week is added to the meeting
  |--------------------------------------------------------------------------
  */
  const toggleWeek = (type, week) => {
    let tempWeekArray = [];

    if (type === "producer") {
      tempWeekArray = [...producerWeekArray];
    } else if (type === "partner") {
      tempWeekArray = [...partnerWeekArray];
    }

    if (tempWeekArray && tempWeekArray.includes(week)) {
      const index = tempWeekArray.indexOf(week);

      if (index !== -1) {
        tempWeekArray.splice(index, 1);
      }
    } else {
      tempWeekArray.push(week);
    }

    if (type === "producer") {
      setProducerWeekArray(tempWeekArray);
    } else if (type === "partner") {
      setPartnerWeekArray(tempWeekArray);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members and clients on start
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    getMembers();
    getClient();
  }, []); //eslint-disable-line

  /*
  |--------------------------------------------------------------------------
  | Get task generators for client
  |--------------------------------------------------------------------------
  */
  useEffect(() => {
    if (producer || partner) {
      getGenerators();
    }
  }, [producer, partner]); //eslint-disable-line

  const getQuestions = async () => {
    try {
      const settings = await getSettings();

      if (settings && settings.clientBrief && settings.clientBrief.length) {
        const tempSettings = settings.clientBrief;

        setBriefQuestions(settings.clientBrief);
        return tempSettings;
      }
    } catch (error) {
      console.error("Error getting questions", error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get all members
  |--------------------------------------------------------------------------
  */
  const getMembers = async () => {
    try {
      const membersFromApi = await getMembersFromApi();

      // to be a producer, the user must have a role that includes the following text:
      const producers = membersFromApi.filter(
        (member) =>
          member.role.role.toLowerCase().includes("production manager") ||
          member.role.role.toLowerCase().includes("partnership manager"),
      );

      // to be a partner, the user must have a role that includes the following text:
      const partners = membersFromApi.filter((member) =>
        member.role.role.toLowerCase().includes("partnership manager"),
      );

      setMembers(membersFromApi);
      setAvailProducers(producers);
      setAvailPartners(partners);
    } catch (error) {
      console.error(error);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Get the client
  |--------------------------------------------------------------------------
  */
  async function getGenerators() {
    const generators = await getAllTaskGeneratorsFromApi({ clientId: id });

    if (generators && producer) {
      generators.forEach((generator) => {
        if (generator.members.includes(producer)) {
          setProducerGeneratorId(generator._id);
          setProducerFrequency(generator.frequency);
          setProducerRepeatEvery(generator.repeatEvery);
          setProducerWeekArray(
            generator.frequency === "Week" ? generator.repeatOn : [],
          );
          setProducerMonthType(
            generator.frequency === "Month" && generator.repeatOn
              ? "Day"
              : "Week",
          );
          setProducerRepeatOn(generator.repeatOn);
          setProducerSpecificWeek(generator.specificWeek);
          setProducerSpecificDay(generator.specificDay);
          setProducerTime(generator.time);
          setProducerActiveProject(generator.activeProject);
        }
      });
    }

    if (generators && partner) {
      generators.forEach((generator) => {
        if (generator.members.includes(partner)) {
          setPartnerGeneratorId(generator._id);
          setPartnerFrequency(generator.frequency);
          setPartnerRepeatEvery(generator.repeatEvery);
          setPartnerWeekArray(
            generator.frequency === "Week" ? generator.repeatOn : [],
          );
          setPartnerMonthType(
            generator.frequency === "Month" && generator.repeatOn
              ? "Day"
              : "Week",
          );
          setPartnerRepeatOn(generator.repeatOn);
          setPartnerSpecificWeek(generator.specificWeek);
          setPartnerSpecificDay(generator.specificDay);
          setPartnerTime(generator.time);
          setPartnerActiveProject(generator.activeProject);
        }
      });
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Get the client
  |--------------------------------------------------------------------------
  */
  async function getClient() {
    const result = await getClientFromApi(id);

    setClient(result);
    setName(result.name);
    setAcronym(result.acronym);
    setTier(result.tier);
    setStatus(result.status);
    setProducer(result.producer?._id || null);
    setPartner(result.partner?._id);
    setAccountOwner(result.accountOwner?._id);
    setAccountingId(result.accountingId);
    setInvoiceApprovers(result.invoiceApprovers || []);
    setStartDate(result.startDate);
    setEndDate(result.endDate);
    setGoogleSheet(result.googleSheet);
    setGoogleFolder(result.googleFolder);
    setProductionGoogleFolder(result.productionGoogleFolder);

    const briefQuestions = await getQuestions();
    const tempQuestions = [];

    // Set the questions if they have been filled out
    if (result.questions) {
      // Loop through each of the brief questions that were set in the company settings
      briefQuestions.forEach((brief) => {
        const tempItem = { q: brief._id };

        // Check to see if there's a question in the results that has the same id as the brief
        result.questions.forEach((result) => {
          if (brief._id === result.q) {
            tempItem.a = result.a;
          }
        });

        tempQuestions.push(tempItem);
      });
    }
    // If no existing questions are saved
    else {
      briefQuestions.forEach((brief) => {
        tempQuestions.push({
          q: brief._id,
          a: "",
        });
      });
    }

    if (tempQuestions) {
      setQuestions(tempQuestions);
    }

    if (result.pointOfContact) {
      setContactName(result.pointOfContact.name);
      setContactEmail(result.pointOfContact.email);
    }

    // let tempRetainers = [...result.retainers];

    // tempRetainers.forEach((retainer) => {
    //   retainer.status = "old";
    // });

    // setRetainers(tempRetainers);
  }

  /*
  |--------------------------------------------------------------------------
  | Format time
  |--------------------------------------------------------------------------
  */
  const formatTime = (timestamp) => {
    let suffix;
    const timeArray = timestamp.split(":");
    let hours = parseInt(timeArray[0]);
    const minutes = timeArray[1];

    if (hours === 12) {
      suffix = "PM";
    } else if (hours > 12) {
      suffix = "PM";
      hours = hours - 12;
    } else if (hours === "00" || hours === "0" || hours === 0) {
      hours = 12;
      suffix = "AM";
    } else {
      suffix = "AM";
    }

    return `${hours}:${minutes}${suffix}`;
  };

  /*
  |--------------------------------------------------------------------------
  | Update the client in the api
  |--------------------------------------------------------------------------
  */
  const updateClient = async () => {
    try {
      const data = {
        clientId: id,
        harvestId: client.harvestId,
        database: {
          name,
          acronym,
          tier,
          status,
          producer,
          partner,
          accountOwner,
          accountingId,
          invoiceApprovers,
          pointOfContact: {
            name: contactName,
            email: contactEmail,
          },
          startDate,
          endDate,
          questions,
          googleSheet,
          googleFolder,
          productionGoogleFolder,
        },
        harvest: {
          name,
        },
        // retainers,
      };

      let producerResult;
      let partnerResult;

      if (
        producer &&
        producerFrequency &&
        producerRepeatEvery &&
        producerRepeatOn &&
        producerTime
      ) {
        const producerData = {
          clientId: id,
          title: `Client Meeting with ${name}`,
          description: producerTime
            ? `Client meeting with ${name} at ${formatTime(producerTime)}`
            : null,
          startDate,
          endDate,
          members: [producer],
          frequency: producerFrequency,
          repeatEvery: producerRepeatEvery,
          repeatOn:
            producerFrequency === "Week"
              ? producerWeekArray
              : producerFrequency === "Month" && producerMonthType === "Day"
              ? producerRepeatOn
              : null,
          specificWeek:
            producerMonthType === "Week" ? producerSpecificWeek : null,
          specificDay:
            producerMonthType === "Week" ? producerSpecificDay : null,
          time: producerTime,
          activeProject: producerActiveProject,
        };

        if (producerGeneratorId) {
          producerResult = await updateTaskGeneratorOnApi(
            producerGeneratorId,
            producerData,
          );
        } else {
          producerResult = await createTaskGeneratorOnApi(producerData);
        }
      }

      if (
        partner &&
        partnerFrequency &&
        partnerRepeatEvery &&
        partnerRepeatOn &&
        partnerTime
      ) {
        const partnerData = {
          clientId: id,
          title: `Client Meeting with ${name}`,
          description: partnerTime
            ? `Client meeting with ${name} at ${formatTime(partnerTime)}`
            : null,
          startDate,
          endDate,
          members: [partner],
          frequency: partnerFrequency,
          repeatEvery: partnerRepeatEvery,
          repeatOn:
            partnerFrequency === "Week"
              ? partnerWeekArray
              : partnerFrequency === "Month" && partnerMonthType === "Day"
              ? partnerRepeatOn
              : null,
          specificWeek:
            partnerMonthType === "Week" ? partnerSpecificWeek : null,
          specificDay: partnerMonthType === "Week" ? partnerSpecificDay : null,
          time: partnerTime,
          activeProject: partnerActiveProject,
        };

        if (partnerGeneratorId) {
          partnerResult = await updateTaskGeneratorOnApi(
            partnerGeneratorId,
            partnerData,
          );
        } else {
          partnerResult = await createTaskGeneratorOnApi(partnerData);
        }
      }

      const result = await updateClientOnApi(data);

      if (result?.status === "success") {
        const producerFail = producerResult?.status !== "success";
        const partnerFail = partnerResult?.status !== "success";

        let successMessage = "The update was successful";

        if (producerFail && partnerFail) {
          successMessage +=
            ", however the producer and partner check in failed";
        } else if (producerFail) {
          successMessage += ", however the producer check in failed";
        } else if (partnerFail) {
          successMessage += ", however the partner check in failed";
        }

        openAlertPopup("Success", successMessage, true);
      } else {
        openAlertPopup(
          "Update failed",
          "The update was not completed correctly.",
        );
      }
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "";

      openAlertPopup(
        "Update failed",
        `Sorry, we could not complete that update. ${errorMessage}`,
      );
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Update client brief answers
  |--------------------------------------------------------------------------
  */
  const updateAnswer = (index, answer) => {
    const newQuestions = [...questions];
    newQuestions[index].a = answer;
    setQuestions(newQuestions);
  };

  return (
    <>
      {client ? (
        <>
          <ManageHeader>
            <h1>
              {acronym ? `${acronym} -` : ""} {name}
            </h1>
          </ManageHeader>

          <ClientSetup>
            {page === 1 ? (
              <ClientDetails>
                <Heading>Client Details</Heading>
                <FlexContainer>
                  <div>
                    <Field>
                      <Label>Client Name *</Label>
                      <Input
                        name="name"
                        type="text"
                        defaultValue={name}
                        onChange={(e) => setName(e.target.value)}
                        isInvalid={!name}
                      />
                    </Field>
                    <Field>
                      <Label>Client Acronym *</Label>
                      <Input
                        name="acronym"
                        type="text"
                        defaultValue={acronym}
                        onChange={(e) => setAcronym(e.target.value)}
                        isInvalid={!acronym}
                      />
                    </Field>
                    <Field>
                      <Label>Client Status *</Label>
                      <StyledSelectDropdown
                        id="status"
                        onChange={(e) => {
                          setStatus(e.value);
                        }}
                        value={{ label: status, value: status }}
                        placeholder="Select Client Status"
                        options={statuses}
                        isInvalid={!status}
                      ></StyledSelectDropdown>
                    </Field>

                    <Field>
                      <Label>Client Tier *</Label>
                      <StyledSelectDropdown
                        id="tier"
                        onChange={(e) => {
                          setTier(e.value);
                        }}
                        value={{ label: tier, value: tier }}
                        placeholder="Select Client Tier"
                        options={tiers}
                        isInvalid={!tier}
                      ></StyledSelectDropdown>
                    </Field>
                    {availPartners ? (
                      <Field>
                        <Label>Partnership Manager *</Label>
                        <StyledSelectDropdown
                          id="partners"
                          onChange={(e) => {
                            setPartner(e.value);
                          }}
                          isInvalid={!partner}
                          placeholder="Select Partner"
                          value={
                            partner &&
                            availPartners &&
                            availPartners
                              .filter((item) => item._id === partner)
                              .map((item) => ({
                                label: item.name,
                                value: item._id,
                              }))
                          }
                          options={
                            availPartners &&
                            availPartners.map((item) => ({
                              label: item.name,
                              value: item._id,
                            }))
                          }
                        />
                      </Field>
                    ) : null}
                    {availProducers && (
                      <Field>
                        <Label>Production Manager *</Label>
                        <StyledSelectDropdown
                          onChange={(e) => setProducer(e.value)}
                          placeholder="Select Producer"
                          isInvalid={!producer}
                          value={
                            producer &&
                            availProducers
                              .filter((item) => item._id === producer)
                              .map((item) => ({
                                label: item.name,
                                value: item._id,
                              }))
                          }
                          options={availProducers.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                        />
                      </Field>
                    )}
                    {members ? (
                      <Field>
                        <Label>Relationship Owner</Label>
                        <StyledSelectDropdown
                          id="accountOwner"
                          onChange={(e) => {
                            setAccountOwner(e.value);
                          }}
                          placeholder="Select Relationship Owner"
                          value={
                            accountOwner &&
                            members
                              .filter((item) => item._id === accountOwner)
                              .map((item) => ({
                                label: item.name,
                                value: item._id,
                              }))
                          }
                          options={members.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                        />
                      </Field>
                    ) : null}

                    <Field>
                      <Label>Client Start Date *</Label>
                      <Input
                        name="startDate"
                        type="date"
                        placeholder=""
                        defaultValue={startDate && startDate.substring(0, 10)}
                        onChange={(e) => setStartDate(e.target.value)}
                        isInvalid={!startDate}
                      />
                    </Field>
                    <Field>
                      <Label>Client End Date *</Label>
                      <Input
                        name="endDate"
                        type="date"
                        placeholder=""
                        defaultValue={endDate && endDate.substring(0, 10)}
                        onChange={(e) => setEndDate(e.target.value)}
                        isInvalid={!endDate}
                      />
                    </Field>

                    <Field>
                      <Label>Account Summary Google Sheet Link</Label>
                      <Input
                        name="googleSheet"
                        type="text"
                        value={googleSheet}
                        onChange={(e) => setGoogleSheet(e.target.value)}
                      />
                    </Field>
                    <Field>
                      <Label>Client Delivery Google Folder Link</Label>
                      <Input
                        name="googleFolder"
                        type="text"
                        value={googleFolder}
                        onChange={(e) => setGoogleFolder(e.target.value)}
                      />
                    </Field>
                    <Field>
                      <Label>Production Google Folder Link</Label>
                      <Input
                        name="productionGoogleFolder"
                        type="text"
                        value={productionGoogleFolder}
                        onChange={(e) =>
                          setProductionGoogleFolder(e.target.value)
                        }
                      />
                    </Field>

                    <Subheading>Primary Client Contact</Subheading>
                    <Field>
                      <Label>Name</Label>
                      <Input
                        name="contactName"
                        type="text"
                        placeholder=""
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />
                    </Field>
                    <Field>
                      <Label>Email</Label>
                      <Input
                        name="contactEmail"
                        type="text"
                        placeholder=""
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                      />
                    </Field>
                    <Subheading>Billing</Subheading>
                    <Field>
                      <Label>QuickBooks ID</Label>
                      <Input
                        name="accountingId"
                        type="text"
                        defaultValue={accountingId}
                        onChange={(e) => setAccountingId(e.target.value)}
                      />
                    </Field>
                    {members ? (
                      <Field>
                        <Label>Invoice Approvers</Label>
                        <StyledSelectDropdown
                          id="invoiceApprovers"
                          onChange={(e) => {
                            const approvers = e || [];
                            const newApprovers = approvers.map(
                              (approver) => approver.value,
                            );
                            setInvoiceApprovers(newApprovers);
                          }}
                          placeholder="Select Approvers"
                          options={members.map((option) => ({
                            label: option.name,
                            value: option._id,
                          }))}
                          value={invoiceApprovers.map((approverId) => {
                            const approverMember = members.find(
                              (member) => member._id === approverId,
                            );

                            return {
                              label: approverMember?.name || approverId,
                              value: approverId,
                            };
                          })}
                          isMulti
                        />
                      </Field>
                    ) : null}
                  </div>

                  {/* <div>
                    <Subheading style={{ marginTop: "0px" }}>
                      Retainers
                    </Subheading>
                    <RetainerContainer>
                      {retainers.map((retainer, index) => {
                        if (retainer.status && retainer.status !== "deleted") {
                          return (
                            <Retainer key={index}>
                              <RetainerHeader>
                                <RetainerTitle>
                                  Retainer {index + 1}
                                </RetainerTitle>
                                <RetainerDelete
                                  onClick={() => deleteRetainer(index)}
                                >
                                  Delete
                                </RetainerDelete>
                              </RetainerHeader>
                              <RetainerField>
                                <Label>Start Date</Label>
                                <RetainerInput
                                  name="retainerStartDate"
                                  type="date"
                                  placeholder=""
                                  defaultValue={
                                    retainer.startDate &&
                                    retainer.startDate.substring(0, 10)
                                  }
                                  onChange={(e) =>
                                    setRetainerStartDate(e.target.value, index)
                                  }
                                />
                              </RetainerField>
                              <RetainerField>
                                <Label>End Date</Label>
                                <RetainerInput
                                  name="retainerEndDate"
                                  type="date"
                                  placeholder=""
                                  value={
                                    retainer.endDate &&
                                    retainer.endDate.substring(0, 10)
                                  }
                                  onChange={(e) =>
                                    setRetainerEndDate(e.target.value, index)
                                  }
                                />
                              </RetainerField>
                              <RetainerField>
                                <Label>Amount</Label>
                                <RetainerInput
                                  name="retainerEndDate"
                                  type="text"
                                  placeholder="$420"
                                  value={retainer.amount}
                                  onChange={(e) =>
                                    setRetainerAmount(e.target.value, index)
                                  }
                                />
                              </RetainerField>
                            </Retainer>
                          );
                        } else return null;
                      })}
                      <CreateLink
                        onClick={() => {
                          addRetainer();
                        }}
                      >
                        Add a Retainer
                      </CreateLink>
                    </RetainerContainer>
                  </div> */}
                </FlexContainer>
              </ClientDetails>
            ) : page === 2 ? (
              <ClientBrief>
                <Heading>Client Brief</Heading>
                {briefQuestions &&
                  briefQuestions.length &&
                  questions &&
                  questions.length &&
                  questions.map((question, index) => {
                    return (
                      <Field key={index}>
                        <Label>
                          {
                            briefQuestions.find(
                              (item) => item._id === question.q,
                            ).question
                          }
                        </Label>
                        <Input
                          name={`question${index}`}
                          type="text"
                          placeholder=""
                          value={question.a}
                          onChange={(e) => updateAnswer(index, e.target.value)}
                        />
                      </Field>
                    );
                  })}
              </ClientBrief>
            ) : page === 3 ? (
              <MeetingContainer>
                <Heading>Client Meetings</Heading>
                <FlexContainer>
                  <ClientMeeting
                    frequency={producerFrequency}
                    setFrequency={setProducerFrequency}
                    repeatEvery={producerRepeatEvery}
                    setRepeatEvery={setProducerRepeatEvery}
                    weekArray={producerWeekArray}
                    monthType={producerMonthType}
                    setMonthType={setProducerMonthType}
                    repeatOn={producerRepeatOn}
                    setRepeatOn={setProducerRepeatOn}
                    specificWeek={producerSpecificWeek}
                    setSpecificWeek={setProducerSpecificWeek}
                    specificDay={producerSpecificDay}
                    setSpecificDay={setProducerSpecificDay}
                    time={producerTime}
                    setTime={setProducerTime}
                    activeProject={producerActiveProject}
                    setActiveProject={setProducerActiveProject}
                    toggleWeek={toggleWeek}
                    heading={"Production Manager Check-in"}
                    type="producer"
                  />

                  <ClientMeeting
                    frequency={partnerFrequency}
                    setFrequency={setPartnerFrequency}
                    repeatEvery={partnerRepeatEvery}
                    setRepeatEvery={setPartnerRepeatEvery}
                    weekArray={partnerWeekArray}
                    monthType={partnerMonthType}
                    setMonthType={setPartnerMonthType}
                    repeatOn={partnerRepeatOn}
                    setRepeatOn={setPartnerRepeatOn}
                    specificWeek={partnerSpecificWeek}
                    setSpecificWeek={setPartnerSpecificWeek}
                    specificDay={partnerSpecificDay}
                    setSpecificDay={setPartnerSpecificDay}
                    time={partnerTime}
                    setTime={setPartnerTime}
                    activeProject={partnerActiveProject}
                    setActiveProject={setPartnerActiveProject}
                    toggleWeek={toggleWeek}
                    heading={"Partnership Manager Check-in"}
                    type="partner"
                  />
                </FlexContainer>
              </MeetingContainer>
            ) : null}
          </ClientSetup>

          <SaveContainer>
            {page === 1 ? (
              <Continue
                onClick={() => setPage(page + 1)}
                disabled={
                  !(
                    name &&
                    startDate &&
                    partner &&
                    producer &&
                    acronym &&
                    tier &&
                    status
                  )
                }
              >
                Continue
              </Continue>
            ) : (
              <ButtonRow>
                <Back onClick={() => setPage(page - 1)}>Back</Back>

                {page === 3 ? (
                  <Button
                    onClick={() => updateClient()}
                    disabled={
                      !(
                        name &&
                        startDate &&
                        partner &&
                        producer &&
                        acronym &&
                        tier &&
                        status
                      )
                    }
                  >
                    Save
                  </Button>
                ) : (
                  <Continue
                    onClick={() => setPage(page + 1)}
                    disabled={
                      !(
                        name &&
                        startDate &&
                        partner &&
                        producer &&
                        acronym &&
                        tier &&
                        status
                      )
                    }
                  >
                    Continue
                  </Continue>
                )}
              </ButtonRow>
            )}
          </SaveContainer>
        </>
      ) : null}
    </>
  );
};

const ManageHeader = styled.div`
  padding: 40px 50px;
  border-bottom: 1px solid #cec9d4;
  margin-bottom: 30px;

  h1 {
    font-size: 28px;
    color: #373040;
    margin-bottom: 0;
  }

  p {
    font-weight: 300;
    font-size: 14px;
    color: #373040;
    margin-bottom: 0px;
  }
`;

const ClientSetup = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 0px 50px;

  input,
  textarea,
  label {
    width: 400px;
  }
`;

const ClientDetails = styled.div``;

const ClientBrief = styled.div``;

const Heading = styled.h2`
  font-size: 28px;
  color: #373040;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 40px;
`;

const Subheading = styled.h4`
  font-size: 16px;
  color: #373040;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 900px;
  margin: 50px 0px 100px 50px;
`;

// const RetainerContainer = styled.div`
//   margin-top: 30px;
//   width: 400px;
// `;

// const Retainer = styled.div`
//   margin-bottom: 40px;
// `;

// const RetainerHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-end;
//   margin-bottom: 1rem;
// `;

// const RetainerDelete = styled.p`
//   margin: 0px;
//   color: #df5757;
//   font-weight: bold;
//   font-size: 12px;
//   cursor: pointer;

//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const RetainerTitle = styled.p`
//   font-weight: 500;
//   margin-bottom: 0px;
// `;

// const RetainerField = styled(Field)`
//   flex-direction: column;
//   align-items: flex-start;
// `;

// const RetainerInput = styled(Input)`
//   margin-top: 5px;
// `;

const Continue = styled(Button)``;

const Back = styled(Button)`
  margin-right: 50px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledSelectDropdown = styled(SelectDropdown)`
  width: 400px;

  div:first-of-type {
    ${(props) =>
      props.isInvalid
        ? css`
            border-color: ${props.theme.colors.red};
          `
        : ``}
  }
`;

const MeetingContainer = styled.div``;

export default ClientEdit;
